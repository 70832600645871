import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    Input,
    OnDestroy,
    Output,
    EventEmitter,
    AfterViewInit,
} from '@angular/core';
import { LocationData, LocationEntitiesData, SeriesEntityGroup } from 'app/shared/models/locations-entities-data';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import {
    OLMAPPROJECTION,
    ADS_MONITOR_LAYER,
    SILT_ENTITY,
    RAIN_ENTITY,
    DEPTH_ENTITY,
    VELOCITY_ENTITY,
    QUANTITY_ENTITY,
    DFINAL_ENTITY,
    DEPTH_DISPLAY_GROUP,
    VELOCITY_DISPLAY_GROUP,
    FLOW_DISPLAY_GROUP,
    RAIN_DISPLAY_GROUP,
    V_FINAL_ENTITY,
    Q_FINAL_ENTITY,
    R_FINAL_ENTITY,
    QCONTINUITY_ENTITY,
    LEVEL_ENTITY,
    MANHOLE_DEPTH_ANNOTATION,
    PIPE_HEIGHT_ANNOTATION,
} from 'app/shared/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'app/pages/admin/users.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { SignalRService } from 'app/shared/services/signalr.service';
import { GISService } from 'app/shared/services/gis-service';
import { LocationService } from 'app/shared/services/location.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { AdvanceScattergraphContainerComponent } from 'app/pages/view-data/graphs/advance-scattergraph-container/advance-scattergraph-container.component';
import { HydrographArgs } from 'app/shared/models/hydrograph';
import { AnnotationSettings, HydrographTickInterval, OptimizedViewData } from 'app/shared/models/view-data';
import { AnnotationOptions, BasicSeriesData, DataType } from 'app/shared/models/hydrographNEW';
import { cloneDeep, isEqual } from 'lodash';
import { ScatterData } from 'app/shared/models/scatter-data';
import { LocationDashboardFilterData } from 'app/shared/models/location-dashboard-filter-data';
import { ViewDataFilterArgs } from 'app/shared/models/view-data-filter';
import { ViewDataFilterComponent } from 'app/pages/view-data/view-data-filter/view-data-filter.component';
import { SelectableGroup } from 'app/shared/models/selectable';
import { MapService } from 'app/shared/services/map.service';
import { LocationDetails, LocationUIPipeTable } from 'app/shared/models/location-details';
import { UnitCategoryType } from 'app/shared/models/units';
import { UnitsService } from 'app/shared/services/units.service';
import { PercentFullData, PercentFullReportArgs } from 'app/shared/models/percent-full';
import { PercentFullReportService } from 'app/pages/report/percent-full-report';
import { DrawPipeHelper } from 'app/shared/helpers/draw-pipe.helper';
import { LightningChartBuilder } from 'app/shared/components/hydrograph/lightning-chart-builder/lightning-chart-builder';
import { LightningChartObject } from 'app/shared/components/hydrograph/lightning-chart-object/lightning-chart-object';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { LightningChartBuildDataConfig } from 'app/shared/components/hydrograph/lightning-chart-builder/lightning-chart-data-model';
import { tap } from 'rxjs/operators';

const RAINALERT = 'RainAlert III';
const OTHERTEXT = 'other';
const MONITOR_LAYER_NAME = "Monitor";

const DEFAULT_SELECTED_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: VELOCITY_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VELOCITY', isChecked: true, isANSR: false },
    { id: QUANTITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QUANTITY', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];

export const DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: DFINAL_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DFINAL', isChecked: true, isANSR: false },
    { id: V_FINAL_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VFINAL', isChecked: true, isANSR: false },
    { id: Q_FINAL_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QFINAL', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];

const DEFAULT_SG_SELECTED_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: VELOCITY_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VELOCITY', isChecked: true, isANSR: false },
];
const DEFAULT_SG_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: DFINAL_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DFINAL', isChecked: true, isANSR: false },
    { id: V_FINAL_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VFINAL', isChecked: true, isANSR: false },
];

@Component({
    selector: 'app-location-gis-quick-look',
    templateUrl: './location-gis-quick-look.component.html',
    styleUrls: ['./location-gis-quick-look.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationGisQuickLookComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('hydrographChartContainer', { static: true })
    public hydrographChartContainer: ElementRef<HTMLDivElement>;
    @ViewChild('advanceScatterGraph', { static: true })
    public advanceScatterGraph: AdvanceScattergraphContainerComponent;
    @ViewChild('dataFilter', { static: true }) public dataFilter: ViewDataFilterComponent;
    @ViewChild('pipeCanvas', { static: true }) public pipeCanvas: ElementRef<HTMLCanvasElement>;

    @Output() public preventUnpinnedClose = new EventEmitter<boolean>();

    @Input() public customerID: number;
    @Input() public locationGroupID: number;
    @Input() public locationEntityData: LocationEntitiesData;
    @Input() public isOpened: boolean;
    public filterSettings: ViewDataFilterArgs = {
        isExportPrintEnabled: false,
        isDisplayExportMenu: false,
        isDisplayEntities: true,
        isDisplayDatePicker: false,
        isDisplayDataAveraging: false,
    };

    lcChart: LightningChartObject;
    fetchGraphSub: Subscription;

    public prevFiltersData: LocationDashboardFilterData;

    public activeMonitor: string = null;
    public hydroGraphLoading$ = new BehaviorSubject<boolean>(false);
    public noDataForHydrographChart = false;
    newHydroBackupData: any;
    missingRain: boolean;
    missingLevel = false;
    missingParameters: string;
    locationId: number;
    private overallEntityGroupingInformation: SeriesEntityGroup[] | any;
    private confirmationText: any;
    public newHydroDates: number[];
    public entityGroupingsHydroNEW: number[][];
    public numberOfTimes: Array<number>;
    public hideScatterGraph = false;
    public selectedSGEntityIds: number[];
    public scatterGraphConfirmationData = {};
    public annotationSettings: AnnotationSettings = {
        isPipeHeight: false,
        isManholeDepth: false,
        isIsoQ: false,
        isBestFit: false,
        isSSCurve: false,
        isCWRcurve: false,
        isManningDesign: false,
        isLanfearColl: false,
        isManualLinear: false,
        isManualSmooth: false,
        isFroude: false,
        isCurveEnabled: false,
        isDataQuality: false,
        isHighHigh: false,
        isHighLevel: false,
        isHighFlow: false,
        isLowDepth: false,
        isRainOnTop: false,
        isShowEdits: false,
        isSilt: false,
    };
    public scattergraphLoading: boolean;
    public noDataForScatterChart: boolean;
    public scatterGraphData: any;
    public enableHeaderButtons: boolean;
    userHasRawDataPermission: boolean;
    scatterDateFormat: string;
    tooltipTimeFormat: string;
    scatterTimeFormat: string;
    monitorSeries: any;
    extraEntities: number[];
    private subscriptions = new Array<Subscription>();
    isCompLoc: boolean;
    startDate: Date;
    endDate: Date;
    getHydrographParams: any;
    showScatterGraph: boolean;
    handToogleHydrograph: boolean;
    showHydroGraph: boolean;
    filterSelectedValues: LocationDashboardFilterData;
    selectedEntities: SelectableGroup[];
    isBasicDataEditingAllowed: boolean;
    locIndex: number;
    usgs: boolean;
    isUSGS$ = new BehaviorSubject<boolean>(null);
    unit: object = { unit: 'in' };
    public pipeDetails?: LocationUIPipeTable[] = null;
    public noPipeVolumeData = true;
    public pipeVolumePercent?: number;

    public pipeStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public pipeEndDate = new Date();
    public pipeMaxDate = new Date();
    public pipeTickInterval: HydrographTickInterval;

    public chartId = 1000;
    public isMonitor: boolean = true;      // true if Location is selected for quick look, false if any other layer is selected
    constructor(
        private elRef: ElementRef,
        private activatedRoute: ActivatedRoute,
        private usersService: UsersService,
        private cdr: ChangeDetectorRef,
        private dateutilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        public translate: TranslateService,
        public signalRService: SignalRService,
        private router: Router,
        public gisService: GISService,
        private locationService: LocationService,
        public dataEditService: DataEditService,
        private viewDataService: ViewDataService,
        private mapService: MapService,
        private unitsService: UnitsService,
        private percentFullReportService: PercentFullReportService,
        private drawPipeHelper: DrawPipeHelper,
        private lightningChartBuilder: LightningChartBuilder,
        private statusCodeService: StatusCodeService
    ) { }

    ngOnInit() {
        const d = new Date();
        d.setDate(d.getDate() - 7);
        const tzOffset = d.getTimezoneOffset() * 60 * 1000; // in ms
        const graphStart = d.getTime() - tzOffset;
        const graphEnd = new Date().getTime() - tzOffset;
        this.newHydroDates = [graphStart, graphEnd];
        this.userHasRawDataPermission = this.usersService.isRawDataEditingAllowed.getValue();
        this.scatterDateFormat = this.dateutilService.getGraphDateFormat();
        const preSelectedDateTimeFormat = this.viewDataService.dateTimeFormats.getValue();
        if (preSelectedDateTimeFormat) {
            this.tooltipTimeFormat = preSelectedDateTimeFormat.tooltipTimeFormat;
        } else {
            // subscribe to changes in dateFormat
            this.subscriptions.push(
                this.dateutilService.dateFormat.subscribe(() => {
                    // Getting customer dateformat from dateUtil Service
                    this.scatterDateFormat = this.dateutilService.getGraphDateFormat();
                    const timeSubscription = this.dateutilService.timeFormat.subscribe((value) => {
                        this.tooltipTimeFormat = this.scatterTimeFormat = value;
                    });
                    this.subscriptions.push(timeSubscription);
                }),
            );
        }
    }

    public ngAfterViewInit() {
        // #43814 install on scroll event to notify LC about scrolling
        // MatDrawer creates CDKScrollable inside itself, there is no other way to access it then programatically
        const scrollDiv = this.elRef.nativeElement.parentElement as HTMLDivElement;
        scrollDiv.onscroll = () => {
            this.lcChart?.dashboard.engine.layout();
        }
    }
    public ngOnDestroy() {
        this.fetchGraphSub?.unsubscribe();
        this.fetchGraphSub = null;
        this.lcChart?.destroy();
        this.lcChart = null;

        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    clearGis() {
        this.activeMonitor = '';
        this.locationId = -1;
        this.dataEditService.newHydroData$.next(null);
        this.scatterGraphData = null;
        this.monitorSeries = null;

        this.pipeDetails = null;
        this.noPipeVolumeData = true;
        this.pipeVolumePercent = null;

        if (this.pipeCanvas && this.pipeCanvas.nativeElement) {
            const ctx = this.pipeCanvas.nativeElement.getContext('2d');
            ctx?.clearRect(0, 0, this.pipeCanvas.nativeElement.width, this.pipeCanvas.nativeElement.height);
        }
    }

    private checkIfUsgs(details: LocationDetails[], pipeInfo: LocationDetails) {
        // Fix for #21956 - only way we can know location is USGS
        // TODO: API is so much inconsistent here that it works differently for Raleigh, BEAVERDAM CREEK AT DAM and Cape Girardeu, Mississippi River
        this.usgs = !!details[0]?.usgsid || !!pipeInfo?.usgsid || pipeInfo?.series === 'USGS';
        this.isUSGS$.next(this.usgs);
    }

    public locationChange(featureclick) {
        this.dataFilter.resetEntitiesByPermission();

        let selectedname = featureclick[0].feature.get('name');
        if (!selectedname && featureclick && featureclick[0] && featureclick[0].feature && featureclick[0].feature.values_) {
            selectedname = featureclick[0].feature.values_.Name;
        }

        const locationID = featureclick[0].feature.get('LocationID');
        this.isMonitor = featureclick[0].layername === MONITOR_LAYER_NAME;

        if (selectedname && locationID && this.activeMonitor !== selectedname) {
            this.activeMonitor = selectedname;

            combineLatest([
                this.locationService.getLocationDetailsV2(this.customerID, locationID),
                this.mapService.getMarkerLocationDetails(locationID, this.customerID),
            ]).subscribe(([details, pipeInfo]) => {
                this.checkIfUsgs(details, pipeInfo);

                this.locationId = locationID;
                this.drawPipe();

                this.dataFilter.onChangeLocationManual(selectedname, true);
            });
        } else if (selectedname && !locationID && this.activeMonitor !== selectedname) {
            this.clearGis();
        }
    }

    public mouseOutFromHydro() {
        this.viewDataService.highlightScatterPoint.next(null);
    }

    public locationChangeHandler(location: LocationData): void {
        this.scatterGraphData = null;
        this.hideScatterGraph = false;
        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.getCurvesList(location.lid);
            this.advanceScatterGraph.graph.zoomEvent = null;
        }
        // Glitches have let me end up changing location without exiting editing mode
        // Also, this ensures everything is reset (like flaggedPointsOnEdit window AKA ghost points)
        this.locationId = location.lid;

        this.drawPipe();
    }

    // hydro
    // Retrieves hydro graph data for the current location dashboard filters.
    private getHydroGraphData(params: HydrographArgs): void {
        if (!this.isOpened || params.locationId === -1 || params.locationId === 0) {
            return;
        }


        // #22650 sometimes locationChange is called after getHydroGraphData and we do not have correct usgs information here
        let usgsSub = null;
        if (this.usgs === undefined || this.usgs === null) {
            usgsSub = combineLatest([
                this.locationService.getLocationDetailsV2(this.customerID, this.locationId),
                this.mapService.getMarkerLocationDetails(this.locationId, this.customerID),
            ]).pipe(
                tap(([details, pipeInfo]) => {
                    this.checkIfUsgs(details, pipeInfo);
                })
            );
        } else {
            usgsSub = of(null);
        }

        usgsSub.subscribe((ignoreResult) => {
            this.subscriptions.push(
                this.viewDataService.getHydrograph(this.customerID, params.locationId, params).subscribe(
                    (hydroResult: OptimizedViewData) => {
                        this.handleAPIdata(hydroResult);

                        // Internal tracking

                        this.hydroGraphLoading$.next(false);

                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                    () => {
                        this.hydroGraphLoading$.next(false);
                        this.noDataForHydrographChart = true;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                ),
            );
        });
    }

    private handleAPIdata(apiData: OptimizedViewData) {
        if (!this.locationId) {
            return;
        }
        if (!apiData || Object.keys(apiData.compactData).length === 0) {
            if (apiData && apiData.displayGroups[0]) {
                this.unit = { unit: apiData.displayGroups[0].unit };
            }
            this.dataEditService.newHydroData$.next(null);
            this.newHydroBackupData = null;
            this.noDataForHydrographChart = true;

            const currentLocation = this.locationEntityData.l.find((x) => x.lid === this.locationId);
            if (currentLocation && currentLocation.s === RAINALERT) {
                this.missingRain = true;
            }
            return;
        }

        this.noDataForHydrographChart = false;

        if (this.usgs) {
            apiData.entityIds.push(LEVEL_ENTITY);
        }
        const thisLocation = this.locationEntityData.l.find((x) => x.lid === this.locationId);
        const monitorType = thisLocation.s.toLowerCase();
        const ansrEntities = thisLocation.ae;
        let entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === monitorType);
        if (!entityGroups) {
            entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === OTHERTEXT);
        }
        this.overallEntityGroupingInformation = [...entityGroups.g, ...ansrEntities];

        /* Tracker.groups.flagged is the data flag from API. Values can be:
        Good (0), Bad (1, consider that a flagged point), Manual (2), Anonaly (3), or Missing (4)
        */

        // Expand and refactor compacted annotation data
        let tracker;
        const annotationData = {};
        Object.keys(apiData.compactAnnots).forEach((annotKey) => {
            while ((tracker = this.dataEditService.apiRegEx.exec(apiData.compactAnnots[annotKey])) !== null) {
                if (!annotationData[apiData.annotationIds[tracker.groups.entityIndex]]) {
                    annotationData[apiData.annotationIds[tracker.groups.entityIndex]] = [];
                }

                let dataValue = 0;
                let flagged = false;
                let quality = 15; // Default for good (if no quality exists)

                if (tracker.groups.valueString) {
                    const valueStringRes = tracker.groups.valueString.split(',');
                    dataValue = +valueStringRes[0];
                    quality = +valueStringRes[1];
                    flagged = +valueStringRes[2] === 1;
                } else {
                    dataValue = +tracker.groups.value;
                    flagged = +tracker.groups.flagged === 1;
                }

                annotationData[apiData.annotationIds[tracker.groups.entityIndex]].push({
                    x: +annotKey * 1000,
                    y: dataValue,
                    quality: quality,
                    flagged: flagged,
                });
            }
        });

        // Expand and refactor compacted entity data
        const entityData = {};
        let dataMaxDate: number;
        let dataMinDate: number;
        Object.keys(apiData.compactData).forEach((dataKey) => {
            while ((tracker = this.dataEditService.apiRegEx.exec(apiData.compactData[dataKey])) !== null) {
                const entityId = apiData.entityIds[tracker.groups.entityIndex];
                if (!entityData[entityId]) {
                    entityData[entityId] = [];
                }

                let dataValue = 0;
                let flagged = false;
                let edited = false;
                let quality = 15; // Default for good (if no quality exists)
                let origValue: number;

                if (tracker.groups.valueString) {
                    const valueStringRes = tracker.groups.valueString.split(',');
                    dataValue = +valueStringRes[0];
                    quality = +valueStringRes[1];
                    flagged = +valueStringRes[2] === 1;
                    edited = +valueStringRes[2] === 2;
                } else {
                    // No value string, value only
                    dataValue = +tracker.groups.value;
                }

                if (tracker.groups.optionsString) {
                    const optionStringRes = tracker.groups.optionsString.split(',');
                    edited = true;
                    origValue = +optionStringRes[1];
                } else {
                    // No options string, flag marker only
                    flagged = +tracker.groups.flagged === 1;
                }

                const dataEntry = {
                    x: +dataKey * 1000,
                    y: dataValue, // We show edited data as separate series so don't track here
                    correctedY: dataValue,
                    quality: quality,
                    flagged: flagged,
                };

                if (!edited) {
                    delete dataEntry.correctedY;
                }
                entityData[entityId].push(dataEntry);

                if (origValue && !entityData[entityId + '_orig']) {
                    entityData[entityId + '_orig'] = [];
                }
                if (origValue) {
                    entityData[entityId + '_orig'].push({
                        x: +dataKey * 1000,
                        y: origValue,
                    });
                }

                dataMinDate = isNaN(dataMinDate) ? +dataKey : Math.min(dataMinDate, +dataKey);
                dataMaxDate = isNaN(dataMaxDate) ? +dataKey : Math.max(dataMaxDate, +dataKey);
            }
        });

        // Create new data structure that hydrograph needs
        let entitiesData: BasicSeriesData[] = [];
        let siltData = undefined;
        apiData.displayGroups.forEach((group) => {
            group.entities.forEach((entity) => {
                const databaseEntity = this.overallEntityGroupingInformation.find(
                    (x) => x.entities.filter((y) => y.id === Math.abs(entity.id)).length > 0,
                );

                if (databaseEntity) {
                    // Check for database entity to ensure entity is valid for device type
                    let entityName = entity.name;
                    if (entity.id < 0) {
                        const databaseEntitys = this.overallEntityGroupingInformation.find(
                            (x) => x.entities.filter((y) => y.id === -entity.id).length > 0,
                        );
                        const entityType: string = DataType[databaseEntitys.precendence].toLowerCase();
                        entityName = this.confirmationText[entityType];
                    }

                    if (entityName === 'Depth' || entityName === 'Rain') {
                        this.unit = { unit: group.unit };
                    }

                    let groupedData: BasicSeriesData = {
                        entityName: entityName,
                        axisName: group.label,
                        unitOfMeasure: group.unit,
                        displayGroupId: group.id,
                        entityId: entity.id,
                        color: entity.id < 0 ? '#eb5910' : entity.color,
                        precision: group.precision,
                        data: entityData[entity.id],
                        dataType: databaseEntity.precendence,
                        annotations: [],
                    };

                    if (entity.id > 0 && group.annotations && group.annotations.length > 0) {
                        group.annotations.forEach((annot) => {
                            groupedData.annotations.push({
                                name: annot.name,
                                id: annot.id,
                                color: annot.color,
                                data: annotationData[annot.id],
                                unitOfMeasure: group.unit,
                                precision: group.precision,
                            });
                        });
                    }
                    entitiesData.push(groupedData);

                    if (entity.id > 0 && entityData[entity.id + '_orig']) {
                        groupedData = {
                            entityName: entityName + ' (Orig)',
                            axisName: group.label,
                            unitOfMeasure: group.unit,
                            displayGroupId: group.id,
                            entityId: -(entity.id + 0.1),
                            color: entity.color,
                            precision: group.precision,
                            data: entityData[entity.id + '_orig'],
                            dataType: entity.id > 0 ? databaseEntity.precendence : DataType.Annotation,
                            annotations: [],
                        };
                        entitiesData.push(groupedData);
                    } else if (entity.id === SILT_ENTITY) {
                        siltData = {
                            name: entity.name,
                            id: entity.id,
                            color: entity.color,
                            data: entityData[entity.id],
                            unitOfMeasure: group.unit,
                            precision: group.precision,
                        };
                    }
                }
            });
        });

        if (siltData && entitiesData.filter((x) => x.dataType === DataType.Depth).length > 0) {
            const depthEntity = entitiesData.filter((x) => x.dataType === DataType.Depth)[0];
            depthEntity.annotations.push(siltData);
            entitiesData = entitiesData.filter((x) => x.entityId !== SILT_ENTITY);
        }

        // Because we can't trust 'this.startDate' or the data min date for the graph actual extents...
        // Plus a shim to add 0 value points to start and end of 1 data series to ensure correct range on graph
        // Seems to be a highcharts issue, not an issue with how xAxis is created for the graph
        const tzOffset = this.startDate.getTimezoneOffset() * 60 * 1000; // in ms
        const graphStart = this.startDate.getTime() - tzOffset;
        const graphEnd = this.endDate.getTime() - tzOffset;

        if (entitiesData[0] && entitiesData[0].data && dataMinDate * 1000 !== graphStart && entitiesData.length > 0) {
            entitiesData[0].data.unshift({ x: graphStart, y: undefined, flagged: false });
        }
        if (entitiesData[0] && entitiesData[0].data && dataMaxDate * 1000 !== graphEnd && entitiesData.length > 0) {
            entitiesData[0].data.push({ x: graphEnd, y: undefined, flagged: false });
        }
        this.newHydroDates = [graphStart, graphEnd];

        this.shipDataToNEWHydrograph(entitiesData);
    }

    private shipDataToNEWHydrograph(entityData: BasicSeriesData[]) {
        this.missingParameters = '';
        this.missingRain = false;
        this.missingLevel = false;

        // This is all temporary, will allow ease of custom axis selection in future
        // Convert from desired IDs not desired data types for correct axes
        const axesToGraph = [];
        this.selectedEntities.forEach((x) => {
            const data = entityData.find((y) => y.entityId === x.id);

            if (!data || !data.data) {
                // This isn't temporary but easiest to include in here for now
                if (x.id === RAIN_ENTITY) {
                    this.missingRain = true;
                } else if (x.id === LEVEL_ENTITY) {
                    this.missingLevel = true;
                } else {
                    // Fix for #21956
                    this.missingParameters = this.missingParameters.length > 0 ? this.missingParameters.concat(', ' + x.name) : x.name;
                }

                if (entityData.filter((y) => x.id === Math.abs(y.entityId)).length !== 0) {
                    const entityInformation = this.overallEntityGroupingInformation.find((y) =>
                        y.entities.some((z) => z.id === Math.abs(x.id)),
                    );
                    axesToGraph.push({
                        dataType: entityInformation.precendence,
                        entityId: Math.abs(x.id),
                    });
                }
            } else {
                const entityInformation = this.overallEntityGroupingInformation.find((y) =>
                    y.entities.some((z) => z.id === x.id),
                );
                axesToGraph.push({
                    dataType: entityInformation.precendence,
                    entityId: x.id,
                });
            }
        });

        this.entityGroupingsHydroNEW = [[], [], [], []];

        axesToGraph
            .filter((x) => x.dataType === DataType.Depth)
            .forEach((x) => this.entityGroupingsHydroNEW[0].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.Velocity)
            .forEach((x) => this.entityGroupingsHydroNEW[0].push(x.entityId));

        axesToGraph
            .filter((x) => x.dataType === DataType.Quantity)
            .forEach((x) => this.entityGroupingsHydroNEW[1].push(x.entityId));
        // Fix for #21956
        if (!this.missingRain)
            axesToGraph
                .filter((x) => x.dataType === DataType.Rain)
                .forEach((x) => this.entityGroupingsHydroNEW[1].push(x.entityId));

        axesToGraph
            .filter((x) => x.dataType === DataType.Temperature)
            .forEach((x) => this.entityGroupingsHydroNEW[2].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.Voltage)
            .forEach((x) => this.entityGroupingsHydroNEW[2].push(x.entityId));

        axesToGraph
            .filter((x) => x.dataType === DataType.PumpFlow)
            .forEach((x) => this.entityGroupingsHydroNEW[3].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.RainIntensity)
            .forEach((x) => this.entityGroupingsHydroNEW[3].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.ElapsedTime)
            .forEach((x) => this.entityGroupingsHydroNEW[3].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.Feet)
            .forEach((x) => this.entityGroupingsHydroNEW[3].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.Sample)
            .forEach((x) => this.entityGroupingsHydroNEW[3].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.TotalFlow)
            .forEach((x) => this.entityGroupingsHydroNEW[3].push(x.entityId));
        axesToGraph
            .filter((x) => x.dataType === DataType.Other)
            .forEach((x) => this.entityGroupingsHydroNEW[3].push(x.entityId));
        // End temporary code area

        this.dataEditService.newHydroData$.next(entityData);
        this.newHydroBackupData = cloneDeep(entityData); // Immutable clone
        Object.seal(this.newHydroBackupData);

        this.setGraphObject();

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public detectChanges() {
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public setGraphObject() {
        this.fetchGraphSub?.unsubscribe();

        // TODO: LC: Issue with depth going to zero
        this.fetchGraphSub = combineLatest([
            this.dataEditService.newHydroData$,
            this.statusCodeService.userInfoThemeBS
        ])
        .subscribe(([seriesData, darkTheme]) => {
            if(this.lcChart) {
                this.lcChart.destroy();
                this.lcChart = null;
            }

            const config: LightningChartBuildDataConfig = {
                graphGrouping: this.entityGroupingsHydroNEW,
                annotationOptions: {} as AnnotationOptions,
                seriesData: seriesData,
                events: [],
                locationId: this.locationId,
                showAllMarkers: false,
                selectedDateRange: this.newHydroDates,
                startDate: this.startDate,
                endDate: this.endDate,

                lightningChartReceiver: this,
                seriesData$:  this.dataEditService.newHydroData$,
                chartId: `${this.chartId}`,
                allowEditMode: false,
                userSettings: null,
                annotationSettings: this.annotationSettings,
                showEditMenu: () => false,
                darkTheme: darkTheme
            }

            this.lcChart = this.lightningChartBuilder.buildChart(config);

            this.hydroGraphLoading$.next(false);
        });
    }

    public redrawHydrographGraph() {
        // Still used for resizing when parent box changes size

        // Force it to run after screen adjustments are made, and force view to refresh
        setTimeout(() => {
            if (
                this.viewDataService.advancedCharts &&
                this.viewDataService.advancedCharts.length > 0 &&
                this.viewDataService.advancedCharts[0]
            ) {
                if(this.viewDataService.advancedCharts[0].reflow)
                    this.viewDataService.advancedCharts[0].reflow()

                this.uiUtilsService.safeChangeDetection(this.cdr);
            }

        }, 0);

    }

    private getScatterGraphData(filters: LocationDashboardFilterData): void {
        // If entities aren't available hide hydrograph
        const location = this.locationEntityData.l.find((x) => x.lid === this.locationId);
        const d = new Date();
        d.setDate(d.getDate() - 7);

        if (!location || !this.isOpened) {
            return;
        }

        const monitorType = location.s.toLowerCase();
        let entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === monitorType);
        if (!entityGroups) {
            entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === OTHERTEXT);
        }

        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.getScatterGraphData(filters);
        }

        if (!this.selectedSGEntityIds || !this.selectedSGEntityIds.length || this.selectedSGEntityIds.length < 2)
            return;
        // SG should display for USGS locations
        if (this.usgs) return;

        // Otherwise do API call and load scatter
        this.hideScatterGraph = false;

        const tempFlags = [];
        this.subscriptions.push(
            this.viewDataService
                .getScatterGraphOptimized(
                    this.customerID,
                    this.locationId,
                    d,
                    new Date(),
                    filters.annotationsSettings.isPipeOverlay ? true : false,
                    this.annotationSettings.isPipeHeight,
                    this.annotationSettings.isManholeDepth,
                    this.annotationSettings.isIsoQ,
                    this.annotationSettings.isBestFit,
                    this.annotationSettings.isSSCurve,
                    this.annotationSettings.isCWRcurve,
                    this.annotationSettings.isManningDesign,
                    this.annotationSettings.isLanfearColl,
                    this.annotationSettings.isFroude,
                    tempFlags,
                    this.scatterGraphConfirmationData,
                    [...this.selectedSGEntityIds].reverse(),
                    filters.summarizeInterval,
                )
                .subscribe(
                    (scatterResult: ScatterData) => {
                        if (!scatterResult) {
                            /** Handling in case we have 204 No content in Response */
                            this.scattergraphLoading = false;
                            this.scatterGraphData = null;
                            this.noDataForScatterChart = true;

                            this.uiUtilsService.safeChangeDetection(this.cdr);

                            return;
                        }
                        this.enableHeaderButtons = false;

                        this.scatterGraphData = <ScatterData>scatterResult;
                        this.scattergraphLoading = false;
                        this.noDataForScatterChart = false;
                    },
                    (error) => {
                        this.scattergraphLoading = false;
                        this.scatterGraphData = null;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                ),
        );
    }

    public onScatterResponse(response: ScatterData) {
        if (!response) {
            /** Handling in case we have 204 No content in Response */
            this.scattergraphLoading = false;
            this.scatterGraphData = null;
            this.noDataForScatterChart = true;

            this.uiUtilsService.safeChangeDetection(this.cdr);

            return;
        }

        this.enableHeaderButtons = true;

        this.scatterGraphData = <ScatterData>response;
        this.scattergraphLoading = false;
        this.noDataForScatterChart = false;
    }

    public onScatterConfirmationDataChange(confirmationData) {
        const { type, dateRange } = confirmationData;
        this.scatterGraphConfirmationData = { type, dateRange };
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public notifyGraph({
        filtersData,
        enableAcceptButtonEditor,
        enableAcceptButtonNoReason,
        reloadHg = false,
        reloadSg = false,
    }: {
        filtersData: LocationDashboardFilterData;
        reloadHg?: boolean;
        reloadSg?: boolean;
        enableAcceptButtonEditor?: boolean;
        enableAcceptButtonNoReason?: boolean;
    }) {
        if (!filtersData) {
            return;
        }

        // #41057 Copied from LD. #41159 Should be refactored.
        if (!this.prevFiltersData || !Object.keys(this.prevFiltersData).length) {
            reloadHg = true;
            reloadSg = true;
        }

        let netAnnotations = filtersData.annotationsSettings;
        if (this.annotationSettings) {
            // Take some entities from annotation settings and some from filters data
            netAnnotations = {
                ...netAnnotations,
                ...this.annotationSettings,
                isSilt: filtersData.annotationsSettings.isSilt,
            }; // Because silt is special...
        }

        // Before changing anything track what has changed for handling API calls
        const prevEntities =
            this.prevFiltersData && this.prevFiltersData.entities && this.prevFiltersData.entities.map((x) => x.id);
        const newEntities = filtersData.entities && filtersData.entities.map((x) => x.id);
        let onlyEntitiesChanged = true;
        if (this.prevFiltersData) {
            for (const key in filtersData) {
                if (
                    !isEqual(this.prevFiltersData[key], filtersData[key]) &&
                    key !== 'entities' &&
                    (key !== 'endDate' || this.endDate.getTime() !== filtersData.endDate.getTime())
                ) {
                    // Because end date gets screwed with elsewhere...
                    onlyEntitiesChanged = false;
                }
            }
        }
        this.prevFiltersData = filtersData;

        this.filterSettings.isDisplayDatePicker = false;
        this.filterSelectedValues = filtersData;
        this.enableHeaderButtons = false;
        this.selectedEntities = filtersData.entities;
        this.isBasicDataEditingAllowed = false;
        this.locIndex = 0;
        if (filtersData && filtersData.isResetScale) {
            this.scattergraphLoading = true;
        }

        // For scatter
        this.scatterDateFormat = this.dateutilService.getGraphDateFormat();

        if (this.annotationSettings) {
            Object.keys(netAnnotations).forEach((v) => (this.annotationSettings[v] = netAnnotations[v]));
        } else {
            this.annotationSettings = { ...netAnnotations };
        }
        // this.locationName = filtersData.locationName;

        const d = new Date();
        d.setHours(0, 0, 0, 0);
        this.locationId = filtersData.locationIDs[0] ? filtersData.locationIDs[0] : -1;
        this.startDate = new Date(d.setDate(d.getDate() - 7));
        this.endDate = new Date();
        this.isCompLoc = filtersData.isCompositeLoc;

        // this.locString.push(this.locationName);
        this.getHydrographParams = <HydrographArgs>{
            start: this.startDate,
            end: this.endDate,
            entityIds: filtersData.entities.map((x) => x.id),
            locationId: filtersData.locationIDs[0] ? filtersData.locationIDs[0] : -1,
            summarizeInterval: 0,
        };

        this.viewDataService.filterValues.next(filtersData);

        // If previous desired entity IDs are same or more than new desired
        // no reason for new API call, just eliminate previous data from graph
        if (
            prevEntities &&
            prevEntities.length >= newEntities.length &&
            onlyEntitiesChanged &&
            !reloadHg &&
            !reloadSg
        ) {
            // Take backup of the data, remove unwanted entities
            if (this.newHydroBackupData) {
                this.newHydroBackupData = this.newHydroBackupData.filter((x) => newEntities.includes(x.entityId));

                //Remove any annotations that shouldn't be on graph
                // Since annotations weren't implemented cleanly and don't have annotation IDs to rely on...
                let ind: number;
                if (!this.annotationSettings.isManholeDepth) {
                    ind = this.newHydroBackupData.findIndex(
                        (x) => x.annotations.findIndex((y) => y.id === MANHOLE_DEPTH_ANNOTATION) !== -1,
                    );
                    if (ind !== -1)
                        this.newHydroBackupData[ind] = {
                            ...this.newHydroBackupData[ind],
                            annotations: this.newHydroBackupData[ind].annotations.filter(
                                (x) => x.id !== MANHOLE_DEPTH_ANNOTATION,
                            ),
                        };
                }
                if (!this.annotationSettings.isPipeHeight) {
                    ind = this.newHydroBackupData.findIndex(
                        (x) => x.annotations.findIndex((y) => y.id === PIPE_HEIGHT_ANNOTATION) !== -1,
                    );
                    if (ind !== -1)
                        this.newHydroBackupData[ind] = {
                            ...this.newHydroBackupData[ind],
                            annotations: this.newHydroBackupData[ind].annotations.filter(
                                (x) => x.id !== PIPE_HEIGHT_ANNOTATION,
                            ),
                        };
                }
                if (!this.annotationSettings.isSilt) {
                    ind = this.newHydroBackupData.findIndex(
                        (x) => x.annotations.findIndex((y) => y.id === SILT_ENTITY) !== -1,
                    );
                    if (ind !== -1)
                        this.newHydroBackupData[ind] = {
                            ...this.newHydroBackupData[ind],
                            annotations: this.newHydroBackupData[ind].annotations.filter((x) => x.id !== SILT_ENTITY),
                        };
                }

                Object.seal(this.newHydroBackupData);
                this.shipDataToNEWHydrograph(this.newHydroBackupData);
            }

            // Only need to call API related to scatter if scatter entities are affected
            // Right now scatter is hard coded for UniDepth and Velocity
            if (!newEntities.includes(DEPTH_ENTITY) || !newEntities.includes(VELOCITY_ENTITY)) {
                this.getScatterGraphData(filtersData);
            } else {
                // TODO: need to remove annotations from scatter without API call
                // Something similar to this but this.scatterGraphData doesn't seem to have the annotations included?
            }
        } else {
            // Entities changed or other inputs changed
            // we have to call safeChangeDetection so that dataEditingTableComponent gets its inputs.
            // TODO: refactor
            if (reloadHg) {
                this.getHydroGraphData(this.getHydrographParams);
            }
            if (reloadSg) {
                this.getScatterGraphData(filtersData);
            }

            if (!reloadHg && !reloadSg) {
                this.getHydroGraphData(this.getHydrographParams);
                this.getScatterGraphData(filtersData);
            }
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public scatterEntityBlur() {
        this.getScatterGraphData(this.viewDataService.filterValues.getValue());
    }

    public onScatterEntitiesChange(selectedEntityIds: number[]) {
        this.selectedSGEntityIds = selectedEntityIds;
        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.selectedEntityIds = this.selectedSGEntityIds;
        }
    }

    public hideScattergraphs() {
        this.scatterGraphData = null;
        this.toggleHydroGraph(true);
        this.toggleScatterGraph(false);
        this.noDataForScatterChart = true;
        this.hideScatterGraph = true;
    }

    public toggleHydroGraph(expanded: boolean, handToogleHydrograph?: boolean) {
        // True for expand, false for collapse
        if (expanded) {
            this.showScatterGraph = false;
        } else {
            this.showScatterGraph = true;
            this.redrawScatterGraph();
        }

        if (handToogleHydrograph !== undefined) {
            if (handToogleHydrograph) {
                this.handToogleHydrograph = true;
            } else {
                this.handToogleHydrograph = undefined;
            }
        }

        this.redrawHydrographGraph();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public toggleScatterGraph(expanded: boolean) {
        // True for expand, false for collapse
        if (expanded) {
            this.showHydroGraph = false;
        } else {
            this.showHydroGraph = true;
            this.redrawHydrographGraph();
        }

        this.redrawScatterGraph();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public redrawScatterGraph() {
        if (
            this.viewDataService.advancedCharts &&
            this.viewDataService.advancedCharts.length > 1 &&
            this.viewDataService.advancedCharts[1]
        ) {
            // Force it to run after screen adjustments are made, and force view to refresh
            setTimeout(() => this.viewDataService.advancedCharts[1].reflow(), 0);
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    private getPipeVolume(): Observable<PercentFullData> {
        const parameters = <PercentFullReportArgs>{
            CustomerId: this.customerID,
            LocationIds: [this.locationId],
            Start: [
                this.pipeStartDate.getMonth() + 1,
                this.pipeStartDate.getDate(),
                this.pipeStartDate.getFullYear(),
            ].join('-'),
            End: [
                this.pipeEndDate.getMonth() + 1,
                this.pipeEndDate.getDate(),
                this.pipeEndDate.getFullYear(),
            ].join('-'),
        };

        return this.percentFullReportService.getPercentFullReport(parameters, false);
    }

    public drawPipe() {
        if (!this.customerID || !this.locationId) return;

        this.noPipeVolumeData = true;
        this.pipeVolumePercent = null;

        combineLatest([
            this.locationService.locationCosmoDetails(this.customerID, this.locationId),
            this.unitsService.getUnitsCategories(this.customerID, [UnitCategoryType.Linear, UnitCategoryType.LinearFeet, UnitCategoryType.Area]),
            this.getPipeVolume(),
            this.statusCodeService.userInfoThemeBS
        ]).subscribe(([res, units, percentFull, isDarkTheme]) => {
            this.pipeVolumePercent = this.drawPipeHelper.averageVolumeData(percentFull);
            this.noPipeVolumeData = this.drawPipeHelper.isNoVolumeData(percentFull);

            this.pipeDetails = this.drawPipeHelper.drawPipe(this.pipeCanvas, res, units, isDarkTheme, percentFull);
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    public pipeDateRangeClosed() {
        this.preventUnpinnedClose.emit(false);
        this.drawPipe();
    }

    public pipeDateRangeOpened() {
        this.preventUnpinnedClose.emit(true);
    }

    public pipeUpdateDateChange() {
        this.drawPipe();
    }
}
