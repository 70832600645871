import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationDetails } from 'app/shared/models/location-details';
import { LocationStatus } from 'app/shared/models/locations';
import { MonitorSeriesNames } from '../location-card/location-card.constants';
import { INSTALLATION_TYPE } from 'app/shared/models/location-details';
import { LocationService } from 'app/shared/services/location.service';
import { RAIN_DEFAULT_INSTALLATION_TYPE } from 'app/shared/constant';
import { AlarmService } from 'app/shared/services/alarm.service';
import { GetAlarmsArgs } from 'app/shared/models/alarms';
import { ActiveAlarm } from 'app/shared/models/active-alarm';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';


const CHANNEL_STANDARD_VALUE = 'Channel';

@Component({
    selector: 'app-site-name-popover',
    templateUrl: './site-name-popover.component.html',
    styleUrls: ['./site-name-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SiteNamePopoverComponent implements OnInit, OnChanges {
    @Input() details: LocationDetails;
    @Input() isMetric: boolean;

    @Input() customerId: number;
    @Input() locationGroupID: number;
    @Input() includeInactiveLocations: boolean;

    public alarms: ActiveAlarm[] = [];
    public currentAlarm: ActiveAlarm;

    public installationDetails: string;

    public seriesEnum = MonitorSeriesNames;
    public locationStatusEnum = LocationStatus;

    public INSTALLATION_TYPE = INSTALLATION_TYPE;

    public pipeHeightPlaceHolder: string;
    public pipeWidthPlaceHolder: string;
    public flumeRangePlaceHolder: string;
    public capacityPlaceHolder: string;
    public widthPlaceHolder: string;
    public heightPlaceHolder: string;
    public referencePointPlaceHolder: string;
    public datumPlaceHolder: string;
    public physicalOffsetPlaceHolder: string;
    public lengthPlaceHolder: string;
    public breadthPlaceHolder: string;
    public quantityPlaceHolder: string;


    public channelHeight: number = null;
    public referencePoint: number = null;

    public depthDecimal: string;
    public flowDecimal: string;
    constructor(
        private translate: TranslateService,
        private locationService: LocationService,
        private alarmService: AlarmService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService
    ) { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.details || changes.isMetric) {
            this.onDetailChange();
        }

        if (changes.customerId || changes.locationGroupID || changes.includeInactiveLocations) {
            this.getAlarms();
        }
    }

    onDetailChange() {
        this.currentAlarm = this.alarms.find(v => v.locationID === this.details.locationID);

        this.getTranslations();

        this.installationDetails =
            this.details.installationType + ': ' + this.details.installationShape;
        if (this.details.installationType === this.translate.instant('LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.RAIN_GAUGE')) {
            this.installationDetails = RAIN_DEFAULT_INSTALLATION_TYPE;
        } else if (
            this.details.installationType &&
            this.details &&
            this.details.installationShapeTypeID > 0
        ) {
            this.installationDetails = '';
        }

        // Logic coming from the triton-basic-info file around line 503
        // that if the series is foreSITE, hard code channel: channel standard
        if (this.details.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || this.details.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD) {
            if (this.details.installationType === INSTALLATION_TYPE.ELEVATION) {
                this.installationDetails = this.translate.instant('LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ELEVATION')
            }
            else {
                this.installationDetails = this.translate.instant('LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.CHANNEL_STANDARD_FORESITE');
            }

            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else if (this.details.series === MonitorSeriesNames.usgs) {
            this.installationDetails = this.translate.instant('COMMON.TEST_USGS');

            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            const instalationTypeUI = this.locationService.getInstallationType();
            instalationTypeUI.forEach((installationList) => {
                installationList.forEach((installationItem) => {
                    const colonIndex = installationItem.value.indexOf(':');
                    const newInstallationId = Number(installationItem.value.substr(colonIndex + 2));
                    if (newInstallationId === Number(this.details.installationShapeTypeID)) {
                        this.installationDetails = installationItem.text;
                    }
                });

                if (this.installationDetails.includes(CHANNEL_STANDARD_VALUE) && this.details.entries && this.details.entries) {
                    this.channelHeight = Math.max(...this.details.entries.map(v => v.depth));
                } else {
                    this.channelHeight = null;
                }
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });
        }
    }

    get series() {
        return this.details ? this.details.series : '';
    }

    private getAlarms() {
        const args = <GetAlarmsArgs>{
            customerId: this.customerId,
            LocationGroupID: this.locationGroupID || 0,
            Active: true,
            Acknowledged: true,
            IncludeInactiveLocations: this.includeInactiveLocations || false,
        };

        this.alarmService.getAlarms(args).subscribe((data: ActiveAlarm[]) => {
            this.alarms = data || [];

            this.currentAlarm = this.alarms.find(v => v.locationID === this.details.locationID);

            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    private getTranslations() {
        const keys: string[] = [
            'HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_HEIGHT',
            'HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_WIDTH',
            'HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER',
            'HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER',
            'HOME.MAP.MARKER_LOCATION_DETAIL.WIDTH',
            'HOME.MAP.MARKER_LOCATION_DETAIL.LENGTH',
            'HOME.MAP.MARKER_LOCATION_DETAIL.BREADTH',
            'HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY',
            'HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS',
            'HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS',
            'HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_TRACER_UNITS',
            'HOME.MAP.MARKER_LOCATION_DETAIL.MGD_TRACER_UNITS',
            'HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT',
            'HOME.MAP.MARKER_LOCATION_DETAIL.REFERENCE_POINT',
            'HOME.MAP.MARKER_LOCATION_DETAIL.DATUM',
            'HOME.MAP.MARKER_LOCATION_DETAIL.PHYSICAL_OFFSET',
            'HOME.MAP.MARKER_LOCATION_DETAIL.FEET_UNITS',
            'HOME.MAP.MARKER_LOCATION_DETAIL.METER_UNITS'
        ];


        this.translate.get(keys).subscribe((values) => {
            const metricUnit = values['HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS'];
            const usUnit = values['HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS'];

            const metricFlow = values['HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_TRACER_UNITS'];
            const usFlow = values['HOME.MAP.MARKER_LOCATION_DETAIL.MGD_TRACER_UNITS'];

            const elevationMetricUnits = values['HOME.MAP.MARKER_LOCATION_DETAIL.METER_UNITS'];
            const elevationStandardUnits = values['HOME.MAP.MARKER_LOCATION_DETAIL.FEET_UNITS'];

            this.depthDecimal = this.isMetric ? '1.1-1' : '1.2-2';
            this.flowDecimal = this.isMetric ?  '1.0-0' : '1.3-3';

            const depthUnit = this.isMetric ? metricUnit : usUnit;
            const flowUnit = this.isMetric ? metricFlow : usFlow;
            const elevationUnit = this.isMetric ? elevationMetricUnits : elevationStandardUnits;

            this.pipeWidthPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_WIDTH'] + ' (' + depthUnit + ')';
            this.pipeHeightPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_HEIGHT'] + ' (' + depthUnit + ')';
            this.flumeRangePlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER'] + ' (' + depthUnit + ')';
            this.widthPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.WIDTH'] + ' (' + depthUnit + ')';
            this.heightPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT'] + ' (' + depthUnit + ')';
            this.referencePointPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.REFERENCE_POINT'] + ' (' + elevationUnit + ')';
            this.datumPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.DATUM'];
            this.physicalOffsetPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.PHYSICAL_OFFSET'] + ' (' + elevationUnit + ')';
            this.lengthPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.LENGTH'] + ' (' + depthUnit + ')';
            this.breadthPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.BREADTH'] + ' (' + depthUnit + ')';
            this.capacityPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER'] + ' (' + flowUnit + ')';
            this.quantityPlaceHolder = values['HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY'];
        })
    }
}
