<div class="custom-location-dashboard  contentLayout custom-dashboard-print-content">
  <app-loader [isLoading]="isLoading"></app-loader>
  <!-- mulitilocation header-->
  <div class="location-details border-radius-5 mat-box-shadow">
    <div class="auto-margin pad-l-15">{{'DASHBOARDS.MULTI_LOCATION_DASHBOARD.CUSTOM_DASHBOARD' | translate}}</div>
    <div class="app-flex-filler"></div>
    <div class="auto-margin custom-flat-button dont-print">
      <button
        mat-button
        [disabled]="!showGraph"
        (click)="generatePDF();"
      >
        <mat-icon class="cd-print-hint">print</mat-icon>{{'COMMON.PRINT_TEXT' | translate}}
      </button>
      <button
        mat-button
        (click)="openCustomDashboardModal()"
      >
        <mat-icon class="cd-add-hint">add</mat-icon>{{'DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_GRAPH' | translate}}
      </button>
    </div>
  </div>
  <!-- multilocations content -->
  <div
    class="multi-locations"
    *ngIf="showGraph"
  >
    <div class="flex-container  block-print custom-dash">
      <app-loader [isLoading]="isLoading"></app-loader>
      <ng-container *ngFor="let graphObj of graphsObject; let i = index; trackBy: trackByIndex">
        <div
          [class.graph-full-width]="graphObj.isExpand || graphsObject.length === 1"
          [class.graph-width]="!graphObj.isExpand && graphsObject.length > 1"
          class="graph-background mat-box-shadow print-page-break print-full-width"
        >
          <div class="location-details">
            <div class="auto-margin pad-l-15">{{graphObj.name}} :<span class="font-14">
                {{dateutilService.getUTCDateFromString(graphObj.headerDates[0]) | date: customerDateFormat: dateFormat}}
                to
                {{dateutilService.getUTCDateFromString(graphObj.headerDates[1]) | date: customerDateFormat: dateFormat}}
            </span> </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button width-100 dont-print">
              <mat-icon
                class="backIcon cd-full-hint"
                *ngIf="!graphObj.isExpand && graphsObject.length > 1"
                (click)="expandGraph(graphObj.graphId)"
                matTooltip="{{'COMMON.FULLSCREEN_TOOLTIP' | translate}}"
              >
                fullscreen</mat-icon>
              <!-- -->
              <mat-icon
                class="backIcon cd-full-hint"
                *ngIf="graphObj.isExpand"
                (click)="expandGraph(graphObj.graphId)"
                matTooltip="{{'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate}}"
              >fullscreen_exit</mat-icon>
              <!---->
              <mat-icon
                class="backIcon cd-edit-hint"
                matTooltip="{{'COMMON.EDIT_TOOLTIP' | translate}}"
                (click)="editGraph(graphObj.graphId,  graphObj.index)"
              >edit</mat-icon>
              <mat-icon
                class="backIcon cd-delete-hint"
                matTooltip="{{'COMMON.CLOSE' | translate}}"
                (click)="removeGraph(graphObj.graphId)"
              >close</mat-icon>
            </div>
          </div>
          <div
            *ngIf="graphObj.graphType === GraphType.Hydrograph"
            (mouseleave)="mouseOutFromHydro()"
          >
            <div *ngIf="graphObj.inactiveLocation" class="no-data-found-section">
                <div class="no-data-content">
                    <div class="no-data-found-img"></div>
                    <div class="no-data-found-text custom-header">
                        <span> {{ "COMMON.INACTIVE_LOCATION_ERROR" | translate }}</span>
                    </div>
                  </div>
            </div>
            <ng-container *ngIf="!graphObj.inactiveLocation">
                <div *ngFor="let ndata of graphObj.noData; trackBy: trackByIndex" class="center">
                  <span *ngIf="ndata.location"> {{ "COMMON.NO_DATA_FOUND_FOR" | translate }} {{ndata.location}} {{ndata.eidXName}}</span>
                </div>
            </ng-container>
            <div
              class="no-data-found-section"
              *ngIf="!graphObj.inactiveLocation && !graphObj.isLoading && (!graphObj.config?.series?.length)"
            >
              <div class="no-data-content">
                <div class="no-data-found-img"></div>
                <div class="no-data-found-text custom-header">
                  <span class="header-text">{{ "COMMON.NO_RESULTS_FOUND" | translate }}</span>
                  <span class="sub-header-text">{{ "COMMON.NO_RESULTS_FOUND_SUB_TEXT1" | translate }} <br />
                    {{ "COMMON.NO_RESULTS_FOUND_SUB_TEXT2" | translate }}</span>
                </div>
              </div>
            </div>

            <app-hydrograph-tracer-panel
                [hidden]="graphObj.inactiveLocation || graphObj.isLoading || !(graphObj.config?.series?.length)"
                (zoomButtonClicked)="graphObj.chart.zoomButtonClicked($event)"
                (tracerButtonClicked)="graphObj.chart.tracerButtonClicked($event)"
            ></app-hydrograph-tracer-panel>
            <div [id]="i" class="lc-chart" [hidden]="graphObj.inactiveLocation || graphObj.isLoading || !(graphObj.config?.series?.length)"></div>
            <app-hydrograph-legend
                [hidden]="graphObj.inactiveLocation || graphObj.isLoading || !(graphObj.config?.series?.length)"
                [legendSeriesDefinition]="graphObj.chart?.legendSeriesDefinition"
                (serieVisibility)="graphObj.chart?.updateSeriesVisibility($event)"
            ></app-hydrograph-legend>
          </div>
          <div
            *ngIf="graphObj.graphType === GraphType.Scattergraph"
            class="scattergraphGraphDetailsContainer"
          >
            <app-advance-scattergraph
              *ngIf="!graphObj.inactiveLocation"
              [class.hide]="(graphObj.isLoading | async)"
              class="test"
              [data]="graphObj.data"
              [isDataEditingModeEnabled]="false"
              [tooltipTimeFormat]="tooltipTimeFormat"
              [scatterDateFormat]="scatterDateFormat"
              [annotationSettings]="{}"
              (setLoading)="setLoading($event)"
              [locationId]="locationId"
              [toggleChartReflow]="reflowChart"
              [allowHighlight]="false"
              [advanceScatterId]="'advance-scattergraph-chart' + graphObj.graphId"
            >
            </app-advance-scattergraph>

            <div *ngIf="graphObj.inactiveLocation" class="no-data-found-section">
                <div class="no-data-content">
                    <div class="no-data-found-img"></div>
                    <div class="no-data-found-text custom-header">
                        <span> {{ "COMMON.INACTIVE_LOCATION_ERROR" | translate }}</span>
                    </div>
                  </div>
            </div>

            <ng-container *ngIf="!graphObj.inactiveLocation">
                <div
                  *ngFor="let ndata of graphObj.noData; trackBy: trackByIndex"
                  class="center"
                >
                  <span *ngIf="ndata.location">{{ "COMMON.NO_DATA_FOUND_FOR" | translate }} {{ndata.location}} ({{ndata.eidXName}}\{{ndata.eidYName}})</span>
                </div>
            </ng-container>
            <div
              class="no-data-found-section"
              *ngIf="!graphObj.inactiveLocation && (graphObj.isLoading | async)"
            >
              <div class="no-data-content">
                <div class="no-data-found-img"></div>
                <div class="no-data-found-text custom-header">
                  <span class="header-text">{{ "COMMON.NO_RESULTS_FOUND" | translate }}</span>
                  <span class="sub-header-text">{{ "COMMON.NO_RESULTS_FOUND_SUB_TEXT1" | translate }} <br />
                    {{ "COMMON.NO_RESULTS_FOUND_SUB_TEXT2" | translate }}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
  <!-- empty dashboard -->
  <div
    class="multi-locations-add-new"
    *ngIf="!showGraph"
  >
    <div class="page">
      <img
        class="slicer_image"
        [ngSrc]="'assets/images/slicer_empty_dashboard_image.png'"
        alt="image"
        height="216"
        width="209"
      >
      <div class="page-content">
        <h3>{{'SLICER_PAGE.EMPTY' | translate}}</h3>
        <p class="lines"><b> {{'DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_GRAPH_CLICKING' | translate}}</b>
          &nbsp;
          <!-- force a space before the quote marks-->
          <q>
            <mat-icon class="icon-middle">add</mat-icon><b>{{'DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_GRAPH' |
              translate}}</b>
          </q>
        </p>
      </div>
    </div>
  </div>
</div>
