<mat-expansion-panel [ngClass]="{ 'alarms-config-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text" *ngIf="!isMp2">
            {{ 'ADD_EDIT.ADD_EDIT_CONFIGURE' | translate }}
        </mat-panel-title>
        <mat-panel-title class="input-text" *ngIf="isMp2">
            {{ 'COMMON.MP_CONFIGURE_ALARMS' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>
        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span></span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <div class="configure-alarms" [class.disable-field]="!isMonitorEditor">
            <div class="each-configure-alarm">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="lowLevelEnable"
                        (change)="onSlideChange($event, ['lowLevelThreshold'])"
                    >
                        {{ 'COMMON.LOW_LEVEL_ALARM' | translate }}
                    </mat-slide-toggle>
                </div>

                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            type="number"
                            [placeholder]="locationCardService.addDepthUnitToPlaceholder(('COMMON.DEPTH_THRESHOLD_OF' | translate) +
                                ('COMMON.LOW_LEVEL_ALARM' | translate), isElevationInstallation)"
                            id="Alarmlowlevelthreshold"
                            formControlName="lowLevelThreshold"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('lowLevelThreshold').errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="each-configure-alarm" *ngIf="monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="fullPipeEnable"
                        (change)="onSlideChange($event, ['fullPipethreshold'])"
                    >
                        {{ 'COMMON.FULL_PIPE_ALARM' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="locationCardService.addDepthUnitToPlaceholder(('COMMON.DEPTH_THRESHOLD_OF' | translate) +
                                ('COMMON.FULL_PIPE_ALARM' | translate))"
                            id="AlarmfullPipethreshold"
                            formControlName="fullPipethreshold"
                            readonly
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('fullPipethreshold').errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="each-configure-alarm">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="highLevelEnable"
                        (change)="onSlideChange($event, ['highLevelThreshold'])"
                    >
                        {{ 'COMMON.HIGH_LEVEL_ALARM' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="locationCardService.addDepthUnitToPlaceholder(('COMMON.DEPTH_THRESHOLD_OF' | translate) +
                                ('COMMON.HIGH_LEVEL_ALARM' | translate), isElevationInstallation)"
                            id="AlarmHighAlaramthreshold"
                            formControlName="highLevelThreshold"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('highLevelThreshold').errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="each-configure-alarm">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="highHighEnable"
                        (change)="onSlideChange($event, ['highHighThreshold'])"
                    >
                        {{ 'COMMON.HIGH_HIGH_ALARAM' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="locationCardService.addDepthUnitToPlaceholder(('COMMON.DEPTH_THRESHOLD_OF' | translate) +
                                ('COMMON.HIGH_HIGH_ALARAM' | translate), isElevationInstallation)"
                            id="Alarmhighhighthreshold"
                            formControlName="highHighThreshold"
                            type="number"
                            [pattern]="IsUnitMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('highHighThreshold').errors?.pattern && !IsUnitMetric">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                    <div class="mat-error" *ngIf="form.get('highHighThreshold').errors?.pattern && IsUnitMetric">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                    </div>
                </div>
            </div>

            <div class="each-configure-alarm"  *ngIf="monitorSeries === MonitorSeriesNames.triton">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="highFlowEnable"
                        (change)="onSlideChange($event, ['highFlowThreshold'])"
                    >
                        {{ 'COMMON.HIGH_FLOW_ALARM' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="('COMMON.QUANTITY_THRESHOLD_OF' | translate) + (flowUnit)"
                            id="AlarmhighFlowthreshold"
                            formControlName="highFlowThreshold"
                            type="number"
                            [pattern]="IsUnitMetric?numericWith1DecimalPlaces:negativeWithThreeDecimalPattern"
                        />
                    </mat-form-field>
                    <ng-container *ngIf="form.get(CONSTANTS.HIGH_FLOW_THRESHOLD).errors as errors">
                        <div class="mat-error" *ngIf="(errors.pattern || errors.min || errors.max) && !IsUnitMetric">
                            {{ 'COMMON.NUMERIC_PRECISION_ERROR_3_DECIMALS' | translate }}
                        </div>
                        <div class="mat-error" *ngIf="(errors.pattern || errors.min || errors.max) && IsUnitMetric">
                            {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="each-configure-alarm margin-t-b-15" *ngIf="monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="overflowEnable">
                        {{ 'COMMON.OVERFLOW_ALARMS' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item"></div>
            </div>

            <div class="each-configure-alarm" *ngIf="form.get('tiltThreshold') && monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="tiltEnable"
                        (change)="onSlideChange($event, ['tiltThreshold'])"
                    >
                        {{ 'COMMON.TILT' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'COMMON.TILT_THRESHOLD_INPUT_PLACEHOLDER' | translate }} (degree)"
                            id="tiltThreshold"
                            formControlName="tiltThreshold"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('tiltThreshold')?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="each-configure-alarm" *ngIf="!isMp2">
                <div class="each-alarm item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="batteryLowEnable"
                        (change)="onSlideChange($event, ['batteryLowThreshold'])"
                    >
                        {{ 'COMMON.BATTERY_LOW_ALARM' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'COMMON.BATTERY_THRESHOLD_INPUT_PLACEHOLDER' | translate }} (V)"
                            id="AlarmbatteryLowthreshold"
                            formControlName="batteryLowThreshold"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('batteryLowThreshold').errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>
            <div class="each-configure-alarm flow-loss-alaram" *ngIf="monitorSeries === 'TRITON+'">
                <div class="each-alarm item-flex-center flow-loss-alaram-toggle">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="flowLossAlarm"
                        (change)="
                            onSlideChange($event, ['flowLossType', 'percent', 'sensitivity', 'clearPatternHistory'])
                        "
                    >
                        {{ 'COMMON.FLOW_LOSS_ALARAM' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'COMMON.TYPE' | translate }}"
                            formControlName="flowLossType"
                            class="full-width"
                        >
                            <mat-option *ngFor="let type of flowLossAlarmTypeList; trackBy: trackById" [value]="type.id">
                                {{ type.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'COMMON.PERCENTAGE' | translate }}"
                            formControlName="percent"
                            class="full-width"
                        >
                            <mat-option *ngFor="let percentage of flowLossAlarmPercentageList; trackBy: trackById" [value]="percentage.id">
                                {{ percentage.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'COMMON.SENSITIVITY' | translate }}"
                            formControlName="sensitivity"
                            class="full-width"
                        >
                            <mat-option
                                *ngFor="let sensitivity of flowLossAlarmSensitivityList; trackBy: trackById"
                                [value]="sensitivity.id"
                            >
                                {{ sensitivity.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item item-flex-center reset-pattern-history-toggle">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="clearPatternHistory">
                        {{ 'COMMON.RESET_PATTERN_HISTORY_ON_ACTIVATION' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </form>
</mat-expansion-panel>
