import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    EventEmitter,
    Output,
    HostListener,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DataTableHeader, DataRowItem } from './data-editing-table.model';
import _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SelectableGroup } from 'app/shared/models/selectable';
import { BasicSeriesData, HGGraphData } from 'app/shared/models/hydrographNEW';
import { matSortKey, DATETIME, RAIN_ENTITY } from 'app/shared/constant';
import { isNewTab } from 'app/shared/models/customer';
import { TrackBy } from 'app/shared/utils/track-by';

interface DataValue {
    datetime: string; 
    timestamp: number; 
    highlight: boolean; 
    [key: number]: string;
}


interface IndexMapValue {
    currentIndex: number; 
    entityData: HGGraphData[]; 
    entityId: number;
    precision: number;
}
@Component({
    selector: 'ads-prism-data-editing-table',
    templateUrl: './data-editing-table.component.html',
    styleUrls: ['./data-editing-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsPrismDataEditingTableComponent implements OnInit, OnChanges {
    @Input() public editable: boolean;
    @Input() public customerId: number;
    @Input() public locationId: number;
    @Input() public startDate: Date;
    @Input() public endDate: Date;
    @Input() public summarizeInterval: number;
    @Input() public selectedEntities: Array<SelectableGroup>;
    @Input() public isRefreshDataTable = false;
    @Input() public isLoading = false;
    @Input() public customerDateFormat: string;
    @Input() public timeFormat: string;
    @Input() public showSearch = false;
    @Input() public data: BasicSeriesData[];
    @Input() public selectedDateRange: number[];
    @Input() public includeZeroRainValues: boolean;
    @ViewChild('dataEditingTable') private dataEditingTable: any;
    @Output() public rowEditing: EventEmitter<DataRowItem> = new EventEmitter<DataRowItem>();

    @HostListener('window:beforeprint', ['$event']) public onBeforePrint(event) {
        this.prePrint = { size: this.pageSize, expanded: this.isExpanded };
        if (this.matPaginator) this.matPaginator.pageSize = this.paginationMaxLength;
        this.pageSize = this.paginationMaxLength;
        // this.fetchDataEditingTable(); TODO: add this back (maybe? depending on requirements) once it isn't throwing errors
    }
    @HostListener('window:afterprint', ['$event']) public onAfterPrint(event) {
        this.pageSize = this.prePrint.size;
        if (this.matPaginator) this.matPaginator.pageSize = this.prePrint.size;
        this.isExpanded = this.prePrint.expanded;
    }

    @ViewChild('pagination') public matPaginator: MatPaginator;
    @ViewChild(MatSort) public matTableSort: MatSort;

    private prePrint: { size: number; expanded: boolean };
    public dataEditingSource: FilterDataSource;
    public dataEditingSourceChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    public tableHeaderColumns: Array<DataTableHeader> = new Array<DataTableHeader>();
    public tableSubHeaderColumns: Array<DataTableHeader> = new Array<DataTableHeader>();
    public tableColumns: Array<string> = new Array<string>();
    public headerColumns: Array<string> = new Array<string>();
    public formattedDataSource: Array<any> = new Array<any>();
    public mappedDataValues: BehaviorSubject<Map<number, DataValue>> = new BehaviorSubject(new Map());
    public timestampColumnTitle: string;
    public isExpanded = false;

    public isGeneratingTable = false;

    /** Whenever data should be processed (they are not update but component content is not visible) */
    public isDataDirty = true;

    // table fields
    public pageIndex = 1;
    public pageSize = 100;
    public searchValue: string;
    public sortColumn: string;
    public paginationMaxLength = 100;

    public customerDateFormatted: string;
    public customerTimeFormatted: string;
    public TIMESTAMP_COLUMN = 'timestamp';
    public TIMESTAMP_CONVERTED_COLUMN = 'datetime';

    // translate field
    private apiFailureMessage: string;
    private snackBarAction: string;
    private unitType: any;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private viewDataService: ViewDataService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        public uiUtilsService: UiUtilsService,
        private dateUtilService: DateutilService,
        private notificationService: SnackBarNotificationService,
    ) {}

    public ngOnInit() {
        this.applyTranslations();
        if(!window.location.href.includes(isNewTab)){
            sessionStorage.removeItem(matSortKey);
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            if (
                (changes.data.currentValue && !changes.data.previousValue) ||
                (changes.data.currentValue &&
                    changes.data.previousValue &&
                    !_.isEqual(changes.data.currentValue, changes.data.previousValue))
            ) {
                if (this.isExpanded) {
                    this.generateTable();
                } else {
                    this.isDataDirty = true;
                }
            } else if (!changes.data.currentValue && changes.data.previousValue) {
                if (this.isExpanded) {
                    this.clearTable();
                } else {
                    this.isDataDirty = true;
                }
            }
        }

        if (changes.selectedDateRange && changes.selectedDateRange.currentValue && 
            changes.selectedDateRange.previousValue && 
            !_.isEqual(changes.selectedDateRange.currentValue, changes.selectedDateRange.previousValue)) {
                if (this.isExpanded) {
                    this.generateTable();
                } else {
                    this.isDataDirty = true;
                }
        }
    }

    public clearTable() {
        if (this.dataEditingSource) {
            this.dataEditingSourceChange.next([]);
            this.dataEditingSource = new FilterDataSource(
                this.dataEditingSourceChange,
                [],
                this.matPaginator || <MatPaginator>{ pageSize: this.pageSize, pageIndex: this.pageIndex },
                <MatSort>{ active: DATETIME, start: 'desc' },
                this.tableColumns,
                true,
                '',
                true
            );
            this.paginationMaxLength = 0;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
        }
    }

    public generateTable() {
        if (!this.data || !this.data.length) {
            this.clearTable();
            return;
        }
        this.isLoading = true;

        if (this.matPaginator) {
            this.matPaginator.pageIndex = 0;
        }

        if (this.dataEditingSource) {
            this.dataEditingSource.disconnect();
            this.dataEditingSource = null;
        }

        this.customerDateFormatted = this.customerDateFormat.toUpperCase();
        this.customerTimeFormatted = (this.timeFormat || '').replace(':ss tt', ' A');

        this.tableSubHeaderColumns = [];
        this.tableHeaderColumns = [];
        this.tableColumns = [];
        this.headerColumns = [];

        let filteredData: BasicSeriesData[] = JSON.parse(JSON.stringify(this.data));
        filteredData.forEach((series) => {
            series.data = series.data.map((v) => ({ ...v, x: this.dateUtilService.formatDateAsTimeZone(new Date(v.x)).getTime() }))
        });
        const zoomStart = this.selectedDateRange && this.selectedDateRange[0] ? this.dateUtilService.formatDateAsTimeZone(new Date(this.selectedDateRange[0])).getTime() : null;
        const zoomEnd = this.selectedDateRange && this.selectedDateRange[1] ? this.dateUtilService.formatDateAsTimeZone(new Date(this.selectedDateRange[1])).getTime() : null;

        if (zoomStart && zoomEnd && (zoomStart !== this.startDate.getTime() || zoomEnd !== this.endDate.getTime())) {
            filteredData = filteredData.map((series) => ({
                ...series,
                data: (series && series.data) ? series.data.filter(v => v.x >= this.selectedDateRange[0] && v.x <= this.selectedDateRange[1]) : []
            }));
        }

        this.tableHeaderColumns.push({
            id: this.TIMESTAMP_CONVERTED_COLUMN,
            title: this.timestampColumnTitle,
        });
        this.tableColumns.push(this.TIMESTAMP_CONVERTED_COLUMN);
        this.headerColumns.push(this.TIMESTAMP_CONVERTED_COLUMN);
        this.tableSubHeaderColumns.push({
            id: this.TIMESTAMP_CONVERTED_COLUMN,
            title: this.timestampColumnTitle,
        });

        this.selectedEntities = this.selectedEntities.filter(v => v.groupId !== undefined);
        if (this.selectedEntities) {
            const assocUnit = [];
            const displayGroups = [];

            for (const d of filteredData) {
                assocUnit[d.entityId] = d.unitOfMeasure;
            }

            for (const entity of this.selectedEntities) {
                if (!displayGroups[entity.groupId]) {
                    displayGroups[entity.groupId] = {
                        id: entity.groupId,
                        name: entity.name,
                        entities: [],
                        unit: assocUnit[entity.id] || '',
                    };
                }
                this.tableColumns.push(entity.id.toString());
                displayGroups[entity.groupId].entities.push(entity);
                assocUnit[entity.id] = entity;
            }

            if (displayGroups && displayGroups.length) {
                const sortedDisplayGroup = StringUtils.applyUniqueAndSort<Array<any>>(
                    displayGroups,
                    'name',
                    'id',
                    false,
                );
                this.unitType = '';
                sortedDisplayGroup.forEach((group) => {
                    if (group.entities && group.entities.length) {
                        const subEntities = StringUtils.applyUniqueAndSort<Array<any>>(
                            group.entities,
                            'name',
                            'id',
                            true,
                        );
                        const tableSubHeaders = subEntities.map((sub) => {
                            return {
                                id: sub.id + this.unitType,
                                title: sub.name,
                                unit: group.unit,
                            };
                        });

                        this.tableSubHeaderColumns.push(...tableSubHeaders);
                        this.headerColumns.push(group.id);
                        this.tableHeaderColumns.push({
                            id: group.id,
                            title: group.name,
                            colspan: subEntities ? subEntities.length : 1,
                        });
                    }
                });
            }

            this.tableColumns = this.tableColumns.filter(Boolean);
        }
        this.tableSubHeaderColumns = this.tableSubHeaderColumns.filter(x => !!x);

        this.dataEditingSourceChange.next([]);
        this.dataEditingSource = new FilterDataSource(
            this.dataEditingSourceChange,
            [],
            this.matPaginator || <MatPaginator>{ pageSize: this.pageSize, pageIndex: this.pageIndex },
            <MatSort>{ active: DATETIME, start: 'desc' },
            this.tableColumns,
            true,
            '',
            true
        );

        if (filteredData) {
            this.formattedDataSource = [];

            // Process the first page of data quickly
            const mappedValues = this.processInitialDataChunk(filteredData);

            // Display the first page of data quickly
            this.mappedDataValues.next(mappedValues);
            this.formattedDataSource = this.getPaginatedData();
            this.paginationMaxLength = mappedValues.size;

            const sortSettings = JSON.parse(sessionStorage.getItem(matSortKey)) ;
            if (this.matTableSort) {
                if (sortSettings) {
                    this.matTableSort.active = sortSettings.active;
                    this.matTableSort.direction = sortSettings.direction;
                } else {
                    this.matTableSort.active = DATETIME;
                    this.matTableSort.direction = 'desc';
                }
            }
            
            if (this.paginationMaxLength !== 0 && this.formattedDataSource.length) {
                this.dataEditingSourceChange.next(this.formattedDataSource);
                setTimeout(() => {
                    this.dataEditingSource = new FilterDataSource(
                        this.dataEditingSourceChange,
                        this.formattedDataSource,
                        this.matPaginator || <MatPaginator>{ pageSize: this.pageSize, pageIndex: this.pageIndex },
                        this.matTableSort,
                        this.tableColumns,
                        true,
                        '',
                        true
                    );
                }, 0);

                setTimeout(() => {
                    // Process the rest of the data in the background
                    this.processRemainingDataChunks(filteredData);
                }, 100);
            }
        }

        this.isLoading = false;
        this.isDataDirty = false;
        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
    }

    private findLatestUnprocessedTimestamp(dataIndexes: Map<number, IndexMapValue>): number | null {
        let latestTimestamp: number | null = null;

        dataIndexes.forEach(({ currentIndex, entityData }) => {
            if (currentIndex < entityData.length) {
                const currentPoint = entityData[currentIndex];
                if (latestTimestamp === null || currentPoint.x > latestTimestamp) {
                    latestTimestamp = currentPoint.x;
                }
            }
        });

        return latestTimestamp;
    }

    private collectPointsAtTimestamp(
        dataIndexes: Map<number, IndexMapValue>, 
        timestamp: number
    ): Map<number, HGGraphData> {
        const pointsAtTimestamp = new Map<number, HGGraphData>();

        dataIndexes.forEach(({ currentIndex, entityData, entityId }) => {
            if (currentIndex < entityData.length && entityData[currentIndex].x === timestamp) {
                pointsAtTimestamp.set(entityId, entityData[currentIndex]);
            }
        });

        return pointsAtTimestamp;
    }

    private initializeEntityDataIndexes(filteredData: BasicSeriesData[]): Map<number, IndexMapValue> {
        const dataIndexes = new Map<number, IndexMapValue>();

        filteredData.forEach(entity => {
            if (entity.data?.length > 0) {
                const validData = entity.data
                    .filter(v => v.y !== undefined && v.y !== null)
                    .reverse();

                dataIndexes.set(entity.entityId, {
                    currentIndex: 0,
                    entityData: validData,
                    entityId: entity.entityId,
                    precision: entity.precision
                });
            }
        });

        return dataIndexes;
    }

    private createEmptyRowData(entityIds: number[]): { [key: number]: string } {
        const rowData: { [key: number]: string } = {};
        entityIds.forEach(id => rowData[id] = '-');
        return rowData;
    }

    private populateRowWithValues(
        rowData: { [key: number]: string },
        entityPoints: Map<number, HGGraphData>,
        dataIndexes: Map<number, IndexMapValue>
    ): void {
        entityPoints.forEach((point, entityId) => {
            const entityInfo = dataIndexes.get(entityId);
            if (entityInfo) {
                rowData[entityId] = this.getPointDisplayValue(point, entityInfo.precision, entityId);
                entityInfo.currentIndex++; // advance to next point for this entity
            }
        });
    }

    private createTimestampRow(
        timestamp: number,
        rowData: { [key: number]: string }
    ): DataValue {
        return {
            datetime: moment(timestamp).format(`${this.customerDateFormatted} ${this.customerTimeFormatted}`),
            timestamp: timestamp,
            highlight: false,
            ...rowData
        };
    }

    public processInitialDataChunk(filteredData: BasicSeriesData[]): Map<number, DataValue> {
        const pageSize = this.matPaginator?.pageSize || 100;
        const mappedData = new Map<number, DataValue>();
        const dataIndexes = this.initializeEntityDataIndexes(filteredData);
        const entityIds = filteredData.map(entity => entity.entityId);

        let processedPoints = 0;

        while (processedPoints < pageSize) {
            // Find latest timestamp across all unprocessed points
            const latestTimestamp = this.findLatestUnprocessedTimestamp(dataIndexes);
            if (latestTimestamp === null) break;

            // Get all entity values for this timestamp
            const entityPoints = this.collectPointsAtTimestamp(dataIndexes, latestTimestamp);

            // Create and populate row data
            const rowData = this.createEmptyRowData(entityIds);
            this.populateRowWithValues(rowData, entityPoints, dataIndexes);
 
            // Add formatted row to results
            const timestampRow = this.createTimestampRow(latestTimestamp, rowData);
            mappedData.set(latestTimestamp, timestampRow);

            processedPoints++;
        }

        return mappedData;
    }

    public processRemainingDataChunks(filteredData: BasicSeriesData[]) {
        const dataIndexes = new Map<number, IndexMapValue>();
        const localMappedData = new Map<number, DataValue>();

        let maxDataCount: number = 0;

        // Initialize dataIndexes with necessary values
        filteredData.forEach(entity => {
            if (entity.data) {
                dataIndexes.set(entity.entityId, { currentIndex: 0, entityData: entity.data, entityId: entity.entityId, precision: entity.precision });

                maxDataCount = Math.max(maxDataCount, entity.data.length);
            }
        });

        const chunkSize = 1000;

        const processChunk = () => {
            // Track the last indexes before processing
            const lastIndexes = new Map<number, number>();
            dataIndexes.forEach((value, key) => {
                lastIndexes.set(key, value.currentIndex);
            });

            this.processDataPoints(filteredData, dataIndexes, localMappedData, chunkSize);
            // Check if any indexes have changed
            const indexesChanged = Array.from(dataIndexes.entries()).some(([key, value]) => value.currentIndex !== lastIndexes.get(key));
            // If no indexes have changed, end the processing
            if (!indexesChanged) {
                this.paginationMaxLength = localMappedData.size;
                this.mappedDataValues.next(localMappedData);
                this.formattedDataSource = this.getPaginatedData();
        
                this.dataEditingSourceChange.next(this.formattedDataSource);
                this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                return;
            }

            // Process next chunk of data
            setTimeout(processChunk, 0);
        };

        setTimeout(processChunk, 0);
    }

    public processDataPoints(data: BasicSeriesData[], dataIndexes: Map<number, IndexMapValue>, resultMap: Map<number, DataValue>, chunkSize: number) {
        let processedCount = 0;
        let totalProcessedCount = 0;    // used to count all the processed entries, including skipped rows
        
        const maxIterations = Math.max(...Array.from(dataIndexes.values()).map(v => v.entityData.length));

        while (processedCount < chunkSize && totalProcessedCount <= maxIterations) {
            const result = this.processRow(data, dataIndexes);
            totalProcessedCount++;

            if (result === null) {
                continue;
            }
            const { ts, row } = result;
            resultMap.set(ts, {
                datetime: moment(ts).format(`${this.customerDateFormatted} ${this.customerTimeFormatted}`),
                timestamp: ts,
                highlight: false,
                ...row,
            });

            processedCount++;
        }
    }

    public processRow(data: BasicSeriesData[], indexedMap: Map<number, IndexMapValue>): { ts: number, row: { [key: number]: string } } | null {
        const defaultRow: { [key: number]: string } = {};
        data.forEach(v => defaultRow[v.entityId] = '-');

        let earliestTimestamp = Infinity;

        const currentPoints = Array.from(indexedMap.values())
            .map(v => this.getCurrentDataPoint(v))
            .filter(value => {
                if (value === null) {
                    return false;
                }

                if (value.dataPoint === null) {
                    indexedMap.get(value.entityId).currentIndex += 1;

                    return false;
                }

                earliestTimestamp = Math.min(earliestTimestamp, value.dataPoint.x);
                return true;
            });

        if (currentPoints.length === 0) {
            return null;
        }

        currentPoints.forEach((value) => {
            if (value.dataPoint.x !== earliestTimestamp) {
                return;
            }

            const currentEntity = indexedMap.get(value.entityId);
            const displayValue = this.getPointDisplayValue(value.dataPoint, currentEntity.precision, value.entityId);
            currentEntity.currentIndex++;

            defaultRow[value.entityId] = displayValue;
        });

        return { ts: earliestTimestamp, row: defaultRow };
    }

    private getCurrentDataPoint(entity: { currentIndex: number, entityData: HGGraphData[], entityId: number }) {
        const { currentIndex, entityData } = entity;

        if (currentIndex >= entityData.length) {
            return null;
        }

        const dataPoint = entityData[currentIndex];

        if (dataPoint.y === null || dataPoint.y === undefined) {
            return { entityId: entity.entityId, dataPoint: null };
        }

        return { entityId: entity.entityId, dataPoint };
    }

    public pageEvent() {
        this.formattedDataSource = this.getPaginatedData();
        this.dataEditingSourceChange.next(this.formattedDataSource);

        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
    }

    public getPointDisplayValue(point: HGGraphData, precision: number, entityId: number) {
        if (!point || point.flagged) {
            return '-';
        }

        let yValue;

        if (point.correctedY !== undefined && point.correctedY !== null) {
            yValue = point.correctedY;
        } else if (point.y !== undefined && point.y !== null) {
            yValue = point.y
        }

        if (this.includeZeroRainValues && yValue === 0 && entityId === RAIN_ENTITY) {
            return this.dateUtilService.formatMetricsValue(yValue, precision);
        }

        if (!yValue) {
            return '-';
        }

        return this.dateUtilService.formatMetricsValue(yValue, precision);
    }

    public sortChange() {
        this.formattedDataSource = this.getPaginatedData();
        
        this.dataEditingSourceChange.next(this.formattedDataSource);
        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);    
    }

    private getPaginatedData() {
        const sortActive = this.matTableSort?.active || DATETIME;
        const sortDirection = this.matTableSort?.direction || 'desc';

        const pageIndex = this.matPaginator ? this.matPaginator.pageIndex : 0;
        const pageSize = this.matPaginator ? this.matPaginator.pageSize : 100;

        const fullData = Array.from(this.mappedDataValues.getValue().values());

        if (sortActive && sortDirection) {
            fullData.sort((a, b) => {
                const direction = sortDirection === 'asc' ? 1 : -1;

                // When sorting by datetime, use the timestamp instead
                if (sortActive === DATETIME) {
                    return (a.timestamp < b.timestamp ? -1 : 1) * direction;                    
                }

                const valueA = a[sortActive];
                const valueB = b[sortActive];

                const numA = valueA === '-' ? 0 : Number(valueA);
                const numB = valueB === '-' ? 0 : Number(valueB);
                return (numA < numB ? -1 : 1) * direction;
            });
        }

        const start = pageIndex * pageSize;
        const end = start + pageSize;

        return fullData.slice(start, end);
    }

    public expandDataEditing() {
        if (this.isDataDirty) {
            this.generateTable();
        }
    }

    public dataTableRowClick(row, rowIndex: number) {
        if (!row) {
            return;
        }

        this.resetDataTable(rowIndex);
        // highlight point on hydrograph and scatter graph for selected row
        this.viewDataService.selectAdvancedGraphs(
            this.dateUtilService.getLocalDateFromUTCDate(new Date(row.timestamp)).getTime()
        );
        row.highlight = true;
        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
    }

    public dataRowLabelClick(event: any, row: any, columnId: string, originalReading: number, isEditable: boolean) {
        // Keep table readonly until saving functionality is implemented
        return;

        // TODO: Code that was there previously, can be reactivated once saving is available
        // if (!event || !this.editable || !row || !columnId || columnId === this.TIMESTAMP_CONVERTED_COLUMN) {
        //   return;
        // }
        // // make field editable only for clicked item
        // row['editable'] = isEditable ? columnId : undefined;
        // row['reset'] = true;
        // if (!isEditable && event.target.value && Number(event.target.value) !== Number(row[columnId])) {
        //   row[columnId] = event.target.value;
        //   this.rowEditing.emit(<DataRowItem>{
        //     datetime: row['datetime'],
        //     timestamp: row['timestamp'],
        //     entityId: Number(columnId),
        //     reading: row[columnId]
        //   });
        // }
        // this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
    }

    private applyTranslations() {
        this.translateService
            .get(['COMMON.TIMESTAMP', 'COMMON.API_FAILURE_MESSAGE', 'COMMON.DISMISS_TEXT'])
            .subscribe((items) => {
                this.timestampColumnTitle = items['COMMON.TIMESTAMP'];
                this.apiFailureMessage = items['COMMON.API_FAILURE_MESSAGE'];
                this.snackBarAction = items['COMMON.DISMISS_TEXT'];
            });
    }

    private resetDataTable(rowIndex: number) {
        if (this.dataEditingSource.renderedData) {
            this.dataEditingSource.renderedData.forEach((row, index: number) => {
                row['highlight'] = false;
                if (index !== rowIndex) {
                    row['editable'] = false;
                }
            });

            this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
        }
    }
}
