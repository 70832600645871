import { Component, EventEmitter, Output, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { SliicerCaseStudy, SeasonType, Settings } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import * as Utils from './seasons-settings.utils';
import { Either, isRight } from 'fp-ts/es6/Either';
import { NgForm } from '@angular/forms';
import { isSome } from 'fp-ts/es6/Option';
import * as A from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/pipeable';
import moment from 'moment';
import { ChangesAction } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import * as _ from 'underscore';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'ads-seasons-settings',
    templateUrl: './seasons-settings.component.html',
    styleUrls: ['./seasons-settings.component.scss'],
})
export class SeasonsSettingsComponent implements OnChanges {
    @Input() public isStudyLocked: boolean;
    @Input() public shouldUpdate: boolean;
    @Input() public changesAction: ChangesAction;
    @Input() public caseStudyDetails: SliicerCaseStudy;
    @Output() public checkChanges: EventEmitter<string> = new EventEmitter();
    @ViewChild('seasonsForm') seasonsForm: NgForm;

    public error = false;

    public seasonTypes = Object.keys(SeasonType).map((k: SeasonType) => SeasonType[k]).filter(function (item) { return item.toString() != "Year"});

    public displayedColumns: string[] = ['type', 'startDate', 'endDate'];
    public months: { name: string; month: number }[] = pipe(
        A.range(0, 11),
        A.map((i) => ({ name: moment().month(i).format('MMM'), month: i })),
    );
    public days: number[][] = pipe(
        A.range(0, 11),
        A.map((i) => A.range(1, Utils.daysInAMonth[i])),
    );
    public state: Utils.State;

    public maxDate: Date;
    public minDate: Date;
    public dateFormat: string;
    public formError: string | null = null;

    private originalSeasonsData: Utils.SeasonsInitData;

    public formValues = { value: '' };
    public initialFormValues = { value: '' };

    public uniqueNamesError = false;
    public maxSeasonsLimitError = false;
    public atLeastTwoCustomSeasonsError = false;
    public emptyNamesError = false;
    public emptyStartDatesError = false;
    public seasonsChronologicalOrderError = false;

    public trackByIndex = TrackBy.byIndex;
    constructor(private sliicerService: SliicerService, private dateUtilService: DateutilService) {
        this.maxDate = this.dateUtilService.maxDate;
        this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
            this.dateFormat = newDateFormat;
        });
    }

    private restorePreviousValue() {
        const dates = Utils.caseStudyDates(this.caseStudyDetails);
        this.originalSeasonsData = Utils.caseStudySeasonsInitData(this.caseStudyDetails);
        this.state = Utils.initSeasonsState(this.dateFormat, dates, this.originalSeasonsData);
    }

    private updateFormError(val: Either<string, Settings>) {
        this.formError = isRight(val) ? null : val.value;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.caseStudyDetails) {
            const dates = Utils.caseStudyDates(this.caseStudyDetails);
            if (isSome(dates)) {
                this.maxDate = dates.value.endDate;
                this.minDate = dates.value.startDate;
            }
            this.originalSeasonsData = Utils.caseStudySeasonsInitData(this.caseStudyDetails);

            this.state = Utils.initSeasonsState(this.dateFormat, dates, this.originalSeasonsData);
        }
        if (changes.shouldUpdate) {
            this.restorePreviousValue();
        } else if (changes.changesAction && this.getChangeValue()) {
            if (this.changesAction.action === 'undoChanges') {
                this.restorePreviousValue();
                this.emitChanges();
            }
        }
    }

    public seasonTypeChanged(val: SeasonType) {
        // #22451 reset form errors
        this.formError = null;

        const dates = Utils.caseStudyDates(this.caseStudyDetails);
        const seasonsInit: Utils.SeasonsInitData =
            val === SeasonType.Custom
                ? { seasonType: SeasonType.Custom, seasons: Utils.caseStudySeasonDefinitions(this.caseStudyDetails) }
                : { seasonType: val };

        this.state = Utils.initSeasonsState(this.dateFormat, dates, seasonsInit);

        this.emitChanges();
    }

    public addCustomSeason() {
        this.state = Utils.seasonAdded(this.state);
        this.emitChanges();
    }

    public removeCustomSeason(item: Utils.SeasonDefinitionItem) {
        this.state = Utils.seasonRemoved(item)(this.state);

        setTimeout(() => {
            this.emitChanges();
        }, 0);
    }

    public customSeasonNameChanged(item: Utils.SeasonDefinitionItem, value: string) {
        this.state = Utils.seasonNameChanged(value, item)(this.state);
        this.emitChanges();
    }

    public customSeasonStartMonthChanged(item: Utils.SeasonDefinitionItem, value: number) {
        this.state = Utils.seasonStartMonthChanged(value, item)(this.state);
        this.emitChanges();
    }

    public customSeasonStartDayChanged(item: Utils.SeasonDefinitionItem, value: number) {
        this.state = Utils.seasonStartDayChanged(value, item)(this.state);
        this.emitChanges();
    }

    public getChanges(): Settings {
        this.seasonsForm.form.markAsDirty();
        this.seasonsForm.form.markAsTouched();

        const settings = Utils.toSettingsResult(this.state);
        this.updateFormError(settings);

        if (isRight(settings)) {
            this.originalSeasonsData =
                settings.value.seasonType === SeasonType.Custom
                    ? {
                          seasonType: SeasonType.Custom,
                          seasons: Utils.caseStudySeasonDefinitions(this.caseStudyDetails),
                      }
                    : { seasonType: settings.value.seasonType };
            return settings.value;
        } else {
            // in case validation error is not visible in the UI
            console.error(`Seasons validation failed: ${settings.value}`);
        }
        return null;
    }

    public getChangeValue(): boolean {
        if (this.uniqueNamesError || this.maxSeasonsLimitError) return false;

        const originalSeasonType =
            (this.caseStudyDetails.settings && this.caseStudyDetails.settings.seasonType) || SeasonType.None;
        const originalSeasons = Utils.getSeasons(this.caseStudyDetails);
        const formSeasons = isSome(this.state.seasons) ? this.state.seasons.value : [];
        return (this.state.seasonType !== originalSeasonType || !_.isEqual(originalSeasons, formSeasons)) && !this.error;
    }

    private clearValidationOnCustomType() {
        this.uniqueNamesError = false;
        this.maxSeasonsLimitError = false;
        this.emptyNamesError = false;
        this.emptyStartDatesError = false;
        this.seasonsChronologicalOrderError = false;
    }

    // #29159 custom season names must be unique
    // #29154 max seasons number should not exceed 12
    private validateSeasonsForm() {
        this.clearValidationOnCustomType();

        if (!this.state || this.state.seasonType !== SeasonType.Custom || !this.seasonsForm) {
            this.checkCustomSeasons();
            this.checkError();
            return;
        }

        const values = this.state.customSeasonsForm.getOrElse([]);

        const seasonNames = [];
        let pr: Utils.SeasonDefinitionItem = null;
        for(const r of values) {
            const name = r.name.raw;
            if(!seasonNames[name]) seasonNames[name] = 0;
            if(!name || name === '') this.emptyNamesError = true;
            seasonNames[name]++;

            const startDate = r.start.raw;
            if(startDate.day === null || startDate.month === null) this.emptyStartDatesError = true;
            if(pr) {
                const previousStartDate = pr.start.raw;

                if((previousStartDate.month > startDate.month) || (previousStartDate.month === startDate.month && previousStartDate.day >= startDate.day)) {
                    this.seasonsChronologicalOrderError = true;
                }
            }

            pr = r;
        }

        for(const [i, sn] of Object.entries(seasonNames)) {
            if(sn > 1) this.uniqueNamesError = true;
        }

        this.maxSeasonsLimitError = values.length > 12;

        this.checkCustomSeasons();
        this.checkError();
    }

    private checkCustomSeasons() {
        const values = this.state.customSeasonsForm.getOrElse([]);

        this.atLeastTwoCustomSeasonsError = values.length < 2;

        this.checkError();
    }

    private checkError() {
        this.error =
            this.state && this.state.seasonType !== SeasonType.Custom ? false :
                this.uniqueNamesError
                || this.maxSeasonsLimitError
                || this.atLeastTwoCustomSeasonsError
                || this.emptyNamesError
                || this.emptyStartDatesError
                || this.seasonsChronologicalOrderError;
    }

    private emitChanges() {
        this.validateSeasonsForm();
        this.checkChanges.emit();
    }
}
