<div class="notification-tree-dialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.TITLE }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <div class="dialog-title-bar">
            <div class="w-33">
                <mat-form-field>
                    <input
                        matInput
                        (focusout)="trimName()"
                        placeholder="{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.LEVEL_NAME }}"
                        id="nameInput"
                        #nameInput="ngModel"
                        [disabled]="data.nameReadonly !== undefined && data.nameReadonly === true"
                        maxlength="25"
                        [(ngModel)]="data.name"
                        ngDefaultControl
                        required
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="!validateNameExist()">
                    {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.ALARM_NAME_EXIST }}
                </div>
            </div>
            <div class="w-33">
                <mat-form-field>
                    <input
                        matInput
                        type="number"
                        (focusout)="forceDelay()"
                        placeholder="{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.DELAY }}"
                        id="delayInput"
                        #delayInput="ngModel"
                        [(ngModel)]="data.delay"
                        ngDefaultControl
                        required
                        [pattern]="numeric0_1440Pattern"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="delayInput?.errors?.pattern">
                    {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.ALARM_DELAY_VALIDATION }}
                </div>
            </div>
            <div class="input-design">
                <span>{{ PAGE_TEXT.COLUMN_NAMES.ENABLE }}</span>
                <mat-slide-toggle
                    #enabledToggle
                    [checked]="data.enabled"
                    (change)="toggleNotificationEnabled($event)"
                ></mat-slide-toggle>
            </div>
        </div>
        <mat-form-field class="full-width">
            <mat-icon matPrefix class="search-icon">search</mat-icon>
            <input
                matInput
                type="text"
                placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                [(ngModel)]="searchString"
                (input)="searchUsers()"
                ngDefaultControl
            />
        </mat-form-field>
        <div class="user-box">
            <label>{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.USERS }}</label>
            <div class="left-side">
                <div class="header">
                    <div class="name">{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.USERS }}</div>
                    <div class="alarm">
                        <mat-checkbox [checked]="allUserAlarm" (change)="checkAll(true, true)"></mat-checkbox>
                        {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.ALARM }}
                    </div>
                    <div *ngIf="data.showRtn" class="rtn">
                        <mat-checkbox [checked]="allUserRTN" (change)="checkAll(true, false)"></mat-checkbox>
                        {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.RTN }}
                    </div>
                </div>
                <div class="rows" *ngFor="let user of dispUsers; trackBy: trackById">
                    <div class="name">{{ user.dispName }}</div>
                    <div class="alarm">
                        <mat-checkbox [checked]="user.alarm" (change)="updateUser(user, true)"></mat-checkbox>
                    </div>
                    <div *ngIf="data.showRtn" class="rtn">
                        <mat-checkbox [checked]="user.rtn" (change)="updateUser(user, false)"></mat-checkbox>
                    </div>
                </div>
                <p *ngIf="dispUsers.length === 0">{{ PAGE_TEXT.NO_USERS }}</p>
            </div>
            <div class="right-side">
                <div class="header">
                    <div class="name">{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.ADMINS }}</div>
                    <div class="alarm">
                        <!-- <mat-checkbox [checked]="allAdminAlarm" (change)="checkAll(false, true)"></mat-checkbox> -->
                        {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.ALARM }}
                    </div>
                    <div *ngIf="data.showRtn" class="rtn">
                        <!-- <mat-checkbox [checked]="allAdminRTN" (change)="checkAll(false, false)"></mat-checkbox> -->
                        {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.RTN }}
                    </div>
                </div>
                <div class="rows" *ngFor="let admin of dispAdmins; trackBy: trackById">
                    <div class="name">{{ admin.dispName }}</div>
                    <div class="alarm">
                        <mat-checkbox [checked]="admin.alarm" (change)="updateUser(admin, true)"></mat-checkbox>
                    </div>
                    <div *ngIf="data.showRtn" class="rtn">
                        <mat-checkbox [checked]="admin.rtn" (change)="updateUser(admin, false)"></mat-checkbox>
                    </div>
                </div>
                <p *ngIf="dispAdmins.length === 0">{{ PAGE_TEXT.NO_ADMINS }}</p>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-button (click)="close(false)">{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
        <button
            mat-button
            (click)="close(true)"
            [disabled]="!data.name || data.delay === null || delayInput?.errors?.pattern || !validateNameExist()"
        >
            {{ 'COMMON.APPLY_BTN' | translate }}
        </button>
    </div>
</div>
