import {
    Component,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
    ElementRef,
    Output,
    EventEmitter
} from '@angular/core';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { VaultUploadDialogLocalComponent } from 'app/pages/vault';
import {
    DepthDurationFrequency,
    DdfTable,
    DryDayDefinition,
    StormDefinition,
    DryDefinitionDetails,
    WasteWaterBaseInfiltration,
    BaseInfiltrationMethod,
    DailyMaximum,
} from 'app/shared/models/sliicer/customer-rainfall-profile';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { DdfGraphComponent } from 'app/shared/components/ddf-graph/ddf-graph.component';
import { UnitOfMeasureType } from '../../../shared/constant';
import { SharedService } from 'app/shared/services/shared.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { Customer, SliicerUserConfig } from 'app/shared/models/customer';
import { VaultFile, VaultUpload } from 'app/shared/models/vault';
import { SelectItem } from 'app/shared/models/selected-Item';
import { IDataTransfer } from 'app/shared/models/modal';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { ConfirmationButton, ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TrackBy } from 'app/shared/utils/track-by';

const CSV_FILE_TYPE = 'text/csv';
const SNACKBAR_DURATION = 10000;
const DAY_MIN_CONVERSION = 1440;
const HR_MIN_CONVERSION = 60;
const HR_DAY_CONVERSION = 24;
const ddfTableColumnNames = [
    'duration',
    '2mo',
    '3mo',
    '4mo',
    '6mo',
    '9mo',
    '1yr',
    '2yr',
    '5yr',
    '10yr',
    '25yr',
    '50yr',
    '100yr',
    '200yr',
    '500yr',
    '1000yr',
];
@Component({
    selector: 'app-customer-rain-profile',
    templateUrl: './customer-rain-profile.component.html',
    styleUrls: ['./customer-rain-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomerRainProfileComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('ddfGraph') public ddfGraphComponent: DdfGraphComponent;
    @ViewChild('dryDefinitionForm', { static: true }) public dryDefinitionForm: NgForm;
    @ViewChild('stormDefnitionForm', { static: true }) public stormDefnitionForm: NgForm;
    @ViewChild('CustomerWasteWaterForm', { static: true }) public CustomerWasteWaterForm: NgForm;
    
    @ViewChild('table') public matTable: any;

    public ddfTableColumns = ddfTableColumnNames;
    public ddfTableColumnDefs: { name: string; show: boolean }[] = ddfTableColumnNames.map((v) => ({
        name: v,
        show: true,
    }));
    @Input('customerDetails') public customerDetails: Customer;
    @Input('customerUnit') public customerUnit: number;
    @Output() public rainfallProfileChanged = new EventEmitter<boolean>();

    public BaseInfiltrationMethod = BaseInfiltrationMethod;

    public percentagePattern = REGEX_CONFIG.numeric0_100With6DecimalPlacesPattern;
    public numericValidationPattern = REGEX_CONFIG.numericGeneralPattern;
    public numericWith2DecimalPlaces = REGEX_CONFIG.numericWith2DecimalPlaces;
    public numericWith1DecimalPlaces = REGEX_CONFIG.numericWith1DecimalPlaces;
    public isPositiveInteger = REGEX_CONFIG.isPositiveInteger;
    public isDryDayDefFormChanges = true;
    public isStormDefFormChange = true;
    public cusWasteWaterFormChange = true;

    public selectedOptions: string[];
    public IsUnitMetric = true;
    public saveDisabled = false;

    public dryDefinitionDetails = new DryDefinitionDetails();

    public stormDefinitionDetails: StormDefinition = {
        minRainfallThreshold: '0',
        minimumTimeBetweenEventsMinutes: 0,
    };

    public isLoadingState: boolean;
    public ddfFormChange: boolean;

    public dismissBtn: string;

    public stormCreationSuccessfultxt: string;
    public savedDdfText: string;
    public failedDdfText: string;

    public stormCreationFailurefultxt: string;

    public dryDayCreationSuccessfultxt: string;

    public dryDayCreationFailuretxt: string;


    public wasteWaterMethod: BaseInfiltrationMethod = BaseInfiltrationMethod.StevensSchutzbach;
    public wasteWaterMethodName = '';
    public wasteWaterConstant: number;
    public wasteWaterMethodOptions: Array<SelectItem> = [];
    public wasteWaterMethodDescriptions = {
        [BaseInfiltrationMethod.PercentMinimum]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MINIMUM_DESC',
        [BaseInfiltrationMethod.Mitchell]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MITCHELL_DESC',
        [BaseInfiltrationMethod.StevensSchutzbach]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_METHOD_DESC',
        [BaseInfiltrationMethod.WastewaterProduction]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_PRODUCTION_DESC',
    };
    // TODO: WPS - fix "any" types in variable declarations
    public ddfTableSource: any;
    public rainfallDataColumns: any;
    public ddf: DepthDurationFrequency;
    public ddfData: DdfTable[];
    public rowindex = [];
    public deleteColumn = 'checked';
    public deleteButton = false;
    public durationOptions = ['min', 'hr', 'days'];
    public clicked = false;
    public status = false;
    public noFileMsg: string;
    public csvUploadFilesErr: string;
    public chooseCsvFileMsg: string;
    public filesUploadErr: string;
    public filesUploadSuccessMsg: string;
    public unitInInch: string;
    public unitInMM: string;
    public uploadVaultFile: VaultFile;
    public ddfChart: any;
    public chartOptions: any;
    public isDepthDurationHeaderChecked: boolean;
    public tempData = {};
    public data = [
        'month2',
        'month3',
        'month4',
        'month6',
        'month9',
        'year1',
        'year2',
        'year5',
        'year10',
        'year25',
        'year50',
        'year100',
        'year200',
        'year500',
        'year1000',
    ];
    public finalData = {};
    public unit: string;
    public filterOptions = [
        '2mo',
        '3mo',
        '4mo',
        '6mo',
        '9mo',
        '1yr',
        '2yr',
        '5yr',
        '10yr',
        '25yr',
        '50yr',
        '100yr',
        '200yr',
        '500yr',
        '1000yr',
    ];
    private rainFallDepthDurationRowDeletionMsg: string;
    public savedMethod: string;
    public failedToSave: string;
    public wasteWaterId: string;
    public hr: string;
    public minute: string;
    public days: string;
    public baseMethod: BaseInfiltrationMethod;
    public baseConstant: number;
    public columnIndex = 0;

        // Below variable will be used to access the form state
    

    private badDDFFormatText: string;
    private userConfig: SliicerUserConfig = null;

    public trackByName = TrackBy.byUniqueKey<{ name: string }>()('name');
    public trackByIndex = TrackBy.byIndex;
    constructor(
        private customerService: CustomerService,
        private sharedService: SharedService,
        private snackBarNotificationService: SnackBarNotificationService,
        private dialog: MatDialog,
        private translate: TranslateService,
        public domOperationUtilsService: DomOperationUtilsService,
        public elementRef: ElementRef,
    ) { 
    }
    /**
      oninit lifecyclehook used to fetch static text
    **/
    public ngOnInit() {
        this.disableSaveButton(true);

        this.domOperationUtilsService.rainfallTabIndex.next(0);
        this.domOperationUtilsService.ddfTabIndex.next(0);

        this.rainfallDataColumns = this.ddfTableColumns;
        this.selectedOptions = []; // hide column Checkbox should be unchecked as default;;
        this.wasteWaterConstant = 80;
        this.applyTranslations();

        const translateMethodKeys = [
            { key: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MINIMUM', value: BaseInfiltrationMethod.PercentMinimum },
            { key: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MITCHELL', value: BaseInfiltrationMethod.Mitchell },
            { key: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_METHOD', value: BaseInfiltrationMethod.StevensSchutzbach },
            { key: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_PRODUCTION', value: BaseInfiltrationMethod.WastewaterProduction },
        ];
 
        this.translate.get(translateMethodKeys.map((k) => k.key)).subscribe((values: Array<string>) => {
            let index = 0;

            translateMethodKeys.forEach((item) => {
                const value: SelectItem = {
                    id: index++,
                    name: item.value,
                    text: values[item.key],
                };

                this.wasteWaterMethodOptions.push(value);
            });
        });
    }

    /**
      onchanges lifecyclehook used to fetch existing storm and dry day definitions of customer
    **/
    public ngOnChanges(changes: SimpleChanges) {
        if (this.customerDetails) {
            this.getStormDifinitionData();
            this.getDryDefinitionData();
            this.getDepthDurationFrequency();
            this.getWasteWaterInfilteration();
            this.initializeChartSettings();
            this.getUserConfig();
        }
    }

    public minRainfall1DayChange(){
        this.rainfallProfileChanged.emit(true);
    }

    public minRainfall3DayChange(){
        this.rainfallProfileChanged.emit(true);
    }

    public minRainfall5DayChange(){
        this.rainfallProfileChanged.emit(true);
    }

    public minFlowPrecentageChange(){
        this.rainfallProfileChanged.emit(true);
    }

    public maxFlowPrecentageChange() {
        this.rainfallProfileChanged.emit(true);
    }

    public minRainfallThresholdChange() {
        this.rainfallProfileChanged.emit(true);
    }

    public minTimeBwStormEventsChange() {
        this.rainfallProfileChanged.emit(true);
    }

    public getWasteWaterInfilteration() {
        this.isLoadingState = true;
        this.wasteWaterMethodName = this.wasteWaterMethodOptions[0].text;
        this.customerService.getWasteWaterMethod(this.customerDetails.customerID).subscribe(
            (result: WasteWaterBaseInfiltration) => {
                this.isLoadingState = false;
                if (result) {
                    this.wasteWaterId = result.id;
                    this.wasteWaterMethod = result.method;
                    this.wasteWaterConstant = result.constant;
                    this.baseMethod = this.wasteWaterMethod;
                    this.baseConstant = this.wasteWaterConstant;
                    const wasterWater: SelectItem = this.wasteWaterMethodOptions.find(
                        (item) => item.name === this.wasteWaterMethod,
                    );
                    this.wasteWaterMethodName = wasterWater ? wasterWater.text : this.wasteWaterMethodOptions[0].text;
                }
            },
            (error) => {
                this.isLoadingState = false;
            },
        );
    }

    public applyDdfSource(data: DdfTable[], shouldSort = true) {
        if(data && shouldSort) {
            data.sort((row1, row2) => row1.durationvalue > row2.durationvalue ? 1 : row1.durationvalue < row2.durationvalue ? -1 : 0)
        }

        if(this.ddfTableSource) {
            this.ddfTableSource.data = data;
        } else {
            this.ddfTableSource = new MatTableDataSource(data);
        }
    }

    public saveWasteWaterMethod() {
        this.isLoadingState = true;
        const wasteWaterValue: WasteWaterBaseInfiltration = {
            method: this.wasteWaterMethod,
            constant: this.wasteWaterConstant,
            id: this.wasteWaterId,
        };
        this.customerService.saveWasteWaterMethod(this.customerDetails.customerID, wasteWaterValue).subscribe(
            (result: WasteWaterBaseInfiltration[]) => {
                this.snackBarNotificationService.raiseNotification(this.savedMethod, this.dismissBtn, {
                    duration: SNACKBAR_DURATION,
                });
                this.isLoadingState = false;
                this.saveDisabled = true;
            },
            (error) => {
                this.isLoadingState = false;
                this.snackBarNotificationService.raiseNotification(this.failedToSave, this.dismissBtn, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );
    }

    public selectConstantChange()
    {
        this.saveDisabled = false;
        this.rainfallProfileChanged.emit(true);
    }

    private isTabChangeConfirmationShown = false;
    private oldTabIndex = 0;

    public onSelectedTabChange(event: MatTabChangeEvent, tabGroup: MatTabGroup) {
        if (this.isTabChangeConfirmationShown) return;

        if((!(!this.dryDefinitionForm.valid || !this.dryDefinitionForm.dirty) && this.isDryDayDefFormChanges) || 
        (!(!this.stormDefnitionForm.valid || !this.stormDefnitionForm.dirty) && this.isStormDefFormChange && this.oldTabIndex === 1) ||
        (!(!this.CustomerWasteWaterForm.valid || !this.CustomerWasteWaterForm.dirty || this.saveDisabled) && this.cusWasteWaterFormChange && this.oldTabIndex === 2) || 
          this.ddfFormChange
    ){
            this.isTabChangeConfirmationShown = true;
            tabGroup.selectedIndex = this.oldTabIndex;

            this.warningDialogOpen().subscribe((data: { whichButtonWasPressed: ConfirmationButton }) => {
                this.isTabChangeConfirmationShown = false;

                if (data && data.whichButtonWasPressed === ConfirmationButton.ok) {
                    tabGroup.selectedIndex = event.index;
                    this.isDryDayDefFormChanges = false;
                    this.isStormDefFormChange = false;
                    this.ddfFormChange = false;
                    this.cusWasteWaterFormChange = false;
                    this.tabChangeHandler(event);
                }
            });
        } else {
            this.tabChangeHandler(event);
        }
    }

    public warningDialogOpen(): Observable<IComponentDialogConfirmationResult> {
        const message = true
            ? this.translate.instant('CUSTOMER_EDITOR.NAVIGATE_AWAY_WARNING')
            : this.translate.instant('SLIICER.COMMON.NAVIGATE_AWAY_WARNING');

        return this.dialog.open<ConfirmationDialogComponent, IComponentDialog, IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
            data: {
                title: this.translate.instant('SLIICER.COMMON.WARNING'),
                message: message,
                cancelText: this.translate.instant('SLIICER.COMMON.CANCEL_BUTTON_TITLE'),
                okText: this.translate.instant('SLIICER.COMMON.CONFIRM'),
                messageClass: 'danger'
            },
            disableClose: true,
        }).afterClosed();
    }

    private tabChangeHandler(event: MatTabChangeEvent) {
        this.oldTabIndex = event.index;
    }

    public onDDFtabChange(event: MatTabChangeEvent) {
        this.domOperationUtilsService.ddfTabIndex.next(event.index);
    }

    public onSelectMethodChange(event: any) {
        this.saveDisabled = false;
        this.rainfallProfileChanged.emit(true);
        const selectedMethodItem = event.source.selected._element.nativeElement;
        this.wasteWaterMethodName = selectedMethodItem.innerText.trim();

        if (event.value === BaseInfiltrationMethod.PercentMinimum && this.baseMethod !== BaseInfiltrationMethod.PercentMinimum) {
            this.wasteWaterConstant = 80;
        } else if (event.value === BaseInfiltrationMethod.StevensSchutzbach) {
            this.wasteWaterConstant = 0;
        } else if (event.value === BaseInfiltrationMethod.WastewaterProduction && this.baseMethod !== BaseInfiltrationMethod.WastewaterProduction) {
            this.wasteWaterConstant = 88;
        } else {
            this.wasteWaterConstant = this.baseConstant;
        }
    }
    public isSchutzbach(method: BaseInfiltrationMethod) {
        return method === BaseInfiltrationMethod.StevensSchutzbach;
    }
    public isMitchell(method: BaseInfiltrationMethod) {
        return method === BaseInfiltrationMethod.Mitchell;
    }
    /** Depth duration frequency */
    public openUploadDepthDurationDialog($event: MouseEvent, fileType?: string): void {
        // setup initial url
        fileType = CSV_FILE_TYPE;

        this.dialog
            .open(VaultUploadDialogLocalComponent, {
                data: <IDataTransfer>{
                    fileType: fileType,
                },
                panelClass: 'file-upload-background-panel',
                disableClose: true,
            })
            .afterClosed()
            .subscribe(
                (vaultUpload: VaultUpload) => {
                    // if upload fails
                    if (vaultUpload === null) {
                        return;
                    }
                    if (vaultUpload) {
                        let uploadContainsNaN = false;
                        this.disableSaveButton(false);
                        const response = JSON.parse(vaultUpload.response);
                        this.ddfData = response.ddfTable;
                        this.ddfData.forEach((element) => {
                            element.duration = this.minute;
                            element.durationvalue = element.durationMinutes;
                            this.data.forEach((d) => {
                                element[d] = this.applyUnitRounding(element[d]);
                                if(isNaN(element[d])){
                                    uploadContainsNaN = true;
                                }
                            });
                        });
                        if(this.ddfData.length === 0 || uploadContainsNaN)
                        {
                            this.snackBarNotificationService.raiseNotification(this.badDDFFormatText, this.dismissBtn, {
                                panelClass: 'custom-error-snack-bar',
                            }, false);
                        }
                        else{
                            this.applyDdfSource(this.ddfData);
                            this.ddfGraph(this.ddfData, this.unit);
                        }
                    }
                },
                (error) => {
                    // Error Block to handle errors
                    this.disableSaveButton(true);
                },
            );

        if (fileType === CSV_FILE_TYPE) {
            this.sharedService.setFileUploadMessage(this.chooseCsvFileMsg);
        }
    }
    /**DDF Column Filter */
    @HostListener('document:click', ['$event'])
    public clickout(event) {
        this.status = false;
    }

    public openFilterMenu(event: Event) {
        this.status = true;
        event.stopPropagation();
    }

    public ddfFilter(values) {
        const columnNameArr = [];
        this.ddfTableColumns.forEach((ele) => {
            if (values.indexOf(ele) === -1 && ele !== 'duration') {
                columnNameArr.push(ele);
            }
        });
        this.rainfallDataColumns = columnNameArr;
        this.rainfallDataColumns.unshift('duration');
    }

    /**Method to convert duration values based on selected unit value */
    public onDurationUnitChange(event, index: number) {
        this.disableSaveButton(false);
        if (
            this.ddfData &&
            this.ddfData.length > 0 &&
            this.ddfData[index] &&
            this.ddfData[index].durationMinutes &&
            event &&
            event.value &&
            event.source &&
            event.source.ngControl
        ) {
            const duration = this.ddfData[index].durationMinutes,
                newValue = event.value,
                oldValue = event.source.ngControl.model;
            let result: number;
            if (oldValue === this.durationOptions[0]) {
                if (newValue === this.durationOptions[2]) {
                    result = duration / DAY_MIN_CONVERSION;
                } else if (newValue === this.durationOptions[1]) {
                    result = duration / HR_MIN_CONVERSION;
                }
            } else if (oldValue === this.durationOptions[2]) {
                if (newValue === this.durationOptions[1]) {
                    result = duration * HR_DAY_CONVERSION;
                } else if (newValue === this.durationOptions[0]) {
                    result = duration * DAY_MIN_CONVERSION;
                }
            } else if (oldValue === this.durationOptions[1]) {
                if (newValue === this.durationOptions[0]) {
                    result = duration * HR_MIN_CONVERSION;
                } else if (newValue === this.durationOptions[2]) {
                    result = duration / HR_DAY_CONVERSION;
                }
            }
            this.ddfData[index].durationMinutes = result;
            this.applyDdfSource(this.ddfData, false);
        }
    }
    /** To add new row in ddf table */
    public addNewRow() {
        this.disableSaveButton(false);
        this.clearSelectedRow();
        this.ddfData.push({
            month1: null,
            month2: null,
            month3: null,
            month4: null,
            month6: null,
            month9: null,
            year1: null,
            year2: null,
            year5: null,
            year10: null,
            year25: null,
            year50: null,
            year100: null,
            year200: null,
            year500: null,
            year1000: null,
            durationvalue: null,
            durationMinutes: null,
            duration: this.minute,
        });
        this.applyDdfSource(this.ddfData, false);
    }
    /** To delete rows in ddf table **/
    public deleteRow() {
        this.rainfallDataColumns.unshift(this.deleteColumn);
        this.deleteButton = true;
    }

    public deleteSelectedRow() {
        this.ddfData = this.ddfTableSource.data.filter((elements) => !elements.checked);
        this.applyDdfSource(this.ddfData, false);
        this.ddfGraph(this.ddfData, this.unit);
        this.snackBarNotificationService.raiseNotification(this.rainFallDepthDurationRowDeletionMsg, this.dismissBtn);
        this.disableSaveButton(false);
        this.clearSelectedRow();
    }

    public clearSelectedRow() {
        if (this.rainfallDataColumns.some((column: string) => column === this.deleteColumn)) {
            this.rainfallDataColumns.shift(this.deleteColumn);
        }
        this.deleteButton = false;
        this.isDepthDurationHeaderChecked = false;
        if (this.ddfTableSource && this.ddfTableSource.data) {
            this.ddfTableSource.data.forEach((item) => (item.checked = false));
        }
    }

    public depthDurationCheckBoxAll(event: MatCheckbox) {
        if (this.ddfTableSource.data) {
            this.ddfTableSource.data.forEach((item) => (item.checked = event.checked));
        }
        this.applyDdfSource(this.ddfData, false);
    }

    public depthDurationCheckBox(event: MatCheckbox) {
        if (this.ddfTableSource.data) {
            const selectedItems = this.ddfTableSource.data.filter((item) => item.checked) || [];
            this.isDepthDurationHeaderChecked = this.ddfTableSource.data.length === selectedItems.length;
        }
    }
    /** DDF Graph */
    public ddfGraph(ddfValues: DdfTable[], unitOfMeasure: string) {
        this.data.forEach((dataValue) => {
            this.finalData[dataValue] = [];
            this.tempData[dataValue] = [];
        });

        ddfValues.forEach((element) => {
            this.data.forEach((val) => {
                const value: number = !element[val] || element[val] === '' ? 0 : parseFloat(element[val]);
                if (!!value) {
                    this.tempData[val].push(element['durationMinutes']);
                    this.tempData[val].push(value);
                    this.finalData[val].push(this.tempData[val]);
                    this.tempData[val] = [];
                }
            });
        });

        const seriesData = [];
        this.data.forEach((series) => {
            const points = this.finalData[series];
            if (points && points.length > 0) {
                const obj = {
                    name: this.splitLegendText(series),
                    data: this.finalData[series],
                    pointStart: 5,
                    showInLegend: false,
                    color: '#999',
                };
                seriesData.push(obj);
            }
        });
        this.ddfGraphComponent.generateDdfGraph(seriesData);
    }

    public ngOnDestroy() {
        if (this.ddfGraphComponent.chart) {
            this.ddfGraphComponent.chart.destroy();
        }
    }

    /**
     * This method will modify ddf chart legend from month-year to year-month
     * @param {String} value: receive as legend string as parameter
     */
    public splitLegendText(value: string) {
        const matchVal = value ? value.match(/\d+/) : value;
        if (matchVal) {
            const numInValue = matchVal[0];
            const index = value.indexOf(numInValue);
            const txt = value.substring(0, index) + ',' + value.substring(index);
            return txt.split(',')[1] + ' ' + txt.split(',')[0];
        } else {
            return value;
        }
    }

    /** To get ddf data*/
    public getDepthDurationFrequency() {
        this.isLoadingState = true;
        this.customerService.getDepthDuration(this.customerDetails.customerID).subscribe(
            (result: DepthDurationFrequency) => {
                if (result.ddfTable) {
                    result.ddfTable.forEach((element) => {
                        element.durationvalue = element.durationMinutes;
                        element.duration = this.minute;
                        element.month2 = this.applyUnitRounding(element.month2);
                        element.month3 = this.applyUnitRounding(element.month3);
                        element.month4 = this.applyUnitRounding(element.month4);
                        element.month6 = this.applyUnitRounding(element.month6);
                        element.month9 = this.applyUnitRounding(element.month9);
                        element.year1 = this.applyUnitRounding(element.year1);
                        element.year2 = this.applyUnitRounding(element.year2);
                        element.year5 = this.applyUnitRounding(element.year5);
                        element.year10 = this.applyUnitRounding(element.year10);
                        element.year25 = this.applyUnitRounding(element.year25);
                        element.year50 = this.applyUnitRounding(element.year50);
                        element.year100 = this.applyUnitRounding(element.year100);
                        element.year200 = this.applyUnitRounding(element.year200);
                        element.year500 = this.applyUnitRounding(element.year500);
                        element.year1000 = this.applyUnitRounding(element.year1000);
                    });
                    this.ddfData = result.ddfTable;
                    this.ddf = result;
                    this.applyDdfSource(this.ddfData);
                    this.ddfGraph(this.ddfData, this.unit);
                } else {
                    this.ddfData = [];
                    this.addNewRow();
                    this.applyDdfSource(this.ddfData);
                }
                this.isLoadingState = false;
            },
            (error) => {
                this.isLoadingState = false;
            },
        );
    }

    /** To save DDF table data */
    public saveDepthDurationFrequency() {
        this.isLoadingState = true;
        if (this.ddfData) {
            const durations = this.ddfData.map((v) => ({ type: v.duration, value: v.durationMinutes }));
            const tableDataCopy = this.ddfData.map((x) => Object.assign({}, x));
            // Fix for #21101 - values are stored as number instead of string, convert them.
            tableDataCopy.forEach((row) => {
                Object.keys(row).forEach(k => {
                    if (k.startsWith('month') || k.startsWith('year')) {
                        const v = row[k];
                        row[k] = v && v.toFixed ? v.toFixed(2) : v;
                    }
                })
                if (row.duration === this.hr) {
                    row.durationvalue = row.durationMinutes * HR_MIN_CONVERSION;
                } else if (row.duration === this.days) {
                    row.durationvalue = row.durationMinutes * DAY_MIN_CONVERSION;
                } else {
                    row.durationvalue = row.durationMinutes;
                }
            });

            let ddf = {};
            this.ddfData.forEach((element) => {
                if (element.duration === this.hr) {
                    element.durationMinutes = element.durationMinutes * HR_MIN_CONVERSION;
                } else if (element.duration === this.days) {
                    element.durationMinutes = element.durationMinutes * DAY_MIN_CONVERSION;
                }
                element.duration = this.minute;
            });
            this.ddfGraph(this.ddfData, this.unit);
            if (this.ddf) {
                ddf = {
                    ddfTable: this.ddfData,
                    pk: this.ddf.pk,
                    id: this.ddf.id,
                    docType: this.ddf.docType,
                };
            } else {
                ddf = {
                    ddfTable: this.ddfData,
                };
            }
            this.customerService.saveDepthDuration(this.customerDetails.customerID, ddf).subscribe(
                (result: DepthDurationFrequency[]) => {
                    if (result) {
                        this.updateUserConfig(durations);
                        this.ddfData = tableDataCopy;
                        this.applyDdfSource(this.ddfData);
                        this.snackBarNotificationService.raiseNotification(this.savedDdfText, this.dismissBtn, {
                            duration: SNACKBAR_DURATION,
                        });
                    }
                    this.disableSaveButton(true);
                    this.isLoadingState = false;
                },
                (error) => {
                    this.disableSaveButton(true);
                    this.isLoadingState = false;
                    this.snackBarNotificationService.raiseNotification(this.failedDdfText, this.dismissBtn, {}, false);
                },
            );
        }
    }

    /**
      Method to create storm or dry day definition on form submission
    **/
    public saveRainEventInfo(definitionEvent) {
        this.isLoadingState = true;
        if (definitionEvent === 'stormDefinition' && this.customerDetails) {
            this.createStormDefinition();
        } else if (definitionEvent === 'dryDefinition' && this.customerDetails) {
            this.createDryDayDefinition();
        }
    }

    public saverange()
    {
        this.disableSaveButton(false);
    }

    public disableSaveButton(changeEvent: boolean)
    {
        this.ddfFormChange = false;
        if(!changeEvent) {
            this.ddfFormChange = true;
            this.rainfallProfileChanged.emit(true);
        }
        
        var saveElement = this.elementRef.nativeElement.getElementsByClassName('DDSave');

        if (saveElement && saveElement.length > 0) {
            saveElement[0].disabled = changeEvent;
        }
    }

    /**
      Method to get storm definition of currently editing customer in created previously
    **/
    public getStormDifinitionData() {
        this.customerService.getStormDefinition(this.customerDetails.customerID).subscribe(
            (result: StormDefinition[]) => {
                if (result.length > 0) {
                    const { unitsType } = this.customerDetails;
                    const isMetric = unitsType === UnitOfMeasureType.METRIC;

                    this.stormDefinitionDetails.minRainfallThreshold = isMetric ? Number(result[0].minRainfallThreshold).toFixed(1) : Number(result[0].minRainfallThreshold).toFixed(2) || '0';
                    this.stormDefinitionDetails.minimumTimeBetweenEventsMinutes =
                        result[0].minimumTimeBetweenEventsMinutes >= 0
                            ? result[0].minimumTimeBetweenEventsMinutes
                            : undefined;
                    this.stormDefinitionDetails.id = result[0].id || undefined;
                }
                this.isLoadingState = false;
            },
            (error) => {
                this.isLoadingState = false;
            },
        );
    }

    /**
      Method to create/update storm definition calls customer service, inturn for definition creation
    **/
    public createStormDefinition() {
        const stormDefinition: StormDefinition = {
            minRainfallThreshold: this.stormDefinitionDetails.minRainfallThreshold,
            minimumTimeBetweenEventsMinutes: this.stormDefinitionDetails.minimumTimeBetweenEventsMinutes,
            id: this.stormDefinitionDetails.id,
        };
        this.customerService.createStormDefinition(this.customerDetails.customerID, stormDefinition).subscribe(
            (result) => {
                this.snackBarNotificationService.raiseNotification(this.stormCreationSuccessfultxt, this.dismissBtn, {
                    duration: SNACKBAR_DURATION,
                });
                this.getStormDifinitionData();
            },
            (error) => {
                this.snackBarNotificationService.raiseNotification(this.stormCreationFailurefultxt, this.dismissBtn, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                this.isLoadingState = false;
            },
        );
    }

    /**
      Method to get dry definition of currently editing customer if created previously
    **/
    public getDryDefinitionData() {
        this.customerService.getDryDayDefinition(this.customerDetails.customerID).subscribe(
            (result: DryDayDefinition) => {
                if (result) {
                    this.dryDefinitionDetails.id = result.id;
                    this.dryDefinitionDetails.minimumFlowPercentage = result.minFlowPercentage;
                    this.dryDefinitionDetails.maximumFlowPercentage = result.maxFlowPercentage;
                    if (result.dailyMaximum) {
                        result.dailyMaximum.forEach((maximObject: DailyMaximum) => {
                            if (maximObject.dayCount === 1) {
                                this.dryDefinitionDetails.minRainPrevOneDay = this.addZeroes(maximObject.accumulation.toString());
                            } else if (maximObject.dayCount === 3) {
                                this.dryDefinitionDetails.minRainPrevThreeDays = this.addZeroes(maximObject.accumulation.toString());
                            } else if (maximObject.dayCount === 5) {
                                this.dryDefinitionDetails.minRainPrevFiveDays = this.addZeroes(maximObject.accumulation.toString());
                            }
                        });
                    }
                }
                this.isLoadingState = false;
            },
            (error) => {
                this.isLoadingState = false;
            },
        );
    }

    public addZeroes(num) {
            var value: number;
            var returnVal: string;
            value = Number(num);
            var res = num.split(".");
            if(res.length >= 1) {
                if(this.IsUnitMetric)
                returnVal = value.toFixed(1);
                else
                returnVal = value.toFixed(2);
            }
        return returnVal;
        }

    /**
      Method to create/update Dry day definition, calls customer service inturn for definition creation
    **/
    public createDryDayDefinition() {
        const dryDefinitionObject: DryDayDefinition = {
            id: this.dryDefinitionDetails.id,
            maxFlowPercentage: this.dryDefinitionDetails.maximumFlowPercentage,
            minFlowPercentage: this.dryDefinitionDetails.minimumFlowPercentage,
            dailyMaximum: [
                {
                    dayCount: 1,
                    accumulation: Number(this.dryDefinitionDetails.minRainPrevOneDay),
                },
                {
                    dayCount: 3,
                    accumulation: Number(this.dryDefinitionDetails.minRainPrevThreeDays),
                },
                {
                    dayCount: 5,
                    accumulation: Number(this.dryDefinitionDetails.minRainPrevFiveDays),
                },
            ],
        };
        this.customerService.createDryDefinition(this.customerDetails.customerID, dryDefinitionObject).subscribe(
            (result) => {
                this.snackBarNotificationService.raiseNotification(this.dryDayCreationSuccessfultxt, this.dismissBtn, {
                    duration: SNACKBAR_DURATION,
                });
                this.getDryDefinitionData();
            },
            (error) => {
                this.snackBarNotificationService.raiseNotification(this.dryDayCreationFailuretxt, this.dismissBtn, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                this.isLoadingState = false;
            },
        );
    }

    public applyUnitRounding(value: number): any {
        if (!value) {
            return value;
        }
        const place: number = Number(this.customerUnit) === 2 ? 1 : 2;
        return Number(value).toFixed(place);
    }

    public onShowDDFColumnChange(name: string, event: MatCheckboxChange) {
        this.disableSaveButton(false);
        const columnDef = this.ddfTableColumnDefs.find((v) => v.name === name);
        columnDef.show = event.checked;

        this.updateDDFColumns();
    }

    private updateDDFColumns() {
        this.rainfallDataColumns = this.ddfTableColumnDefs.filter((v) => v.show).map((v) => v.name);
    }

    private applyTranslations() {
        const translationKeys: Array<string> = [
            'COMMON.DISMISS_TEXT',
            'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_CREATION_SUCCESSFUL',
            'CUSTOMER_EDITOR.RAIN_PROFILE.DDF.DDF_CREATION_SUCCESSFUL',
            'CUSTOMER_EDITOR.RAIN_PROFILE.DDF.DDF_CREATION_FAILED',
            'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_CREATION_FAILED',
            'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAY_EVENT_CREATION_SUCCESSFUL',
            'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAY_EVENT_CREATION_FAILURE',
            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.DELETED_ROW_SUCCESS_MESSAGE',
            'VAULT.VAULT_DASHBOARD.FILES_UPLOAD_SUCCESS',
            'COMMON.DURATION_MINUTE',
            'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.IN',
            'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MM',
            'CUSTOMER_EDITOR.RAIN_PROFILE.METHOD_CREATION_SUCCESSFUL',
            'CUSTOMER_EDITOR.RAIN_PROFILE.METHOD_CREATION_FAILED',
            'CUSTOMER_EDITOR.RAIN_PROFILE.DDF.FILE_TYPE_ERR_DDF_FORMAT',
            'COMMON.HR',
            'COMMON.MIN',
            'COMMON.DAYS',
        ];
        this.translate.get(translationKeys).subscribe((values) => {
            this.hr = values['COMMON.HR'];
            this.minute = values['COMMON.MIN'];
            this.days = values['COMMON.DAYS'];
            this.dismissBtn = values['COMMON.DISMISS_TEXT'];
            this.stormCreationSuccessfultxt =
                values['CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_CREATION_SUCCESSFUL'];
            this.savedDdfText = values['CUSTOMER_EDITOR.RAIN_PROFILE.DDF.DDF_CREATION_SUCCESSFUL'];
            this.failedDdfText = values['CUSTOMER_EDITOR.RAIN_PROFILE.DDF.DDF_CREATION_FAILED'];
            this.savedMethod = values['CUSTOMER_EDITOR.RAIN_PROFILE.METHOD_CREATION_SUCCESSFUL'];
            this.failedToSave = values['CUSTOMER_EDITOR.RAIN_PROFILE.METHOD_CREATION_FAILED'];
            this.badDDFFormatText = values['CUSTOMER_EDITOR.RAIN_PROFILE.DDF.FILE_TYPE_ERR_DDF_FORMAT'];
            this.stormCreationFailurefultxt =
                values['CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_CREATION_FAILED'];
            this.dryDayCreationSuccessfultxt =
                values['CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAY_EVENT_CREATION_SUCCESSFUL'];
            this.dryDayCreationFailuretxt =
                values['CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAY_EVENT_CREATION_FAILURE'];
            this.rainFallDepthDurationRowDeletionMsg =
                values['CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.DELETED_ROW_SUCCESS_MESSAGE'];
            this.filesUploadSuccessMsg = values['VAULT.VAULT_DASHBOARD.FILES_UPLOAD_SUCCESS'];
            this.unitInInch = values['CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.IN'];
            this.unitInMM = values['CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MM'];
        });
    }

    // TODO: WPS - have a single function for arrow events that accepts
    //       the index and a direction
    public arrowUpEvent(index: number) {
        event.preventDefault();
        const nextrow = this.ddfTableSource.data[index - 1];
        if (nextrow) {
            this.setPointer(index - 1);
        }
    }

    public arrowDownEvent(index: number) {
        event.preventDefault();
        const nextrow = this.ddfTableSource.data[index + 1];
        if (nextrow) {
            this.setPointer(index + 1);
        }
    }

    public arrowLeftEvent(index: number) {
        event.preventDefault();
        if (this.columnIndex > 0) {
            this.columnIndex--;
        } else {
            index--;
            this.columnIndex = 15;
        }
        const nextrow = this.ddfTableSource.data[index];
        if (nextrow) {
            this.setPointer(index, false);
        }
    }

    public arrowRightEvent(index: number) {
        event.preventDefault();
        if (this.columnIndex < 15) {
            this.columnIndex++;
        } else {
            index++;
            this.columnIndex = 0;
        }
        const nextrow = this.ddfTableSource.data[index];
        if (nextrow) {
            this.setPointer(index);
        }
    }

    private getNextColumnIndex(index: number, isRight = true) {
        let columnIndex = -1;

        while (columnIndex === -1) {
            columnIndex = this.rainfallDataColumns.indexOf(this.ddfTableColumnDefs[index].name);

            index = isRight ? index + 1 : index - 1;
        }

        return columnIndex;
    }

    public setPointer(index: number, isRight = true) {
        const displayedColumnIndex = this.getNextColumnIndex(this.columnIndex, isRight)

        this.columnIndex = this.ddfTableColumnDefs.findIndex(v => v.name === this.rainfallDataColumns[displayedColumnIndex]);

        this.matTable
            ._getRenderedRows(this.matTable._rowOutlet)
        [index].children[displayedColumnIndex].getElementsByTagName('input')[0]
            .focus();
    }

    public setColumn(index) {
        this.columnIndex = index;
    }

    private initializeChartSettings() {
        const { unitsType } = this.customerDetails;
        const isMetric = unitsType === UnitOfMeasureType.METRIC;

        this.unit = isMetric ? this.unitInMM : this.unitInInch;
        this.IsUnitMetric = (this.unit == this.unitInMM)? true : false;

    }

    private populateDurationsFromUserConfig(durations: { type: string; value: number }[]) {
        if (!durations || !this.ddfData || this.ddfData.length !== durations.length) {
            return;
        }

        durations.forEach((v, i) => {
            const ddfItem = this.ddfData[i];
            let durationValue: number;

            if (v.type === this.durationOptions[0]) {
                durationValue = v.value;
            } else if (v.type === this.durationOptions[1]) {
                durationValue = v.value * HR_MIN_CONVERSION;
            } else {
                durationValue = v.value * DAY_MIN_CONVERSION;
            }

            if (ddfItem.durationMinutes !== durationValue) {
                return;
            }

            ddfItem.duration = v.type;
            ddfItem.durationMinutes = v.value;
        });
    }

    private getUserConfig() {
        this.customerService.getSliicerUserConfig(this.customerDetails.customerID).subscribe((data) => {
            this.userConfig = data;

            if (!this.userConfig) {
                return;
            }

            this.userConfig.hiddenColumns.forEach((v) => this.onShowDDFColumnChange(v, { checked: false } as any));

            if (this.userConfig.otherConfig) {
                const durations = JSON.parse(this.userConfig.otherConfig);
                this.populateDurationsFromUserConfig(durations);
            }
        });
    }

    private updateUserConfig(durations: { type: string; value: number }[]) {
        const viewableColumns = this.ddfTableColumnDefs.filter((v) => v.show).map((v) => v.name);
        const hiddenColumns = this.ddfTableColumnDefs.filter((v) => !v.show).map((v) => v.name);

        this.customerService
            .postSliicerUserConfig(this.customerDetails.customerID, {
                ...this.userConfig,
                viewableColumns,
                hiddenColumns,
                otherConfig: JSON.stringify(durations),
            })
            .subscribe();
    }
}
