import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject,
    OnDestroy,
    Optional,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { AuthenticationType, ModemSetup } from '../modem-setup.model';
import { ModemSetupService } from 'app/shared/services/modem-setup.service';
import { Subscription } from 'rxjs';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Selectable } from 'app/shared/models/selectable';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'ads-add-modem-setup',
    templateUrl: './add-modem-setup.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddModemSetupComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public isEditMode = false;
    public showPasswordChars = true;
    public modemSetupType: number;
    public modemSetupTypes: Array<Selectable>;
    public modemAuthenticationType = AuthenticationType.PAP;
    public modemAuthenticationTypes: Array<Selectable>;
    public modemSetup: ModemSetup;
    public alphaNumericPattern = REGEX_CONFIG.alphaNumericPattern;
    public alphaNumericWithSpecialSymbolsPattern = REGEX_CONFIG.alphaNumericWitSpecialSymbols_Pattern;
    public availableProviders: Array<string>;
    public isDuplicateProvider: boolean;
    // private members
    private dismissMessageLink: string;
    private successMessage: string;
    private failureMessage: string;
    private isRequestProcessing: boolean;
    private pageSubscriptions = new Array<Subscription>(0);

    public trackById = TrackBy.byId;
    constructor(
        public dialogRef: MatDialogRef<AddModemSetupComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: { modem: ModemSetup; availableProviders: Array<string> },
        private modemSetupService: ModemSetupService,
        private notificationService: SnackBarNotificationService,
        private translateService: TranslateService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit() {
        this.applyTranslations();
        this.initialize();
    }

    public ngOnDestroy() {
        if (this.pageSubscriptions && this.pageSubscriptions.length) {
            this.pageSubscriptions.forEach((s) => s.unsubscribe());
        }
    }

    public save() {
        this.validateProvider(this.modemSetup.provider);
        if (this.isDuplicateProvider) {
            return;
        }

        if (this.isRequestProcessing) {
            return;
        }
        this.isRequestProcessing = true;
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        const apiRequest = this.isEditMode
            ? this.modemSetupService.update(this.modemSetup.provider, this.modemSetup)
            : this.modemSetupService.create(this.modemSetup);

        const subs = apiRequest.subscribe(
            () => {
                this.completeProcessing();
                this.snackBarNotification(`${this.modemSetup.provider} ${this.successMessage}`, null, this.isEditMode, true);
                this.close();
            },
            () => {
                this.completeProcessing();
                this.snackBarNotification(`${this.failureMessage}`, 'custom-error-snack-bar', false, true);
            },
        );

        this.pageSubscriptions.push(subs);
    }

    public close() {
        this.dialogRef.close({ success: false });
    }

    public validateProvider(provider: string) {
        if (this.isEditMode && this.modemSetup.provider.toLocaleLowerCase() === provider.toLocaleLowerCase().trim()) {
            this.isDuplicateProvider = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }
        this.isDuplicateProvider =
            this.availableProviders.findIndex((p) => p.toLocaleLowerCase() === provider.toLocaleLowerCase().trim()) >
            -1;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private applyTranslations() {
        const translateKeys: Array<string> = [
            'COMMON.DISMISS_TEXT',
            'COMMON.SUCCESSFULLY_MESSAGE',
            'COMMON.API_FAILURE_MESSAGE',
        ];

        this.translateService.get(translateKeys).subscribe((translateValues) => {
            this.dismissMessageLink = translateValues['COMMON.DISMISS_TEXT'];
            this.successMessage = translateValues['COMMON.SUCCESSFULLY_MESSAGE'];
            this.failureMessage = translateValues['COMMON.API_FAILURE_MESSAGE'];
        });
    }
    private initialize() {
        if (this.data) {
            this.modemSetup = this.data.modem || <ModemSetup>{ authenticationType: AuthenticationType.PAP };
            this.isEditMode = !!this.data.modem;
            this.availableProviders = this.data.availableProviders;
        }

        this.modemAuthenticationTypes = [
            { id: AuthenticationType.None, name: 'NONE' },
            { id: AuthenticationType.PAP, name: 'PAP' },
            { id: AuthenticationType.CHAP, name: 'CHAP' },
        ];
        this.modemAuthenticationType = AuthenticationType.PAP;
    }

    private snackBarNotification(message: string, notificationClass, isEdited, dismiss) {
        const translateKey = isEdited ? 'COMMON.UPDATED_TEXT' : 'COMMON.CREATED_TEXT';
        this.translateService.get(translateKey).subscribe((value) => {
            message = message.replace('{0}', value);
        });
        this.notificationService.raiseNotification(message, this.dismissMessageLink, {
            panelClass: notificationClass,
        }, dismiss);
    }

    private completeProcessing() {
        this.isLoading = false;
        this.isRequestProcessing = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
}
