import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { FormControl } from '@angular/forms';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { SelectableGroup } from 'app/shared/models/selectable';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-multi-select-group',
    templateUrl: './multi-select-group.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectGroupComponent implements OnInit, OnChanges {
    /**
     * Represents the collection of entities.
     */
    @Input() public items: Array<SelectableGroup>;

    /**
     * Defines the placeholder string value for the control.
     */
    @Input() public placeholder: string;

    /**
     * Represents original placholder string value
     */
    public initialPlaceholder: string;

    /**
     * Represents the minimu number that can be selected.
     */
    @Input() public minDisplayLimit = 0;

    /**
     * Represents the maximum numbers that can be selected.
     */
    @Input() public maxDisplayLimit: number;

    /**
     * Preselected items
     */
    @Input() public preselectedItems: Array<SelectableGroup>;

    /**
     * Indicates whether to show the "All" feature. Enabled by default.
     */
    @Input() public isShowAll = false;

    /**
     * Indicates whether to toggle id's 4 and 5 to have mutual exclusivity.
     */
    @Input() public isSiteCommentary = false;

    /**
     * Emits the currently selected items.
     */
    @Output() public currentlySelectedItems = new EventEmitter<Array<SelectableGroup>>();

    /**
     * Represents the colleciton of material check boxes
     */
    @ViewChildren(MatListOption) private options: QueryList<MatListOption>;

    /**
     * Represents teh search term control.
     */
    public searchString = new FormControl();

    /**
     * Represents a value indicating whether the selection list is visible.
     */
    public isResultsVisible = false;

    /**
     * Reresents a random number value that will be added to the data-id of the
     * element and will serve as a poor mans unique identifier.
     */
    public surrogateIdentifier = Math.ceil(Math.random() * Number.MAX_VALUE);

    /**
     * Represents a value indicating if an option was changed.
     */
    public isOptionsTouched = false;

    /**
     * Represents a value indicating if all the options option was selected.
     */
    public isAllSelected = false;

    /**
     * Indicates a state of validity for the input.
     */
    public isValid = true;

    /**
     * Represents a value indicating whether the selection list has focus.
     */
    private isSelectionListFocused = false;

    public isExceedLimit = false;

    public initPlaceholderText: string;

    readonly DTR_SAME_AVERAGING_AS_HYDROGRAPHS = 5;
    readonly DTR_NONE_AVERAGING = 4;

    /**
     * Represents a value indicating whether the display limit has been reached.
     */
    private get isUpperLimitReached(): boolean {
        return this.items.filter((selectable) => selectable.isChecked).length >= this.maxDisplayLimit;
    }

    public itemList: Array<SelectableGroup>;
    public orginalItemList: Array<SelectableGroup>;
    public itemGroupNameList: Array<Object>;
    public selectedItemList: Array<SelectableGroup>;

    public itemGroupByList: SelectableGroup[][] = [];

    public isSearching: boolean;

    public trackByIndex = TrackBy.byIndex;
    public trackById = TrackBy.byId;
    constructor(
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private domOperationUtilsService: DomOperationUtilsService,
    ) {
        this.searchString.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((res: string) => {
            this.filterEntity(res);
        });
    }

    public ngOnInit() {
        this.initialPlaceholder = this.placeholder;
        if (this.items) {
            // If in fmr dtr averaging, default to NONE
            if (this.isSiteCommentary) {
                let avg = this.items.find((s: SelectableGroup) => s.id === this.DTR_SAME_AVERAGING_AS_HYDROGRAPHS);
                avg.isChecked = false;
            }

            this.orginalItemList = this.items.length > 1 ? this.sortByName() : this.items;
            this.itemList = this.orginalItemList;

            this.loadGroupByEntity();
        }
    }
    /**
     * Method takes a sting as an argument and filters out customers whose name include the string
     * @param searchString - string to search for in customer name
     */
    public filterEntity(searchString: string) {
        if (!this.isResultsVisible) {
            return;
        }
        this.isSearching = false;
        if (!searchString || searchString.trim() === '') {
            this.itemList = this.orginalItemList;
        } else {
            searchString = searchString.trim();
            this.itemList = this.orginalItemList.filter((x) =>
                x.name.toLowerCase().includes(searchString.toLowerCase()),
            );
            this.isSearching = true;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.loadGroupByEntity();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (this.items) {
            // this.orginalItemList = this.items.filter((i: SelectableGroup) => i.name).sort();
            this.orginalItemList = this.items.length > 1 ? this.sortByName() : this.items;
            this.itemList = this.orginalItemList;
            this.loadGroupByEntity();
        }
    }

    public reset(items) {
        this.items = items;
        this.orginalItemList = this.items;
        this.sortByName();
        this.itemList = this.orginalItemList;
        this.loadGroupByEntity();
    }

    private loadGroupByEntity() {
        if (this.items) {
            this.selectedItemList = this.itemList.filter((i: SelectableGroup) => i.isChecked).sort();
            this.itemGroupNameList = this.itemList
                .map((i: SelectableGroup) => i.groupName)
                .sort()
                .filter((el, i, a) => i === a.indexOf(el));
            this.itemGroupNameList.forEach((item) => {
                this.itemGroupByList[item.toString()] = this.itemList.filter(
                    (a: SelectableGroup) => a.groupName === item && a.isChecked !== true,
                );
            });
            // this.itemGroupByList['Selected'] = this.selectedItemList;
            if (!this.isResultsVisible) {
                this.setSelectedPlaceholderValue();
            }
            // set if upper limit is reached
            this.isExceedLimit = this.isUpperLimitReached;
            this.isValid =
                !this.isExceedLimit &&
                !(this.orginalItemList.filter((selectable) => selectable.isChecked).length < this.minDisplayLimit);

            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    /**
     * Handles focus event on input search field.
     * @param event Reprsents the focus event.
     */
    public onInputFocusIn(event: FocusEvent) {
        // get target element
        // let inputEl = <HTMLInputElement>event.target;

        // set vvalue to nothing
        // inputEl.value = '';
        this.initPlaceholderText = '';

        // enable selection menu
        this.isResultsVisible = true;
    }

    /**
     * Handles focusout event on input search field.
     * @param event Reprsents the focus event.
     */
    public onInputFocusOut(event: FocusEvent) {
        // if list visible but is not focused
        if (
            this.isSameElementClicked(<HTMLElement>event.relatedTarget) &&
            (this.isResultsVisible || this.isSelectionListFocused)
        ) {
            return;
        }

        // get target element
        const inputEl = <HTMLInputElement>event.target;

        // set vvalue to nothing
        // inputEl.value = '';

        if (this.isOptionsTouched) {
            // emit items
            this.currentlySelectedItems.emit(this.orginalItemList.filter((i: SelectableGroup) => i.isChecked).sort());
        }

        // hide results visible
        this.isOptionsTouched = this.isResultsVisible = false;
        if (!this.isResultsVisible) {
            this.setSelectedPlaceholderValue();
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Handles focus event on selection drop down.
     * @param event Reprsents the focus event.
     */
    public onSelectionListFocused(event: FocusEvent): void {
        this.isSelectionListFocused = true;
    }

    public onSelectionListNotFocused(event: FocusEvent): void {
        // if list visible but is not focused
        if (
            this.isSameElementClicked(<HTMLElement>event.relatedTarget) &&
            (this.isResultsVisible || this.isSelectionListFocused)
        ) {
            return;
        }

        // tslint:disable-next-line:no-any
        if (event.relatedTarget && (<any>event.relatedTarget).localName === 'mat-list-option') {
            return;
        }
        // tslint:disable-next-line:no-any
        if (event.relatedTarget && (<any>event.relatedTarget).localName === 'mat-selection-list') {
            return;
        }
        // tslint:disable-next-line:no-any
        if (event.relatedTarget && (<any>event.relatedTarget).localName === 'mat-expansion-panel-header') {
            return;
        }

        // if a sibling element (i.e, input) is not the focus than click outside occured and so notify
        if (!this.isSameElementClicked(<HTMLElement>event.relatedTarget) && this.isOptionsTouched) {
            // emit items
            this.currentlySelectedItems.emit(this.orginalItemList.filter((i: SelectableGroup) => i.isChecked).sort());
        }

        // clear search input
        //  this.searchInput.value = '';

        // reset state
        this.isResultsVisible = this.isOptionsTouched = this.isSelectionListFocused = false;
        if (!this.isResultsVisible) {
            this.setSelectedPlaceholderValue();
        }

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    private setSelectedPlaceholderValue(): void {
        const checkedItems = this.orginalItemList.filter((i: SelectableGroup) => i.isChecked).sort();
        let placeholderText = '';
        if (checkedItems.length > 0) {
            placeholderText = checkedItems.map((itm: SelectableGroup) => itm.name).join(', ');
        }
        this.initPlaceholderText = placeholderText;
        this.initialPlaceholder = '';
        this.initialPlaceholder = `${this.placeholder} : ${checkedItems.length} selected`;
        // this.currentlySelectedItems.emit(checkedItems);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public onSelectAllChange(event) {
        this.isOptionsTouched = true;
        // mark all selected as the reverse of what it was prior
        this.isAllSelected = !this.isAllSelected;

        // mark all items as selected based on the all selected status
        this.orginalItemList.forEach((selectable: SelectableGroup) => (selectable.isChecked = this.isAllSelected));
        if (this.isSiteCommentary) {
            // Fix select all behavior for fmr dtr averaging
            let noneAvg = this.orginalItemList.find((s: SelectableGroup) => s.id === this.DTR_NONE_AVERAGING);
            noneAvg.isChecked = true;
            let sameAvg = this.orginalItemList.find((s: SelectableGroup) => s.id === this.DTR_SAME_AVERAGING_AS_HYDROGRAPHS);
            sameAvg.isChecked = false;
        }


        // mark all options as selected based on the all selected status
        this.options.forEach((option: MatListOption) => (option.selected = this.isAllSelected));
    }

    public onSelectionChange(event: MouseEvent, selectedId): void {

        this.isOptionsTouched = true;

        const foundOrginalItem = this.orginalItemList.find((s: SelectableGroup) => s.id === selectedId);
        // ensure that item can be selected
        if (this.isExceedLimit && !foundOrginalItem.isChecked) {
            // set placeholder
            // this.setSelectedPlaceholderValue();
            // exit immediately
            return;
        }

        // set checked
        if (this.isSiteCommentary) {
            if (foundOrginalItem.id === this.DTR_NONE_AVERAGING) {
            let dtr = this.orginalItemList.find((s: SelectableGroup) => s.id === this.DTR_SAME_AVERAGING_AS_HYDROGRAPHS)
            dtr.isChecked = false;
            }
            else if (foundOrginalItem.id === this.DTR_SAME_AVERAGING_AS_HYDROGRAPHS) {
                let dtr = this.orginalItemList.find((s: SelectableGroup) => s.id === this.DTR_NONE_AVERAGING)
                dtr.isChecked = false;
            }
        }

        foundOrginalItem.isChecked = !foundOrginalItem.isChecked;

        // set if upper limit is reached
        const isUpperLimitReached = this.isUpperLimitReached;

        this.isExceedLimit = isUpperLimitReached;
        // set validation state based on either lower or upper limit being reached
        this.isValid =
            !isUpperLimitReached &&
            !(this.orginalItemList.filter((selectable) => selectable.isChecked).length < this.minDisplayLimit);
        // set placeholder
        // this.setSelectedPlaceholderValue();
        // ensure that item can be selected
        if (isUpperLimitReached) {
            // set the entity list scroll to top to display the entity limit validation error message
            this.domOperationUtilsService.scrollToTop('#entityGroupLimit');
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Attempts to capture the passed in elements identifier and compare it to this one.
     * Returns true if the this elements and the passed in elements identifiers are equal;
     * otherwise false.
     * @param element Represents the element to be used for comparion.
     */
    private isSameElementClicked(element: HTMLElement): boolean {
        // ensure args
        if (!element) {
            return;
        }

        // ensure property
        if (!element.dataset.hasOwnProperty('identifier')) {
            return;
        }

        // determine if items are the same
        const result = Number(element.dataset.identifier) === this.surrogateIdentifier;

        // return result
        return result;
    }

    private sortByName() {
        // console.log(this.items);
        const sortedItems = this.items.sort((t1, t2) => {
            // console.log(t1, t2);
            const name1 = t1.name.toLowerCase();
            const name2 = t2.name.toLowerCase();
            if (name1 > name2) {
                return 1;
            }
            if (name1 < name2) {
                return -1;
            }
            return 0;
        });
        return sortedItems;
    }
}
