<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'surface-combo-section-error': form.invalid }">
        <mat-expansion-panel-header>
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 0">
                {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 1">
                {{ 'ADD_EDIT.MP2' | translate }} {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount }}
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.PHYSICAL_OFFSET')
                                )
                            "
                            formControlName="physicalOffset"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('physicalOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <div class="full-width">
                        <span class="toggle toggle-text">{{ 'ADD_EDIT.STORE_DATA' | translate }} </span>
                        <mat-slide-toggle formControlName="storeData"> </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.BLANKING')
                                )
                            "
                            formControlName="blanking"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('blanking').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'LOCATION_DASHBOARD.TEMPERATURE_SCALE_AXIS' | translate }}"
                            name="temperature"
                            formControlName="temperatureSensor"
                        >
                            <mat-option *ngFor="let temperature of sensorTemp; trackBy: trackByValue" [value]="temperature.value">
                                {{ temperature.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            formControlName="power"
                            placeholder="{{ 'ADD_EDIT.POWER' | translate }}"
                            (selectionChange)="onChangePowerValue($event)"
                        >
                            <mat-option *ngFor="let power of sensorPower; trackBy: trackByValue" [value]="power.value"
                                >{{ power.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.ELECTRONIC_OFFSET')
                                )
                            "
                            formControlName="electronicOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('electronicOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            formControlName="gainControlSelection"
                            placeholder="{{ 'ADD_EDIT.GAIN_CONTROL' | translate }}"
                            (selectionChange)="onChangeGainControlTriton($event)"
                        >
                            <mat-option *ngFor="let gaincontrol of gainControl; trackBy: trackByValue" [value]="gaincontrol.text">
                                {{ gaincontrol.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item" *ngIf="form.get('gainControlSelection').value === 'Manual'">
                    <mat-form-field class="full-width">
                      <mat-select
                          formControlName="gainControl"
                          placeholder="{{ 'ADD_EDIT.GAIN_VALUE' | translate }} (db)"
                          (selectionChange)="onChangeManualGainTriton($event.value)"
                      >
                          <mat-option *ngFor="let value of gainValues; trackBy: trackByIndex" [value]="value">
                              {{ value }}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <div class="mat-error" *ngIf="invalidManualGain">
            {{'COMMON.TRITON_OFFSET_NUMERIC_PRECISION_ERROR' | translate}}
          </div> -->
                </div>
            </div>
            <mat-panel-subtitle class="input-text">
                {{ 'ADD_EDIT.COMPENSATED_TEMPERATURE' | translate }}
            </mat-panel-subtitle>
            <div class="custom-50p text-space pad-t-15">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addTempUnitToPlaceholder(translateService.instant('ADD_EDIT.MARCH'))
                            "
                            formControlName="compensatedTemperatureMarch"
                            required
                        />
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addTempUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.SEPTEMBER')
                                )
                            "
                            formControlName="compensatedTemperatureSeptember"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="full-width pad-b-15 input-text">
                <span class="toggle toggle-text">{{ 'ADD_EDIT.SUBMERSION_SENSOR' | translate }}</span>
                <mat-slide-toggle
                    formControlName="disableCapacitance"
                    (change)="onToggleChange($event, ['logCapacitance', 'surchargeThreshold'])"
                >
                </mat-slide-toggle>
            </div>
            <div class="custom-50p text-space">
                <div class="each-Item auto-margin-t-b">
                    <div class="full-width">
                        <span class="toggle toggle-text">{{ 'ADD_EDIT.LOGGING' | translate }}</span>
                        <mat-slide-toggle formControlName="logCapacitance"></mat-slide-toggle>
                    </div>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            type="number"
                            (change)="onChangeSubmersionThreshold()"
                            placeholder="{{ 'ADD_EDIT.THRESHOLD' | translate }}"
                            formControlName="surchargeThreshold"
                            required
                        />
                    </mat-form-field>
                    <!-- <div class="mat-error" *ngIf="invalidSubmersionThreshold">
            {{ 'ADD_EDIT.SUBMERSION_THRESHOLD_ERROR_MESSAGE' | translate }}
          </div> -->
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
