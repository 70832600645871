<div class="filters-container">
    <div class="uptime-filter-item uptime-loc-filter-hint">
        <app-multi-select-auto-complete
            #multiSelectAutoCompleteLocations
            [items]="filteredLocations"
            [preselectedItems]="selectedLocations"
            [isShowAll]="true"
            (currentlySelectedItems)="handleSelectedLocations($event)"
            placeholder="{{ 'COMMON.LOCATIONS' | translate }}"
        ></app-multi-select-auto-complete>
    </div>

    <div class="uptime-filter-item uptime-entity-filter-hint">
        <app-entity-selector
            [availableEntities]="availableEntities"
            [selectedEntitiyIds]="selectedEntityIds"
            (selectedEntities)="selectedEntities($event)"
            [defaultEntities]="defaultEntities"
        >
        </app-entity-selector>
    </div>

    <div class="uptime-filter-item uptime-date-filter-hint">
        <app-date-range-picker
            [(startDate)]="startDate"
            [(endDate)]="endDate"
            [(tickInterval)]="tickInterval"
            [showAvailableDates]="false"
            (emitClose)="onDatePickerClose()"
            [dateRangeType]="'date'"
            [maxDate]="maxDate"
        ></app-date-range-picker>
    </div>
    <div class="uptime-filter-item uptime-samplerate-filter-hint">
        <mat-form-field class="full-width required">
            <mat-select
                [(value)]="selected"
                name="Sample Rate"
                (selectionChange)="selectedSampleR($event)"
                placeholder="{{ 'ADD_EDIT.SAMPLE_RATE' | translate }}"
            >
                <mat-option *ngFor="let sampleRate of sampleRList; trackBy: trackById" [value]="sampleRate.value"
                    >{{ sampleRate.text }}
                </mat-option>
                
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <button mat-button (click)="btnApplyFilters()">{{ 'COMMON.APPLY_BTN' | translate | uppercase }}</button>
    </div>
</div>
