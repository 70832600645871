import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationUser } from 'app/shared/models/notifications';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import cloneDeep from 'lodash/cloneDeep';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TrackBy } from 'app/shared/utils/track-by';

export interface NotificationTreeComponentData {
    users: NotificationUser[];
    selectedUsers: NotificationUser[];
    name: string;
    names: string[];
    delay: number;
    enabled: boolean;
    showRtn?: boolean;
    nameReadonly?: boolean;
}

@Component({
    selector: 'notification-tree-component',
    templateUrl: './notification-tree.component.html',
    styleUrls: ['./notification-tree.component.scss'],
})
export class NotificationTreeComponent implements OnInit {
    @ViewChild('enabledToggle') public enabledToggle: MatSlideToggle;
    public PAGE_TEXT: any;
    public searchString: string;

    public numeric0_1440Pattern = REGEX_CONFIG.numeric0_1440Pattern;

    // All users/admins that are options to show
    public usersList: NotificationUser[];

    // Check boxes
    public allUserAlarm = false;
    public allUserRTN = false;
    public allAdminAlarm = false;
    public allAdminRTN = false;

    // Lists to display
    public dispUsers: NotificationUser[];
    public dispAdmins: NotificationUser[];


    public trackById = TrackBy.byId;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: NotificationTreeComponentData,
        private matDialog: MatDialog,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<NotificationTreeComponent>,
    ) {
        this.translate.get('NOTIFICATION_DASHBOARD').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });
    }

    public ngOnInit() {
        this.usersList = cloneDeep(this.data.users);
        if (this.data.selectedUsers) {
            // If selected users are passed in, update the usersList accordingly
            this.data.selectedUsers.forEach((usr) => {
                const associatedUser = this.usersList.find((x) => x.id === usr.id.toUpperCase());
                if (associatedUser && usr) {
                    associatedUser.alarm = usr.alarm;
                    if (this.data.showRtn) associatedUser.rtn = usr.rtn;
                }
            });
        }
        this.dispUsers = this.usersList.filter((x) => !x.admin);
        this.allUserAlarm = !this.dispUsers?.some(a => a.alarm === false);
        this.dispAdmins = this.usersList.filter((x) => x.admin);
    }

    public toggleNotificationEnabled(event: MatSlideToggleChange) {
        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_LEVEL_CONRIM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.data.enabled = false;
                    } else {
                        this.data.enabled = true;
                        this.enabledToggle.checked = true; // To visually force it back to the previous state
                    }
                });
        } else {
            this.data.enabled = true;
        }
    }
    public trimName() {
        if (this.data.name) {
            this.data.name = this.data.name.trim();
        }
    }
    public forceDelay() {
        if (this.data.delay === null) {
            this.data.delay = 0;
        }
    }

    public close(state) {
        if (state) {
            this.data.name = this.data.name.trim();
            this.data.selectedUsers = this.usersList.filter((x) => x.alarm || (this.data.showRtn && x.rtn));
        }
        this.dialogRef.close({ save: state, data: this.data });
    }

    public updateUser(user: NotificationUser, alarm: boolean) {
        this.usersList = this.usersList.map((usr) => {
            if (usr.id === user.id) {
                alarm ? (usr.alarm = !usr.alarm) : (usr.rtn = !usr.rtn);
            }
            return usr;
        });
        this.searchUsers();
    }
    public checkAll(user: boolean, alarm: boolean) {
        // Active action of clicking the header check box
        // User true means user; false means admin
        // Alarm true means alarm; false means rtn
        if (user && alarm) {
            this.allUserAlarm = !this.allUserAlarm;
            this.dispUsers.forEach((x) => (x.alarm = this.allUserAlarm));
        } else if (user && !alarm) {
            if (this.data.showRtn) {
                this.allUserRTN = !this.allUserRTN;
                this.dispUsers.forEach((x) => (x.rtn = this.allUserRTN));
            }
        } else if (!user && alarm) {
            this.allAdminAlarm = !this.allAdminAlarm;
            this.dispAdmins.forEach((x) => (x.alarm = this.allAdminAlarm));
        } else if (!user && !alarm) {
            if (this.data.showRtn) {
                this.allAdminRTN = !this.allAdminRTN;
                this.dispAdmins.forEach((x) => (x.rtn = this.allAdminRTN));
            }
        }
    }

    public searchUsers() {
        if (this.searchString) {
            this.dispUsers = this.usersList.filter(
                (x) => !x.admin && x.dispName.toLowerCase().includes(this.searchString.toLowerCase()),
            );
            this.dispAdmins = this.usersList.filter(
                (x) => x.admin && x.dispName.toLowerCase().includes(this.searchString.toLowerCase()),
            );
        } else {
            this.dispUsers = this.usersList.filter((x) => !x.admin);
            this.dispAdmins = this.usersList.filter((x) => x.admin);
        }

        this.allUserAlarm = this.dispUsers.length > 0 && this.dispUsers.every((x) => x.alarm);
        if (this.data.showRtn) {
            this.allUserRTN = this.dispUsers.length > 0 && this.dispUsers.every((x) => x.rtn);
        }
        this.allAdminAlarm = this.dispAdmins.length > 0 && this.dispAdmins.every((x) => x.alarm);
        if (this.data.showRtn) {
            this.allAdminRTN = this.dispAdmins.length > 0 && this.dispAdmins.every((x) => x.rtn);
        }
    }

    validateNameExist() {
        return this.data.names && !this.data.names.includes(this.data.name);
    }
}
