<mat-progress-bar mode="indeterminate" color="primary" *ngIf="!isLoadingRoute"></mat-progress-bar>
<mat-toolbar [ngClass]="{'disabledDiv': uiService.isDisabled()}">
    <mat-toolbar-row id="topHeaderSection">
            <img [ngSrc]="'/assets/images/new_prism_logo.png'" class="logo" alt="ADS PRISM" height="45" width="150" />

        <span class="app-flex-filler"></span>
        <!-- Added customer and locationGroup globaly -->
        <div *ngIf="user" id="locationGroupSelect" class="non-printable top-header-each-item">
            <div class="each-section" [ngClass]="{'sep-win-selectors': sepWinService.isNewTab}">

                <div class="customersDropdown customer-selector-hint de-topbar-hint">
                    <app-auto-complete
                        [disabled]="sepWinService.isNewTab || updatesWidgetService['updates'] || (disableHeaders$ | async)"
                        [disableClear]="sepWinService.isNewTab || updatesWidgetService['updates'] || (disableHeaders$ | async)"
                        [items]="customers"
                        [preselectedItem]="selectedCustomer"
                        placeholder="{{ 'COMMON.CUSTOMER_TEXT' | translate }}"
                        [isShowAll]="false"
                        (selectedItems)="onCustomerChange($event)"
                        [focusOnClear] = "true"
                        [onlyManualClear]="true"
                        [panelWidth]="'300px'"
                        [showTooltipMinLength]="33"
                        [showAllOptionsOnFocus]="true"
                        [populateSelectedValueonFocusOut]="true"
                        [sortStartWith]="true"
                        [scrollToSelectedOnFocus]="true"
                        [markSelectedOption]="true"
                    >
                    </app-auto-complete>
                </div>
                <span *ngIf="!sepWinService.isNewTab" [ngClass]="{ disabledAction: updatesWidgetService['updates'] }" matTooltip="{{ 'TOP_NAV.CUSTOMER_EDITOR' | translate }}">
                    <button
                        id="addCustomerId"
                        mat-button
                        [disabled]="
                            updatesWidgetService['updates'] ||
                            !isCustomerCanEdit ||
                            availablePageHint ||
                            (disableHeaders$ | async)
                        "
                        (click)="openCustomerEditor()"
                        class="lightBackGround hintIcon customer-edit-svg"
                    ></button>
                </span>
            </div>

            <div [ngClass]="{'sep-win-selectors': sepWinService.isNewTab}" class="each-section location-group-hint">
                <div class="customersDropdown">
                    <mat-form-field>
                        <mat-label>{{ 'TOP_NAV.LOCATION_GROUP_PLACEHOLDER' | translate }}</mat-label>
                        <mat-select *ngIf="isLocationGroupSelectDisabled | async; else locationGroupSelectEnabled" disabled>
                            <mat-option [value]="0">{{textCommonAll}}</mat-option>
                        </mat-select>
                        <ng-template #locationGroupSelectEnabled>
                            <mat-select [(ngModel)]="locationGroupID" [disabled]="updatesWidgetService['updates'] || (disableHeaders$ | async)" (ngModelChange)="onChangeLocationGroup($event)">
                                <mat-option [value]="0">{{textCommonAll}}</mat-option>
                                <mat-option *ngFor="let locationGroup of locationGroups; trackBy: trackByLocationGroup" [value]="locationGroup.locationGroupID">
                                    {{ locationGroup.name }}
                                </mat-option>
                            </mat-select>
                        </ng-template>
                    </mat-form-field>
                </div>
                <span *ngIf="!sepWinService.isNewTab" [ngClass]="{ disabledAction: updatesWidgetService['updates'] }" matTooltip="{{ 'COMMON.LOCATION_GROUP_EDITOR' | translate }}">
                    <button
                        id="addlocationGroupId"
                        mat-button
                        (click)="openLocationGroup()"
                        [disabled]="
                            updatesWidgetService['updates'] ||
                            disableLocationGroupButton ||
                            availablePageHint ||
                            (disableHeaders$ | async)
                        "
                        class="lightBackGround hintIcon location-header-svg"
                        [ngClass]="{ active: disableLocationGroupButton }"
                    ></button>
                </span>
            </div>
        </div>
        <button id="activeInactiveLocation" *ngIf="user" mat-button (click)="activeInactiveLocationHandler()" [disabled]="updatesWidgetService['updates'] || availablePageHint || (disableHeaders$ | async)" class="top-header-each-item activeBackGround active-inactive-hint non-printable"
            [ngClass]="{ active: isInactiveLocations }" [matTooltip]="inActiveLocationTooltip | translate">
            <mat-icon>pin_drop</mat-icon>
        </button>

        <button *ngIf="!user" mat-button (click)="contactUs()">Contact Us</button>
        <a *ngIf="!user && !isCurrentPageHome" mat-button href="/"> Back To Home </a>

        <button
            id="activeInactiveLocation2"
            *ngIf="user && !sepWinService.isNewTab"
            [disabled]="updatesWidgetService['updates']"
            mat-button
            [popper]="popperAlertContent"
            [popperShowOnStart]="false"
            [popperTrigger]="'click'"
            [popperHideOnClickOutside]="true"
            [popperHideOnScroll]="false"
            [popperDisableAnimation]="true"
            [popperPlacement]="'bottom'"
            class="top-header-each-item activeBackGround notification-hint non-printable"
            [ngClass]="{ 'black-c': badgeCount > 0 }"
            matTooltip="{{ 'TOP_NAV.NOTIFICATIONS_TOOLTIP' | translate }}"
            (click)="openNotifications()"
        >
            <mat-icon
                matBadge="{{ badgeCount }}"
                matBadgeColor="warn"
                [matBadgeHidden]="badgeCount < 1 || ringBell"
                [ngClass]="{ 'bell-shake': ringBell, active: badgeCount > 0 }"
                >notifications</mat-icon
            >
        </button>

        <button *ngIf="!user" mat-raised-button color="accent" (click)="login()">Sign In</button>

        <a *ngIf="!user" href="https://account.microsoft.com/account">
            <button mat-button>Create New Microsoft Account</button>
        </a>
        <button *ngIf="user && !sepWinService.isNewTab" [disabled]="updatesWidgetService['updates'] || (disableHeaders$ | async)" mat-icon-button [matMenuTriggerFor]="menu" id="user-dropdown-toggler" class="top-header-each-item non-printable user-hint">
            <span *ngIf="!navigation.mediumScreenAndDown">{{ user.name }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <button *ngIf="user" mat-icon-button [disabled]="!showHintOption" (click)="showPageHint()" class="top-header-each-item hint-action lightBackGround non-printable" matTooltip="{{ 'TOP_NAV.HELP_TOOLTIP' | translate }}">
            <mat-icon>help</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
            <button mat-menu-item (click)="openUserPreferences()">
                {{ 'TOP_NAV.USER_PREFERENCE_MENU' | translate }}
            </button>
            <button mat-menu-item (click)="openAboutUs()">{{ 'TOP_NAV.ABOUT_US' | translate }}</button>
            <button mat-menu-item [matMenuTriggerFor]="menuAssistanceCenter">
                {{ 'TOP_NAV.HELP_TOOLTIP' | translate }}
            </button>
            <button mat-menu-item (click)="openResetPassword()">{{ 'TOP_NAV.CHANGE_PASSWORD' | translate }}</button>
            <button mat-menu-item (click)="logOut()">{{ 'TOP_NAV.SIGN_OUT' | translate }}</button>
        </mat-menu>
        <mat-menu #menuAssistanceCenter="matMenu" [overlapTrigger]="false" class="menuAssistanceCenter">
            <button mat-menu-item (click)="AssistanceCenter('0', 'CUSTOMER SUPPORT')">
                <mat-icon>headset_mic</mat-icon>
                <span> {{ 'TOP_NAV.CUSTOMER_SUPPORT' | translate }} </span>
            </button>
            <button mat-menu-item (click)="AssistanceCenter('1', 'REPORT DEFECT')">
                <mat-icon>bug_report</mat-icon>
                <span> {{ 'TOP_NAV.REPORT_DEFECT' | translate }} </span>
            </button>
            <button mat-menu-item (click)="AssistanceCenter('2', 'SURVEY')">
                <mat-icon>assignment</mat-icon>
                <span>{{ 'TOP_NAV.SURVEY' | translate }} </span>
            </button>
            <button mat-menu-item (click)="AssistanceCenter('4', 'CONTACT US')">
                <mat-icon>call</mat-icon>
                <span> {{ 'TOP_NAV.CONTACT_US' | translate }} </span>
            </button>
            <button mat-menu-item (click)="openURL('https://www.adsenv.com/prism/', '_blank')">
                <mat-icon>help</mat-icon>
                <span> {{ 'TOP_NAV.FAQ' | translate }} </span>
            </button>
            <button mat-menu-item (click)="openURL('https://www.youtube.com/user/ADSENVSVCS/videos', '_blank')">
                <mat-icon>smart_display</mat-icon>
                <span> {{ 'TOP_NAV.HOW_TO_VIDEOS' | translate }} </span>
            </button>
        </mat-menu>

        <div *ngIf="navigation.mediumScreenAndDown && breadcrumbs.length > 0 && !autoBreadcrumbs">
            <span class="breadcrumb-holder">
                <span *ngFor="let breadcrumb of breadcrumbs; trackBy: trackByLink" class="breadcrumb">
                    <a *ngIf="breadcrumb.link !== null" [routerLink]="breadcrumb.link" class="breadcrumb-link">{{
                        breadcrumb.title
                    }}</a>
                    <a *ngIf="breadcrumb.link === null" class="breadcrumb-link">{{ breadcrumb.title }}</a>
                </span>
            <span class="breadcrumb">{{ pageTitle }}</span>
            </span>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="main-nav-menu non-printable" *ngIf="user && !sepWinService.isNewTab">
        <app-nav-item *ngFor="let menuItem of menuItemsObservable | async; trackBy: trackByTitle" [menuItem]="menuItem">
        </app-nav-item>
    </mat-toolbar-row>
</mat-toolbar>

<div class="autoListNotification" hidden>
    <div class="autoNotification">
        <mat-icon class="close">close</mat-icon>
        <div class="message">
            <mat-icon class="red-c">notifications</mat-icon>
            <div>
                <span class="red-c"> High Level </span>
                <span> at BC10! </span>
            </div>
        </div>
        <div class="action">
            <div mat-menu-item>
                <span class="red-c"> Click to Acknowledge </span>
                <div></div>
            </div>
        </div>
    </div>
    <div class="autoNotification">
        <mat-icon class="close">close</mat-icon>
        <div class="message">
            <mat-icon class="green-c">check</mat-icon>
            <div>
                <span>High Level at BC10! Acknowledge </span>
            </div>
        </div>
    </div>
</div>

<!-- Popover for alerts -->
<popper-content #popperAlertContent class="notification-popover">
    <div class="notification-popover-align">
    <mat-card>
        <mat-card-content class="notification-tab-content">
            <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
                <mat-tab label="{{ 'COMMON.ALARM' | translate }}" [default]="notificationsAlarm.length || !notificationsOther.length">
                    <mat-list>
                        <mat-list-item class="notification-alarm" [ngClass]="{ 'unread-notification': nf.state === 1 }" *ngFor="let nf of notificationsAlarm; trackBy: trackByTimestamp">
                            <div class="app-flex-filler">
                                <div class="item-location-info">
                                    <strong>{{ nf.customerName }}</strong> - <strong>{{ nf.locationName }}</strong> -
                                    <strong>{{ alarmType[nf.alarmType] ? alarmType[nf.alarmType].name : '' }}</strong>
                                    <br />
                                    <strong>{{ nf.message }}</strong
                                    ><br />
                                    <div class="notification-alarm__links">
                                        <a [routerLink]="['/dashboard']" [queryParams]="{ c: nf.cid, widget: 0 }"
                                            ><strong>HOME</strong>
                                    </a>
                                    <a [routerLink]="['/pages/menuDashboard/viewData']" [queryParams]="{ c: nf.cid, lid: nf.lid }"><strong>LOCATION DASHBOARD</strong>
                                        </a>
                                </div>
                            </div>
                            </div>
                            <button class="clear-button" mat-icon-button (click)="readNotification(nf, notificationsAlarm)">
                                <mat-icon class="mat-24">clear</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
                </mat-tab>
                <mat-tab label="{{ 'COMMON.OTHERS' | translate }}" [default]="!notificationsAlarm.length && notificationsOther.length" style="flex-flow: wrap;">
                    <mat-list>
                        <mat-list-item class="notification-list-element" [ngClass]="{ 'unread-notification': nf.state === 1 }" *ngFor="let nf of notificationsOther; trackBy: trackByTimestamp">
                            <div *ngIf="
                                    nf.message !== SliicerSignalRMessage.SLIICER_MESSAGE &&
                                    nf.message !== PASSWORD_EXPIRATION_DAYS &&
                                    !nf.filePath
                                ">
                                <div class="item-location-info">
                                    {{ nf.customerName }} for
                                    <a [routerLink]="['/pages/viewLocationDetails']" [queryParams]="{ c: nf.cid, lid: nf.lid }">{{ nf.locationName }}
                                    </a>
                                </div>
                                <div (click)="notificationMessageClicked(nf)">
                                    <div class="item-location-message" [ngClass]="{ 'notification-message-pointer': nf.url, 'red': !nf.success }" *ngIf="!nf.fileName">
                                        {{ nf.message }}
                                    </div>
                                    <div *ngIf="nf.url && nf.fileName" class="item-location-message" [ngClass]="{ 'notification-message-pointer': nf.url }" [innerHtml]="nf.fileName"></div>
                                </div>
                            </div>
                            <div *ngIf="
                                    nf.message !== SliicerSignalRMessage.SLIICER_MESSAGE &&
                                    nf.filePath
                                ">
                                <div class="item-location-info">
                                    {{ nf.customerName }}
                                </div>

                                <div (click)="notificationMessageClicked(nf)">
                                    The export
                                    <a [routerLink]="['/pages/vault/list']" [queryParams]="{ c: nf.cid}" [state]="{folderId: '/'+nf.folderPath+'/', filePath: '/'+nf.filePath}">
                                        {{nf.filePath}}
                                    </a> is completed
                                </div>
                            </div>
                            <div *ngIf="nf.message === SliicerSignalRMessage.SLIICER_MESSAGE">
                                <div class="item-location-info" *ngIf="nf.success">
                                    {{ nf.customerName }} Data export is complete for <b>{{ nf.caseStudyName }}</b>
                                    <a [routerLink]="['/pages/vault']" [queryParams]="{ c: nf.cid}" [state]="{folderId: '/' + nf.detail + '/Exports/'}">
                                        Open study results in the Vault
                                    </a>
                                </div>
                                <div class="item-location-info" *ngIf="!nf.success">
                                    {{ nf.customerName }} There was an error exporting data for
                                    <b>{{ nf.caseStudyName }}</b
                                    >.
                                    {{ nf.detail }}
                                </div>
                            </div>
                            <div *ngIf="nf.message === PASSWORD_EXPIRATION_DAYS">
                                <div class="item-location-info">
                                    {{ nf.detail }}
                                    <a (click)="openResetPassword()"> Change Password Now </a>
                                </div>
                            </div>
                            <button
                                mat-icon-button
                                (click)="readNotification(nf, notificationsOther)"
                                class="clear-button"
                            >
                                <mat-icon class="mat-24">clear</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
</popper-content>
