<div id="customer-editor" class="contentLayout" [hidden]="showNewLocationEditor">
    <mat-card>
        <mat-card-header>
            <mat-icon class="backIcon auto-margin" (click)="exitCustomerEditor($event)">arrow_back</mat-icon>
            <div class="auto-margin">{{ headerName }}</div>
            <div class="app-flex-filler"></div>
        </mat-card-header>

        <div class="new-customer-editor">
            <app-loader [isLoading]="customersLoadingState"></app-loader>
            <div id="customerDetailsId">
                <mat-tab-group>
                    <mat-tab label="{{ 'CUSTOMER_EDITOR.DETAILS_LABEL' | translate }}">
                        <mat-card-content>
                            <form #addCustomerForm="ngForm" name="addCustomerForm" novalidate class="topPadding">
                                <div class="custom-50p">
                                    <div
                                        class="each-Item custom-error"
                                        [ngClass]="{ 'pad-b-15': customerNameInput?.errors?.pattern }"
                                    >
                                        <mat-form-field class="full-width">
                                            <input
                                                matInput
                                                name="customerNameInput"
                                                id="customerNameInput"
                                                placeholder="{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}"
                                                [(ngModel)]="customerName"
                                                (ngModelChange)="validateDuplicateCustomerName()"
                                                #customerNameInput="ngModel"
                                                required
                                                minlength="6"
                                                maxlength="64"
                                                [pattern]="customerNamePattern"
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span *ngIf="isValidCustomerName !== null && isValidCustomerName === false"
                                                >{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}
                                                {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                                            >
                                            <span
                                                *ngIf="
                                                    customerNameInput?.errors?.minlength || customerNameLengthIsInvalid
                                                "
                                            >
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_NAME_LENGTH_VALIDATION' | translate }}
                                            </span>
                                            <span *ngIf="customerNameInput?.errors?.maxlength">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_VALIDATION_MSG' | translate }}
                                            </span>
                                            <span *ngIf="customerNameInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_PATTERN_VALIDATION' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="showUpdateCustomerEditor" class="each-Item custom-error">
                                        <mat-form-field class="full-width">
                                            <input
                                                matInput
                                                name="shortNameInput"
                                                id="shortNameInput"
                                                placeholder="{{ 'CUSTOMER_EDITOR.CUSTOMER_SHORT_NAME' | translate }}"
                                                [(ngModel)]="shortName"
                                                (ngModelChange)="validateShortDuplicateCustomerName()"
                                                #shortNameInput="ngModel"
                                                required
                                                minlength="3"
                                                maxlength="32"
                                                [pattern]="alphaNumericWithoutSpacePattern"
                                                disabled
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span
                                                *ngIf="
                                                    isValidCustomerShortName !== null &&
                                                    isValidCustomerShortName === false
                                                "
                                                >{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}
                                                {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                                            >
                                            <span
                                                *ngIf="
                                                    shortNameInput?.errors?.minlength ||
                                                    customerShortNameLengthIsInvalid
                                                "
                                            >
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_VALIDATION' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.maxlength">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_NAME_LONG_MSG' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_PATTERN_ERR' | translate }}
                                            </span>
                                        </div>
                                    </div>

                                    <div *ngIf="!showUpdateCustomerEditor" class="each-Item custom-error">
                                        <mat-form-field class="full-width">
                                            <input
                                                matInput
                                                name="shortNameInput"
                                                id="shortNameInput"
                                                placeholder="{{ 'CUSTOMER_EDITOR.CUSTOMER_SHORT_NAME' | translate }}"
                                                [(ngModel)]="shortName"
                                                (ngModelChange)="validateShortDuplicateCustomerName()"
                                                #shortNameInput="ngModel"
                                                required
                                                minlength="3"
                                                maxlength="32"
                                                [pattern]="alphaNumericWithoutSpacePattern"
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span
                                                *ngIf="
                                                    isValidCustomerShortName !== null &&
                                                    isValidCustomerShortName === false
                                                "
                                                >{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}
                                                {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                                            >
                                            <span
                                                *ngIf="
                                                    shortNameInput?.errors?.minlength ||
                                                    customerShortNameLengthIsInvalid
                                                "
                                            >
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_VALIDATION' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.maxlength">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_NAME_LONG_MSG' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_PATTERN_ERR' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="custom-50p">
                                    <div *ngIf="showUpdateCustomerEditor" class="each-Item">
                                        <mat-form-field class="full-width">
                                            <input
                                                matInput
                                                name="dataTTL"
                                                id="dataTTL"
                                                placeholder="{{
                                                    'CUSTOMER_EDITOR.TIME_TO_LIVE_PLACEHOLDER' | translate
                                                }}"
                                                [(ngModel)]="dataTTL"
                                                #shortNameInput="ngModel"
                                                disabled
                                            />
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="!showUpdateCustomerEditor" class="each-Item custom-error">
                                        <mat-form-field class="full-width">
                                            <input
                                                matTooltip="{{ 'CUSTOMER_EDITOR.DATA_TTL_TOOLTIP' | translate }}"
                                                matTooltipPosition="above"
                                                matInput
                                                name="dataTTL"
                                                id="dataTTL"
                                                placeholder="{{
                                                    'CUSTOMER_EDITOR.TIME_TO_LIVE_PLACEHOLDER' | translate
                                                }}"
                                                [(ngModel)]="dataTTL"
                                                #datTTLInput="ngModel"
                                                required
                                                [pattern]="numeric3_36Pattern"
                                            />
                                        </mat-form-field>
                                        <span class="custom-error-message" *ngIf="datTTLInput?.errors?.pattern">
                                            {{ 'CUSTOMER_EDITOR.TTL_VALIDATION' | translate }}
                                        </span>
                                    </div>
                                    <div class="customersDropdown each-Item">
                                        <mat-form-field class="full-width">
                                            <mat-select
                                                placeholder="{{ 'CUSTOMER_EDITOR.UNITS_TEXT' | translate }}"
                                                [(ngModel)]="units"
                                                name="displayUnits"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let units of unitsofMeasure; trackBy: trackByValue"
                                                    [value]="units.value"
                                                    >{{ units.text }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- <div class="pad-b-20">
                                    <mat-checkbox name="flowViewFlag" [(ngModel)]="flowViewFlag">
                                        {{ 'CUSTOMER_EDITOR.FLOWVIEW_CHECKBOX' | translate }}
                                    </mat-checkbox>
                                </div> -->

                                <div class="custom-50p">
                                    <div class="each-Item">
                                        <mat-form-field class="full-width">
                                            <mat-select
                                                placeholder="{{ 'CUSTOMER_EDITOR.DATE_FORMAT' | translate }}"
                                                [(ngModel)]="dateFormat"
                                                name="displayDateFormat"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let dateFormat of dateFormatType; trackBy: trackByValue"
                                                    [value]="dateFormat.value"
                                                    >{{ dateFormat.text }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="each-Item">
                                        <mat-form-field class="full-width">
                                            <mat-select
                                                placeholder="{{ 'CUSTOMER_EDITOR.TIME_FORMAT' | translate }}"
                                                [(ngModel)]="timeFormat"
                                                name="displayTimeFormat"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let timeFormat of timeFormatType; trackBy: trackByValue"
                                                    [value]="timeFormat.value"
                                                    >{{ timeFormat.text }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="custom-50p">
                                    <div class="each-Item">
                                        <mat-form-field class="full-width">
                                            <mat-select
                                                placeholder="{{ 'COMMON.TIMEZONE_PLACEHOLDER' | translate }}"
                                                [(ngModel)]="timeZone"
                                                name="displayTimeZone"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let timeZone of timeZoneType; trackBy: trackByValue"
                                                    [value]="timeZone.value"
                                                    >{{ timeZone.text }} ({{ timeZone.utcValue }})
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="each-Item">
                                        <mat-form-field class="full-width">
                                            <input
                                                matInput
                                                name="workOrderLinkInput"
                                                #workOrderLinkInput="ngModel"
                                                id="workOrderLinkInput"
                                                placeholder="{{ 'COMMON.CUSTOMER_LINK_TEXT' | translate }}"
                                                [(ngModel)]="workOrderLink"
                                                [pattern]="validLinkPattern"
                                                maxlength="512"
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span *ngIf="workOrderLinkInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.URL_PATTERN_VALIDATION' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="custom-50p">
                                    <div class="each-Item">
                                        <mat-form-field class="full-width">
                                            <input
                                                matInput
                                                name="idleTime"
                                                #idleTimeOutInput="ngModel"
                                                id="idleTimeOutInput"
                                                placeholder="{{ 'COMMON.IDLE_TIMEOUT' | translate }}"
                                                [(ngModel)]="idleTimeOut"
                                                type="number"
                                                matTooltip="{{ 'CUSTOMER_EDITOR.IDLE_TIMEOUT_TOOLTIP' | translate }}"
                                            />
                                        </mat-form-field>
                                    </div>
                                    <div class="each-Item">
                                        <mat-form-field class="full-width">
                                            <input
                                                matInput
                                                name="contractNum"
                                                #contractNum="ngModel"
                                                id="contractNum"
                                                placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.CONTRACT_NUMBER' | translate }}"
                                                [(ngModel)]="contractNumber"
                                                type="text"
                                                required
                                                maxlength="16"
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="custom-50p">
                                    <div class="each-Item pad-b-20">
                                        <mat-checkbox name="daylightSavingFlag" [(ngModel)]="daylightSavingFlag">
                                            {{ 'COMMON.ENABLE_DAYLIGHT_TEXT' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <div class="each-Item">
                                        <mat-form-field class="full-width">
                                            <mat-select
                                                placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.SUBSCRIPTION_LEVEL' | translate }}"
                                                [(ngModel)]="subscriptionLevel"
                                                name="subscriptionLevel"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let alternative of subscriptionLevelAlternatives; trackBy: trackByValue"
                                                    [value]="alternative.value"
                                                >
                                                    {{ alternative.text }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                        </mat-card-content>
                        <mat-card-actions>
                            <div class="auto-margin" *ngIf="showUpdateCustomerEditor">
                                <button mat-button (click)="openNewLocationEditor()" [disabled]="disableAddLocationBtn">
                                    {{ 'COMMON.ADD_LOCATION_TEXT' | translate }}
                                </button>
                            </div>
                            <div class="app-flex-filler"></div>
                            <div class="auto-margin">
                                <button
                                    mat-button
                                    (click)="addCustomer()"
                                    [disabled]="
                                        addCustomerForm.pristine ||
                                        !addCustomerForm.valid ||
                                        !isValidCustomerName ||
                                        !isValidCustomerShortName
                                    "
                                >
                                    {{ 'COMMON.SAVE_BUTTON' | translate }}
                                </button>
                            </div>
                        </mat-card-actions>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-card>
</div>
