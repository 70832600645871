<!-- <div id="advanceScatterGraphId" class="contentLayout"> -->
    <div class="no-data-found-section dont-print" *ngIf="!isLoading && !data">
        <div class="no-data-content">
            <div class="no-data-found-img"></div>
            <div class="no-data-found-text custom-header">
                <span class="header-text">{{ 'COMMON.NO_RESULTS_FOUND' | translate }}</span>
                <span class="sub-header-text"
                    >{{ 'COMMON.NO_RESULTS_FOUND_SUB_TEXT1' | translate }} <br />
                    {{ 'COMMON.NO_RESULTS_FOUND_SUB_TEXT2' | translate }}</span
                >
            </div>
        </div>
    </div>

    <mat-card-content *ngIf="showCustomRangesForScattergraph" class="margin-t-5">
        <div class="hydrographScaleOptions">
            <form #customRangeScattergraphForm="ngForm">
                <div *ngFor="let opt of customRanges; let i = index; trackBy: trackByIndex">
                    <div class="manualRanges">
                        <div class="opt-label">{{ opt.name }}</div>
                        <div class="minRange">
                            <mat-form-field class="minFieldRange">
                                <input
                                    matInput
                                    type="number"
                                    placeholder="{{ RANGE_TEXT.MIN_SCALE_PLACEHOLDER }}"
                                    name="{{ opt.name }}_min"
                                    [(ngModel)]="opt.min"
                                />
                            </mat-form-field>
                        </div>
                        <div class="maxRange">
                            <mat-form-field class="maxFieldRange">
                                <input
                                    matInput
                                    type="number"
                                    placeholder="{{ RANGE_TEXT.MAX_SCALE_PLACEHOLDER }}"
                                    name="{{ opt.name }}_max"
                                    [(ngModel)]="opt.max"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="mat-error" *ngIf="!validateCustomRanges(opt)">
                        {{ RANGE_TEXT.INVALID_SCALE }}
                    </div>
                </div>

                <div class="buttonRow">
                    <button mat-button (click)="resetManualScales()">Reset</button>
                    <button mat-button (click)="applyManualRanges()" [disabled]="!validateCustomRanges()">Apply</button>
                </div>
            </form>
        </div>
    </mat-card-content>
    <app-loader [isLoading]="isLoading"></app-loader>
    <div [class.hide]="!isLoading && !data">
        <mat-card-content>
            <div>
                <app-scattergraph-edit-menu
                    [ngClass]="{ hide: hideMenu }"
                    (updateAnnotationSettings)="onAnnotationSettingsChange($event)"
                    [locationId]="locationId"
                    [customerId]="customerId"
                    (emitChange)="onChange($event)"
                    (updateCurve)="updateCurve()"
                    [annotationSettings]="annotationSettings"
                    [data]="data"
                    [isDataEditingModeEnabled]="isDataEditingModeEnabled"
                    [isLoading]="isLoading"
                    [isDataSelected]="isDataSelected"
                    [isSnapToCurveButton]="isSnapToCurveButton"
                    (emitApplySnapToCurve)="onApplySnapToCurve()"
                    [locationDetails]="locationDetails"
                    [isMetric]="isMetric"
                    (rangeChanged)="rangeChanged()"
                    [selectedCurve]="selectedCurve"
                    (setLoading)="setLoading($event)"
                    (emitSaveCurve)="onSaveCurve($event)"
                    [curves]="curves"
                    [savedCurve]="savedCurveData"
                    [savedCurves]="savedCurves"
                    (updateCurveValue)="onSelectedCurveChange($event)"
                    (emitSavedCurveSelected)="onSavedCurveSelected($event)"
                    (emitSavedCurveDeleted)="onSavedCurveDeleted($event)"
                    [generationOption]="generationOption"
                    [syncZoomWithHg]="syncZoomWithHg"
                    (emitGenerationOptionsChange)="onGenerationOptionsChange($event)"
                    [isPrinting]="isPrinting"
                    [validPipeTable]="validPipeTable"
                    (updateSelectionMode)="updateSelectionMode($event)"
                >
                </app-scattergraph-edit-menu>

                <app-advance-scattergraph
                    [data]="data"
                    [isDataEditingModeEnabled]="isDataEditingModeEnabled"
                    [flaggedData]="flaggedData"
                    [tooltipTimeFormat]="tooltipTimeFormat"
                    [zoomFromHydro]="zoomFromHydro"
                    [effectiveRoughness]="effectiveRoughness"
                    [slope]="slope"
                    [scatterDateFormat]="scatterDateFormat"
                    (enablePreviewChange)="enablePreviewChange.emit($event)"
                    [editedPoints]="editedPoints"
                    [selectedEntityIds]="selectedEntityIds"
                    [annotationSettings]="annotationSettings"
                    [snappingPosition]="snappingPosition"
                    (setLoading)="setLoading($event)"
                    (dataSelect)="onDataSelect($event)"
                    (dataSelectForSnap)="dataSelectForSnap.emit($event)"
                    [syncZoomWithHg]="syncZoomWithHg"
                    [savedCurve]="savedCurveData"
                    [locationId]="locationId"
                    (curveChanged)="onCurveChanged($event)"
                    [selectedConfirmationEntity]="selectedConfirmationEntity"
                ></app-advance-scattergraph>
            </div>
        </mat-card-content>
    </div>
