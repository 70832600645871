<div *ngIf="hasStatsData" class="dry-day-stats-container mat-elevation-z4">
    <mat-table [dataSource]="tableData">
        <!-- Property column definition -->
        <ng-container matColumnDef="propertyName" sticky>
            <th mat-header-cell *matHeaderCellDef class="sticky-top sup">Day of Week Group</th>
            <td mat-cell *matCellDef="let element"
                [ngClass]="allHaveDryDays && [G_ALT, G_ALT_YEAR, G_ALT_DAY_GROUP, G_ALT_SEASON, G_ALT_REGIME].includes(element.key) ? 'disabled' : ''"
            >{{ element.propertyName }}</td>
        </ng-container>

        <!-- Value column definitions, loop over the keys for unique column ids. -->
        <ng-container *ngFor="let key of valueKeys; index as i; trackBy: trackByIndex">
            <ng-container matColumnDef="{{ key }}">
                <th mat-header-cell *matHeaderCellDef class="sticky-top">{{ columnNames[i] }}</th>
                <td mat-cell *matCellDef="let element">
                <span *ngIf="!element.font && !element.checkboxes && !element.daygroupdropdowns && !element.yeardropdowns && !element.seasondropdowns && !element.regimedropdowns">
                    {{ (element.precision[i] && element.values[i] !== 'Infinity' && element.values[i] !== '-Infinity') ? (element.values[i] | number: element.precision[i]) : element.values[i] }}
                </span>
                <!--
                    #33505 Alternate checkboxes should be temporary disabled. Original disabled section of html element below is here:
                    [disabled]="!element.values[i] || isStudyLocked"
                -->
                <span *ngIf="element.checkboxes">
                    <mat-checkbox
                    [checked]="element.checkboxvalues[i]"
                    [disabled]="true"
                    (change)="somethingChanged($event,element, i)"
                ></mat-checkbox>
                </span>
                <span *ngIf="element.daygroupdropdowns" [ngClass]="allHaveDryDays ? 'disabled' : ''">
                    <mat-select [(ngModel)]="element.values[i]" (selectionChange)="somethingChanged($event,element,i)" [disabled]="!element.checkboxvalues[i] || isStudyLocked">
                        <mat-option *ngFor="let dayGroup of dayGroups; trackBy: trackByIndex" [value]="dayGroup">
                            {{ dayGroup }}
                        </mat-option>
                    </mat-select>
                </span>
                <span *ngIf="element.yeardropdowns" [ngClass]="allHaveDryDays ? 'disabled' : ''">
                    <mat-select [(value)]="element.values[i]" (selectionChange)="somethingChanged($event,element,i)" [disabled]="!element.checkboxvalues[i] || isStudyLocked">
                        <mat-option *ngFor="let year of yearsUniqueKeys; trackBy: trackByIndex" [value]="year">
                            {{ year }}
                        </mat-option>
                    </mat-select>
                </span>
                <span *ngIf="element.seasondropdowns" [ngClass]="allHaveDryDays ? 'disabled' : ''">
                    <mat-select [(value)]="element.values[i]" (selectionChange)="somethingChanged($event,element,i)" [disabled]="!element.checkboxvalues[i] || isStudyLocked">
                        <mat-option *ngFor="let season of seasonsUniqueKeys; trackBy: trackByIndex" [value]="season">
                            {{ season }}
                        </mat-option>
                    </mat-select>
                </span>
                <span *ngIf="element.regimedropdowns" [ngClass]="allHaveDryDays ? 'disabled' : ''">
                    <mat-select [(value)]="element.values[i]" (selectionChange)="somethingChanged($event,element,i)" [disabled]="!element.checkboxvalues[i] || isStudyLocked">
                        <mat-option *ngFor="let regime of regimesUniqueKeys; trackBy: trackByIndex" [value]="regime">
                            {{ regime }}
                        </mat-option>
                    </mat-select>
                </span>
                <div class="fontsizedate-time" *ngIf="element.font">
                    {{ element.values[i] }}
                </div>
                </td>
            </ng-container>
        </ng-container>

        <!-- first header row -->
        <ng-container *ngIf="yearsAvailable || seasonsAvailable || regimesAvailable">
            <tr mat-header-row *matHeaderRowDef="displayColumnKeys" class="no-bottom-border"></tr>
        </ng-container>
        <ng-container *ngIf="!(yearsAvailable || seasonsAvailable || regimesAvailable)">
            <tr mat-header-row *matHeaderRowDef="displayColumnKeys"></tr>
        </ng-container>

        <!-- data rows -->
        <tr mat-row *matRowDef="let row; columns: displayColumnKeys"></tr>

        <!-- year definitions -->
        <ng-container *ngIf="yearsAvailable">
            <!-- year column definition -->
            <ng-container matColumnDef="year" sticky>
                <th mat-header-cell *matHeaderCellDef>Year</th>
            </ng-container>

            <!-- create the unique year column definitions -->
            <ng-container *ngFor="let key of yearsUniqueKeys; trackBy: trackByIndex">
                <ng-container matColumnDef="{{ 'Y' + key }}">
                    <th mat-header-cell *matHeaderCellDef>{{ key }}</th>
                </ng-container>
            </ng-container>

            <!-- add the header row -->
            <ng-container *ngIf="seasonsAvailable || regimesAvailable">
                <tr
                    mat-header-row
                    *matHeaderRowDef="yearsDisplayColumnKeys"
                    class="secondary-header-row no-bottom-border"
                ></tr>
            </ng-container>
            <ng-container *ngIf="!(seasonsAvailable || regimesAvailable)">
                <tr mat-header-row *matHeaderRowDef="yearsDisplayColumnKeys" class="secondary-header-row"></tr>
            </ng-container>
        </ng-container>

        <!-- season definitions -->
        <ng-container *ngIf="seasonsAvailable">
            <!-- season column definition -->
            <ng-container matColumnDef="season" sticky>
                <th mat-header-cell *matHeaderCellDef>Season</th>
            </ng-container>

            <!-- create the unique season column definitions -->
            <ng-container *ngFor="let key of seasonsUniqueKeys; trackBy: trackByIndex">
                <ng-container matColumnDef="{{ 'S' + key }}">
                    <th mat-header-cell *matHeaderCellDef>{{ key }}</th>
                </ng-container>
            </ng-container>

            <!-- add the header row -->
            <ng-container *ngIf="regimesAvailable">
                <tr
                    mat-header-row
                    *matHeaderRowDef="seasonsDisplayColumnKeys"
                    class="secondary-header-row no-bottom-border"
                ></tr>
            </ng-container>
            <ng-container *ngIf="!regimesAvailable">
                <tr mat-header-row *matHeaderRowDef="seasonsDisplayColumnKeys" class="secondary-header-row"></tr>
            </ng-container>
        </ng-container>

        <!-- regime definitions -->
        <ng-container *ngIf="regimesAvailable">
            <!-- regime column definition -->
            <ng-container matColumnDef="regime" sticky>
                <th mat-header-cell *matHeaderCellDef>Regime</th>
            </ng-container>

            <!-- create the unique regime column definitions -->
            <ng-container *ngFor="let key of regimesUniqueKeys; trackBy: trackByIndex">
                <ng-container matColumnDef="{{ 'R' + key }}">
                    <th mat-header-cell *matHeaderCellDef>{{ key }}</th>
                </ng-container>
            </ng-container>

            <!-- add the header row -->
            <tr mat-header-row *matHeaderRowDef="regimesDisplayColumnKeys" class="secondary-header-row"></tr>
        </ng-container>
    </mat-table>
</div>
<div class="no-data-msg" *ngIf="!hasStatsData">{{ 'COMMON.NO_DRY_DAY_STATS_DATA_MSG' | translate }}</div>
