import { DailySummaryExportComponent } from './../daily-summary-export/daily-summary-export.component';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UsersService } from 'app/pages/admin/users.service';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
    locationIdQueryParam,
} from 'app/shared/models/customer';
import {
    DailyQuickSpan,
    DailyReportSettings,
    DailySummaryData,
    DailySummaryDetailsReportArgs,
    Header,
    IDailySummaryLocationDetail,
    Paging,
    Synopsis,
    timespanFromAPI,
    timespanToAPI,
} from 'app/shared/models/daily-summary';
import {
    DEPTH_ENTITY,
    RAIN_ENTITY,
    VELOCITY_ENTITY,
    QUANTITY_ENTITY,
    QCONTINUITY_ENTITY,
    FLOW_DISPLAY_GROUP,
    Q_FINAL_ENTITY,
    DEPTH_DISPLAY_GROUP,
    VELOCITY_DISPLAY_GROUP,
    RAIN_DISPLAY_GROUP,
    TEMPERATURE_DISPLAY_GROUP,
    INVALID_DISPLAY_GROUP,
    DFINAL_ENTITY,
    V_FINAL_ENTITY,
    R_FINAL_ENTITY,
    RAIN_INTENSITY_GROUP,
    LEVEL_ENTITY,
    FEET_DISPLAY_GROUP,
    AQUIFER_LEVEL_ENTITY,
    ELEVATION_ENTITY,
    ELEVATION_DISPLAY_GROUP,
} from 'app/shared/constant';
import { WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SharedService } from 'app/shared/services/shared.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject, combineLatest, Observable, Subscription, zip } from 'rxjs';
import { DailySummaryReportService } from '../daily-summary-report.service';
import moment from 'moment';
import { LocationData, LocationEntitiesData, LocationListArgs } from 'app/shared/models/locations-entities-data';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EntitySelectorObject, EntitySelectorEntity } from 'app/shared/models/entities';
import { LocationGroup } from 'app/shared/models/location-group';
import { Locations } from 'app/shared/models/locations';
import { SelectableGroup } from 'app/shared/models/selectable';
import { QUICK_DATE_RANGES } from 'app/shared/models/view-data';
import { ViewDataFilterArgs } from 'app/shared/models/view-data-filter';
import { EntityService } from 'app/shared/services/entity.service';
import { LocationService } from 'app/shared/services/location.service';
import { DateQuickSpan } from 'app/shared/services/telemetry.service';
import { delay, first, tap } from 'rxjs/operators';
import { MapService } from 'app/shared/services/map.service';
import { INSTALLATION_TYPE, LocationDetails } from 'app/shared/models/location-details';
import { MonitorSeriesNames } from 'app/shared/components/location-card/location-card.constants';
import { TrackBy } from 'app/shared/utils/track-by';

const ENTITY_ALLOWED_GROUPS = [
    INVALID_DISPLAY_GROUP,
    DEPTH_DISPLAY_GROUP,
    VELOCITY_DISPLAY_GROUP,
    RAIN_DISPLAY_GROUP,
    TEMPERATURE_DISPLAY_GROUP,
    FLOW_DISPLAY_GROUP,
    RAIN_INTENSITY_GROUP,
    ELEVATION_DISPLAY_GROUP,
    FEET_DISPLAY_GROUP
];

const USGS_ENTITY_ALLOWED_GROUPS = [
    RAIN_DISPLAY_GROUP,
    FEET_DISPLAY_GROUP
];

const COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: Q_FINAL_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QFINAL', isChecked: true, isANSR: false },
    { id: QCONTINUITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QContinuity', isChecked: true, isANSR: false },
];

const DEFAULT_SELECTED_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: VELOCITY_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VELOCITY', isChecked: true, isANSR: false },
    { id: QUANTITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QUANTITY', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];
const DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: DFINAL_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DFINAL', isChecked: true, isANSR: false },
    { id: V_FINAL_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VFINAL', isChecked: true, isANSR: false },
    { id: Q_FINAL_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QFINAL', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];
const USGS_SELECTED_ENTITIES = [
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
    { id: LEVEL_ENTITY, groupId: FEET_DISPLAY_GROUP, name: 'LEVEL', isChecked: true, isANSR: false },
    { id: AQUIFER_LEVEL_ENTITY, groupId: FEET_DISPLAY_GROUP, name: 'AQUIFER LEVEL', isChecked: true, isANSR: false },
];

const FORESITE_CHANNEL_DEFAULT_SELECTED_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];

const FORESITE_ELEVATION_DEFAULT_SELECTED_ENTITIES = [
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
    { id: ELEVATION_ENTITY, groupId: ELEVATION_DISPLAY_GROUP, name: 'ELEVATION', isChecked: true, isANSR: false },
];

const ENTITIES_RAIN = ['RAIN', 'RAINFINAL', 'SATRAIN'];

@Component({
    selector: 'app-daily-summary-details',
    templateUrl: './daily-summary-details.component.html',
    styleUrls: ['./daily-summary-details.component.scss'],
    providers: [DatePipe],
})
export class DailySummaryDetailsComponent implements OnInit, OnDestroy {
    public GROUPS = {
        INVALID_DISPLAY_GROUP: INVALID_DISPLAY_GROUP,
        DEPTH_DISPLAY_GROUP: DEPTH_DISPLAY_GROUP,
        ELEVATION_DISPLAY_GROUP: ELEVATION_DISPLAY_GROUP,
        VELOCITY_DISPLAY_GROUP: VELOCITY_DISPLAY_GROUP,
        RAIN_DISPLAY_GROUP: RAIN_DISPLAY_GROUP,
        TEMPERATURE_DISPLAY_GROUP: TEMPERATURE_DISPLAY_GROUP,
        FLOW_DISPLAY_GROUP: FLOW_DISPLAY_GROUP,
        RAIN_INTENSITY_GROUP: RAIN_INTENSITY_GROUP,
        FEET_DISPLAY_GROUP
    };

    public customerID: number;
    public locationGroupID: number;

    public selectedLocation: LocationData;
    public locationId: number;
    /** Used to force assign location ID and load report if ID is passed by query */
    public queryLocationId?: number;
    public locationName: string;
    public isComposite: boolean;

    /** Represents the daily summary table representation */
    public dailySummaryData: IDailySummaryLocationDetail;
    private flag = true;
    /** represents inactive locations included or not */
    public includeInactiveLocations = false;

    public displayFilters = false;
    public startDate: Date;
    public endDate: Date;
    public pageData = new Array<Array<DailySummaryData>>();
    public isLoading: boolean;

    private subscriptions = new Array<Subscription>();

    /** customer date format */
    public dateFormat: string;
    /** customer time format */
    private timeFormat: string;
    /** represents Synopsis List */
    public synopsisList = new Array<Synopsis>();
    public searchValue: string;
    public startIndex: 0;
    public pageSize: 50;

    public userHasRawDataPermission: boolean;

    public locationGroups: Array<LocationGroup>;
    public locations: Array<Locations>;
    public selectedLocations: Array<Locations>;

    public availableEntities$ = new BehaviorSubject<EntitySelectorObject[]>(undefined);
    public availableANSREntities$ = new BehaviorSubject<EntitySelectorObject[]>(undefined);
    public allAvailableSGEntities: EntitySelectorObject[];
    public entityData: LocationEntitiesData;

    public isUSGS: boolean;
    public isForesite: boolean = false;
    public isElevationInstallType: boolean = false;
    public usgsSetEntities = false;

    public filterSettings: ViewDataFilterArgs = {
        isExportPrintEnabled: true,
        isDisplayExportMenu: false,
        isDisplayEntities: true,
        isDisplayDatePicker: true,
        isDisplayDataAveraging: true,
    };

    public displayEntityError: boolean;
    public entityErrorMessage: string;

    public selectedEntities: SelectableGroup[] = [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
    public selectedEntityIds: number[] = DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS.map((entity) => entity.id);
    private prevSelectedEntities: SelectableGroup[] = [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
    public defaultEntities: SelectableGroup[] = [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];

    public minDate: Date;
    public maxDate: Date;

    private settings: DailyReportSettings = {
        eids: [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS.map((entity) => entity.id)],
        quickSpan: 0,
    };

    public selectedTimespan: QUICK_DATE_RANGES;
    private downloadDialog: MatDialogRef<DailySummaryExportComponent>;

    private oldRouteSettings: ParamMap = null;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private activatedRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        private reportService: DailySummaryReportService,
        private router: Router,
        private sharedService: SharedService,
        private dateUtilService: DateutilService,
        private datePipe: DatePipe,
        private userService: UsersService,
        private uiUtilsService: UiUtilsService,
        private locationGroupService: LocationGroupService,
        private locationService: LocationService,
        private entityService: EntityService,
        private dialog: MatDialog,
        private mapService: MapService
    ) { }

    private closeDownloadDialog() {
        this.downloadDialog.close();
        this.domOperationUtilsService.dailyDialogOpened = false;
        this.downloadDialog = null;
    }
    public ngOnInit() {
        this.oldRouteSettings = null;
        const hintOpenCloseDialogSub = this.domOperationUtilsService.openCloseDailyDialog.subscribe((action: boolean) => {
            if (action) {
                this.openDailySummaryExport();
                this.downloadDialog
                    .afterOpened()
                    .pipe(first(), delay(1000))
                    .subscribe(() => this.domOperationUtilsService.showPageHintContent());
            } else if (this.downloadDialog) {
                this.closeDownloadDialog();
                this.domOperationUtilsService.showPageHintContent();
            }
        });
        this.subscriptions.push(hintOpenCloseDialogSub);
        const hintSubs = this.domOperationUtilsService.showpageHint.subscribe(isOpen => {
            if (!isOpen && this.downloadDialog) {
                this.closeDownloadDialog();
            }
        })
        this.subscriptions.push(hintSubs);

        this.isLoading = true;
        this.userHasRawDataPermission = this.userService.isRawDataEditingAllowed.getValue();

        if (this.userHasRawDataPermission) {
            this.selectedEntities = [...DEFAULT_SELECTED_ENTITIES];
            this.selectedEntityIds = DEFAULT_SELECTED_ENTITIES.map((entity) => entity.id);
            this.prevSelectedEntities = [...DEFAULT_SELECTED_ENTITIES];
            this.defaultEntities = [...DEFAULT_SELECTED_ENTITIES];
            this.settings = { eids: [...DEFAULT_SELECTED_ENTITIES.map((entity) => entity.id)], quickSpan: 0 };
        } else {
            this.selectedEntities = [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.selectedEntityIds = DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS.map((entity) => entity.id);
            this.prevSelectedEntities = [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.defaultEntities = [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.settings = {
                eids: [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS.map((entity) => entity.id)],
                quickSpan: 0,
            };
        }

        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate() - 1);
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);

        const params = combineLatest([this.activatedRoute.queryParamMap, this.dateUtilService.dateFormat, this.dateUtilService.timeFormat]);

        const globalSubscription = params.subscribe(([routeParams, dateFormat, timeFormat]: [ParamMap, string, string]) => {

            // if all params match the previous, skip
            if (this.dateFormat === dateFormat && this.oldRouteSettings !== null && routeParams.keys.length === this.oldRouteSettings.keys.length && routeParams.keys.every(k => this.oldRouteSettings.get(k) === routeParams.get(k))) {
                return;
            }
            const locationGroupChanged = !this.oldRouteSettings || routeParams.get('lg') !== this.oldRouteSettings.get('lg');

            this.oldRouteSettings = routeParams;
            let shouldReload = false;

            if (this.customerID && Number(routeParams.get(customerQueryParam)) !== this.customerID) {
                shouldReload = true;
                this.isLoading = true;
                this.locationId = null;
                this.locations = null;
                this.selectedLocations = null;
                this.includeInactiveLocations = null;
            }

            const locationParameter = Number(routeParams.get(locationIdQueryParam));

            if (locationParameter) {
                this.queryLocationId = Number(routeParams.get(locationIdQueryParam));
            }

            this.customerID = Number(routeParams.get(customerQueryParam));
            this.locationGroupID = Number(routeParams.get(customerLocationGroupQueryParam));
            this.dateFormat = this.dateUtilService.getFormat();
            this.timeFormat = timeFormat;
            this.displayFilters = false;

            const oldIncludeInactiveLocation = this.includeInactiveLocations;

            this.includeInactiveLocations = Boolean(Number(routeParams.get(activeInactiveLocationQueryParam)) || 0);

            if (oldIncludeInactiveLocation !== this.includeInactiveLocations || shouldReload) {
                // have to load location again
                this.loadEntities();
            } else {
                this.filterLocations();
                this.processEntities();
            }

            // Change location group
            if (locationGroupChanged) {
                this.filterLocations();
                this.loadReport();
            }
        });
        this.subscriptions.push(globalSubscription);

        this.loadEntities();
    }

    public ngOnDestroy() {
        if (this.downloadDialog) {
            this.downloadDialog.close();
        }
        this.subscriptions.forEach((subsc) => {
            subsc.unsubscribe();
        });
        this.changeDetector.detach();
    }

    public loadReport() {
        this.isLoading = true;
        const mstart = (this.startDate.getMonth() + 1 < 10 ? '0' : '') + (this.startDate.getMonth() + 1);
        const start =
            [this.startDate.getFullYear(), mstart, this.startDate.getDate()].join('-') +
            ' 00:00:00';

        const mend = (this.endDate.getMonth() + 1 < 10 ? '0' : '') + (this.endDate.getMonth() + 1);
        const end =
            [this.endDate.getFullYear(), mend, this.endDate.getDate()].join('-') + ' 23:59:59';
        const entityIDs = this.selectedEntities.map((x) => x.id);

        if (!entityIDs || entityIDs.length < 1) {
            this.dailySummaryData = null;
            this.isLoading = false;
            return;
        }

        const locationId = this.locationId;

        if (locationId) {
            const args = <DailySummaryDetailsReportArgs>{
                CustomerId: this.customerID,
                LocationGroupId: this.locationGroupID || 0,
                EntityIds: entityIDs,
                Start: moment(start).format('YYYY-MM-DD HH:mm:ss'),
                End: moment(end).format('YYYY-MM-DD HH:mm:ss'),
                LocationIds: [locationId],
                includeInactiveLocations: this.includeInactiveLocations,
            };

            if (this.customerID && this.dateFormat && this.timeFormat) {
                this.generateDailySummaryFilterReportData(args);
            } else {
                this.isLoading = false;
            }
        } else {
            this.dailySummaryData = null;
            this.loadDataTable();
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        }

    }

    private loadEntities() {
        // subscribe the already selected group
        this.subscriptions.push(
            this.locationGroupService.locationGroupSelected.subscribe((data: any) => {
                if (data && data.locationGroups) {
                    this.locationGroups = data.locationGroups;
                }
            }),
        );

        const loadEntitiesSubscription = combineLatest([
            this.reportService.loadSettings(),
            this.locationService.getLocationData(<LocationListArgs>{
                cid: this.customerID,
                IncludeInactiveLocations: this.includeInactiveLocations,
            })
        ]).subscribe(([settings, entityData]) => {
            if (!entityData) {
                loadEntitiesSubscription.unsubscribe();
                return;
            }


            this.entityData = entityData;

            entityData.l = entityData.l.map((l) => {
                if (l.s === 'Echo') {
                    l.s = 'ECHO';
                }
                return l;
            });
            entityData.d = entityData.d.map((d) => {
                if (d.s === 'Echo') {
                    d.s = 'ECHO';
                }
                return d;
            });

            this.locations = entityData.l.map(
                (locData: LocationData) =>
                    <Locations>{
                        id: locData.lid,
                        locationId: locData.lid,
                        name: locData.n,
                        locationName: locData.n,
                        latitude: locData.lat,
                        longitude: locData.lon,
                        locationType: locData.t,
                    },
            );

            const shouldLoadLocation = !this.locationId;
            this.filterLocations();
            this.checkifUsgs().subscribe(() => {
                this.processEntities();

                if (settings) {
                    this.settings = settings;
                    this.applySettings();
                }

                if (shouldLoadLocation) {
                    this.loadReport();
                }
            })
            loadEntitiesSubscription.unsubscribe();
        });
    }

    public applySettings() {
        if (!this.settings) {
            if (this.userHasRawDataPermission) {
                this.settings = { eids: [...DEFAULT_SELECTED_ENTITIES.map((entity) => entity.id)], quickSpan: 0 };
            } else {
                this.settings = {
                    eids: [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS.map((entity) => entity.id)],
                    quickSpan: 0,
                };
            }
        }

        this.applyEntitiesDefaults();

        if (this.settings.quickSpan && this.settings.quickSpan !== DailyQuickSpan.Custom) {
            const timeSpan = timespanFromAPI(this.settings.quickSpan);
            if (timeSpan !== this.selectedTimespan) {
                this.selectedTimespan = timeSpan;
            }
        }
        else if (this.settings.quickSpan && this.settings.quickSpan === DailyQuickSpan.Custom) {
            if (this.settings.customStart) {
                this.startDate = new Date(this.settings.customStart);
            }
            if (this.settings.customStop) {
                this.endDate = new Date(this.settings.customStop);
            }
        }

    }



    public applyEntitiesDefaults() {
        if (this.isUSGS) {
            if (!this.usgsSetEntities) {
                this.prevSelectedEntities = this.selectedEntities;
            }
            this.selectedEntities = [...USGS_SELECTED_ENTITIES];
            this.selectedEntityIds = USGS_SELECTED_ENTITIES.map((entity) => entity.id);

            this.usgsSetEntities = true;
            return;
        } else {
            this.usgsSetEntities = false;
        }

        if(this.isForesite){
            if(this.isElevationInstallType){
                this.selectedEntities = [...FORESITE_ELEVATION_DEFAULT_SELECTED_ENTITIES];
                this.selectedEntityIds = FORESITE_ELEVATION_DEFAULT_SELECTED_ENTITIES.map((entity) => entity.id);    
            }else{
                this.selectedEntities = [...FORESITE_CHANNEL_DEFAULT_SELECTED_ENTITIES];
                this.selectedEntityIds = FORESITE_CHANNEL_DEFAULT_SELECTED_ENTITIES.map((entity) => entity.id);    
            }

            return ;
        }

        if (this.settings.eids && this.settings.eids.length > 0) {
            this.selectedEntityIds = this.settings.eids;
            const availableEntities = [...this.availableEntities$.getValue()].reduce<EntitySelectorEntity[]>(
                (acc, cur) => {
                    acc.push(...cur.entities);
                    return acc;
                },
                [],
            );
            this.selectedEntities = availableEntities.filter((entity) => this.selectedEntityIds.includes(entity.id));

            // If there is no entity, select first
            if (this.selectedEntities.length === 0) {
                this.selectedEntities = [availableEntities[0]];
            }
            this.selectedEntityIds = this.selectedEntities.map((x) => x.id);
        }
    }

    public processEntities() {
        if (!this.entityData) return;

        const wasLocationSelected = this.selectedLocation ? true : false;

        this.selectedLocation =
            this.entityData.l.find((loc: LocationData) => loc.lid === this.locationId) || this.entityData.l[0];

        if (!wasLocationSelected) {
            this.locationId = this.selectedLocation.lid;
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        }


        const selectedDevices = this.entityService.getSelectedDevices(
            this.entityData,
            this.isUSGS ? 'USGS' : this.selectedLocation.s,
            this.selectedLocation.t,
        );

        this.isComposite = this.selectedLocation.t === 3;

        if (this.isComposite) {
            const QFinalOnly = [...selectedDevices[0].g];
            QFinalOnly[0].id = FLOW_DISPLAY_GROUP;
            QFinalOnly[0].entities = [
                ...COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS.map((v) => ({
                    e: v.name,
                    color: '#000000',
                    id: v.id,
                    groupId: v.groupId,
                })),
            ];
            this.availableEntities$.next(this.entityService.seriesEntityGroupToEntitySelectorObject(QFinalOnly));
        } else {
            if (selectedDevices) {
                const allowedGroups = this.isUSGS ? USGS_ENTITY_ALLOWED_GROUPS : ENTITY_ALLOWED_GROUPS;
                this.availableEntities$.next(
                    this.entityService.seriesEntityGroupToEntitySelectorObject([
                        ...selectedDevices[0].g.filter((x) => allowedGroups.includes(x.id)),
                    ]),
                );
            }
        }
        const ansrEntities = this.entityData.l.find((x) => x.lid === this.locationId);
        if (ansrEntities) {
            const entities = ansrEntities.ae
            this.availableANSREntities$.next(
                this.entityService.ansrEntityGroupToEntitySelectorObject([...entities], [], true),
            );
        }

        this.applyEntitiesDefaults();
    }

    public selectEntities(entities: EntitySelectorEntity[]) {
        if (
            // Nothing has changed
            this.prevSelectedEntities.length === entities.length &&
            entities.every((x) => this.prevSelectedEntities.findIndex((y) => y.id === x.id) !== -1)
        ) {
            return;
        }

        this.selectedEntities = entities;
        this.selectedEntityIds = this.selectedEntities.map((x) => x.id);
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public datepickerOnClose() {
        this.saveSettings();
        this.loadReport();
    }

    public entityOnBlur() {
        this.saveSettings();
        this.loadReport();
    }

    private saveSettings() {
        const quickSpan = timespanToAPI(this.selectedTimespan);
        this.settings = { eids: this.selectedEntities.map((x) => x.id), quickSpan: quickSpan };
        if (quickSpan === DailyQuickSpan.Custom) {
            this.settings.customStart = this.startDate;
            this.settings.customStop = this.endDate;
        }
        this.reportService.saveSettings(this.settings).subscribe((ignoreResult) => { });
    }

    public filterLocations() {
        if (!this.locations) return;

        if (this.queryLocationId) {
            this.locationId = this.queryLocationId;
            delete this.queryLocationId;
        }

        this.selectedLocations = this.locations.filter(
            (loc) =>
                this.locationGroupID === 0 ||
                this.locationGroups
                    .find((x) => x.locationGroupID === this.locationGroupID)
                    .locations.some((group: any) => group.locationID === loc.locationId),
        );

        if (
            !this.locationId ||
            (this.selectedLocations && !this.selectedLocations.some((x) => x.locationId === this.locationId))
        ) {
            this.locationId = this.selectedLocations[0] ? this.selectedLocations[0].locationId : 0;
            this.locationName = this.selectedLocations[0] ? this.selectedLocations[0].name : '';
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        }
    }

    public checkifUsgs() {
        return combineLatest([
            this.locationService.getLocationDetailsV2(this.customerID, this.locationId),
            this.mapService.getMarkerLocationDetails(this.locationId, this.customerID),
        ]).pipe(
            tap(([details, pipeInfo]) => {
                // Fix for #21956 - only way we can know location is USGS
                // TODO: API is so much inconsistent here that it works differently for Raleigh, BEAVERDAM CREEK AT DAM and Cape Girardeu, Mississippi River
                this.isForesite = pipeInfo?.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || pipeInfo?.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD;
                if(this.isForesite){
                    this.isElevationInstallType = pipeInfo?.installationType === INSTALLATION_TYPE.ELEVATION ;
                }
                this.isUSGS = !!details[0]?.usgsid || !!pipeInfo?.usgsid || pipeInfo?.series === 'USGS';
            })
        )
    }

    public onChangeLocation(name: string) {
        if (this.locationName === name) {
            return;
        }
        this.isUSGS = null;
        const newlySelectedLocation = this.locations.find((loc: Locations) => loc.name === name);

        if (newlySelectedLocation.locationId === this.locationId) {
            return;
        }

        this.locationId = newlySelectedLocation.locationId;
        this.locationName = newlySelectedLocation.name;

        this.isLoading = true;
        this.checkifUsgs().subscribe(() => {
            this.isLoading = false;

            this.filterLocations();

            this.processEntities();
            this.applySettings();
            this.loadReport();
        });
    }

    public notifyReport(filtersData: WidgetFilterData) {
        if (filtersData.entityIDs.length < 1) {
            return;
        }

        let startDate,
            endDate = null;

        if (filtersData.startDate) {
            startDate = [
                filtersData.startDate.getMonth() + 1,
                filtersData.startDate.getDate(),
                filtersData.startDate.getFullYear(),
            ].join('/');
        }
        if (filtersData.endDate) {
            endDate = [
                filtersData.endDate.getMonth() + 1,
                filtersData.endDate.getDate(),
                filtersData.endDate.getFullYear(),
            ].join('/');
        }

        const args = <DailySummaryDetailsReportArgs>{
            CustomerId: filtersData.customerID,
            LocationGroupId: this.locationGroupID || undefined,
            EntityIds: filtersData.entityIDs,
            Start: startDate,
            End: endDate + ' 23:59:59',
            LocationIds: [this.activatedRoute.snapshot.params['id']] || null,
            includeInactiveLocations: true,
        };
        this.startDate = startDate;
        this.endDate = endDate;
        this.generateDailySummaryFilterReportData(args);
    }

    private generateDailySummaryFilterReportData(reportArgs: DailySummaryDetailsReportArgs) {
        this.isLoading = true;
        // initate the preloader
        const subscriptionGetDailySummaryFilterReport = this.reportService.getLocationDetails(reportArgs).subscribe(
            (response) => {
                this.dailySummaryData = response;
                this.isLoading = false;
                this.loadDataTable();
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
        );

        this.subscriptions.push(subscriptionGetDailySummaryFilterReport);
    }

    public updateDateRange($event) {
        this.startDate = $event.startDate.currentValue;
        this.endDate = $event.endDate.currentValue;
    }

    private loadDataTable() {
        const tempSubHeader = new Array<Header>();

        // collects the index where the name is Time
        const tempDateIndex = new Array<number>();

        // collects the index where the name is not Time, Max, Min i.e Avg , Total etc
        const tempSingleDataIndex = new Array<number>();

        // #38689 Remove ANSR entities from Entity list
        const ansrEntities = this.entityData?.l?.find((x) => x.lid === this.locationId)?.ae;
        let ansrNameAssoc: string[] = [];
        if(ansrEntities && ansrEntities.length) {
            ansrEntities.forEach(ansrGroup => ansrGroup.entities.forEach(ansrEntity => ansrNameAssoc[ansrEntity.e] = true));
        }

        if (
            this.dailySummaryData &&
            this.dailySummaryData.report &&
            this.dailySummaryData.report.headers[0] &&
            this.dailySummaryData.report.headers[1]
        ) {
            // collects the Synopsis List
            this.synopsisList = new Array<Synopsis>();
            const tempHeadIndex = {};
            let tempIndex = 0;
            let rainType = null;
            this.dailySummaryData.report.headers[0].forEach((data, index) => {
                const tempMIndex = data.colspan !== undefined ? 4 + (data.colspan - 2) : tempIndex + 1;
                tempHeadIndex[data.name] = [tempIndex, tempIndex + tempMIndex];
                tempIndex = tempIndex + tempMIndex;
                if (ENTITIES_RAIN.includes(data.name)) {
                    data.colspan = 1;
                    rainType = ENTITIES_RAIN.find((x) => x === data.name);
                }
            });

            // to get the entity ame from header[0] loop header
            this.dailySummaryData.report.headers[0].forEach((data, index) => {
                // #38689 Remove ANSR entities from Entity list
                if (data.name !== 'Date' && !ansrNameAssoc[data.name]) {
                    const group = this.getEntityGroup(data.name);
                    const groupId = group ? group.groupId : null;
                    this.synopsisList.push({
                        name: data.name,
                        group: groupId,
                        // synopsis total->synopsis[0], synopsis average->synopsis[1]
                        total: this.dailySummaryData.report.synopsis[0][index].value,
                        average: this.dailySummaryData.report.synopsis[1][index].value,
                        totalSuffix: this.dailySummaryData.report.headers[0][index].totalSuffix,
                        suffix: this.dailySummaryData.report.headers[0][index].suffix,
                    });

                    this.dailySummaryData.report.headers[0][index].group = groupId;
                }
            });

            const checkRainFn = (rainType, index) =>
                tempHeadIndex.hasOwnProperty(rainType) &&
                index >= tempHeadIndex[rainType][0] &&
                index < tempHeadIndex[rainType][1];

            this.dailySummaryData.report.headers[1].forEach((data, index) => {
                const rainType = ENTITIES_RAIN.find((rt) => checkRainFn(rt, index));

                if (rainType) {
                    tempSingleDataIndex.push(tempHeadIndex[rainType][1] - 1);
                    if (index === tempHeadIndex[rainType][1] - 1) {
                        tempSubHeader.push(data);
                    }
                } else {
                    if (data.name !== 'Time' && data.name !== 'Max' && data.name !== 'Min') {
                        tempSingleDataIndex.push(index);
                    }

                    if (data.name !== 'Time') {
                        tempSubHeader.push(data);
                    } else {
                        tempDateIndex.push(index);
                    }
                }
            });

            this.dailySummaryData.report.headers[1] = tempSubHeader;

            const tempData = [];
            this.dailySummaryData.report.data.forEach((data, dataIndex) => {
                const tempDataItem = new Array<DailySummaryData>();

                data.forEach((item, index: number) => {
                    // matches the index where the value are not for Time, Max, Min i.e Avg , Total etc
                    if (tempSingleDataIndex.indexOf(index) >= 0) {
                        tempDataItem.push(item);
                    } else if (tempDateIndex.indexOf(index) >= 0) {
                        let value = '';

                        // setting time in HH:MM AM/PM format for 12 hours and HH:MM for 24 hours
                        if (item.value.indexOf(':') > -1) {
                            if (!item.value.split(':')[2]) {
                                item.value = item.value + ':00';
                            }
                            const time = item.value.split(':')[2].split(' ')[1]
                                ? item.value.split(':')[0] +
                                ':' +
                                item.value.split(':')[1] +
                                ' ' +
                                item.value.split(':')[2].split(' ')[1]
                                : item.value.split(':')[0] + ':' + item.value.split(':')[1];
                            value = data[index + 1].value + '\n' + time;
                        } else {
                            value = data[index + 1].value + '\n' + item.value;
                        }

                        item.value = value;
                        tempDataItem.push(item);
                    }
                });
                if (tempDataItem.length > 0) {
                    const date = tempDataItem[0].value;
                    tempData[date] = tempDataItem;
                }
            });

            const emptyRow = [];
            const firstIndex = Object.keys(tempData)[0];
            const len = tempData[firstIndex].length;
            for (let i = 0; i < len; i++) {
                emptyRow.push({ value: '-\n-' });
            }

            const outData = [];
            for (const d = moment(this.startDate); d <= moment(this.endDate); d.add(1, 'days')) {
                const index = d.format(this.dateUtilService.dateFormat.getValue());
                if (tempData[index]) {
                    outData.push(tempData[index]);
                } else {
                    const empty = [...emptyRow];
                    empty[0] = { value: index };
                    outData.push(empty);
                }
            }

            this.dailySummaryData.report.data = JSON.parse(JSON.stringify(outData));
            this.pageData = this.dailySummaryData.report.data.slice(0, this.pageSize ? this.pageSize : 50);
        }
    }

    public onPaginateChange(event) {
        this.domOperationUtilsService.scrollToTop('html');
        if (this.dailySummaryData && this.dailySummaryData.report && this.dailySummaryData.report.data) {
            const datatable = this.dailySummaryData.report.data.slice();
            // Grab the page's slice of data.
            const startIndex = event.pageIndex * event.pageSize;
            this.pageData = datatable.splice(startIndex, event.pageSize);
        }
    }

    public openDailySummaryExport() {
        if (this.downloadDialog) {
            return;
        }
        this.domOperationUtilsService.dailyDialogOpened = true;
        this.downloadDialog = this.dialog.open(DailySummaryExportComponent, {
            disableClose: true,
            data: {
                preSelectedLocationId: this.locationId,
                preSelectedEntityIds: this.selectedEntities.map((x) => x.id),
                allowedEntityGroups: ENTITY_ALLOWED_GROUPS,
                startDate: this.startDate,
                endDate: this.endDate,
                includeInactiveLocations: this.includeInactiveLocations,
            },
            hasBackdrop: false
        });
        this.downloadDialog.afterClosed().subscribe((result) => {
            this.downloadDialog = null;
            this.domOperationUtilsService.dailyDialogOpened = false;
        });
    }

    private getEntityGroup(name: string) {
        if (!name) return null;

        const groups = [...this.availableEntities$.getValue(), ...this.availableANSREntities$.getValue()];

        if (!groups.length) return null;

        return groups.find(v => v.entities.some(e => e.name && e.name.toUpperCase() === name.toUpperCase()));
    }
}
