import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SliicerCaseStudy, RainfallEvent } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { MathService } from 'app/shared/services/math.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-rain-table',
    templateUrl: './rain-table.component.html',
    styleUrls: ['./rain-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainTableComponent implements OnInit {
    public unit: string;
    public rainPrecision: string;
    public hasRainData = false;
    public rainEvents: any[];
    public rainfallMonitors: string[];
    public allColumns: string[];
    public dateFormat: string;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private dateutilService: DateutilService,
        private sliicerService: SliicerService,
        private mathService: MathService,
    ) {}

    public ngOnInit() {
        this.dateFormat = this.dateutilService.getFormat() || '';
        this.dateFormat += ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
        const customerDateFormat = this.dateFormat.toUpperCase();
        this.unit = this.dateutilService.isCustomerUnitsMetric.getValue() ? 'mm' : 'in';
        this.rainPrecision = this.unit === 'mm' ? '1.1-1' : '1.2-2';

        this.sliicerService.studyDetailsData$.subscribe((caseStudy: SliicerCaseStudy) => {
            this.loadRainfallEvents(caseStudy.customerId, caseStudy.id);
        });
    }

    private loadRainfallEvents(customerId: number, caseStudyId: string): void {
        this.sliicerService.getRainfallEvents(customerId, caseStudyId).subscribe(
            (result) => {
                if (result && result.rainfalls) {

                    result.rainfalls.forEach((rainEvent) =>
                    {
                        rainEvent.rainGaugeMeasurements.sort((a,b) => a.rainGauge.localeCompare(b.rainGauge));
                    });

                    this.rainEvents = this.getTableData(result.rainfalls);
                } else {
                    this.rainEvents = [];
                }
                this.hasRainData = this.rainEvents.length > 0;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            () => {
                this.rainEvents = [];
                this.hasRainData = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    private getTableData(rainEvents: RainfallEvent[]): any[] {
        const resultData = [];
        this.rainfallMonitors = [];
        this.allColumns = ['rainfallDate'];

        rainEvents.sort((a, b) => new Date(a.rainStartTime).getTime() - new Date(b.rainStartTime).getTime());
        rainEvents.forEach((re: RainfallEvent) => {
            const row = [];
            row['rainfallDate'] = re.rainStartTime;
            re.rainGaugeMeasurements.forEach((m) => {
                if (!this.rainfallMonitors.includes(m.rainGauge)) {
                    this.rainfallMonitors.push(m.rainGauge);
                    this.allColumns.push(m.rainGauge);
                }
                row[m.rainGauge] = m.rainInPeriod;
            });
            resultData.push(row);
        });


        this.allColumns = this.mathService.sortArrayExceptOne(this.allColumns,'rainfallDate');

        return resultData;
    }
}
