import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UpdatesAction } from './updates-widget.models';
import { UpdatesWidgetService } from './updates-widget.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'updates-widget',
    templateUrl: './updates-widget.component.html',
    styleUrls: ['./updates-widget.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UpdatesWidgetComponent implements OnDestroy {
    public anchorAt = 20;
    public reviewChanges = false;
    public disableApply = false;
    private subscriptions = [];
    public UpdatesAction = UpdatesAction;

    public trackByIndex = TrackBy.byIndex;
    constructor(public updatesWidgetService: UpdatesWidgetService) {
        this.subscriptions.push(this.updatesWidgetService.updateApplyStatus.subscribe(status => {
            this.disableApply = status;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
        this.updatesWidgetService.setUpdatesInfo([], '');
    }

    updatesAction(action: UpdatesAction): void {
        if (this.reviewChanges) this.setReviewChanges(false);
        this.updatesWidgetService.setUpdatesAction(action);
    }

    setReviewChanges(value: boolean): void {
        this.reviewChanges = value;
    }
}
