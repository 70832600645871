import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { customerQueryParam } from 'app/shared/models/customer';
import { LCConfigurationDetail } from 'app/shared/models/report';
import { NotificationDashboardService } from 'app/shared/services/notification.service';
import { TrackBy } from 'app/shared/utils/track-by';

enum GROUP_BY {
    None,
    Location,
    Notification
}

const SORTING_DIRECTION = [
    'locationname', 'eventtype'
]

const SORTING_TYPE = [
    'asc', 'desc'
]

@Component({
  selector: 'app-location-notification-configuration',
  templateUrl: './location-notification-configuration.component.html',
  styleUrls: ['./location-notification-configuration.component.scss'],
})
export class LocationNotificationConfigurationComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) public matPaginator: MatPaginator;

    private customerId: number = null;

    public displayFilters = false;
    public isLoading = true;
    public dataColumns = ['eventType', 'locationName', 'notificationGroup', 'isActiveEvent', 'isReturnToNormalEvent', 'notificationTree'];
    public dataSource: MatTableDataSource<LCConfigurationDetail> = new MatTableDataSource();
    private sortingDirection = 0;
    public notificationAlarms = [];

    public pagination = {
        pageSize: 10,
        pageIndex: 0,
        rowCount: 0,
        pageSizeOptions: [10, 25, 100, 500]
    }

    public translations = {
        YES: '',
        NO: ''
    }

    public filters: {
        groupByValue: GROUP_BY;
        groupByOptions: Array<{key: GROUP_BY, name: string}>
    } = {groupByOptions: [], groupByValue: GROUP_BY.Location}

    public trackByKey = TrackBy.byUniqueKey<{ key: string; }>()('key');
    public trackByIndex = TrackBy.byIndex;
    constructor(
        private activatedRoute: ActivatedRoute,
        private notifications: NotificationDashboardService,
        private translate: TranslateService,
        private notificationService: NotificationDashboardService,
        private snackBar: MatSnackBar,
    ) {
        this.filters.groupByOptions = [
            {key: GROUP_BY.Location, name: this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.GROUP_BY_LOCATION')},
            {key: GROUP_BY.Notification, name: this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.GROUP_BY_NOTIFICATION')}
        ];

        this.translations.YES = this.translate.instant('COMMON.YES').toLowerCase();
        this.translations.NO = this.translate.instant('COMMON.NO').toLowerCase();
        this.translations.YES = this.translations.YES.charAt(0).toUpperCase() + this.translations.YES.slice(1);
        this.translations.NO = this.translations.NO.charAt(0).toUpperCase() + this.translations.NO.slice(1);

        const alarms = this.notificationService.getAllAlarmTypes();

        alarms.forEach((a) => {
            this.notificationAlarms[a.id] = a.name;
        })
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                this.customerId = Number(params.get(customerQueryParam));

                this.generateData();
            }
        );
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.matPaginator;
    }

    public generateData() {
        this.isLoading = true;

        this.notifications.getNotificationConfiguration(
            this.customerId, SORTING_DIRECTION[this.sortingDirection], SORTING_TYPE[this.sortingDirection] , this.pagination.pageIndex + 1, this.pagination.pageSize
        ).subscribe((res) => {
            this.isLoading = false;

            this.dataSource.data = res.configurationDetails;

            setTimeout(() => {
                if (this.matPaginator) {
                    this.matPaginator.pageIndex = this.pagination.pageIndex;
                    this.matPaginator.length = res.detailsCount;
                }
            });

        }, (error) => {
            this.isLoading = false;

            const TXT_ERROR_MSG = this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.CANNOT_LOAD_PAGE');
            const TXT_DISMISS = this.translate.instant('COMMON.DISMISS_TEXT');

            this.snackBar.open(TXT_ERROR_MSG, TXT_DISMISS, {
                panelClass: 'custom-error-snack-bar',
            });
        });
    }

    public download() {
        this.notifications.getNotificationConfiguration(
            this.customerId, SORTING_DIRECTION[this.sortingDirection], SORTING_TYPE[this.sortingDirection],
        ).subscribe((res) => {
            this.isLoading = false;

            const csvData = new Array<Object>();
            const csvHeaders = [
                `"${this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.EVENT_TYPE')}"`,
                `"${this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.GROUP_BY_LOCATION')}"`,
                `"${this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.NOTIFICATION_GROUP')}"`,
                `"${this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.ACTIVE_EVENT')}"`,
                `"${this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.RETURN_TO_NORMAL_EVENT')}"`,
                `"${this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.NOTIFICATION_TREE')}"`,
            ];

            const TXT_TITLE = this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.NOTIFICATION_CONFIGURATION_TITLE');

            if (res.configurationDetails) {
                res.configurationDetails.forEach((itm) => {
                    const newItem = new Array<string>();

                    newItem.push(this.notificationAlarms[itm.eventType]);
                    newItem.push(itm.locationName);
                    newItem.push(itm.notificationGroup);
                    newItem.push(itm.isActiveEvent ? this.translations.YES : this.translations.NO);
                    newItem.push(itm.isReturnToNormalEvent ? this.translations.YES : this.translations.NO);
                    newItem.push(itm.notificationTree.map(u => u.displayName).join(', '));

                    csvData.push(newItem);
                });

                const options = {
                    showLabels: true,
                    headers: csvHeaders,
                    title: `"${TXT_TITLE}"`,
                    showTitle: true,
                };

                new AngularCsv(csvData, TXT_TITLE, options);
            }

        }, (error) => {
            this.isLoading = false;

            const TXT_ERROR_MSG = this.translate.instant('REPORT.NOTIFICATION_CONFIGURATION.CANNOT_GENERATE_REPORT');
            const TXT_DISMISS = this.translate.instant('COMMON.DISMISS_TEXT');

            this.snackBar.open(TXT_ERROR_MSG, TXT_DISMISS, {
                panelClass: 'custom-error-snack-bar',
            });
        });
    }

    public onGroupBy(ev) {
        this.sortingDirection = this.sortingDirection === 1 ? 0 : 1;
        this.generateData();
    }

    public toggleFilters() {
        this.displayFilters = !this.displayFilters;
    }

    public changePage(page: PageEvent) {
        this.pagination.pageSize = page.pageSize;
        this.pagination.pageIndex = page.pageIndex;

        this.generateData();
    }

}
