<div *ngIf="state" class="seasons-timeline" [ngClass]="[seasonType]">
    <span class="timeline-title">Seasons timeline</span>
    <mat-card class="seasons-card">
        <div *ngIf="!state.items.length" class="season-none season">
            <span>No seasons defined</span>
        </div>
        <div
            *ngFor="let item of state.items; let i = index; trackBy: trackByIndex"
            [ngClass]="['season', item.tag]"
            [ngStyle]="{ width: item.size + '%', 'margin-left': item.offset + '%' }"
        >
            <span>{{ item.name }}</span>
        </div>
        <div *ngFor="let mark of state.years; trackBy: trackByIndex" class="year-mark-container" [ngStyle]="{ left: mark.offset + '%' }">
            <span class="year-mark">{{ mark.year }}</span>
        </div>
        <div
            *ngIf="state.start | asSome; let start"
            class="study-date-mark-container"
            [ngStyle]="{ left: start.value.offset + '%' }"
        >
            <span class="study-date-mark mark-start">{{ start.value.date | date: dateFormat }}</span>
        </div>
        <div
            *ngIf="state.end | asSome; let end"
            class="study-date-mark-container"
            [ngStyle]="{ left: end.value.offset + '%' }"
        >
            <span class="study-date-mark mark-end">{{ end.value.date | date: dateFormat }}</span>
        </div>
    </mat-card>
    <div class="study-dates">
        <span *ngIf="state.start | isNone" class="date-start">{{ startDate | date: dateFormat }}</span>
        <span *ngIf="state.end | isNone" class="date-end">{{ endDate | date: dateFormat }}</span>
    </div>
</div>
