import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
    Input,
    SimpleChanges,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
} from '@angular/core';
import {
    DepthDurationFrequency,
    DdfTable
} from 'app/shared/models/sliicer/customer-rainfall-profile';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DdfGraphComponent, GRAPH_SYMBOL_CROSS } from 'app/shared/components/ddf-graph/ddf-graph.component';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SLI_COLOR_RAINFALL_MONITORS } from 'app/shared/constant';
import { SliicerCaseStudy, RainfallEvent } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import highCharts from 'highcharts';
import { DatePipe } from '@angular/common';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VaultUploadDialogLocalComponent } from 'app/pages/vault';
import { IDataTransfer } from 'app/shared/models/modal';
import { VaultUpload } from 'app/shared/models/vault';
import { SharedService } from 'app/shared/services/shared.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { Settings } from '../../../../../shared/models/sliicer/settings';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { TrackBy } from 'app/shared/utils/track-by';

const CSV_FILE_TYPE = 'text/csv';
const DAY_MIN_CONVERSION = 1440;
const SNACKBAR_DURATION = 10000;
const HR_MIN_CONVERSION = 60;
const HR_DAY_CONVERSION = 24;
const ddfTableColumnNames = [
    'duration',
    '2mo',
    '3mo',
    '4mo',
    '6mo',
    '9mo',
    '1yr',
    '2yr',
    '5yr',
    '10yr',
    '25yr',
    '50yr',
    '100yr',
    '200yr',
    '500yr',
    '1000yr',
];
const ddfTableFieldNames = [
    'month1',
    'month2',
    'month3',
    'month4',
    'month6',
    'month9',
    'year1',
    'year2',
    'year5',
    'year10',
    'year25',
    'year50',
    'year100',
    'year200',
    'year500',
    'year1000'
]
const DDF_SERIES = [
    { key: 'month1', legend: '1 Month' },
    { key: 'month2', legend: '2 Month' },
    { key: 'month3', legend: '3 Month' },
    { key: 'month4', legend: '4 Month' },
    { key: 'month6', legend: '6 Month' },
    { key: 'month9', legend: '9 Month' },
    { key: 'year1', legend: '1 Year' },
    { key: 'year2', legend: '2 Year' },
    { key: 'year5', legend: '5 Year' },
    { key: 'year10', legend: '10 Year' },
    { key: 'year25', legend: '25 Year' },
    { key: 'year50', legend: '50 Year' },
    { key: 'year100', legend: '100 Year' },
    { key: 'year200', legend: '200 Year' },
    { key: 'year500', legend: '500 Year' },
    { key: 'year1000', legend: '1000 Year' },
];

interface DDF_TABLE_DEFINITION {
    title: string;
    definition: string;
    visible: boolean;
}

@Component({
    selector: 'app-rainfall-ddf',
    templateUrl: './rainfall-ddf.component.html',
    styleUrls: ['./rainfall-ddf.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainfallDdfComponent implements OnInit, OnDestroy {
    @ViewChildren('durationMinutesInputs') public durationMinutesInputs: QueryList<ElementRef<HTMLInputElement>>;
    @ViewChild('rainfallEventChart', { static: true }) public chartEl: ElementRef;
    @ViewChild('addNewRowBtn', { static: true }) public addNewRowBtn: MatButton;
    @ViewChild('ddfGraph', { static: true }) public ddfGraphComponent: DdfGraphComponent;

    @ViewChild('ddfGraphForStudy', { static: true }) public ddfGraphComponentForStudy: DdfGraphComponent;
    @ViewChild('selectGauge') public selectGauge;
    @ViewChild('table') public matTable: MatTable<MatTableDataSource<DdfTable[]>>;

    @Output() ddfTableChange = new EventEmitter<DdfTable[]>();

    public canSaveDdfTable = true;

    public rainfallTableColumnNames = [];
    public rainfallTableColumnDefinitions: DDF_TABLE_DEFINITION[] = [];
    public allRainEvents: Array<string> = [];
    public selectedRainEvent: string;
    public rainfallmonitorDataSource;
    public unit: string;
    public dataExists = false;

    public selectedGauges: Array<string> = [];

    public caseStudyId: string;
    public caseStudySettings: Settings;

    public ddfTableSource: any;
    public ddfData: DdfTable[];
    public origddfData : DdfTable[];
    public ddf: DepthDurationFrequency;
    public rainfallDataColumns: any;
    public finalData = {};
    public tempData = {};
    public chooseCsvFileMsg: string;
    public deleteColumn = 'checked';
    public deleteButton = false;
    public cancelButton = false;
    public isDepthDurationHeaderChecked: boolean;
    public durationOptions = ['min', 'hr', 'days'];
    public data = [
        'month2',
        'month3',
        'month4',
        'month6',
        'month9',
        'year1',
        'year2',
        'year5',
        'year10',
        'year25',
        'year50',
        'year100',
        'year200',
        'year500',
        'year1000',
    ];
    public filterOptions = [
        '2mo',
        '3mo',
        '4mo',
        '6mo',
        '9mo',
        '1yr',
        '2yr',
        '5yr',
        '10yr',
        '25yr',
        '50yr',
        '100yr',
        '200yr',
        '500yr',
        '1000yr',
    ];

    public readonly INPUT_DURATION_ID = 'durationMinutes_';

    public columnIndex = 0;
    public hr = 'hr';
    public minute = 'min';
    public days = 'days';

    public ddfTableColumns = ddfTableColumnNames;
    public ddfTableColumnDefs: { name: string; show: boolean }[] = ddfTableColumnNames.map((v) => ({
        name: v,
        show: true,
    }));
    @Input('customerId') public customerId: number;
    @Input('customerUnit') public customerUnit: number;

    public selectedOptions: string[];

    public isLoadingState: boolean;

    private rainfallEvents: RainfallEvent[];
    private depthDurationFrequency: DepthDurationFrequency;
    public rainPrecision: string;
    public gaugeData: any[];
    public isLocked = false;
    public dismissBtn: string;
    private badDDFFormatText: string;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private customerService: CustomerService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private snackBarNotificationService: SnackBarNotificationService,
        private dateutilService: DateutilService,
        private sliicerService: SliicerService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private sharedService: SharedService,
        public domOperationUtilsService: DomOperationUtilsService,
        public elementRef: ElementRef,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this.applyTranslations();
        this.ddfData = [];
        // Create table datasources to avoid table errors
        this.applyDdfSource(this.ddfData);
        this.rainfallmonitorDataSource = new MatTableDataSource([]);

        this.selectedOptions = []; // hide column Checkbox should be unchecked as default;;
        this.rainfallDataColumns = this.ddfTableColumns;

        this.unit = this.dateutilService.isCustomerUnitsMetric.getValue() ? 'mm' : 'in';
        this.rainPrecision = this.unit === 'mm' ? '1.1-1' : '1.2-2';

        highCharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
            return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
        };
        if (highCharts.VMLRenderer) {
            highCharts.VMLRenderer.prototype.symbols.cross = highCharts.SVGRenderer.prototype.symbols.cross;
        }

        this.sliicerService.caseStudyEditable.subscribe((isEditable) => {
            this.isLocked = !isEditable;
            this.uiUtilsService.safeChangeDetection(this.cdr);

            this.resetButtons();
        });

        this.sliicerService.studyDetailsData$.subscribe((caseStudy: SliicerCaseStudy) => {
            this.caseStudyId = caseStudy.id;
            this.caseStudySettings = caseStudy.settings;
            this.isLocked = caseStudy.meta.locked;
            this.rainfallTableColumnDefinitions = [
                {
                    title: 'Duration',
                    definition: 'Duration',
                    visible: true,
                },
                {
                    title: '',
                    definition: 'rainDuration',
                    visible: false,
                },
            ];
            if (caseStudy.config && caseStudy.config.rainfallMonitors) {
                caseStudy.config.rainfallMonitors.forEach((rm) => {
                    this.rainfallTableColumnDefinitions.push({
                        title: rm.name,
                        definition: rm.name,
                        visible: true,
                    });
                });
            }
            this.rainfallTableColumnNames = this.rainfallTableColumnDefinitions.map((x) => x.definition);

            this.depthDurationFrequency = caseStudy.settings.ddfData;

            if(this.depthDurationFrequency && this.depthDurationFrequency.ddfTable)
            {
                Object.keys(this.depthDurationFrequency.ddfTable)?.forEach((key) => {
                    Object.keys(this.depthDurationFrequency.ddfTable[key])?.forEach((keyVal) =>
                    {
                        if(isNaN(this.depthDurationFrequency.ddfTable[key][keyVal]))
                        {
                            this.depthDurationFrequency.ddfTable[key][keyVal] = null;
                        }
                    });
                });
            }

            this.dataExists = caseStudy.settings.ddfData?.ddfTable?.length === 0 && caseStudy.settings.ddfData.pk === 0 ? false: true;
            this.loadRainfallEvents(caseStudy.customerId, caseStudy.id);
        });

        this.getDepthDurationFrequency();
    }

    public ngOnDestroy() {
        if (this.ddfGraphComponentForStudy && this.ddfGraphComponentForStudy.chart) {
            this.ddfGraphComponentForStudy.chart.destroy();
        }
        if (this.ddfGraphComponent && this.ddfGraphComponent.chart) {
            this.ddfGraphComponent.chart.destroy();
        }
    }

    public rainEventChanged() {
        const index = this.allRainEvents.findIndex((re) => re === this.selectedRainEvent);
        if (index >= 0) {
            this.setRainfallEvent(this.rainfallEvents[index], this.depthDurationFrequency);
        }
    }

    public onClickOfLegend(event) {

        event.preventDefault();

        const browserEvent = event.browserEvent;
        const target = browserEvent.target;
        const parent = target.parentElement;
        const index = parent.getAttribute('tabindex');

        if (index === undefined || index === null) return;

        const series = event.target.chart.series;
        const serie = series[index];
        const visible = serie.visible;

        series[index].isProcessed = false;

        if (visible) {
            serie.hide();
        } else {
            serie.show();
        }
    }

    public onDDFtabChange(event: MatTabChangeEvent) {
        this.domOperationUtilsService.ddfTabIndex.next(event.index);
    }

    public ddfFilter(values) {
        const columnNameArr = [];
        this.ddfTableColumns.forEach((ele) => {
            if (values.indexOf(ele) === -1 && ele !== 'duration') {
                columnNameArr.push(ele);
            }
        });
        this.rainfallDataColumns = columnNameArr;
        this.rainfallDataColumns.unshift('duration');
    }

    /** Depth duration frequency */
    public openUploadDepthDurationDialog($event: MouseEvent, fileType?: string): void {
        // setup initial url
        fileType = CSV_FILE_TYPE;

        this.dialog
            .open(VaultUploadDialogLocalComponent, {
                data: <IDataTransfer>{
                    fileType: fileType,
                },
                panelClass: 'file-upload-background-panel',
                disableClose: true,
            })
            .afterClosed()
            .subscribe(
                (vaultUpload: VaultUpload) => {
                    // if upload fails
                    if (!vaultUpload) {
                        return;
                    }
                    if (vaultUpload) {
                        let uploadContainsNaN = false;
                        const response = JSON.parse(vaultUpload.response);
                        this.ddfData = response.ddfTable;
                        this.ddfData.forEach((element) => {
                            element.duration = this.minute;
                            element.durationvalue = element.durationMinutes;
                            this.data.forEach((d) => {
                                element[d] = this.applyUnitRounding(element[d]);
                                if(isNaN(element[d])){
                                    uploadContainsNaN = true;
                                }                         
                            });
                        });
                        if(this.ddfData.length === 0 || uploadContainsNaN)
                        {
                                this.snackBarNotificationService.raiseNotification(this.badDDFFormatText, this.dismissBtn, {
                                    panelClass: 'custom-error-snack-bar',
                                }, false);
                        }
                        else{
                            this.applyDdfSource(this.ddfData);
                            this.ddf = {
                                ddfTable: this.ddfData,
                            };
                            this.ddfGraphManual(this.ddfData, this.unit);
                            this.onDdfChanges();
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        }
                    }
                },
                (error) => {
                },
            );

        if (fileType === CSV_FILE_TYPE) {
            this.sharedService.setFileUploadMessage(this.chooseCsvFileMsg);
        }
    }

    /** DDF Graph */
    public ddfGraphManual(ddfValues: DdfTable[], unitOfMeasure: string) {
        this.data.forEach((dataValue) => {
            this.finalData[dataValue] = [];
            this.tempData[dataValue] = [];
        });

        ddfValues.forEach((element) => {
            this.data.forEach((val) => {
                const value: number = !element[val] || element[val] === '' ? 0 : parseFloat(element[val]);
                if (!!value) {
                    this.tempData[val].push(element['durationMinutes']);
                    this.tempData[val].push(value);
                    this.finalData[val].push(this.tempData[val]);
                    this.tempData[val] = [];
                }
            });
        });

        const seriesData = [];
        this.data.forEach((series) => {
            const points = this.finalData[series];
            if (points && points.length > 0) {
                const obj = {
                    name: this.splitLegendText(series),
                    data: this.finalData[series],
                    pointStart: 5,
                    showInLegend: false,
                    color: '#999',
                };
                seriesData.push(obj);
            }
        });

        this.selectedGauges = [];

        const rainEventIndex = this.allRainEvents.findIndex((re) => re === this.selectedRainEvent);
        if (rainEventIndex >= 0) {
            const rainEvent = this.rainfallEvents[rainEventIndex];
            this.setRainfallEvent(rainEvent, this.ddf);
        } else {
            this.ddfGraphComponent.generateDdfGraph(seriesData);
        }
    }

    /**
     * This method will modify ddf chart legend from month-year to year-month
     * @param {String} value: receive as legend string as parameter
     */
    public splitLegendText(value: string) {
        const matchVal = value ? value.match(/\d+/) : value;
        if (matchVal) {
            const numInValue = matchVal[0];
            const index = value.indexOf(numInValue);
            const txt = value.substring(0, index) + ',' + value.substring(index);
            return txt.split(',')[1] + ' ' + txt.split(',')[0];
        } else {
            return value;
        }
    }

    /**Method to convert duration values based on selected unit value */
    public onDurationUnitChange(event, index: number) {
        if (
            this.ddfData &&
            this.ddfData.length > 0 &&
            this.ddfData[index] &&
            this.ddfData[index].durationMinutes &&
            event &&
            event.value &&
            event.source &&
            event.source.ngControl
        ) {
            const duration = this.ddfData[index].durationMinutes,
                newValue = event.value,
                oldValue = event.source.ngControl.model;
            let result: number;
            if (oldValue === this.durationOptions[0]) {
                if (newValue === this.durationOptions[2]) {
                    result = duration / DAY_MIN_CONVERSION;
                } else if (newValue === this.durationOptions[1]) {
                    result = duration / HR_MIN_CONVERSION;
                }
            } else if (oldValue === this.durationOptions[2]) {
                if (newValue === this.durationOptions[1]) {
                    result = duration * HR_DAY_CONVERSION;
                } else if (newValue === this.durationOptions[0]) {
                    result = duration * DAY_MIN_CONVERSION;
                }
            } else if (oldValue === this.durationOptions[1]) {
                if (newValue === this.durationOptions[0]) {
                    result = duration * HR_MIN_CONVERSION;
                } else if (newValue === this.durationOptions[2]) {
                    result = duration / HR_DAY_CONVERSION;
                }
            }
            this.ddfData[index].durationMinutes = result;
            this.applyDdfSource(this.ddfData, false);
        }
    }

    /**
      onchanges lifecyclehook used to fetch existing storm and dry day definitions of customer
    **/
      public ngOnChanges(changes: SimpleChanges) {
        if (this.customerId) {
            this.getDepthDurationFrequency();
        }
    }

    /** To add new row in ddf table */
    public addNewRow() {
        this.clearSelectedRow();
        this.ddfData.push({
            month1: null,
            month2: null,
            month3: null,
            month4: null,
            month6: null,
            month9: null,
            year1: null,
            year2: null,
            year5: null,
            year10: null,
            year25: null,
            year50: null,
            year100: null,
            year200: null,
            year500: null,
            year1000: null,
            durationvalue: null,
            durationMinutes: null,
            duration: this.minute,
        });
        this.applyDdfSource(this.ddfData, false);
        this.cancelButton = true;

        setTimeout(() => {
            // Force mark newly added row RED
            this.durationMinutesInputs.last.nativeElement.focus();
            this.addNewRowBtn.focus();
        }, 100)

        this.onDdfChanges();
    }

    public deleteSelectedRow() {
        this.ddfData = this.ddfTableSource.data.filter((elements) => !elements.checked);
        this.applyDdfSource(this.ddfData, false);
        this.onDdfChanges();
        this.clearSelectedRow();
    }

    private resetButtons() {
        this.deleteButton = false;
        this.cancelButton = false;
    }

    /** To delete rows in ddf table **/
    public deleteRow() {
        this.rainfallDataColumns.unshift(this.deleteColumn);
        this.deleteButton = true;
    }

    public cancel() {
        if(this.origddfData){
            this.ddfData = this.origddfData?.map((x) => Object.assign({}, x));
            this.applyDdfSource(this.origddfData);
        }
        this.cancelButton = false;
        this.onDdfChanges();
    }

    public clearSelectedRow() {
        if (this.rainfallDataColumns.some((column: string) => column === this.deleteColumn)) {
            this.rainfallDataColumns.shift(this.deleteColumn);
        }
        this.deleteButton = false;
        this.isDepthDurationHeaderChecked = false;
        if (this.ddfTableSource && this.ddfTableSource.data) {
            this.ddfTableSource.data.forEach((item) => (item.checked = false));
        }
    }

    public depthDurationCheckBoxAll(event: MatCheckbox) {
        if (this.ddfTableSource.data) {
            this.ddfTableSource.data.forEach((item) => (item.checked = event.checked));
        }
        this.applyDdfSource(this.ddfTableSource.data, false);
    }

    public depthDurationCheckBox(event: MatCheckbox) {
        if (this.ddfTableSource.data) {
            const selectedItems = this.ddfTableSource.data.filter((item) => item.checked) || [];
            this.isDepthDurationHeaderChecked = this.ddfTableSource.data.length === selectedItems.length;
        }
    }

    /** To get ddf data*/
    public getDepthDurationFrequency() {
        this.isLoadingState = true;

        if (this.customerId && this.caseStudyId) {
            // if, there exists a study specific ddf table then we should show that one...
            // otherwise get the customer ddf table.
            this.sliicerService.getCaseStudy(this.customerId, this.caseStudyId).subscribe(
                (result: SliicerCaseStudy) => {
                    if (result.settings?.ddfData.ddfTable) {
                        result.settings?.ddfData.ddfTable.forEach((element) => {
                            element.durationvalue = element.durationMinutes;
                            element.duration = this.minute;
                            element.month2 = this.applyUnitRounding(element.month2);
                            element.month3 = this.applyUnitRounding(element.month3);
                            element.month4 = this.applyUnitRounding(element.month4);
                            element.month6 = this.applyUnitRounding(element.month6);
                            element.month9 = this.applyUnitRounding(element.month9);
                            element.year1 = this.applyUnitRounding(element.year1);
                            element.year2 = this.applyUnitRounding(element.year2);
                            element.year5 = this.applyUnitRounding(element.year5);
                            element.year10 = this.applyUnitRounding(element.year10);
                            element.year25 = this.applyUnitRounding(element.year25);
                            element.year50 = this.applyUnitRounding(element.year50);
                            element.year100 = this.applyUnitRounding(element.year100);
                            element.year200 = this.applyUnitRounding(element.year200);
                            element.year500 = this.applyUnitRounding(element.year500);
                            element.year1000 = this.applyUnitRounding(element.year1000);
                        });
                        this.ddfData = result.settings.ddfData.ddfTable;
                        this.ddf = result.settings.ddfData;
                        this.applyDdfSource(this.ddfData);
                        this.ddfGraphManual(this.ddfData, this.unit);
                        this.origddfData = this.ddfData?.map((x) => Object.assign([], JSON.parse(JSON.stringify(x))));
                    } else {
                        this.ddfData = [];
                        this.addNewRow();
                        this.applyDdfSource(this.ddfData);
                    }
                    this.isLoadingState = false;
                },
                (error) => {
                    this.isLoadingState = true;
                },
            );
        }
        if (!this.isLoadingState) {
            this.customerService.getDepthDuration(this.customerId).subscribe(
                (result: DepthDurationFrequency) => {
                    if (result.ddfTable) {
                        result.ddfTable.forEach((element) => {
                            element.durationvalue = element.durationMinutes;
                            element.duration = this.minute;
                            element.month2 = this.applyUnitRounding(element.month2);
                            element.month3 = this.applyUnitRounding(element.month3);
                            element.month4 = this.applyUnitRounding(element.month4);
                            element.month6 = this.applyUnitRounding(element.month6);
                            element.month9 = this.applyUnitRounding(element.month9);
                            element.year1 = this.applyUnitRounding(element.year1);
                            element.year2 = this.applyUnitRounding(element.year2);
                            element.year5 = this.applyUnitRounding(element.year5);
                            element.year10 = this.applyUnitRounding(element.year10);
                            element.year25 = this.applyUnitRounding(element.year25);
                            element.year50 = this.applyUnitRounding(element.year50);
                            element.year100 = this.applyUnitRounding(element.year100);
                            element.year200 = this.applyUnitRounding(element.year200);
                            element.year500 = this.applyUnitRounding(element.year500);
                            element.year1000 = this.applyUnitRounding(element.year1000);
                        });
                        this.ddfData = result.ddfTable;
                        this.ddf = result;
                        this.applyDdfSource(this.ddfData);
                        this.ddfGraphManual(this.ddfData, this.unit);
                    } else {
                        this.ddfData = [];
                        this.addNewRow();
                        this.applyDdfSource(this.ddfData);
                    }
                    this.isLoadingState = false;
                },
                (error) => {
                    this.isLoadingState = false;
                },
            );
        }
    }

    private getNextColumnIndex(index: number, isRight = true) {
        let columnIndex = -1;

        while (columnIndex === -1) {
            columnIndex = this.rainfallDataColumns.indexOf(this.ddfTableColumnDefs[index].name);

            index = isRight ? index + 1 : index - 1;
        }

        return columnIndex;
    }

    public onShowDDFColumnChange(name: string, event: MatCheckboxChange) {
        const columnDef = this.ddfTableColumnDefs.find((v) => v.name === name);
        columnDef.show = event.checked;

        this.updateDDFColumns();
    }

    private updateDDFColumns() {
        this.rainfallDataColumns = this.ddfTableColumnDefs.filter((v) => v.show).map((v) => v.name);
    }

    public setPointer(index: number, isRight = true) {
        const displayedColumnIndex = this.getNextColumnIndex(this.columnIndex, isRight)

        this.columnIndex = this.ddfTableColumnDefs.findIndex(v => v.name === this.rainfallDataColumns[displayedColumnIndex]);

        const inputId = `${this.INPUT_DURATION_ID}${index}_${this.columnIndex}`;
        const input = document.getElementById(inputId);

        if(input) {
            input.focus();
        }
    }

    public setColumn(index) {
        this.columnIndex = index;
    }

    public calculateMinutes(element: DdfTable): number {
        if (element.duration === this.hr) {
            return element.durationMinutes * HR_MIN_CONVERSION;
        } else if (element.duration === this.days) {
            return element.durationMinutes * DAY_MIN_CONVERSION;
        } else {
            return element.durationMinutes;
        }
    }

    public prepareDdfData(convertFields = true) {
        const precision = 2;

        this.ddfData.forEach((row) => {
            if(convertFields) {
                for(const fieldName of ddfTableFieldNames) {
                    row[fieldName] = row[fieldName] ? row[fieldName].toFixed(precision) : null;
                }
            }

            row.durationMinutes = this.calculateMinutes(row);

            // #39233 Values are minute
            row.duration = this.minute;
        });
    }

    public onDdfChanges() {
        const modifiedValues: DdfTable[] = this.ddfData.map(val => {
            let el = {
                durationMinutes: this.calculateMinutes(val)
            };
            for(const fieldName of ddfTableFieldNames) {
                el[fieldName] = val[fieldName] ? new Number(val[fieldName]) : null;
            }

            // Have to cast, we write those fields by fieldNames array
            return el as DdfTable;
        });
        this.ddfTableChange.emit(modifiedValues);
    }

    public undoChanges() {
        this.ddfData = JSON.parse(JSON.stringify(this.caseStudySettings.ddfData.ddfTable));

        this.prepareDdfData();

        this.applyDdfSource(this.ddfData);

        this.resetButtons();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public applyChanges() {
        this.caseStudySettings.ddfData.ddfTable = this.ddfData;
        this.prepareDdfData(false);
        this.applyDdfSource(this.ddfData);
        this.ddfGraphManual(this.ddfData, this.unit);
        this.resetButtons();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public applyDdfSource(data: DdfTable[], shouldSort = true) {
        if(data && shouldSort) {
            data.sort((row1, row2) => row1.durationMinutes > row2.durationMinutes ? 1 : row1.durationMinutes < row2.durationMinutes ? -1 : 0)
        }

        if(this.ddfTableSource) {
            this.ddfTableSource.data = data;
        } else {
            this.ddfTableSource = new MatTableDataSource(data);
        }
    }

    public setLoading(isLoading: boolean) {
        this.isLoadingState = isLoading;
    }

    private loadRainfallEvents(customerId: number, caseStudyId: string): void {
        const dateFormat = this.dateutilService.getFormat();
        const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
        const customDateFormat = is12HourFormat ? `${dateFormat} ${'hh:mm a'}` : `${dateFormat} ${'HH:mm'}`;

        this.sliicerService.getRainfallEvents(customerId, caseStudyId).subscribe(
            (result) => {
                if (result && result.rainfalls) {
                    this.rainfallEvents = result.rainfalls;
                    this.allRainEvents = this.rainfallEvents.map((re) => {
                        return this.datePipe.transform(re.rainStartTime, customDateFormat);
                    });
                    if (this.allRainEvents.length > 0) {
                        this.selectedRainEvent = this.allRainEvents[0];
                        this.setRainfallEvent(this.rainfallEvents[0], this.depthDurationFrequency);
                    }
                } else {
                    this.rainfallEvents = [];
                    this.allRainEvents = [];
                    this.selectedRainEvent = '';
                }
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.rainfallEvents = [];
                this.allRainEvents = [];
                this.selectedRainEvent = '';
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    private applyUnitRounding(value: number): any {
        const place: number = this.unit === 'mm' ? 1 : 2;
        return value === null ? null : Number(value).toFixed(place);
    }

    private getTableData(rainEvent: RainfallEvent, ddf: DepthDurationFrequency): any[] {
        // first, populate with DDF durations
        const resultData = ddf?.ddfTable?.map((duration) => {
            const v = [];
            v['Duration'] = duration.durationMinutes;
            v['rainDuration'] = duration.durationMinutes + ' Min';
            return v;
        });

        if (rainEvent && rainEvent.rainGaugeMeasurements) {
            rainEvent.rainGaugeMeasurements.forEach((rgm) => {
                /** TODO: WPS - Add capability to show the rainfall duration as measured by the rainfall monitor
                 * There are a couple of ways to do it. Tried adding this along with HTML change but it did
                 * not take.
                 * Another way to try is to add another row (like the DryDayStatistics table)
                // Add the duration of period to the column definitions
                let def = this.rainfallTableColumnDefinitions.find(d => d.defintion === rgm.rainGauge);
                if (def) {
                    def.titleExtra = rgm.durationOfPeriod;
                }
                 **/
                rgm.rainMeasurements.forEach((m) => {
                    if(resultData){

                        const index = resultData?.findIndex((data) => data['Duration'] === m.duration);
                        if (index < 0) {
                            const v = [];
                            v['Duration'] = m.duration;
                            v['rainDuration'] = m.duration + ' Min';
                            v[rgm.rainGauge] = m.rainTotal;
                            resultData.push(v);
                        } else {
                            resultData[index][rgm.rainGauge] = m.rainTotal;
                        }
                    }
                });
            });
        }

        return resultData;
    }

    private getMaxFreq(rainEvent: RainfallEvent, ddf: DepthDurationFrequency): any[] {
        // first, populate with DDF durations
        const resultData = [];

        const dic = {};

        if (rainEvent && rainEvent.rainGaugeMeasurements) {
            rainEvent.rainGaugeMeasurements.forEach((rgm) => {
                rgm.rainMeasurements.forEach((m) => {
                    let update = false;
                    const index = resultData.findIndex((data) => data['name'] === rgm.rainGauge);
                    if (!dic[rgm.rainGauge]) {
                        dic[rgm.rainGauge] = {};
                        dic[rgm.rainGauge].duration = m.duration + ' Min';
                        dic[rgm.rainGauge].name = rgm.rainGauge;
                        dic[rgm.rainGauge].rainTotal = m.rainTotal;
                        dic[rgm.rainGauge].color =
                            SLI_COLOR_RAINFALL_MONITORS[resultData.length % SLI_COLOR_RAINFALL_MONITORS.length];
                        update = true;
                    } else if (dic[rgm.rainGauge].rainTotal < m.rainTotal) {
                        dic[rgm.rainGauge].duration = m.duration + ' Min';
                        dic[rgm.rainGauge].rainTotal = m.rainTotal;
                        update = true;
                    }
                    if (update) {
                        if (index < 0) {
                            resultData.push(dic[rgm.rainGauge]);
                        } else {
                            resultData[index] = dic[rgm.rainGauge];
                        }
                        update = false;
                    }
                });
            });
        }

        return resultData;
    }

    private setRainfallEvent(rainEvent: RainfallEvent, ddf: DepthDurationFrequency) {
        this.selectedGauges = [];

        const resultData = this.getTableData(rainEvent, ddf);
        this.gaugeData = this.getMaxFreq(rainEvent, ddf);
        this.rainfallmonitorDataSource = new MatTableDataSource(resultData);

        const gaugeData = this.rainEventSeries(rainEvent);
        const ddfData = this.ddfGraph(ddf);
        this.ddfGraphComponent.gaugeData = this.gaugeData;
        this.ddfGraphComponent.ddfData = ddfData;
        this.ddfGraphComponent.generateDdfGraph(gaugeData.concat(ddfData));
    }

    private ddfGraph(ddf: DepthDurationFrequency): any[] {
        const seriesData = [];
        DDF_SERIES.forEach((series) => {
            const points = this.pointsForSeries(series.key, ddf);
            if (points.length === 0) {
                return;
            }
            seriesData.push({
                name: series.legend,
                data: points,
                showInLegend: true,
                color: '#999',
            });
        });
        return seriesData;
    }

    private pointsForSeries(seriesKey: string, ddf: DepthDurationFrequency): any[] {
        const points = [];
        ddf?.ddfTable?.forEach((duration) => {
            const value = duration[seriesKey];
            if (value !== null) {
                points.push([duration.durationMinutes, Number(duration[seriesKey])]);
            }
        });
        return points;
    }

    private applyTranslations() {
        this.dismissBtn = this.translate.instant('COMMON.DISMISS_TEXT');
        this.badDDFFormatText = this.translate.instant('CUSTOMER_EDITOR.RAIN_PROFILE.DDF.FILE_TYPE_ERR_DDF_FORMAT');
    }

    private rainEventSeries(rainEvent: RainfallEvent): any[] {
        const seriesData = [];
        if (rainEvent && rainEvent.rainGaugeMeasurements) {
            rainEvent.rainGaugeMeasurements.forEach((rgm, index) => {
                const points = rgm.rainMeasurements.map((m) => {
                    return [m.duration, m.rainTotal];
                });
                seriesData.push({
                    name: rgm.rainGauge,
                    isProcessed: false,
                    color: SLI_COLOR_RAINFALL_MONITORS[index % SLI_COLOR_RAINFALL_MONITORS.length],
                    data: points,
                    showInLegend: true,
                    marker: {
                        symbol: GRAPH_SYMBOL_CROSS,
                        lineWidth: 2,
                    }
                });
            });
        }
        return seriesData;
    }

    public arrowUpEvent(event, index: number) {
        event.preventDefault();
        const nextrow = this.ddfTableSource.data[index - 1];
        if (nextrow) {
            this.setPointer(index - 1);
        }
    }

    public arrowDownEvent(event, index: number) {
        event.preventDefault();
        const nextrow = this.ddfTableSource.data[index + 1];
        if (nextrow) {
            this.setPointer(index + 1);
        }
    }

    public arrowLeftEvent(event, index: number) {
        event.preventDefault();
        const checkedDdfTableColumns = this.ddfTableColumnDefs.map((v) => {
            return {name: v, visible: this.rainfallDataColumns.includes(v.name)}
        })

        if (this.columnIndex === 0) {
            index--;
            if(index < 0) index = 0;
            // will decrease it in next step
            this.columnIndex = 15 + 1;
        }


        do {
            this.columnIndex--;
        } while(this.columnIndex > 0 && !checkedDdfTableColumns[this.columnIndex].visible)

        const nextrow = this.ddfTableSource.data[index];
        if (nextrow) {
            this.setPointer(index, false);
        }
    }

    public arrowRightEvent(event, index: number) {
        event.preventDefault();

        const checkedDdfTableColumns = this.ddfTableColumnDefs.map((v) => {
            return {name: v, visible: this.rainfallDataColumns.includes(v.name)}
        })

        if (this.columnIndex === 15) {
            index++;
            if(index >= this.ddfData.length) index = this.ddfData.length - 1;
            // will increase it in next step
            this.columnIndex = 0 - 1;
        }


        do {
            this.columnIndex++;
        } while(this.columnIndex < 15 && !checkedDdfTableColumns[this.columnIndex].visible);

        const nextrow = this.ddfTableSource.data[index];
        if (nextrow) {
            this.setPointer(index);
        }
    }
}
