<mat-expansion-panel [ngClass]="{ 'echo-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ 'ADD_EDIT.RAINALERT' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>
        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span> </span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <mat-panel-subtitle class="input-text">
            {{ 'ADD_EDIT.ADD_EDIT_SUBTITLE' | translate }}
        </mat-panel-subtitle>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'ADD_EDIT.SENSOR' | translate }}"
                        class="full-width"
                        formControlName="sensor"
                    >
                        <mat-option value="{{ 'ADD_EDIT.RAIN' | translate }}">
                            {{ 'ADD_EDIT.RAIN' | translate }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="custom-50p">
                <div class="each-Item item-flex-center">
                    {{ 'ADD_EDIT.RAIN_INTENSITY_INTERVAL' | translate }}
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            type="number"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 44 || event.charCode == 46)"
                            placeholder="{{ 'ADD_EDIT.MINUTE' | translate }}"
                            formControlName="intensityinterval"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('intensityinterval')?.errors">
                        {{ 'HOME.MAP.MONITOR_SETTINGS.RAIN_INTENSITY_VALIDATION' | translate }}
                    </div>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item item-flex-center">
                    <mat-checkbox class="custom-slide-toggle" formControlName="storetemperature">
                        {{ 'HOME.MAP.MONITOR_SETTINGS.STORE_TEMPERATURE_TEXT' | translate }}
                    </mat-checkbox>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.STORE_BATTERY_PLACEHOLDER' | translate }}"
                            formControlName="storebattery"
                            class="full-width"
                        >
                            <mat-option *ngFor="let v of batteryVoltage; trackBy: trackByValue" [value]="v.value">{{ v.text }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-50p text-bottom-space">
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="logintensity">
                        {{ 'ADD_EDIT.LOG_INTENSITY' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="logukintensity"
                        [disabled]="sampleRate !== 120"
                    >
                        {{ 'ADD_EDIT.LOG_UK_INTENSITY' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </form>
</mat-expansion-panel>
