<div class="customDialogLayer">
    <div class="customDialog">
        <div class="customDialogPosition">
            <div id="resonForEditId">
                <div class="popupHeaderWithButton" mat-dialog-title>
                    <div class="auto-margin pad-l-15">
                        {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.DATA_APPROVAL' | translate }}
                    </div>
                    <div class="app-flex-filler"></div>
                </div>
                <div mat-dialog-content class="eachTabContent">
                    <app-approve-data 
                    [startDate]="startDate" 
                    [endDate]="endDate" 
                    [prevApprStartDate]="prevApprStartDate" 
                    [prevApprEndDate]="prevApprEndDate" 
                    [approvedBy]="approvedUserName" 
                    [disabled]="false" 
                    (startDateChange)="startDateChanged($event)"  
                    (endDateChange)="endDateChanged($event)"   
                    (isErrorOnApproveData)="errorOnApproveData($event)" 
                    ></app-approve-data>
                </div>
                <div mat-dialog-actions>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin">
                        <button mat-dialog-close mat-button>{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
                    </div>
                    <div class="auto-margin">
                        <button (click)="submit()" [disabled]="isErrorOnApproval" mat-button>{{ 'COMMON.APPROVE_BTN' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
