import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { RainfallMonitor, RainfallMonitorBlockDays } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-rainfall-block-days',
    templateUrl: './rainfall-block-days.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainfallBlockDaysComponent implements OnInit, OnDestroy, OnChanges {
    @Input('rainfallMonitorList') public rainfallMonitorList: RainfallMonitor[];
    @Input('currentBlockDays') public currentBlockDays: RainfallMonitorBlockDays[];
    @Output('removeBlockDate') public removeBlockDate = new EventEmitter();

    public blockDaysTableDataSource: MatTableDataSource<RainfallMonitorBlockDays>;
    public blockDaysTableColumns = ['name', 'days', 'Action'];
    private deleteConfrmText: string;
    private cancelButtonText: string;
    private deleteButtonText: string;
    private deleteAllBlockdaysForRainfallMonitorText: string;

    private subscriptions: Subscription[] = [];

    public dateFormat;

    public isStudyLocked: boolean;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private matDialog: MatDialog,
        private translateService: TranslateService,
        private dateUtilService: DateutilService,
        private sliicerService: SliicerService,
    ) {}

    public ngOnInit() {
        this.subscriptions.push(
            this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
                this.dateFormat = this.dateUtilService.getStringFormat(newDateFormat);
            }),
        );

        const translateKeys: Array<string> = [
            'COMMON.DELETE_TITLE',
            'COMMON.CANCEL_BUTTON',
            'COMMON.DELETE_BUTTON',
            'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.CONFIRMATIONS.DELETE_ALL_BLOCKDYS_FOR_RAINFALL_MONITOR',
        ];

        this.translateService.get(translateKeys).subscribe((values) => {
            this.deleteConfrmText = values['COMMON.DELETE_TITLE'];
            this.cancelButtonText = values['COMMON.CANCEL_BUTTON'];
            this.deleteButtonText = values['COMMON.DELETE_BUTTON'];
            this.deleteAllBlockdaysForRainfallMonitorText =
                values[
                    'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.CONFIRMATIONS.DELETE_ALL_BLOCKDYS_FOR_RAINFALL_MONITOR'
                ];
        });
        // set Locked study notifiaction
        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
                this.cdr.markForCheck();
            }),
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.currentBlockDays) {
            this.showBlockDaysForRainfallMonitor();
        }

        // TODO: we might want to highlight the rows that are currently selected
        // if (changes.currentSelectedMonitorNames) {
        // }

        /*
        // Dragged block days come from the graph. We want to either add or remove days depending
        // on if they are already in our list. This _might_ lead to better UI experience?
        if (changes.draggedBlockDays) {
        }
         */
    }

    private showBlockDaysForRainfallMonitor() {
        const totalList: RainfallMonitorBlockDays[] = [];
        this.rainfallMonitorList.forEach((rainfallMonitor) => {
            let index = -1;
            if (this.currentBlockDays && this.currentBlockDays.length > 0) {
                index = this.currentBlockDays.findIndex((b) => b.name === rainfallMonitor.name);
            }
            const newItem = {
                name: rainfallMonitor.name,
                days: [],
            };
            if (index >= 0) {
                newItem.days = this.currentBlockDays[index].days.map((x) => x);
            }
            totalList.push(newItem);
        });
        this.blockDaysTableDataSource = new MatTableDataSource(totalList);
        // TODO: do we need to do this? See other calls below. I think not since we are using a material data source?!
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Click event from UI
     * @param date
     * @param rainfallMonitorName
     */
    public deleteClickedDate(date: string, rainfallMonitorName: string) {
        const index = this.currentBlockDays.findIndex((f) => f.name === rainfallMonitorName);
        if (index < 0) {
            return;
        }
        if (!this.currentBlockDays[index].days || this.currentBlockDays[index].days.length === 0) {
            return;
        }
        const dayIndex = this.currentBlockDays[index].days.findIndex((d) => d === date);
        if (dayIndex < 0) {
            return;
        }
        this.removeBlockDate.emit({ name: rainfallMonitorName, days: [date] });
    }

    private sendDeleteEvent(rainfallBlockDay: RainfallMonitorBlockDays) {
        this.removeBlockDate.emit({
            name: rainfallBlockDay.name,
            days: _.map(rainfallBlockDay.days, _.clone),
        });
    }

    /**
     * Click event from UI
     * @param rainfallMonitorName
     */
    public deleteAllRowDaysOfRainfallMonitor(rainfallMonitorName: string) {
        const index = this.currentBlockDays.findIndex((f) => f.name === rainfallMonitorName);
        if (index < 0) {
            return;
        }
        if (this.currentBlockDays[index].days && this.currentBlockDays[index].days.length > 1) {
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: <IComponentDialog>{
                        title: this.deleteConfrmText,
                        message: `${this.deleteAllBlockdaysForRainfallMonitorText} ` + rainfallMonitorName,
                        cancelText: this.cancelButtonText,
                        okText: this.deleteButtonText,
                        hidebackContent: false,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.sendDeleteEvent(this.currentBlockDays[index]);
                    }
                });
        } else {
            this.sendDeleteEvent(this.currentBlockDays[index]);
        }
    }
}
