import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    ChangeDetectorRef,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { VaultService } from '../vault.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { VaultFile } from 'app/shared/models/vault';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-vault-files-delete-action',
    templateUrl: './vault-files-delete-action.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaultFilesDeleteActionComponent implements OnInit {
    public selectedFiles = new Array<VaultFile>();
    public fileNameList = [];
    private indexedArray: any;
    public isLoading = true;

    public dismissText: string;
    public successMessage: string;
    public failedMessage: string;
    public translateKeys: Array<string> = ['COMMON.DISMISS_TEXT', 'COMMON.DELETE_SUCCESS', 'COMMON.DELETE_ERROR'];

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private dialogRef: MatDialogRef<VaultFilesDeleteActionComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private vaultService: VaultService,
        private chagneDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private snackBarNotificationService: SnackBarNotificationService,
        private translate: TranslateService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.successMessage = translateValues['COMMON.DELETE_SUCCESS'];
            this.failedMessage = translateValues['COMMON.DELETE_ERROR'];
        });
    }

    public ngOnInit() {
        this.selectedFiles = this.data.selectedFiles;
        if (this.selectedFiles.length > 0) {
            this.fileNameList = [];
            this.indexedArray = '{';
            this.selectedFiles.forEach((file, index) => {
                this.indexedArray += `"${file.uri}": ${false},`;
                this.fileNameList.push(file.fileName);
            });
            this.indexedArray = this.indexedArray.substr(0, this.indexedArray.lastIndexOf(',')) + '}';
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.chagneDetector);
        }
    }
    public close() {
        this.dialogRef.close({ success: false });
    }
    public delete() {
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
        this.vaultService.deleteFiles(this.indexedArray).subscribe(
            (result: boolean) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.chagneDetector);
                this.snackBarNotificationService.raiseNotification(this.successMessage, this.dismissText, {
                    duration: 10000,
                    panelClass: '',
                });
                this.loadVault();
                this.dialogRef.close({ success: true });
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.chagneDetector);
                this.snackBarNotificationService.raiseNotification(this.failedMessage, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                this.loadVault();
                this.dialogRef.close({ success: false });
            },
        );
    }

    private loadVault() {
        // get the latest vault data for current customer and stop preloader
        this.vaultService
            .getRootVaultListForCustomer()
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.chagneDetector);
                },
                (error) => {
                    // Error Block to handle errors
                },
            )
            .unsubscribe(); // immediately unsubscribe
    }
}
