import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subscription } from 'rxjs';
import { StormEvent, BasinStormSettings, Overrides, BasinStormResult } from 'app/shared/models/sliicer';
import { StormEventAdjustment, StormSettings } from 'app/shared/models/sliicer/overrides';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import {
    EditStormEventsComponent,
    EditStormEventsDialogOutput,
} from '../edit-storm-events/edit-storm-events.component';
import { EditStormEvents, ExpandedStormEvent, QvsIConfigurations, StormChooserModel } from 'app/shared/models/sliicer/results/storm-events';
import { ExcludeStormChanged } from '../../flow-monitor.model';
import { QvsIGroupDialogComponent } from '../q-vs-i-group-dialog/q-vs-i-group-dialog.component';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import config from 'config';
import { QVI_GROUP_ALL_STORMS, QVI_GROUP_ALL_STORMS_CONFIG } from 'app/shared/models/sliicer/basins';
import { UsersService } from 'app/pages/admin/users.service';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { DatePipe } from '@angular/common';
import { TrackBy } from 'app/shared/utils/track-by';


const STORM_INFO_ROW_HEIGHT = 60;

@Component({
    selector: 'ads-sliicer-storm-chooser',
    templateUrl: './storm-chooser.component.html',
    styleUrls: ['./storm-chooser.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SliicerStormChooserComponent implements OnInit, OnChanges, OnDestroy {

    private paginator: MatPaginator;

    @ViewChild('stormsTable') private stormsTable: ElementRef<HTMLDivElement>;
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    setDataSourceAttributes() {
        this.stormDataSource.paginator = this.paginator;
    }

    @Input() public stormEvents: StormEvent[];
    @Input() public basinStormSettings: BasinStormSettings[];
    @Input() public selectedBasin: string;
    @Input() public customerId: number;
    @Input() public caseStudyId: string;
    @Input() public stormSettings: StormSettings[] = [];
    @Input() public addedStormEvents: StormEventAdjustment[] = [];
    @Input() public removedStormEvents: StormEventAdjustment[] = [];
    @Input() public isLoading: boolean;
    @Input() public basinStormResult: BasinStormResult;
    @Input() public stormBasinResults: BasinStormResult[];
    @Input() public qvsIConfigurations: QvsIConfigurations[];
    @Input() public originalQvsIConfigurations: QvsIConfigurations[];
    @Input() public regimes: [];
    @Input() public selectedStormId: number;
    @Input() public excludedStormIds: { [key: string]: number[] } = {};
    @Input() public availableSeasons: [];
    @Input() public availableYears: number[];
    @Input() public hasYears: boolean;
    @Output() public excludedStormToggled = new EventEmitter<ExcludeStormChanged>();
    @Output() public stormSelected = new EventEmitter<number>();
    @Output() public stormEventsEdited = new EventEmitter<Overrides>();
    @Output() public qvsiConfChanged = new EventEmitter<QvsIConfigurations[]>();
    @Output() public expandChooser = new EventEmitter<boolean>();
    @Output() public onEditStorm = new EventEmitter<number>();
    @Output() public onStormDelete = new EventEmitter<StormChooserModel>();

    private subscriptions: Subscription[] = [];

    public isStudyLocked: boolean;
    public selectedStorm = {} as StormEvent;
    public caseStudy: SliicerCaseStudy;
    public stormDataSource = new MatTableDataSource([]);
    public customDateFormat: string;
    public stormColumns = ['color', 'group', 'stormStartTime', 'actions'];
    public QvsiGroup = QVI_GROUP_ALL_STORMS;
    selectedStormConfig: QvsIConfigurations;
    public pageSize = 10;
    public paginationMaxLength;
    public stormStatsDataSource = new MatTableDataSource<StormChooserModel>([]);

    public isOwnStudy = false;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private sliicerService: SliicerService,
        private dateutilService: DateutilService,
        private dialog: MatDialog,
        private userService: UsersService,
        private translate: TranslateService,
        private datePipe: DatePipe
    ) { }

    public ngOnInit() {
        // set Locked study notifiaction
        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
            }),
        );

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyDetails: SliicerCaseStudy) => {
                this.caseStudy = caseStudyDetails;
                this.isOwnStudy = caseStudyDetails.meta.authors.some(v => v.id === this.userService.userID.getValue());
            })
        );

        this.subscriptions.push(this.sliicerService.qvsiSelectedConfig.subscribe(qvsiConfig => {
            this.processSelectedConfig(qvsiConfig);
        }));

        this.customDateFormat = this.sliicerService.dateTimeFormatEditStorms();
    }

    private processSelectedConfig(qvsiConfig: {conf: QvsIConfigurations, afterupdate: boolean}, forceGroupSelect = false) {
        if (!qvsiConfig || qvsiConfig.afterupdate) return;

        if (qvsiConfig == null) qvsiConfig.conf = QVI_GROUP_ALL_STORMS_CONFIG;
        if (forceGroupSelect || (qvsiConfig && this.selectedStormConfig !== qvsiConfig.conf)) {
            this.QvsiGroup = qvsiConfig.conf.name;
            this.selectedStormConfig = qvsiConfig.conf;
            this.qvsiGroupSelect(qvsiConfig.conf.name, false);
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
        this.subscriptions = [];
    }

    public ngOnChanges(changes: SimpleChanges) {
        let needUpdate = false;
        this.autoScrollToSelectedStorm();
        if ((changes.stormEvents || changes.selectedStormId) && this.stormEvents) {
            needUpdate = true;
            if (this.stormEvents.length > 0) {
                this.selectedStorm = this.stormEvents.find(v => v.stormId === this.selectedStormId);
                if (!this.selectedStorm) {
                    this.selectedStorm = this.stormEvents[0];
                }

                if (this.selectedStorm) {
                    this.stormSelected.emit(this.selectedStormId);
                } else {
                    this.stormSelected.emit(null);
                }
            }
        }
        if (
            changes.addedStormEvents ||
            changes.removedStormEvents ||
            changes.selectedBasin ||
            changes.basinStormSettings ||
            changes.originalQvsIConfigurations  ||
            changes.stormBasinResults
        ) {
            needUpdate = true;
        }

        if (needUpdate) {
            const config = this.sliicerService.qvsiSelectedConfig.getValue();
            this.processSelectedConfig(config, true);
        }
    }


    /**
     * Called from UI when user toggles a storm to exclude from results
     * @param event the UI click event
     * @param stormId the storm that has been toggled
     */
    public toggleStormBasinExclusion(event, stormId: number): void {
        if (event.checked === false) {
            this.excludedStormIds[this.selectedBasin] = this.excludedStormIds[this.selectedBasin].filter(s => s !== stormId)
        }
        else {
            this.excludedStormIds[this.selectedBasin]?.push(stormId);
        }
        this.excludedStormToggled.emit({
            stormId: stormId,
            excluded: event.checked,
        });

        this.setSelectedStorm(stormId);
    }

    public isExcluded(stormId: number) {
        const result = this.excludedStormIds[this.selectedBasin]?.includes(stormId);
        return result;
    }

    /**
     * Called when the user selects a storm
     * @param stormId the selected row in the list of storms
     */
    public setSelectedStorm(stormId: number, isEdit = false) {
        this.selectedStorm = this.stormEvents.find((s) => s.stormId === stormId);

        if (isEdit) {
            this.onEditStorm.emit(stormId);
        } else {
            this.stormSelected.emit(stormId);
        }

    }

    public deleteStorm(storm: StormChooserModel) {
        const formattedDate = this.datePipe.transform(storm.stormStartTime, this.customDateFormat);

        const title = this.translate.instant('SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.DELETE_STORM_DIALOG_TITLE');
        const messageText = this.translate.instant('SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.DELETE_DIALOG_MESSAGE');
        const message = `${messageText} ${formattedDate} ?`;
        const okText = this.translate.instant('COMMON.DELETE_BUTTON');
        const cancelText = this.translate.instant('COMMON.CANCEL_BUTTON');

        this.dialog
        .open<ConfirmationDialogComponent, IComponentDialog>(ConfirmationDialogComponent, {
            data: {
                title,
                message,
                cancelText,
                okText,
                isStormDelete: true
            },
        }).afterClosed().subscribe((res) => {
            if (!res || res.whichButtonWasPressed !== 'ok') {
                return;
            }

            this.onStormDelete.emit(storm);
        });
    }

    public editStormEvents(): void {
        if (!this.isStudyLocked) {
            this.dialog
                .open<EditStormEventsComponent, EditStormEvents, EditStormEventsDialogOutput>(
                    EditStormEventsComponent,
                    {
                        disableClose: true,
                        data: {
                            stormEvents: this.stormEvents,
                            basinStormResult: this.basinStormResult,
                            stormSettings: this.stormSettings,
                            addedStormEvents: this.addedStormEvents,
                            removedStormEvents: this.removedStormEvents,
                            basinStormSettings: this.basinStormSettings,
                            selectedBasin: this.selectedBasin,
                        },
                    },
                )
                .afterClosed()
                .subscribe((data) => {
                    if (data.updated) {
                        this.stormEventsEdited.emit(data.overrides);
                    }
                });
        }
    }

    public expandChooserFull() {
        this.expandChooser.emit(true);
    }

    private bindStorms(
        stormEvents: ExpandedStormEvent[],
        selectedBasin: string,
        stormSettings: StormSettings[],
        basinStormSettings: BasinStormSettings[],
    ): MatTableDataSource<StormChooserModel> {
        let currentExcludedBasinStorms = [];
        if (basinStormSettings) {
            currentExcludedBasinStorms = basinStormSettings
                .filter((s) => s.basinName === selectedBasin && s.exclude === true)
                .map((s) => s.stormId);
        }
        const stormData = stormEvents.map((s) => {
            const basinSetting = basinStormSettings.find(
                (x) => x.stormId === s.stormId && x.basinName === selectedBasin,
            );
            const stormSetting = stormSettings.find((x) => x.stormId === s.stormId);
            const stormExists = this.stormBasinResults ? this.stormBasinResults.find((x) => x.stormId === s.stormId) : null;

            let isManuallyAdded = false;

            if (s.manuallyAdded || this.caseStudy?.overrides?.addedStormEvents?.find((x) => x.stormStartTime === s.stormStartTime)) {
                isManuallyAdded = true;
            }
            
            const labelExtra = basinSetting
                ? this.sliicerService.stormLabelExtras(s.stormStartTime, {
                    season: basinSetting && basinSetting.season ? basinSetting.season : undefined,
                    regime: basinSetting && basinSetting.regime ? basinSetting.regime : undefined,
                })
                : s.labelExtra;

            const stormConfigGroup = this.processQvsiFilter(s)

            return {
                isExcluded: currentExcludedBasinStorms.indexOf(s.stormId) > -1,
                stormId: s.stormId,
                stormStartTime:
                    stormSetting && stormSetting.stormStartTime ? stormSetting.stormStartTime : s.stormStartTime,
                statusTag: s.statusTag,
                saved: s.saved,
                qvsiGroup: stormConfigGroup.group,
                color: stormConfigGroup.color,
                labelExtra: labelExtra,
                stormExists: stormExists || isManuallyAdded ? true : false,
            };
        });

        stormData.sort((a,b) => new Date(a.stormStartTime).getTime() - new Date(b.stormStartTime).getTime());
        return new MatTableDataSource<StormChooserModel>(stormData);
    }
    public setCustomFilter() {
        this.dialog
            .open<QvsIGroupDialogComponent>(
                QvsIGroupDialogComponent,
                {
                    data: {
                        qvsIConfigurations: this.qvsIConfigurations,
                        originalQvsIConfigurations: this.originalQvsIConfigurations,
                        regimes: this.regimes,
                        availableSeasons: this.availableSeasons,
                        availableYears: this.availableYears,
                        hasYears: this.hasYears
                    },
                },
            )
            .afterClosed()
            .subscribe((res) => {
                if (res && res.lastEditedName) {
                    this.sliicerService.qvsiSelectedConfig.next({ conf: { name: res.lastEditedName, description: '', groups: [] }, afterupdate: true });
                }
                if (res && res.qvi) {
                    this.qvsiConfChanged.emit(res.qvi);
                }

            });
    }

    public adjustStormEvents() {
        const adjustedStormEventsAll = this.sliicerService.getAdjustedStormEvents(
            this.stormEvents,
            this.addedStormEvents,
            this.removedStormEvents,
        );

        this.stormDataSource = this.bindStorms(
            adjustedStormEventsAll,
            this.selectedBasin,
            this.stormSettings,
            this.basinStormSettings,
        );

        // remove duplicate storms, filter by start time
        const startTimeSet: Set<string> = new Set();

        this.stormDataSource.data = this.stormDataSource.data.filter((s, i) => {
            if (startTimeSet.has(s.stormStartTime)) {
                return false;
            }

            startTimeSet.add(s.stormStartTime);
            return true;
        });

        // since there is no explicit way to change pseudo class (:after/:before) properties
        // we change the value of css global variable that is linked to pseudo class
        if (this.stormDataSource && this.stormDataSource.data && this.stormDataSource.data.length) {
            const color = this.stormDataSource.data[0].color;
            document.documentElement.style.setProperty("--cross-color", color);
        }
        if (this.stormDataSource && this.paginator && this.stormsTable) {
            this.autoScrollToSelectedStorm();
        }
    }

    public autoScrollToSelectedStorm()
    {
        // auto scroll to selected storm
        const selectedStormIndex = this.stormDataSource.filteredData.findIndex(v => v.stormId === this.selectedStormId);
        this.paginator.pageIndex = Math.floor(selectedStormIndex / this.paginator.pageSize);

        const selectedStormRowIndex = selectedStormIndex - (this.paginator.pageIndex * this.paginator.pageSize);
        // for first 3 items we do not need to scroll
        const scrollSteps = selectedStormRowIndex < 3 ? 0 : selectedStormRowIndex - 2;

        this.stormsTable.nativeElement.scroll(0, scrollSteps * STORM_INFO_ROW_HEIGHT);
    }

    public qvsiGroupSelect(event, trigger) {
        this.selectedStormConfig = this.originalQvsIConfigurations.find(item => item.name === event);
        if (!this.selectedStormConfig) {
            this.QvsiGroup = QVI_GROUP_ALL_STORMS;
            this.selectedStormConfig = this.originalQvsIConfigurations.find(item => item.name === QVI_GROUP_ALL_STORMS);
        }

        this.adjustStormEvents();

        if (trigger) {
            this.sliicerService.qvsiSelectedConfig.next({ conf: this.selectedStormConfig, afterupdate: false });
        }
    }

    private processQvsiFilter(storm) {
        const configs = {
            color: '#0000FF',
            group: QVI_GROUP_ALL_STORMS
        }

        if (this.selectedStormConfig) {
            configs['color'] = '';
            configs['group'] = '';
            if (storm.configurationGroups && storm.configurationGroups[this.selectedStormConfig.name]) {
                const configGroup = this.selectedStormConfig.groups.find(x => x.name === storm.configurationGroups[this.selectedStormConfig.name])
                if (configGroup) {
                    configs['color'] = configGroup.color;
                    configs['group'] = configGroup.name;
                }
            }
        }

        return configs
    }
}

