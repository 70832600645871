<div id="assistanceCenterDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ selectedTabTitel | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div id="assistanceCenterContainer">
        <div class="customerSupportContainer" *ngIf="selectedTab === '0'">
            <form #emailForm="ngForm" novalidate>
                <div mat-dialog-content>
                    <app-loader [isLoading]="isRequestDone"></app-loader>
                    <mat-card-title class="text-align-center">
                        {{ 'CUSTOMER_SUPPORT.DIALOG_TITLE_1' | translate }}
                        <br />
                        {{ 'CUSTOMER_SUPPORT.DIALOG_TITLE_2' | translate }}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{ 'CUSTOMER_SUPPORT.DIALOG_SUBTITLE' | translate }}
                    </mat-card-subtitle>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'COMMON.PROJECT_NAME_TEXT' | translate }}</mat-label>
                            <mat-select
                                placeholder="{{ 'COMMON.PROJECT_NAME_TEXT' | translate }}"
                                [(ngModel)]="emailContent.customerName"
                                (ngModelChange)="customerSelectedHandler($event)"
                                name="customer"
                            >
                                <mat-option *ngFor="let customer of filteredassignedCustomers | async; trackBy: trackByCustomerId" [value]="customer.customer.customerName">
                                    {{ customer.customer.customerName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="custom-error-message">
                            <span *ngIf="invalidCustomerName">{{ 'CUSTOMER_SUPPORT.INVALID_NAME' | translate }}</span>
                        </div>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <input
                                name="userName"
                                id="userName"
                                #phoneNumber="ngModel"
                                [disabled]="true"
                                [(ngModel)]="emailContent.userName"
                                matInput
                                placeholder="{{ 'COMMON.USER_NAME_TEXT' | translate }}"
                                tabindex="-1"
                            />
                        </mat-form-field>
                    </div>
                    <div class="custom-error">
                        <div class="full-width custom-phone-input"
                            [ngClass]="{ error: emailForm.form.controls['phoneNumber'] && emailForm.form.controls['phoneNumber'].touched && emailForm.form.controls['phoneNumber'].invalid }">
                            <div class="inputfield" matTooltip="{{ 'USER_PREFERENCES.PHONE_NUMBER_TOOLTIP' | translate }}">
                                <ngx-intl-tel-input
                                    appTelInputDropdownPosition
                                    [cssClass]="'phone-input'"
                                    [preferredCountries]="[CountryISO.UnitedStates]"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectedCountryISO]="CountryISO.UnitedStates"
                                    [phoneValidation]="true"
                                    name="phoneNumber"
                                    #userPrefPhoneNumberInput="ngModel"
                                    [(ngModel)]="emailContent.phoneNumber">
                                </ngx-intl-tel-input>
                            </div>
                        </div>
                        <div class="custom-error-message phone-input-error"
                            *ngIf="userPrefPhoneNumberInput && userPrefPhoneNumberInput.touched && userPrefPhoneNumberInput.invalid">
                            {{ 'USER_PREFERENCES.PHONE_NUMBER_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <input
                                name="emailPrimary"
                                id="emailPrimary"
                                #emailPrimary="ngModel"
                                [(ngModel)]="emailContent.emailPrimary"
                                matInput
                                placeholder="{{ 'USER_PREFERENCES.PRIMARY_EMAIL' | translate }}"
                                [pattern]="emailPattern"
                                required
                                tabindex="-1"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="emailPrimary?.errors?.pattern">
                            {{ 'COMMON.EMAIL_ID_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <input
                                name="emailSecondary"
                                id="emailSecondary"
                                #emailSecondary="ngModel"
                                [(ngModel)]="emailContent.emailSecondary"
                                matInput
                                placeholder="{{ 'USER_PREFERENCES.SECONDARY_EMAIL' | translate }}"
                                [pattern]="emailPattern"
                                tabindex="-1"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="emailSecondary?.errors?.pattern">
                            {{ 'COMMON.EMAIL_ID_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <textarea
                                name="reasonForCall"
                                id="reasonForCall"
                                matTextareaAutosize
                                matAutosizeMinRows="1"
                                matAutosizeMaxRows="5"
                                #reasonForCall="ngModel"
                                [(ngModel)]="emailContent.reasonForCall"
                                matInput
                                placeholder="{{ 'CUSTOMER_SUPPORT.REASON_FOR_CALL' | translate }}"
                                [pattern]="isEmptyPattern"
                                required
                                tabindex="-1"
                            ></textarea>
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="reasonForCall?.errors?.pattern">
                            {{ 'CUSTOMER_SUPPORT.NAME_PATTERN_VALIDATION' | translate }}
                        </div>
                    </div>
                </div>
            </form>
            <div mat-dialog-actions>
                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="emitAssistanceCenter()">{{ 'COMMON.CLOSE' | translate }}</button>
                </div>
                <div class="auto-margin">
                    <button mat-button (click)="sendCallRequest()" [disabled]="!emailForm.valid || !validCustomerName">
                        {{ 'CUSTOMER_SUPPORT.REQUEST_CALL_BTN' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="reportDefectContainer" *ngIf="selectedTab === '1'">
            <form #emailReportDefectForm="ngForm" novalidate>
                <div mat-dialog-content>
                    <mat-card-title>
                        {{ 'CUSTOMER_SUPPORT.FORM_TITLE' | translate }}
                    </mat-card-title>
                    <div class="custom-error">
                        <mat-form-field class="full-width top">
                            <input
                                name="fullName"
                                id="fullName"
                                #fullName="ngModel"
                                [pattern]="alphaNumericPattern"
                                [(ngModel)]="emailContents.fullName"
                                matInput
                                maxlength="65"
                                placeholder="{{ 'CUSTOMER_SUPPORT.FULL_NAME' | translate }}"
                                required
                                (blur)="capitalizedStringWithSpace()"
                                tabindex="-1"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="fullName?.errors?.pattern">
                            {{ 'CUSTOMER_SUPPORT.NAME_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width top">
                            <input
                                #emailAddress="ngModel"
                                name="emailaddress"
                                id="emailaddress"
                                [pattern]="emailPattern"
                                [(ngModel)]="emailContents.emailAddress"
                                matInput
                                placeholder="{{ 'CUSTOMER_SUPPORT.EMAIL_ADDRESS' | translate }}"
                                required
                                tabindex="-1"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="emailAddress?.errors?.pattern">
                            {{ 'CUSTOMER_SUPPORT.EMAIL_ADDRESS_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <input
                                name="contactnumber"
                                type="number"
                                id="contactnumber"
                                #contactNumber="ngModel"
                                [(ngModel)]="emailContents.contactNumber"
                                matInput
                                placeholder="{{ 'CUSTOMER_SUPPORT.CONTACT_NUMBER' | translate }}"
                                [pattern]="numericOnlyPattern"
                                required
                                maxlength="15"
                                tabindex="-1"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="contactNumber?.errors?.pattern">
                            {{ 'CUSTOMER_SUPPORT.CONTACT_NUMBER_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <textarea
                                placeholder="{{ 'CUSTOMER_SUPPORT.STEPS_TO_REPRODUCE' | translate }}"
                                name="stepsToReproduce"
                                id="stepsToReproduce"
                                matTextareaAutosize
                                matAutosizeMinRows="1"
                                matAutosizeMaxRows="5"
                                #stepsToReproduce="ngModel"
                                (blur)="stepsToReproduceValidation()"
                                [(ngModel)]="emailContents.stepsToReproduce"
                                [matTooltipClass]="'steptoproduce-tooltip-wordwrap'"
                                matInput
                                maxlength="512"
                                required
                                [pattern]="isEmptyPattern"
                                tabindex="-1"
                                matTooltip="{{ 'CUSTOMER_SUPPORT.REPRODUCE_TOOLTIP' | translate }}"
                            ></textarea>
                            <mat-hint align="end">
                                {{ 512 - stepsToReproduce?.value?.length }}
                                {{ 'CUSTOMER_SUPPORT.CHARACTERS_LEFT' | translate }}</mat-hint
                            >
                        </mat-form-field>

                        <div class="custom-error-message" *ngIf="stepsToReproduce?.errors?.pattern">
                            {{ 'CUSTOMER_SUPPORT.NAME_PATTERN_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="fileUplode custom-error">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                readonly
                                placeholder="{{ 'CUSTOMER_SUPPORT.UPLOAD_FILE' | translate }}"
                                #showFileNames
                            />
                        </mat-form-field>
                        <label for="file-input">
                            <mat-icon>camera_alt</mat-icon>
                        </label>
                        <input
                            hidden
                            type="file"
                            class="file-input"
                            [(ngModel)]="reportImage"
                            name="file-input"
                            id="file-input"
                            (change)="loadImage($event, showFileNames)"
                        />
                        <div class="custom-error-message">
                            <span *ngIf="!validExtensions"> {{ 'CUSTOMER_SUPPORT.FILE_FORMAT' | translate }}</span>
                            <span *ngIf="!exceedFileSize">{{ 'CUSTOMER_SUPPORT.FILE_SIZE' | translate }}</span>
                        </div>
                    </div>
                </div>
            </form>

            <div mat-dialog-actions>
                <app-loader [isLoading]="reportDefectLoadingState"></app-loader>
                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="emitAssistanceCenter()">{{ 'COMMON.CLOSE' | translate }}</button>
                </div>
                <div class="auto-margin">
                    <button
                        mat-button
                        (click)="sendDefectReport()"
                        [disabled]="!emailReportDefectForm.valid || isEmpty"
                    >
                        {{ 'COMMON.SEND_BTN' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="betaSurveyContainer" *ngIf="selectedTab === '2'">
            <form #appSurveyForm="ngForm" novalidate>
                <div mat-dialog-content>
                    <div class="custom-error">
                        <mat-form-field class="full-width top">
                            <input
                                name="applicationSurvey.departmentalRole"
                                id="departmentalRole"
                                #departmentalRole="ngModel"
                                [(ngModel)]="applicationSurvey.departmentalRole"
                                required
                                matInput
                                [pattern]="isEmptyPattern"
                                maxlength="128"
                                tabindex="-1"
                                placeholder="{{ 'CUSTOMER_SUPPORT.ROLE_PLACEHOLDER' | translate }}"
                            />
                        </mat-form-field>
                        <div class="custom-error-message">
                            <span *ngIf="departmentalRole?.errors?.pattern">{{
                                'CUSTOMER_SUPPORT.NAME_PATTERN_VALIDATION' | translate
                            }}</span>
                        </div>
                    </div>
                    <label class="headerLabel">{{ 'CUSTOMER_SUPPORT.SURVEY_TITLE' | translate }}</label>
                    <div id="operationsSatisficationGroup">
                        <mat-radio-group
                            [(ngModel)]="applicationSurvey.howUseFulApp"
                            name="applicationSurvey.howUseFulApp"
                            required
                        >
                            <mat-radio-button value="Highly Useful" (change)="checkHowUseFulAppRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.HIGHLY_USEFUL_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_very_satisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Useful" (change)="checkHowUseFulAppRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.USEFUL_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_satisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Somewhat Useful" (change)="checkHowUseFulAppRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.SOMEWHAT_USEFUL_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_neutral</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Less Useful" (change)="checkHowUseFulAppRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.LESS_USEFUL_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_very_dissatisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Not Useful" (change)="checkHowUseFulAppRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.NOT_USEFUL_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >mood_bad</mat-icon
                                >
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="mat-error" *ngIf="howUseFulAppRating == true">
                        {{ 'CUSTOMER_SUPPORT.SELECT_RATING_OPTION' | translate }}
                    </div>

                    <label class="headerLabel margin-t-15">{{
                        'CUSTOMER_SUPPORT.ASK_CHANGE_IN_FEATURE' | translate
                    }}</label>
                    <mat-radio-group
                        [(ngModel)]="applicationSurvey.featureMakeAppEasy"
                        name="applicationSurvey.featureMakeAppEasy"
                    >
                        <mat-radio-button value="Yes">{{ 'COMMON.YES_TEXT' | translate }}</mat-radio-button>
                        <mat-radio-button value="No">{{ 'COMMON.NO_TEXT' | translate }}</mat-radio-button>
                    </mat-radio-group>
                    <mat-form-field class="full-width margin-t-15">
                        <textarea
                            [(ngModel)]="applicationSurvey.featureDescMakeAppEasy"
                            name="applicationSurvey.featureDescMakeAppEasy"
                            matInput
                            placeholder="{{ 'CUSTOMER_SUPPORT.PLEASE_ELOBARATE' | translate }}"
                            [disabled]="applicationSurvey.featureMakeAppEasy === 'No'"
                            matTextareaAutosize
                            maxlength="128"
                            matAutosizeMinRows="1"
                            matAutosizeMaxRows="5"
                        ></textarea>
                    </mat-form-field>
                    <label class="headerLabel">
                        {{ 'CUSTOMER_SUPPORT.OVERALL_SATISFACTION_QUESTION' | translate }}
                    </label>
                    <div id="satisficationGroup">
                        <mat-radio-group
                            [(ngModel)]="applicationSurvey.satisficationLevel"
                            name="applicationSurvey.satisficationLevel"
                            required
                        >
                            <mat-radio-button value="Very Satisfied" (change)="checkFeatureMakeAppEasyRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.VERY_SATISFIED_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_very_satisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Satisfied" (change)="checkFeatureMakeAppEasyRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.SATISFIED_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_satisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Neutral" (change)="checkFeatureMakeAppEasyRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.NEUTRAL_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_neutral</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Dissatisfied" (change)="checkFeatureMakeAppEasyRating($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.DISSATISFIED_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_very_dissatisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button
                                value="Very Dissatisfied"
                                (change)="checkFeatureMakeAppEasyRating($event)"
                            >
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.VERY_DISSATISFIED_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >mood_bad</mat-icon
                                >
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="mat-error" *ngIf="featureMakeAppEasyRating == true">
                        {{ 'CUSTOMER_SUPPORT.SELECT_SATISFACTION_RATING_LINE' | translate }}
                    </div>

                    <mat-form-field class="full-width margin-t-15">
                        <textarea
                            [(ngModel)]="applicationSurvey.satisficationLevelWhy"
                            name="applicationSurvey.satisficationLevelWhy"
                            matInput
                            placeholder="{{ 'CUSTOMER_SUPPORT.SURVEY_PLACEHOLDER' | translate }}"
                            maxlength="128"
                            matTextareaAutosize
                            matAutosizeMinRows="1"
                            matAutosizeMaxRows="5"
                        ></textarea>
                    </mat-form-field>
                    <label class="headerLabel"> Navigation is consistent and easy to use. </label>
                    <div id="navigationSatisficationGroup">
                        <mat-radio-group
                            [(ngModel)]="applicationSurvey.navigationSatisfication"
                            name="applicationSurvey.navigationSatisfication"
                            required
                        >
                            <mat-radio-button value="Highly Agree" (change)="checkNavigationSatisfication($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.HIGHLY_AGREE_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_very_satisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Agree" (change)="checkNavigationSatisfication($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.AGREE_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_satisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Neutral" (change)="checkNavigationSatisfication($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.NEUTRAL_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_neutral</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Disagree" (change)="checkNavigationSatisfication($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.DISAGREE_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >sentiment_very_dissatisfied</mat-icon
                                >
                            </mat-radio-button>
                            <mat-radio-button value="Highly Disagree" (change)="checkNavigationSatisfication($event)">
                                <mat-icon
                                    matTooltip="{{ 'CUSTOMER_SUPPORT.HIGHLY_DISAGREE_TOOLTIP' | translate }}"
                                    matTooltipPosition="right"
                                    >mood_bad</mat-icon
                                >
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="mat-error" *ngIf="navigationSatisficationRating == true">
                        {{ 'CUSTOMER_SUPPORT.SELECT_RATING_OPTING_ERR' | translate }}
                    </div>

                    <mat-form-field class="full-width margin-t-15">
                        <textarea
                            [(ngModel)]="applicationSurvey.navigationSatisficationWhy"
                            name="applicationSurvey.navigationSatisficationWhy"
                            matInput
                            placeholder="{{ 'CUSTOMER_SUPPORT.SURVEY_PLACEHOLDER' | translate }}"
                            maxlength="128"
                            matTextareaAutosize
                            matAutosizeMinRows="1"
                            matAutosizeMaxRows="5"
                        ></textarea>
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <textarea
                            [(ngModel)]="applicationSurvey.finalComments"
                            name="applicationSurvey.finalComments"
                            matInput
                            placeholder="{{ 'CUSTOMER_SUPPORT.FINAL_COMMENTS' | translate }}"
                            matTextareaAutosize
                            maxlength="128"
                            matAutosizeMinRows="1"
                            matAutosizeMaxRows="5"
                        ></textarea>
                    </mat-form-field>
                </div>
            </form>
            <div mat-dialog-actions>
                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="emitAssistanceCenter()">{{ 'COMMON.CLOSE' | translate }}</button>
                </div>
                <app-loader [isLoading]="appSurveyReportState"></app-loader>
                <div class="auto-margin">
                    <button
                        mat-button
                        (click)="sendAppSurveyReport()"
                        [disabled]="!appSurveyForm.valid || isEmpty || surveyRating"
                    >
                        {{ 'COMMON.SUBMIT_BTN' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div id="contactUsContainerId" class="contactUsContainer" *ngIf="selectedTab === '4'">
            <div mat-dialog-content>
                <div class="contactDetails top">
                    <div class="eachContactDetails">
                        <div class="icon">
                            <button mat-fab color="accent" class="mat-fabIcon-40">
                                <mat-icon>location_on</mat-icon>
                            </button>
                        </div>
                        <div class="details">
                            <h3>{{ 'COMMON.VISIT_US' | translate }}</h3>
                            <a href="https://goo.gl/maps/9vaEzRc9YH92" target="_blank" rel="noopener"
                                >{{ 'COMMON.ADDRESS_LINE' | translate }}
                            </a>
                        </div>
                    </div>
                    <div class="eachContactDetails">
                        <div class="icon">
                            <button mat-fab class="mat-fabIcon-40 b-blue">
                                <mat-icon>call</mat-icon>
                            </button>
                        </div>
                        <div class="details">
                            <h3>{{ 'COMMON.CALL_US' | translate }}</h3>
                            {{ 'COMMON.CALL_NUMBERS' | translate }}
                        </div>
                    </div>
                    <div class="eachContactDetails">
                        <div class="icon">
                            <button mat-fab class="mat-fabIcon-40 b-orange">
                                <mat-icon>print</mat-icon>
                            </button>
                        </div>
                        <div class="details">
                            <h3>{{ 'COMMON.FAX' | translate }}</h3>
                            {{ 'COMMON.FAX_NUMBER' | translate }}
                        </div>
                    </div>
                    <div class="eachContactDetails">
                        <div class="icon">
                            <button mat-fab class="mat-fabIcon-40 b-pink">
                                <mat-icon>email</mat-icon>
                            </button>
                        </div>
                        <div class="details">
                            <h3>{{ 'COMMON.WRITE_US' | translate }}</h3>
                            <a href="mailto:ADSSupportCenter@IDEXCorp.com">{{ 'COMMON.EMAIL_ADDRESS' | translate }} </a>
                        </div>
                    </div>
                </div>
                <div class="contactMap">
                    <a class="contactMapImg" href="https://goo.gl/maps/9vaEzRc9YH92" target="_blank" rel="noopener"></a>
                </div>
            </div>
            <div mat-dialog-actions>
                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="emitAssistanceCenter()">{{ 'COMMON.CLOSE' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
