<div #dialog id="locationEditorTritonDialog">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
        (listenConfirmation)="handleConfirmationDialogResult($event)"
    ></app-customized-confirmation-box>
    <div
        class="popupHeader"
        mat-dialog-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragBoundary=".cdk-overlay-container"
        (cdkDragEnded)="dragEvent()"
    >
        <span *ngIf="data.editMode && locationForm">{{ locationForm.get('basicInfo').get('locationName').value }}</span>
        <span *ngIf="!data.editMode">{{ 'COMMON.ADD_LOCATION_TEXT' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>

    <form *ngIf="locationForm" [formGroup]="locationForm">
        <div mat-dialog-content>
            <mat-accordion>
                <app-triton-basic-info
                    *ngIf="locationForm.get('basicInfo')"
                    [form]="locationForm.get('basicInfo')"
                    (manholeDepthChange)="onManholeDepthChange()"
                    [timezone]="timezone"
                    (monitorSeriesChange)="onMonitorSeriesChange($event)"
                    (installationSeriesChange)="onInstallationSeriesChange($event)"
                    (statusChange)="onMp1StatusChange($event)"
                    (pipeHeightChange)="onPipeHeightChange($event)"
                    [data]="data"
                    [details]="locationDetails[0]"
                    [monitorConfigs]="monitorConfigs"
                    [defaultRoughness]="defaultRoughness"
                    [customProperties]="customProperties"
                ></app-triton-basic-info>

                <app-triton-info
                    *ngIf="locationForm.get('tritonInfo')"
                    [form]="locationForm.get('tritonInfo')"
                    [monitorConfigs]="monitorConfigs"
                    (sensorRemove)="onSensorRemove($event)"
                    (changeSensor)="onTritonSensorChange($event)"
                    [details]="locationDetails"
                    [data]="data"
                    (comportChange)="onComportChange($event)"
                    (monitorPointChange)="onMonitorPointChange($event)"
                ></app-triton-info>

                <app-foreSITEXD-info
                    *ngIf="locationForm.get('foreSITEXDInfo')"
                    [form]="locationForm.get('foreSITEXDInfo')"
                    (foreSITEXDRemove)="onForeSITESensorRemove($event)"
                    (changeSensor)="onForeSITESensorChange($event)"
                    [monitorConfigs]="monitorConfigs"
                    [details]="locationDetails"
                    [data]="data"
                ></app-foreSITEXD-info>

                <app-echo-info
                    *ngIf="locationForm.get('echoInfo')"
                    [form]="locationForm.get('echoInfo')"
                    [data]="data"
                    [monitorConfigs]="monitorConfigs"
                ></app-echo-info>

                <app-rainalert-info
                    *ngIf="locationForm.get('rainalertInfo')"
                    [form]="locationForm.get('rainalertInfo')"
                    [monitorConfigs]="monitorConfigs"
                    [sampleRate]="locationForm.get('basicInfo').get('samplerate').value"
                ></app-rainalert-info>

                <app-other-monitors-info
                    *ngIf="isOtherMonitorSeriesSelected() && locationForm.get('basicInfo').get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && locationForm.get('basicInfo').get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD"
                    [monitorSeries]="getMonitorSeries()"
                ></app-other-monitors-info>

                <ng-container *ngIf="locationForm.get('tritonSensors') as tritonSensors">
                    <!-- First Sensor -->
                    <div *ngIf="tritonSensors.controls.length > 0">
                        <ng-container *ngFor="let sensor of tritonSensors.controls; let i = index; trackBy: trackByIndex">
                            <ng-container *ngIf="i === 0">
                                <app-add-edit-location-peak-combo-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && (sensor.get('name').value === sensorNames.peakCombo || sensor.get('name').value === sensorNames.avMax)"
                                    [form]="sensor"
                                ></app-add-edit-location-peak-combo-sensor>
                                <app-add-edit-location-smart-depth-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && (sensor.get('name').value === sensorNames.smartDepth || sensor.get('name').value === sensorNames.paraDepth)"
                                    [form]="sensor"
                                ></app-add-edit-location-smart-depth-sensor>
                                <app-add-edit-location-avGated-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    *ngIf="sensor && sensor.controls && sensor.get('name').value === sensorNames.avGated"
                                    [form]="sensor"
                                    [monitorConfigs]="monitorConfigs"
                                ></app-add-edit-location-avGated-sensor>
                                <app-add-edit-location-surface-combo-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && (sensor.get('name').value === sensorNames.surfaceCombo || sensor.get('name').value === sensorNames.paraFlow)"
                                    [form]="sensor"
                                ></app-add-edit-location-surface-combo-sensor>
                                <app-add-edit-location-long-range-depth-triton-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && sensor.get('name').value === sensorNames.longRange"
                                    [form]="sensor"
                                ></app-add-edit-location-long-range-depth-triton-sensor>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="locationForm.get('ForeSITEXDSensors') as ForeSITEXDSensors">
                    <div *ngFor="let sensor of ForeSITEXDSensors.controls; let i = index; trackBy: trackByIndex">
                        <add-add-foreSITEXD-radar-sensor
                            [index]="i"
                            [monitorConfigs]="monitorConfigs"
                            [series]="series"
                            *ngIf="sensor && sensor.controls && sensor.get('name').value === foreSITESensorNames.radar"
                            [form]="sensor"
                        ></add-add-foreSITEXD-radar-sensor>
                    </div>
                </ng-container>

                <app-rain-sensor
                    *ngIf="locationForm.get('rainSensor')"
                    [form]="locationForm.get('rainSensor')"
                    [monitorConfigs]="monitorConfigs"
                ></app-rain-sensor>
                <app-add-edit-location-long-range-depth-sensor
                    *ngIf="locationForm.get('echoLongRange')"
                    [form]="locationForm.get('echoLongRange')"
                    [monitorConfigs]="monitorConfigs"
                ></app-add-edit-location-long-range-depth-sensor>

                <app-triton-configure-alarms
                    *ngIf="(locationForm.get('alarmsInfo') && !isOtherMonitorSeriesSelected()) || locationForm.get('basicInfo').get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || locationForm.get('basicInfo').get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD"
                    [monitorConfigs]="monitorConfigs"
                    [form]="locationForm.get('alarmsInfo')"
                    [isMp2]="false"
                    [installationShapeType]="locationForm.get('basicInfo').get('installationType').value"
                    [monitorSeriesChange]="monitorSeriesChange$"
                    [installationSeriesChange]="installationSeriesChange$"
                    [alarmConfigs]="alarmConfigs"
                    [monitorSeries]="locationForm.get('basicInfo').get('series').value"
                ></app-triton-configure-alarms>

                <app-triton-data-delivery
                    *ngIf="locationForm.get('dataDelivery') && !isOtherMonitorSeriesSelected()"
                    [form]="locationForm.get('dataDelivery')"
                    [monitorConfigs]="monitorConfigs"
                    [monitorSeriesChange]="monitorSeriesChange$"
                ></app-triton-data-delivery>

                <app-flow-form
                    *ngIf="locationForm.get('flowInfo') && !isOtherMonitorSeriesSelected()"
                    [form]="locationForm.get('flowInfo')"
                    [monitorConfigs]="monitorConfigs"
                    [installationSeriesChange]="installationSeriesChange$"
                    [isMp2]="false"
                ></app-flow-form>

                <app-mp2-basic-info
                    *ngIf="locationForm.get('mp2BasicInfo')"
                    [data]="data"
                    [installationTypes]="basicInfo.installationTypes"
                    [timezone]="timezone"
                    [form]="locationForm.get('mp2BasicInfo')"
                    [details]="locationDetails[1]"
                    (pipeHeightChange)="onPipeHeightChange($event)"
                    (installationSeriesChange)="onMp2InstallationSeriesChange($event)"
                    [defaultRoughness]="defaultRoughness"
                    [customProperties]="customProperties"
                ></app-mp2-basic-info>

                <ng-container *ngIf="locationForm.get('tritonSensors') as tritonSensors">
                    <!-- Second Sensor -->
                    <div *ngIf="tritonSensors.controls.length > 1">
                        <ng-container *ngFor="let sensor of tritonSensors.controls; let i = index; trackBy: trackByIndex">
                            <ng-container *ngIf="i === 1">
                                <app-add-edit-location-peak-combo-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && (sensor.get('name').value === sensorNames.peakCombo || sensor.get('name').value === sensorNames.avMax)"
                                    [form]="sensor"
                                ></app-add-edit-location-peak-combo-sensor>
                                <app-add-edit-location-smart-depth-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && (sensor.get('name').value === sensorNames.smartDepth || sensor.get('name').value === sensorNames.paraDepth)"
                                    [form]="sensor"
                                ></app-add-edit-location-smart-depth-sensor>
                                <app-add-edit-location-avGated-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    *ngIf="sensor && sensor.controls && sensor.get('name').value === sensorNames.avGated"
                                    [form]="sensor"
                                    [monitorConfigs]="monitorConfigs"
                                ></app-add-edit-location-avGated-sensor>
                                <app-add-edit-location-surface-combo-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && (sensor.get('name').value === sensorNames.surfaceCombo || sensor.get('name').value === sensorNames.paraFlow)"
                                    [form]="sensor"
                                ></app-add-edit-location-surface-combo-sensor>
                                <app-add-edit-location-long-range-depth-triton-sensor
                                    [index]="i"
                                    [monitoringPoint]="sensor.value.mp"
                                    [monitorConfigs]="monitorConfigs"
                                    *ngIf="sensor && sensor.controls && sensor.get('name').value === sensorNames.longRange"
                                    [form]="sensor"
                                ></app-add-edit-location-long-range-depth-triton-sensor>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <app-triton-configure-alarms
                    *ngIf="locationForm.get('mp2AlarmsInfo')"
                    [form]="locationForm.get('mp2AlarmsInfo')"
                    [isMp2]="true"
                    [monitorConfigs]="monitorConfigs"
                    [monitorSeriesChange]="monitorSeriesChange$"
                    [alarmConfigs]="alarmConfigs"
                    [monitorSeries]="locationForm.get('basicInfo').get('series').value"
                ></app-triton-configure-alarms>

                <app-rain-alarm-info
                    *ngIf="locationForm.get('rainAlarms')"
                    [monitorConfigs]="monitorConfigs"
                    [form]="locationForm.get('rainAlarms')"
                ></app-rain-alarm-info>

                <app-flow-form
                    *ngIf="locationForm.get('flowInfo2') && !isOtherMonitorSeriesSelected()"
                    [form]="locationForm.get('flowInfo2')"
                    [monitorConfigs]="monitorConfigs"
                    [installationSeriesChange]="mp2InstallationSeriesChange$"
                    [isMp2]="true"
                ></app-flow-form>

                <app-sampler-info
                    *ngIf="locationForm.get('samplerInfo')"
                    [form]="locationForm.get('samplerInfo')"
                    [monitorConfigs]="monitorConfigs"
                ></app-sampler-info>

            </mat-accordion>
        </div>
    </form>

    <div cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" (cdkDragEnded)="dragEvent()" cdkDragBoundary=".cdk-overlay-container">
        <app-dialog-actions
            *ngIf="locationForm"
            (openLocationDashboard)="openLocationDashboard()"
            (save)="onSaveClicked($event)"
            (activateLocation)="activateLocation()"
            (cancelClicked)="onCancelClicked()"
            [data]="data"
            [form]="locationForm"
            (collectLocationClicked)="onCollectLocationClicked()"
            (scheduleCollectClicked)="onScheduleCollectClicked()"
            (exportLocationClicked)="onExportLocationClicked()"
        ></app-dialog-actions>
    </div>
</div>
