<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'alarms-config-section-error': form.invalid }">
        <mat-expansion-panel-header>
            <mat-panel-title class="input-text uppercaseTransform">
                {{ 'COMMON.SAMPLER' | translate }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount }}
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="custom-50p">
            <div class="each-Item item-flex-center">
                <div class="custom-50p">
                    <div class="each-Item">
                        <mat-form-field class="full-width">
                            <mat-select
                                placeholder="{{ 'COMMON.TYPE_COLUMN_TITLE' | translate }}"
                                formControlName="samplingType"
                                (selectionChange)="onTypeChange($event)"
                            >
                                <mat-option *ngFor="let type of samplerTypes; trackBy: trackById" [value]="type.id">
                                    {{ type.name }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="each-Item">
                        <mat-form-field class="full-width required">
                            <input
                                matInput
                                type="number"
                                formControlName="trigger"
                                [placeholder]="triggerPlaceholder"
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="form.get('trigger').errors?.pattern && triggerErrorMessage">
                            {{ triggerErrorMessage }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="each-Item">
                <div class="custom-50p">
                    <div class="each-Item">
                        <mat-form-field class="full-width">
                            <mat-select
                                placeholder="{{ 'COMMON.SAMPLER_PER_WAKEUP' | translate }}"
                                formControlName="samplesPerWakeup"
                            >
                                <mat-option *ngFor="let wakeup of samplerPerWakeupOptions; trackBy: trackById" [value]="wakeup.id"
                                    >{{ wakeup.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="each-Item">
                        <mat-form-field class="full-width">
                            <mat-select placeholder="{{ 'COMMON.PULSE_LENGTH' | translate }}" formControlName="delay">
                                <mat-option *ngFor="let pulse of samplerPulseLengthOptions; trackBy: trackById" [value]="pulse.id"
                                    >{{ pulse.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
