<div id="sli-flow-container">
    <div
        *ngIf="seasonFilter"
        class="filter-content"
    >
        <div class="filter-field filter-title">{{ 'SLIICER.FLOW_TAB.FILTERS_TITLE' | translate }}</div>
        <div class="filter-field title">
            <mat-icon>filter_list</mat-icon> {{ 'SLIICER.FLOW_TAB.FILTERBY' | translate }}
        </div>
        <div
            class="filter-field"
            *ngIf="hasSeasons"
        >
            <mat-form-field>
                <mat-select
                    placeholder="Seasons"
                    ngModel
                    [disabled]="!filtersEnabled"
                    [(ngModel)]="selectedSeasons"
                    (ngModelChange)="shouldApplyFilters = true"
                    multiple
                >
                    <mat-option
                        *ngFor="let season of availableSeasons; trackBy: trackByIndex"
                        [value]="season"
                    >
                        {{ season }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            class="filter-field"
            *ngIf="hasYears"
        >
            <mat-form-field>
                <mat-select
                    [disabled]="!filtersEnabled"
                    placeholder="Years"
                    [(ngModel)]="selectedYears"
                    multiple
                    (ngModelChange)="shouldApplyFilters = true"
                >
                    <mat-option
                        *ngFor="let year of availableYears; trackBy: trackByIndex"
                        [value]="year"
                    >
                        {{ year }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            class="filter-field"
            *ngIf="hasRegimes"
        >
            <mat-form-field>
                <mat-select
                    [disabled]="!filtersEnabled"
                    placeholder="Regime"
                    [(ngModel)]="selectedRegime"
                    (ngModelChange)="shouldApplyFilters = true"
                    multiple
                >
                    <mat-option
                        *ngFor="let regime of regimes; trackBy: trackByIndex"
                        [value]="regime.name"
                    >
                        {{ regime.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="filter-field">
            <button
                [ngClass]="{'f-w-400': !shouldApplyFilters, 'f-w-550': shouldApplyFilters}"
                [disabled]="!filtersEnabled"
                mat-button
                (click)="filterChange()"
            >{{ 'COMMON.APPLY_BTN' | translate }}</button>
        </div>
    </div>
    <div class="sli-header">
        <h4 class="graph-heading">
            {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.FLOW_LABEL' | translate }}: {{ startDateString }} -
            {{ endDateString }}
        </h4>
        <div class="spacer"></div>
        <div class="form-undo-offset">
            <h4 class="graph-heading pad-r-15">
                {{ 'SLIICER.FLOW_TAB.CHOOSE_BASIN' | translate }}
            </h4>
            <mat-form-field>
                <mat-select
                    [disabled]="updatesWidgetService.updates"
                    [ngModel]="selectedBasin"
                    [value]="selectedBasin"
                    (ngModelChange)="onBasinChanged($event)"
                >
                    <mat-option
                        *ngFor="let basin of basinList; trackBy: trackById"
                        [value]="basin.text"
                    >{{ basin.text }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="spacer"></div>
        <div *ngIf="subTabsIndex === 0">
            <span class="pad-r-15">{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EDIT_DRY_DAYS' | translate }}</span>
            <mat-slide-toggle
                (change)="onEditDryDayToggle($event)"
                [checked]="isDryDaysEditActive"
                [disabled]="isStudyLocked"
            ></mat-slide-toggle>
        </div>
        <div *ngIf="subTabsIndex === 1">
            <span class="pad-r-15">{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.ADD_STORMS_TOGGLE' | translate }}</span>
            <mat-slide-toggle
                (change)="onAddStormsToggle($event)"
                [checked]="isStormAddActive"
                [disabled]="isStudyLocked"
            ></mat-slide-toggle>
        </div>
    </div>

    <div id="sli-hyetograph-container">
        <app-loader [isLoading]="isLoading"></app-loader>
        <ads-prism-flow-monitor-hydrograph
            #hydroGraphChart
            [isDryDaysEditActive]="isDryDaysEditActive && subTabsIndex === 0"
            [isStormAddActive]="isStormAddActive && subTabsIndex === 1"
            [basinDryDays]="currentBasinDryDays"
            [stormEvents]="stormEvents"
            [selectedStormId]="selectedStormId"
            [basinStormSettings]="basinStormSettings"
            [stormSettings]="stormSettings"
            [addedStormEvents]="addedStormEvents"
            [basinName]="selectedBasin"
            [regimes]="regimes"
            [seasons]="seasons"
            (stormCreated)="addNewStorm($event)"
            [basinOutlierDays]="basinOutlierDays"
            [isLoading]="isLoading"
            (plotBandAdded)="plotBandAdded($event)"
            (plotBandRemoved)="plotBandRemoved($event)"
            (plotBandSelected)="plotBandSelected($event)"
        >
        </ads-prism-flow-monitor-hydrograph>
    </div>
    <div
        class="toggle-container"
        *ngIf="!isLoading"
    >
        <div>
            <mat-checkbox
                class="custom-chk-margin"
                [checked]="hydroGraphChart.showDryDaysValue"
                (change)="showDryDaysToggle($event)"
            >
                <span>{{ 'SLIICER.COMMON.SHOW_DRY_DAYS' | translate }}</span>
            </mat-checkbox>
            <mat-checkbox
                class="pad-l-15 custom-chk-margin"
                [checked]="hydroGraphChart.showStormsValue"
                (change)="showStormsToggle($event)"
            >
                <span>{{ 'SLIICER.COMMON.SHOW_STORMS' | translate }}</span>
            </mat-checkbox>
            <mat-checkbox
                class="pad-l-15 pad-r-15 custom-chk-margin"
                [checked]="tooltipEnabled"
                (change)="onTooltipEnabledChanged()"
            >
                <span>{{ 'SLIICER.COMMON.SHOW_TOOLTIPS' | translate }}</span>
            </mat-checkbox>

        </div>
    </div>
    <div class="sli-tabs horizontal-tab-container">
        <mat-tab-group [(selectedIndex)]="subTabsIndex">
            <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.DRY_DAYS' | translate }}">
                <app-dry-days
                    [caseStudyId]="caseStudyId"
                    [isStudyLocked]="isStudyLocked"
                    [customerId]="customerId"
                    [telemetryKey]="telemetryKey"
                    [stepLength]="stepLength"
                    [basinDryDays]="currentBasinDryDays"
                    [selectedBasin]="selectedBasin"
                    [isDryDaysEditActive]="isDryDaysEditActive"
                    [daysLoading]="basinDaysLoading"
                    [selectDryDays]="selectDryDays"
                    (dryDayRemoved)="onDryDayRemoved($event)"
                    (weekDaySelection)="getDryDayDate($event)"
                >
                </app-dry-days>
            </mat-tab>
            <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_EVENTS' | translate }}">
                <ng-template matTabContent>
                    <ads-storm-events
                        [caseStudyId]="caseStudyId"
                        [customerId]="customerId"
                        [defaultPrecompType]="defaultPrecompType"
                        [dayOfWeekGroups]="dayOfWeekGroups"
                        [stepLength]="stepLength"
                        [selectedBasin]="selectedBasin"
                        [stormBasinResults]="stormBasinResults"
                        [stormEvents]="stormEvents"
                        [qvsIConfigurations]="qvsIConfigurations"
                        [originalQvsIConfigurations]="originalQvsIConfigurations"
                        [basinStorms]="basinStormSettings"
                        [stormSettings]="stormSettings"
                        [basinQvsiOverride]="originalBasinQvsISettings"
                        [basinDryDays]="originalCurrentBasinDryDays"
                        [addedStormEvents]="addedStormEvents"
                        [removedStormEvents]="removedStormEvents"
                        [isLoading]="stormEventsLoading"
                        [seasons]="seasons"
                        [availableSeasons]="availableSeasons"
                        [regimes]="regimes"
                        [availableYears]="availableYears"
                        [hasYears]="hasYears"
                        [refreshStorm]="refreshStorm$"
                        [designStorms]="designStorms"
                        (seasonChanged)="handleSeasonChange($event)"
                        (regimeChanged)="handleRegimeChange($event)"
                        (qvsiConfChanged)="handleqvsiConfigurationChange($event)"
                        (precompensationMethodChanged)="precompensationMethodChanged($event)"
                        (excludedStormToggled)="excludedStormToggled($event)"
                        (updatedStormEventsPeriods)="updatedStormEventPeriods($event)"
                        (selectedStormIdChanged)="handleSelectedIdStormChange($event)"
                        (stormEventsEdited)="checkEditedStormEvents($event)"
                        (basinQvsIEdited)="checkEditedBasinQvsI($event)"
                        (deleteStorm)="deleteStorm($event)"
                        (manualStormEdited)="onManuallyCreatedStormEdit($event)"
                        (recallBasinOverrides)="recallBasinOverrides()"
                        (refreshStormBasinResults)="refreshStormBasinResults($event)"
                        (updateActiveDesignStorms)="updateActiveDesignStorms($event)"
                    >
                    </ads-storm-events>
                </ng-template>
            </mat-tab>
            <mat-tab [label]="'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STATISTICS' | translate">
                <div class="tab-body">
                    <mat-tab-group>
                        <!-- ads-sliicer-storm-stats component is still used in storm events, so not deleting it-->
                        <mat-tab [label]="'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.BASIN_QVI' | translate">
                            <app-basin-qvi-stats
                                [selectedBasin]="selectedBasin"
                                [basinQvsISettings]="basinQvsISettings"
                            ></app-basin-qvi-stats>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
