<div class="display-flex">
    <ul class="pagination">
        <li [ngClass]="{ disabled: pager.currentPage === 1 }">
            <a matTooltip="Previous" matTooltipPosition="above" (click)="setPage(pager.currentPage - 1)"
                ><i class="material-icons">chevron_left</i></a
            >
        </li>
        <li *ngFor="let page of pager.pages; trackBy: trackByIndex" [ngClass]="{ active: pager.currentPage === page }">
            <a (click)="setPage(page)">{{ page }}</a>
        </li>
        <li [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
            <a matTooltip="Next" matTooltipPosition="above" (click)="setPage(pager.currentPage + 1)"
                ><i class="material-icons">chevron_right</i></a
            >
        </li>
    </ul>
</div>
<div></div>
