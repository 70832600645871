interface IHasId {
    id: number;
}

interface IHasLocationId {
    locationId: number;
}

interface IHasLocationGroup {
    locationGroupID: number;
}

interface IHasCustomerId {
    customerID: number;
}

interface IHasEntityId {
    entityId: number;
}

interface IHasValue<T> {
    value: T;
}

export class TrackBy {
    static byId = <T extends IHasId>(index: number, item: T): number => {
        return item.id;
    };

    static byLocationId = <T extends IHasLocationId>(index: number, item: T): number => {
        return item.locationId;
    };

    static byLocationGroupId = <T extends IHasLocationGroup>(index: number, item: T): number => {
        return item.locationGroupID;
    };

    static byUniqueKey<T>() {
        return (key: keyof T) => {
            return (index: number, item: T): T[typeof key] => {
                return item[key];
            };
        };
    }

    static byCustomerId = <T extends IHasCustomerId>(index: number, item: T): number => {
        return item.customerID;
    };

    static byEntityId = <T extends IHasEntityId>(index: number, item: T): number => {
        return item.entityId;
    };

    static byComposite<T, K extends keyof T>(props: K[]) {
        return (index: number, item: T): string => {
            return props.map(prop => item[prop]).join('|');
        };
    }

    static byKeyWithFallback<T>(primaryKey: keyof T, fallbackKey: keyof T) {
        return (index: number, item: T) => {
            return item[primaryKey] !== undefined && item[primaryKey] !== null
                ? item[primaryKey]
                : item[fallbackKey];
        };
    }

    static byIndex = (index: number): number => {
        return index;
    };

    static byValue() {
        return <TValue>(index: number, item: IHasValue<TValue>) => {
            return item.value;
        };
    }
}