import {
    Component,
    ViewChild,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import {
    GetPermissionUserFeature,
    GetPermissionsResponseUser,
    GetUsersResponse,
    User,
    UserPermissionResponse,
} from 'app/shared/models/users-permission';
import { UsersService, USER_ROLES, ACTIVE_INACTIVE } from 'app/pages/admin/users.service';
import { BehaviorSubject } from 'rxjs';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { FormControl } from '@angular/forms';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
    Active,
    activeInactiveLocationQueryParam,
    All,
    AppQueryParams,
    Customer,
    customerFilter,
    customerLocationGroupQueryParam,
    customerQueryParam,
    InActive,
    userSearchPageIndexParam,
    userSearchPageSizeParam,
    userSearchParam,
} from 'app/shared/models/customer';
import { IFeatures, UsersDataDisplay } from 'app/shared/models/users';
import { SharedService } from 'app/shared/services/shared.service';
import { SelectOptionType } from 'app/shared/models/add-edit-location';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IRoles } from '../../../shared/models/users';
import { TrackBy } from 'app/shared/utils/track-by';


@Component({
    selector: 'app-admin-users',
    templateUrl: './admin-users.component.html',
    styleUrls: ['./admin-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AdminUsersComponent implements OnInit, OnDestroy {
    public searchString = new FormControl();
    public customers = new Array<Customer>();
    public isFeatureFilterSelected: boolean;
    public users = new Array<User>();
    public activeInactiveList: SelectOptionType[];
    public usersSummary = new Array<User>();
    public assignedFeatureUsers = new Array<string>();
    public rolesSelected = new Array<string>();
    public filteredData = new Array<User>();
    public showUserSearch: boolean;
    public initialUsersData = new Array<User>();
    private subscriptions = new Array<Subscription>();
    public featureList = new Array<IFeatures>;
    public loggedInUserFeatures:  Array<IFeatures>;
    public isActive: string;
    public allAdminFeatures: Array<IFeatures>;
    public availableFeatures: Array<IFeatures>;
    public userDataChange: BehaviorSubject<UsersDataDisplay[]> = new BehaviorSubject<UsersDataDisplay[]>([]);
    public userDataSource: FilterDataSource | null;
    public userName: string;
    public inActivationHeader: string;
    public inActivationMessage: string;
    public eventValue: string;
    public confrim: any;
    public cancel: any;
    public isUserStatusChanged: boolean;
    public isMatTableVisible = true;
    public isUser: boolean;
    public dismissBtnText: string;
    public snackbarErrMsg: string;
    public snackbarSuccessMsg: string;
    public USER_ROLES = USER_ROLES;
    public get userData(): UsersDataDisplay[] {
        return this.userDataChange.value;
    }
    public totalPaginationLength: number;
    @ViewChild(MatPaginator, { static: true }) public userPaginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) public userSort: MatSort;
    @ViewChild(MatInput, { static: true }) public searchBox: MatInput;
    public userFilterColumns = ['userName', 'userEmail', 'name', 'role', 'customers', 'features', 'active', 'editUser'];
    private selectedUser: User;
    private selectedCustomerId: number;
    public userEdited: string;
    public isFetchingUser = false;
    public searchValue: string;
    public pageSize: number;
    public pageIndex: number;

    /**
     * This is track register user permission
     */
    public isRegisterUser: boolean;

    /**
     * Indicates the loading state of the component.
     */
    public isLoading: boolean;
    public loggedInUserRole: string;
    public addNewUserVisible: boolean;
    public roles: Array<IRoles> = [];
    public translateKeys: Array<string> = [
        'COMMON.DISMISS_TEXT',
        'ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_SUCCESS_MESSAGE',
        'ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_ERROR_MESSAGE',
        'ADMIN.ADMIN_USERS.USER_CONFIRM_INACTIVATION_TEXT',
        'ADMIN.ADMIN_USERS.USER_INACTIVATION_MESSAGE',
        'ADMIN.CONFIRM',
        'ADMIN.CANCEL',
    ];

    public isIdexAccount?: boolean;
    public trackById = TrackBy.byId;
    public trackByValue = TrackBy.byValue();
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private locationGroupService: LocationGroupService,
        private usersService: UsersService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
    ) {
        /*  Search by user email address changes  */
        this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe(
                (res: string) => {
                    this.filterUsers(res);
                },
                (error) => {
                    // error block
                },
            );

        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissBtnText = translateValues['COMMON.DISMISS_TEXT'];
            this.snackbarErrMsg = translateValues['ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_ERROR_MESSAGE'];
            this.inActivationHeader = translateValues['ADMIN.ADMIN_USERS.USER_CONFIRM_INACTIVATION_TEXT'];
            this.inActivationMessage = translateValues['ADMIN.ADMIN_USERS.USER_INACTIVATION_MESSAGE'];
            this.confrim = translateValues['ADMIN.CONFIRM'];
            this.cancel = translateValues['ADMIN.CANCEL'];
            this.snackbarSuccessMsg = translateValues['ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_SUCCESS_MESSAGE'];
        });
    }

    public ngOnInit() {
        // check permission for register user
        this.subscriptions.push(
            this.usersService.isRegisterUserFeatureAllowed.subscribe(
                (response) => {
                    this.isRegisterUser = response;
                },
                (ignoreError) => {},
            ),
        );

        this.loggedInUserRole = USER_ROLES.NONE;
        this.addNewUserVisible = false;

        this.statusCodeService.userInfo.subscribe(
            (res) => {
                this.loggedInUserRole = res && res.userRole ? res.userRole[0] : USER_ROLES.NONE;
                this.isIdexAccount = res.isIdex;
                this.addNewUserVisible =
                    this.loggedInUserRole === USER_ROLES.ADMIN ||
                    ((this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN ||
                    this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER) && this.isRegisterUser);
                    // #22179 have to rollback - all Customer Admins can create new users !
                    // (this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN && this.isRegisterUser && this.isIdexAccount);

                    this.usersService.getAllRoles().subscribe((roles: Array<IRoles>) => {
                        this.roles = roles;

                        if(this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN) {
                            this.roles = this.roles.filter(r => r.name !== USER_ROLES.ADMIN);
                        }

                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    });
            },
            (ignoreError) => {},
        );

        this.usersService.getAllFeatures().subscribe((features: Array<IFeatures>) => {
            this.loggedInUserFeatures = features.filter((x) => !x.canassigncustomer);
            this.uiUtilsService.safeChangeDetection(this.cdr);

        });
        
        this.isLoading = true;
        // subscribe to route change
        this.searchValue = this.searchString.value;
        this.pageSize = this.userPaginator.pageSize;
        this.pageIndex = this.userPaginator.pageIndex;

        this.activeInactiveList = [
            { value: '1', text: 'All' },
            { value: '2', text: 'Active' },
            { value: '3', text: 'Inactive' },
        ];

        this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                this.selectedCustomerId = Number(params.get(customerQueryParam));
                this.searchValue = params.get(userSearchParam);
                this.pageIndex = Number(params.get(userSearchPageIndexParam));
                this.pageSize = Number(params.get(userSearchPageSizeParam));
            },
            (error) => {
                // error block
            },
        );

        // disable location group selector in top-nav
        this.locationGroupService.locationGroupDisabledStatus = true;

        this.statusCodeService.userEmailInEditScreen.subscribe(
            (editedUser: string) => {
                this.userEdited = editedUser;
            },
            (error) => {
                // error block
            },
        );
        //  get all the users
        if (!!this.searchValue) {
            this.searchString.setValue(this.searchValue);
        }
        this.getUsers(this.pageSize, this.pageIndex, this.searchValue);
    }

    public filterCustomers() {
        this.filteredData = this.usersSummary;

        this.filteredData =
            this.eventValue === ACTIVE_INACTIVE.ACTIVE || this.eventValue === ACTIVE_INACTIVE.INACTIVE
                ? this.filteredData.filter((user) => user.isActive === (this.eventValue === ACTIVE_INACTIVE.ACTIVE))
                : this.usersSummary;
        if (this.assignedFeatureUsers && this.assignedFeatureUsers.length > 0) {
            this.filteredData = this.filteredData.filter(
                (x) =>
                    x.features &&
                    x.features.length > 0 &&
                    x.features.some((z) => this.assignedFeatureUsers.some((y) => z.trim() === y.trim())),
            );
        }
        this.isMatTableVisible = this.filteredData.length > 0;
        this.totalPaginationLength = this.filteredData.length;
        this.users = this.filteredData;
        this.generateUserTable();
    }

    public getUserByFeatures($event) {
        this.assignedFeatureUsers = [];
        this.isFeatureFilterSelected = $event.length > 0;
        if (this.isFeatureFilterSelected) {
            this.assignedFeatureUsers.push(...$event);
        }
        this.getUsers(this.pageSize, this.pageIndex, this.searchString.value);
    }


    public onRoleSelection($event) {
        this.rolesSelected = [];
        if($event.length > 0){
            this.rolesSelected.push(...$event);
        }
        this.getUsers(this.pageSize, this.pageIndex, this.searchString.value);
    }

    public onSelection(event) {
        if (event === Active) {
            this.isActive = customerFilter.Active;
        } else if (event === InActive) {
            this.isActive = customerFilter.Inactive;
        } else if (event === All) {
            this.isActive = customerFilter.All;
        }
        this.getUsers(this.pageSize, this.pageIndex, this.searchString.value);
    }

    public showUsersFilters(value?: boolean) {
        if (value == null) {
            this.showUserSearch = !this.showUserSearch;
            if (this.showUserSearch === false) {
                this.filterCustomers();
                this.assignedFeatureUsers = [];
                this.rolesSelected = [];
            }
            return;
        }
        this.showUserSearch = value;
    }

    /**
     * Method takes a sting as an argument and filters out users whose email addresses include the string
     * @param searchString - string to search for in email addresses
     */
    public filterUsers(searchString: string) {
        const pageSize: number = this.userPaginator.pageSize;
        const pageIndex: number = this.userPaginator.pageIndex;
        this.getUsers(pageSize, pageIndex, searchString);
    }
    /**
     * Method generates a table of users
     */
    public generateUserTable(enableServerSidePaging = false) {
        this.userDataSource = null;
        this.userDataChange = new BehaviorSubject<UsersDataDisplay[]>([]);
        this.users.forEach((user) => {
            // Flag to show the edited row
            const highlightEditedRow: boolean = this.userEdited !== '' && this.userEdited === user.userEmail;
            const userCopiedData = this.userData.slice();
            const data = {
                userID: user.userID,
                firstName: user.firstName,
                lastName: user.lastName,
                userEmail: user.userEmail,
                userName: user.userName,
                name: [user.firstName, user.lastName].join(' '),
                role: user.role,
                locations: user.locations,
                customers: user.customers,
                features: user.features.sort(),
                isSelected: user.isSelected,
                highLight: highlightEditedRow,
                disable: this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN && user.role === USER_ROLES.CUSTOMER_ADMIN,
                isActive: user.isActive,
            };
            userCopiedData.push(data);
            this.userDataChange.next(userCopiedData);
        });
        this.userDataSource = new FilterDataSource(
            this.userDataChange,
            this.userData,
            this.userPaginator,
            this.userSort,
            this.userFilterColumns,
            enableServerSidePaging,
        );
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public ngOnDestroy() {
        this.statusCodeService.userEmailInEditScreen.next('');

        // re-enable location group selector in top-nav
        this.locationGroupService.locationGroupDisabledStatus = false;

        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        this.statusCodeService.userEmailInEditScreen.next('');
    }

    public data: Object;
    public customerForUser: any;

    public activateUser(user: User) {
        this.userName = user.userName;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        if (user.isActive) {
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.inActivationHeader,
                        message: this.inActivationMessage,
                        okText: this.confrim,
                        cancelText: this.cancel,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.isLoading = true;
                        this.changeState(user.userID, false);
                    } else {
                        this.generateUserTable();
                    }
                });
        } else {
            this.isLoading = true;
            this.changeState(user.userID, true);
        }
    }
    public changeState(userID: string, status: boolean) {
        this.usersService.activateStatus(userID, status).subscribe(
            (response) => {
                this.isUserStatusChanged = true;
                this.isUser = true;
                this.getUsers(this.pageSize, this.pageIndex, this.searchValue);
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                // snak bar message when error occured
                this.snackBar.open(this.snackbarErrMsg + ' ' + this.userName, this.dismissBtnText, {
                    panelClass: 'custom-error-snack-bar',
                });
            },
        );
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Method brings up a pop up window with user's name and customers menu
     * @param user the user data object.
     */
    public customerAssociation(user: User) {
        this.isLoading = true;
        const customerAssociation = this.usersService.getUserPermissions(user.userID).subscribe(
            (userData: GetPermissionsResponseUser) => {
                const userFullData = this.users.find((x) => x.userID === user.userID);
                userData.userID = userFullData.userID;
                this.selectUser(user);
                this.customerForUser = userData.customers.map((x) => x.customer);
                if (user.userEmail === this.usersService.userName) {
                    const defaultCustomer = this.usersService.defaultCustomer.getValue();
                    userData.customers.forEach(
                        (customer) => {
                            if (defaultCustomer && customer.customer.customerID === defaultCustomer) {
                                customer.defaultCustomer = true;
                            } else {
                                customer.defaultCustomer = false;
                            }
                        },
                        (error) => {
                            // error block
                        },
                    );
                }
                // storing data in an object to send to the detail screen
                this.data = {
                    user: userData,
                    customers: this.customerForUser,
                };
                // setup the route for admin detail screen
                this.setupAdminDetailRoute(userFullData);
            },
            (error) => {
                // error block
            },
        );
        this.subscriptions.push(customerAssociation);
        // Initially set the value of closeUserAssociation to false
        this.sharedService.closeUserAssociation(false);
    }
    /**
     * Function to setup the admin detail route
     */
    public setupAdminDetailRoute(userFullData) {
        const currentCustomerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));
        // get current query params for location group
        const locationGroupId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam));
        // get current query params for showing all locations
        const isShowAllLocations = Number(
            this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
        );
        if (userFullData === '') {
            // Using Behaviour Subjects next method to emit the data
            this.sharedService.adminUserData.next(null);
        } else {
            sessionStorage.setItem('adsGuid', userFullData.userID);
            // Using Behaviour Subjects next method to emit the data
            this.sharedService.adminUserData.next(this.data);
        }
        // get global query params
        const appQueryParams = <AppQueryParams>{
            c: currentCustomerId,
            lg: locationGroupId || undefined,
            lt: isShowAllLocations || undefined,
            u: userFullData === '' ? 'registeruser' : userFullData.userEmail,
            s: !!this.searchString.value ? this.searchString.value : undefined,
            pi: !this.userPaginator.pageIndex ? 0 : Number(this.userPaginator.pageIndex),
            ps: this.userPaginator.pageSize || undefined,
        };
        // Navigating with required params
        this.isLoading = false;
        this.router.navigate(['/pages/admin/details'], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }
    /**
     * Updates the user table post customer association updates.
     * @param res the API response.
     */
    private updateUserTable(res: UserPermissionResponse) {
        //  if permissions were changes update the users' table
        //  get all the users
        const allUsersSubscription = this.usersService.getAllUsers().subscribe(
            (response: GetUsersResponse) => {
                if (response.payload && response.payload.length > 0) {
                    this.users = this.initialUsersData = response.payload;
                    // Hiding loader
                    this.isLoading = false;
                    this.generateUserTable();
                    if (this.searchString.value !== '') {
                        this.filterUsers(this.searchString.value);
                    }
                    this.selectUser(this.selectedUser);
                }
            },
            (error) => {
                // error block
            },
        );
        this.subscriptions.push(allUsersSubscription);
    }
    /**
     * Method takes a user as a parameter and sets this user as selected
     * for the purpose of highlighting
     * @param user
     */
    private selectUser(user: User) {
        this.userData.forEach((listUser) => {
            listUser.isSelected = listUser.userEmail === user.userEmail;
        });
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    /**
     * checks if the name length is more than specific length
     */
    public validateLongStringLength(str: string, strLength: number): boolean {
        if ((str + '').length > strLength) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * provide space between two word wih , saperated
     */
    public provideSpace(str: string) {
        str = str + '';
        return str.replace(/,/g, ', ');
    }

    public changePage(page) {
        const searchValue = this.searchString.value;
        this.getUsers(page.pageSize, page.pageIndex, searchValue, true);
    }

    public getUsers(pageSize: number, pageIndex: number, searchValue: string, isPageChanged = false) {
        if (this.isFetchingUser) {
            return;
        }
        if (pageIndex) {
            pageIndex += 1;
        } else {
            pageIndex = 1;
        }
        //  get all the users
        this.disableSearchBox();
        this.isFetchingUser = true;
        this.isLoading = true;
        const allUsersSubscription: Subscription = this.usersService
            .getAllUsers(pageSize, pageIndex, searchValue, null, this.isActive, this.assignedFeatureUsers, this.rolesSelected)
            .subscribe(
                (response: GetUsersResponse) => {
                    if (response && response.payload && response.payload.length > 0) {
                        this.users = this.usersSummary = this.initialUsersData = response.payload;
                        // let pageSize: number = Number(page.pageSize) === 0 ? 1 : Number(page.pageSize);
                        this.users
                            .filter((x) => x.role === USER_ROLES.ADMIN)
                            .map((x) => {
                                (x.customers = 'All'), (x.locations = 'All');
                            });
                        this.totalPaginationLength = Math.round(response.count);
                        this.userPaginator.length = Math.round(response.count);
                        this.isMatTableVisible = this.users.length > 0;
                    } else {
                        this.users = this.initialUsersData = [];
                        this.totalPaginationLength = 0;
                        this.userPaginator.length = 0;
                    }
                    if (this.isUser) {
                        // snack bar message
                        const simpleSnackBarRef = this.snackBar.open(
                            this.userName + ' ' + this.snackbarSuccessMsg,
                            this.dismissBtnText,
                        );
                        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
                    }
                    this.isUser = false;
                    this.generateUserTable(true);
                    if (!isPageChanged && pageIndex && pageIndex > 0) {
                        if (!!pageSize && this.totalPaginationLength > pageSize) {
                            this.userPaginator.pageIndex = pageIndex - 1;
                        } else {
                            this.userPaginator.pageIndex = 0;
                        }
                    }
                    if (!!pageSize) {
                        this.userPaginator.pageSize = pageSize;
                    }
                    // Hiding loader
                    this.isFetchingUser = this.isLoading = false;
                    this.disableSearchBox(false);
                },
                (error) => {
                    // error block
                    this.isFetchingUser = this.isLoading = false;
                    this.disableSearchBox(false);
                },
            );
        this.subscriptions.push(allUsersSubscription);
    }
    public disableSearchBox(isDisable = true) {
        if (this.searchBox) {
            this.searchBox.disabled = isDisable;
        }
    }
}
