<div id="submissions">
    <mat-card class="flex-item" style="flex: 1">
        <mat-grid-list cols="5" rowHeight="40px">
            <mat-grid-tile [colspan]="3" [rowspan]="1">
                <mat-card-title class="full-width">Daily Submissions</mat-card-title>
                <!--<mat-card-subtitle>This is the dashboard page.</mat-card-subtitle>-->
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="1"> Date Ranges: </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="1">
                <mat-form-field>
                    <mat-select
                        placeholder="Date Ranges"
                        [(ngModel)]="dateRangeId"
                        (ngModelChange)="onDateRangeChange()"
                        class="full-width"
                        name="dateRange"
                    >
                        <mat-option *ngFor="let range of dateRanges; trackBy: trackById" [value]="range.id">
                            {{ range.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-card-content>
            <div class="layout-row justify-space image-max-width">
                <div [chart]="options" class="tabChart" (load)="saveChart($event.context)"></div>
            </div>
            <mat-card-actions class="submitButn"> </mat-card-actions>
        </mat-card-content>
    </mat-card>
</div>
