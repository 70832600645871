<div id="bpHumanReviewId">
    <div class="topmenu">
    </div>
    <mat-card>
        <mat-card-header>
            <div class="pt-20"><span class="daily-title-hint">{{ 'BLOCKAGE_PREDICT.HUMAN_REVIEW' | translate }}</span>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div class="human-review-filters">
                <div class="custom-50p">
                    <div class="each-Item" *ngIf="(customers$ | async) as customers">
                        <app-auto-complete
                            [items]="customers"
                            [preselectedItem]="selectedCustomer"
                            placeholder="{{ 'COMMON.CUSTOMER_TEXT' | translate }}"
                            [isShowAll]="false"
                            (selectedItems)="onChangeCustomer($event)"
                            [focusOnClear] = "true"
                            [onlyManualClear]="true"
                            [panelWidth]="'300px'"
                            [showTooltipMinLength]="33"
                            [showAllOptionsOnFocus]="true"
                            [populateSelectedValueonFocusOut]="true"
                            [sortStartWith]="true"
                            [scrollToSelectedOnFocus]="true"
                            [markSelectedOption]="true"
                        >
                        </app-auto-complete>
                    </div>
                </div>

                <div *ngIf="selectedCustomer && selectedCustomer.customerID !== 0">
                    <div class="custom-50p">
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select placeholder="{{ 'COMMON.TYPE_PLACEHOLDER' | translate }} *"
                                    #locationTypeInput="ngModel" [(ngModel)]="locationType"
                                    (selectionChange)="handleLocationTypeChange($event)" name="typedlocation">
                                    <mat-option *ngFor="let location of locationTypeCollection; trackBy: trackByValue"
                                        [value]="location.value">
                                        {{ location.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="each-Item" [hidden]="locationType !== 'locationGroup'">
                            <app-auto-complete [items]="locationGroupList" [preselectedItem]="[]" [isShowAll]="false"
                                (selectedItems)="handleSelectedLocationsGroup($event)"
                                placeholder="{{ 'COMMON.SELECT_LOCATION_GROUP' | translate }} *">
                            </app-auto-complete>
                            <div class="mat-error" *ngIf="locationGroupsUnavailable">
                                <span>{{ 'FLOW_MONITORING.LOCATIONGROUP_UNAVAILABLE_MESSAGE' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mat-error" *ngIf="locationsUnavailable && locationType === 'location'">
                            <span>{{ 'FLOW_MONITORING.LOCATIONS_UNAVAILABLE_MESSAGE' | translate }}</span>
                        </div>
                        <div class="custom-Section margin-b-10" *ngIf="!locationsUnavailable">
                            <ng-container *ngIf="locationType === 'location'">
                                <mat-checkbox class="each-location-item auto-margin"
                                    (change)="onSelectAllLocations($event)" [checked]="selectAllLocations"
                                    name="selectallcheckbox" ngDefaultControl [hidden]="!locations.length">
                                    {{ 'COMMON.SELECT_All_OPTION' | translate }}
                                </mat-checkbox>
                            </ng-container>
                            <span class="app-flex-filler"></span>
                            <div class="auto-margin search-section">
                                <span class="each-Item includeLocations fmrHintIncludeInactiveLocations">
                                    <mat-slide-toggle class="toggle" [(ngModel)]="shouldIncludeInactiveLocations"
                                        (change)="getLocations(true)">
                                    </mat-slide-toggle>
                                    <span>Include Inactive Locations</span>
                                </span>
                                <ng-container *ngIf="locationType === 'location'">
                                    <mat-form-field class="half-width fmrHintSearch">
                                        <mat-icon matPrefix class="search-icon">search</mat-icon>
                                        <input class="" matInput type="text"
                                            placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                                            [formControl]="locationSearch" />
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                        <div class="customMessage" *ngIf="minimumLocationError">
                            <span class="mat-error">{{ 'COMMON.SECARCH_LOCATION_ERROR' | translate }}</span>
                        </div>
                        <div class="custom-SectionList margin-b-10 fmrHintLocationGroupList"
                            *ngIf="!locationsUnavailable && locationType === 'location'">
                            <div class="each-location-item" *ngFor="let location of filteredLocations; trackBy: trackById">
                                <mat-checkbox [checked]="location.isChecked" name="location.locationName"
                                    [(ngModel)]="location.isChecked" (change)="onLocationChecked()">
                                    <span>{{ location.name }}</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-space-between align-center mt-2">
                    <div class="bp-filter-row">
                        <div class="dates">
                            <mat-form-field class="full-width start-date" matTooltip="{{ 'BLOCKAGE_PREDICT.BP_DATE_PICKER_TOOLTIP' | translate }}">
                                <input matInput [readonly]="true" [value]="reportDate | date: bpDateFormat"
                                    [max]="today" (change)="setDate($event)" tabindex="-1" class="datepickerStyles"
                                    appDatetimepickerPosition [matDatetimepickerComp]="bpdatepicker" placeholder="{{'BLOCKAGE_PREDICT.BP_DATE_PICKER_LABEL' | translate}}" />
                                <input hidden [matDatetimepicker]="bpdatepicker" (dateInput)="setDate($event)"
                                    [max]="today" />
                                <mat-datetimepicker-toggle matSuffix [for]="bpdatepicker">
                                </mat-datetimepicker-toggle>
                                <mat-datetimepicker #bpdatepicker timeInterval="1" type="date">
                                </mat-datetimepicker>
                            </mat-form-field>
                        </div>
                        <mat-form-field matTooltip="{{ 'BLOCKAGE_PREDICT.SCORE_ABOVE_TOOLTIP' | translate }}">
                            <input matInput 
                                type="number" 
                                name="scoreAbove"
                                [(ngModel)]="scoreAbove"
                                #scoreAboveInput="ngModel"
                                (blur)="formatScoreAbove()"
                                min="0"
                                max="100"
                                required
                                pattern="^\d+(\.\d{0,2})?$"
                                [placeholder]="'BLOCKAGE_PREDICT.SCORE_ABOVE' | translate" />
                            <span matSuffix>%</span>
                            <mat-error *ngIf="scoreAboveInput.invalid">
                                {{'BLOCKAGE_PREDICT.SCORE_ABOVE_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput 
                                type="number" 
                                name="daysToPlot"
                                [(ngModel)]="daysToPlot"
                                #daysToPlotInput="ngModel"
                                min="1"
                                max="60"
                                required
                                pattern="^[0-9]+$"
                                placeholder="{{ 'BLOCKAGE_PREDICT.DAYS_TO_PLOT' | translate }}" />
                            <mat-error *ngIf="daysToPlotInput.invalid">
                                {{'BLOCKAGE_PREDICT.DAYS_TO_PLOT_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-slide-toggle class="toggles" [(ngModel)]="blockagesToggle">
                            {{ 'BLOCKAGE_PREDICT.BLOCKAGES_FILTER' | translate }}
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="unflaggedToggle">
                            {{ 'BLOCKAGE_PREDICT.UNFLAGGED_FILTER' | translate }}
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="nonBlockageToggle">
                            {{ 'BLOCKAGE_PREDICT.NON_BLOCKAGES_FILTER' | translate }}
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="invertScatterToggle">
                            {{ 'BLOCKAGE_PREDICT.INVERT_SCATTER' | translate }}
                        </mat-slide-toggle>
                    </div>
                    <div class="custom-flat-button">
                        <button [disabled]="daysToPlotInput.invalid || scoreAboveInput.invalid || locationsUnavailable" mat-button (click)="getOverviewReport()" type="button">
                            {{ 'BLOCKAGE_PREDICT.RUN_HR_BUTTON' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoading">
                <app-loader [isLoading]="isLoading"></app-loader>
            </div>

        </mat-card-content>
    </mat-card>
    <app-human-review-report *ngIf="overviewReport" [invertScatter]="invertScatterToggle" [overviewReport]="overviewReport" [reportDate]="resReportDate" [daysToPlot]="daysToPlot"
    ></app-human-review-report>
</div>