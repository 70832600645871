<div id="addEvent">
    <!-- <app-loader [isLoading]="isLoading"></app-loader> -->
    <div class="popupHeader" mat-dialog-title>
        <span *ngIf="!isEditMode">{{ 'HOME.EVENT_WIDGET.ADD_EVENT' | translate }}</span>
        <span *ngIf="isEditMode">{{ 'HOME.EVENT_WIDGET.EDIT_EVENT' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->
    <div mat-dialog-content>
        <form [formGroup]="form">
            <mat-form-field class="full-width top">
                <input matInput formControlName="desc" placeholder="{{ 'HOME.EVENT_WIDGET.DESCRIPTION' | translate }}" required />
            </mat-form-field>
            <div class="date-range-container">
                <app-date-range-picker class="full-width" [showQuickRanges]="false" [buttonClasses]="['full-width', 'datepicker-escape-margin']"
                    [startDate]="form.get('start').value" [disabled]="!data.hasPermission" [endDate]="form.get('end').value" (emitDateChanged)="dateChange($event)">
                </app-date-range-picker>

                <mat-form-field class="duration-input">
                    <input matInput formControlName="duration" placeholder="{{ 'HOME.EVENT_WIDGET.DURATION' | translate }}" readonly />
                </mat-form-field>
            </div>

            <mat-form-field class="full-width">
                <mat-select [disabled]="!data.hasPermission" (selectionChange)="eventTypeChange($event)" placeholder="{{ 'HOME.EVENT_WIDGET.TYPE' | translate }}" formControlName="etype">
                    <mat-option *ngFor="let item of eventTypes; trackBy: trackById" [value]="item.id">{{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="custom-error">
                <app-multi-select-auto-complete [disabled]="!data.hasPermission" #multiSelectAutoCompleteLocations [items]="selectableLocations" [preselectedItems]="preselectedLocations" 
                    [isShowAll]="true" (currentlySelectedItems)="handleSelectedLocations($event)" placeholder="{{ 'COMMON.LOCATIONS' | translate }} *">
                </app-multi-select-auto-complete>
                <div class="custom-error-message" *ngIf="form.get('lids')?.errors">
                    <span>{{ 'COMMON.LOCATIONS_VALIDATION_MESSSAGE' | translate }}</span>
                </div>
            </div>

            <mat-form-field class="full-width">
                <mat-select placeholder="{{ 'HOME.EVENT_WIDGET.PARENT' | translate }}" [disabled]="!data.hasPermission" (selectionChange)="setParentChildrenList()" formControlName="parent">
                    <mat-option *ngFor="let item of parentList; trackBy: trackByGuid" [value]="item.guid">{{ item.desc }}</mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="childrenList.length && data.hasPermission">
                <div class="children-placeholder">{{ 'HOME.EVENT_WIDGET.CHILD_EVENTS' | translate }} *</div>
                <mat-selection-list (selectionChange)="childrenSelection($event)">
                    <mat-list-option *ngFor="let item of childrenList; trackBy: trackByGuid" [selected]="item.isChecked" [value]="item.guid">
                        {{ item.desc }}
                    </mat-list-option>
                </mat-selection-list>
            </ng-container>
        </form>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button mat-dialog-close>{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button *ngIf="data.hasPermission" [disabled]="form.invalid || form.pristine" (click)="saveEvent()">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>