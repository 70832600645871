<form [formGroup]="form">
    <div *ngIf="installationType === 200">
        <div class="custom-50p">
            <div class="each-Item">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        id="PipeHeightInput"
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_HEIGHT')
                                | addUnits: locationCardService.depthUnit
                        "
                        formControlName="height"
                        required
                        (change)="onPipeValueChange($event, true)"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('height')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('height')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
            <div class="each-Item">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        formControlName="width"
                        (change)="onPipeValueChange($event)"
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_WIDTH')
                                | addUnits: locationCardService.depthUnit
                        "
                        required
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('width')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('width')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
        </div>

        <div class="custom-50p pad-b-15">
            <div class="each-Item">
                <div class="full-width">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'COMMON.HC' | translate }}"
                            [matTooltipShowDelay]="1000"
                            id="hc"
                            formControlName="hc"
                            [pattern]="REGEX_CONFIG.numericWith2DecimalPlaces"
                            type="number"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('hc')?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate }}
                    </div>
                </div>
            </div>
            <div class="each-Item">
                <div class="full-width">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'COMMON.SLOPE' | translate }}"
                            [matTooltipShowDelay]="1000"
                            id="slope"
                            [pattern]="REGEX_CONFIG.numeric0_100With5DecimalPlacesPattern"
                            formControlName="slope"
                            type="number"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('slope')?.errors?.pattern">
                        {{ 'CALCULATED_ENTITY_DIALOG.INVALID_MANNING_SLOPE_CONSTANT_MESSAGE' | translate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-50p pad-b-15">
            <div class="each-Item">
                <div class="full-width">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'COMMON.ROUGHNESS' | translate }}"
                            [matTooltipShowDelay]="1000"
                            id="roughness"
                            formControlName="roughness"
                            [pattern]="REGEX_CONFIG.numericWith5DecimalPlaces"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 44 || event.charCode == 46)"
                            type="number"
                            min="0"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('roughness')?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR_5_DECIMALS' | translate }}
                    </div>
                </div>
            </div>
            <div class="each-Item">
                <mat-form-field class="full-width required">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.EFFECTIVE_ROUGHNESS' | translate }}"
                        [matTooltipShowDelay]="1000"
                        matTooltip="{{ 'COMMON.EFFECTIVE_ROUGHNESS_TOOLTIP' | translate }}"
                        id="effectiveRoughness"
                        formControlName="effectiveRoughness"
                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 44 || event.charCode == 46)"
                        [pattern]="REGEX_CONFIG.numericWith5DecimalPlaces"
                        type="number"
                        min="0"
                        required
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('effectiveRoughness')?.errors?.pattern">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_5_DECIMALS' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="installationType === 300">
        <div class="custom-33p">
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('range')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        formControlName="range"
                        required
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER')
                                | addUnits: locationCardService.depthUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('range')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('range')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('capacity')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        required
                        placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER' | translate }}"
                        formControlName="capacity"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('capacity')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('capacity')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('height')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        required
                        formControlName="height"
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT')
                                | addUnits: locationCardService.depthUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('height')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('height')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
        </div>
        <div class="custom-33p">
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('coefficient')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        required
                        placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CONSTANT' | translate }}"
                        formControlName="coefficient"
                    />
                </mat-form-field>
                <div
                    class="mat-error"
                    *ngIf="form.get('coefficient')?.errors?.pattern && !locationCardService.isMetric"
                >
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('coefficient')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('length')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        required
                        formControlName="length"
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.LENGTH')
                                | addUnits: locationCardService.depthUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('length')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('length')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('breadth')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        required
                        matInput
                        placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.BREADTH' | translate }}"
                        formControlName="breadth"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('breadth')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('breadth')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="installationType === 400">
        <div class="custom-33p">
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('range')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        formControlName="range"
                        required
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER')
                                | addUnits: locationCardService.depthUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('range')?.errors?.pattern">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
            </div>
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('capacity')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        required
                        placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER' | translate }}"
                        formControlName="capacity"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('capacity')?.errors?.pattern">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
            </div>
            <div class="each-Item" [ngClass]="{ 'pad-b-15': form.get('width')?.errors?.pattern }">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        formControlName="width"
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.WIDTH')
                                | addUnits: locationCardService.depthUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('width')?.errors?.pattern">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="installationType === 500">
        <div>
            <div *ngIf="form.get('range')">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        required
                        formControlName="range"
                        [placeholder]="
                        translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT')
                        | addUnits: locationCardService.depthUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('range')?.errors?.pattern && !locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('range')?.errors?.pattern && locationCardService.isMetric">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                </div>
            </div>
            <div *ngIf="monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD && form.get('numberOfEntries')">
                <mat-form-field class="pad-t-6 full-width required">
                    <input
                        matInput
                        placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_ENTRIES_LABEL' | translate }}"
                        formControlName="numberOfEntries"
                        (change)="onEntitiesChange($event.target.value)"
                        required
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('numberOfEntries')?.errors?.pattern">
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_ENTRY_ERROR' | translate }}
                </div>
            </div>

            <div
                formArrayName="entries"
                *ngFor="let group of getEntriesControls()?.controls; let i = index; trackBy: trackByIndex"
                [attr.data-index]="i"
            >
                <div *ngIf="monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && monitorSeries?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD" class="custom-50p" [formGroupName]="i">
                    <div class="each-Item">
                        <mat-form-field class="pad-t-6 full-width required">
                            <input
                                matInput
                                required
                                formControlName="depth"
                                (change)="onInputChange(i)"
                                [placeholder]="
                                    translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.DEPTH')
                                        | addUnits: locationCardService.depthUnit
                                "
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="group.get('depth')?.errors?.pattern && isMetric">
                            {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.ONE_PRECISION_ERROR' | translate }}
                        </div>
                        <div class="mat-error" *ngIf="(group.get('depth')?.errors?.pattern || group.get('depth')?.errors?.max) && !isMetric">
                            {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_DEPTH_PATTERN_ERROR' | translate }}
                        </div>
                    </div>
                    <div class="each-Item">
                        <mat-form-field class="pad-t-6 full-width required">
                            <input
                                matInput
                                required
                                (change)="onInputChange(i)"
                                placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY' | translate }}"
                                formControlName="quantity"
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="(group.get('quantity')?.errors?.pattern || group.get('quantity')?.errors?.max) && isMetric">
                            {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.ONE_PRECISION_ERROR' | translate }}
                        </div>
                        <div class="mat-error" *ngIf="(group.get('quantity')?.errors?.pattern || group.get('quantity')?.errors?.max) && !isMetric">
                            {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.THREE_PRECISION_ERROR' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Elevation installation type -->
    <div *ngIf="installationType === 1000">
        <div class="custom-50p pad-b-15">
            <div class="each-Item" *ngIf="form.get('referencePoint')" [ngClass]="{ 'pad-b-15': form.get('referencePoint')?.errors?.pattern }">
                <mat-form-field class="full-width required">
                    <input
                        matInput
                        required
                        formControlName="referencePoint"
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.REFERENCE_POINT')
                            | addUnits: locationCardService.elevationUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('referencePoint')?.errors?.pattern && !isMetric">
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.ELEVATION_REF_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('referencePoint')?.errors?.pattern && isMetric">
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.ELEVATION_REF_METRIC_PRECISION_ERROR' | translate }}
                </div>
            </div>
            <div class="each-Item" *ngIf="form.get('datum')" [ngClass]="{ 'pad-b-15': form.get('datum')?.errors?.pattern }">
                <mat-form-field class="full-width required">
                    <input
                        matInput
                        required
                        formControlName="datum"
                        [placeholder]="translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.DATUM')"
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('datum')?.errors?.pattern">
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.DATUM_PATTERN_ERROR' | translate }}
                </div>
            </div>
        </div>
        <div class="custom-50p pad-b-15">
            <div class="each-Item" *ngIf="form.get('physicalOffset')" [ngClass]="{ 'pad-b-15': form.get('physicalOffset')?.errors?.pattern }">
                <mat-form-field class="full-width required">
                    <input
                        matInput
                        required
                        formControlName="physicalOffset"
                        [placeholder]="
                            translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.PHYSICAL_OFFSET')
                            | addUnits: locationCardService.elevationUnit
                        "
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="form.get('physicalOffset')?.errors?.pattern && !isMetric">
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.ELEVATION_PHY_PRECISION_ERROR' | translate }}
                </div>
                <div class="mat-error" *ngIf="form.get('physicalOffset')?.errors?.pattern && isMetric">
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.ELEVATION_PHY_METRIC_PRECISION_ERROR' | translate }}
                </div>
            </div>
        </div>
    </div>
</form>
