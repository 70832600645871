import { DesignStormItem } from 'app/shared/models/sliicer/design-storm';
import { filter, map } from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/pipeable';
import { QIGraphComponent } from './q-i-graph.component';
import { Type } from '../basin-qvi-stats/basin-qvi-model';
import { UnitOfMeasureType } from 'app/shared/constant';

export type Point = { x: number; y: number };
export type RegressionLineData = { name: string; data: Point[], isAlternate: boolean, color?: string };
export type DesignStormData = { name: string; group?: string; data: DesignStormChartPoint[] };
export type Regression = { slope: number; intercept: number };
export type DesignStormChartPoint = { x: number; y: number; date: string };

export const COLOR_REGRESSION_LINE = '#00f';
export const COLOR_ALTERNATE_REGRESSION_LINE = '#f00';

export const SLOPE_PRECISION = '1.4-4';
export const GENERIC_PRECISION = '1.2-2';
export const Y_INTERCEPT_PRECISION = '1.3-3';

export function getDesignStormsData(
    storms: DesignStormItem[],
    regression: Regression,
    fieldAccessor: string,
): DesignStormChartPoint[] {
    return pipe(
        storms,
        map<DesignStormItem, DesignStormChartPoint>((s) => ({
            x: s[mapFieldAccessor(fieldAccessor)],
            y: regression.slope * s[mapFieldAccessor(fieldAccessor)] + regression.intercept,
            date: s.name,
        })),
    );
}

function mapFieldAccessor(name: string): string {
    switch (name) {
        case 'stormRain':
            return 'totalStormRain';
        case 'eventRain':
            return 'totalEventRain';
        case 'rainToPeakGrossII':
        case 'rainToPeakNetII':
            return 'cumulativePeakRain';
        case 'rainPeak':
            return 'peakRain';
        default:
            return '';
    }
}

export function qvsiTooltipFormatter(rainFractionDigits: 1 | 2, flowFractionDigits: 0 | 1 | 3, rainUnit: string, volumeUnit: string, that: QIGraphComponent) {
    const displayX = Number.parseFloat(this.x).toFixed(rainFractionDigits);
    const displayY = Number.parseFloat(this.y).toFixed(flowFractionDigits);

    if (!this.point) return '';
    return `
        <div class="tev">
            <span style ='color:${this.color}'>\u25CF</span>
            <span>${this.point.date} (${this.series.name}) <br>${that.xAxisLabel}: <strong>${displayX}</strong> ${rainUnit}</span>
            <br>
            <span>${that.yAxisLabel}: <strong>${displayY}</strong> ${volumeUnit}</span>
        </div>`
}

export const Y_INTERCEPT_PRECISION_MAP = {
    // #43805 for Csf we display no decimals for Volume types and 3 decimals for Peak types
    [UnitOfMeasureType.CFS]: {
        [Type.PeakPeak]: '1.3-3',
        [Type.PeakVolToPeak]: '1.3-3',

        [Type.VolVol]: '1.0-0',
        [Type.VolumeStorm]: '1.0-0'
    },
    // #43804 for metric we display 3 decimals for Volume types and 1 decimal for Peak types 
    [UnitOfMeasureType.METRIC]: {
        [Type.PeakPeak]: '1.1-1',
        [Type.PeakVolToPeak]: '1.1-1',

        [Type.VolVol]: '1.3-3',
        [Type.VolumeStorm]: '1.3-3'
    },
    // #43770 we display 3 decimal places for all types on MGD units
    [UnitOfMeasureType.MGD]: {
        [Type.PeakPeak]: '1.3-3',
        [Type.PeakVolToPeak]: '1.3-3',

        [Type.VolVol]: '1.3-3',
        [Type.VolumeStorm]: '1.3-3'
    }
}
