import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-custom-paginator',
    templateUrl: './custom-paginator.component.html',
    styleUrls: ['./custom-paginator.component.scss']
})
export class CustomPaginatorComponent implements OnChanges {
    @Input() currentPage: number = 1;
    @Input() totalPages: number = 1;
    @Input() visiblePagesLimit: number = 5;
    @Output() pageChange = new EventEmitter<number>();

    visiblePages: number[] = [];

    trackByIndex = TrackBy.byIndex;
    ngOnChanges(changes: SimpleChanges) {
        if (changes['currentPage'] || changes['totalPages'] || changes['visiblePagesLimit']) {
            this.calculateVisiblePages();
        }
    }

    calculateVisiblePages() {
        const totalButtons = Math.min(this.visiblePagesLimit, this.totalPages);
        const halfWay = Math.floor(totalButtons / 2);

        let startPage = Math.max(this.currentPage - halfWay, 1);
        let endPage = startPage + totalButtons - 1;

        if (endPage > this.totalPages) {
            endPage = this.totalPages;
            startPage = Math.max(endPage - totalButtons + 1, 1);
        }

        this.visiblePages = Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
        );
    }

    onPageChange(page: number) {
        if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
            this.pageChange.emit(page);
        }
    }

    isFirstPage(): boolean {
        return this.currentPage === 1;
    }

    isLastPage(): boolean {
        return this.currentPage === this.totalPages;
    }
}