import {
    Component,
    ViewEncapsulation,
    Input,
    SimpleChanges,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import {
    WasteWaterBaseInfiltration,
    BaseInfiltrationMethod,
} from 'app/shared/models/sliicer/customer-rainfall-profile';
import * as Utils from './waste-water-settings.utils';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { ChangesAction } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import { UpdatesWidgetService } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'ads-waste-water-settings',
    templateUrl: './waste-water-settings.component.html',
    styleUrls: ['./waste-water-settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WasteWaterSettingsComponent implements OnInit, OnChanges {
    @Input() public customerId: number;
    @Input() public isStudyLocked: boolean;
    @Input() public allowEditing: boolean;
    @Input() public shouldUpdate: boolean;
    @Input() public changesAction: ChangesAction;
    @Input() public originalWastewaterBaseInfiltration: WasteWaterBaseInfiltration;
    @Output() public checkChanges: EventEmitter<string> = new EventEmitter();


    public BaseInfiltrationMethod = BaseInfiltrationMethod;
    // Public variables
    public state: Utils.State;
    public showErrorMessage= false;
    public isPositiveInteger = REGEX_CONFIG.isPositiveInteger;
    public wasteWaterMethodOptions: BaseInfiltrationMethod[] = [
        BaseInfiltrationMethod.PercentMinimum,
        BaseInfiltrationMethod.Mitchell,
        BaseInfiltrationMethod.StevensSchutzbach,
        BaseInfiltrationMethod.WastewaterProduction,
    ];
    public wasteWaterMethodNames = {
        [BaseInfiltrationMethod.PercentMinimum]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MINIMUM',
        [BaseInfiltrationMethod.Mitchell]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MITCHELL',
        [BaseInfiltrationMethod.StevensSchutzbach]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_METHOD',
        [BaseInfiltrationMethod.WastewaterProduction]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_PRODUCTION',
    };

    public wasteWaterMethodDescriptions = {
        [BaseInfiltrationMethod.PercentMinimum]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MINIMUM_DESC',
        [BaseInfiltrationMethod.Mitchell]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_MITCHELL_DESC',
        [BaseInfiltrationMethod.StevensSchutzbach]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_METHOD_DESC',
        [BaseInfiltrationMethod.WastewaterProduction]: 'SLIICER_TABLE.SLICER_SUMMARY.WASTEWATER_PRODUCTION_DESC',
    };

    private constantChanged = false;

    public trackByIndex = TrackBy.byIndex
    constructor(private translate: TranslateService, public updatesWidgetService: UpdatesWidgetService) {}

    public ngOnInit() {
        this.state = Utils.initState(this.originalWastewaterBaseInfiltration);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.shouldUpdate) {
            this.state = Utils.initState(this.originalWastewaterBaseInfiltration);
        } else if (changes.changesAction && this.getChangeValue()) {
            if (this.changesAction.action === 'undoChanges') {
                this.showErrorMessage = false;
                this.constantChanged = false;
                this.state = Utils.initState(this.originalWastewaterBaseInfiltration);
                this.checkChanges.emit('');
            }
        }
    }

    public getChanges(): WasteWaterBaseInfiltration {
        const wasteWaterValue = Utils.toResult(this.state);
        return wasteWaterValue;
    }

    public onSelectMethodChange(value: BaseInfiltrationMethod) {
        // Make sure that we change the constant to the appropriate default if it has
        // not already been changed
        if (value === BaseInfiltrationMethod.PercentMinimum) {
            this.state.constant = this.constantChanged ? this.state.constant : 80;
        } else if (value === BaseInfiltrationMethod.WastewaterProduction) {
            this.state.constant = this.constantChanged ? this.state.constant : 88;
        } else {
            this.state.constant = null;
        }
        this.state.method = value;

        this.checkChanges.emit('');
    }

    public onConstantChange(value: number) {
        this.constantChanged = true;
        this.showErrorMessage = false;
        value = value ? value : 0;
        const minValue = new RegExp(this.isPositiveInteger);
        if (minValue.test(value.toString())) {
            this.state = Utils.constantChanged(value)(this.state);
            this.checkChanges.emit('');
        } else {
            this.showErrorMessage = true;
            this.updatesWidgetService.setUpdatesInfo([], '');
        }
    }

    public getChangeValue(): boolean {
        const originalWasteWater: WasteWaterBaseInfiltration = {
            method: this.originalWastewaterBaseInfiltration.method,
            constant: this.originalWastewaterBaseInfiltration.constant || 0,
        };
        const currentWasteWater: WasteWaterBaseInfiltration = {
            method: this.state.method,
            constant: this.state.constant ? this.state.constant : 0,
        };
        return !_.isEqual(originalWasteWater, currentWasteWater);
    }
}
