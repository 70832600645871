import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { SiltEditorComponent } from 'app/pages/silt-editor/silt-editor.component';
import { LocationDetails } from 'app/shared/models/location-details';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import {
    ChannelList,
    MonitoringPoints,
    PrimarySensors,
    SecondarySensors,
    NoneSensor,
} from '../../location-card.constants';
import { LocationCardService } from '../../services/location-card.service';
import { LocationFormBuilder } from '../../services/location-form-builder';
import { DepthDeviceType, DEVICE_MODE_AUTO_ID, DeviceTypeCode, VelocityDeviceType } from 'app/shared/enums/device-type-code';
import { ChannelElement, LocationCardData, MonitoringPointElement, SensorElement, SensorPreference, TritonMonitor } from '../../models/location-card.model';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-triton-info',
    templateUrl: './triton-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class TritonInfoComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() data: LocationCardData;
    @Input() monitorConfigs: TritonConfiguration;
    @Input() details: LocationDetails[];
    @Output() changeSensor = new EventEmitter();
    @Output() comportChange = new EventEmitter();
    @Output() monitorPointChange = new EventEmitter();
    @Output() sensorRemove = new EventEmitter();

    public primarySensors: SensorElement[] = [NoneSensor, ...JSON.parse(JSON.stringify(PrimarySensors)).sort((a, b) => a.text.localeCompare(b.text))];
    public secondarySensors: SensorElement[] = [NoneSensor, ...JSON.parse(JSON.stringify(SecondarySensors)).sort((a, b) => a.text.localeCompare(b.text))];
    public monitoringPoints: MonitoringPointElement[] = MonitoringPoints;
    public channelList: ChannelElement[] = ChannelList;

    public userHasPermissionOnEdit: boolean;
    public customerHasBasicEditPermission: boolean;
    public siltPlaceholder: string;
    public isMonitorEditor = false;
    public isMetric: boolean;

    public depthMergedPreferences: SensorPreference[] = [];
    public velMergedPreferences: SensorPreference[] = [];

    public depthPreferencesFirst: SensorPreference[] = [];
    public velPreferencesFirst: SensorPreference[] = [];

    public depthPreferencesSecond: SensorPreference[] = [];
    public velPreferencesSecond: SensorPreference[] = [];
    public translations = {
        ch1: '',
        ch2: ''
    }

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private locationFormBuilder: LocationFormBuilder,
        private usersService: UsersService,
        public locationCardService: LocationCardService,
        private translateService: TranslateService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.getMonitors().clearValidators();
        this.getMonitors().setValidators(this.sameSensorsValidator);

        this.isMetric = this.locationCardService.isMetric;
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        this.siltPlaceholder = `${this.translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_SILT')} (${
            this.locationCardService.depthUnit
        })`;
        this.translations.ch1 = this.translateService.instant('COMMON.CH1');
        this.translations.ch2 = this.translateService.instant('COMMON.CH2');

        if (this.data.editMode && this.monitorConfigs && this.monitorConfigs.devices) {
            this.populateForm();
        }
        this.enableDisableSecondSensorValues();
        this.populatePermissions();
        this.checkSensorValidationOnCommportChange();
    }

    // we should not allow 2 same sensors on the same monitoring point
    private sameSensorsValidator(control: FormArray) {
        const [first, second] = control.value;

        // if sensors are picked, no error
        if (first.sensor === null || second.sensor === null) {
            return null;
        }

        // if different monitoring points picked, no error
        if (first.mp !== second.mp) {
            return null;
        }

        const allSensors = [...PrimarySensors, ...SecondarySensors];

        const firstSensor = allSensors.find(v => v.value === first.sensor);
        const secondSensor = allSensors.find(v => v.value === second.sensor);

        if (firstSensor.text === secondSensor.text) {
            return { sameSensors: true };
        }

        return null;
    }

    private populateForm() {
        const sensorsList = [...this.secondarySensors, ...this.primarySensors];
        let devices = this.monitorConfigs.devices.filter((v) => sensorsList.find((i) => v.name === i.value || v.name === i.altValue));

        devices = devices.map((v) => ({ ...v, mp: Number(v.name.match(/(\d)/)[0]) - 1, name: sensorsList.find((i) => v.name === i.value || v.name === i.altValue).value }));
        this.form.patchValue({
            ...this.monitorConfigs,
            monitors: devices.map((v) => ({ ...v, sensor: v.name })),
        });

        const monitors = this.getMonitors();
        this.monitorConfigs.silts.forEach((v, i) => monitors.controls[i].patchValue({ silt: v }));

        const monitorValues = monitors.getRawValue();
        monitorValues.forEach((v: TritonMonitor, i: 0 | 1) => {
            if (v.mp !== null) {
                const device = devices.find((x) => x.name === v.sensor);
                this.sensorChangeEvent(v.sensor, i, v.mp, true, device);
            }
        });

        if (this.details && this.details.length) {
            this.details.forEach((detail: LocationDetails, index: 0 | 1) => {
                const patchPayload: { depthSensor?: DepthDeviceType, velocitySensor?: VelocityDeviceType } = {};

                if (detail.depthSensor !== undefined) {
                    patchPayload.depthSensor = detail.depthSensor;
                }

                if (detail.velocitySensor !== undefined) {
                    patchPayload.velocitySensor = detail.velocitySensor;
                }

                monitors.controls[index].patchValue(patchPayload);
            });
        }

        this.comportChange.emit({ value: this.monitorConfigs.comport });
    }

    private enableDisableSecondSensorValues() {
        const secondSensor = this.getMonitors().controls[1] as FormGroup;
        this.locationFormBuilder.enableDisableControls(secondSensor, !secondSensor.get('sensor').value, [
            'mp',
            'channel',
            'silt',
        ]);

        if (secondSensor.get('sensor').value) {
            secondSensor.get('mp').setValidators(Validators.required);
            secondSensor.get('channel').setValidators(Validators.required);
        }
    }

    isSensorOnSameMp = false;
    public onMpChange(index: number, mp: 0 | 1) {

        const monitors = this.getMonitors();
        const mp1Sensor = monitors.value.find((v) => v.mp === 0);
        const [first, _second] = monitors.controls;
        if (!mp1Sensor && index === 0) {
            first.patchValue({ mp: 0 });

            return;
        }

        this.monitorPointChange.emit({ monitors: monitors.getRawValue(), index, mp });

        if (index !== mp && mp1Sensor) {
            const oldList = mp === 1 ? this.primarySensors : this.secondarySensors;
            const newList = mp === 0 ? this.primarySensors : this.secondarySensors;
            const control = this.getMonitors().controls[index];
            const oldValue = oldList.find((v) => v.value === control.value.sensor);
            control.patchValue({ sensor: newList.find((v) => v.text === oldValue?.text).value });
        }
    }

    public sensorChangeEvent(name: string, monitorId: 0 | 1, mp: number, fromEdit = false, device = null) {
        this.clearSensorPreferences(monitorId);

        if (!name) {
            this.onNoneSensorSelect(monitorId);
            return;
        }

        if (!fromEdit) {
            this.setMpAndChannelDefaultValues(monitorId);
        }
        this.enableDisableSecondSensorValues();
        mp = mp === null || mp === undefined ? monitorId : mp;
        const list = [...this.primarySensors, ...this.secondarySensors];
        const sensor = list.find((v) => v.value === name);

        if (!sensor) return;

        this.setSensorPreferences(monitorId);
        this.changeSensor.emit({ name: sensor.text, monitorId, mp, device });
    }

    public onChannelChange(channel: number, monitorId: 0 | 1) {
        const monitors = this.getMonitors();
        const anotherMonitor = monitors.controls[monitorId === 0 ? 1 : 0];
        const anotherChannel = this.channelList.find((v) => v.value !== channel).value;

        if (anotherMonitor.get('channel').value === channel) {
            anotherMonitor.patchValue({ channel: anotherChannel });
        }

        if(this.getMonitors().length > 1) {
            this.setSensorPreferences(0);
            this.setSensorPreferences(1);
        }
    }

    public getMonitors() {
        return this.form.get('monitors') as FormArray;
    }

    public getSensorList(sensor, index: number) {
        if (sensor.mp === null || sensor.mp === undefined) {
            return index === 0 ? this.primarySensors : this.secondarySensors;
        }

        return sensor.mp === 0 ? this.primarySensors : this.secondarySensors;
    }

    public openSiltEditor(index: number) {
        const data = {
            cid: this.data.customerId,
            lid: this.details[index] ? this.details[index].locationID : 0,
            user: this.usersService.userName,
            locationName: this.data.locationDetails?.locationName,
        };

        const dialogRef = this.dialog.open(SiltEditorComponent, {
            disableClose: true,
            data,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.updated) {
                this.getMonitors().controls[index].patchValue({ silt: result.lastValue.editedValue });
            }
        });
    }

    public onCommPortChange(event: MatSelectChange) {
        this.checkSensorValidationOnCommportChange();
        this.comportChange.emit(event);
    }

    public checkSensorValidationOnCommportChange() {
        const commportValue = this.form.get('comport').value;
        const firstSensor = (this.getMonitors().controls[0] as FormGroup);

        if (commportValue === 'None') {
            firstSensor.get('sensor').setValidators(Validators.required);
            firstSensor.get('mp').setValidators(Validators.required);
            firstSensor.get('channel').setValidators(Validators.required);
        } else {
            firstSensor.get('sensor').clearValidators();
            firstSensor.get('mp').clearValidators();
            firstSensor.get('channel').clearValidators();
            this.form.updateValueAndValidity();
            firstSensor.updateValueAndValidity();
        }
    }

    private setMpAndChannelDefaultValues(monitorId: 0 | 1) {
        const monitors = this.getMonitors();
        const [first, second] = monitors.controls;

        if (monitors.controls[monitorId] && !monitors.controls[monitorId].value.silt) {
            monitors.controls[monitorId].patchValue({ silt: 0 });
        }

        if (first.value.mp === null) {
            first.patchValue({ mp: 0,
              channel: (second.value.channel === null || second.value.channel === undefined || second.value.channel === 2) ? 1 : 2
            });
        }

        if (monitorId === 1 && (second.value.mp === null || second.value.mp === undefined)) {
            second.patchValue({ mp: 1,
              channel: (first.value.channel === null || first.value.channel === undefined || first.value.channel === 1) ? 2 : 1
            });
        }
    }

    private populatePermissions() {
        this.userHasPermissionOnEdit = this.usersService.isBasicDataEditingAllowed.getValue();
        this.customerHasBasicEditPermission = this.usersService.isBasicDataEditingAllowed.getValue();
    }

    private onNoneSensorSelect(monitorId: 0 | 1) {
        this.sensorRemove.emit(monitorId);
        if (monitorId === 0) {
            this.getMonitors().controls[0].patchValue({ mp: null, channel: null });
        } else {
            this.getMonitors().controls[1].patchValue({ mp: null, channel: null, silt: null });
            this.locationFormBuilder.enableDisableControls(this.getMonitors().controls[1] as FormGroup, true, [
                'mp',
                'channel',
                'silt',
            ]);
        }
    }

    private clearSensorPreferences(monitorId: 0 | 1) {
        (this.getMonitors().get(String(monitorId)) as FormGroup).removeControl('depthSensor');
        (this.getMonitors().get(String(monitorId)) as FormGroup).removeControl('velocitySensor');
    }

    private setSensorPreferences(monitorId: 0 | 1) {
        const name = this.getSensorNameForPreference(monitorId);

        if (monitorId === 0) {
            this.depthPreferencesFirst = DeviceTypeCode.getDepthPreferenceBySensor(name);
            this.velPreferencesFirst = DeviceTypeCode.getVelocityPreferenceBySensor(name);
        } else {
            this.depthPreferencesSecond = DeviceTypeCode.getDepthPreferenceBySensor(name);
            this.velPreferencesSecond = DeviceTypeCode.getVelocityPreferenceBySensor(name);
        }

        this.depthMergedPreferences = [];

        const firstMonitorChannel = this.getMonitors()?.get('0')?.get('channel')?.value ?? null;
        const secondMonitorChannel = this.getMonitors()?.get('1')?.get('channel')?.value ?? null;
        const mergeArrays = (inArr1: SensorPreference[], inArr2: SensorPreference[]): SensorPreference[] => {

            const existingAssoc = [];
            const outArr = [];
            let autoOptionPushed = false;

            const mergeSingleArray = (inArr: SensorPreference[], sufix: string) => {
                if(inArr && inArr.length) {
                    for(const p of inArr) {
                        if(p.id === DEVICE_MODE_AUTO_ID) {
                            if(autoOptionPushed) continue;

                            outArr.push(p);
                            autoOptionPushed = true;
                        } else if(!existingAssoc[p.id]) {
                            outArr.push({...p, text: `${p.text} ${sufix}`});
                            existingAssoc[p.id] = true;
                        }
                    }
                }
            }

            const channelFor = (channel: number): string => {
                return channel === 1 ? this.translations.ch1 : channel === 2 ? this.translations.ch2 : '';
            }

            mergeSingleArray(inArr1, channelFor(firstMonitorChannel));
            mergeSingleArray(inArr2, channelFor(secondMonitorChannel));

            return outArr;
        }

        this.depthMergedPreferences = mergeArrays(this.depthPreferencesFirst, this.depthPreferencesSecond);
        this.velMergedPreferences = mergeArrays(this.velPreferencesFirst, this.velPreferencesSecond);

        (this.getMonitors().get(String(monitorId)) as FormGroup).addControl('depthSensor', new FormControl(DepthDeviceType.Auto));

        if (monitorId === 0 && this.velPreferencesFirst) {
            (this.getMonitors().get(String(monitorId)) as FormGroup).addControl('velocitySensor', new FormControl(VelocityDeviceType.Auto));
        } else if (monitorId === 1 && this.velPreferencesSecond) {
            (this.getMonitors().get(String(monitorId)) as FormGroup).addControl('velocitySensor', new FormControl(VelocityDeviceType.Auto));
        }
    }

    private getSensorNameForPreference(monitoId: 0 | 1) {
        const currentMonitor = this.getMonitors().get(String(monitoId));

        const allSensors = [...PrimarySensors, ...SecondarySensors];
        const sensor = allSensors.find(v => v.value === currentMonitor.value.sensor);

        const currentChannelList = currentMonitor.value.channel === 1 ? this.primarySensors : this.secondarySensors;

        return currentChannelList.find(v => v.text === sensor.text).value;
    }
}
