import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { activeInactiveLocationQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { LocationService } from 'app/shared/services/location.service';
import { CommunicationType, InstallationShapeType, LocationType, MonitorConfigLocationModel, MonitorConfigLocGroupModel, MonitorConfigurationDetail, MonitorConfigurationReport, MonitorSeriesModel } from 'app/shared/models/monitorConfiguration';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { LocationEntitiesData, LocationListArgs } from 'app/shared/models/locations-entities-data';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Selectable } from 'app/shared/models/selectable';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SecondsToSampleRatePipe } from 'app/shared/pipes/seconds-to-sample-rate.pipe';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { TrackBy } from 'app/shared/utils/track-by';

const propNamesToTranslation = {
    'LOC_NAME': 'locationName',
    'MP_NUMBER': 'monitoringPointNumber',
    'LOC_TYPE': 'locationType',
    'LOC_SHAPE': 'locationShape',
    'LOC_GROUP': 'locationGroups',
    'MONITOR_SERIES': 'monitorSeries',
    'SERIAL_NUMBER': 'serialNumber',
    'IP_ADDRESS': 'ipAddress',
    'COMM_TYPE': 'communicationType',
    'SENSORS': 'sensors',
    'STATUS': 'isActive',
    'LATITUDE': 'latitude',
    'LONGITUDE': 'longitude',
    'COMPOSITE_LOC': 'compositeLocation',
    'MANHOLE_DEPTH': 'manholeDepth',
    'HEIGHT': 'height',
    'WIDTH': 'width',
    'CAPACITY': 'capacity',
    'SILT': 'silt',
    'HYDRAULIC_COEFF': 'hydraulicCoefficient',
    'VGAIN': 'vgain',
    'RAIN_GAUGE': 'associatedRainGuage',
    'OVERFLOW': 'isOverflow',
    'ANALYSIS_TRIGGER_EVENT': 'analysisTriggerEvent',
    'SAMPLE_RATE': 'sampleRateNormalSeconds',
    'FAST_SAMPLE_RATE': 'sampleRateFastSeconds',
    'NEXT_DATA_COLLECT': 'nextDataCollect',
    'SCHEDULE_INTERVAL': 'scheduledInterval'
};

const alarmPropNameToTranslation = {
    'HIGH_DEPTH_DATE': 'highDepthThresholdActivation',
    'HIGH_DEPTH': 'highDepthThreshold',
    'LOW_DEPTH_DATE': 'lowDepthThresholdActivationDate',
    'LOW_DEPTH': 'lowDepthThreshold',
    'HIGH_HIGH_DATE': 'highHighThresholdActivation',
    'HIGH_HIGH': 'highHighThreshold',
    'HIGH_FLOW_DATE': 'highFlowThresholdActivation',
    'HIGH_FLOW': 'highFlowThreshold',
}

enum FilterTypeEnum {
    Location = 1,
    LocationGroup = 2
}

const dateKeys = new Set([
    'highDepthThresholdActivation',
    'highFlowThresholdActivation',
    'highHighThresholdActivation',
    'lowDepthThresholdActivationDate',
    'nextDataCollect'
]);

@Component({
    selector: 'app-location-configuration-report',
    templateUrl: './location-configuration-report.component.html',
    styleUrls: ['./location-configuration-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationConfigurationReportComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    constructor(private locationService: LocationService, private activatedRoute: ActivatedRoute,
            private changeDetectorRef: ChangeDetectorRef, private uiUtilService: UiUtilsService,
            private locationGroupService: LocationGroupService, private matDialog: MatDialog,
            private translationService: TranslateService, private domOperationUtilsService: DomOperationUtilsService,
            private dateutilService: DateutilService, private secondsToSampleRatePipe: SecondsToSampleRatePipe,
            private datePipe: DatePipe) {}

    private customerId: number;
    private includeInactiveLocations: boolean;

    public reportDetails: MonitorConfigurationDetail[] = [];
    public isLoading: boolean;

    public locGroups: MonitorConfigLocGroupModel[] = [];
    public locations: MonitorConfigLocationModel[] = [];
    private selectedLocGroupId: number;

    public showFilters = false;
    public selectedType: FilterTypeEnum;
    public filterTypesEnum = FilterTypeEnum;

    public filterTypes = [
        { id: FilterTypeEnum.Location, text: 'Location' },
        { id: FilterTypeEnum.LocationGroup, text: 'Location Group' },
    ];
    public totalPaginationLength: number;
    public customDateFormat: string;
    public displayedColumns = [
        'locationName',
        'mpNumber',
        'locType',
        'locShape',
        'locGroup',
        'monitorSeries',
        'serialNumber',
        'ipNumber',
        'commType',
        'sensors',
        'status',
        'latitude',
        'longitude',
        'compositeLocs',
        'manholeDepth',
        'height',
        'width',
        'capacity',
        'silt',
        'hydraulicCoeff',
        'vgain',
        'rainGauge',
        'overflow',
        'analysisTriggerEvent',
        'highDepthActivationDate',
        'highDepth',
        'highHighActivationDate',
        'highHigh',
        'highFlowActivationDate',
        'highFlow',
        'lowDepthActivationDate',
        'lowDepth',
        'sampleRateNormalSeconds',
        'sampleRateFastSeconds',
        'nextCollect',
        'scheduleInterval'
    ];

    private subscriptions: Subscription[] = [];

    public trackById = TrackBy.byId;
    ngOnInit(): void {
        this.subscriptions.push(this.dateutilService.dateFormat.subscribe(() => {
            const dateFormat = this.dateutilService.getFormat();
            const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
            this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;    
        }));

        this.subscriptions.push(this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const newCustomerId = Number(params.get(customerQueryParam));
            const isCustomerChanged = this.customerId !== newCustomerId;

            if (isCustomerChanged) {
                this.selectedType = undefined
                this.selectedLocGroupId = undefined;
                this.locations = [];
            }

            this.customerId = newCustomerId;
            this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)) || 0);

            this.getReportData();
            this.getLocationsData();
            this.getLocationGroups();

            if (this.paginator) {
                this.paginator.firstPage();
            }
        }));
    }

    public filterTypeChange(event: MatSelectChange) {
        this.selectedType = event.value;

        if (this.selectedType === FilterTypeEnum.Location) {
            this.selectedLocGroupId = undefined;
        }
    }

    public locationGroupChanged(event: MatSelectChange) {
        this.paginator.firstPage();

        this.selectedLocGroupId = event.value;
        this.getReportData();
    }

    public locationSelected(event: Selectable[]) {
        this.paginator.firstPage();

        this.locations = event;
        this.getReportData();
    }

    public getLocationGroupText(element: MonitorConfigurationDetail) {
        if (!element.locationGroups || element.locationGroups.length === 0) {
            return 'All';
        }

        return element.locationGroups.map(v => v.name).join(', ');
    }

    public onPaginateChange() {
        this.domOperationUtilsService.scrollToTop('html');
        this.getReportData();
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    private getReportData() {
        this.isLoading = true;

        const { pageIndex, pageSize } = this.paginator || { pageIndex: 0, pageSize: 10 };
        let locationIds: number[];

        if (this.selectedType === FilterTypeEnum.Location) {
            locationIds = this.locations.filter(v => v.isChecked).map(v => v.id);
        }
        this.locationService.getLocationConfigurationReport(
            this.customerId,
            this.selectedLocGroupId,
            this.includeInactiveLocations,
            locationIds,
            pageSize,
            pageIndex + 1           // on UI we count pages starting from 0, however on API side we count them from 1
        ).subscribe((data: MonitorConfigurationReport) => {
            this.isLoading = false;

            if (!data || data.details.length === 0) {
                this.reportDetails = [];
                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);

                return;
            }

            this.reportDetails = data.details;
            this.totalPaginationLength = data.detailsCount;

            this.reportDetails = this.reportDetails.map(v => ({
                ...v,
                locationType: LocationType[v.locationType],
                monitorSeries: v?.externalVendor ? v.externalVendor : MonitorSeriesModel[v.monitorSeries],
                communicationType: CommunicationType[v.communicationType],
                locationShape: InstallationShapeType[v.locationShape]
            }));

            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        }, () => {
            this.isLoading = false;
        }, () => this.isLoading = false);
    }

    private getLocationsData() {
        this.locationService.getLocationData(<LocationListArgs>{
            cid: this.customerId,
            IncludeInactiveLocations: this.includeInactiveLocations
        }).subscribe((entityData: LocationEntitiesData) => {
            if (!entityData) return;

            entityData.l = entityData.l.map((l) => {
                if (l.s === 'Echo') {
                    l.s = 'ECHO';
                }
                return l;
            });

            entityData.d = entityData.d.map((d) => {
                if (d.s === 'Echo') {
                    d.s = 'ECHO';
                }
                return d;
            });

            this.locations = entityData.l.map(v => ({ id: v.lid, name: v.n, isChecked: true }));
        });
    }

    private getLocationGroups() {
        this.locationGroupService.getLocationGroups(this.customerId).subscribe((data) => {
            if (typeof data === 'string') {
                this.locGroups = [];
                return;
            }

            this.locGroups = data.locationGroups.map(v => ({
                name: v.name,
                id: v.locationGroupID,
                locations: v.locations.map(i => ({ id: i.locationID, name: i.name }))
            }));

            this.locGroups.unshift({ name: 'All', id: 0 });
        });
    }

    public download() {
        const title = this.translationService.instant('REPORT.MONITOR_CONFIGURATION.TITLE');
        const message = this.translationService.instant('REPORT.MONITOR_CONFIGURATION.DOWNLOAD_TEXT');
        const confirmText = this.translationService.instant('COMMON.CONFIRM_BTN');
        const cancelText = this.translationService.instant('COMMON.CANCEL');

        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: title,
                    message: message,
                    okText: confirmText,
                    cancelText: cancelText,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed !== 'ok') {
                    return;
                }

                this.getReportAndDownloadCsv();
            });

    }

    private getReportAndDownloadCsv() {
        let locationIds: number[];

        if (this.selectedType === FilterTypeEnum.Location) {
            locationIds = this.locations.filter(v => v.isChecked).map(v => v.id);
        }

        this.locationService.getLocationConfigurationReport(
            this.customerId,
            this.selectedLocGroupId,
            this.includeInactiveLocations,
            locationIds,
            this.totalPaginationLength, 1
        ).subscribe((data: MonitorConfigurationReport) => {
            const csvExportData = [];

            const title = this.translationService.instant('REPORT.MONITOR_CONFIGURATION.TITLE');
            const headers = this.translationService.instant('REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS');
            const csvHeaders = Object.keys(propNamesToTranslation).map(v => headers[v]);
            Object.keys(alarmPropNameToTranslation).forEach(v => csvHeaders.push(headers[v]));

            const valueKeys = Object.values(propNamesToTranslation);
            const alarmKeys = Object.values(alarmPropNameToTranslation);

            const reportDetails = data.details.map(v => ({
                ...v,
                locationType: LocationType[v.locationType],
                monitorSeries: MonitorSeriesModel[v.monitorSeries],
                communicationType: CommunicationType[v.communicationType],
                locationShape: InstallationShapeType[v.locationShape]
            }));

            if (reportDetails) {
                reportDetails.forEach((report, i) => {
                    const values = valueKeys.map((key: string) => {
                        if (key === 'locationGroups') {
                            return report[key].length > 0 ? report[key].map(v => v.name).join(', ') : 'All';
                        } else if (key === 'isActive') {
                            return report[key] ? 'Active' : 'Inactive'
                        } else if (key === 'compositeLocation' || key === 'isOverflow') {
                            return report[key] ? 'Yes' : 'No';
                        } else if (key === 'monitorSeries') {
                            report[key] = report['externalVendor'] ? report['externalVendor'] : report[key]
                        } else if (['sampleRateNormalSeconds', 'sampleRateFastSeconds'].includes(key)) {
                            report[key] = report[key] ? this.secondsToSampleRatePipe.transform(report[key]) : report[key]
                        } else if (report[key] && dateKeys.has(key)) {
                            report[key] = this.transformDateToCustomerFormat(report[key]);
                        }

                        return report[key];
                    }).map(v => v === undefined ? '--' : v);

                    alarmKeys.forEach((key: string) => {
                        let value: string;

                        if (!report.alarms || !report.alarms[key]) {
                            value = '--';
                        } else if (dateKeys.has(key)) {     // convert date values
                            value = this.transformDateToCustomerFormat(report.alarms[key]);
                        } else {
                            value = report.alarms[key];
                        }

                        values.push(value);
                    });

                    csvExportData.push(values);
                });
            }
            const options = {
                showLabels: true,
                headers: csvHeaders,
                title,
                showTitle: true,
            };

            // download to csv
            const exported = new AngularCsv(csvExportData, title, options);
        });

    }

    private transformDateToCustomerFormat(date: Date | string) {
        return this.datePipe.transform(date, this.customDateFormat);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(v => v.unsubscribe());
    }
}
