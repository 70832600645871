<div #dialog id="compositeLocationDialog">
    <div
        class="popupHeader"
        mat-dialog-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragBoundary=".cdk-overlay-container"
        (cdkDragEnded)="dragEvent()"
    >
        <span>{{ locationLabel }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <form #addCompositeLocationForm="ngForm">
        <!-- <app-loader [isLoading]="isLoadingState"></app-loader> -->

        <div mat-dialog-content>
            <div class="custom-error">
                <mat-form-field class="full-width top">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.LOCATION_NAME' | translate }}"
                        [(ngModel)]="compositeLocationName"
                        name="compositeLocationName"
                        [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                        required
                        maxlength="63"
                        #compositeLocationNameInput="ngModel"
                        (input)="validateDuplicateLocationName($event.target.value)"
                    />
                </mat-form-field>
                <div class="custom-error-message danger">
                    <span [hidden]="isValidLocationName"
                        >{{ 'COMMON.LOCATION_NAME' | translate }} {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                    >
                    <span *ngIf="compositeLocationNameInput.hasError('pattern')">{{
                        'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate
                    }}</span>
                </div>
            </div>
            <div class="custom-error">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'HOME.MAP.COMPOSITE_LOCATION.LOCATION_DESCRIPTION_PLACEHOLDER' | translate }}"
                        #AddressInput="ngModel"
                        [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                        name="Address"
                        [(ngModel)]="compositeLocationAddress"
                    />
                </mat-form-field>
                <div class="custom-error-message danger" *ngIf="AddressInput?.errors?.pattern">
                    {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}
                </div>
            </div>
            <div class="custom-50p custom-error">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matTooltip="Latitude must be between -90 and 90 degrees with maximum 8 Decimal precision"
                            [matTooltipShowDelay]="1000"
                            matInput
                            placeholder="{{ 'COMMON.LATITUDE_LABEL' | translate }}"
                            name="Latitude"
                            [(ngModel)]="compositeLocationAddressLatitude"
                            #LatitudeInput="ngModel"
                            [pattern]="latitudePattern"
                        />
                    </mat-form-field>
                </div>
                <div class="custom-error each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matTooltip="Longitude must be between -180 and 180 degrees with maximum 8 Decimal precision"
                            [matTooltipShowDelay]="1000"
                            matInput
                            placeholder="{{ 'COMMON.LONGITUDE_LABEL' | translate }}"
                            name="Longitude"
                            [(ngModel)]="compositeLocationAddressLongitude"
                            #longitudeInput="ngModel"
                            [pattern]="longitudePattern"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="custom-error-message danger"
                    *ngIf="LatitudeInput?.errors?.pattern || longitudeInput?.errors?.pattern"
                >
                    {{ 'COMMON.LONGITUDE_ERROR' | translate }}
                </div>
                <img class="move-icon" [hidden]="!enableSelectOnMap" (click)="onMoveIconClick()" 
                    [ngSrc]="'/assets/images/icons/move_icon.png'" height="18" width="18" />
            </div>
            <div class="pad-b-20">
                <mat-checkbox name="active" [(ngModel)]="activeLocation">
                    <strong>{{ 'COMMON.ACTIVE_CHECKBOX' | translate }}</strong>
                </mat-checkbox>
            </div>
            <div class="pad-b-20">{{ 'HOME.MAP.COMPOSITE_LOCATION.ADD_LOCATIONS_TEXT' | translate }}</div>

            <div
                class="compositeLocationLocationList"
                *ngFor="let num of numberOfTimes; let i = index; trackBy: trackByIndex"
                [attr.data-index]="i"
            >
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            type="text"
                            placeholder="{{ 'COMMON.LOCATION_NAME' | translate }}"
                            id="locationName{{ i }}"
                            #LocationName[i]="ngModel"
                            [(ngModel)]="locationNameLoc[i]"
                            name="locationName{{ i }}"
                            [attr.id]="locationName[i]"
                            matInput
                            [matAutocomplete]="auto"
                            (ngModelChange)="changeLocationDropdown($event, i)"
                            [isShowAll]="false"
                            required
                        />
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
                            <mat-option *ngFor="let option of selectableLocations; trackBy: trackByIndex" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="mat-error danger">
                        <span *ngIf="isLocationAdded[i]" class="mat-error">{{
                            'HOME.MAP.COMPOSITE_LOCATION.LOCATION_ALREADY_SELECTED' | translate
                        }}</span>
                        <span *ngIf="!isLocationSearched[i]" class="mat-error">{{
                            'HOME.MAP.COMPOSITE_LOCATION.SELECT_VALID_LOCATION' | translate
                        }}</span>
                    </div>
                </div>

                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            (ngModelChange)="changeFlowDropdown($event, i)"
                            tabindex="-1"
                            id="FlowDirection"
                            #FlowDirection="ngModel"
                            [(ngModel)]="flowId[i]"
                            name="flowLoc{{ i }}"
                            [attr.id]="flowLoc[i]"
                            ngDefaultControl
                            required
                        >
                            <mat-option *ngFor="let flow of flowTypes; trackBy: trackById" [value]="flow.id">
                                {{ flow.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            (input)="validateFormMultiplierValues()"
                            matInput
                            type="number"
                            placeholder="{{ 'HOME.MAP.COMPOSITE_LOCATION.MULTIPLIER_PLACEHOLDER' | translate }}"
                            tabindex="-1"
                            name="multiplier"
                            id="Multiplier"
                            #Multiplier="ngModel"
                            [(ngModel)]="multiplierLoc[i]"
                            [attr.id]="'Multiplier' + multiplierLoc[i]"
                            name="multiplierLoc{{ i }}"
                            [pattern]="maxScaleValuePattern"
                            maxlength="25"
                            required
                        />
                    </mat-form-field>
                    <!-- <div class="mat-error" *ngIf="Multiplier?.errors?.pattern">{{'COMMON.COMPOSITE_NUMERIC_PRECISION_ERROR' | translate}}</div> -->
                    <div
                        class="mat-error danger"
                        *ngIf="Multiplier?.errors?.pattern || Multiplier?.value < 0.01 || Multiplier?.value > 25"
                    >
                        {{ 'COMMON.COMPOSITE_NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <button type="button" mat-button class="lightBackGround" (click)="remveLocation(i)">
                    <mat-icon class="mat-24">delete</mat-icon>
                </button>
            </div>
            <div class="right-side-button">
                <div class="auto-margin" *ngIf="isCompositeLocationCountInvalid">
                    <span class="mat-error danger">{{
                        'HOME.MAP.COMPOSITE_LOCATION.SELECT_ATLEAST_LOCATION_MSG' | translate
                    }}</span>
                </div>
                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="addMoreLocation()">{{ 'COMMON.ADD_MORE' | translate }}</button>
                </div>
            </div>
        </div>
    </form>

    <div mat-dialog-actions cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" (cdkDragEnded)="dragEvent()">
        <app-loader [isLoading]="addCompositeLocationLoadingState"></app-loader>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitAddCompositeLocation(false)">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button
                mat-button
                [disabled]="
                    invalidMultiplier ||
                    !addCompositeLocationForm.valid ||
                    addCompositeLocationForm.pristine ||
                    isDuplicateLocationSelected ||
                    numberOfEntries < 2 ||
                    !isValidLocationSelected ||
                    isLoadingState ||
                    !isValidLocationName
                "
                (click)="addCompositeLocation()"
            >
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
