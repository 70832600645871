<div *ngIf="hasRainData" class="max-return-container mat-elevation-z4">
    <mat-table [dataSource]="rainEvents">
        <ng-container matColumnDef="rainfallDate">
            <mat-header-cell [ngClass]="'w-150'" *matHeaderCellDef>
                {{ 'SLIICER.RAINFALL_TAB.RAINFALL_EVENTS.RAIN_DATE' | translate }}
            </mat-header-cell>
            <mat-cell [ngClass]="'w-150'" *matCellDef="let element">
                {{ element.rainfallDate | date: dateFormat }}
            </mat-cell>
        </ng-container>
        <ng-container *ngFor="let key of rainfallMonitors; index as i; trackBy: trackByIndex">
            <ng-container matColumnDef="{{ key }}">
                <mat-header-cell [ngClass]="'padded'" *matHeaderCellDef>{{ key }}</mat-header-cell>
                <mat-cell [ngClass]="'padded'" *matCellDef="let element">{{ element[key] }}</mat-cell>
            </ng-container>
        </ng-container>
        <mat-header-row *matHeaderRowDef="allColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: allColumns"></mat-row>
    </mat-table>
</div>
