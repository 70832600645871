import { OnInit, Component, Input } from '@angular/core';
import { REGEX_CONFIG } from '../../../../utils/regex-utils';
import { FormGroup } from '@angular/forms';
import {
    TritonSensorPower,
    TritonSensorTemp,
    VelocityList,
} from 'app/shared/components/location-card/location-card.constants';
import { LocationFormBuilder } from 'app/shared/components/location-card/services/location-form-builder';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { TranslateService } from '@ngx-translate/core';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { TrackBy } from 'app/shared/utils/track-by';

const ECHO_GAIN_VALUE = 840;

@Component({
    selector: 'app-add-edit-location-long-range-depth-triton-sensor',
    templateUrl: './add-triton-location-long-range-depth-triton-sensor.component.html',
    styleUrls: ['./add-triton-location-long-range-depth-triton-sensor.component.scss'],
})
export class AddTritonLocationLongRangeDepthTritonSensorComponent implements OnInit {
    // Reg Ex. Pattern for numeric -+ Decimal value 2Place
    public negativeWithTwoDecimalPattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public numeric1_24Pattern = REGEX_CONFIG.numeric1_24Pattern;

    @Input() public monitoringPoint: number;
    @Input() public index: 0 | 1;
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;
    public sensorTemp = TritonSensorTemp;
    public sensorPower = TritonSensorPower(true);
    public gainControl = VelocityList;

    public invalidSubmersionThreshold: boolean;
    public isGainControlManualTriton: boolean;
    public invalidManualGain: boolean;
    public isMetric: boolean;
    public gainValues: number[] = [];
    public trackByValue = TrackBy.byValue();
    public trackByIndex = TrackBy.byIndex;
    constructor(
        private locationFormBuilder: LocationFormBuilder,
        public locationCardService: LocationCardService,
        public translateService: TranslateService,
    ) {}

    public ngOnInit() {
        this.isMetric = this.locationCardService.isMetric;
        const formValue = this.form.getRawValue();
        formValue.gainControlSelection =
            !formValue.gainControl || formValue.gainControl === 840
                ? this.gainControl[0].text
                : this.gainControl[1].text;
        if (this.monitorConfigs) {
            const tempRounder = this.isMetric ? 100 : 10;   // for temprature values we allow 2 decimals for Metric and 1 for non metric
            const offsetRounder = this.isMetric ? 10 : 100; // for offset values we allow 1 decimal for Metric and 2 for non metric

            formValue.compensatedTemperatureMarch = Math.round(formValue.compensatedTemperatureMarch * tempRounder) / tempRounder;
            formValue.compensatedTemperatureSeptember = Math.round(formValue.compensatedTemperatureSeptember * tempRounder) / tempRounder;

            formValue.electronicOffset = Math.round(formValue.electronicOffset * offsetRounder) / offsetRounder;
            formValue.blanking = Math.round(formValue.blanking * offsetRounder) / offsetRounder;
            formValue.physicalOffset = Math.round(formValue.physicalOffset * offsetRounder) / offsetRounder;

            this.form.patchValue({ ...formValue });
        }

        for(let i = -24; i <= 24; i ++) {
            this.gainValues.push(i);
        }
    }

    public onChangeGainControlTriton(event: MatSelectChange) {
        const { value } = event;

        this.isGainControlManualTriton = value === this.gainControl[0].text;
        if (value === this.gainControl[1].text) {
            this.form.patchValue({ gainControl: ECHO_GAIN_VALUE });
        } else {
            this.onChangeManualGainTriton(this.form.get('gainControl').value);
        }
    }

    public onChangeSubmersionThreshold() {
        const surchargeThreshold = this.form.get('surchargeThreshold').value;

        this.invalidSubmersionThreshold = surchargeThreshold && (surchargeThreshold < 70 || surchargeThreshold > 99);
    }

    public onChangeManualGainTriton(value: number) {
        if (value === ECHO_GAIN_VALUE) {
            value = null;
        }

        this.form.patchValue({ gainControl: value });
        this.invalidManualGain = this.isGainControlManualTriton && (value < -12 || value > 12);
    }

    public onChangePowerValue(event: MatSelectChange) {
        const { value } = event;
        this.form.patchValue({ power: value === this.sensorPower[0].text ? 0 : Number(value) });
    }

    public onToggleChange(event: MatSlideToggleChange, keys: string[]) {
        this.locationFormBuilder.enableDisableControls(this.form, !event.checked, keys);
    }
}
