<div id="customerFeatureDialog">
    <div>
        <div class="popupHeader" mat-dialog-title>
            <span>{{ 'ADMIN.CUSTOMER_FEATURES.TITLE' | translate }}</span>
            <span class="app-flex-filler"></span>
        </div>

        <form #customerFeatureForm="ngForm" novalidate>
            <div mat-dialog-content>
                <div class="assignEachFeature" *ngFor="let feature of featureList; trackBy: trackById">
                    <mat-checkbox
                        [checked]="feature.isChecked"
                        name="feature"
                        (change)="featureChangeHandler($event, feature)"
                        ><strong>{{ feature.name }}</strong></mat-checkbox
                    >
                    <div class="assignDescription">{{ feature.desc }}</div>
                </div>
            </div>
        </form>

        <div mat-dialog-actions>
            <app-loader [isLoading]="customerFeatureLoadingState"></app-loader>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="saveCustomerAssignedFeatures()" [disabled]="!isFeatureSelected">
                    {{ 'COMMON.APPLY_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
