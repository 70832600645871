
<div class="hydrograph-legend highcharts-legend">
    <div>
        <table *ngIf="isDataQuality">
            <tr>
                <td *ngFor="let item of dataQualityItems; trackBy: trackByIndex" [ngStyle]="{'background-color': item.color}" class="colorbox"></td>
            </tr>
            <tr>
                <td *ngFor="let item of dataQualityItems; trackBy: trackByIndex" class="textbox">{{item.value}}</td>
            </tr>
        </table>

        <div *ngIf="!isDataQuality">
            <div class="legendcontainer">
                <div *ngIf="legendEventDefinition?.length">
                    <div class="legendentities">
                        <div *ngFor="let item of legendEventDefinition; trackBy: trackByIndex" (click)="changeEventVisibility(item.etype)" class="mouse-pointer" >
                            <span *ngIf="item.visible" class="circle-active"
                                [ngStyle]="{'background-color': item.color}"></span>
                            <span *ngIf="!item.visible" class="circle-inactive"
                                [ngStyle]="{'border-color': item.color}"></span>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="legendEventDefinition?.length" class="separator"></div>
                <div class="legendentities">
                    <div *ngFor="let item of legendSeriesDefinition; trackBy: trackByIndex" (click)="changeSeriesVisibility(item.name, item.entityId)" class="mouse-pointer" >
                        <span *ngIf="item.visible" class="box-active"
                            [ngStyle]="{'background-color': item.color}"></span>
                        <span *ngIf="!item.visible" class="box-inactive"
                            [ngStyle]="{'border-color': item.color}"></span>
                        <span>{{item.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

