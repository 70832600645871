<div id="gis-layers">
    <mat-card-header>
        <div class="search-section auto-margin">Available Services:</div>
        <div class="app-flex-filler"></div>
    </mat-card-header>
    <mat-card-content>
        <!-- GIS layer table -->
        <div id="gispagetable">
            <mat-table #table [dataSource]="gisDataSource" matSort>
                <!-- GIS layer table column 1 -->
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>Service Type</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ mapTypeDisplayNames[element.serviceType] }}</mat-cell>
                </ng-container>
                <!-- GIS layer table column 2 -->
                <ng-container matColumnDef="url">
                    <mat-header-cell *matHeaderCellDef>Service Url</mat-header-cell>
                    <mat-cell class="description-space" *matCellDef="let element">{{ element.url }} </mat-cell>
                </ng-container>
                <!-- GIS layer table edit icon -->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-right">
                        <mat-icon matTooltip="Edit" class="edit-cus-gis-edit-hint" (click)="editGISService(element)">mode_edit</mat-icon>
                        <mat-icon matTooltip="Delete" (click)="deleteGISService(element.serviceId)">delete</mat-icon>
                    </mat-cell>
                </ng-container>
                <!-- GIS layer table delete icon -->

                <mat-header-row *matHeaderRowDef="gisDescriptionColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: gisDescriptionColumns"></mat-row>
            </mat-table>
        </div>
    </mat-card-content>
    <!-- GIS layer table add location icon -->
    <mat-card-actions>
        <div class="auto-margin">
            <button
                mat-button
                class="edit-cus-gis-add-hint"
                color="accent"
                matTooltip="{{ 'CUSTOMER_EDITOR.GIS_ADD_LAYER' | translate }}"
                matTooltipPosition="above"
                (click)="addNewGISService()"
            >
                Add More
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="paginator" *ngIf="false"></div>
        <mat-paginator *ngIf="false" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
    </mat-card-actions>

    <!-- Card Action for Saving Detail -->
    <mat-card-actions>
        <div class="auto-margin action-right">
            <button
                mat-button
                color="accent"
                matTooltip="{{ 'CUSTOMER_EDITOR.SAVE_GIS_SETTINGS' | translate }}"
                matTooltipPosition="above"
                (click)="saveGISLayerMappingData()"
            >
                Save
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="paginator" *ngIf="false"></div>
        <mat-paginator *ngIf="false" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
    </mat-card-actions>
</div>
