import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject,
    Directive,
    Input,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
// import { AuthService, SnackBarNotificationService } from 'app/shared/services';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { AuthService } from 'app/shared/services/auth.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TrackBy } from 'app/shared/utils/track-by';
const SUCCESS_MESSAGE = 'Your password has been reset successfully. Please login again.';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['change-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
    public newPassword: string;
    public confirmPassword: string;
    public userName: string;
    public emailId: string;
    public emailTokenCode: string;
    public isRepetitive: boolean;
    public isRepetitiveConfirmPassword: boolean;
    public isNotSamepassword: boolean;
    public hide = true;
    public isLoading: boolean;
    public resetErrorMessage: boolean;
    public dismissText = 'Dismiss';
    public validRecaptcha: boolean;
    public validUserDetails: boolean;
    public validEmailTokenCode: boolean;
    public token: string;
    public errorMessage: string;
    public isResetDisabled: boolean;
    public isForgotPassword: boolean;
    public showUserInfo: boolean;
    public showChangePassword: boolean;
    public showVerificationCode: boolean;
    public googleReCaptchaResponse: string;

    // Reg Ex. Pattern for email validation
    public emailPattern = REGEX_CONFIG.strictEmailPattern;
    public containLowercasePattern = REGEX_CONFIG.containLowercasePattern;
    public containUppercasePattern = REGEX_CONFIG.containUppercasePattern;
    public containDigitPattern = REGEX_CONFIG.containDigitPattern;
    public containNonWordPattern = REGEX_CONFIG.containNonWordPattern;

    public Array = Array;

    public newPasswordformErrors = {
        containLowercasePattern: false,
        containUppercasePattern: false,
        containDigitPattern: false,
        containNonWordPattern: false,
    };

    public trackByIndex = TrackBy.byIndex;
    constructor(
        public resetDialog: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private snackBarNotificationService: SnackBarNotificationService,
    ) {}

    public ngOnInit() {
        if (this.data) {
            this.isForgotPassword = this.data.isForgotPassword;
            this.userName = this.data.userName;
            this.emailId = this.data.emailId;
            this.showUserInfo = this.isForgotPassword;
            this.uiUtilsService.safeChangeDetection(this.cdr);

            //   // TODO: DEBUG: REMOVE:
            //   this.showVerificationCode = false;
            //   this.showChangePassword = true;
            //   this.showUserInfo = false;
        }
    }
    public resolved(captchaResponse: string) {
        this.googleReCaptchaResponse = captchaResponse;
        this.validRecaptcha = true;
        this.validateUserDetails();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    /**
     * Used to cancel reset pop up
     */
    public cancel(permisionsUpdated?: boolean) {
        this.resetDialog.close({ success: permisionsUpdated });
    }
    /**
     * Used to clear error message
     */
    public clearError() {
        this.resetErrorMessage = false;
    }

    public validateEmailTokenCode() {
        this.validEmailTokenCode = this.emailTokenCode.length === 6;
    }

    public validateUserDetails() {
        if (this.emailId && !new RegExp(this.emailPattern).test(this.emailId)) {
            this.validUserDetails = false;
            return;
        }
        if (this.userName || (this.emailId && new RegExp(this.emailPattern).test(this.emailId))) {
            this.validUserDetails = true;
        } else {
            this.validUserDetails = false;
        }
    }
    public validateUser() {
        if (this.userName || this.emailId) {
            this.isLoading = true;
            this.authService.nonAuthGenerateToken(this.userName, this.emailId, this.googleReCaptchaResponse).subscribe(
                (response) => {},
                (error) => {
                    this.isLoading = false;
                    this.redirect(error.status);
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );
            this.showUserInfo = false;
            this.showVerificationCode = true;
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    public verificationCode() {
        this.showVerificationCode = false;
        this.showChangePassword = true;
    }

    validatePassword(): boolean {
        this.newPasswordformErrors = {
            containUppercasePattern: false,
            containLowercasePattern: false,
            containDigitPattern: false,
            containNonWordPattern: false,
        };

        this.newPasswordformErrors.containLowercasePattern = new RegExp(this.containLowercasePattern).test(
            this.newPassword,
        );
        this.newPasswordformErrors.containUppercasePattern = new RegExp(this.containUppercasePattern).test(
            this.newPassword,
        );
        this.newPasswordformErrors.containDigitPattern = new RegExp(this.containDigitPattern).test(this.newPassword);
        this.newPasswordformErrors.containNonWordPattern = new RegExp(this.containNonWordPattern).test(
            this.newPassword,
        );

        return this.isValidPassword();
    }

    public redirect(code) {
        if (code === 404) {
            const message = 'User name or Email address could not be found.';
            this.snackBarNotification(message, 10000, '', false);
            this.cancel(false);
        } else if (code === 449) {
            const message = 'Its a microsoft user.';
            this.snackBarNotification(message, 10000, '', false);
            window.location.href = 'https://login.live.com/';
        } else if (code === 400) {
            const message = 'User is not active.';
            this.snackBarNotification(message, 10000, '', false);
            this.cancel(false);
        }
    }

    public snackBarNotification(message: string, duration, notificationClass, dismiss = true) {
        this.snackBarNotificationService.raiseNotification(message, this.dismissText, {
            panelClass: notificationClass,
        }, dismiss);
    }

    public passwordChange() {
        this.validatePassword();
        this.confirmPasswordVerification();
    }

    public confirmPasswordVerification() {
        this.isNotSamepassword = !(this.newPassword === this.confirmPassword);
    }

    public isValidPassword(): boolean {
        return (
            this.newPasswordformErrors.containUppercasePattern &&
            this.newPasswordformErrors.containLowercasePattern &&
            this.newPasswordformErrors.containDigitPattern &&
            this.newPasswordformErrors.containNonWordPattern
        );
    }

    /**
     * Used to reset password
     */
    public nonAuthResetPassword() {
        const isValid = this.validatePassword();

        if (!isValid) return;

        this.isLoading = true;
        if (this.newPassword === this.confirmPassword && this.emailTokenCode.length === 6) {
            this.changePassword(this.emailTokenCode, this.userName, this.emailId, this.confirmPassword);
        } else {
            this.isNotSamepassword = true;
            this.isLoading = false;
        }
    }

    public changePassword(token, username, email, confirmPassword) {
        this.resetErrorMessage = false;
        this.errorMessage = '';

        this.isLoading = true;
        this.authService.nonAuthResetPassword(token, username, email, confirmPassword).subscribe(
            (res) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.cancel();
                // snack bar message
                this.snackBarNotification(SUCCESS_MESSAGE, 10000, '');
            },
            (error) => {
                this.isLoading = false;

                if (error.status === 400 || (error.error && error.error.length > 0)) {
                    this.resetErrorMessage = true;
                    if (error.error === 'Invalid Token') {
                        this.errorMessage = 'Invalid verification code.';
                    } else {
                        this.errorMessage = error.error.filter((x) => x.isError).map((x) => x.message);
                    }
                }

                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    public resetPassword() {
        this.isLoading = true;
        if (this.newPassword === this.confirmPassword) {
            this.userName = this.data.userName;
            this.emailId = this.data.emailId;
            this.authService.generateToken().subscribe(
                (response) => {
                    if (response) {
                        this.token = response.toString();
                        this.authService.resetPassword(this.token, this.userName, this.emailId, this.confirmPassword).subscribe(
                            (res) => {
                                this.isLoading = false;
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                                this.cancel();
                                this.snackBarNotification(SUCCESS_MESSAGE, 10000, '');
                            },
                            (error) => {
                                this.isLoading = false;

                                if (error.status === 400 || (error.error && error.error.length > 0)) {
                                    this.resetErrorMessage = true;
                                    if (error.error === 'Invalid Token') {
                                        this.errorMessage = 'Invalid verification code.';
                                    } else {
                                        this.errorMessage = error.error.filter((x) => x.isError).map((x) => x.message);
                                    }
                                }
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                            },
                        );
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.resetErrorMessage = true;
                    this.errorMessage = error.error;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );
        } else {
            this.isNotSamepassword = true;
            this.isLoading = false;
        }
    }
}
