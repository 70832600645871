import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BlockagePredictService } from '../blockage-predict.service';
import { FormControl } from '@angular/forms';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ADMIN_DATE_FORMAT, BPReportCustomer, HumanReviewDialogData } from 'app/shared/models/blockage-prediction';
import { MatDatetimepickerInputEvent } from '@mat-datetimepicker/core';
import { MatSort, Sort } from '@angular/material/sort';
import { debounceTime, Subscription } from 'rxjs';
import { BPModeFormat } from 'app/shared/pipes/bp-mode.pipe';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HumanReviewReportDialog } from '../hr-report-dialog/hr-report-dialog.component';
import { TrackBy } from 'app/shared/utils/track-by';

function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

@Component({
    selector: 'app-bp-daily-summary',
    templateUrl: './bp-daily-summary.component.html',
    styleUrls: ['./bp-daily-summary.component.scss'],
    providers: [DatePipe, BPModeFormat],
})

export class BPDailySummaryComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) public matPaginator: MatPaginator;
    @ViewChild(MatSort) public matSort: MatSort;
    public searchString = new FormControl('');
    public customerDateFormat: string;
    public bpDateFormat: string = ADMIN_DATE_FORMAT;
    public reportDate: Date = new Date(new Date().setDate(new Date().getDate() - 1));
    public today: Date = new Date();
    isLoading: boolean = false;

    public synopsisData: {
        percent: string;
        total: string;
        name: string;
        className: string;
    }[];

    private getReportDateFormat = 'yyyy-MM-dd';
    public dataColumns = ['customerName', 'locationName', 'cid', 'lid', 'series', 'pipeInfo', 'bpScore', 'bpFlag', 'bpType', 'bpDate', 'actions'];
    public dataSource: MatTableDataSource<BPReportCustomer> = new MatTableDataSource();
    public originalData: BPReportCustomer[] = [];
    public paginationConfig = {
        pageSize: 100,
        pageIndex: 0,
        length: 0
    }

    private subscriptions: Subscription[] = [];
    public trackByIndex = TrackBy.byIndex;
    constructor(
        private bpService: BlockagePredictService,
        private dateutilService: DateutilService,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private bpModePipe: BPModeFormat,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(this.dateutilService.dateFormat.subscribe(() => {
            this.customerDateFormat = this.dateutilService.getFormat();
        }));

        this.subscriptions.push(
            this.searchString.valueChanges.pipe(
                debounceTime(500),
            ).subscribe((value: string) => {
                if (this.matPaginator) {
                    this.matPaginator.firstPage();
                }
                this.paginationConfig.pageIndex = 0;
                this.getCurrentData();
            })
        );

        this.getReport();
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.matPaginator;

        this.dataSource.sort = this.matSort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'bpScore':
                    return item.bpscore;
                case 'bpFlag':
                    return item.bpflag;
                case 'bpType':
                    return item.bptype;
                case 'bpDate':
                    return new Date(item.bpdate);
                default:
                    return item[property];
            }
        };
    }

    setDate(event: MatDatetimepickerInputEvent<Date>) {
        if (!event || !event.value) {
            return;
        }

        const newDate = new Date(event.value);

        if (newDate.getFullYear() === this.reportDate.getFullYear() &&
            newDate.getMonth() === this.reportDate.getMonth() &&
            newDate.getDate() === this.reportDate.getDate()) {
            return; // Dates are the same, do nothing
        }

        if (this.matPaginator) {
            this.matPaginator.firstPage();
        }

        this.paginationConfig.pageIndex = 0;

        this.reportDate = new Date(event.value);
        this.getReport();
    }

    clearSearch() {
        if (this.matPaginator) {
            this.matPaginator.firstPage();
        }
        this.paginationConfig.pageIndex = 0;
    
        this.searchString.setValue('');
        this.getCurrentData();
    }
    
    getReport() {
        const date = this.datePipe.transform(this.reportDate, this.getReportDateFormat);

        this.synopsisData = [];
        this.originalData = [];
        this.dataSource.data = [];
        this.isLoading = true;
        this.bpService.getBPdailyData(date).subscribe({
            next: data => {
                this.isLoading = false;
                if (!data) {
                    return;
                }

                this.synopsisData.push({
                    percent: data.flaggedPercentText || '-',
                    total: data.flaggedText || '-',
                    name: this.translate.instant('BLOCKAGE_PREDICT.FLAGGED_SITES'),
                    className: 'flagged-sites'
                });

                this.synopsisData.push({
                    percent: data.didNotRunPercentText || '-',
                    total: data.didNotRunText || '-',
                    name: this.translate.instant('BLOCKAGE_PREDICT.DID_NOT_RUN'),
                    className: 'did-not-run'
                });

                this.synopsisData.push({
                    percent: data.successfulPercentText || '-',
                    total: data.successfulText || '-',
                    name: this.translate.instant('BLOCKAGE_PREDICT.SUCCESSFUL_BP_EVAL'),
                    className: 'successful-eval'
                });

                this.synopsisData.push({
                    percent: data.noDataPercentText || '-',
                    total: data.noDataText || '-',
                    name: this.translate.instant('BLOCKAGE_PREDICT.MISSING_EVAL_DATA'),
                    className: 'missing-eval'
                });

                this.synopsisData.push({
                    percent: data.processingErrorPercentText || '-',
                    total: data.processingErrorText || '-',
                    name: this.translate.instant('BLOCKAGE_PREDICT.PROCESSING_ERRORS'),
                    className: 'process-errors'
                });

                this.paginationConfig.length = data.sites.length;
                this.originalData = data.sites;
                this.getCurrentData();
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }

    downloadCSV() {
        if (!this.dataSource.data || this.dataSource.data.length === 0) {
            console.warn('No data to download');
            return;
        }

        const columnTranslationMap = {
            customerName: 'BLOCKAGE_PREDICT.CUSTOMER_NAME',
            locationName: 'BLOCKAGE_PREDICT.LOCATION_NAME',
            cid: 'BLOCKAGE_PREDICT.CID',
            lid: 'BLOCKAGE_PREDICT.LID',
            series: 'BLOCKAGE_PREDICT.SERIES',
            pipeInfo: 'BLOCKAGE_PREDICT.PIPE_INFO',
            bpScore: 'BLOCKAGE_PREDICT.BP_SCORE',
            bpFlag: 'BLOCKAGE_PREDICT.BP_FLAG',
            bpType: 'BLOCKAGE_PREDICT.BP_TYPE',
            bpDate: 'BLOCKAGE_PREDICT.BP_DATE',
        };

        const dataPropertyMap = {
            customerName: 'custname',
            locationName: 'lname',
            cid: 'cid',
            lid: 'lid',
            series: 'ms',
            pipeInfo: 'lookupdesc',
            bpScore: 'bpscore',
            bpFlag: 'bpflag',
            bpType: 'bptype',
            bpDate: 'bpdate',
        };

        const headers = this.dataColumns.filter(v => !!columnTranslationMap[v]).map(column => 
            this.translate.instant(columnTranslationMap[column] || `BLOCKAGE_PREDICT.${column.toUpperCase()}`)
        );

        const csvData = this.dataSource.data.map(row => 
            this.dataColumns.filter(v => !!columnTranslationMap[v]).map(column => {
                const dataProperty = dataPropertyMap[column] || column;
                if (column === 'bpDate') {
                    return this.datePipe.transform(row[dataProperty], this.bpDateFormat);
                }

                if (column === 'bpFlag' || column === 'bpType') {
                    const mode = this.bpModePipe.transform(row[dataProperty]);
                    return `${row[dataProperty]} - ${mode}`;
                }

                if (column === 'bpScore') {
                    return row[dataProperty] + '%';
                }

                if (typeof row[dataProperty] === 'string' && row[dataProperty].includes(',')) {
                    return `"${row[dataProperty].replace(/"/g, '""')}"`;
                }

                return row[dataProperty];
            })
        );

        csvData.unshift(headers);

        const csvContent = csvData.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `blockage_predict_report_${this.datePipe.transform(this.reportDate, 'yyyyMMdd')}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }


    changePage(event: PageEvent) {
        const isChanged = this.paginationConfig.pageIndex !== event.pageIndex || this.paginationConfig.pageSize !== event.pageSize;

        this.paginationConfig.pageIndex = event.pageIndex;
        this.paginationConfig.pageSize = event.pageSize;

        if (isChanged) {
            this.getCurrentData();
        }
    }

    sortData(sort: Sort) {
        if (this.matPaginator) {
            this.matPaginator.firstPage();
        }

        this.paginationConfig.pageIndex = 0;
        this.getCurrentData();
    }

    getCurrentData() {
        let data = this.originalData.slice();
    
        const filterValue = this.searchString.value ? this.searchString.value.trim().toLowerCase() : '';
        if (filterValue) {
            data = data.filter(item => {
                return (
                    item.custname.toLowerCase().includes(filterValue) ||
                    item.lname.toLowerCase().includes(filterValue) ||
                    item.cid.toString().includes(filterValue) ||
                    item.lid.toString().includes(filterValue) ||
                    item.ms.toLowerCase().toString().includes(filterValue) ||
                    item.lookupdesc.toLowerCase().toString().includes(filterValue) ||
                    item.bpscore.toString().includes(filterValue) ||
                    this.bpModePipe.transform(item.bpflag).toLowerCase().includes(filterValue) ||
                    this.bpModePipe.transform(item.bptype).toLowerCase().includes(filterValue) ||
                    this.datePipe.transform(item.bpdate, this.bpDateFormat).toLowerCase().includes(filterValue)
                );
            });
        }
    
        this.paginationConfig.length = data.length;
    
        if (this.matSort && this.matSort.active && this.matSort.direction) {
            data.sort((a, b) => {
                const isAsc = this.matSort.direction === 'asc';
                switch (this.matSort.active) {
                    case 'customerName':
                        return compare(a.custname, b.custname, isAsc);
                    case 'locationName':
                        return compare(a.lname, b.lname, isAsc);
                    case 'cid':
                        return compare(a.cid, b.cid, isAsc);
                    case 'lid':
                        return compare(a.lid, b.lid, isAsc);
                    case 'series':
                        return compare(a.ms, b.ms, isAsc);
                    case 'pipeInfo':
                        return compare(a.lookupdesc, b.lookupdesc, isAsc);
                    case 'bpScore':
                        return compare(a.bpscore, b.bpscore, isAsc);
                    case 'bpFlag':
                        return compare(a.bpflag, b.bpflag, isAsc);
                    case 'bpType':
                        return compare(a.bptype, b.bptype, isAsc);
                    case 'bpDate':
                        return compare(new Date(a.bpdate), new Date(b.bpdate), isAsc);
                    default:
                        return 0;
                }
            });
        }
    
        const startIndex = this.paginationConfig.pageIndex * this.paginationConfig.pageSize;
        const endIndex = startIndex + this.paginationConfig.pageSize;
        const paginatedData = data.slice(startIndex, endIndex);
    
        this.dataSource.data = paginatedData;
    }

    openHumanReview(item: BPReportCustomer) {
        const data: HumanReviewDialogData = {
            cid: item.cid, lid: item.lid, date: this.dateutilService.formatDateAsTimeZone(new Date(item.bpdate))
        };

        this.dialog.open(HumanReviewReportDialog, {
            disableClose: true,
            data,
            minWidth: '90vw'
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(v => v.unsubscribe());
    }
}
