import {
    Component,
    ViewEncapsulation,
    EventEmitter,
    Output,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    OnInit,
} from '@angular/core';
import * as _ from 'underscore';
import { ChangesAction } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import { PrecompensationType } from 'app/shared/models/sliicer';
import { PRECOMP_TYPES } from 'app/shared/models/sliicer/settings';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'ads-precompensation-settings',
    templateUrl: './precompensation-settings.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class PrecompensationSettingsComponent implements OnChanges, OnInit {
    @Input() public selectedPrecompType: PrecompensationType;
    @Input() public isStudyLocked: boolean;
    @Input() public shouldUpdate: boolean;
    @Input() public changesAction: ChangesAction;
    @Input() public originalPrecompType: PrecompensationType;
    @Output() public checkChanges: EventEmitter<string> = new EventEmitter();
    @ViewChild('preCompensation') public preCompensationForm;

    // Constants
    public precompTypes = PRECOMP_TYPES;
    private preCompValue: PrecompensationType;
    public formValues = { value: '' };
    public initialFormValues = { value: '' };
    public preCompMessage : string;
    private dismissMessage: string;
    public translateKeys: Array<string> = [
        'COMMON.DISMISS_TEXT',
        'SLIICER.COMMON.STORM_EVENT.PRECOMP_CHANGE_MSG',
    ];

    public trackByValue = TrackBy.byValue();
    constructor(
        private snackBarNotificationService: SnackBarNotificationService,
        private translate: TranslateService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissMessage = translateValues['COMMON.DISMISS_TEXT'];
            this.preCompMessage = translateValues['SLIICER.COMMON.STORM_EVENT.PRECOMP_CHANGE_MSG'];
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.shouldUpdate) {
            this.restorePreValue();
        } else if (changes.changesAction && this.getChangeValue()) {
            if (this.changesAction.action === 'undoChanges') {
                this.restorePreValue();
                this.preCompensationForm.form.markAsUntouched();
                this.preCompensationForm.form.markAsPristine();
                this.checkChanges.emit('');
            }
        }
    }

    public ngOnInit() {
        this.restorePreValue();
        this.initialFormValues = { ...this.formValues };
    }

    private restorePreValue() {
        this.selectedPrecompType = this.originalPrecompType;
        this.formValues.value = this.selectedPrecompType;
    }

    public getChanges(): PrecompensationType {
        if (this.preCompValue) {
            this.preCompensationForm.form.markAsUntouched();
            this.preCompensationForm.form.markAsPristine();
            this.originalPrecompType = this.preCompValue;
            return this.preCompValue;
        }
        return null;
    }

    public onChangePreCompType(preCompValue: PrecompensationType) {
        if (preCompValue) {
            this.preCompValue = preCompValue;
            this.formValues.value = preCompValue;
        }
        this.checkChanges.emit('');

        this.snackBarNotificationService.raiseNotification(
            this.preCompMessage,
            this.dismissMessage,
            {
                duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
            },
            true,
        );
    }

    public getChangeValue(): boolean {
        return !_.isEqual(this.formValues, this.initialFormValues);
    }
}
