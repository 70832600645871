<div id="navBarContainer" *ngIf="menuItem.show">
    <a
        mat-button
        *ngIf="hasExternalLink"
        class="each-nav-item"
        (click)="clicked($event)"
        [href]="menuItem.link"
        [class.active]="selectedItem === menuItem.title"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
    </a>

    <a
        mat-button
        *ngIf="!hasLink && !hasChildren && !hasQuery && !hasExternalLink"
        class="each-nav-item"
        (click)="clicked($event)"
        [class.active]="selectedItem === menuItem.title"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
    </a>

    <a
        mat-button
        *ngIf="hasLink && !hasChildren && !hasQuery && !hasExternalLink && menuItem.isAdmin"
        class="each-nav-item"
        [class.active]="selectedItem === menuItem.title"
        [ngClass]="{ flowBalance: menuItem.title === 'NAVIGATION.FLOW_BALANCE' }"
        (toggle)="(true)"
        (click)="clicked($event)"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title" [ngClass]="{ 'no-text-case': menuItem.title === menuTitlesEnum.SLIICER }"
            >{{ menuItem.title | translate }}<span *ngIf="menuItem.title === menuTitlesEnum.SLIICER">&reg;</span></span
        >
    </a>

    <a
        mat-button
        *ngIf="hasLink && !hasChildren && hasQuery && !hasExternalLink && menuItem.isAdmin"
        class="each-nav-item"
        [class.active]="selectedItem === menuItem.title"
        (toggle)="(true)"
        (click)="clicked($event)"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title" [ngClass]="{ 'no-text-case': menuItem.title === menuTitlesEnum.SLIICER }"
            >{{ menuItem.title | translate }}<span *ngIf="menuItem.title === menuTitlesEnum.SLIICER">&reg;</span></span
        >
    </a>

    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === menuTitlesEnum.DASHBOARDS"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === 'menuDashboard'"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>

        <div class="dropdown-content custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children; trackBy: trackByTitle"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>

    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === menuTitlesEnum.REPORTS && menuItem?.parent?.title !== menuTitlesEnum.ADMIN"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === 'report'"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>
        <div class="dropdown-content custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children; trackBy: trackByTitle"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>
    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === menuTitlesEnum.ANALYTICS"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === 'sliicer'"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="analyticSvgIcon"></span>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>
        <div class="dropdown-content custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children; trackBy: trackByTitle"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>
    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === menuTitlesEnum.ADMIN"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === activeMenuEnum.Admin || selectedItem === activeMenuEnum.BlockagePredict"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>
        <div class="dropdown-content custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children; trackBy: trackByTitle"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>

    <a
        mat-button
        *ngIf="hasChildren && (menuItem.title === menuTitlesEnum.BLOCKAGE_PREDICT || (menuItem.title === menuTitlesEnum.REPORTS && menuItem?.parent?.title === menuTitlesEnum.ADMIN )) && menuItem.isAdmin"
        class="each-nav-item custom-subnav-dropdown"
        [class.active]="selectedItem === activeMenuEnum.Admin || selectedItem === activeMenuEnum.BlockagePredict"
        (mouseenter)="toggleSubmenu(menuItem.title, $event)"
        (mouseleave)="toggleSubmenu(menuItem.title, $event)"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly && level === 1">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="!menuItem.showOnly && level >= 2">arrow_right</mat-icon>
        <div class="dropdown-content custom-subnav-dropdown-content submenu-dropdown"
            #submenuDropdown *ngIf="isSubmenuOpen[menuItem.title]" [class.active]="isSubmenuOpen[menuItem.title]" style="visibility: hidden;">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>
</div>
