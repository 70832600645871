<mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="location-layer-list-tree"
    [ngClass]="{ 'hint-background': availablePageHint }"
>
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <div class="layerelement" #treeNode>
            <div class="element-row">
                <mat-checkbox
                    class="checklist-leaf-node"
                    [(ngModel)]="node.checked"
                    (ngModelChange)="toggleNode(node, 0)"
                    [ngClass]="{ 'monitor-hint': node.item == 'Monitor' }"
                >
                    {{ node.item }}</mat-checkbox
                >
                <button
                    mat-icon-button
                    *ngIf="node.item == 'Monitor'"
                    class="add-monitor-hint"
                    [matMenuTriggerFor]="menu"
                    #addNewLocationTrigger="matMenuTrigger"
                    matTooltip="{{ 'GIS_TOOLTIPS.PLUS_ICON' | translate }}"
                >
                    <mat-icon>add</mat-icon>
                    <mat-menu
                        #menu="matMenu"
                        class="add-location-menu"
                        yPosition="above"
                        xPosition="after"
                        [overlapTrigger]="false"
                    >
                        <button mat-menu-item (click)="addCompositeLocation()">
                            <mat-icon>add_circle</mat-icon>
                            <span>{{ 'HOME.MAP.LEGEND.ADD_MAP_COMPOSITE_LOCATION' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="addEditLocation()">
                            <mat-icon>add_location</mat-icon>
                            <span>{{ 'HOME.MAP.LEGEND.ADD_NEW_LOCATION' | translate }}</span>
                        </button>
                    </mat-menu>
                </button>
            </div>
            <div class="hover-items">
                <button
                    *ngIf="node.showLegend"
                    mat-icon-button
                    class="layerbutton layerbtnpressed"
                    (click)="node.showLegend = false; toggleNode(node, 0)"
                    matTooltip="{{ 'GIS_TOOLTIPS.HAMBURGER_MENU' | translate }}"
                    value="true"
                >
                    <mat-icon class="show-legend-hint">format_list_bulleted</mat-icon>
                </button>
                <button
                    *ngIf="!node.showLegend"
                    mat-icon-button
                    mat-button
                    (click)="node.showLegend = true; toggleNode(node, 0)"
                    class="layerbutton"
                    matTooltip="{{ 'GIS_TOOLTIPS.HAMBURGER_MENU' | translate }}"
                    value="true"
                >
                    <mat-icon class="show-legend-hint">format_list_bulleted</mat-icon>
                </button>
                <button
                    *ngIf="node.selection"
                    mat-icon-button
                    class="layerbutton layerbtnpressed"
                    (click)="node.selection = false; toggleNode(node, 0)"
                    matTooltip="{{ 'GIS_TOOLTIPS.ENABLE_FEATURES' | translate }}"
                >
                    <mat-icon class="feat-select-hint">highlight_alt</mat-icon>
                </button>
                <button
                    *ngIf="!node.selection"
                    mat-icon-button
                    mat-button
                    class="layerbutton"
                    (click)="node.selection = true; toggleNode(node, 0)"
                    matTooltip="{{ 'GIS_TOOLTIPS.ENABLE_FEATURES' | translate }}"
                >
                    <mat-icon>highlight_alt</mat-icon>
                </button>
                <button mat-icon-button class="layerbutton"
                    *ngIf="node.item == 'Monitor'"
                    matTooltip="{{ 'GIS_TOOLTIPS.ENABLE_LABELS' | translate }}"
                    (click)="labelsClick()"
                    [ngClass]="{'layerbtnpressed': userSettings && userSettings.showLocationLabels}"
                    >
                      <mat-icon>local_offer</mat-icon>
                </button>
                <button mat-icon-button class="layerbutton more-options-button more-options-hint">
                    <mat-icon>more_horiz</mat-icon>

                    <div class="more-options-menu mat-box-shadow">
                        <button mat-menu-item (click)="toggleNode(node, 1)"><mat-icon>zoom_in</mat-icon>Zoom To</button>
                        <div *ngIf="node.item == 'Monitor'" class="transparency">
                            <button mat-menu-item><mat-icon>browser_not_supported</mat-icon>Transparency</button>

                            <mat-slider
                                class="transparency-slider"
                                [(ngModel)]="node.opacity"
                                (MatSliderChange)="toggleNode(node, 2)"
                                (ngModelChange)="node.opacity = node.opacity; toggleNode(node, 2)"
                                max="1"
                                min="0"
                                step="0.1"
                                tickInterval="0.1"
                            ></mat-slider>
                        </div>
                    </div>
                </button>
            </div>
            <div class="layer-legend" *ngIf="node.showLegend">
                <div *ngFor="let legend of node.legendInfo; trackBy: trackByLabel" class="layer-legend-item">
                    <img
                        [src]="legend.url"
                        *ngIf="legend.url"
                        [ngClass]="{ 'monitor-icon': node.item == 'Monitor' }"
                        [ngStyle]="{ height: legend.height }"
                    />
                    <div *ngIf="legend.color && legend.type === LegendType.LINE" class="solid-legend" [ngStyle]="{ background: legend.color }"></div>
                    <div *ngIf="legend.color && legend.type === LegendType.CIRCLE" class="circle-legend" [ngStyle]="{ background: legend.color }"></div>
                    <div *ngIf="legend.color && legend.type === LegendType.AREA" class="area-legend" [ngStyle]="{ background: legend.borderColor }">
                        <div [ngStyle]="{ background: legend.color }"></div>
                    </div>
                    {{ legend.label }}
                </div>
            </div>
        </div>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
        </button>
        <mat-checkbox
            [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="selectAllChildren(node)"
            >{{ node.item }}</mat-checkbox
        >
    </mat-tree-node>
</mat-tree>
