<div id="uptimeOverviewId" class="contentLayout">
    <mat-card>
        <app-loader [isLoading]="uptimeLoadingState"></app-loader>
        <mat-card-header (click)="overlayClick()">
            <div class="auto-margin">
                <span class="uptime-title-hint">{{ 'REPORT.UPTIME.OVERVIEW_TITLE' | translate }}</span>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button
                    class="uptime-download-hint"
                    (click)="downloadCSV()"
                    mat-button
                    [disabled]="!uptimeData || uptimeData?.length === 0"
                >
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    class="backIcon uptime-filter-hint"
                    [ngClass]="{ active: displayFilters }"
                    (click)="displayFilters = !displayFilters"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <form *ngIf="displayFilters" id="widgetFiltersForm">
            <app-uptime-filters
                [startDate]="startDate"
                [endDate]="endDate"
                [availableEntities]="availableEntities"
                [uptimeData]="uptimeData"
                [locationsList]="locationsList"
                [sampleRateList]="sampleRateList"
                [selectedEntityIds]="selectedEntityIds"
                (dateRangeChange)="onDateRangeChange($event)"
                (entitiesChange)="onEntitiesChange($event)"
                (sampleRateChange)="onSampleRateChange($event)"
                [defaultEntities]="defaultEntities"
                (locationSelected)="onLocationSelected($event)"
                [selectedLocations]="selectedLocations"
                [selectedSampleRate]="selectedSampleRate"
                (removeLocation)="onRemoveLocation($event)"
                (applyFilters)="applyFilters()"
            ></app-uptime-filters>
        </form>
        <mat-card-content (click)="overlayClick()">
            <div
                *ngIf="(uptimeData?.length === 0 || !uptimeOverviewData || formattedUptimeData?.length === 0) && !uptimeLoadingState"
                class="notabledataFound custom-table"
            >
                <span>{{ 'REPORT.NO_DATA_IN_OVERVIEW' | translate }}</span>
            </div>
            <div *ngIf="formattedUptimeData?.length > 0" id="uptimeOverviewLeaderBoardTable">
                <table mat-table [dataSource]="formattedUptimeData" class="uptime-overview-table">
                    <ng-container matColumnDef="location" sticky>
                        <th mat-header-cell *matHeaderCellDef>Location</th>
                        <td
                            mat-cell
                            *matCellDef="let data; let i = index"
                            [ngClass]="{ 'uptime-location-hint': i === 0 }"
                            (click)="itemSelected($event, data.locationId, data.locationName)"
                            [attr.rowspan]="entitiesLength"
                            [style.display]="getRowSpan('locationName', i) ? '' : 'none'"
                        >
                            <span>{{ data.locationName }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="avrUptime" sticky>
                        <th mat-header-cell *matHeaderCellDef class="uptime-coloumn">ENTITY AVERAGE UPTIME</th>
                        <td mat-cell *matCellDef="let data; let i = index" class="average-container uptime-coloumn" >
                            <p class="average-entity" [ngClass]="{ 'uptime-entity-hint': i === 0 }">
                                {{ data.average.entityName }}
                            </p>
                            <p
                                class="average-value"
                                *ngIf="data.average as v"
                                [ngClass]="{
                                    'success-text': v.uptime === 100,
                                    'danger-text': !v.uptime,
                                    'warn-text': v.uptime !== 100 && v.uptime !== 0 && v.uptime !== undefined,
                                    'uptime-average-hint': i === 0
                                }"
                            >
                                {{ data?.average?.uptime ? data?.average?.uptime + '%' : '—' }}
                            </p>
                        </td>
                    </ng-container>

                    <ng-container *ngFor="let column of dayColumns; let i = index; trackBy: trackById" [matColumnDef]="column.id">
                        <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'uptime-day-hint': i === 0 }">
                            {{ column.value }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let data; let i = index"
                            [ngClass]="{
                                'success-text': (column | entityDayValue: data:i:selectedEntityIds.length) === 100,
                                'warn-text':
                                    (column | entityDayValue: data:i:selectedEntityIds.length) !== 100 &&
                                    (column | entityDayValue: data:i:selectedEntityIds.length) !== 0,
                                'danger-text':
                                    (column | entityDayValue: data:i:selectedEntityIds.length) === 0 ||
                                    (column | entityDayValue: data:i:selectedEntityIds.length) === '—'
                            }"
                        >
                            {{ column | entityDayValue: data:i:selectedEntityIds.length }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
            <div
                [hidden]="
                    selectedLocations.length <= (uptimeOverviewPaginator ? uptimeOverviewPaginator.pageSize : 5) ||
                    uptimeLoadingState
                "
            >
                <mat-paginator
                    #paginator
                    [length]="selectedLocations.length"
                    [pageIndex]="0"
                    [pageSize]="5"
                    (page)="onPaginateChange()"
                    [pageSizeOptions]="[5, 15, 25]"
                >
                </mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>
