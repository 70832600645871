import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { UsersService } from 'app/pages/admin/users.service';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { customerLocationGroupQueryParam, customerQueryParam, locationIdQueryParam, activeInactiveLocationQueryParam } from 'app/shared/models/customer';
import { Selectable } from 'app/shared/models/selectable';
import { LocationArgs, LocationStatus } from 'app/shared/models/locations';
import { FlowBalanceDetails } from 'app/shared/models/location-details';
import { TrackBy } from 'app/shared/utils/track-by';
const UPSTREAM = 'Upstream';
const OVERFLOW = 'Overflow';
const UPSTREAMFLOWTYPE = 1;
const OVERFLOWTYPE = 2;

@Component({
    selector: 'app-add-edit-flow-balance',
    templateUrl: './add-edit-flow-balance.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditFlowBalanceComponent implements OnInit, OnDestroy {
    @Input() public flowBalanceData;
    @Output() public renderSection: EventEmitter<boolean> = new EventEmitter<boolean>();
    public locations: Array<Selectable>;
    public copiedLocation: Array<Selectable>;
    public customerId: number;
    public locationId: number;
    public isLocationError: boolean;
    public locationGroupID: number;
    public includeInactiveLocations = false;
    private subscriptions = new Array<Subscription>();
    public locationName = new Array<Selectable>();
    public numberOfEntries: number;
    public isLoading: boolean;
    public isAddMoreLocation: boolean;
    public isLocationAlreadyAdded = new Array<boolean>();
    public isMinLocationSelected: boolean;
    public isSaveDisabled = true;
    public isAddMoreDisabled = true;
    public isLocationSelected: boolean;
    public errorLocation = new Array<any>();
    public isDuplicateLocationRemoved = false;
    public isDuplicateRowRemoved: boolean;
    public removeDuplicateLocation: string;
    public duration = 10000;
    public dismiss: string;
    public isInitailrow: boolean;
    public upstream: string;
    /**
     * array to loop input values for depth and quantity depending upon numberOfEntries
     */
    public numberOfTimes: Array<number>;

    public trackByIndex = TrackBy.byIndex;
    public trackById = TrackBy.byId;
    constructor(
        private usersService: UsersService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private snackBarNotificationService: SnackBarNotificationService,
        private translate: TranslateService,
    ) {}

    public ngOnInit() {
        this.translate.get('COMMON.REMOVE_DUPLICATE_LOCATION').subscribe((res: string) => {
            this.removeDuplicateLocation = res;
        });
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismiss = res;
        });
        this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                this.locationGroupID = Number(params.get(customerLocationGroupQueryParam));
                this.customerId = Number(params.get(customerQueryParam));
                this.locationId = Number(params.get(locationIdQueryParam));
                this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
                this.loadLocations();
            },
            (error) => {
                // error block
            },
        );
        if (this.flowBalanceData && this.flowBalanceData.length > 0) {
            this.flowBalanceData.forEach((response) => {
                const locationNameArgs = <Selectable>{
                    order: response.order,
                    name: response.flowBalanceLocation,
                    flow: response.flowType === UPSTREAMFLOWTYPE ? UPSTREAM : OVERFLOW,
                    id: response.id,
                };
                this.isAddMoreDisabled = false;
                this.locationName.push(locationNameArgs);
            });
        } else {
            this.isMinLocationSelected = true;
            this.isAddMoreDisabled = false;
            this.isInitailrow = true;
        }
        
    }
    /**
     * Used to load location
     * from the location list api
     */
    public loadLocations() {
        this.isLoading = true;
        this.locations = new Array<Selectable>();
        // initialize location parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            locationGroupId: this.locationGroupID,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        // Fetch the locations for selected customer
        const subscription = this.usersService.getLocationsList(locationArgs).subscribe(
            (res) => {
                if (res && res.length > 0) {
                    for (const item of res) {
                        if (
                            item.viewable &&
                            item.status !== LocationStatus.InactiveRemoved &&
                            item.locationId !== this.locationId &&
                            item.series !== 'RainAlert III' &&
                            item.series !== 'RainAlert II' &&
                            item.locationType !== 3
                        ) {
                            this.locations.push({ id: item.locationId, name: item.locationName });
                        }
                    }
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    const filterPipe = new OrderByPipe();
                    this.locations = filterPipe.transform(this.locations, 'name', false);
                    this.copiedLocation = JSON.parse(JSON.stringify(this.locations));
                }
            },
            (error) => {
                // error block
            },
        );
        this.subscriptions.push(subscription);
    }
    public changeFlowDropdown() {
        this.enableAddMore();
    }
    public changeLocationDropdown(value, index) {
        this.checkForDuplicateLocation(value, index);
        if (!value || value === '') {
            this.isLocationSelected = false;
            this.locations = this.copiedLocation;
        } else if (this.isAddMoreLocation) {
            this.isLocationSelected = true;
            this.locations = this.copiedLocation.filter((item) =>
                item.name.toLowerCase().startsWith(value.toLowerCase()),
            );
        } else {
            this.isLocationSelected = true;
            this.locations = this.locations.filter((item) => item.name.toLowerCase().startsWith(value.toLowerCase()));
        }
        this.enableAddMore();
    }

    public enableAddMore() {
        if ((this.isLocationSelected && !this.isLocationError) || this.isDuplicateRowRemoved) {
            this.isAddMoreDisabled = false;
            this.isSaveDisabled = false;
        } else {
            if (
                (!this.isLocationSelected && !this.isLocationError) ||
                (!this.isLocationSelected && !this.isLocationError)
            ) {
                this.isAddMoreDisabled = false;
                this.isSaveDisabled = false;
            } else {
                this.isAddMoreDisabled = true;
            }
        }
        this.isDuplicateRowRemoved = false;
    }
    /**
     * Used to check if there are any duplicate location in the pop up
     * @param location name
     */
    public checkForDuplicateLocation(value, i) {
        this.copiedLocation.forEach((x) => {
            if (this.isLocationAlreadyAdded[i] === false || (this.isAddMoreLocation && x.name === value)) {
                this.locationName.filter((item, index) => {
                    if (
                        i !== index &&
                        (this.isLocationAlreadyAdded[i] === false || this.isAddMoreLocation) &&
                        item.name === value
                    ) {
                        this.isLocationAlreadyAdded[i] = true;
                        this.errorLocation.push({ name: this.locationName[i].name, index: i });
                        this.isSaveDisabled = true;
                        this.isLocationError = true;
                        this.isAddMoreDisabled = true;
                        this.isAddMoreLocation = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                });
            }
        });
    }
    /**
     * Used toremove row
     * on the pop up
     */
    public removeError(index) {
        this.isLocationAlreadyAdded[index] = false;
        this.isLocationError = false;
        this.errorLocation = this.errorLocation.filter((item) => item.index !== index);
        this.enableAddMore();
    }
    /**
     * Used to add more location
     * on the pop up
     */
    public addMoreLocation() {
        this.locations = this.copiedLocation;
        this.isMinLocationSelected = false;
        this.isLocationSelected = false;
        this.isAddMoreDisabled = false;
        this.isAddMoreLocation = true;
        this.isSaveDisabled = true;
        this.locationName.push({
            name: '',
            id: 0,
            order: this.locationName.length + 1,
            flow: UPSTREAM,
        });
        this.isLocationAlreadyAdded[this.locationName.length - 1] = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    /**
     * Used to remove location from pop up
     * @param index
     */
    public removeRow(index: number) {
        if (this.errorLocation.length > 0) {
            this.errorLocation.forEach((location) => {
                if (this.locationName[index].name === location.name) {
                    this.isLocationAlreadyAdded[this.locationName.length - 1] = false;
                    this.isDuplicateLocationRemoved = true;
                    if (this.locationName[index].flow === '') {
                        this.isAddMoreDisabled = false;
                        this.isDuplicateRowRemoved = true;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    } else {
                        this.isAddMoreDisabled = true;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                }
            });
        }
        if (index > -1) {
            this.removeError(index);
            this.locationName.splice(index, 1);
        }
        if (this.isLocationAlreadyAdded[index] === true) {
            this.isAddMoreDisabled = false;
        }
        this.isAddMoreLocation = false;
        if (!this.isDuplicateLocationRemoved && this.locationName.length !== 0 && this.errorLocation.length !== 0) {
            this.isLocationAlreadyAdded[this.locationName.length - 1] = true;
            this.isAddMoreDisabled = true;
            this.isSaveDisabled = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else if (
            this.isDuplicateLocationRemoved &&
            this.locationName.length !== 0 &&
            this.errorLocation.length !== 0
        ) {
            this.isLocationAlreadyAdded[this.locationName.length - 1] = false;
        }
        if (this.locationName.length === 0) {
            this.isSaveDisabled = this.isInitailrow ? true : false;
            this.isAddMoreDisabled = false;
            this.isMinLocationSelected = true;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    /**
     * Used to save location from pop up
     *
     */
    public saveFlowBalanceDetails() {
        const selectedLocationFlowDetails = [];
        this.isLoading = true;
        this.locationName.forEach((selectedLocation) => {
            this.copiedLocation.forEach((location) => {
                if (selectedLocation.name === location.name) {
                    const flowBalanceArgs = <FlowBalanceDetails>{
                        order: selectedLocation.order,
                        flowType: Number(selectedLocation.flow === OVERFLOW ? OVERFLOWTYPE : UPSTREAMFLOWTYPE),
                        flowBalanceLocationId: location.id,
                    };
                    selectedLocationFlowDetails.push(flowBalanceArgs);
                }
            });
        });
        const valueArr = selectedLocationFlowDetails.map(function (item) {
            return item.flowBalanceLocationId;
        });
        const isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) !== idx;
        });
        if (isDuplicate) {
            this.isSaveDisabled = true;
            this.isLoading = false;
            this.snackBarNotificationService.raiseNotification(this.removeDuplicateLocation, this.dismiss, {
                duration: this.duration,
            });
        } else {
            this.usersService
                .flowBalanceDetails(this.customerId, this.locationId, selectedLocationFlowDetails)
                .subscribe(
                    (res) => {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.close();
                    },
                    (error) => {
                        // error block
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                );
        }
    }
    /**
     * Close dialoge box method
     * @param permissionsUpdated
     */
    public close() {
        this.renderSection.emit(false);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    /**
     * angular life cycle hook
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
