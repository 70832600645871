import { OnInit, Component, Input } from '@angular/core';
import { REGEX_CONFIG } from '../../../../utils/regex-utils';
import { FormGroup } from '@angular/forms';
import { BlankingList, MetricBlankingList, ModeList, PrimarySensors, SensorsList } from 'app/shared/components/location-card/location-card.constants';
import { TranslateService } from '@ngx-translate/core';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { UsersService } from 'app/pages/admin/users.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-add-edit-location-smart-depth-sensor',
    templateUrl: './add-triton-location-smart-depth-sensor.component.html',
    styleUrls: ['./add-triton-location-smart-depth-sensor.component.scss'],
})
export class AddTritonLocationSmartDepthSensorComponent implements OnInit {
    @Input() public monitoringPoint;
    @Input() public index: 0 | 1;
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;

    public isMonitorEditor = false;
    public sensorsList = SensorsList;
    public modeList = ModeList;
    public blankingList = [];

    public isMetric: boolean;
    // Reg Ex. Pattern for numeric -+ Decimal value 2Place
    public negativeWithTwoDecimalPattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public trackByValue = TrackBy.byValue();

    constructor(
        public translateService: TranslateService,
        public locationCardService: LocationCardService,
        private usersService: UsersService,
    ) {}

    public ngOnInit() {
        this.isMetric = this.locationCardService.isMetric;
        this.blankingList = this.isMetric ? MetricBlankingList : BlankingList;
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();

        const formValue = this.form.getRawValue();

        if (this.monitorConfigs) {
            const offsetRounder = this.isMetric ? 10 : 100; // for offset values we allow 1 decimal for Metric and 2 for non metric

            formValue.physicalOffset = Math.round(formValue.physicalOffset * offsetRounder) / offsetRounder;
            formValue.ultrasonicElectronicOffset = Math.round(formValue.ultrasonicElectronicOffset * offsetRounder) / offsetRounder;
        }
        this.form.patchValue({ ...formValue });
    }
}
