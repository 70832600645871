import { UsersService } from 'app/pages/admin/users.service';
import {
    ChangeDetectionStrategy,
    Component,
    AfterViewInit,
    OnDestroy,
    Input,
    ViewChildren,
    QueryList,
    ViewEncapsulation,
    ElementRef,
    OnInit,
    ViewChild,
    Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';
import { MenuItem } from '../menu-item';
import { StringUtils } from 'app/shared/utils/string-utils';
import { NavigationService } from '../navigation.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SliicerMarketingScreenComponent } from 'app/pages/sliicer/sliicer-marketing-screen/sliicer-marketing-screen.component';
import { LocationService } from 'app/shared/services/location.service';
import {
    activeInactiveLocationQueryParam,
    AppQueryParams,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { take } from 'rxjs/operators';
import { AnnouncementComponent } from 'app/pages/admin/alert/Alert.component';
import { NavigationMenuTitles } from '../menu-setup';
import { TrackBy } from 'app/shared/utils/track-by';

const SUBMENU_START_LEVEL = 2;
const BLOCKAGE_PREDICT_LINK = 'blockage-predict';

enum ActiveMenu {
    MenuDashboard = 'menuDashboard',
    Home = 'NAVIGATION.HOME',
    Vault = 'NAVIGATION.VAULT',
    Report = 'report',
    Slicer = 'sliicer',
    Admin = 'admin',
    BlockagePredict = 'blockagePredict'
}

@Component({
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['./nav-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
})
export class NavItemComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChildren(NavItemComponent) public children: QueryList<NavItemComponent>;
    @ViewChild('submenuDropdown') submenuDropdown: ElementRef;
    @Input() public menuItem: MenuItem;
    @Input() public level = 1;

    @Input() public set active(active: boolean) {
        this._active = active;
    }

    @Input() public parent: NavItemComponent;

    public disableHeaders$: BehaviorSubject<boolean>;

    private subscription: Subscription;
    // tslint:disable-next-line:variable-name
    private _active = false;
    // tslint:disable-next-line:variable-name
    private _currentRoute: string;
    public highlightnav: boolean;
    public sliicerPagepermission: boolean;
    public selectedItem: string;
    public isSubmenuOpen: boolean[] = [];

    public menuTitlesEnum = NavigationMenuTitles;

    public activeMenuEnum = ActiveMenu;
    public trackByTitle = TrackBy.byUniqueKey<MenuItem>()('title');
    constructor(
        private elements: ElementRef,
        private navigation: NavigationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private statusCodeService: StatusCodeService,
        private locationService: LocationService,
        private usersService: UsersService,
        private dialog: MatDialog,
        private renderer: Renderer2
    ) {
        this.disableHeaders$ = this.locationService.disableHeaderButtons$;
    }

    public ngOnInit() {
        // will be invoked if router changes also highlight the active tab on route change
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.setActiveTab();
            }
        });

        // highlight active tab on page refresh
        this.setActiveTab();

        this.usersService.isSliicerAllowed.subscribe((isAllowed) => {
            this.sliicerPagepermission = isAllowed.value;
        });
    }
    public ngAfterViewInit() {
        if (!this.hasChildren && this.hasLink) {
            // Open up the current menu item on initial load (i.e. someones refreshes the page or you go directly to an inner page)
            this.subscription = this.navigation.getCurrentRoute.subscribe((currentRoute) => {
                this._currentRoute = currentRoute;

                if (this.isActive(currentRoute)) {
                    let hasShowOnly = false;
                    let parent: NavItemComponent = this;
                    while (parent !== undefined && parent !== null) {
                        if (!StringUtils.isNull(parent, 'menuItem') && parent.menuItem.showOnly) {
                            hasShowOnly = true;
                            break;
                        }
                        parent = parent.parent;
                    }
                    if (!hasShowOnly) {
                        this.toggle(true);
                    } else {
                        this.navigation.getActiveMenuItem.pipe(take(1)).subscribe((activeMenu: MenuItem) => {
                            this.navigation.tempMenuItems.pipe(take(1)).subscribe((tempMenus: Array<MenuItem>) => {
                                if (activeMenu === null && tempMenus === null) {
                                    this.navigation.showOnly(parent.menuItem);
                                } else if (tempMenus !== null && tempMenus.length > 0) {
                                    parent.toggle(true, null, true);
                                } else if (activeMenu !== null && tempMenus !== null && tempMenus.length === 0) {
                                    if (parent.parent !== undefined && parent.parent !== null) {
                                        parent.parent.toggle(true, null, false, true);
                                    }
                                }
                            });
                        });
                    }
                }
            });
        } else if (this.menuItem.showOnly) {
            this.navigation.tempMenuItems.pipe(take(1)).subscribe((tempMenus) => {
                if (tempMenus !== null && tempMenus.length > 0) {
                    this.toggle(true, null, true, true);
                }
            });
        }

        if (this.level > SUBMENU_START_LEVEL && this.submenuDropdown) {
            this.positionSubmenu();
        }
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private positionSubmenu() {
        if (!this.submenuDropdown) return;

        const parentRect = this.elements.nativeElement.getBoundingClientRect();
        const submenuRect = this.submenuDropdown.nativeElement.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        let left = parentRect.right;
        if (left + submenuRect.width > viewportWidth) {
            left = parentRect.left - submenuRect.width;
        }

        this.renderer.setStyle(this.submenuDropdown.nativeElement, 'left', `${left - parentRect.left}px`);
        this.renderer.setStyle(this.submenuDropdown.nativeElement, 'top', '0');
        this.renderer.setStyle(this.submenuDropdown.nativeElement, 'visibility', 'visible');
    }

    public toggleDropdown(active: boolean) {
        if (this.children) {
            this.children.forEach((childMenu) => {
                childMenu.toggle(false, undefined, true);
            });
        }
        if (this.parent && active) {
            this.parent.toggle(active, this);
        }

        if (this.menuItem.showOnly && active) {
            this.navigation.showOnly(this.menuItem);
        }

        if (this.level > SUBMENU_START_LEVEL) {
            setTimeout(() => this.positionSubmenu(), 0);
        }
    }

    public toggleSubmenu(title: string, event: MouseEvent) {
        event.stopPropagation();
        if(this.isSubmenuOpen[title] === undefined) {
            this.isSubmenuOpen[title] = true;
        } else {
            this.isSubmenuOpen[title] = !this.isSubmenuOpen[title];
        }

        if (this.isSubmenuOpen[title] && this.level >= 2) {
            setTimeout(() => {
                this.positionSubmenu();
            }, 0);
        }
    }

    public toggle(active: boolean, child?: NavItemComponent, noParent = false, noChildren = false): void {
        this.active = active;
        if (!noChildren && this.children) {
            this.children.forEach((childComponent) => {
                if (child !== undefined) {
                    if (child !== childComponent) {
                        childComponent.toggle(false, undefined, true);
                    }
                } else {
                    childComponent.toggle(active, undefined, true);
                }
            });
        } else if (active) {
            this.navigation.setActiveMenuItem(this.menuItem);
        }

        if (this.parent !== undefined && !noParent) {
            this.parent.toggle(active, this);
        }
    }

    public clicked(event: MouseEvent) {
        if (this.disableHeaders$.getValue()) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: 'ATTENTION',
                    message: 'Are you sure you want to leave data editing mode?',
                    okText: 'OK',
                    cancelText: 'Cancel',
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.disableHeaders$.next(false);
                    this.handleClick(event);
                }
            });
        } else {
            this.handleClick(event);
        }
    }
    private handleClick(event: MouseEvent) {
        if (this.menuItem.clickHandler !== null) {
            const clickResult: boolean = this.menuItem.clickHandler(event, this.navigation, this);
            if (clickResult) {
                this.toggleDropdown(!this._active);
            }
        } else {
            this.toggleDropdown(!this._active);
        }
        this.setupRoute();
    }

    public isActive(currentRoute: string): boolean {
        if (StringUtils.isEmpty(currentRoute)) {
            return false;
        }
        if (StringUtils.cleanLinkString(currentRoute) === this.menuItem.link) {
            if (this.hasQuery) {
                let active = false;
                this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
                    if (StringUtils.deepCompare(this.menuItem.queryParams, params)) {
                        active = true;
                    }
                });
                return active;
            } else {
                return true;
            }
        } else if (this.menuItem.pathMatch === 'partial' && StringUtils.startsWith(currentRoute, this.menuItem.link)) {
            return true;
        }
        return false;
    }

    public get active(): boolean {
        return this._active || (StringUtils.isEmpty(this._currentRoute) ? false : this.isActive(this._currentRoute));
    }

    public get height(): number {
        let addedHeight = 0;
        if (this.children) {
            this.children.forEach((childComponent) => {
                if (childComponent.active) {
                    addedHeight += childComponent.height;
                }
            });
        }
        return this.menuItem.children.length * (this.menuItem.shrinkDisplayHeight ? 36 : 48) + addedHeight;
    }

    public get levelClass(): string {
        if (this.level < 4) {
            return `level${this.level}`;
        }
        return 'level5';
    }

    public get showIcon(): boolean {
        return (
            !(!this.menuItem || !this.menuItem.icon || StringUtils.isEmpty(this.menuItem.icon)) &&
            (!this.menuItem.showOnly || this.parent === undefined || this.parent.parent === undefined)
        );
    }

    public get hasExternalLink(): boolean {
        if (!this.menuItem) {
            return false;
        }

        return (
            !StringUtils.isEmpty(this.menuItem.link) &&
            (StringUtils.startsWith(this.menuItem.link, 'http://') ||
                StringUtils.startsWith(this.menuItem.link, 'https://'))
        );
    }

    public get hasLink(): boolean {
        if (!this.menuItem || this.hasExternalLink) {
            return false;
        }
        return !StringUtils.isEmpty(this.menuItem.link);
    }

    public get hasChildren(): boolean {
        if (!this.menuItem) {
            return false;
        }
        return this.menuItem.children.length > 0;
    }

    public get hasQuery(): boolean {
        if (!this.menuItem) {
            return false;
        }
        return !this.menuItem.queryParams ? false : Object.getOwnPropertyNames(this.menuItem.queryParams).length !== 0;
    }

    private setupRoute(): void {
        if (!this.hasLink && !this.hasExternalLink) {
            return;
        }

        // get current query params for customer
        const currentCustomerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));

        // get current query params for location group
        const locationGroupId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam));

        // get current query params for showing all locations
        const isShowAllLocations = Number(
            this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
        );

        // get global query params
        const appQueryParams = <AppQueryParams>{
            c: currentCustomerId,
            lg: locationGroupId || undefined,
            lt: isShowAllLocations || undefined,
        };

        // merge with items query params
        const queryParams = Object.assign(this.menuItem.queryParams || {}, appQueryParams);

        // get the path if there is a link or return empty array for current path
        const path = this.menuItem.link ? [this.menuItem.link] : [];
        // open marketing popup if customer or user does not have sliicer permission
        if (path[0].indexOf('sliicer') > 0) {
            if (!this.sliicerPagepermission) {
                this.dialog.open(SliicerMarketingScreenComponent, {
                    disableClose: true,
                    data: currentCustomerId,
                });
                return;
            }
        }
        // Open alert announcment popup (us #28541)
        if (path[0].indexOf('adminalert') > 0) {
            this.dialog.open(AnnouncementComponent);
            return;
        }
        // navigate
        this.router.navigate(path, {
            queryParams: queryParams,
            relativeTo: this.activatedRoute,
        });
    }

    /**
     * Code Changes for on load nav select
     * Menu active tab
     * 
     * TODO: Put mapper function instead of indexOf > -1
     */
    private setActiveTab() {
        const url = this.router.url || '';
        if (this.elements && this.elements.nativeElement && this.elements.nativeElement.parentElement) {
            if (url.indexOf('menuDashboard') > -1 || url.indexOf('viewLocationDetails') > -1) {
                this.selectedItem = ActiveMenu.MenuDashboard;
            } else if (url.indexOf('dashboard') > -1) {
                this.selectedItem = ActiveMenu.Home;
            } else if (url.indexOf('vault') > -1) {
                this.selectedItem = ActiveMenu.Vault;
            } else if (url.indexOf('report') > -1) {
                this.selectedItem = ActiveMenu.Report;
            } else if (url.indexOf('sliicer') > -1) {
                this.selectedItem = ActiveMenu.Slicer;
            } else if (url.indexOf('admin') > -1) {
                this.selectedItem = ActiveMenu.Admin;
            } else if (url.indexOf(BLOCKAGE_PREDICT_LINK)) {
                this.selectedItem = ActiveMenu.BlockagePredict;
            }
        }
    }
}
