<div
    id="dialogBackground"
    *ngIf="(scheduleCollectionService.addDialogIsOpened ||
            domOperationUtilsService.dailyDialogOpened ||
            domOperationUtilsService.customDashboardDialogOpened ||
            (domOperationUtilsService.gisDialogOpened | async) ||
            (domOperationUtilsService.ftpDialogOpened | async) ||
            domOperationUtilsService.blockEditorOpened ||
            domOperationUtilsService.vaultDialog ||
            domOperationUtilsService.rollbackDialog) && !showHintOverlay"
>
    <div class="dialog-background-area"></div>
</div>
<div
    id="hintOverlay"
    [ngClass]="{ 'widget-hint-overflow': hintClass === 'data-communication-hint' }"
>
    <div class="hintOverlayArea">
        <div
            class="topNavOverlay"
            [ngClass]="{
                'hidden-hint': domOperationUtilsService.tabChangeInProgress,
                'visible-hint': !domOperationUtilsService.tabChangeInProgress
            }"
        >
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="customer-selector-hint-arrow"
            />
            <div class="customer-selector-hint-text w-350">
                {{ 'TOP_NAV_HINT.CUSTOMER_SELECTOR_HINT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="location-group-hint-arrow"
            />
            <div class="location-group-hint-text w-350">
                {{ 'TOP_NAV_HINT.LOCATION_GROUP_HINT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="active-inactive-hint-arrow"
            />
            <div class="active-inactive-hint-text w-200">
                {{ 'TOP_NAV_HINT.INACTIVE_HINT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="notification-hint-arrow"
            />
            <div class="notification-hint-text w-120">
                {{ 'TOP_NAV_HINT.NOTIFICATIONS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="user-hint-arrow"
            />
            <div class="user-hint-text w-100">
                {{ 'TOP_NAV_HINT.USER_OPTIONS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="pin-hint-arrow"
            />
            <div class="pin-hint-text w-200">
                {{ 'TOP_NAV_HINT.PIN_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="widgets-hint-arrow"
            />
            <div class="widgets-hint-text w-300">
                {{ 'TOP_NAV_HINT.WIDGET_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="auto-review-hint-arrow"
            />
            <div class="auto-review-hint-text w-30vw">
                {{ 'TOP_NAV_HINT.AUTO_REVIEW_TILE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="blockage-predict-hint-arrow"
            />
            <div class="blockage-predict-hint-text w-30vw">
                {{ 'TOP_NAV_HINT.BLOCKAGE_PREDICT_TILE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-hint-arrow"
            />
            <div class="data-comm-hint-text w-30vw">
                {{ 'TOP_NAV_HINT.DATA_COMM_TILE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-widget-hint-arrow"
            />
            <div class="event-widget-hint-text w-30vw">
                {{ 'HOME.EVENT_WIDGET.EVENTS_TILE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-hint-arrow"
            />
            <div class="battery-hint-text w-30vw">
                {{ 'TOP_NAV_HINT.BATTERY_TILE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-hint-arrow"
            />
            <div class="alarm-hint-text w-350">
                {{ 'TOP_NAV_HINT.ALARMS_TILE' | translate }}
            </div>

            <ng-container *ngIf="domOperationUtilsService.isHomePageWidgetToggled && domOperationUtilsService.homePageSelectedWidget === widgetOptions.EVENT">
                <!-- events widget hints -->
                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="add-event-hint-arrow"
                />
                <div class="add-event-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.ADD_NEW_EVENT_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-filters-hint-arrow"
                />
                <div class="event-filters-hint-text w-150">
                    {{ 'HOME.EVENT_WIDGET.EVENTS_FILTER_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-full-hint-arrow"
                />
                <div class="event-full-hint-text w-150">
                    {{ 'HOME.EVENT_WIDGET.EVENT_FULL_HINT' | translate }}
                </div>

                <!-- <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="event-full-close-hint-arrow" />
                <div class="event-full-close-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_FULL_CLOSE_HINT' | translate }}
                </div> -->

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-close-hint-arrow"
                />
                <div class="event-close-hint-text w-200">
                    {{ 'HOME.EVENT_WIDGET.EVENT_CLOSE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-description-hint-arrow"
                />
                <div class="event-description-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_DESC_HINT' | translate }}
                </div>

                <!-- <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="event-type-hint-arrow" />
                <div class="event-type-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_TYPE_HINT' | translate }}
                </div> -->

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-locations-hint-arrow"
                />
                <div class="event-locations-hint-text w-200">
                    {{ 'HOME.EVENT_WIDGET.EVENT_LOCATIONS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-started-at-hint-arrow"
                />
                <div class="event-started-at-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_STARTED_AT_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-duration-hint-arrow"
                />
                <div class="event-duration-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_DURATION_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-gis-hint-arrow"
                />
                <div class="event-gis-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_GIS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-edit-hint-arrow"
                />
                <div class="event-edit-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_EDIT_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-delete-hint-arrow"
                />
                <div class="event-delete-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_DELETE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-filter-locations-hint-arrow"
                />
                <div class="event-filter-locations-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_FILTER_LOCATIONS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-filter-date-hint-arrow"
                />
                <div class="event-filter-date-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_FILTER_DATE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-filter-type-hint-arrow"
                />
                <div class="event-filter-type-hint-text w-100">
                    {{ 'HOME.EVENT_WIDGET.EVENT_FILTER_TYPE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-filter-duration-hint-arrow"
                />
                <div class="event-filter-duration-hint-text w-200">
                    {{ 'HOME.EVENT_WIDGET.EVENT_DURATION_FILTER_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="event-paginator-hint-arrow"
                />
                <div class="event-paginator-hint-text w-250">
                    {{ 'HOME.EVENT_WIDGET.EVENT_PAGINATOR_HINT' | translate }}
                </div>
            </ng-container>
            <!-- Blockage predict widget quick help -->
            <ng-container *ngIf="domOperationUtilsService.isHomePageWidgetToggled && domOperationUtilsService.homePageSelectedWidget === widgetOptions.BLOCKAGE_PREDICT">
                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-filter-hint-arrow"
                />
                <div class="blockage-filter-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_CLOSE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-close-hint-arrow"
                />
                <div class="blockage-close-hint-text w-300">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_FILTER_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-location-hint-arrow"
                />
                <div class="blockage-location-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_LOCATION_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-date-hint-arrow"
                />
                <div class="blockage-date-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_DATE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-status-hint-arrow"
                />
                <div class="blockage-status-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_STATUS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-dtrend-hint-arrow"
                />
                <div class="blockage-dtrend-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_DTREND_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-paginator-hint-arrow"
                />
                <div class="blockage-paginator-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_PAGINATOR_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-filter-start-hint-arrow"
                />
                <div class="blockage-filter-start-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_FILTER_START_DATE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-filter-end-hint-arrow"
                />
                <div class="blockage-filter-end-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_FILTER_END_DATE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-filter-locations-hint-arrow"
                />
                <div class="blockage-filter-locations-hint-text w-300">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_FILTER_LOCATIONS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="blockage-filter-status-hint-arrow"
                />
                <div class="blockage-filter-status-hint-text w-200">
                    {{ 'TOP_NAV_HINT.BLOCKAGE_FILTER_STATUS_HINT' | translate }}
                </div>
            </ng-container>

            <!-- Auto review widget quick help -->
            <ng-container *ngIf="domOperationUtilsService.isHomePageWidgetToggled && domOperationUtilsService.homePageSelectedWidget === widgetOptions.AUTO_REVIEW">
                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-filter-hint-arrow"
                />
                <div class="ar-filter-hint-text w-200">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_FILTERS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-full-hint-arrow"
                />
                <div class="ar-full-hint-text w-200">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_FULL_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-full-close-hint-arrow"
                />
                <div class="ar-full-close-hint-text w-200">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_FULL_CLOSE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-close-hint-arrow"
                />
                <div class="ar-close-hint-text w-300">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_CLOSE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-start-hint-arrow"
                />
                <div class="ar-start-hint-text w-200">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_START_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-end-hint-arrow"
                />
                <div class="ar-end-hint-text w-200">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_END_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-locations-hint-arrow"
                />
                <div class="ar-locations-hint-text w-200">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_LOCATIONS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-reasons-hint-arrow"
                />
                <div class="ar-reasons-hint-text w-300">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_REASONS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-lc-hint-arrow"
                />
                <div class="ar-lc-hint-text w-150">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_LC_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-date-hint-arrow"
                />
                <div class="ar-date-hint-text w-100">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_DATE_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-status-hint-arrow"
                />
                <div class="ar-status-hint-text w-120">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_STATUS_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-error-hint-arrow"
                />
                <div class="ar-error-hint-text w-100">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_ERROR_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-reason-hint-arrow"
                />
                <div class="ar-reason-hint-text">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_REASON_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-graph-hint-arrow"
                />
                <div class="ar-graph-hint-text">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_GRAPH_HINT' | translate }}
                </div>

                <img
                    [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                    class="ar-paginator-hint-arrow"
                />
                <div class="ar-paginator-hint-text">
                    {{ 'HOME.AUTO_REVIEW_TILE.AR_PAGINATOR_HINT' | translate }}
                </div>
            </ng-container>
        </div>

        <div class="customDashboardHintOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-print-hint-arrow"
            />
            <div class="cd-print-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.PRINT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-add-hint-arrow"
            />
            <div class="cd-add-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.ADD_GRAPH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-full-hint-arrow"
            />
            <div class="cd-full-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.EXPAND_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-edit-hint-arrow"
            />
            <div class="cd-edit-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.EDIT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-delete-hint-arrow"
            />
            <div class="cd-delete-hint-text w-100">
                {{ 'CUSTOM_DASHBOARD.DELETE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-zoom-hint-arrow"
            />
            <div class="cd-zoom-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.ZOOM_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-tracer-hint-arrow"
            />
            <div class="cd-tracer-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.TRACER_HINT' | translate }}
            </div>
        </div>

        <div class="customDashboardDialogHinrOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-hg-hint-arrow"
            />
            <div class="cd-hg-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.HG_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-sg-hint-arrow"
            />
            <div class="cd-sg-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.SG_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-name-hint-arrow"
            />
            <div class="cd-dialog-name-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.NAME_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-date-hint-arrow"
            />
            <div class="cd-dialog-date-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.DATE_RANGE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-rolling-hint-arrow"
            />
            <div class="cd-dialog-rolling-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.ROLLING_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-entity-hint-arrow"
            />
            <div class="cd-dialog-entity-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-color-hint-arrow"
            />
            <div class="cd-dialog-color-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.COLOR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-delete-hint-arrow"
            />
            <div class="cd-dialog-delete-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.DELETE_SERIES_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-add-more-hint-arrow"
            />
            <div class="cd-dialog-add-more-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.ADD_SERIES_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-y-entity-hint-arrow"
            />
            <div class="cd-dialog-y-entity-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.Y_ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-x-entity-hint-arrow"
            />
            <div class="cd-dialog-x-entity-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.X_ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-data-averag-hint-arrow"
            />
            <div class="cd-dialog-data-averag-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.DATA_AVERAG_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-close-hint-arrow"
            />
            <div class="cd-dialog-close-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.CANCEL_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-save-hint-arrow"
            />
            <div class="cd-dialog-save-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.SAVE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cd-dialog-location-hint-arrow"
            />
            <div class="cd-dialog-location-hint-text w-200">
                {{ 'CUSTOM_DASHBOARD.LOCATION_HINT' | translate }}
            </div>
        </div>

        <div class="dataCommunationOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-filter-hint-arrow"
            />
            <div class="data-comm-filter-hint-text w-100">
                {{ 'HOME.COLLECTION_TILE.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-full-hint-arrow"
            />
            <div class="data-comm-full-hint-text w-200">
                {{ 'HOME.COLLECTION_TILE.FULLSCREEN_HINT' | translate }}
                <b>{{ 'HOME.COLLECTION_TILE.DATA_COMM_WIDGET' | translate }}</b>
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-close-hint-arrow"
            />
            <div class="data-comm-close-hint-text w-150">
                {{ 'HOME.COLLECTION_TILE.CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-title-hint-arrow"
            />
            <div class="data-comm-title-hint-text w-200">
                {{ 'HOME.COLLECTION_TILE.TITLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-headers-hint-arrow"
            />
            <div class="data-comm-headers-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.MONITOR_SERIES' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-failed-hint-arrow"
            />
            <div class="data-comm-failed-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.FAILED' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-attempted-hint-arrow"
            />
            <div class="data-comm-attempted-hint-text w-250">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.ATTEMPTED' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-item-hint-arrow"
            />
            <div class="data-comm-item-hint-text w-250">
                {{ 'HOME.COLLECTION_TILE.LOCATION_LIST_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-schedule-hint-arrow"
            />
            <div class="data-comm-schedule-hint-text w-450">
                {{ 'HOME.COLLECTION_TILE.SCHEDULE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-collect-hint-arrow"
            />
            <div class="data-comm-collect-hint-text w-200">
                {{ 'HOME.COLLECTION_TILE.COLLECT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-date-hint-arrow"
            />
            <div class="data-comm-date-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.DATE_HINT_1' | translate }}
                <b>{{ 'HOME.COLLECTION_TILE.FAILED' | translate }}</b><span>, </span> <b>{{
                    'HOME.COLLECTION_TILE.ATTEMPTED' | translate }}</b> {{ 'HOME.COLLECTION_TILE.AND' | translate }} <b>{{ 'HOME.COLLECTION_TILE.SUCCESS' | translate }}</b> {{ 'HOME.COLLECTION_TILE.DATE_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-series-hint-arrow"
            />
            <div class="data-comm-series-hint-text w-200">
                {{ 'HOME.COLLECTION_TILE.SERIES_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-locations-hint-arrow"
            />
            <div class="data-comm-locations-hint-text w-200">
                {{ 'HOME.COLLECTION_TILE.LOCATION_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-footer-schedule-hint-arrow"
            />
            <div class="data-comm-footer-schedule-hint-text w-200">
                {{ 'HOME.COLLECTION_TILE.SCHEDULE_FOOTER_HINT_1' | translate }}
                <b>{{ 'HOME.COLLECTION_TILE.SCHEDULE_COLLECT_DASHBOARD' | translate }}</b> {{ 'HOME.COLLECTION_TILE.SCHEDULE_FOOTER_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-comm-footer-download-hint-arrow"
            />
            <div class="data-comm-footer-download-hint-text w-450">
                {{ 'HOME.COLLECTION_TILE.DOWNLOAD_HINT' | translate }}
            </div>
        </div>

        <div class="customerEditorOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-filter-hint-arrow"
            />
            <div class="cus-filter-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-filter-feature-hint-arrow"
            />
            <div class="cus-filter-feature-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.ASSIGNED_FEATS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-filter-inactive-hint-arrow"
            />
            <div class="cus-filter-inactive-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.INACTIVE_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-search-hint-arrow"
            />
            <div class="cus-search-hint-text w-350">
                {{ 'ADMIN.ADMIN_CUSTOMER.SEARCH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-name-hint-arrow"
            />
            <div class="cus-name-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.NAME_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-locs-hint-arrow"
            />
            <div class="cus-locs-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.ACTIVE_LOCS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-inactive-locs-hint-arrow"
            />
            <div class="cus-inactive-locs-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.INACTIVE_LOCS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-feats-hint-arrow"
            />
            <div class="cus-feats-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.SUBSCRIPTION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-inactive-hint-arrow"
            />
            <div class="cus-inactive-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.INACTIVE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-edit-hint-arrow"
            />
            <div class="cus-edit-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.EDIT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-add-hint-arrow"
            />
            <div class="cus-add-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.ADD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="cus-paginator-hint-arrow"
            />
            <div class="cus-paginator-hint-text w-200">
                {{ 'ADMIN.ADMIN_CUSTOMER.PAGINATOR_HINT' | translate }}
            </div>
        </div>


        <div class="autoReviewOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-filter-hint-arrow"
            />
            <div class="ar-filter-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_FILTERS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-full-hint-arrow"
            />
            <div class="ar-full-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_FULL_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-full-close-hint-arrow"
            />
            <div class="ar-full-close-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_FULL_CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-close-hint-arrow"
            />
            <div class="ar-close-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_EXPANDED_CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-start-hint-arrow"
            />
            <div class="ar-start-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_START_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-end-hint-arrow"
            />
            <div class="ar-end-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_END_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-locations-hint-arrow"
            />
            <div class="ar-locations-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_LOCATIONS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-reasons-hint-arrow"
            />
            <div class="ar-reasons-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_REASONS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-lc-hint-arrow"
            />
            <div class="ar-lc-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_LC_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-date-hint-arrow"
            />
            <div class="ar-date-hint-text w-100">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-status-hint-arrow"
            />
            <div class="ar-status-hint-text w-120">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_STATUS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-error-hint-arrow"
            />
            <div class="ar-error-hint-text w-150">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_ERROR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-reason-hint-arrow"
            />
            <div class="ar-reason-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_REASON_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-graph-hint-arrow"
            />
            <div class="ar-graph-hint-text w-200">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_GRAPH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ar-paginator-hint-arrow"
            />
            <div class="ar-paginator-hint-text w-300">
                {{ 'HOME.AUTO_REVIEW_TILE.AR_PAGINATOR_HINT' | translate }}
            </div>
        </div>

        <div class="alarmsWidgetCollapsedOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-title-hint-arrow"
            />
            <div class="alarm-sm-title-hint-text w-350">
                {{ 'HOME.ALARMS_TILE.TITLE_HINT_1' | translate }}
                <b>{{ 'HOME.ALARMS_TILE.TITLE_HINT_2' | translate }}</b> {{ 'HOME.ALARMS_TILE.TITLE_HINT_3' | translate }}
                <b>{{ 'HOME.ALARMS_TILE.TITLE_HINT_4' | translate }}</b> {{ 'HOME.ALARMS_TILE.TITLE_HINT_5' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-expand-hint-arrow"
            />
            <div class="alarm-sm-expand-hint-text w-350">
                {{ 'HOME.ALARMS_TILE.EXPAND_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-close-hint-arrow"
            />
            <div class="alarm-sm-close-hint-text w-350">
                {{ 'HOME.ALARMS_TILE.CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-status-hint-arrow"
            />
            <div class="alarm-sm-status-hint-text w-200">
                {{ 'HOME.ALARMS_TILE.STATUS_HINT_1' | translate }}
                <b>{{ 'HOME.ALARMS_TILE.STATUS_HINT_2' | translate }}</b> {{ 'HOME.ALARMS_TILE.STATUS_HINT_3' | translate }}
                <b>{{ 'HOME.ALARMS_TILE.STATUS_HINT_4' | translate }}</b> {{ 'HOME.ALARMS_TILE.STATUS_HINT_5' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-type-hint-arrow"
            />
            <div class="alarm-sm-type-hint-text w-150">
                {{ 'HOME.ALARMS_TILE.TYPE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-date-hint-arrow"
            />
            <div class="alarm-sm-date-hint-text w-350">
                {{ 'HOME.ALARMS_TILE.DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-location-hint-arrow"
            />
            <div class="alarm-sm-location-hint-text w-350">
                {{ 'HOME.ALARMS_TILE.LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-graph-hint-arrow"
            />
            <div class="alarm-sm-graph-hint-text w-350">
                {{ 'HOME.ALARMS_TILE.GRAPH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-sm-map-hint-arrow"
            />
            <div class="alarm-sm-map-hint-text w-350">
                {{ 'HOME.ALARMS_TILE.MAP_LOCATION_HINT' | translate }}
            </div>
        </div>

        <div class="eventsWidgetOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add-event-widget-hint-arrow"
            />
            <div class="add-event-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.ADD_NEW_EVENT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-filters-widget-hint-arrow"
            />
            <div class="event-filters-widget-hint-text w-150">
                {{ 'HOME.EVENT_WIDGET.EVENTS_FILTER_HINT' | translate }}
            </div>

            <!-- <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="event-full-widget-hint-arrow" />
            <div class="event-full-widget-hint-text w-150">
                {{ 'HOME.EVENT_WIDGET.EVENT_FULL_HINT' | translate }}
            </div> -->

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-full-close-widget-hint-arrow"
            />
            <div class="event-full-close-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_FULL_CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-close-widget-hint-arrow"
            />
            <div class="event-close-widget-hint-text w-200">
                {{ 'HOME.EVENT_WIDGET.EVENT_CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-description-widget-hint-arrow"
            />
            <div class="event-description-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_DESC_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-type-widget-hint-arrow"
            />
            <div class="event-type-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_TYPE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-locations-widget-hint-arrow"
            />
            <div class="event-locations-widget-hint-text w-200">
                {{ 'HOME.EVENT_WIDGET.EVENT_LOCATIONS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-started-at-widget-hint-arrow"
            />
            <div class="event-started-at-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_STARTED_AT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-duration-widget-hint-arrow"
            />
            <div class="event-duration-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_DURATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-gis-widget-hint-arrow"
            />
            <div class="event-gis-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_GIS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-edit-widget-hint-arrow"
            />
            <div class="event-edit-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_EDIT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-delete-widget-hint-arrow"
            />
            <div class="event-delete-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_DELETE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-filter-locations-widget-hint-arrow"
            />
            <div class="event-filter-locations-widget-hint-text w-200">
                {{ 'HOME.EVENT_WIDGET.EVENT_FILTER_LOCATIONS_HINT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-filter-date-widget-hint-arrow"
            />

            <div class="event-filter-date-widget-hint-text w-200">
                {{ 'HOME.EVENT_WIDGET.EVENT_FILTER_DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-filter-type-widget-hint-arrow"
            />
            <div class="event-filter-type-widget-hint-text w-100">
                {{ 'HOME.EVENT_WIDGET.EVENT_FILTER_TYPE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-filter-duration-widget-hint-arrow"
            />
            <div class="event-filter-duration-widget-hint-text w-200">
                {{ 'HOME.EVENT_WIDGET.EVENT_DURATION_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="event-paginator-widget-hint-arrow"
            />
            <div class="event-paginator-widget-hint-text w-250">
                {{ 'HOME.EVENT_WIDGET.EVENT_PAGINATOR_HINT' | translate }}
            </div>
        </div>

        <div class="alarmsWidgetOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-title-hint-arrow"
            />
            <div class="alarm-title-hint-text w-350">
                {{ 'HOME.ALARM_WIDGET.TITLE_HINT_1' | translate }}
                <b>{{ 'HOME.ALARM_WIDGET.TITLE_HINT_2' | translate }}</b> {{ 'HOME.ALARM_WIDGET.TITLE_HINT_3' | translate }}
                <b>{{ 'HOME.ALARM_WIDGET.TITLE_HINT_4' | translate }}</b> {{ 'HOME.ALARM_WIDGET.TITLE_HINT_5' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-filter-hint-arrow"
            />
            <div class="alarm-filter-hint-text w-150">
                {{ 'HOME.ALARM_WIDGET.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-collapse-hint-arrow"
            />
            <div class="alarm-collapse-hint-text w-200">
                {{ 'HOME.ALARM_WIDGET.COLLAPSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-close-hint-arrow"
            />
            <div class="alarm-close-hint-text w-150">
                {{ 'HOME.ALARM_WIDGET.CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-status-hint-arrow"
            />
            <div class="alarm-status-hint-text w-350">
                {{ 'HOME.ALARM_WIDGET.STATUS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-type-hint-arrow"
            />
            <div class="alarm-type-hint-text w-350">
                {{ 'HOME.ALARM_WIDGET.TYPE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-date-hint-arrow"
            />
            <div class="alarm-date-hint-text w-200">
                {{ 'HOME.ALARM_WIDGET.DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-location-hint-arrow"
            />
            <div class="alarm-location-hint-text w-350">
                {{ 'HOME.ALARM_WIDGET.LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-location-card-hint-arrow"
            />
            <div class="alarm-location-card-hint-text w-350">
                {{ 'HOME.ALARM_WIDGET.LOCATION_CARD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-graph-hint-arrow"
            />
            <div class="alarm-graph-hint-text w-350">
                {{ 'HOME.ALARM_WIDGET.GRAPH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm-download-hint-arrow"
            />
            <div class="alarm-download-hint-text w-200">
                {{ 'HOME.ALARM_WIDGET.DOWNLOAD_HINT' | translate }}
            </div>
        </div>

        <div class="DataCommCollapsedOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-title-hint-arrow"
            />
            <div class="dcomm-title-hint-text w-250">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.WIDGET.TITLE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-full-hint-arrow"
            />
            <div class="dcomm-full-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.WIDGET.FULL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-close-hint-arrow"
            />
            <div class="dcomm-close-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.WIDGET.CLOSE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-location_s_c-hint-arrow"
            />
            <div class="dcomm-location_s_c-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.WIDGET.LOCATION_S_C' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-location_c-hint-arrow"
            />
            <div class="dcomm-location_c-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.WIDGET.LOCATION_C' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-schedule-hint-arrow"
            />
            <div class="dcomm-schedule-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.WIDGET.SCHEDULE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-collect-all-hint-arrow"
            />
            <div class="dcomm-collect-all-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.WIDGET.COLLECT_ALL' | translate }}
            </div>
        </div>

        <div class="DataCommExpandedOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-title-hint-arrow"
            />
            <div class="dcomm-title-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.TITLE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-start-end-hint-arrow"
            />
            <div class="dcomm-start-end-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.START_END' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-monitor-series-hint-arrow"
            />
            <div class="dcomm-monitor-series-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.MONITOR_SERIES' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-location-hint-arrow"
            />
            <div class="dcomm-location-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.LOCATION' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-attempted-hint-arrow"
            />
            <div class="dcomm-attempted-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.ATTEMPTED' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-success-hint-arrow"
            />
            <div class="dcomm-success-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.SUCCESS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-location_s_c-hint-arrow"
            />
            <div class="dcomm-location_s_c-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.LOCATION_S_C' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-location_c-hint-arrow"
            />
            <div class="dcomm-location_c-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.LOCATION_C' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-schedule-hint-arrow"
            />
            <div class="dcomm-schedule-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.SCHEDULE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-collect-all-hint-arrow"
            />
            <div class="dcomm-collect-all-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.COLLECT_ALL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-filter-hint-arrow"
            />
            <div class="dcomm-filter-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.FILTER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-collapse-report-hint-arrow"
            />
            <div class="dcomm-collapse-report-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.COLLAPSE_REPORT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dcomm-close-hint-arrow"
            />
            <div class="dcomm-close-hint-text w-350">
                {{ 'HOME.COLLECTION_TILE.HELP_UI.EXPANDED.CLOSE' | translate }}
            </div>
        </div>

        <div class="batteryStatusCollapsedOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-title-hint-arrow"
            />
            <div class="battery-sm-title-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.TITLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-filter-hint-arrow"
            />
            <div class="battery-sm-filter-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-expand-hint-arrow"
            />
            <div class="battery-sm-expand-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.EXPAND_HINT_1' | translate }}
                <b>{{ 'HOME.BATTERY_TILE.EXPAND_HINT_2' | translate }}</b>
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-close-hint-arrow"
            />
            <div class="battery-sm-close-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-location-hint-arrow"
            />
            <div class="battery-sm-location-hint-text w-200">
                {{ 'HOME.BATTERY_TILE.LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-series-hint-arrow"
            />
            <div class="battery-sm-series-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.SERIES_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-voltage-hint-arrow"
            />
            <div class="battery-sm-voltage-hint-text w-600">
                {{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_1' | translate }}
                <b>{{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_2' | translate }}</b> {{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_3' | translate }}
                <b>{{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_4' | translate }}</b> {{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_5' | translate }}
                <b>{{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_6' | translate }}</b> {{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_7' | translate }}
                <b>{{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_8' | translate }}</b> {{ 'HOME.BATTERY_TILE.VOLTAGE_HINT_9' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-date-hint-arrow"
            />
            <div class="battery-sm-date-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-buy-now-hint-arrow"
            />
            <div class="battery-sm-buy-now-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.BUY_NOW_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-location-filter-hint-arrow"
            />
            <div class="battery-sm-location-filter-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.LOCATION_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-sm-status-filter-hint-arrow"
            />
            <div class="battery-sm-status-filter-hint-text w-350">
                {{ 'HOME.BATTERY_TILE.STATUS_FILTER_HINT' | translate }}
            </div>
        </div>

        <div class="batteryStatusOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-title-hint-arrow"
            />
            <div class="battery-title-hint-text w-350">
                {{ 'HOME.BATTERY_WIDGET.TITLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-filter-hint-arrow"
            />
            <div class="battery-filter-hint-text w-200">
                {{ 'HOME.BATTERY_WIDGET.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-fullscreen-hint-arrow"
            />
            <div class="battery-fullscreen-hint-text w-350">
                {{ 'HOME.BATTERY_WIDGET.FULLSCREEN_HINT' | translate }}
                <b>{{ 'HOME.BATTERY_WIDGET.BATTERY_STATUS_WIDGET' | translate }}</b>
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-close-hint-arrow"
            />
            <div class="battery-close-hint-text w-200">
                {{ 'HOME.BATTERY_WIDGET.CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-locations-hint-arrow"
            />
            <div class="battery-locations-hint-text w-350">
                {{ 'HOME.BATTERY_WIDGET.LOCATIONS_HINT_1' | translate }}
                <b>{{ 'HOME.BATTERY_WIDGET.VOLTAGE' | translate }}</b> {{ 'HOME.BATTERY_WIDGET.LOCATIONS_HINT_2' | translate }}
                <b>{{ 'HOME.BATTERY_WIDGET.RED' | translate }}</b> {{ 'HOME.BATTERY_WIDGET.LOCATIONS_HINT_3' | translate }}
                <b>{{ 'HOME.BATTERY_WIDGET.YELLOW' | translate }}</b> {{ 'HOME.BATTERY_WIDGET.LOCATIONS_HINT_4' | translate }}
                <b>{{ 'HOME.BATTERY_WIDGET.GREEN' | translate }}</b> {{ 'HOME.BATTERY_WIDGET.LOCATIONS_HINT_5' | translate }}
                <b>{{ 'HOME.BATTERY_WIDGET.GREY' | translate }}</b> {{ 'HOME.BATTERY_WIDGET.LOCATIONS_HINT_6' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-download-hint-arrow"
            />
            <div class="battery-download-hint-text w-200">
                {{ 'HOME.BATTERY_WIDGET.DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-buy-hint-arrow"
            />
            <div class="battery-buy-hint-text w-200">
                {{ 'HOME.BATTERY_WIDGET.BUY_NOW_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-filter-status-hint-arrow"
            />
            <div class="battery-filter-status-hint-text w-350">
                {{ 'HOME.BATTERY_WIDGET.STATUS_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="battery-locations-filter-hint-arrow"
            />
            <div class="battery-locations-filter-hint-text w-350">
                {{ 'HOME.BATTERY_WIDGET.LOCATION_FILTER_HINT' | translate }}
            </div>
        </div>

        <div class="auditLogOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-title-hint-arrow"
            />
            <div class="audit-title-hint-text w-350">
                {{ 'ADMIN.AUDIT_LOG.TITLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-download-hint-arrow"
            />
            <div class="audit-download-hint-text w-200">
                {{ 'ADMIN.AUDIT_LOG.DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-filter-hint-arrow"
            />
            <div class="audit-filter-hint-text w-150">
                {{ 'ADMIN.AUDIT_LOG.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-search-hint-arrow"
            />
            <div class="audit-search-hint-text w-350">
                {{ 'ADMIN.AUDIT_LOG.SEARCH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-date-hint-arrow"
            />
            <div class="audit-date-hint-text w-200">
                {{ 'ADMIN.AUDIT_LOG.DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-locations-hint-arrow"
            />
            <div class="audit-locations-hint-text w-200">
                {{ 'ADMIN.AUDIT_LOG.LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-loc-group-hint-arrow"
            />
            <div class="audit-loc-group-hint-text w-200">
                {{ 'ADMIN.AUDIT_LOG.LOCATION_GROUP_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="audit-item-hint-arrow"
            />
            <div class="audit-item-hint-text w-350">
                {{ 'ADMIN.AUDIT_LOG.ITEM_HINT' | translate }}
            </div>
        </div>

        <div
            class="homePageSecondTabOverlay"
            [ngClass]="{
                'hidden-hint': domOperationUtilsService.tabChangeInProgress,
                'visible-hint': !domOperationUtilsService.tabChangeInProgress
            }"
        >
            <div class="more-options-menu-hint">
                <button
                    mat-menu-item
                    class="zoom-in-hint"
                >
                    <mat-icon>zoom_in</mat-icon>Zoom To
                </button>
                <div class="transparency-hint">
                    <button mat-menu-item>
                        <mat-icon>browser_not_supported</mat-icon>Transparency
                    </button>
                </div>
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="layers-hint-arrow"
            />
            <div class="layers-hint-text w-600">
                {{ 'TOP_NAV_HINT.LAYERS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="basemap-hint-arrow"
            />
            <div class="basemap-hint-text w-200">
                {{ 'TOP_NAV_HINT.BASEMAP_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add-monitor-hint-arrow"
            />
            <div class="add-monitor-hint-text w-200">
                {{ 'TOP_NAV_HINT.NEW_MONITOR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="monitor-hint-arrow"
            />
            <div class="monitor-hint-text w-200">
                {{ 'TOP_NAV_HINT.MONITOR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="zoom-in-hint-arrow"
            />
            <div class="zoom-in-hint-text w-200">
                {{ 'TOP_NAV_HINT.ZOOM_TO_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="transparency-hint-arrow"
            />
            <div class="transparency-hint-text w-200">
                {{ 'TOP_NAV_HINT.TRANSPARENCY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="feat-select-hint-arrow"
            />
            <div class="feat-select-hint-text w-180">
                {{ 'TOP_NAV_HINT.FEATURE_SELECTION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="show-legend-hint-arrow"
            />
            <div class="show-legend-hint-text w-120">
                {{ 'TOP_NAV_HINT.SHOW_LEGENT_HINT' | translate }}
            </div>
        </div>

        <div
            class="homePageThirdTabOverlay"
            [ngClass]="{
                'hidden-hint': domOperationUtilsService.tabChangeInProgress,
                'visible-hint': !domOperationUtilsService.tabChangeInProgress
            }"
        >
            <div class="selection-menu-mock">
                <button
                    mat-button
                    class="select-feat-hint"
                >
                    <span>Select Feature(s)</span>
                </button>
                <button
                    mat-button
                    class="select-poly-hint"
                >
                    <span>Select Features by Polygon</span>
                </button>
                <button
                    mat-button
                    class="select-radius-hint"
                >
                    <span>Select Features by Radius</span>
                </button>
            </div>
            <div class="measure-menu-mock">
                <button class="measure-area-hint">
                    <mat-icon> format_shapes</mat-icon>
                    <span>Measure Area</span>
                </button>
                <button class="measure-length-hint">
                    <mat-icon>straighten</mat-icon>
                    <span>Measure Length</span>
                </button>
                <button class="measure-clear-hint">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Clear</span>
                </button>
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="tools-hint-arrow"
            />
            <div class="tools-hint-text w-200">
                {{ 'TOP_NAV_HINT.TOOLS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="search-loc-hint-arrow"
            />
            <div class="search-loc-hint-text w-200">
                {{ 'TOP_NAV_HINT.SEARCH_MONITOR' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="map-btn-hint-arrow"
            />
            <div class="map-btn-hint-text w-350">
                {{ 'TOP_NAV_HINT.ZOOM_CONTROLS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="measure-area-hint-arrow"
            />
            <div class="measure-area-hint-text w-350">
                {{ 'TOP_NAV_HINT.MEASURE_AREA_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="measure-length-hint-arrow"
            />
            <div class="measure-length-hint-text w-350">
                {{ 'TOP_NAV_HINT.MEASURE_LENGTH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="measure-clear-hint-arrow"
            />
            <div class="measure-clear-hint-text w-200">
                {{ 'TOP_NAV_HINT.CLEAR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="select-feat-hint-arrow"
            />
            <div class="select-feat-hint-text w-200">
                {{ 'TOP_NAV_HINT.SELECT_FEATURE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="clear-tool-selection-hint-arrow"
            />
            <div class="clear-tool-selection-hint-text w-100">
                {{ 'TOP_NAV_HINT.CLEAR_SELECTION' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="select-poly-hint-arrow"
            />
            <div class="select-poly-hint-text w-150">
                {{ 'TOP_NAV_HINT.SELECT_BY_POLYGON' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="select-radius-hint-arrow"
            />
            <div class="select-radius-hint-text w-200">
                {{ 'TOP_NAV_HINT.SELECT_BY_RADIUS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="map-overview-hint-arrow"
            />
            <div class="map-overview-hint-text w-350">
                {{ 'TOP_NAV_HINT.OVERVIEW_MAP_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="map-scale-hint-arrow"
            />
            <div class="map-scale-hint-text w-350">
                {{ 'TOP_NAV_HINT.MAP_SCALE_HINT' | translate }}
            </div>
        </div>

        <div
            class="homePageFourthTabOverlay"
            [ngClass]="{
                'hidden-hint': domOperationUtilsService.tabChangeInProgress,
                'visible-hint': !domOperationUtilsService.tabChangeInProgress
            }"
        >
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-mon-hint-arrow"
            />
            <div class="edit-mon-hint-text w-350">
                {{ 'TOP_NAV_HINT.EDIT_LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="child-search-hint-arrow"
            />
            <div class="child-search-hint-text w-200">
                {{ 'TOP_NAV_HINT.ZOOM_TO_LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="monitor-name-hint-arrow"
            />
            <div class="monitor-name-hint-text w-200">
                {{ 'TOP_NAV_HINT.LAYER_NAME_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add-mon-hint-arrow"
            />
            <div class="add-mon-hint-text w-250">
                {{ 'TOP_NAV_HINT.ADD_LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="mon-search-hint-arrow"
            />
            <div class="mon-search-hint-text w-350">
                {{ 'TOP_NAV_HINT.ZOOM_TO_MONITOR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="prop-items-hint-arrow"
            />
            <div class="prop-items-hint-text w-200">
                {{ 'TOP_NAV_HINT.PROPERTIES_HINT' | translate }}
            </div>
        </div>

        <div class="usersPageOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="search-users-hint-arrow"
            />
            <div class="search-users-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_SEARCH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="user-status-hint-arrow"
            />
            <div class="user-status-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_STATUS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="user-edit-hint-arrow"
            />
            <div class="user-edit-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_EDIT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="user-add-hint-arrow"
            />
            <div class="user-add-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_ADD_HINT' | translate }}
            </div>
        </div>

        <div class="editUserDetailsOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="userDetailsHint-arrow"
            />
            <div class="userDetailsHint-text w-300">
                {{ 'ADMIN.ADMIN_USERS.USER_DETAILS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="activate-user-hint-arrow"
            />
            <div class="activate-user-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_STATUS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="active-hint-arrow"
            />
            <div class="active-hint-text w-450">
                {{ 'ADMIN.ADMIN_USERS.DETAILS_FORM_HINT' | translate }}
                <br />
                <b>{{ 'ADMIN.ADMIN_USERS.NOTE' | translate }}</b> {{ 'ADMIN.ADMIN_USERS.DETAILS_FORM_NOTE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="save-user-hint-arrow"
            />
            <div class="save-user-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_SAVE_HINT' | translate }}
            </div>
        </div>

        <div class="editUserRoleOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignRolesUserHint-arrow"
            />
            <div class="assignRolesUserHint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_ROLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="roles-hint-arrow"
            />
            <div class="roles-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.ROLES_LIST_HINT' | translate }}
            </div>
        </div>

        <div class="editUserCustomerOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignCustomersUserHint-arrow"
            />
            <div class="assignCustomersUserHint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_CUSTOMER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="customer-search-hint-arrow"
            />
            <div class="customer-search-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.CUSTOMER_SEARCH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="customer-list-hint-arrow"
            />
            <div class="customer-list-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.CUSTOMER_LIST_HINT' | translate }}
            </div>
        </div>

        <div class="editUserLocationOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignLocationsUserHint-arrow"
            />
            <div class="assignLocationsUserHint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="select-all-hint-arrow"
            />
            <div class="select-all-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.SELECT_ALL_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="customer-select-hint-arrow"
            />
            <div class="customer-select-hint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.SELECT_CUSTOMER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="location-list-hint-arrow"
            />
            <div class="location-list-hint-text w-350">
                {{ 'ADMIN.ADMIN_USERS.LOCATION_LIST_HINT' | translate }}
            </div>
        </div>

        <div class="editUserFeaturesOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignFeaturesUserHint-arrow"
            />
            <div class="assignFeaturesUserHint-text w-200">
                {{ 'ADMIN.ADMIN_USERS.USER_FEATURES_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="features-hint-arrow"
            />
            <div class="features-hint-text w-350">
                {{ 'ADMIN.ADMIN_USERS.FEATURE_LIST_HINT' | translate }}
            </div>
        </div>

        <div class="leaderBoardTileOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarmLeaderHintArrow"
            />
            <div class="alarmLeaderHintTest">
                {{ 'LEADERBOARD_SECTION_HINT.ALARM_LEADER_HINT.LINE_1' | translate }} {{ 'LEADERBOARD_SECTION_HINT.ALARM_LEADER_HINT.LINE_2' | translate }} {{ 'LEADERBOARD_SECTION_HINT.ALARM_LEADER_HINT.LINE_3' | translate }} {{ 'LEADERBOARD_SECTION_HINT.ALARM_LEADER_HINT.LINE_4'
                | translate }} {{ 'LEADERBOARD_SECTION_HINT.ALARM_LEADER_HINT.LINE_5' | translate }} {{ 'LEADERBOARD_SECTION_HINT.ALARM_LEADER_HINT.LINE_6' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="batteryLeaderHintArrow"
            />
            <div class="batteryLeaderHintTest">
                {{ 'LEADERBOARD_SECTION_HINT.BATTERY_LEADER_HINT.LINE_1' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BATTERY_LEADER_HINT.LINE_2' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BATTERY_LEADER_HINT.LINE_3' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BATTERY_LEADER_HINT.LINE_4'
                | translate }} {{ 'LEADERBOARD_SECTION_HINT.BATTERY_LEADER_HINT.LINE_5' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BATTERY_LEADER_HINT.LINE_6' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="blockagePDLeaderHintArrow"
            />
            <div class="blockagePDLeaderHintTest">
                {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_1' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_2' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_3' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_4'
                | translate }} {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_5' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_6' | translate }} {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_7' | translate
                }} {{ 'LEADERBOARD_SECTION_HINT.BLOCKAGE_PDLEADER_HINT.LINE_8' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="autoReviewLeaderHintArrow"
            />
            <div class="autoReviewLeaderHintTest">
                {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_1' | translate }} {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_2' | translate }} {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_3' | translate }} {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_4'
                | translate }} {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_5' | translate }} {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_6' | translate }} {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_7' | translate
                }} {{ 'LEADERBOARD_SECTION_HINT.AUTOREVIEW_LEADER_HINT.LINE_8' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="collectionLeaderHintArrow"
            />
            <div class="collectionLeaderHintTest">
                {{ 'LEADERBOARD_SECTION_HINT.COLLECTION_LEADER_HINT.TILE_DESCRIPTION' | translate }}
            </div>
        </div>
        <div
            class="homePageAction"
            [ngClass]="{
                'left-action':
                    hintClass === 'homePageIconHint' ||
                    hintClass === 'homePageSecondTab' ||
                    hintClass === 'homePageThirdTab' ||
                    hintClass === 'homePageFourthTab' ||
                    hintClass === 'alarms-sm-widget-hint' ||
                    hintClass === 'battery-sm-widget-hint'
            }"
        >
            <button
                [disabled]="domOperationUtilsService.tabChangeInProgress || hintClass === 'homePageIconHint'"
                *ngIf="
                    hintClass === 'homePageIconHint' ||
                    hintClass === 'homePageSecondTab' ||
                    hintClass === 'homePageThirdTab' ||
                    hintClass === 'homePageFourthTab' ||
                    hintClass === 'alarms-sm-widget-hint' ||
                    hintClass === 'battery-sm-widget-hint'
                "
                mat-raised-button
                color="accent"
                (click)="homeDashboardChangeOverlay(-1)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                [disabled]="domOperationUtilsService.tabChangeInProgress || hintClass === 'homePageFourthTab'"
                *ngIf="
                    hintClass === 'homePageIconHint' ||
                    hintClass === 'homePageSecondTab' ||
                    hintClass === 'homePageThirdTab' ||
                    hintClass === 'homePageFourthTab' ||
                    hintClass === 'alarms-sm-widget-hint' ||
                    hintClass === 'battery-sm-widget-hint'
                "
                mat-raised-button
                color="accent"
                (click)="homeDashboardChangeOverlay(+1)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button
                *ngIf="hintClass === 'location-dashBoard-edit-hint' || hintClass === 'location-dashBoard-edit-sg-hint' || hintClass === 'de-dialog-hint'"
                mat-raised-button
                color="accent"
                [disabled]="hintClass === 'location-dashBoard-edit-hint'"
                (click)="deOverlayChange(false)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                *ngIf="hintClass === 'location-dashBoard-edit-hint' || hintClass === 'location-dashBoard-edit-sg-hint' || hintClass === 'de-dialog-hint'"
                [disabled]="hintClass === 'de-dialog-hint'"
                mat-raised-button
                color="accent"
                (click)="deOverlayChange(true)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <!-- EDIT CUSTOMER BUTTONS START -->
            <button
                [disabled]="hintClass === 'edit-cus-locations'"
                *ngIf="hintClass === 'edit-cus-locations' || hintClass === 'edit-cus-locaitons-filters'"
                mat-raised-button
                color="accent"
                (click)="editCustomerLocationOverlayChange(false)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                [disabled]="hintClass === 'edit-cus-locaitons-filters'"
                *ngIf="hintClass === 'edit-cus-locations' || hintClass === 'edit-cus-locaitons-filters'"
                mat-raised-button
                color="accent"
                (click)="editCustomerLocationOverlayChange(true)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>


            <button
                [disabled]="hintClass === 'edit-cus-ftp'"
                *ngIf="hintClass === 'edit-cus-ftp' || hintClass === 'edit-cus-ftp-add'"
                mat-raised-button
                color="accent"
                (click)="editCustomerFTPChange(false)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                [disabled]="hintClass === 'edit-cus-ftp-add'"
                *ngIf="hintClass === 'edit-cus-ftp' || hintClass === 'edit-cus-ftp-add'"
                mat-raised-button
                color="accent"
                (click)="editCustomerFTPChange(true)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>


            <button
                [disabled]="hintClass === 'edit-cus-gis'"
                *ngIf="hintClass === 'edit-cus-gis' || hintClass === 'edit-cus-gis-basic' || hintClass === 'edit-cus-gis-custom'"
                mat-raised-button
                color="accent"
                (click)="editCustomerGISchange(false)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                [disabled]="hintClass === 'edit-cus-gis-custom'"
                *ngIf="hintClass === 'edit-cus-gis' || hintClass === 'edit-cus-gis-basic' || hintClass === 'edit-cus-gis-custom'"
                mat-raised-button
                color="accent"
                (click)="editCustomerGISchange(true)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>


            <button
                [disabled]="hintClass === 'edit-cus-rainfall-dry-day'"
                *ngIf="hintClass === 'edit-cus-rainfall-dry-day' ||
                    hintClass === 'edit-cus-rainfall-storm-event' ||
                    hintClass === 'edit-cus-rainfall-infiltration' ||
                    hintClass === 'edit-cus-rainfall-ddf-data' ||
                    hintClass === 'edit-cus-rainfall-ddf-graph'"
                mat-raised-button
                color="accent"
                (click)="editCustomerRainfallChange(-1)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                [disabled]="hintClass === 'edit-cus-rainfall-ddf-graph'"
                *ngIf="hintClass === 'edit-cus-rainfall-dry-day' ||
                    hintClass === 'edit-cus-rainfall-storm-event' ||
                    hintClass === 'edit-cus-rainfall-infiltration' ||
                    hintClass === 'edit-cus-rainfall-ddf-data' ||
                    hintClass === 'edit-cus-rainfall-ddf-graph'"
                mat-raised-button
                color="accent"
                (click)="editCustomerRainfallChange(1)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <!-- EDIT CUSTOMER BUTTONS END -->

            <button
                [disabled]="hintClass === 'view-location-details-hint'"
                *ngIf="hintPageName === 'LOCATION_DETAIL_HINT'"
                mat-raised-button
                color="accent"
                (click)="nextLocationDashboardDetailHintOverlay(false)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                [disabled]="hintClass === 'view-location-advanced-hint'"
                *ngIf="hintPageName === 'LOCATION_DETAIL_HINT'"
                mat-raised-button
                color="accent"
                (click)="nextLocationDashboardDetailHintOverlay(true)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button
                [disabled]="domOperationUtilsService.editUserSelectedTab === 0"
                *ngIf="
                    hintClass === 'edit-user-details' ||
                    hintClass === 'edit-user-role' ||
                    hintClass === 'edit-user-location' ||
                    hintClass === 'edit-user-features' ||
                    hintClass === 'edit-user-customer'
                "
                mat-raised-button
                color="accent"
                (click)="editUserTabChange(-1)"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
                *ngIf="
                    hintClass === 'edit-user-details' ||
                    hintClass === 'edit-user-role' ||
                    hintClass === 'edit-user-location' ||
                    hintClass === 'edit-user-features' ||
                    hintClass === 'edit-user-customer'
                "
                [disabled]="domOperationUtilsService.editUserSelectedTab === 4"
                mat-raised-button
                color="accent"
                (click)="editUserTabChange(+1)"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <ng-container *ngIf="hintClass === 'locationDashboardHint' || hintClass === 'locationDashboardSecondHint'">
                <button
                    mat-raised-button
                    [disabled]="hintClass === 'locationDashboardHint'"
                    color="accent"
                    (click)="onLDoverlayChange(false)"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="onLDoverlayChange(true)"
                    [disabled]="hintClass === 'locationDashboardSecondHint'"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="hintClass === 'scheduleDialogHint' || hintClass === 'scheduleDashboardHint'">
                <button
                    mat-raised-button
                    [disabled]="hintClass === 'scheduleDashboardHint'"
                    color="accent"
                    (click)="domOperationUtilsService.openCloseScheduleDialog.next(false)"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="domOperationUtilsService.openCloseScheduleDialog.next(true)"
                    [disabled]="hintClass === 'scheduleDialogHint'"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="hintClass === 'custom-dashboard-dialog-hint' || hintClass === 'custom-dashboard-hint'">
                <button
                    mat-raised-button
                    [disabled]="hintClass === 'custom-dashboard-hint'"
                    color="accent"
                    (click)="customDashboardHintChange(-1)"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="customDashboardHintChange(1)"
                    [disabled]="hintClass === 'custom-dashboard-dialog-hint' && (domOperationUtilsService.customDashboardDialogGraphType | async) === '1'"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="hintClass === 'dailyDialogHint' || hintClass === 'dailySummaryOverviewHint'">
                <button
                    mat-raised-button
                    [disabled]="hintClass === 'dailySummaryOverviewHint'"
                    color="accent"
                    (click)="domOperationUtilsService.openCloseDailyDialog.next(false)"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="domOperationUtilsService.openCloseDailyDialog.next(true)"
                    [disabled]="hintClass === 'dailyDialogHint'"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="hintClass === 'vaultPageHint' || hintClass === 'vaultSecondHint' || hintClass === 'vaultSecondListHint' || hintClass === 'vaultThirdHint' || hintClass === 'vaultDialogHint' || hintClass === 'rollbackDialog'">
                <button
                    mat-raised-button
                    [disabled]="hintClass === 'vaultPageHint'"
                    color="accent"
                    (click)="vaultOverlayChange(false)"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="vaultOverlayChange(true)"
                    [disabled]="hintClass === 'vaultDialogHint'"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="hintClass === 'data-communication-hint' && isScrollable">
                <button
                    [disabled]="!scrolled"
                    mat-raised-button
                    color="accent"
                    (click)="scrollDataComm(false)"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button
                    [disabled]="scrolled"
                    mat-raised-button
                    color="accent"
                    (click)="scrollDataComm(true)"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </ng-container>
            <button
                mat-raised-button
                color="accent"
                (click)="hideHintOverlay()"
            >Ok Got It</button>
        </div>

        <div class="locationSecondOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="hg-filter-hint-arrow"
            />
            <div class="hg-filter-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.HG_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="hg-new-tab-hint-arrow"
            />
            <div class="hg-new-tab-hint-text w-250">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.NEW_TAB_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="hg-new-tab-close-hint-arrow"
            />
            <div class="hg-new-tab-close-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.NEW_TAB_CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="hg-full-hint-arrow"
            />
            <div class="hg-full-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.HG_FULL_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-full-hint-arrow"
            />
            <div class="sg-full-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_FULL_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-data-table-hint-arrow"
            />
            <div class="ld-data-table-hint-text w-450">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LONG_TABLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-select-hint-arrow"
            />
            <div class="sg-select-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_SELECT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-clear-hint-arrow"
            />
            <div class="sg-clear-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_CLEAR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-zoom-hint-arrow"
            />
            <div class="sg-zoom-hint-text w-300">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_ZOOM_HINT_1' | translate }}
                <b>{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.RESET_HINT' | translate }}</b> {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_ZOOM_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-flag-hint-arrow"
            />
            <div class="sg-flag-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_FLAG_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-unflag-hint-arrow"
            />
            <div class="sg-unflag-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_UNFLAG_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-block-hint-arrow"
            />
            <div class="sg-block-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_LOCK_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-snap-hint-arrow"
            />
            <div class="sg-snap-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_SNAP_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-curve-hint-arrow"
            />
            <div class="sg-curve-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_CURVE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-tolerance-hint-arrow"
            />
            <div class="sg-tolerance-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_TOLERANCE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-tol-select-hint-arrow"
            />
            <div class="sg-tol-select-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_TOL_SELECT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-generation-hint-arrow"
            />
            <div class="sg-generation-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_GENERATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="sg-sync-hint-arrow"
            />
            <div class="sg-sync-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_SYNC_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="hg-zoom-arrow"
            />
            <div class="hg-zoom-text w-250">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.HG_ZOOM_HINT_1' | translate }}
                <b>{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.OFF' | translate }}</b> {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.HG_ZOOM_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="hg-tracer-arrow"
            />
            <div class="hg-tracer-text w-250">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.TRACER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="highcharts-legend-arrow"
            />
            <div class="highcharts-legend-text w-450">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.HG_LEGEND_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="highcharts-navigator-arrow"
            />
            <div class="highcharts-navigator-text w-450">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.HG_ZOOM_BAR_HINT' | translate }}
            </div>
        </div>

        <div class="edit-cus-details-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-name-hint-arrow"
            />
            <div class="edit-cus-name-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.NAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-short-name-hint-arrow"
            />
            <div class="edit-cus-short-name-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.SHORT_NAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ttl-hint-arrow"
            />
            <div class="edit-cus-ttl-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.TIME_TO_LIVE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-units-hint-arrow"
            />
            <div class="edit-cus-units-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.UNITS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-date-format-hint-arrow"
            />
            <div class="edit-cus-date-format-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.DATE_FORMAT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-time-format-hint-arrow"
            />
            <div class="edit-cus-time-format-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.TIME_FORMAT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-timezone-hint-arrow"
            />
            <div class="edit-cus-timezone-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.TIMEZONE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-work-order-hint-arrow"
            />
            <div class="edit-cus-work-order-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.WORK_ORDER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-idle-time-hint-arrow"
            />
            <div class="edit-cus-idle-time-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.IDLE_TIMEOUT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-daylight-hint-arrow"
            />
            <div class="edit-cus-daylight-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.DAYLIGHT_SAVING' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-con-num-hint-arrow"
            />
            <div class="edit-cus-con-num-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.CONTRACT_NUM' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-subs-hint-arrow"
            />
            <div class="edit-cus-subs-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.SUBS_LEVEL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-orig-start-hint-arrow"
            />
            <div class="edit-cus-orig-start-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.ORIG_CONTRACT_START' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-curr-start-hint-arrow"
            />
            <div class="edit-cus-curr-start-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.CURRENT_CONTRACT_START' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-curr-end-hint-arrow"
            />
            <div class="edit-cus-curr-end-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.CURRENT_CONTRACT_END' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-da-hint-arrow"
            />
            <div class="edit-cus-da-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.DATA_ANALYST' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-reg-man-hint-arrow"
            />
            <div class="edit-cus-reg-man-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.REG_MANAGER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-sales-hint-arrow"
            />
            <div class="edit-cus-sales-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.SALES_PERSON' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-pm-hint-arrow"
            />
            <div class="edit-cus-pm-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.PROJECT_MANAGER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-inactive-on-hint-arrow"
            />
            <div class="edit-cus-inactive-on-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.MARKED_INACTIVE_ON' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-rough-hint-arrow"
            />
            <div class="edit-cus-rough-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.ROUGHNESS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-details-add-hint-arrow"
            />
            <div class="edit-cus-details-add-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.ADD' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-save-hint-arrow"
            />
            <div class="edit-cus-save-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.DETAILS.SAVE' | translate }}
            </div>

        </div>

        <div class="edit-cus-features-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-feats-hint-arrow"
            />
            <div class="edit-cus-feats-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.FEATURES.FEATS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-feats-apply-hint-arrow"
            />
            <div class="edit-cus-feats-apply-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.FEATURES.APPLY' | translate }}
            </div>
        </div>

        <div class="edit-cus-users-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-search-hint-arrow"
            />
            <div class="edit-cus-users-search-hint-text w-400">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.SEARCH' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-assigned-hint-arrow"
            />
            <div class="edit-cus-users-assigned-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.ASSIGNED' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-check-hint-arrow"
            />
            <div class="edit-cus-users-check-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.CHECKBOX' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-username-hint-arrow"
            />
            <div class="edit-cus-users-username-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.USERNAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-email-hint-arrow"
            />
            <div class="edit-cus-users-email-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.EMAIL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-name-hint-arrow"
            />
            <div class="edit-cus-users-name-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.NAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-role-hint-arrow"
            />
            <div class="edit-cus-users-role-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.ROLE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-login-hint-arrow"
            />
            <div class="edit-cus-users-login-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.LAST_LOGIN' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-feats-hint-arrow"
            />
            <div class="edit-cus-users-feats-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.ASSIGNED_FEATURES' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-status-hint-arrow"
            />
            <div class="edit-cus-users-status-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.ACTIVE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-edit-hint-arrow"
            />
            <div class="edit-cus-users-edit-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.PENCIL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-add-hint-arrow"
            />
            <div class="edit-cus-users-add-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.ADD' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-paginator-hint-arrow"
            />
            <div class="edit-cus-users-paginator-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.PAGING' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-users-save-hint-arrow"
            />
            <div class="edit-cus-users-save-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.USERS.SAVE' | translate }}
            </div>
        </div>

        <div class="edit-cus-locations-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-search-hint-arrow"
            />
            <div class="edit-cus-locs-search-hint-text w-300">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.SEARCH' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-filter-hint-arrow"
            />
            <div class="edit-cus-locs-filter-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.FILTER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-name-hint-arrow"
            />
            <div class="edit-cus-locs-name-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.LOC_NAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-series-hint-arrow"
            />
            <div class="edit-cus-locs-series-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.SERIES' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-serial-hint-arrow"
            />
            <div class="edit-cus-locs-serial-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.SERIAL_NUM' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-desc-hint-arrow"
            />
            <div class="edit-cus-locs-desc-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.DESCRIPTION' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-sensors-hint-arrow"
            />
            <div class="edit-cus-locs-sensors-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.SENSORS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-group-hint-arrow"
            />
            <div class="edit-cus-locs-group-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.LOC_GROUP' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-status-hint-arrow"
            />
            <div class="edit-cus-locs-status-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.INACTIVE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-activate-hint-arrow"
            />
            <div class="edit-cus-locs-activate-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.ACTIVATE_MON' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-composite-hint-arrow"
            />
            <div class="edit-cus-locs-composite-hint-text w-80">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.COMPOSITE_LOC' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-wdo-hint-arrow"
            />
            <div class="edit-cus-locs-wdo-hint-text w-100">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.WDO' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-edit-hint-arrow"
            />
            <div class="edit-cus-locs-edit-hint-text w-80">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.PENCIL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-locs-add-hint-arrow"
            />
            <div class="edit-cus-locs-add-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.ADD' | translate }}
            </div>
        </div>

        <div class="edit-cus-locaitons-filters-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-filters-series-hint-arrow"
            />
            <div class="edit-cus-filters-series-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.FILTER_SERIES' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-filters-group-hint-arrow"
            />
            <div class="edit-cus-filters-group-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.FILTER_LOC_GROUP' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-filters-status-hint-arrow"
            />
            <div class="edit-cus-filters-status-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.FILTER_STATUS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-filters-sensors-hint-arrow"
            />
            <div class="edit-cus-filters-sensors-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.FILTER_SENSORS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-filters-wdo-hint-arrow"
            />
            <div class="edit-cus-filters-wdo-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.FILTER_WDO' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-filters-composite-hint-arrow"
            />
            <div class="edit-cus-filters-composite-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.LOCATIONS.FILTER_COMPOSITE' | translate }}
            </div>
        </div>

        <div class="edit-cus-ftp-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-table-hint-arrow"
            />
            <div class="edit-cus-ftp-table-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP.LIST' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-add-hint-arrow"
            />
            <div class="edit-cus-ftp-add-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP.ADD' | translate }}
            </div>
        </div>

        <div class="edit-cus-ftp-add-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-protocol-hint-arrow"
            />
            <div class="edit-cus-ftp-protocol-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.PROTOCOL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-mode-hint-arrow"
            />
            <div class="edit-cus-ftp-mode-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.MODE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-host-hint-arrow"
            />
            <div class="edit-cus-ftp-host-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.HOST' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-username-hint-arrow"
            />
            <div class="edit-cus-ftp-username-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.USERNAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-password-hint-arrow"
            />
            <div class="edit-cus-ftp-password-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.PASSWORD' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-port-hint-arrow"
            />
            <div class="edit-cus-ftp-port-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.PORT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-folder-hint-arrow"
            />
            <div class="edit-cus-ftp-folder-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.FTP_FOLDER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-time-hint-arrow"
            />
            <div class="edit-cus-ftp-time-hint-text w-200">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.INTERVAL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-import-hint-arrow"
            />
            <div class="edit-cus-ftp-import-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.IMPORT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-close-hint-arrow"
            />
            <div class="edit-cus-ftp-close-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.CLOSE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ftp-save-hint-arrow"
            />
            <div class="edit-cus-ftp-save-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.FTP_ADD.SAVE' | translate }}
            </div>
        </div>

        <div class="edit-cus-gis-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-edit-hint-arrow"
            />
            <div class="edit-cus-gis-edit-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS.EDIT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-add-hint-arrow"
            />
            <div class="edit-cus-gis-add-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS.ADD' | translate }}
            </div>
        </div>

        <div class="edit-cus-gis-basic-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-type-hint-arrow"
            />
            <div class="edit-cus-gis-type-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.TYPE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-mapping-hint-arrow"
            />
            <div class="edit-cus-gis-mapping-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.MAPPING' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-auth-hint-arrow"
            />
            <div class="edit-cus-gis-auth-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.AUTH' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-username-hint-arrow"
            />
            <div class="edit-cus-gis-username-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.USERNAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-password-hint-arrow"
            />
            <div class="edit-cus-gis-password-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.PASSWORD' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-token-hint-arrow"
            />
            <div class="edit-cus-gis-token-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.TOKEN' | translate }}
            </div>
        </div>

        <div class="edit-cus-gis-custom-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-type-hint-arrow-custom"
            />
            <div class="edit-cus-gis-type-hint-text-custom w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.TYPE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-mapping-hint-arrow-custom"
            />
            <div class="edit-cus-gis-mapping-hint-text-custom w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.MAPPING' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-auth-hint-arrow-custom"
            />
            <div class="edit-cus-gis-auth-hint-text-custom w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.AUTH' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-token-hint-arrow-custom"
            />
            <div class="edit-cus-gis-token-hint-text-custom w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_BASIC.TOKEN' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-gis-params-hint-arrow-custom"
            />
            <div class="edit-cus-gis-params-hint-text-custom w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.GIS_CUSTOM.AUTH_PARAMS' | translate }}
            </div>
        </div>

        <div class="edit-cus-rainfall-dry-day-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-dry-tab-hint-arrow"
            />
            <div class="edit-cus-dry-tab-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DRY_DAY.DRY_DAY' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-dry-one-hint-arrow"
            />
            <div class="edit-cus-dry-one-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DRY_DAY.MAX_PREV_DAY' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-dry-three-hint-arrow"
            />
            <div class="edit-cus-dry-three-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DRY_DAY.MAX_PREV_3_DAYS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-dry-five-hint-arrow"
            />
            <div class="edit-cus-dry-five-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DRY_DAY.MAX_PREV_5_DAYS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-dry-min-hint-arrow"
            />
            <div class="edit-cus-dry-min-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DRY_DAY.MIN' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-dry-max-hint-arrow"
            />
            <div class="edit-cus-dry-max-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DRY_DAY.MAX' | translate }}
            </div>
        </div>

        <div class="edit-cus-rainfall-storm-event-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-storm-tab-hint-arrow"
            />
            <div class="edit-cus-storm-tab-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.STORM_EVENT.STORM_EVENT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-storm-rain-hint-arrow"
            />
            <div class="edit-cus-storm-rain-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.STORM_EVENT.MINIMUM_RAINFALL_THRESHOLD' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-storm-storm-hint-arrow"
            />
            <div class="edit-cus-storm-storm-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.STORM_EVENT.BETWEEN_STORM_EVENTS' | translate }}
            </div>
        </div>

        <div class="edit-cus-rainfall-infiltration-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-base-method-hint-arrow"
            />
            <div class="edit-cus-base-method-hint-text w-250">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.BASE_INFILTRATION.INFILTRATION_METHOD' | translate }}
            </div>
        </div>

        <div class="edit-cus-rainfall-ddf-data-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-data-tab-hint-arrow"
            />
            <div class="edit-cus-ddf-data-tab-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_DATA.DATA' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-data-upload-hint-arrow"
            />
            <div class="edit-cus-ddf-data-upload-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_DATA.UPLOAD' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-data-table-hint-arrow"
            />
            <div class="edit-cus-ddf-data-table-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_DATA.TABLE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-data-add-hint-arrow"
            />
            <div class="edit-cus-ddf-data-add-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_DATA.ADD' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-data-delete-hint-arrow"
            />
            <div class="edit-cus-ddf-data-delete-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_DATA.DELETE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-data-graph-hint-arrow"
            />
            <div class="edit-cus-ddf-data-graph-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_DATA.GRAPH' | translate }}
            </div>
        </div>

        <div class="edit-cus-rainfall-ddf-graph-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-graph-tab-hint-arrow"
            />
            <div class="edit-cus-ddf-graph-tab-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_GRAPH.GRAPH' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-graph-tooltip-hint-arrow"
            />
            <div class="edit-cus-ddf-graph-tooltip-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_GRAPH.SHOW_TOOLTIPS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-graph-chart-hint-arrow"
            />
            <div class="edit-cus-ddf-graph-chart-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_GRAPH.TOOLTIP' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-cus-ddf-graph-options-hint-arrow"
            />
            <div class="edit-cus-ddf-graph-options-hint-text w-150">
                {{ 'CUSTOMER_EDITOR.HINTS.RAINFALL.DDF_GRAPH.OPTIONS' | translate }}
            </div>
        </div>

        <div class="locationOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-series-hint-arrow"
            />
            <div class="ld-series-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SERIES_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-location-hint-arrow"
            />
            <div class="ld-location-hint-text w-250">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-selected-hint-arrow"
            />
            <div class="ld-selected-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SELECTED_LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-rain-hint-arrow"
            />
            <div class="ld-rain-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.RAIN_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-search-hint-arrow"
            />
            <div class="ld-search-hint-text w-350">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SEARCH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-date-hint-arrow"
            />
            <div class="ld-date-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-entity-hint-arrow"
            />
            <div class="ld-entity-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-work-hint-arrow"
            />
            <div class="ld-work-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.WORK_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-approve-hint-arrow"
            />
            <div class="ld-approve-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.APPROVE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-edit-hint-arrow"
            />
            <div class="ld-edit-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.EDIT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-filter-hint-arrow"
            />
            <div class="ld-filter-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-options-hint-arrow"
            />
            <div class="ld-options-hint-text w-120">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.OPTIONS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-entity-hg-hint-arrow"
            />
            <div class="ld-entity-hg-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ENTITY_HG_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-entity-sg-hint-arrow"
            />
            <div class="ld-entity-sg-hint-text w-250">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ENTITY_SG_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-date-select-hint-arrow"
            />
            <div class="ld-date-select-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.DATE_SELECTOR_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ld-average-hint-arrow"
            />
            <div class="ld-average-hint-text w-250">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.AVERAGE_HINT' | translate }}
            </div>
        </div>

        <!-- help window specific for schedule dashboard -->
        <div class="schedule-dashboard-hint-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-title-hint-arrow"
            />
            <div class="schedule-title-hint-text w-700">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_TITLE_HINT_1' | translate }}
                <br />{{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_TITLE_HINT_2' | translate }} <br /><b>{{
                    'COMMON.SCHEDULE_COLLECT_DASHBOARDS.NOTE' | translate
                    }}</b> {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_TITLE_HINT_NOTE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add-schedule-hint-arrow"
            />
            <div class="add-schedule-hint-text w-100">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.ADD_SCHEDULE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="filter-hint-arrow"
            />
            <div class="filter-hint-text w-200">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SHOW_HIDE_FILTER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="name-filter-hint-arrow"
            />
            <div class="name-filter-hint-text w-200">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.NAME_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="week-days-hint-arrow"
            />
            <div class="week-days-hint-text w-200">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.WEEK_DAY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="frequency-hint-arrow"
            />
            <div class="frequency-hint-text w-200">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.FREQUENCY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-schedule-hint-arrow"
            />
            <div class="edit-schedule-hint-text w-100">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.EDIT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="delete-hint-arrow"
            />
            <div class="delete-hint-text w-100">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.DELETE_HINT' | translate }}
            </div>
        </div>

        <div class="block-editor-hint-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-condition-location-hint-arrow"
            />
            <div class="block-editor-dialog-condition-location-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.CONDITION_LOCATION' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-condition-entity-hint-arrow"
            />
            <div class="block-editor-dialog-condition-entity-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.CONDITION_ENTITY' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-condition-operator-hint-arrow"
            />
            <div class="block-editor-dialog-condition-operator-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.CONDITION_OPERATOR' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-condition-value-hint-arrow"
            />
            <div class="block-editor-dialog-condition-value-hint-text w-250">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.CONDITION_VALUE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-entity-hint-arrow"
            />
            <div class="block-editor-dialog-entity-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.ENTITY' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-action-hint-arrow"
            />
            <div class="block-editor-dialog-action-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.ACTION' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-valueentity-hint-arrow"
            />
            <div class="block-editor-dialog-valueentity-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.VALUE_ENTITY' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-entityvalue-hint-arrow"
            />
            <div class="block-editor-dialog-entityvalue-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.ENTITY_VALUE' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-header-hint-arrow"
            />
            <div class="block-editor-dialog-header-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.HEADER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-date-range-hint-arrow"
            />
            <div class="block-editor-dialog-date-range-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.DATE_RANGE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-add-hint-arrow"
            />
            <div class="block-editor-dialog-add-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.CONDITION_ADD' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-apply-hint-arrow"
            />
            <div class="block-editor-dialog-apply-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.APPLY' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="block-editor-dialog-close-hint-arrow"
            />
            <div class="block-editor-dialog-close-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.HINTS.CLOSE' | translate }}
            </div>

        </div>

        <!-- help window specific for schedule dialog -->
        <div class="schedule-dialog-hint-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-name-hint-arrow"
            />
            <div class="schedule-dialog-name-hint-text w-300">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_NAME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-search-hint-arrow"
            />
            <div class="schedule-dialog-search-hint-text w-300">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_SEARCH' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-cancel-hint-arrow"
            />
            <div class="schedule-dialog-cancel-hint-text w-200">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_CANCEL' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-locations-hint-arrow"
            />
            <div class="schedule-dialog-locations-hint-text w-300">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_LOCATIONS_1' | translate }}
                <br /><b>{{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.NOTE' | translate }}</b> {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_LOCATIONS_NOTE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-days-hint-arrow"
            />
            <div class="schedule-dialog-days-hint-text w-300">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_DAYS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-collect-hint-arrow"
            />
            <div class="schedule-dialog-collect-hint-text w-300">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_COLLECT_TIME' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-frequency-hint-arrow"
            />
            <div class="schedule-dialog-frequency-hint-text w-300">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_FREQUENCY_1' | translate }}
                <br /><b>{{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.NOTE' | translate }}</b> {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_FREQUENCY_NOTE_1' | translate }} <br />{{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_FREQUENCY_NOTE_2'
                | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-event-hint-arrow"
            />
            <div class="schedule-dialog-event-hint-text w-200">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_EVENT_FREQUENCY' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-dialog-save-hint-arrow"
            />
            <div class="schedule-dialog-save-hint-text w-200">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_DIALOG_SAVE' | translate }}
            </div>
        </div>

        <!-- help window specific for edit mode -->
        <div class="location-dashBoard-edit-hint-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-topbar-hint-arrow"
            />
            <div class="de-topbar-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.TOPBAR' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-entity-hint-arrow"
            />
            <div class="de-hg-entity-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_ENTITY' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-zoom-hint-arrow"
            />
            <div class="de-hg-zoom-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_ZOOM' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-drag-hint-arrow"
            />
            <div class="de-hg-drag-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_DRAG' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-flag-hint-arrow"
            />
            <div class="de-hg-flag-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_FLAG' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-clear-hint-arrow"
            />
            <div class="de-hg-clear-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_CLEAR' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-multiple-hint-arrow"
            />
            <div class="de-hg-multiple-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_MULTIPLE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-copy-hint-arrow"
            />
            <div class="de-hg-copy-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_COPY' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-interpolate-hint-arrow"
            />
            <div class="de-hg-interpolate-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_INTERPOLATE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-highcharts-range-selector-group-arrow"
            />
            <div class="de-highcharts-range-selector-group-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_TRACER' | translate }}
            </div>


            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-filter-hint-arrow"
            />
            <div class="de-hg-filter-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_FILTER' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-new-tab-hint-arrow"
            />
            <div class="de-hg-new-tab-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_NEW_TAB' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-new-tab-close-hint-arrow"
            />
            <div class="de-hg-new-tab-close-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_NEW_TAB_CLOSE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-hg-full-hint-arrow"
            />
            <div class="de-hg-full-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.HG_FULL' | translate }}
            </div>

        </div>

        <div class="de-accept-dialog-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-dialog-entities-hint-arrow"
            />
            <div class="de-dialog-entities-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DIALOG_ENTITIES' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-dialog-reason-hint-arrow"
            />
            <div class="de-dialog-reason-hint-text w-250">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DIALOG_REASON_1' | translate }}
                <b>{{ 'COMMON.OTHER_TEXT' | translate }}</b>
                {{ 'LOCATION_DASHBOARD.DE_HINT.DIALOG_REASON_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-dialog-comment-hint-arrow"
            />
            <div class="de-dialog-comment-hint-text w-250">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DIALOG_COMMENT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-dialog-approve-hint-arrow"
            />
            <div class="de-dialog-approve-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DIALOG_APPROVE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-dialog-cancel-hint-arrow"
            />
            <div class="de-dialog-cancel-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DIALOG_CANCEL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-dialog-accept-hint-arrow"
            />
            <div class="de-dialog-accept-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DIALOG_ACCEPT' | translate }}
            </div>
        </div>

        <div class="location-dashBoard-edit-sg-hint-overlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-full-hint-arrow"
            />
            <div class="de-sg-full-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_FULL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-select-hint-arrow"
            />
            <div class="de-sg-select-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_SELECT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-clear-hint-arrow"
            />
            <div class="de-sg-clear-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_CLEAR' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-zoom-hint-arrow"
            />
            <div class="de-sg-zoom-hint-text w-300">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_ZOOM' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-flag-hint-arrow"
            />
            <div class="de-sg-flag-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_IGNORE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-unflag-hint-arrow"
            />
            <div class="de-sg-unflag-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_UNIGNORE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-block-hint-arrow"
            />
            <div class="de-sg-block-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_LOCK' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-snap-hint-arrow"
            />
            <div class="de-sg-snap-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_SNAP' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-curve-hint-arrow"
            />
            <div class="de-sg-curve-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_CURVE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-tolerance-hint-arrow"
            />
            <div class="de-sg-tolerance-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_TOLERANCE_VALUE' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-tol-select-hint-arrow"
            />
            <div class="de-sg-tol-select-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_TOLERANCE_POS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-generation-hint-arrow"
            />
            <div class="de-sg-generation-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_GENERATION' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-sg-sync-hint-arrow"
            />
            <div class="de-sg-sync-hint-text w-200">
                {{ 'LOCATION_DASHBOARD.DE_HINT.SG_SYNC' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-cancel-hint-arrow"
            />
            <div class="de-cancel-hint-text w-100">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DE_CANCEL' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="de-accept-hint-arrow"
            />
            <div class="de-accept-hint-text w-150">
                {{ 'LOCATION_DASHBOARD.DE_HINT.DE_ACCEPT' | translate }}
            </div>

        </div>

        <!-- view location details -->
        <div class="view-location-details-screen-overlay">
            <!-- edit button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit-button-hint-arrow"
            />
            <div class="edit-button-hint-text">
                {{ 'COMMON.MODIFY_DTAILS_LOCATIONS' | translate }}
            </div>
            <!-- dtails  collect button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="collect-button-hint-arrow"
            />
            <div class="collect-button-hint-text collect-desctiption">
                {{ 'COMMON.INITIATE_ONDEMAND' | translate }}
            </div>
            <!-- dtails schedule collect button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="schedule-collect-button-hint-arrow"
            />
            <div class="schedule-collect-button-hint-text collector-width">
                {{ 'COMMON.SCHEDULE_COLLECT_EDITOR' | translate }}
            </div>
            <!-- dtails  collect button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="export-button-hint-arrow"
            />
            <div class="export-button-hint-text export-description">
                {{ 'COMMON.EXPORT_XML' | translate }}
            </div>
            <!-- details percent full -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percent-full-hint-arrow"
            />
            <div class="percent-full-hint-text details-percent-width">
                {{ 'COMMON.MAXIMUM_PERCENT_VALUE' | translate }}
            </div>
            <!-- details daily summary -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-summary-hint-arrow"
            />
            <div class="daily-summary-hint-text details-daily-summary-width">
                {{ 'COMMON.MINIMUM_MAXIMUM_AVERAGE_VALUE' | translate }}
            </div>
            <!-- details uptime -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-hint-arrow"
            />
            <div class="uptime-hint-text details-uptime-width">
                {{ 'COMMON.UPTIME_PERCENTAGE' | translate }}
                <div>
                    <span>{{ 'COMMON.UPTIME_GREEN_COLOR_RANGE' | translate }}</span>
                    <span class="pad-l-15">{{ 'COMMON.UPTIME_YELLOW_COLOR_RANGE' | translate }}</span>
                </div>
                <div>
                    <span>{{ 'COMMON.UPTIME_ORANGE_COLOR_RANGE' | translate }}</span>
                    <span class="pad-l-15">{{ 'COMMON.UPTIME_RED_COLOR_RANGE' | translate }}</span>
                </div>
            </div>
            <!-- details tab hint -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="details-hint-arrow"
            />
            <div class="details-hint-text daily-summary-width">
                {{ 'COMMON.SPECIFIC_VIEW_LOCATIONS' | translate }}
            </div>
        </div>

        <div class="view-location-associated-entity-screen-overlay">
            <!-- ANSR copy entity button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="copy-entity-hint-arrow"
            />
            <div class="copy-entity-hint-text collect-entity">
                {{ 'COMMON.EXISTING_CALCULATED' | translate }}
            </div>
            <!-- ANSR add entity entity button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add-entity-hint-arrow"
            />
            <div class="add-entity-hint-text crete-caclulated-entity">
                {{ 'COMMON.NEW_CALCULATED_ENTITY' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ansr-edit-hint-arrow"
            />
            <div class="ansr-edit-hint-text">
                {{ 'COMMON.EDIT_TOOLTIP' | translate }}.
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ansr-delete-hint-arrow"
            />
            <div class="ansr-delete-hint-text">
                {{ 'COMMON.DELETE_TOOLTIP' | translate }}.
            </div>
            <!-- ANSR tab hint -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ansr-hint-arrow"
            />
            <div class="ansr-hint-text daily-summary-width">
                {{ 'COMMON.ADS_ANSR_ENGINE' | translate }}<span class="fallback-font">™</span> {{ 'COMMON.ENGINE_FEATURES' | translate }}
            </div>
        </div>

        <div class="view-location-confirmation-points-screen-overlay">
            <!-- confirmation-tabadd confirmation button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="download-confirmation-hint-arrow"
            />
            <div class="download-confirmation-hint-text">
                {{ 'COMMON.ADD_CONFIRMATION_HINT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add-confirmation-hint-arrow"
            />
            <div class="add-confirmation-hint-text">
                {{ 'COMMON.DOWNLOAD_CONFIRMATION_HINT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="confirmation-hint-arrow"
            />
            <div class="confirmation-hint-text daily-summary-width">
                {{ 'COMMON.CONFIRMATION_HINT' | translate }}
            </div>
            <!-- ANSR eidt button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="confirmation-edit-hint-arrow"
            />
            <div class="confirmation-edit-hint-text">
                {{ 'COMMON.EDIT_TOOLTIP' | translate }}.
            </div>
            <!-- ANSR delete button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="confirmation-delete-hint-arrow"
            />
            <div class="confirmation-delete-hint-text">
                {{ 'COMMON.DELETE_TOOLTIP' | translate }}.
            </div>
        </div>

        <div class="view-location-files-screen-overlay">
            <!-- files-tab  create folder button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="create-folder-hint-arrow"
            />
            <div class="create-folder-hint-text collector-width">
                {{ 'COMMON.NEW_FOLDER_HINT' | translate }}
            </div>
            <!-- files-tab  show/hide subfolders -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="show-hide-subfolders-hint-arrow"
            />
            <div class="show-hide-subfolders-hint-text collector-width">
                {{ 'COMMON.SHOW_HIDE_SUBFOLDERS' | translate }}
            </div>
            <!-- files-tab  upload file button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="upload-file-hint-arrow"
            />
            <div class="upload-file-hint-text">
                {{ 'COMMON.UPLOAD_FILE_HINT' | translate }}
            </div>
            <!-- files description -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="file-share-hint-arrow"
            />
            <div class="file-share-hint-text">
                {{ 'VAULT.SHARE_TEXT' | translate }}.
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="file-import-hint-arrow"
            />
            <div class="file-import-hint-text">
                {{ 'VAULT.IMPORT_TEXT' | translate }}.
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="file-download-hint-arrow"
            />
            <div class="file-download-hint-text">
                {{ 'COMMON.DOWNLOAD_BTN' | translate }}.
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="file-delete-hint-arrow"
            />
            <div class="file-delete-hint-text">
                {{ 'COMMON.DELETE_TOOLTIP' | translate }}.
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="files-hint-arrow"
            />
            <div class="files-hint-text daily-summary-width">
                {{ 'COMMON.FILES_HINT' | translate }}
            </div>
        </div>

        <div class="view-location-notes-screen-overlay">
            <!-- notes-tab  add button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add-note-hint-arrow"
            />
            <div class="add-note-hint-text add-note-width">
                {{ 'COMMON.NEW_LOCATIONS_NOTES' | translate }}
            </div>
            <!-- notes-tab  filter section -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="filter-hint-arrow"
            />
            <div class="filter-hint-text add-entity-width">
                {{ 'COMMON.LOCATION_NOTES_ACCESS' | translate }}
            </div>
            <!-- note-tab hint -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="notes-hint-arrow"
            />
            <div class="notes-hint-text daily-summary-width">
                {{ 'COMMON.LOCATION_NOTES_ACCESS' | translate }}
            </div>
            <!-- ANSR eidt button -->
            <div class="ansr-edit">
                <mat-icon>mode_edit</mat-icon>
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ansr-edit-arrow"
            />
            <div class="ansr-edit-content">
                {{ 'COMMON.EDIT_TOOLTIP' | translate }}.
            </div>
            <!-- ANSR delete button -->
            <div class="ansr-edit ansr-delete">
                <mat-icon>delete</mat-icon>
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="ansr-delete-arrow"
            />
            <div class="ansr-delete-content">
                {{ 'COMMON.DELETE_TOOLTIP' | translate }}.
            </div>
        </div>

        <div class="view-location-flow-balance-screen-overlay">
            <!-- flow balance report generate report button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="generate-button-hint-arrow"
            />
            <div class="generate-button-hint-text percent-full-width">
                {{ 'COMMON.CONFIGURED_LOCATIONS' | translate }}
            </div>
            <!-- flow balance report edit button -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="flow-balance-edit-hint-arrow"
            />
            <div class="flow-balance-edit-hint-text add-entity-width">
                {{ 'COMMON.FLOW_BALANCE_REPORT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="flow-balance-hint-arrow"
            />
            <div class="flow-balance-hint-text daily-summary-width">
                {{ 'COMMON.FLOW_BALANCE_HINT' | translate }}
            </div>
        </div>

        <div class="view-location-advanced-screen-overlay">
            <!-- advanced tab hints -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="advanced-hint-arrow"
            />
            <div class="advanced-hint-text w-250">
                {{ 'COMMON.ADVANCED_TAB' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="advanced-filter-hint-arrow"
            />
            <div class="advanced-filter-hint-text w-250">
                {{ 'COMMON.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="diagnostics-hint-arrow"
            />
            <div class="diagnostics-hint-text w-250">
                {{ 'COMMON.DIAGNOSTICS' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="date-picker-hint-arrow"
            />
            <div class="date-picker-hint-text w-250">
                {{ 'COMMON.DATE_RANGE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="date-time-hint-arrow"
            />
            <div class="date-time-hint-text w-250">
                {{ 'COMMON.DATE_TIME_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="user-name-hint-arrow"
            />
            <div class="user-name-hint-text w-250">
                {{ 'COMMON.USERNAME_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="result-hint-arrow"
            />
            <div class="result-hint-text w-100">
                {{ 'COMMON.RESULT_HINT' | translate }}
            </div>
        </div>

        <div class="dynamicdbOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="addNewDynamicDbHintArrow"
            />
            <div class="addNewDynamicDbHintText">
                {{ 'DYNAMIC_DB_SECTION_HINT.ADD_DYNAMIC_DB_HINT' | translate }}
            </div>
        </div>

        <div class="vaultOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-folders-hint-arrow"
            />
            <div class="vault-folders-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.FOLDERS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-hide-folders-hint-arrow"
            />
            <div class="vault-hide-folders-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.HIDE_FOLDERS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-breadcrumb-hint-arrow"
            />
            <div class="vault-breadcrumb-hint-text w-450">
                {{ 'VAULT.VAULT_DASHBOARD.BREADCRUMB_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-search-hint-arrow"
            />
            <div class="vault-search-hint-text w-350">
                {{ 'VAULT.VAULT_DASHBOARD.SEARCH_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-create-hint-arrow"
            />
            <div class="vault-create-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.CREATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-hide-sub-hint-arrow"
            />
            <div class="vault-hide-sub-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.HIDE_SUB_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-list-hint-arrow"
            />
            <div class="vault-list-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.LIST_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-delete-hint-arrow"
            />
            <div class="vault-delete-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.DELETE_HINT' | translate }}
            </div>
        </div>

        <div
            *ngIf="hintClass === 'vaultSecondHint' || hintClass === 'vaultSecondListHint'"
            [ngClass]="{'vaultSecondOverlay' : hintClass === 'vaultSecondHint', 'vaultSecondListOverlay': hintClass === 'vaultSecondListHint'}"
        >
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-toggle-hint-arrow"
            />
            <div class="vault-toggle-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.TOGGLE_HINT_1' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.GRID' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.TOGGLE_HINT_2' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.LIST' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.TOGGLE_HINT_3' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-file-check-hint-arrow"
            />
            <div class="vault-file-check-hint-text w-150">
                {{ 'VAULT.VAULT_DASHBOARD.FILE_CHECK_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-file-icon-hint-arrow"
            />
            <div class="vault-file-icon-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.FILE_ICON_HINT_1' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.NOTE' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.FILE_ICON_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-file-share-hint-arrow"
            />
            <div class="vault-file-share-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.FILE_SHARE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-file-download-hint-arrow"
            />
            <div class="vault-file-download-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.FILE_DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-file-import-hint-arrow"
            />
            <div class="vault-file-import-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.FILE_IMPORT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-file-delete-hint-arrow"
            />
            <div class="vault-file-delete-hint-text w-150">
                {{ 'VAULT.VAULT_DASHBOARD.FILE_DELETE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-top-move-hint-arrow"
            />
            <div class="vault-top-move-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.TOP_MOVE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-top-delete-hint-arrow"
            />
            <div class="vault-top-delete-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.TOP_DELETE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-top-download-hint-arrow"
            />
            <div class="vault-top-download-hint-text w-150">
                {{ 'VAULT.VAULT_DASHBOARD.TOP_DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-top-import-hint-arrow"
            />
            <div class="vault-top-import-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.TOP_IMPORT_HINT' | translate }}
            </div>
        </div>

        <!-- <div class="vaultSecondListOverlay">
          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-toggle-hint-arrow" />
          <div class="vault-toggle-hint-text w-200">
              {{ 'VAULT.VAULT_DASHBOARD.TOGGLE_HINT_1' | translate }}
              <b>{{ 'VAULT.VAULT_DASHBOARD.GRID' | translate }}</b>
              {{ 'VAULT.VAULT_DASHBOARD.TOGGLE_HINT_2' | translate }}
              <b>{{ 'VAULT.VAULT_DASHBOARD.LIST' | translate }}</b>
              {{ 'VAULT.VAULT_DASHBOARD.TOGGLE_HINT_3' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-file-check-hint-arrow" />
          <div class="vault-file-check-hint-text w-150">
              {{ 'VAULT.VAULT_DASHBOARD.FILE_CHECK_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-file-icon-hint-arrow" />
          <div class="vault-file-icon-hint-text w-250">
              {{ 'VAULT.VAULT_DASHBOARD.FILE_ICON_HINT_1' | translate }}
              <b>{{ 'VAULT.VAULT_DASHBOARD.NOTE' | translate }}</b>
              {{ 'VAULT.VAULT_DASHBOARD.FILE_ICON_HINT_2' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-file-share-hint-arrow" />
          <div class="vault-file-share-hint-text w-250">
              {{ 'VAULT.VAULT_DASHBOARD.FILE_SHARE_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-file-download-hint-arrow" />
          <div class="vault-file-download-hint-text w-200">
              {{ 'VAULT.VAULT_DASHBOARD.FILE_DOWNLOAD_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-file-import-hint-arrow" />
          <div class="vault-file-import-hint-text w-200">
              {{ 'VAULT.VAULT_DASHBOARD.FILE_IMPORT_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-file-delete-hint-arrow" />
          <div class="vault-file-delete-hint-text w-150">
              {{ 'VAULT.VAULT_DASHBOARD.FILE_DELETE_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-top-move-hint-arrow" />
          <div class="vault-top-move-hint-text w-200">
              {{ 'VAULT.VAULT_DASHBOARD.TOP_MOVE_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-top-delete-hint-arrow" />
          <div class="vault-top-delete-hint-text w-200">
              {{ 'VAULT.VAULT_DASHBOARD.TOP_DELETE_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-top-download-hint-arrow" />
          <div class="vault-top-download-hint-text w-150">
              {{ 'VAULT.VAULT_DASHBOARD.TOP_DOWNLOAD_HINT' | translate }}
          </div>

          <img [ngSrc]="'/assets/images/arrow.png'" height="62" width="43" class="vault-top-import-hint-arrow" />
          <div class="vault-top-import-hint-text w-250">
              {{ 'VAULT.VAULT_DASHBOARD.TOP_IMPORT_HINT' | translate }}
          </div>
        </div> -->

        <div class="vaultThirdOverlay">
            <div class="upload-menu-mock">
                <button
                    mat-menu-item
                    class="vault-upload-file-hint"
                >
                    <mat-icon>insert_drive_file</mat-icon>
                    <span>{{ 'COMMON.FILES_TEXT' | translate }}</span>
                </button>
                <button
                    mat-menu-item
                    class="vault-upload-location-hint"
                >
                    <mat-icon>location_on</mat-icon>
                    <span>{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}</span>
                </button>
                <button
                    mat-menu-item
                    class="vault-upload-data-hint"
                >
                    <mat-icon>file_upload</mat-icon>
                    <span>{{ 'COMMON.DATA_TEXT' | translate }}</span>
                </button>
            </div>

            <div class="export-menu-mock">
                <button
                    mat-menu-item
                    class="vault-export-new-hint"
                >
                    <span>{{ 'COMMON.NEW_EXPORT' | translate }}</span>
                </button>
                <button
                    mat-menu-item
                    class="vault-export-load-hint"
                >
                    <span>{{ 'COMMON.LOAD_SETTING' | translate }}</span>
                </button>
                <button
                    mat-menu-item
                    class="vault-export-recent-hint"
                >
                    {{ 'COMMON.RECENT_EXPORT' | translate }}
                </button>
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-upload-file-hint-arrow"
            />
            <div class="vault-upload-file-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.UPLOAD_FILE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-upload-location-hint-arrow"
            />
            <div class="vault-upload-location-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.UPLOAD_LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-upload-data-hint-arrow"
            />
            <div class="vault-upload-data-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.UPLOAD_DATA_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-export-new-hint-arrow"
            />
            <div class="vault-export-new-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.EXPORT_NEW_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-export-load-hint-arrow"
            />
            <div class="vault-export-load-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.EXPORT_SETTING_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-export-recent-hint-arrow"
            />
            <div class="vault-export-recent-hint-text w-250">
                {{ 'VAULT.VAULT_DASHBOARD.EXPORT_REECENT_HINT' | translate }}
            </div>
        </div>

        <div class="vaultDialogOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-download-hint-arrow"
            />
            <div class="vault-dialog-download-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-type-hint-arrow"
            />
            <div class="vault-dialog-type-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_TYPE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-locations-hint-arrow"
            />
            <div class="vault-dialog-locations-hint-text w-400">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-rounding-hint-arrow"
            />
            <div class="vault-dialog-rounding-hint-text w-400">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_ROUNDING_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-inactive-hint-arrow"
            />
            <div class="vault-dialog-inactive-hint-text w-350">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_INACTIVE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-date-hint-arrow"
            />
            <div class="vault-dialog-date-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-value-hint-arrow"
            />
            <div class="vault-dialog-value-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_VALUE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-multiple-hint-arrow"
            />
            <div class="vault-dialog-multiple-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_FILE_MULTILPE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-average-hint-arrow"
            />
            <div class="vault-dialog-average-hint-text w-450">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_AVERAGE_HINT_1' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.NOTE' | translate }}</b>
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_AVERAGE_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-entity-hint-arrow"
            />
            <div class="vault-dialog-entity-hint-text w-200">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-timestamp-hint-arrow"
            />
            <div class="vault-dialog-timestamp-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_TIMESTAMP_HINT_1' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.OMIT' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.DIALOG_TIMESTAMP_HINT_2' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.BLANK' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.DIALOG_TIMESTAMP_HINT_3' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.CUSTOM_VALUE' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.DIALOG_TIMESTAMP_HINT_4' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.NOTE' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.DIALOG_TIMESTAMP_HINT_5' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.NONE' | translate }}</b>
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-file-type-hint-arrow"
            />
            <div class="vault-dialog-file-type-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_FILE_TYPE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-entity-col-hint-arrow"
            />
            <div class="vault-dialog-entity-col-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_ENTITY_COL_HINT_1' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.CSV' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.DIALOG_ENTITY_COL_HINT_2' | translate }}
                <b>{{ 'VAULT.VAULT_DASHBOARD.EXCEL' | translate }}</b> {{ 'VAULT.VAULT_DASHBOARD.DIALOG_ENTITY_COL_HINT_3' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-settings-hint-arrow"
            />
            <div class="vault-dialog-settings-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_SAVE_SETTING_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-close-hint-arrow"
            />
            <div class="vault-dialog-close-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="vault-dialog-export-hint-arrow"
            />
            <div class="vault-dialog-export-hint-text w-300">
                {{ 'VAULT.VAULT_DASHBOARD.DIALOG_EXPORT_HINT' | translate }}
            </div>
        </div>

        <div class="dailySummaryOverviewOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-locations-hint-arrow"
            />
            <div class="daily-locations-hint-text w-200">
                {{ 'REPORT.DAILY_SUMMARY.LOCATIONS_LIST_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-entity-hint-arrow"
            />
            <div class="daily-entity-hint-text w-200">
                {{ 'REPORT.DAILY_SUMMARY.ENTITY_LIST_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-date-picker-hint-arrow"
            />
            <div class="daily-date-picker-hint-text w-200">
                {{ 'REPORT.DAILY_SUMMARY.DATE_RANGE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-title-hint-arrow"
            />
            <div class="daily-title-hint-text w-350">
                {{ 'REPORT.DAILY_SUMMARY.TITLE_HINT_1' | translate }}
                <b>{{ 'REPORT.DAILY_SUMMARY.MIN' | translate }}</b>, <b>{{ 'REPORT.DAILY_SUMMARY.MAX' | translate
                    }}</b>, <b>{{ 'REPORT.DAILY_SUMMARY.AVG' | translate }}</b>,
                <b>{{ 'REPORT.DAILY_SUMMARY.AND/OR' | translate }}</b>
                <b>{{ 'REPORT.DAILY_SUMMARY.TOTAL' | translate }}</b> {{ 'REPORT.DAILY_SUMMARY.TITLE_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-download-hint-arrow"
            />
            <div class="daily-download-hint-text w-200">
                {{ 'REPORT.DAILY_SUMMARY.DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-hide-tile-hint-arrow"
            />
            <div class="daily-hide-tile-hint-text w-200">
                {{ 'REPORT.DAILY_SUMMARY.HIDE_TILE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-tiles-hint-arrow"
            />
            <div class="daily-tiles-hint-text w-200">
                {{ 'REPORT.DAILY_SUMMARY.TILES_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-table-hint-arrow"
            />
            <div class="daily-table-hint-text w-350">
                {{ 'REPORT.DAILY_SUMMARY.REPORT_TABLE_HINT_1' | translate }}
                <b>{{ 'REPORT.DAILY_SUMMARY.MIN' | translate }}</b>, <b>{{ 'REPORT.DAILY_SUMMARY.MAX' | translate
                    }}</b>, <b>{{ 'REPORT.DAILY_SUMMARY.AVG' | translate }}</b>,
                <b>{{ 'REPORT.DAILY_SUMMARY.AND/OR' | translate }}</b>
                <b>{{ 'REPORT.DAILY_SUMMARY.TOTAL' | translate }}</b> {{ 'REPORT.DAILY_SUMMARY.REPORT_TABLE_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-values-hint-arrow"
            />
            <div class="daily-values-hint-text w-350">
                {{ 'REPORT.DAILY_SUMMARY.VALUE_FIELDS_HINT_1' | translate }}
                <b>{{ 'REPORT.DAILY_SUMMARY.MIN' | translate }}</b>
                <b>{{ 'REPORT.DAILY_SUMMARY.AND' | translate }}</b>
                <b>{{ 'REPORT.DAILY_SUMMARY.MAX' | translate }}</b> {{ 'REPORT.DAILY_SUMMARY.VALUE_FIELDS_HINT_2' | translate }}
            </div>
        </div>

        <div class="dailyDialogOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-type-hint-arrow"
            />
            <div class="daily-type-hint-text w-300">
                {{ 'REPORT.DAILY_SUMMARY.TYPE_HINT_1' | translate }}
                <b>{{ 'REPORT.DAILY_SUMMARY.LOC_LOC_GROUP' | translate }}</b> {{ 'REPORT.DAILY_SUMMARY.TYPE_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-dialog-locations-hint-arrow"
            />
            <div class="daily-dialog-locations-hint-text w-300">
                {{ 'REPORT.DAILY_SUMMARY.LOC_LOC_GROUP_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-dialog-entity-hint-arrow"
            />
            <div class="daily-dialog-entity-hint-text w-300">
                {{ 'REPORT.DAILY_SUMMARY.ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-dialog-date-hint-arrow"
            />
            <div class="daily-dialog-date-hint-text w-250">
                {{ 'REPORT.DAILY_SUMMARY.DIALOG_DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-dialog-cancel-hint-arrow"
            />
            <div class="daily-dialog-cancel-hint-text w-350">
                {{ 'REPORT.DAILY_SUMMARY.CLOSE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="daily-dialog-export-hint-arrow"
            />
            <div class="daily-dialog-export-hint-text w-250">
                {{ 'REPORT.DAILY_SUMMARY.EXPORT_HINT' | translate }}
            </div>
        </div>
        <div class="dailySummaryDetailOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dailySummaryDetailsFilterHintArrow"
            />
            <div class="dailySummaryDetailsFilterHintText">
                {{ 'DAILYSUMMARY_DETAILSSECTION_HINT.DAILYSUMMARY_DETAILSFILTER_HINT.LINE_1' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="dailySummaryDetailsDownloadHintArrow"
            />
            <div class="dailySummaryDetailsDownloadHintText">
                {{ 'DAILYSUMMARY_DETAILSSECTION_HINT.DAILYSUMMARY_DETAILSDOWNLOAD_HINT.LINE_1' | translate }}
            </div>
        </div>

        <div class="percentFullOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percentTitleHintArrow"
            />
            <div class="percentTitleHintText w-350">
                {{ 'PERCENT_FULLSECTION_HINT.TITLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percentFullFilterHintArrow"
            />
            <div class="percentFullFilterHintText w-150">
                {{ 'PERCENT_FULLSECTION_HINT.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percentFullDownloadHintArrow"
            />
            <div class="percentFullDownloadHintText w-250">
                {{ 'PERCENT_FULLSECTION_HINT.DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percent-item-hint-arrow"
            />
            <div class="percent-item-hint-text w-250">
                {{ 'PERCENT_FULLSECTION_HINT.LOCATION_LIST_HINT_1' | translate }}
                <b>{{ 'PERCENT_FULLSECTION_HINT.INSTALLATION_TYPE' | translate }}</b> {{ 'PERCENT_FULLSECTION_HINT.LOCATION_LIST_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percent-locations-hint-arrow"
            />
            <div class="percent-locations-hint-text w-150">
                {{ 'PERCENT_FULLSECTION_HINT.LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percent-date-hint-arrow"
            />
            <div class="percent-date-hint-text w-200">
                {{ 'PERCENT_FULLSECTION_HINT.DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percent-yesterday-hint-arrow"
            />
            <div class="percent-yesterday-hint-text w-200">
                {{ 'PERCENT_FULLSECTION_HINT.YESTERDAY_HINT_1' | translate }}
                <b>{{ 'PERCENT_FULLSECTION_HINT.AVERAGE' | translate }}</b> {{ 'PERCENT_FULLSECTION_HINT.AND' | translate }} <b>{{ 'PERCENT_FULLSECTION_HINT.MAX' | translate }}</b> {{ 'PERCENT_FULLSECTION_HINT.YESTERDAY_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="percent-date-column-hint-arrow"
            />
            <div class="percent-date-column-hint-text w-200">
                {{ 'PERCENT_FULLSECTION_HINT.DATE_COLUMN_HINT_1' | translate }}
                <b>{{ 'PERCENT_FULLSECTION_HINT.AVERAGE' | translate }}</b> {{ 'PERCENT_FULLSECTION_HINT.AND' | translate }} <b>{{ 'PERCENT_FULLSECTION_HINT.MAX' | translate }}</b> {{ 'PERCENT_FULLSECTION_HINT.DATE_COLUMN_HINT_2' | translate }}
            </div>
        </div>

        <div class="dataEditingAuditOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-title-hint-arrow"
            />
            <div class="data-audit-title-hint-text w-350">
                {{ 'REPORT.DATA_EDITING_AUDIT.TITLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-export-hint-arrow"
            />
            <div class="data-audit-export-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.EXPORT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-filter-hint-arrow"
            />
            <div class="data-audit-filter-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-date-hint-arrow"
            />
            <div class="data-audit-date-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.DATE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-entity-hint-arrow"
            />
            <div class="data-audit-entity-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-location-hint-arrow"
            />
            <div class="data-audit-location-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.LOCATION_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-edited-on-hint-arrow"
            />
            <div class="data-audit-edited-on-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.EDITED_ON_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-edited-by-hint-arrow"
            />
            <div class="data-audit-edited-by-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.EDITED_BY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-comment-hint-arrow"
            />
            <div class="data-audit-comment-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.COMMENT_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-actions-hint-arrow"
            />
            <div class="data-audit-actions-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.ACTIONS_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="data-audit-item-hint-arrow"
            />
            <div class="data-audit-item-hint-text w-200">
                {{ 'REPORT.DATA_EDITING_AUDIT.ITEM_HINT' | translate }}
            </div>
        </div>

        <div class="uptimeOverviewOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-title-hint-arrow"
            />
            <div class="uptime-title-hint-text w-350">
                {{ 'REPORT.UPTIME.THE' | translate }}
                <b>{{ 'REPORT.UPTIME.DETAILS_TITLE' | translate }}</b> {{ 'REPORT.UPTIME.TITLE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-download-hint-arrow"
            />
            <div class="uptime-download-hint-text w-350">
                {{ 'REPORT.UPTIME.THE' | translate }}
                <b>{{ 'REPORT.UPTIME.DOWNLOAD' | translate }}</b> {{ 'REPORT.UPTIME.DOWNLOAD_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-filter-hint-arrow"
            />
            <div class="uptime-filter-hint-text w-350">
                {{ 'REPORT.UPTIME.FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-loc-filter-hint-arrow"
            />
            <div class="uptime-loc-filter-hint-text w-150">
                {{ 'REPORT.UPTIME.LOCATION_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-entity-filter-hint-arrow"
            />
            <div class="uptime-entity-filter-hint-text w-200">
                {{ 'REPORT.UPTIME.ENTITY_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-date-filter-hint-arrow"
            />
            <div class="uptime-date-filter-hint-text w-200">
                {{ 'REPORT.UPTIME.DATE_FILTER_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-location-hint-arrow"
            />
            <div class="uptime-location-hint-text w-450">
                {{ 'REPORT.UPTIME.LOCATION_HINT_1' | translate }}
                <b>{{ 'REPORT.UPTIME.LOC_GROUP' | translate }}</b> {{ 'REPORT.UPTIME.LOCATION_HINT_2' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-entity-hint-arrow"
            />
            <div class="uptime-entity-hint-text w-300">
                {{ 'REPORT.UPTIME.ENTITY_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-average-hint-arrow"
            />
            <div class="uptime-average-hint-text w-300">
                {{ 'REPORT.UPTIME.AVERAGE_HINT' | translate }}
            </div>

            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptime-day-hint-arrow"
            />
            <div class="uptime-day-hint-text w-350">
                {{ 'REPORT.UPTIME.DAY_HINT' | translate }}
            </div>
        </div>

        <div class="uptimeDetailOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptimeDetailsFilterHintArrow"
            />
            <div class="uptimeDetailsFilterHintText">
                {{ 'UPTIME_DETAILSSECTION_HINT.UPTIME_DETAILSFILTER_HINT.LINE_1' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="uptimeDetailsDownloadHintArrow"
            />
            <div class="uptimeDetailsDownloadHintText">
                {{ 'UPTIME_DETAILSSECTION_HINT.UPTIME_DETAILSDOWNLOAD_HINT.LINE_1' | translate }}
            </div>
        </div>
        <div class="adminScreenOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="addNewUserHintArrow"
            />
            <div class="addNewUserHintText">
                {{ 'ADMIN_SCREEN_HINT.ADD_NEWUSER_HINT.LINE_1' | translate }}
            </div>
        </div>
        <div class="adminDetailsOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="userDetailsHintArrow"
            />
            <div class="userDetailsHintText">
                {{ 'ADMIN_DETAILSSECTION_HINT.USER_DETAILS_HINT.LINE_1' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignRolesUserHintArrow"
            />
            <div class="assignRolesUserHintText">
                {{ 'ADMIN_DETAILSSECTION_HINT.ASSIGN_ROLESUSER_HINT.LINE_1' | translate }}
                <br /> {{ 'ADMIN_DETAILSSECTION_HINT.ASSIGN_ROLESUSER_HINT.LINE_2' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignCustomersUserHintArrow"
            />
            <div class="assignCustomersUserHintText">
                {{ 'ADMIN_DETAILSSECTION_HINT.ASSIGN_CUSTOMERUSER_HINT.LINE_1' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignLocationsUserHintArrow"
            />
            <div class="assignLocationsUserHintText">
                {{ 'ADMIN_DETAILSSECTION_HINT.ASSIGN_LOCATIONUSER_HINT.LINE_1' | translate }}
                <br />{{ 'ADMIN_DETAILSSECTION_HINT.ASSIGN_LOCATIONUSER_HINT.LINE_2' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="assignFeaturesUserHintArrow"
            />
            <div class="assignFeaturesUserHintText">
                {{ 'ADMIN_DETAILSSECTION_HINT.ASSIGN_FEATURESUSER_HINT.LINE_1' | translate }}
            </div>
        </div>
        <div class="flowMonitoringReportOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintFFlowMonitoringReportArrow"
            />
            <div class="fmrHintFlowMonitoringReportText w-450">
                {{ 'FLOW_MONITORING.HINT_FLOW_MONITORING_REPORT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintLocationGroupArrow"
            />
            <div class="fmrHintLocationGroupText">
                {{ 'FLOW_MONITORING.HINT_LOCATION_GROUP' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintLocationGroupListArrow"
            />
            <div class="fmrHintLocationGroupListText w-300">
                {{ 'FLOW_MONITORING.HINT_LOCATION_GROUP_LIST' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintIncludeInactiveLocationsArrow"
            />
            <div class="fmrHintIncludeInactiveLocationsText w-200">
                {{ 'FLOW_MONITORING.HINT_INCLUDE_INACTIVE_LOCATIONS' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintSearchArrow"
            />
            <div class="fmrHintSearchText w-200">
                {{ 'FLOW_MONITORING.HINT_SEARCH' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintDateRangeArrow"
            />
            <div class="fmrHintDateRangeText w-600">
                {{ 'FLOW_MONITORING.HINT_DATE_RANGE' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintDataAveragingArrow"
            />
            <div class="fmrHintDataAveragingText w-600">
                {{ 'FLOW_MONITORING.HINT_DATA_AVERAGING' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintGroupByArrow"
            />
            <div class="fmrHintGroupByText w-600">
                {{ 'FLOW_MONITORING.HINT_GROUP_BY' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintSGOptionsArrow"
            />
            <div class="fmrHintSGOptionsText w-300">
                {{ 'FLOW_MONITORING.HINT_SG_OPTIONS' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintEntitiesArrow"
            />
            <div class="fmrHintEntitiesText w-600">
                {{ 'FLOW_MONITORING.HINT_ENTITIES' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintSectionsToBeExportedArrow"
            />
            <div class="fmrHintSectionsToBeExportedText w-600">
                {{ 'FLOW_MONITORING.HINT_SECTIONS_TO_BE_EXPORTED' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintExportFormatArrow"
            />
            <div class="fmrHintExportFormatText w-600">
                {{ 'FLOW_MONITORING.HINT_EXPORT_FORMAT' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="fmrHintExportArrow"
            />
            <div class="fmrHintExportText w-450">
                {{ 'FLOW_MONITORING.HINT_EXPORT' | translate }}
            </div>
        </div>
        <div class="notificationDashBoardOverlay">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="notification-dashboard-hint-arrow"
            />
            <div class="notification-dashboard-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Notification_Dashboard' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="add_notification-hint-arrow"
            />
            <div class="add_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Add_Notification' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="filter_notification-hint-arrow"
            />
            <div class="filter_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Filter' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="Name-filter_notification-hint-arrow"
            />
            <div class="Name-filter_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Name-filter' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="users_notification-hint-arrow"
            />
            <div class="users_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Users' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="alarm_type_notification-hint-arrow"
            />
            <div class="alarm_type_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Alarm_Type' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="location_Filter_Type_notification-hint-arrow"
            />
            <div class="location_Filter_Type_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Location_Filter_Type' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="reset_notification-hint-arrow"
            />
            <div class="reset_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Reset' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="apply_notification-hint-arrow"
            />
            <div class="apply_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Apply' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="Name_notification-hint-arrow"
            />
            <div class="Name_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="Description_notification-hint-arrow"
            />
            <div class="Description_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Description' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="Type_notification-hint-arrow"
            />
            <div class="Type_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Type' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="list_notification-hint-arrow"
            />
            <div class="list_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.List' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="enable_notification-hint-arrow"
            />
            <div class="enable_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Enable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="edit_notification-hint-arrow"
            />
            <div class="edit_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Edit' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="delete_notification-hint-arrow"
            />
            <div class="delete_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.DASHBOARD.Delete' | translate }}
            </div>
        </div>
        <div class="nd-common-locations">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-Name-hint-arrow"
            />
            <div class="nd-common-Name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_LOCATIONS.Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-Description-hint-arrow"
            />
            <div class="nd-common-Description-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_LOCATIONS.Description' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-Enable-hint-arrow"
            />
            <div class="nd-common-Enable-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_LOCATIONS.Enable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-Search-hint-arrow"
            />
            <div class="nd-common-Search-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_LOCATIONS.Search' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-Location_Group-hint-arrow"
            />
            <div class="nd-common-Location_Group-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_LOCATIONS.Location_Group' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-Locations-hint-arrow"
            />
            <div class="nd-common-Locations-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_LOCATIONS.Locations' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-Next-hint-arrow"
            />
            <div class="nd-common-Next-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_LOCATIONS.Next' | translate }}
            </div>
        </div>
        <div class="nd-common-alarms">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-alarms-Name-hint-arrow"
            />
            <div class="nd-common-alarms-Name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_ALARMS.Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-alarms-Description-hint-arrow"
            />
            <div class="nd-common-alarms-Description-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_ALARMS.Description' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-alarms-Enable-hint-arrow"
            />
            <div class="nd-common-alarms-Enable-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_ALARMS.Enable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-alarms-Alarm_List-hint-arrow"
            />
            <div class="nd-common-alarms-Alarm_List-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_ALARMS.Alarm_List' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-alarms-Back-hint-arrow"
            />
            <div class="nd-common-alarms-Back-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_ALARMS.Back' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-common-alarms-Next-hint-arrow"
            />
            <div class="nd-common-alarms-Next-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_STANDARD_ALARMS.Next' | translate }}
            </div>
        </div>
        <div class="nd-ANSR-alarms">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Name-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Description-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Description-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Description' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Enable-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Enable-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Enable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Source_Alarm-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Source_Alarm-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Source_Alarm' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Entity-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Entity-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Entity' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Variable-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Variable-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Variable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Add-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Add-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Add' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-equation-hint-arrow"
            />
            <div class="nd-ANSR-alarms-equation-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Equation' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Back-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Back-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Back' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-ANSR-alarms-Next-hint-arrow"
            />
            <div class="nd-ANSR-alarms-Next-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_ANSR_ALARMS.Next' | translate }}
            </div>
        </div>
        <div class="nd-alarms-confirm">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-alarms-summary-name-hint-arrow"
            />
            <div class="nd-alarms-summary-name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.NAME' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-alarms-summary-description-hint-arrow"
            />
            <div class="nd-alarms-summary-description-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.DESCRIPTION' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-alarms-summary-location-hint-arrow"
            />
            <div class="nd-alarms-summary-location-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.LOCATION' | translate }}
            </div>

            <!-- Only show one of the following depending on which page you're on -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-alarms-summary-levels-hint-arrow"
            />
            <div class="hide nd-alarms-summary-levels-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.LEVELS' | translate }}
            </div>
            <div class="hide nd-daily-alarms-summary-levels-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.LEVELS_DAILY' | translate }}
            </div>

            <!-- Only show one of the following depending on which page you're on -->
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-alarms-summary-alarm-hint-arrow"
            />
            <div class="hide nd-ANSR-alarms-summary-alarm-hint-text w-300">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.ANSR_ALARM' | translate }}
            </div>
            <div class="hide nd-standard-alarms-summary-alarm-hint-text w-300">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.STANDARD_ALARM' | translate }}
            </div>
            <div class="hide nd-daily-alarms-summary-alarm-hint-text w-300">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.CONFIRMATION.DAILY_ALARM' | translate }}
            </div>
        </div>
        <div class="nd-daily-report">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-Name-hint-arrow"
            />
            <div class="nd-daily-report-Name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-Description-hint-arrow"
            />
            <div class="nd-daily-report-Description-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Description' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-Enable-hint-arrow"
            />
            <div class="nd-daily-report-Enable-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Enable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-communication-hint-arrow"
            />
            <div class="nd-daily-report-communication-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Communication' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-low_battery-hint-arrow"
            />
            <div class="nd-daily-report-low_battery-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Low_Battery' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-select_days-hint-arrow"
            />
            <div class="nd-daily-report-select_days-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Select_Days' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-report_at-hint-arrow"
            />
            <div class="nd-daily-report-report_at-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Report_At' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-Back-hint-arrow"
            />
            <div class="nd-daily-report-Back-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Back' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-daily-report-Next-hint-arrow"
            />
            <div class="nd-daily-report-Next-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.EDIT_DAILY_REPORT.Next' | translate }}
            </div>
        </div>
        <div class="nd-notification-tree">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Name-hint-arrow"
            />
            <div class="nd-notification-tree-Name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Description-hint-arrow"
            />
            <div class="nd-notification-tree-Description-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Description' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Enable-hint-arrow"
            />
            <div class="nd-notification-tree-Enable-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Enable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Add_Level-hint-arrow"
            />
            <div class="nd-notification-tree-Add_Level-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Add_Level' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Level_Name-hint-arrow"
            />
            <div class="nd-notification-tree-Level_Name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Level_Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Delay-hint-arrow"
            />
            <div class="nd-notification-tree-Delay-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Delay' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-User_Count-hint-arrow"
            />
            <div class="nd-notification-tree-User_Count-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.User_Count' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Enable_Level-hint-arrow"
            />
            <div class="nd-notification-tree-Enable_Level-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Enable_Level' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Edit-hint-arrow"
            />
            <div class="nd-notification-tree-Edit-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Edit' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-delete-hint-arrow"
            />
            <div class="nd-notification-tree-delete-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.delete' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Back-hint-arrow"
            />
            <div class="nd-notification-tree-Back-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Back' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-notification-tree-Next-hint-arrow"
            />
            <div class="nd-notification-tree-Next-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.NOTIFICATION_TREE.Next' | translate }}
            </div>
        </div>
        <div class="nd-validation">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-Name-hint-arrow"
            />
            <div class="nd-validation-Name-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.Name' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-Description-hint-arrow"
            />
            <div class="nd-validation-Description-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.Description' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-Enable-hint-arrow"
            />
            <div class="nd-validation-Enable-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.Enable' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-Location-hint-arrow"
            />
            <div class="nd-validation-Location-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.Location' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-List-hint-arrow"
            />
            <div class="nd-validation-List-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.List' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-Remove_from_notification-hint-arrow"
            />
            <div class="nd-validation-Remove_from_notification-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.Remove_from_notification' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-Back-hint-arrow"
            />
            <div class="nd-validation-Back-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.Back' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="nd-validation-Next-hint-arrow"
            />
            <div class="nd-validation-Next-hint-text w-200">
                {{ 'NOTIFICATION_DASHBOARD.HELP_UI.VALIDATION.Next' | translate }}
            </div>
        </div>
        <div class="hintRollBack">
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="roll-back-hint-arrow"
            />
            <div class="roll-back-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Entity_Selector' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="range-hint-arrow"
            />
            <div class="range-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Date_Range' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="check-hint-arrow"
            />
            <div class="check-hint-text w-150">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Check' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="timestamp-hint-arrow"
            />
            <div class="timestamp-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Timestamp' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="depth-hint-arrow"
            />
            <div class="depth-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Depth' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="velocity-hint-arrow"
            />
            <div class="velocity-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Velocity' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="quantity-hint-arrow"
            />
            <div class="quantity-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Quantity' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="rain-hint-arrow"
            />
            <div class="rain-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Rain' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="performed-hint-arrow"
            />
            <div class="performed-hint-text w-100">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Performed_By' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="comments-hint-arrow"
            />
            <div class="comments-hint-text w-150">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Comments' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="paging-hint-arrow"
            />
            <div class="paging-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Paging' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="close-hint-arrow"
            />
            <div class="close-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Close' | translate }}
            </div>
            <img
                [ngSrc]="'/assets/images/arrow.png'" height="62" width="43"
                class="revert-hint-arrow"
            />
            <div class="revert-hint-text w-200">
                {{ 'COMMON.ROLLBACK_EDITOR_QH.Revert' | translate }}
            </div>
        </div>
    </div>
</div>
