import { combineLatest } from 'rxjs';
// TODO: CURRENTUS: Test it on env with markers on map
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocationDetails } from 'app/shared/models/location-details';
import { MapService } from 'app/shared/services/map.service';
import { BehaviorSubject, Subscription } from 'rxjs';

import {
    AddLocationComponent,
    MarkerLocationDetailsComponent,
    MonitorSettingsComponent,
} from '../../../shared/components/map';
import { CompositeLocComponent } from '../../../shared/components/map/composite-loc/composite-loc.component';
import { FilterDataSource } from '../../../shared/components/paging/filter-data-source';
import { CustomerLocationDetail } from '../../../shared/models/customer-location-detail';
import { LocationGroup } from '../../../shared/models/location-group';
import { MonitorAddLocationSelectable, MonitorIdentifier } from '../../../shared/models/monitorsetting';
import { CustomerDetailLocationService } from '../../../shared/services/customer-detail-location.service';
import { StatusCodeService } from '../../../shared/services/status-code.service';
import { UiUtilsService } from '../../../shared/utils/ui-utils.service';
import { LocationWetDryOverflowComponent } from '../../../shared/components/location-wet-dry-overflow/location-wet-dry-overflow.component';
import { CustomerService } from 'app/shared/services/customer.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { GetLocationsRainGaugeResponse, activeInactiveLocationQueryParam } from 'app/shared/models/customer';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { FEATURE_IDS } from 'app/pages/admin/users.service';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { GISService } from 'app/shared/services/gis-service';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { MonitorSeries } from 'app/shared/components/location-card/location-card.constants';
import { TrackBy } from 'app/shared/utils/track-by';

// Rain gauges IDs for #21927
const rainAlertIds = [3, 29];

const WETDRY = 'WetDryOverFlow';
const WETDYRID = 108;
const WDO = 'wdo';
@Component({
    selector: 'app-customer-detail-locations-editor',
    templateUrl: './customer-detail-locations-editor.component.html',
    styleUrls: ['./customer-detail-locations-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDetailLocationsEditorComponent implements OnInit, OnDestroy {
    public PAGE_TEXT: any;
    public locationIdEdited: number;
    public locationDataSourceNew: any;
    public assignedRainGauge = new Array<CustomerLocationDetail>();
    public displayFilters: boolean;
    public customerDtlLocEdtrColumns = [
        'locationName',
        'monitorSeries',
        'serialNumber',
        'description',
        'sensors',
        'locationGroupName',
        'status',
        'activeMonitor',
        'locationTypeID',
        'wdo',
        'editLoc',
    ];
    public customerDtlLocEdtrDataSource = new MatTableDataSource();
    public locationNameList: any[];
    public monitorSeriesList: any[];
    public serialNumberList: any[];
    public descriptionList: any[];
    public lastActiveDateList: any[];
    public lastFirmwareDateList: any[];
    public locationGroupList: any[];
    public activeInactiveList: any[];
    public sensorsList: any[];
    public wdoList: any[];
    public includeInactiveLocations: boolean;
    public compositeList: { value: string; text: string }[];
    public customerId: number;
    private subscriptions = new Array<Subscription>();
    public isLoading: boolean;
    public locationDataChange: BehaviorSubject<CustomerLocationDetail[]> = new BehaviorSubject<
        CustomerLocationDetail[]
    >([]);
    public customerLocations = new Array<CustomerLocationDetail>();
    public customerLocationWithSerialSearch = new Array<CustomerLocationDetail>();
    public initialcustomerLocationsData = new Array<CustomerLocationDetail>();
    public searchedcustomerLocationsData = new Array<CustomerLocationDetail>();
    public isSearchActive: boolean;
    public disableAddLocationBtn: boolean;
    public searchText: string;

    @ViewChild(MatPaginator) public userPaginator: MatPaginator;
    @ViewChild(MatSort) public customerSort: MatSort;
    public totalPaginationLength: number;

    public locationFilterColumns = [
        'locationName',
        'monitorSeries',
        'serialNumber',
        'description',
        'sensors',
        'locationGroupName',
        'status',
        'activeMonitor',
        'locationTypeID',
        'wdo',
        'editLoc',
    ];

    /**
     * Reprsents customer search input
     */
    public searchString = new FormControl();
    public selectedMonitorSeries: string;
    public selectedActiveLocation = true;
    public selectedwdo: string;
    public selectedSensors: string[];
    public selectedComposite: string;
    public selectedLocationGrp: string;
    public rainGaugesList: GetLocationsRainGaugeResponse[] = [];
    public availableRainGauges: GetLocationsRainGaugeResponse[] = [];
    /** Represents a collection of location groups, associative flat array */
    private locationGroupsFlat: { [key: number]: string[] };
    public activateState = false;
    public isSecondMontoringPoint: boolean;
    public dismissText: string;
    public locationUpdateSuccess: string;
    public locationUpdateError: string;
    public cid: number;
    public allTest: string;
    public naTest: string;
    public noTest: string;
    public yesTest: string;
    public activeTest: string;
    public inActiveTest: string;
    public tritonTest: string;
    public echoTest: string;
    public rainTest: string;
    public usgsTest: string;
    public translateKeys: Array<string> = [
        'COMMON.DISMISS_TEXT',
        'COMMON.LOCATION_UPDATE_MESSAGE',
        'COMMON.LOCATION_UPDATE_ERROR',
        'COMMON.TEST_ALL',
        'COMMON.TEST_NA',
        'COMMON.TEST_NO',
        'COMMON.TEST_YES',
        'COMMON.TEST_ACTIVE',
        'COMMON.TEST_INACTIVE',
        'COMMON.TEST_TRI',
        'COMMON.TEST_ECHO',
        'COMMON.TEST_RAIN',
        'COMMON.TEST_USGS',
    ];

    LocationStatus = LocationStatus;
    public locationStatuses: string[] = [];
    public trackByValue = TrackBy.byValue();
    public trackByIndex = TrackBy.byIndex;
    constructor(
        private customerService: CustomerService,
        private dialog: MatDialog,
        private statusCodeService: StatusCodeService,
        private customerLocationService: CustomerDetailLocationService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private locationGroupService: LocationGroupService,
        private mapService: MapService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private locationDashboardService: LocationDashboardService,
        private gisService: GISService,
        private domOperationUtilsService: DomOperationUtilsService,
        private locationCardService: LocationCardService
    ) {
        /*  Search by user email address changes  */
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.locationUpdateSuccess = translateValues['COMMON.LOCATION_UPDATE_MESSAGE'];
            this.locationUpdateError = translateValues['COMMON.LOCATION_UPDATE_ERROR'];
            this.allTest = translateValues['COMMON.TEST_ALL'];
            this.naTest = translateValues['COMMON.TEST_NA'];
            this.noTest = translateValues['COMMON.TEST_NO'];
            this.yesTest = translateValues['COMMON.TEST_YES'];
            this.activeTest = translateValues['COMMON.TEST_ACTIVE'];
            this.inActiveTest = translateValues['COMMON.TEST_INACTIVE'];
            this.tritonTest = translateValues['COMMON.TEST_TRI'];
            this.echoTest = translateValues['COMMON.TEST_ECHO'];
            this.rainTest = translateValues['COMMON.TEST_RAIN'];
            this.usgsTest = translateValues['COMMON.TEST_USGS'];
        });
        this.translate.get('CUSTOMER_EDITOR').subscribe((res: string) => {
            this.PAGE_TEXT = res;
            this.locationStatuses[LocationStatus.Active] = this.PAGE_TEXT.LOCATION_STATUSES.ACTIVE;
            this.locationStatuses[LocationStatus.Maintenance] = this.PAGE_TEXT.LOCATION_STATUSES.MAINTENANCE;
            this.locationStatuses[LocationStatus.Inactive] = this.PAGE_TEXT.LOCATION_STATUSES.INACTIVE;
            this.locationStatuses[LocationStatus.InactiveRemoved] = this.PAGE_TEXT.LOCATION_STATUSES.INACTIVE_REMOVED;
        });

        const searchStrgSubscription = this.searchString.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe((res: string) => {
                this.filterCustomers(res);
            });
        this.subscriptions.push(searchStrgSubscription);
    }

    public ngOnInit() {
        this.domOperationUtilsService.locationFiltersOn.next(this.displayFilters);
        this.subscriptions.push(this.domOperationUtilsService.locationFiltersOn.subscribe((v: boolean) => {
            this.displayFilters = v;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }));
        this.mapService.isMapLocationEdit = false;
        this.monitorSeriesList = <Array<MonitorAddLocationSelectable>>[
            { monitorSeriesID: MonitorIdentifier.RainAlertIII, value: '', text: this.allTest },
            { monitorSeriesID: MonitorIdentifier.TritonPlus, value: 'TRITON+', text: this.tritonTest },
            { monitorSeriesID: MonitorIdentifier.Echo, value: 'ECHO', text: this.echoTest },
            { monitorSeriesID: MonitorIdentifier.RainAlertIII, value: 'RainAlert III', text: this.rainTest },
            { monitorSeriesID: MonitorIdentifier.USGS, value: 'USGS', text: this.usgsTest },
        ];

        this.activeInactiveList = [
            { value: true, text: this.activeTest },
            { value: '', text: this.allTest },
            { value: false, text: this.inActiveTest },
        ];
        this.wdoList = [
            { value: '', text: this.allTest },
            { value: 'N/A', text: this.naTest },
            { value: 'No', text: this.noTest },
            { value: 'Yes', text: this.yesTest },
        ];
        this.compositeList = [
            { value: 'Yes', text: this.yesTest },
            { value: 'No', text: this.noTest },
            { value: '', text: this.allTest },
        ];
        this.totalPaginationLength = 0;
        this.isSearchActive = false;
        this.statusCodeService.customerIdEdited.pipe(distinctUntilChanged()).subscribe((customerID: number) => {
            this.cid = customerID;
        });

        this.subscriptions.push(
            this.route.queryParamMap.subscribe((params: ParamMap) => {
                this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
            }),
        );
        // create a table model, otherwise render may fail
        this.locationDataSourceNew = new FilterDataSource(
            this.locationDataChange,
            [],
            this.userPaginator,
            this.customerSort,
            this.locationFilterColumns,
        );
        // get current query params for customer
        this.statusCodeService.addNewCustomer.subscribe((addNewCustomerFlag: boolean) => {
            // get current query params for customer
             this.route.queryParams.subscribe((params) => {
                if (addNewCustomerFlag) {
                    this.customerId = params['c'];
                } else {
                    this.customerId = params['cid'];
                }
                this.getAllLocations(this.customerId);
            });
        });
        
        this.getRainGaugesData();        
    }
    public onChangeviewAll(event: any) {
        this.userPaginator.pageSize = 25;
        this.userPaginator.pageIndex = 0;
    }
    /**
     * Method brings up a pop up window with locations options
     * @param customAlarm - the customAlarm data object.
     */
    public getRainGaugesData() {
        // Create parameters object for getAllRainGauges method
        const parameters = {
            CID: this.customerId,
            IncludeInactiveLocations: false,
            IType: 'RainGauge',
            PageSize: 1000,
            StartPage: 1,
        };
        // get all rain gauge ids for that location
        const locationRainGaugeSubscription = this.customerService
            .getAllRainGauges(parameters)
            .subscribe((response: GetLocationsRainGaugeResponse[]) => {
                if (response) {
                    this.rainGaugesList = response;
                    if (this.rainGaugesList.length > 0) {
                        this.rainGaugesList.forEach((locationInfo, index) => {
                            if (rainAlertIds.includes(locationInfo.seriesID)) {
                                this.availableRainGauges.push(locationInfo);
                            }
                        });
                    }
                }
            });
        this.subscriptions.push(locationRainGaugeSubscription);
    }
    /**
     * Add New Location
     */
    public addNewLocation() {
        this.dialog
            .open(AddLocationComponent, {
                disableClose: true,
                data: {
                    isFromCustomerEditor: true,
                    customerId: this.customerId,
                    locationList: this.customerLocations,
                    rainGaugeLocations: this.assignedRainGauge,
                    location: location,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                // to do after close
                if (res.success) {
                    this.getAllLocations(this.customerId);
                    this.resetFilters();
                }
            });
    }
    public addCompositeLocation() {
        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            data: Number(this.customerId),
        };

        if (this.gisService.locationCardPosition) {
            dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
        }

        this.dialog
            .open(CompositeLocComponent, dialogOptions)
            .afterClosed()
            .subscribe((res) => {
                if (res.success) {
                    // if user add new location successfully, then refresh map.
                    this.getAllLocations(this.customerId);
                    this.resetFilters();
                }
            });
    }
    public openWetDry(element) {
        this.dialog
            .open(LocationWetDryOverflowComponent, {
                disableClose: true,
                data: {
                    customerId: this.customerId,
                    overidecustomer: true,
                    rainGaugeList: this.availableRainGauges,
                    locationName: element.locationName,
                    locationId: element.locationId,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.success) {
                    this.getAllLocations(this.customerId);
                }
            });
    }

    public editLocation(location: CustomerLocationDetail) {
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        // condition added to to open Triton+ popup if series matches
        if (location && location.locationTypeID === 1) {
            this.mapService.getMarkerLocationDetails(location.locationId, this.customerId).subscribe(
                (result: any) => {
                    if (result) {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        const locationDetails: LocationDetails = {
                            series: result.series,
                            serialNumber: result.serialNumber,
                            status: result.status,
                            modem: result.modem,
                            description: result.description,
                            manholeAddress: result.manholeAddress,
                            coordinate: { latitude: result.latitude, longitude: result.longitude, elevation: 1 },
                            locationID: result.locationID,
                            locationName: result.locationName,
                            assignedRainGaugeLocationId: result.assignedRainGaugeLocationId,
                            installationHeight: result.height,
                            installationWidth: result.width,
                            installationType: result.installationType,
                            installationId: result.installationID,
                            depthUnit: result.depthUnit,
                            flowUnit: result.flowUnit,
                            locationType: result.components ? 3 : 1,
                            components: result.components,
                            installationShape: result.installationShape,
                            ipaddress: result.connectionString,
                            range: result.range,
                            capacity: result.capacity,
                            width: result.width,
                            installationShapeTypeID: result.installationShapeTypeID,
                            manholedepth: result.manholeDepth,
                            length: result.length,
                            breadth: result.breadth,
                            coefficient: result.coefficient,
                            assignedRainGaugeLocationName: result.assignedRainGaugeLocationName,
                            entries: result.entries,
                            lastCollectedDate: '',
                        };

                        const dialogOptions: MatDialogConfig = {
                            disableClose: true,
                            data: {
                                isFromCustomerEditor: true,
                                rainGaugeLocations: this.assignedRainGauge,
                                customerId: this.customerId,
                                editMode: true,
                                locationDetails: locationDetails,
                            },
                        };

                        if (this.gisService.locationCardPosition) {
                            dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                        }

                        if (this.mapService.addEditLocationDialog) {
                            this.mapService.addEditLocationDialog.close();
                        }

                        this.mapService.addEditLocationDialog = this.dialog.open(
                            TritonLocationDialogComponent,
                            dialogOptions,
                        );
                        this.mapService.addEditLocationDialog
                            .afterClosed()
                            .pipe(first())
                            .subscribe((result: boolean) => {
                                this.mapService.addEditLocationDialog = null;
                                if (result) {
                                    this.locationIdEdited = location.locationId;
                                    this.getAllLocations(this.customerId);
                                    this.uiUtilsService.safeChangeDetection(this.cdr);
                                }
                            });
                    }
                },
                (error) => {
                    this.isLoading = false;
                },
            );
            return;
        }
        if (location.locationTypeID === 3) {
            this.customerLocationService.getLocationSummary(this.customerId, this.includeInactiveLocations).subscribe((items: any) => {
                let locations = new Array<CustomerLocationDetail>();
                if (items) {
                    locations = items.listCutomerLocations;
                }
                this.mapService.getMarkerLocationDetails(location.locationId, this.customerId).subscribe(
                    (result: any) => {
                        if (result) {
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                            const locationDetails: LocationDetails = {
                                locationID: result.locationID,
                                locationName: result.locationName,
                                description: result.description,
                                manholeAddress: result.manholeAddress,
                                coordinate: { latitude: result.latitude, longitude: result.longitude, elevation: 1 },
                                status: result.status,
                                modem: result.modem,
                                series: result.series,
                                serialNumber: result.serialNumber,
                                ipaddress: result.ipaddress,
                                lastCollectedDate: undefined,
                                components: result.components,
                            };
                            const dialogOptions: MatDialogConfig = {
                                disableClose: true,
                                data: [locationDetails, locations, this.customerId],
                            };

                            if (this.gisService.locationCardPosition) {
                                dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
                            }
                            this.dialog
                                .open(CompositeLocComponent, dialogOptions)
                                .afterClosed()
                                .subscribe((result) => {
                                    if (result.success) {
                                        this.locationIdEdited = location.locationId;
                                        this.getAllLocations(this.customerId);
                                        this.uiUtilsService.safeChangeDetection(this.cdr);
                                    }
                                });
                        }
                    },
                    (error) => {
                        this.isLoading = false;
                    },
                );
            });
        } else {
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);

            this.dialog
                .open(MarkerLocationDetailsComponent, {
                    disableClose: true,
                    data: {
                        openEditDialogFromCustomerEdit: true,
                        CustomerId: this.customerId,
                        locationList: this.customerLocations,
                        assignedRainGauge: this.assignedRainGauge,
                        location: location.locationId,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.success) {
                        this.locationIdEdited = location.locationId;
                        this.getAllLocations(this.customerId);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                });
        }

        this.isLoading = false;
    }

    /**
     * Show Hide Filter
     */
    public showHideFilter() {
        this.displayFilters = !this.displayFilters;
        this.domOperationUtilsService.locationFiltersOn.next(this.displayFilters);
    }

    public getAllLocations(customerId: number) {
        this.isLoading = true;

        const locationsSubscription = combineLatest([
            this.locationGroupService.getLocationGroups(this.customerId),
            this.customerLocationService.getLocationSummary(customerId, this.includeInactiveLocations),
        ]).subscribe(
            ([locationGroups, locationData]) => {
                if (!this.locationGroupsFlat) {
                    if (locationGroups && locationGroups !== 'No Data Available') {
                        this.locationGroupList = new Array<any>();
                        const flatLocations = locationGroups.locationGroups.flatMap((x) => [
                            ...x.locations.map((l) => {
                                return {
                                    ...l,
                                    locationGroupID: x.locationGroupID,
                                    locationGroupName: x.name
                                };
                            }),
                        ]);

                        this.locationGroupsFlat = [];
                        flatLocations.forEach((x) => {
                            if (!this.locationGroupsFlat[x.locationID]) {
                                this.locationGroupsFlat[x.locationID] = [x.locationGroupName];
                            } else {
                                this.locationGroupsFlat[x.locationID].push(x.locationGroupName);
                            }
                        });

                        if (locationGroups) {
                            locationGroups.locationGroups.forEach((locationGroup: LocationGroup) => {
                                const locationGroupSelectorItem = {
                                    value: locationGroup.name,
                                    text: locationGroup.name,
                                };
                                this.locationGroupList.push(locationGroupSelectorItem);
                            });
                        }
                        const locationGroupSelectorItemAll = { value: '', text: 'All' };
                        this.locationGroupList.push(locationGroupSelectorItemAll);
                    } else {
                        this.locationGroupsFlat = [];
                        this.locationGroupList = [{ value: '', text: 'All' }];
                    }
                }

                if (locationData) {
                    this.isLoading = false;
                    this.customerLocations = locationData.listCutomerLocations
                        .map((x) => {
                            return {
                                ...x,
                                locationGroupName: this.locationGroupsFlat[x.locationId]
                                    ? this.locationGroupsFlat[x.locationId]
                                    : null,
                            };
                        })
                        .sort((a, b) =>
                            a.locationName && b.locationName
                                ? a.locationName.localeCompare(b.locationName)
                                : a.locationName
                                    ? 1
                                    : -1,
                        );
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.initialcustomerLocationsData = this.customerLocations;
                    if (this.isSearchActive) {
                        this.searchedcustomerLocationsData = this.initialcustomerLocationsData.filter((x) =>
                            x.locationName.toLowerCase().includes(this.searchText.toLowerCase()),
                        );
                    }
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.applyFilters();
                    this.generateLocationTable();
                    this.setupRainGaugeLocations();
                    this.getSensorsList(locationData.listCutomerLocations);
                } else if (locationData == null) {
                    this.isLoading = false;
                    this.generateLocationTable();
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
        // get location groups

        this.subscriptions.push(locationsSubscription);
    }

    private getSensorsList(locationSensors: CustomerLocationDetail[]) {
        const uniqueSensors = [];
        locationSensors.forEach((element) => {
            if (element.sensors && element.sensors !== '') {
                const sensors = element.sensors.split(',');
                sensors.forEach((e) => {
                    uniqueSensors.push(e.trim());
                });
            }
        });
        this.sensorsList = uniqueSensors.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        });
    }

    /**
     * Method generates a table of api keys
     */
    public generateLocationTable() {
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.customerSort.start = 'desc';
        this.locationDataSourceNew = null;
        this.locationDataChange = new BehaviorSubject<CustomerLocationDetail[]>([]);
        const locationCopiedData = [];
        this.customerLocations.forEach((location: CustomerLocationDetail, index) => {
            location.highlight = false;
            if (this.locationIdEdited && this.locationIdEdited === location.locationId) {
                const pageIndex = index / this.userPaginator.pageSize;
                const finalPageIndex = +pageIndex.toString().split('.')[0];
                this.userPaginator.pageIndex = finalPageIndex;
                location.highlight = true;
            }

            const monitorSeries = MonitorSeries.find(a => a.value === location.monitorSeries);
            if(monitorSeries){
                location.monitorSeries = monitorSeries.text;
            }

            const data: CustomerLocationDetail = {
                locationId: location.locationId,
                locationName: location.locationName,
                monitorSeries: location.monitorSeries,
                serialNumber: location.serialNumber,
                description: location.description,
                sensors: location.sensors,
                locationGroupName: location.locationGroupName,
                status: location.status,
                wdo: location.wdo !== 'N/A' ? location.wdo : '',
                locationTypeID: location.locationTypeID,
                highlight: location.highlight,
            };
            locationCopiedData.push(data);
        });
        this.locationDataChange.next(locationCopiedData);
        this.locationDataSourceNew = new FilterDataSource(
            this.locationDataChange,
            this.customerLocations,
            this.userPaginator,
            this.customerSort,
            this.locationFilterColumns,
        );
        if (!this.locationIdEdited) {
            this.userPaginator.pageIndex = 0;
        }
        this.totalPaginationLength = this.customerLocations.length;
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public activeMonitor(location: CustomerLocationDetail) {
        if (location.monitorSeries.toLowerCase().indexOf('triton+') > -1) {
            this.editLocation(location);
            return;
        }

        this.isLoading = true;

        const customerId = this.customerId;
        let result: any;
        const locationsSubscription = this.mapService
            .getMarkerLocationDetails(location.locationId, customerId)
            .subscribe(
                (response: any) => {
                    if (response) {
                        result = response;
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);

                        this.dialog.open(MonitorSettingsComponent, {
                            disableClose: true,
                            data: {
                                customerId: customerId,
                                LocationName: result.locationName,
                                Description: result.description,
                                ManholeAddress: result.manholeAddress,
                                Latitude: result.coordinate ? result.coordinate.latitude : '',
                                Longitude: result.coordinate ? result.coordinate.latitude : '',
                                IsActiveLocation:
                                    result.status === LocationStatus.Active ||
                                    result.status === LocationStatus.Maintenance,
                                MonitorSeries: result.series,
                                SerialNumber: result.serialNumber,
                                Sensors: result.sensors,
                                IpAddress: result.ipaddress,
                                locationID: result.locationID,
                                instalationType: result.installationType,
                            },
                        });
                    }
                },
                (error) => {
                    this.isLoading = false;
                },
            );
        this.subscriptions.push(locationsSubscription);
    }

    public onChange(e: MatSlideToggleChange, locationDetail: CustomerLocationDetail) {
        this.isLoading = true;
        let activeState = 'true';
        if (e.checked === true) {
            activeState = 'true';
        } else {
            activeState = 'false';
        }
        this.locationIdEdited = locationDetail.locationId;
        const editApiKeySubscription = this.customerLocationService
            .editLocation(this.customerId, locationDetail.locationId, e.checked)
            .subscribe(
                (response) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.getAllLocations(this.customerId);
                    if (response) {
                        this.snackBar.open(this.locationUpdateSuccess, this.dismissText, { duration: 10000 });
                    } else {
                        this.snackBar.open(this.locationUpdateError, this.dismissText);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.generateLocationTable();
                },
            );

        this.subscriptions.push(editApiKeySubscription);
    }

    private setupRainGaugeLocations() {
        // check if rain guage locations are already filled and if so exit immediately
        if (this.assignedRainGauge && this.assignedRainGauge.length > 0) {
            return;
        }

        // check if locations exist
        if (this.customerLocations) {
            // make the filtered array for assignedRainGauge.
            this.assignedRainGauge = this.customerLocations.filter((location) => {
                if (
                    location &&
                    location.monitorSeries &&
                    location.monitorSeries.toLowerCase().indexOf('rainalert') > -1
                ) {
                    return location;
                }
            });
        }
    }

    /**
     * Method takes a sting as an argument and filters out customers whose names include the string
     * @param searchString - string to search for customer
     */

    public applyFilters() {
        if (this.isSearchActive) {
            this.customerLocations = this.searchedcustomerLocationsData;
        } else {
            this.customerLocations = this.initialcustomerLocationsData;
        }

        if (this.selectedMonitorSeries) {
            this.monitorSeriesChange('');
        }
        if (this.selectedLocationGrp) {
            this.locationGrpChange();
        }
        if (this.selectedActiveLocation !== null) {
            this.filterCustomersForActiveLoc(this.selectedActiveLocation);
        }

        if (this.selectedwdo) {
            this.wdoListChange('');
        }
        if (this.selectedComposite) {
            this.compositeListChange('');
        }
        if (this.selectedSensors) {
            this.sensorsListChange();
        }
    }

    public filterCustomers(searchString: string) {
        if (!searchString || searchString.trim() === '') {
            this.customerLocations = this.initialcustomerLocationsData;
            this.searchText = '';
        } else {
            searchString = searchString.trim();
            this.searchText = searchString;
            this.customerLocations = this.initialcustomerLocationsData.filter((x) =>
                x.locationName && x.locationName.toLowerCase().includes(searchString.toLowerCase()),
            );
            this.customerLocationWithSerialSearch = this.initialcustomerLocationsData.filter((x) =>
                x.serialNumber && x.serialNumber.toLowerCase().includes(searchString.toLowerCase()),
            );

            this.userPaginator.pageIndex = 0;
        }
        this.searchedcustomerLocationsData = [...this.customerLocations, ...this.customerLocationWithSerialSearch];
        this.isSearchActive = true;
        this.applyFilters();
        this.generateLocationTable();
    }

    public filterCustomersForMonitor(searchString: string) {
        if (!searchString || searchString.trim() === '') {
            this.customerLocations = this.customerLocations;
        } else {
            searchString = searchString.trim();
            this.customerLocations = this.customerLocations.filter((x) =>
                x.monitorSeries && x.monitorSeries.toLowerCase().includes(searchString.toLowerCase()),
            );
            this.userPaginator.pageIndex = 0;
        }
        this.generateLocationTable();
    }

    public filterCustomersForActiveLoc(isActive) {
        if (isActive === undefined || typeof isActive === 'string') {
            this.customerLocations = this.customerLocations;
        } else {
            this.customerLocations = this.customerLocations.filter((x) =>
                isActive
                    ? x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance
                    : x.status === LocationStatus.Inactive || x.status === LocationStatus.InactiveRemoved,
            );
            this.userPaginator.pageIndex = 0;
        }

        this.generateLocationTable();
    }

    public monitorSeriesChange(value: string) {
        this.filterCustomersForMonitor(this.selectedMonitorSeries);
    }

    public activeInactiveListChange(value: any) {
        this.filterCustomersForActiveLoc(this.selectedActiveLocation);
    }
    public compositeListChange(value: string) {
        if (!this.selectedComposite || this.selectedComposite.trim() === '') {
            this.customerLocations = this.customerLocations;
        } else {
            this.customerLocations =
                this.selectedComposite.toLowerCase() === 'yes'
                    ? this.customerLocations.filter((x) => x.locationTypeID !== 1)
                    : this.customerLocations.filter((x) => x.locationTypeID === 1);
            this.userPaginator.pageIndex = 0;
        }
        this.generateLocationTable();
    }
    public wdoListChange(value: string) {
        if (!this.selectedwdo || this.selectedwdo.trim() === '') {
            this.customerLocations = this.customerLocations;
        } else {
            this.customerLocations = this.customerLocations.filter((x) =>
                x.wdo.toLowerCase().includes(this.selectedwdo.toLowerCase()),
            );
            this.userPaginator.pageIndex = 0;
        }
        this.generateLocationTable();
    }

    private sensorsListChange() {
        if (this.selectedSensors[0] === '') {
            this.customerLocations = this.customerLocations;
        } else {
            this.selectedSensors.forEach((e) => {
                this.customerLocations = this.customerLocations.filter(
                    (x) => x.sensors && x.sensors.toLowerCase().includes(e.toLowerCase()),
                );
            });
        }
        this.userPaginator.pageIndex = 0;
        this.generateLocationTable();
    }

    public locationGrpChange() {
        if (!this.selectedLocationGrp || this.selectedLocationGrp.trim() === '') {
            this.customerLocations = this.customerLocations;
        } else {
            this.customerLocations = this.customerLocations.filter(
                (x) => {
                    if (!x.locationGroupName) return false;
                    if (x.locationGroupName instanceof Array) return x.locationGroupName.find(s => s.toLowerCase() === this.selectedLocationGrp.toLowerCase());
                    return x.locationGroupName.toLowerCase().includes(this.selectedLocationGrp.toLowerCase());
                }
            );
            this.userPaginator.pageIndex = 0;
        }
        this.generateLocationTable();
    }

    public resetFilters() {
        this.selectedMonitorSeries = null;
        this.selectedLocationGrp = null;
        this.selectedActiveLocation = null;
        this.selectedwdo = null;
    }
    public addTritonLocation() {
        //  disable "add new location" button on customer editor to prevent multiple dialogs being open
        this.disableAddLocationBtn = true;
        const getLocationsSubscription = this.locationDashboardService
            .getLocations(<LocationArgs>{ customerId: this.customerId })
            .subscribe((locations: Array<Locations>) => {
                // Variable to store rain gauge locations
                let rainGaugeLocations = new Array<Locations>();
                // check if locations exist
                if (locations) {
                    // Filter and set rain gauge locations
                    rainGaugeLocations =
                        locations.filter(
                            (location) => location.series && location.series.toLowerCase().indexOf('rainalert') > -1,
                        ) || new Array<Locations>();
                    rainGaugeLocations.sort(this.uiUtilsService.sortLocations);
                }
                // open add location dialog
                const dialogOptions: MatDialogConfig = {
                    disableClose: true,
                    data: {
                        isFromCustomerEditor: true,
                        rainGaugeLocations,
                        customerId: this.customerId,
                        editMode: false,
                        locationDetails: {},
                    },
                };

                if (this.gisService.locationCardPosition) {
                    dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                }

                if (this.mapService.addEditLocationDialog) {
                    this.mapService.addEditLocationDialog.close();
                }

                this.mapService.addEditLocationDialog = this.dialog.open(TritonLocationDialogComponent, dialogOptions);
                this.mapService.addEditLocationDialog
                    .afterClosed()
                    .pipe(first())
                    .subscribe((result: boolean) => {
                        this.mapService.addEditLocationDialog = null;
                        if (result) {
                            this.getAllLocations(this.customerId);
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        }
                    });
            });
        this.subscriptions.push(getLocationsSubscription);
    }

    ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.forEach((v) => v.unsubscribe());
    }
}

export interface Element {
    id: number;
    name: string;
    series: string;
    serialNo: string;
    description: string;
    lstActDt: string;
    lstFirmUpd: string;
    locationGrp: string;
    active: boolean;
    activeMonitor: string;
    wdo: string;
}
