<div class="long-table" [ngClass]="{ 'dont-print': !paginationMaxLength || !isExpanded }">
    <mat-accordion>
        <mat-expansion-panel [(expanded)]="isExpanded" class="ld-data-table-hint" (opened)="expandDataEditing()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <b>{{ 'COMMON.LONG_TABLE' | translate }}</b>
                    <!-- new page icon -->
                    <button *ngIf="false" mat-icon-button class="new-page-icon">
                        <mat-icon matTooltip="New Page">open_in_new</mat-icon>
                    </button>

                    <div class="only-print">
                        <div class="flag-color-text">{{ 'COMMON.FLAG_FOR_IGNORE' | translate }}</div>
                        <div class="flag-edited-color-text">{{ 'COMMON.PREVIOUSY_EDITED' | translate }}</div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="table-scroll-wraper">
                <div *ngIf="paginationMaxLength">
                    <div *ngIf="isLoading">
                        <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
                    </div>
                    <div class="search-section" *ngIf="showSearch">
                        <mat-form-field class="full-width">
                            <mat-icon matPrefix class="search-icon">search</mat-icon>
                            <input matInput placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }} " />
                        </mat-form-field>
                    </div>
                    <mat-table #dataEditingTable [dataSource]="dataEditingSource" class="dataEditingTable" matSort (matSortChange)="sortChange()">
                        <ng-container *ngFor="let column of tableSubHeaderColumns; trackBy: trackByIndex">
                            <ng-container matColumnDef="{{ column.id }}">
                                <mat-header-cell sortingCache *matHeaderCellDef mat-sort-header
                                    >{{ column.title | uppercase
                                    }}<span *ngIf="column.unit"> ({{ column.unit }})</span></mat-header-cell
                                >
                                <mat-cell
                                    (blur)="dataRowLabelClick($event, element, column.id, i, false)"
                                    [ngClass]="{ selected: element['highlight'] }"
                                    *matCellDef="let element; let i = index"
                                >
                                    <mat-form-field
                                        class="edit-field"
                                        *ngIf="
                                            element['editable'] === column.id &&
                                            column.id !== TIMESTAMP_CONVERTED_COLUMN
                                        "
                                    >
                                        <input
                                            matInput
                                            [value]="element[column.id]"
                                            type="number"
                                            (blur)="
                                                dataRowLabelClick($event, element, column.id, element[column.id], false)
                                            "
                                            matInput
                                        />
                                    </mat-form-field>
                                    <label
                                        class="link-label"
                                        *ngIf="
                                            element['editable'] !== column.id ||
                                            column.id === TIMESTAMP_CONVERTED_COLUMN
                                        "
                                        (click)="dataRowLabelClick($event, element, column.id, undefined, true)"
                                        >{{ element[column.id] }}</label
                                    >
                                </mat-cell>
                            </ng-container>
                        </ng-container>

                        <!-- <mat-header-row * atHeaderRowDef="headerColumns"></mat-header-row> -->
                        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                        <mat-row
                            class="link-label"
                            (click)="dataTableRowClick(row, i)"
                            *matRowDef="let row; columns: tableColumns; let i = index"
                        >
                        </mat-row>
                    </mat-table>
                    <div class="table-action dont-print">
                        <div class="auto-margin">
                            <div class="flag-color"></div>
                        </div>
                        <div class="auto-margin">{{ 'COMMON.FLAG_FOR_IGNORE' | translate }}</div>
                        <div class="auto-margin">
                            <div class="flag-color flag-edited-color"></div>
                        </div>
                        <div class="auto-margin">
                            {{ 'COMMON.PREVIOUSY_EDITED' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin">
                            <!-- paginationMaxLength > 25 to show the pagination so the user can return it back to 25 per page if needed - othervise no need to display paging-->
                            <mat-paginator
                                #pagination
                                [hidden]="paginationMaxLength > pageSize || paginationMaxLength > 25"
                                [length]="paginationMaxLength"
                                [pageSize]="pageSize"
                                [pageSizeOptions]="[25, 100, 200, 500, 1000]"
                                (page)="pageEvent()"
                            >
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dont-print" *ngIf="!paginationMaxLength">
                <div class="notabledataFound custom-table print-nodata-space">
                    <span>{{ 'COMMON.NO_DATA_IN_GRAPH_MSG' | translate }}</span>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
