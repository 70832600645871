import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SeriesProperty } from 'app/pages/dashboards/custom-dashboard/custom-dashboard-model/custom-dashboard.model';
import { EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';
import { HydrographArgs } from 'app/shared/models/hydrograph';
import { Locations } from 'app/shared/models/locations';
import { LocationData, LocationEntitiesData, LocationListArgs } from 'app/shared/models/locations-entities-data';
import { QUICK_DATE_RANGES } from 'app/shared/models/view-data';
import { MultipleLocationsDialogData } from 'app/shared/models/view-data-filter';
import { EntityService } from 'app/shared/services/entity.service';
import { LocationService } from 'app/shared/services/location.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-other-locations-graphing-dialog',
    templateUrl: './other-locations-graphing-dialog.component.html',
    styleUrls: ['./other-locations-graphing-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherLocationsGraphingDialogComponent implements OnInit {
    @ViewChildren(DateRangePickerComponent) private datePickers: QueryList<DateRangePickerComponent>;
    public selectedItems: Array<SeriesProperty> = [];
    public autocompleteData: Array<{ text: string | Locations; locations: Array<Locations> }> = [];
    public locationList = Array<Locations>();

    public enableAddNewRow = false;
    public invalidEntitySelected: boolean;
    public isLoading: boolean;
    public invalidDate: boolean;

    public entitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};
    public ansrEntitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};

    public dismissBtn: string;
    public QUICK_DATE_RANGES = QUICK_DATE_RANGES;

    public formChanged = false;

    public trackByIndex = TrackBy.byIndex;
    constructor(private uiUtilsService: UiUtilsService, private cdr: ChangeDetectorRef,
        private snackBarNotificationService: SnackBarNotificationService,
        private translateService: TranslateService, private locationService: LocationService,
        private statusCodeService: StatusCodeService, private entityService: EntityService,
        public dialogRef: MatDialogRef<OtherLocationsGraphingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MultipleLocationsDialogData,
        private viewDataService: ViewDataService) { }

    ngOnInit(): void {
        this.addMoreRow();
        this.dismissBtn = this.translateService.instant('COMMON.DISMISS_TEXT');

        this.loadEntities();
    }

    public locationSearchDisplay(obj): string {
        if (typeof obj === 'string') return obj;

        return obj && obj.name && obj.name.length > 0 ? obj.name : '';
    }

    public filterLocations(i: number, search: { text: string | Locations; locations: Locations[] }): Array<Locations> {
        if (!this.locationList) return [];

        const text = typeof search.text === 'string' ? search.text : search.text.locationName;

        this.autocompleteData[i].locations = this.locationList.filter((x) =>
            x.locationName.toLowerCase().includes(text.toLowerCase()),
        );

        this.autocompleteData[i].text = text;
    }

    public locationPicked(index: number, loc: Locations) {
        const lid = loc.locationId;
        this.selectedItems[index].lid = lid;
        this.selectedItems[index].locationName = loc.locationName;
        this.checkFormValidity();

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public checkFormValidity() {
        this.enableAddNewRow = this.selectedItems.every((item: SeriesProperty) => item.lid !== -1 && item.eid !== -1 && item.eid !== null && item.color !== '');
    }

    public selectedGroupEntity(selectedEntities: EntitySelectorEntity[], selectedSeries: SeriesProperty) {
        if (!this.selectedItems) {
            return;
        }
        this.invalidEntitySelected = false;
        const index = this.selectedItems.indexOf(selectedSeries);

        if (index === -1) {
            return;
        }

        if ((this.selectedItems[index].eid === -1 && !selectedEntities.length) ||
            (selectedEntities.length && this.selectedItems[index].eid !== selectedEntities[0].id)) {
            this.formChanged = true;
        }
        if (selectedEntities && selectedEntities.length) {
            this.selectedItems[index].eid = selectedEntities[0].id;
            this.selectedItems[index].entityName = selectedEntities[0].name;
        } else {
            this.selectedItems[index].eid = null;
            this.selectedItems[index].entityName = null;
        }

        this.checkFormValidity();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public selectedColorPicker($event, selectedSeries: SeriesProperty) {
        const index = this.selectedItems.indexOf(selectedSeries);
        if (index !== -1) {
            this.selectedItems[index].color = $event;
        }
        if (this.uiUtilsService.reserveColors && this.uiUtilsService.reserveColors.indexOf($event) >= 0) {
            this.snackBarNotification(
                this.reserveColorsMessage($event),
                'custom-error-snack-bar',
                false
            );
            return;
        }
        this.checkFormValidity();
    }

    public snackBarNotification(message: string, notificationClass, auto) {
        this.snackBarNotificationService.raiseNotification(message, this.dismissBtn, {
            panelClass: notificationClass,
        }, auto);
    }

    public reserveColorsMessage(color) {
        let reserveColorsMessage = '';
        const flaggedColorReserved = this.translateService.instant('COMMON.RESERVED_FLAGGED_DATA');
        const editedColorReserved = this.translateService.instant('COMMON.RESERVED_EDITED_DATA');
        const selectdColorReserved = this.translateService.instant('COMMON.RESERVED_SELECTED_DATA');
        const rainColorReserved = this.translateService.instant('COMMON.RESERVED_RAIN_DATA');
        switch (color) {
            case '#FF0000':
                reserveColorsMessage = flaggedColorReserved;
                break;
            case '#FFAD26':
                reserveColorsMessage = editedColorReserved;
                break;
            case '#FFFF00':
                reserveColorsMessage = selectdColorReserved;
                break;
            case '#1F1F9C':
                reserveColorsMessage = rainColorReserved;
                break;
            default:
                break;
        }
        return reserveColorsMessage;
    }

    public removeRow(index: number) {
        this.formChanged = true;
        if (index > -1) {
            this.selectedItems.splice(index, 1);
            this.autocompleteData.splice(index, 1);
        }
        this.checkFormValidity();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public addMoreRow() {
        this.selectedItems.push({
            lid: -1,
            eid: -1,
            color: '',
            startDate: this.data.startDate,
            endDate: this.data.endDate
        });

        this.autocompleteData.push({ text: '', locations: this.locationList });
        this.checkFormValidity();
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.setAvailableQuickRanges();
    }

    public selectedStartDate(date: Date, index: number) {
        if (!date) return;

        this.selectedItems[index].startDate = date;
        this.checkDatesValidity();
    }

    public selectedEndDate(date: Date, index: number) {
        if (!date) return;

        this.selectedItems[index].endDate = date;
        this.checkDatesValidity();
    }

    private setAvailableQuickRanges() {
        const formattedEndDate = new Date(this.data.endDate).setHours(23,59,59);    // need to compare with quick ranges
        const check = ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
            return this.data.startDate.getTime() <= startDate.getTime() && endDate.getTime() <= formattedEndDate;
        }

        this.datePickers.forEach((dp) => {
            const oneDay = dp.getTimespan(QUICK_DATE_RANGES.TODAY);
            const oneWeek = dp.getTimespan(QUICK_DATE_RANGES.LAST_WEEK);
            const lastMonth = dp.getTimespan(QUICK_DATE_RANGES.PREVIOUS_MONTH);
            const oneMonth = dp.getTimespan(QUICK_DATE_RANGES.LAST_MONTH);
            const threeMonths = dp.getTimespan(QUICK_DATE_RANGES.LAST_THREE_MONTHS);
            const sixMonths = dp.getTimespan(QUICK_DATE_RANGES.LAST_SIX_MONTHS);
            const oneYear = dp.getTimespan(QUICK_DATE_RANGES.LAST_YEAR);

            dp.oneDayAvailable = check(oneDay);
            dp.oneWeekAvailable = check(oneWeek);
            dp.lastMonthAvailable = check(lastMonth);
            dp.oneMonthAvailable = check(oneMonth);
            dp.threeMonthsAvailable = check(threeMonths);
            dp.sixMonthsAvailable = check(sixMonths);
            dp.oneYearAvailable = check(oneYear);
        });
    }

    private checkDatesValidity() {
        const formattedEndDate = new Date(this.data.endDate).setHours(23,59,59);
        const getTime = (date: Date) => date.getTime();
        const isDatesValid = this.selectedItems.every((item: SeriesProperty) => {
            const { startDate, endDate } = item;

            return getTime(this.data.startDate) <= getTime(startDate) && formattedEndDate >= getTime(endDate);
        });

        this.invalidDate = !isDatesValid;
    }

    private loadEntities() {
        this.isLoading = true;
        const loadEntitiesSubscription = this.locationService
            .getLocationData(<LocationListArgs>{
                cid: this.data.customerId,
                IncludeInactiveLocations: this.statusCodeService.activeInactiveHandler.getValue(),
            })
            .subscribe((entityData: LocationEntitiesData | never) => {
                if (!entityData) {
                    loadEntitiesSubscription.unsubscribe();
                    return;
                }

                entityData.l = entityData.l.map((l) => {
                    if (l.s === 'Echo') {
                        l.s = 'ECHO';
                    }
                    return l;
                });
                entityData.d = entityData.d.map((d) => {
                    if (d.s === 'Echo') {
                        d.s = 'ECHO';
                    }
                    return d;
                });

                // handle entities
                this.distributeEntitiesAndLocations(entityData);
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                loadEntitiesSubscription.unsubscribe();
            });
    }

    private distributeEntitiesAndLocations(data: LocationEntitiesData) {
        this.locationList = data.l.map(
            (locData: LocationData) =>
                <Locations>{
                    locationId: locData.lid,
                    name: locData.n,
                    ae: locData.ae,
                    locationName: locData.n,
                    latitude: locData.lat,
                    longitude: locData.lon,
                    locationType: locData.t,
                    status: locData.st,
                    series: locData.s,
                },
        );

        const { lid, t: type, s: series } = this.data.currentLocation;
        this.locationList = this.locationList.filter(v => v.locationId !== lid)

        if (this.data.prevData) {
            this.selectedItems = this.data.prevData.map(item => ({ lid: item.lid, eid: item.eid, color: item.color, startDate: new Date(item.startDate), endDate: new Date(item.endDate) }));
            this.autocompleteData = this.selectedItems.map(v => ({ text: '', locations: this.locationList }));
        }
        for (let i = 0; i < this.selectedItems.length; i++) {
            const item = this.selectedItems[i];
            this.autocompleteData[i].locations = this.locationList;

            const location = this.locationList.find((x) => x.locationId === item.lid);
            this.autocompleteData[i].text = location ? location.locationName : '';
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.setAvailableQuickRanges();

        const currentLocationEntityGroups = this.entityService.getSelectedDevices(data, series, type);
        const currentLocationEntGroupIds: Map<number, boolean> = currentLocationEntityGroups[0].g.reduce((acc, curr) => acc.set(curr.id, true), new Map<number, boolean>());

        this.entitiesByLocation = this.locationList.reduce((acc, val) => {
            const groups = this.entityService.getSelectedDevices(data, val.series, val.locationType);

            if (!groups) { return acc; }

            const entityGroups = [...this.entityService.seriesEntityGroupToEntitySelectorObject([...groups[0].g])];
            const filteredGroups = entityGroups.filter(v => currentLocationEntGroupIds.get(v.groupId));

            const groupsWithFilteredEntities = filteredGroups.map((group: EntitySelectorObject) => {
                const currentLocationGroup = currentLocationEntityGroups[0].g.find(v => v.id === group.groupId);
                const currentLocationGroupEntitiesIds = currentLocationGroup.entities.map(v => v.id);
                return { ...group, entities: group.entities.filter(ent => currentLocationGroupEntitiesIds.includes(ent.id)) }
            });

            return {
                ...acc,
                [val.locationId]: groupsWithFilteredEntities,
            };
        }, {});

        const ansrEntities = this.data.currentLocation.ae;
        const ansrGroups = this.entityService.ansrEntityGroupToEntitySelectorObject([...ansrEntities], [], true);
        const ansrGroupIds: Map<number, boolean> = ansrGroups.reduce((acc, curr) => acc.set(curr.groupId, true), new Map<number, boolean>());

        this.ansrEntitiesByLocation = this.locationList.reduce((acc, val) => {
            const entityGroups = [...this.entityService.ansrEntityGroupToEntitySelectorObject([...val.ae], [], true)];
            const filteredGroups = entityGroups.filter(v => ansrGroupIds.get(v.groupId));

            return {
                ...acc,
                [val.locationId]: filteredGroups,
            };
        }, {});
    }

    public close() {
        this.dialogRef.close({ success: false });
    }

    public save() {
        const result = this.selectedItems.map(item => {
            item.locationName = this.locationList.find(v => v.locationId === item.lid).name;
            const request = this.viewDataService.getOtherLocationHydrograph(this.data.customerId, item);

            return { ...item, request };
        });


        this.dialogRef.close({ success: true, result });
    }
}
