import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { PercentFullReport, PercentFullReportArgs, PercentFullValues } from 'app/shared/models/percentage-full-report';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { COMPOSITE, PIPE, ROUND, UnitOfMeasureType } from 'app/shared/constant';

import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { PercentFullReportService } from '../percent-full-report.service';
import * as moment from 'moment';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { FilterSettings, WidgetFilterData } from 'app/shared/models/widget-filter-data';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { CustomerService } from 'app/shared/services/customer.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-percent-full-overview',
    templateUrl: './percent-full-overview.component.html',
    providers: [OrderByPipe],
})

/**
 * Percent full overview report
 */
export class PercentFullOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Array<Subscription>();
    public customerID: number;
    public locationGroupID: number;
    public percentFullLoading: boolean;
    public percentFullOverviewData = new Array<Object>();
    public pagedItems = new Array<Object>();
    public percentFullDisplayedColumns = ['locationName', 'average', 'maximum'];
    public percentFullDataSource: FilterDataSource | null;
    @ViewChild(MatPaginator) public percentFullPaginator: MatPaginator;
    @ViewChild(MatSort) public percentFullSort: MatSort;
    public percentFullDataChange: BehaviorSubject<Object[]> = new BehaviorSubject<Object[]>([]);
    public get percentFullData(): Object[] {
        return this.percentFullDataChange.value;
    }
    public totalPaginationLength: number;
    private installationTypeHeader: string;
    private locationNameText: string;
    private inches: string;
    private inchesHeight: string;
    private inchesWidth: string;

    /**
     * Variable which represents the location name
     */
    public locationName: string;

    /**
     * Variable which represents the start date
     */
    public startDate: Date;

    /**
     * Variable which represents the end date
     */
    public endDate: Date;

    /**
     * Represents the yesterday's date
     */
    public yesterDayDate: Date;

    /**
     * Variable which represents the state of filters
     */
    public displayFilters: boolean;

    /**
     * Variable which represents the location id for particular location
     */
    private locationID: number;

    /**
     * Variable which represents the percent full report data
     */
    public percentFullReportData: PercentFullReport;

    /**
     * Variable which used for pagination of the percent full report data
     */
    public pageData = new Array<PercentFullValues>();

    /**
     * Variable which used for showing percent full report data
     */
    public showPercentageFullReport = false;

    /**
     * Variable used for date format as per customer selected date format
     */
    public dateFormat: string;

    /**
     * Declares the percent full overview filter options
     */
    public percentFullFilterSettings: FilterSettings;

    /**
     * Represents default starting index for pagination
     */
    private startIndex = 0;

    /**
     * Represents default pagination size
     */
    private pageSize = 10;

    /**
     * Below variable will be used for sorted percent full overview data
     */
    private tempData = <PercentFullReport>{ percentFullValues: [] };

    /**
     * Represents the first call upon component initialization
     */
    public isCustomerMessage = true;

    /**
     * Represents the state of active and inactive locations
     * True will represent the both (active and inactive) locations
     * False will represent only active locations
     */
    public includeInactiveLocations: boolean;

    private isMetric: boolean;
    public trackByIndex = TrackBy.byIndex;
    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private cdr: ChangeDetectorRef,
        private percentFullReportService: PercentFullReportService,
        private activatedRoute: ActivatedRoute,
        private dateUtilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private translateService: TranslateService,
        private customerService: CustomerService
    ) {}

    public ngOnInit() {
        this.yesterDayDate = new Date();
        this.yesterDayDate.setDate(this.yesterDayDate.getDate() - 1);

        if (this.customerID > 0) {
            this.generateReportWithDefaultParams();
        }

        // initializes percent full overview filter options
        this.setPercentFullOverviewFilterSettings();

        const customerIDSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // get updated customer id
            this.customerID = Number(params.get(customerQueryParam));
            this.locationGroupID = Number(params.get(customerLocationGroupQueryParam));

            // get updated locations
            this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

            // set default page size 10
            if (this.percentFullPaginator) {
                this.percentFullPaginator.pageSize = 10;
                this.percentFullPaginator.pageIndex = 0;
            }

            // hide percent full overview filter on customer and location group change
            this.displayFilters = false;

            this.customerService.getCustomerById(this.customerID).subscribe(() => {
                this.applyTranslations();
                this.generateReportWithDefaultParams();
            });

            // subscribe to changes in dateformat
            const subscription = this.dateUtilService.dateFormat.subscribe(() => {
                // Getting customer dateformat from dateUtil Service
                this.dateFormat = this.dateUtilService.getFormat();
            });
            this.subscriptions.push(subscription);
        });
        this.subscriptions.push(customerIDSubscription);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
    }

    /**
     * Show Hide Filter
     */
    public showReportFilter() {
        this.displayFilters = !this.displayFilters;
    }

    /**
     * Checks on Change of Filter Data;
     * @param filtersData
     */
    public notifyReport(filtersData: WidgetFilterData) {
        let startDate = null;
        let endDate = null;
        if (filtersData.startDate) {
            startDate = [
                filtersData.startDate.getMonth() + 1,
                filtersData.startDate.getDate(),
                filtersData.startDate.getFullYear(),
            ].join('/');
        }
        if (filtersData.endDate) {
            endDate = [
                filtersData.endDate.getMonth() + 1,
                filtersData.endDate.getDate(),
                filtersData.endDate.getFullYear(),
            ].join('/');
        }
        this.endDate = endDate;
        this.startDate = startDate;
        let locationIDs: Array<number>;
        locationIDs = filtersData.locationIDs;
        if (!locationIDs) {
            locationIDs = [];
        }
        const args = <PercentFullReportArgs>{
            CustomerId: filtersData.customerID,
            LocationGroupId: this.locationGroupID,
            LocationIds: locationIDs,
            EntityIds: filtersData.entityIDs,
            Start: startDate,
            End: endDate,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        if (args.LocationIds) {
            this.isCustomerMessage = args.LocationIds.length === 0;
        }

        this.generatePercentFullReport(args);
    }

    /**
     * getInstallationType
     * @param percentFullValue
     */
    public getInstallationType(percentFullValue) {
        switch (percentFullValue.installationType) {
            case COMPOSITE:
                return percentFullValue.installationType;

            case PIPE:
                if (percentFullValue.installationShape === ROUND) {
                    return (
                        percentFullValue.installationType +
                        ': ' +
                        percentFullValue.installationShape +
                        ' (' +
                        (this.isMetric ? (percentFullValue.height * 25.4).toFixed(1) : percentFullValue.height.toFixed(2)) +
                        ' ' +
                        this.inches +
                        ')'
                    );
                } else {
                    return (
                        percentFullValue.installationType +
                        ': ' +
                        percentFullValue.installationShape +
                        ' (' +
                        (this.isMetric ? (percentFullValue.height * 25.4).toFixed(1) : percentFullValue.height.toFixed(2)) +
                        ' ' +
                        this.inchesHeight +
                        ' x ' +
                        (this.isMetric ? (percentFullValue.width * 25.4).toFixed(1) : percentFullValue.width.toFixed(2)) +
                        ' ' +
                        this.inchesWidth +
                        ')'
                    );
                }

            default:
                return percentFullValue.installationType + ': ' + percentFullValue.installationShape;
        }
    }

    private applyTranslations() {
        const { customer } = this.customerService;
        this.isMetric = customer.unitsType === UnitOfMeasureType.METRIC;
        const translateKeys: Array<string> = [
            'COMMON.LOCATION_NAME',
            'REPORT.PERCENT_FULL.INSTALLATION_TYPE',
            'COMMON.IN',
            'COMMON.MM',
            'REPORT.PERCENT_FULL.MM_HEIGHT',
            'REPORT.PERCENT_FULL.MM_WIDTH',
            'REPORT.PERCENT_FULL.IN_HEIGHT',
            'REPORT.PERCENT_FULL.IN_WIDTH',
        ];

        this.translateService.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.locationNameText = translateValues['COMMON.LOCATION_NAME'];
            this.installationTypeHeader = translateValues['REPORT.PERCENT_FULL.INSTALLATION_TYPE'];

            if (this.isMetric) {
                this.inches = translateValues['COMMON.MM'];
                this.inchesHeight = translateValues['REPORT.PERCENT_FULL.MM_HEIGHT'];
                this.inchesWidth = translateValues['REPORT.PERCENT_FULL.MM_WIDTH'];
            } else {
                this.inches = translateValues['COMMON.IN'];
                this.inchesHeight = translateValues['REPORT.PERCENT_FULL.IN_HEIGHT'];
                this.inchesWidth = translateValues['REPORT.PERCENT_FULL.IN_WIDTH'];
            }
        });
    }

    /**
     * Generate percent full data for defult customer and location
     */
    private generateReportWithDefaultParams() {
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate() - 1);
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);

        const startDay = this.startDate.getDate();
        const startMonth = this.startDate.getMonth();
        const startYear = this.startDate.getFullYear();

        const endDay = this.endDate.getDate();
        const endMonth = this.endDate.getMonth();
        const endYear = this.endDate.getFullYear();
        if (!this.locationID) {
            this.locationID = 0;
        }
        const args = <PercentFullReportArgs>{
            CustomerId: this.customerID,
            LocationGroupId: this.locationGroupID,
            LocationIds: [this.locationID],
            Start: [startMonth + 1, startDay, startYear].join('-'),
            End: [endMonth + 1, endDay, endYear].join('-'),
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        this.generatePercentFullReport(args);
    }

    /**
     * Generate percent full data for current customer and location
     */
    private generatePercentFullReport(args: PercentFullReportArgs) {
        this.percentFullLoading = true;
        this.showPercentageFullReport = false;
        this.percentFullReportData = null;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        const percentFullSubscription = this.percentFullReportService.getPercentFullReportData(args).subscribe(
            (response) => {
                if (response && response.percentFullValues && response.percentFullValues.length > 0) {
                    this.percentFullReportData = response;

                    // store percent full data on temporary data for sorting
                    this.tempData.percentFullValues = this.percentFullReportData.percentFullValues;
                    this.tempData.percentFullValues.sort(this.uiUtilsService.sortLocations);
                    this.pageData = this.percentFullReportData.percentFullValues.slice(0, 10);
                    this.showPercentageFullReport = true;
                }
                this.percentFullLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.percentFullLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(percentFullSubscription);
    }

    /**
     * pagination Change Action
     * @param event
     */
    public onPaginateChange(event) {
        this.domOperationUtilsService.scrollToTop('html');
        if (this.tempData && this.tempData.percentFullValues) {
            const datatable = this.tempData.percentFullValues.slice();
            // Grab the page's slice of data.
            this.startIndex = event.pageIndex * event.pageSize;
            this.pageSize = event.pageSize;
            this.pageData = datatable.splice(this.startIndex, this.pageSize);
        }
    }

    /**
     * Method which sets percent full overview filter settings options
     */
    private setPercentFullOverviewFilterSettings() {
        this.percentFullFilterSettings = {
            displayDateRanges: true,
            skipInitialCall: true,
            dateSpanPastWeek: true,
            displayLocations: true,
            displayInactiveLocations: true,
            singleLocationSelect: true,
        };
    }

    /**
     * Below function would sort the percent full overview data
     * @param sort represents material sort event
     */
    public sortPercentFullData(sort: Sort) {
        // exit immediately
        if (!this.percentFullReportData || !this.percentFullReportData.percentFullValues) {
            return;
        }

        const data = this.percentFullReportData.percentFullValues.slice();
        if (!sort.active || sort.direction === '') {
            this.tempData.percentFullValues = data;
            this.pageData = data.slice(this.startIndex, this.startIndex + this.pageSize);
            return;
        }

        // sort percent full overview data based on location field
        const sortedData = data.sort((currentItem: PercentFullValues, nextItem: PercentFullValues) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'Location':
                    return this.compare(
                        currentItem.locationName.toLowerCase(),
                        nextItem.locationName.toLowerCase(),
                        isAsc,
                    );
                default:
                    return 0;
            }
        });

        this.tempData.percentFullValues = sortedData;

        this.pageData = sortedData.slice(this.startIndex, this.startIndex + this.pageSize);
    }

    /**
     * Compare the default elements
     * @param current represents first element of array
     * @param next represents next element of array
     * @param isAsc represents ascending order
     */
    private compare(current: string, next: string, isAsc: boolean) {
        return (current < next ? -1 : 1) * (isAsc ? 1 : -1);
    }

    public downloadCSV() {
        const csvData = new Array<Object>();
        const csvHeaders = new Array<string>();
        const dateFormat = `${String(this.dateUtilService.getFormat()).toUpperCase()}`;
        csvHeaders.push(this.locationNameText);
        csvHeaders.push(this.installationTypeHeader);

        if (
            this.percentFullReportData &&
            this.percentFullReportData.percentFullValues &&
            this.percentFullReportData.percentFullValues.length > 0
        ) {
            this.percentFullReportData.percentFullValues.forEach((arrayItem, arrayItemIndex) => {
                const newItem = new Array<string>();

                newItem.push(arrayItem.locationName);
                newItem.push(this.getInstallationType(arrayItem));

                if (arrayItem.spans && arrayItem.spans.length > 0) {
                    arrayItem.spans.forEach((value, index) => {
                        if (index % 2 === 0) {
                            newItem.push(value.average !== 0 ? value.average.toString() : '-');
                            newItem.push(value.maximum !== 0 ? value.maximum.toString() : '-');
                        }

                        if (arrayItemIndex === 0) {
                            if (index === 0) {
                                csvHeaders.push(`${value.daysSpan.split('-')[1]} Average`);
                                csvHeaders.push(`${value.daysSpan.split('-')[1]} Maximum`);
                            } else if (index % 2 === 0 && index > 0) {
                                csvHeaders.push(`${this.percentFullReportData.daysSpan} Average`);
                                csvHeaders.push(`${this.percentFullReportData.daysSpan} Maximum`);
                            }
                        }
                    });
                }

                csvData.push(newItem);
            });

            const options = {
                showLabels: true,
                headers: csvHeaders,
                title: `Percent full ${moment(this.startDate).format(dateFormat)}`,
                showTitle: true,
            };

            const result = new AngularCsv(csvData, 'Percent full report', options);
        }
    }
}
