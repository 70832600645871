<div class="customDialogLayer">
    <div class="customDialog">
        <div class="customDialogPosition">
            <div id="resonForEditId">
                <div class="popupHeaderWithButton" mat-dialog-title>
                    <div class="auto-margin pad-l-15">{{ 'DOWNLOAD_CONFIRMATIONS.DOWNLOAD_CONFIRMATIONS_TITLE' | translate }}</div>
                    <div class="app-flex-filler"></div>
                </div>
                <div mat-dialog-content class="eachTabContent">
                    <div class="date-picker-container">
                        <app-date-range-picker [(startDate)]="startDate" [(endDate)]="endDate"></app-date-range-picker>
                    </div>
                </div>
                <div class="averaging-section-wrapper">
                        <mat-form-field class="full-width required">
                            <mat-select
                                [(ngModel)]="reportType" 
                                placeholder="{{ 'DOWNLOAD_CONFIRMATIONS.REPORT_TYPES' | translate }}"
                                required
                            >
                                <mat-option *ngFor="let series of confirmationReportTypes; trackBy: trackByValue" [value]="series.value">
                                    {{ series.text }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="auto-margin align-left pad-t-20">
                        <mat-slide-toggle *ngIf ="reportType === 0" class="pad-b-10 activate-user-hint" [(ngModel)]="includeMeterValues" labelPosition="before">
                            {{ 'DOWNLOAD_CONFIRMATIONS.INCLUDE_MONITOR_VALUES' | translate }}:
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="includeIgnored" labelPosition="before">
                            {{ 'DOWNLOAD_CONFIRMATIONS.INCLUDE_IGNORED' | translate }}:
                        </mat-slide-toggle>
                        </div>
                </div>
                <div mat-dialog-actions>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin">
                        <button mat-dialog-close mat-button>{{ 'COMMON.CLOSE' | translate }}</button>
                    </div>
                    <div class="auto-margin">
                        <button mat-button (click)="download()">{{ 'COMMON.DOWNLOAD_BTN' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
