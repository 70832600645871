<mat-form-field [ngClass]="{ 'full-width': true, 'invalid': !isValid}">
    <div class="mat-focused {{dclass}}">
        <input
            matInput
            [attr.data-identifier]="surrogateIdentifier"
            [(ngModel)]="initPlaceholderText"
            autocomplete="off"
            tabindex="-1"
            [formControl]="searchTermCtrl"
            [placeholder]="placeholder"
            (focus)="onInputFocusIn($event)"
            (focusout)="onInputFocusOut($event)"
        />
    </div>
</mat-form-field>
<mat-selection-list
    id="entityLimit"
    class="multi-select-list"
    [attr.data-identifier]="surrogateIdentifier"
    [disabled]="disabled"
    *ngIf="isResultsVisible"
    (focus)="onSelectionListFocused($event)"
    (focusout)="onSelectionListNotFocused($event)"
>
    <mat-list-option
        *ngIf="isShowAll"
        value="select-all"
        [attr.data-identifier]="surrogateIdentifier"
        [attr.data-selectall]="true"
        (click)="onSelectionChange($event)"
    >
        <span *ngIf="!isAllSelected; else deselect">{{ 'COMMON.SELECT_All_OPTION' | translate }}</span>
        <ng-template #deselect>
            <span>{{ 'COMMON.DESELECT_OPTION' | translate }}</span>
        </ng-template>
    </mat-list-option>
    <mat-divider *ngIf="isShowAll"></mat-divider>
    <mat-list-option
        *ngFor="let item of filteredItems | async; trackBy: trackById"
        [attr.data-item]="item.id"
        [attr.data-identifier]="surrogateIdentifier"
        (click)="onSelectionChange($event)"
        [disabled]="isOptionDisabled(item)"
        [selected]="item.isChecked"
        [attr.data-id]="item.id"
        [value]="item.id"
        >{{ item.name }} {{ item.serie ? '- ' + item.serie : '' }}</mat-list-option
    >
</mat-selection-list>
