<div id="locationEditorDialog" [hidden]="hiddenOnActivate">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'COMMON.ADD_LOCATION_TEXT' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <form #addLocationForm="ngForm">
        <div mat-dialog-content>
            <div class="custom-error">
                <mat-form-field class="full-width top">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.LOCATION_NAME' | translate }}"
                        [(ngModel)]="addLocationObject.locationName"
                        name="LocationName"
                        [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                        required
                        maxlength="63"
                        #LocationName="ngModel"
                        (input)="validateDuplicateLocationName($event.target.value)"
                    />
                </mat-form-field>
                <div class="custom-error-message">
                    <span [hidden]="isValidLocationName"
                        >{{ 'COMMON.LOCATION_NAME' | translate }} {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                    >
                    <span *ngIf="LocationName.hasError('pattern')">{{
                        'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate
                    }}</span>
                </div>
            </div>
            <mat-form-field class="full-width">
                <mat-select
                    placeholder="{{ 'COMMON.MONITOR_SERIES_PLACEHOLDER' | translate }}"
                    name="MonitorSeries"
                    [(ngModel)]="addLocationObject.series"
                    class="full-width"
                    (ngModelChange)="onChangeMonitorSeries($event)"
                    required
                >
                    <mat-option
                        *ngFor="let serics of monitorSeriesUI | orderBy: 'text'; trackBy: trackByMonitorSeriesId"
                        [value]="serics.value"
                        [disabled]="serics.value === 'TRITON+'"
                        >{{ serics.text }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input
                    matInput
                    placeholder="{{ 'COMMON.SERIAL_NUMBER' | translate }}"
                    name="SerialNumber"
                    [(ngModel)]="addLocationObject.serialNumber"
                    (change)="inputValidation('serialNumber', addLocationObject.serialNumber)"
                    required
                    maxlength="50"
                />
            </mat-form-field>
            <div class="pad-b-20">
                <mat-radio-group [(ngModel)]="addLocationObject.isActive" name="IsActiveLocation">
                    {{ 'COMMON.IS_ACTIVE_LABEL' | translate }}
                    <mat-radio-button *ngFor="let isActive of isActives; trackBy: trackByValue" [value]="isActive.value">{{
                        isActive.text
                    }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-form-field class="full-width">
                <mat-select
                    placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.INSTALLATION_TYPE' | translate }}"
                    name="InstallationType"
                    [(ngModel)]="installationType"
                    (ngModelChange)="onChangeInstallationType($event)"
                    class="full-width"
                    [disabled]="!addLocationObject.series"
                    required
                >
                    <mat-option *ngFor="let installationType of installationTypeUI; trackBy: trackByIndex" [value]="installationType">{{
                        installationType.text
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="custom-33p" *ngIf="selectedInstallationType == 'Weir'">
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': RangeInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ flumeRangePlaceHolder }}"
                            id="RangeInput"
                            #RangeInput="ngModel"
                            name="Range"
                            [(ngModel)]="weir.range"
                            maxlength="100"
                            required
                            [pattern]="installationValuePattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="RangeInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': CapacityInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER' | translate }}"
                            id="CapacityInput"
                            #CapacityInput="ngModel"
                            name="Capacity"
                            [(ngModel)]="weir.capacity"
                            maxlength="100"
                            required
                            [pattern]="installationValuePattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="CapacityInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': HeightInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ heightPlaceHolder }}"
                            id="HeightInput"
                            #HeightInput="ngModel"
                            [pattern]="installationValuePattern"
                            name="Height"
                            [(ngModel)]="weir.height"
                            maxlength="100"
                            required
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="HeightInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>
            <div class="custom-33p" *ngIf="selectedInstallationType == 'Weir'">
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': CoefficientInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CONSTANT' | translate }}"
                            id="CoefficientInput"
                            #CoefficientInput="ngModel"
                            [pattern]="installationValuePattern"
                            name="Coefficient"
                            [(ngModel)]="weir.coefficient"
                            maxlength="100"
                            required
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="CoefficientInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': LengthInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ lengthPlaceHolder }}"
                            id="LengthInput"
                            #LengthInput="ngModel"
                            [pattern]="installationValuePattern"
                            name="Length "
                            [(ngModel)]="weir.length"
                            maxlength="100"
                            required
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="LengthInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': BreadthInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ breadthPlaceHolder }}"
                            id="BreadthInput"
                            #BreadthInput="ngModel"
                            [pattern]="installationValuePattern"
                            name="Breadth"
                            [(ngModel)]="weir.breadth"
                            maxlength="100"
                            required
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="BreadthInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>
            <div
                class="custom-50p"
                *ngIf="
                    selectedInstallationType == 'Pipe' &&
                    addLocationObject.series?.toLowerCase().indexOf('rainalert') < 0
                "
            >
                <div class="each-Item custom-error">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ pipeHeightPlaceHolder }}"
                            id="PipeHeightInput"
                            #PipeHeightInput="ngModel"
                            name="PipeHeight"
                            [(ngModel)]="addTritonLocationService.pipe.height"
                            (keyup)="setCircularWidth()"
                            maxlength="100"
                            required
                            [pattern]="installationValuePattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="PipeHeightInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <div class="each-Item custom-error">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ pipeWidthPlaceHolder }}"
                            id="PipeWidthInput"
                            #PipeWidthInput="ngModel"
                            name="PipeWidth"
                            [(ngModel)]="addTritonLocationService.pipe.width"
                            (keyup)="setCircularHeight()"
                            maxlength="100"
                            required
                            [pattern]="installationValuePattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="PipeWidthInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>
            <div class="custom-33p" *ngIf="selectedInstallationType == 'Flume'">
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': FlumeRangeInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ flumeRangePlaceHolder }}"
                            id="FlumeRangeInput"
                            #FlumeRangeInput="ngModel"
                            name="FlumeRange"
                            [(ngModel)]="flume.range"
                            maxlength="100"
                            required
                            [pattern]="installationValuePattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="FlumeRangeInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': FlumeCapacityInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER' | translate }}"
                            id="FlumeCapacityInput"
                            #FlumeCapacityInput="ngModel"
                            name="FlumeCapacity"
                            [(ngModel)]="flume.capacity"
                            maxlength="100"
                            required
                            [pattern]="installationValuePattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="FlumeCapacityInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
                <div class="each-Item custom-error" [ngClass]="{ 'pad-b-15': FlumeWidthInput?.errors?.pattern }">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ widthPlaceHolder }}"
                            id="FlumeWidthInput"
                            #FlumeWidthInput="ngModel"
                            name="FlumeWidth"
                            [(ngModel)]="flume.width"
                            maxlength="100"
                            [required]="isMandatory"
                            [pattern]="installationValuePattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="FlumeWidthInput?.errors?.pattern">
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                </div>
            </div>
            <div *ngIf="selectedInstallationType == 'Channel'">
                <div class="custom-error">
                    <mat-form-field class="pad-t-6 full-width">
                        <input
                            matInput
                            placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_ENTRIES_LABEL' | translate }}"
                            id="ChannelNoEntries"
                            #ChannelNoEntries="ngModel"
                            name="numberOfEntries"
                            [(ngModel)]="numberOfEntries"
                            maxlength="3"
                            required
                            (blur)="addNewFields()"
                            [pattern]="channelEntriesPattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="ChannelNoEntries?.errors?.pattern">
                        {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_ENTRY_ERROR' | translate }}
                    </div>
                </div>

                <div
                    *ngIf="
                        selectedInstallationType == 'Channel' &&
                        numberOfTimes &&
                        numberOfTimes.length >= 2 &&
                        numberOfTimes.length <= 257 &&
                        !ChannelNoEntries?.errors?.pattern
                    "
                >
                    <div class="custom-50p" *ngFor="let num of numberOfTimes; let i = index; trackBy: trackByIndex" [attr.data-index]="i">
                        <div class="each-Item custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{ depthPlaceHolder }}"
                                    id="Depth"
                                    #Depth="ngModel"
                                    maxlength="10"
                                    required
                                    [pattern]="depthQuantityPattern"
                                    [attr.id]="'Depth' + depth[i]"
                                    [(ngModel)]="depth[i]"
                                    name="Depth{{ i }}"
                                />
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="Depth?.errors?.pattern">
                                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.SIX_PRECISION_ERROR' | translate }}
                            </div>
                        </div>
                        <div class="each-Item custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY' | translate }}"
                                    id="Quantity"
                                    #Quantity="ngModel"
                                    maxlength="10"
                                    required
                                    [pattern]="depthQuantityPattern"
                                    [attr.id]="'Quantity' + quantity[i]"
                                    [(ngModel)]="quantity[i]"
                                    name="Quantity{{ i }}"
                                />
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="Quantity?.errors?.pattern">
                                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.SIX_PRECISION_ERROR' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-error">
                <mat-form-field class="full-width">
                    <input
                        matTooltip="{{ 'HOME.MAP.IP_ADDRESS_TOOLTIP' | translate }}"
                        matInput
                        #IpAddress="ngModel"
                        placeholder="{{ 'COMMON.IP_ADDRESS_PLACEHOLDER' | translate }}"
                        id="IpAddress"
                        name="IpAddress"
                        [(ngModel)]="addLocationObject.IPAddress"
                        required
                        maxlength="100"
                        [pattern]="ipaddressPattern"
                    />
                </mat-form-field>
                <div class="custom-error-message" *ngIf="IpAddress?.errors?.pattern">
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.IP_ADDRESS_ERROR' | translate }}
                </div>
            </div>
            <div class="custom-error">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder=" {{ 'COMMON.LOCATION_DESCRIPTION' | translate }}"
                        id="addLocationDescriptionInput"
                        #addLocationDescriptionInput="ngModel"
                        [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                        name="Description"
                        [(ngModel)]="addLocationObject.description"
                        maxlength="256"
                        (change)="locationDescriptionValidation()"
                    />
                </mat-form-field>
                <div class="custom-error-message" *ngIf="addLocationDescriptionInput?.errors?.pattern">
                    {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}
                </div>
            </div>
            <div class="custom-error" *ngIf="selectedInstallationType !== 'Rain'">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        id="addLocationManholeDepthInput"
                        placeholder="{{ manholeDepthPlaceHolder }}"
                        name="manholedepth"
                        #addLocationManholeDepthInput="ngModel"
                        [(ngModel)]="addLocationObject.manholedepth"
                        (change)="inputValidation('manholedepth', addLocationObject.manholedepth)"
                        maxlength="50"
                        [pattern]="installationValuePattern"
                    />
                </mat-form-field>
                <div class="custom-error-message" *ngIf="addLocationManholeDepthInput?.errors?.pattern">
                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                </div>
            </div>
            <div class="custom-error">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.MANHOLE_ADDRESS_TEXT' | translate }}"
                        id="addLocationManholeAddressInput"
                        #addLocationManholeAddressInput="ngModel"
                        [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                        name="ManholeAddress"
                        [(ngModel)]="addLocationObject.manholeAddress"
                        (change)="manholeAddressValidation()"
                    />
                </mat-form-field>
                <div class="custom-error-message" *ngIf="addLocationManholeAddressInput?.errors?.pattern">
                    {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}
                </div>
            </div>
            <div class="custom-50p custom-error">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matTooltip="{{ 'COMMON.LATITUDE_TOOLTIP' | translate }}"
                            [matTooltipShowDelay]="1000"
                            matInput
                            id="addLocationLatitudeInput"
                            placeholder="{{ 'COMMON.LATITUDE_LABEL' | translate }}"
                            name="Latitude"
                            [(ngModel)]="addLocationObject.latitude"
                            #addLocationLatitudeInput="ngModel"
                            [pattern]="latitudePattern"
                        />
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matTooltip="{{ 'COMMON.LONGITUDE_TOOLTIP' | translate }}"
                            [matTooltipShowDelay]="1000"
                            matInput
                            id="addLocationLongitudeInput"
                            placeholder="{{ 'COMMON.LONGITUDE_LABEL' | translate }}"
                            name="Longitude"
                            [(ngModel)]="addLocationObject.longitude"
                            #addLocationLongitudeInput="ngModel"
                            [pattern]="longitudePattern"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="custom-error-message"
                    *ngIf="addLocationLatitudeInput?.errors?.pattern || addLocationLongitudeInput?.errors?.pattern"
                >
                    {{ 'COMMON.LONGITUDE_ERROR' | translate }}
                </div>
            </div>
            <div class="custom-error">
                <div
                    *ngIf="
                        addLocationObject?.series && addLocationObject?.series?.toLowerCase().indexOf('rainalert') < 0
                    "
                    class="custom-50p"
                >
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="{{ 'COMMON.RAIN_GAUAGE' | translate }}"
                            aria-label="Assigned Rain Gauge"
                            maxlength="50"
                            [matAutocomplete]="auto"
                            [(ngModel)]="chosenRainGauge"
                            [formControl]="assignedRainGaugeCtrl"
                        />

                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                *ngFor="let rainGauge of filteredassignedRainGauge | async; trackBy: trackByLocationId"
                                [value]="rainGauge.locationName"
                            >
                                <span>{{ rainGauge.locationName }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="custom-error-message" *ngIf="assignedRainGaugeCtrl.invalid">
                    {{ 'HOME.MAP.RAIN_GAUGE_REQUIRED_VALIDATION' | translate }}
                </div>
            </div>
        </div>
    </form>

    <div mat-dialog-actions>
        <app-loader [isLoading]="addLocationLoadingState"></app-loader>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitAddLocation(false)">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button
                mat-button
                [disabled]="
                    !addLocationForm.valid ||
                    addLocationForm.pristine ||
                    addLocationObject.series == 'TRITON+' ||
                    !isValidLocationName
                "
                (click)="activateLocation()"
            >
                {{ 'COMMON.ACTIVATE_BUTTON' | translate }}
            </button>
        </div>
        <div class="auto-margin">
            <button
                mat-button
                [disabled]="!addLocationForm.valid || addLocationForm.pristine || !isValidLocationName"
                (click)="addLocation()"
            >
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
