<div id="SlicerDashboardId">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin no-text-case">{{ 'SLICER_PAGE.SLIICER' | translate }}&reg; {{ 'SLICER_PAGE.DASHBOARD' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div *ngIf="isBetaFlag" class="betaLabel">{{ 'COMMON.BETA_LABEL' | translate }}</div>
        </mat-card-header>
        <mat-divider></mat-divider>
        <div *ngIf="isLoadingState; else caseStudySummaryTable">
            <app-loader [isLoading]="isLoadingState"></app-loader>
        </div>
        <ng-template #caseStudySummaryTable>
            <mat-card-content class="sliicer-study-dashboard-content">
                <ng-container *ngIf="!showStudyDashboard">
                    <div class="study-dashboard-new-page">
                        <div class="sliicer-no-studies">
                            <img
                                class="slicer_image"
                                [ngSrc]="'assets/images/slicer_empty_dashboard_image.png'"
                                alt="image"
                                height="216"
                                width="209"
                            >
                            <div class="page-content">
                                <h3>{{ 'SLICER_PAGE.EMPTY' | translate }}</h3>
                                <p class="lines">
                                    <b>{{ 'SLICER_PAGE.ADD_SLICER' | translate }}</b> <i class="material-icons">add</i
                                    ><b>{{ 'SLICER_PAGE.BUTTON' | translate }}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div [hidden]="!showStudyDashboard">
                    <div class="dashboard-search-container">
                        <!-- mat input form  -->
                        <mat-form-field class="full-width case-study-search-container">
                            <mat-icon matPrefix class="search-icon">search</mat-icon>
                            <input matInput placeholder="Search" class="full-width" [formControl]="caseStudySearchString" />
                            <mat-icon class="clickable" matTooltip="Clear" matSuffix (click)="clearSearch($event)">clear</mat-icon
                        >
                        </mat-form-field>

                        <mat-checkbox [(ngModel)]="listMyStudiesOnly" (ngModelChange)="onShowMyStudiesChecked()">
                            {{ 'SLICER_PAGE.LIST_MY_STUDIES' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- mat table for sliicer dashboard  -->
                    <div id="sliicerpagetable">
                        <mat-table #table [dataSource]="caseStudyDataSource" matSort>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                    'SLIICER_TABLE.SLIICER_STUDY_NAME' | translate
                                }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Description">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                    'SLIICER_TABLE.SLIICER_DESCRIPTION' | translate
                                }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.description }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Author">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                    'SLIICER_TABLE.SLIICER_AUTHOR' | translate
                                }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.createdBy }}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="StudyLocations">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'SLIICER_TABLE.SLIICER_STUDYLOCATION' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">{{ element?.StudyLocations }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="StudyDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                    'SLIICER_TABLE.SLIICER_STUDYDATE' | translate
                                }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element?.StudyDate }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="daysInStudy">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'SLIICER_TABLE.SLIICER_DAYSINSTUDY' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <span *ngIf="element.daysInStudy != 0">{{ element.daysInStudy }}</span>
                                    <span *ngIf="element.daysInStudy == 0">-</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="modifiedDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'SLIICER_TABLE.SLIICER_MODIFIEDDAY' | translate }}</mat-header-cell
                                >
                                <mat-cell
                                    *matCellDef="let element"
                                    matTooltip="Study created on {{ element.createdOn | date: customerDateFormat }}"
                                >
                                    <span *ngIf="element.lastModified">{{
                                        element.lastModified | date: customerDateFormat
                                    }}</span>
                                    <span *ngIf="!element.lastModified">-</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="cloneStudy">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'SLIICER_TABLE.SLIICER_CLONESTUDY' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <button *ngIf="element.StudyDate !== '-' && element.StudyLocations !== '-'" mat-button (click)="cloneCaseStudy(element)">
                                        <mat-icon>file_copy</mat-icon>
                                    </button>
                                    <button *ngIf="element.StudyDate === '-' && element.StudyLocations === '-'" mat-button disabled>
                                        <mat-icon>file_copy</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteStudy">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'SLIICER_TABLE.SLIICER_DELETESTUDY' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <button
                                        [disabled]="element.locked"
                                        mat-button
                                        (click)="deleteCaseStudy(element)"
                                        *ngIf="element.editable"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="claimStudy" *ngIf="showClaimStudy">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'SLIICER_TABLE.SLIICER_CLAIMSTUDY' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <button
                                        mat-button
                                        *ngIf="!element.editable"
                                        (click)="claimStudy(element)"
                                    >
                                        <mat-icon>sync</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="caseStudyDescriptionColumns"></mat-header-row>
                            <mat-row
                                *matRowDef="let row; columns: caseStudyDescriptionColumns"
                                [ngClass]="
                                    !(row.editable || row.isNonAuthorReadOnly) ? 'disabled-row' : 'cursor-table-row'
                                "
                                (click)="setUpCaseStudyDetailRoute(row)"
                                [disabled]="!(row.editable || row.isNonAuthorReadOnly)"
                            >
                            </mat-row>
                        </mat-table>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions>
                <div>
                    <button
                        mat-fab
                        color="accent"
                        id="addNewCaseStudy"
                        matTooltip="{{ 'SLICER_PAGE.ADD_NEWSTUDY' | translate }}"
                        (click)="addNewSliicerStudy()"
                        matTooltipPosition="above"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div class="app-flex-filler"></div>
                <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
                    <mat-paginator
                        #caseStudySummaryPaginator
                        [length]="totalPaginationLength"
                        [pageIndex]="0"
                        [pageSize]="25"
                        [pageSizeOptions]="[10, 25, 100]"
                    >
                    </mat-paginator>
                </div>
            </mat-card-actions>
        </ng-template>
    </mat-card>
</div>
