import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { VaultUploadResult } from 'app/shared/models/vault';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-vault-upload-overwrite',
    templateUrl: './vault-upload-overwrite.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultUploadOverwriteComponent {
    @Input() public files: Array<VaultUploadResult>;

    @Output() public uploadeOverwriteComplete = new EventEmitter<Array<VaultUploadResult>>();

    public trackByIndex = TrackBy.byIndex;
    public fileSelected(event: MouseEvent): void {
        // workaround due to Angular Material select list bug:
        // https://github.com/angular/material2/pull/6901
        const fileId = (<HTMLDivElement>event.currentTarget).textContent;

        // find file in array and mark it as overwritable
        this.files.forEach((file: VaultUploadResult) => {
            if (file.fileName === fileId) {
                return (file.isOverwritable = !file.isOverwritable);
            }
        });

        this.uploadeOverwriteComplete.emit(this.files);
    }
}
