<div id="wetdryoverflowDialog">
    <div class="popupHeader" mat-dialog-title>
        <span> {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.WETDRY_OVERFLOW' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <span class="toggle"> {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.ENABLE_DISABLE' | translate }}</span>
        <mat-slide-toggle
            [checked]="wetDryToggle"
            [disabled]="!isWetDryAvailable"
            (change)="activeDeactiveWetDry($event)"
        ></mat-slide-toggle>

        <div class="information">
            {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.WHEN_THE' | translate
            }}<strong
                ><i> {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.SELECTED_ALARM_TYPE' | translate }}</i></strong
            >
            {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.ALARM_INFO' | translate
            }}<strong
                ><i>{{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.SELECTED_UNITS' | translate }}</i></strong
            >
            {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.UNITS_INFO' | translate
            }}<strong
                ><i> {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.ASSIGNED_RAIN_GAUGES' | translate }}</i></strong
            >
            {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.WITH_IN' | translate }}
            <strong
                ><i> {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.SELECTED_TIME' | translate }}</i></strong
            >
            {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.PRISM_INFO' | translate }}
            {{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.LINE' | translate }}
        </div>
        <br />
        <div class="eachSection">
            <div class="custom-33p-wdlocation">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.ALARM_TYPE' | translate }}"
                            name="alarmType"
                            [(ngModel)]="alarmTypeID"
                            (ngModelChange)="onCustomAlarmTypeChange($event)"
                            required
                        >
                            <mat-option *ngFor="let alarm of alarmType; trackBy: trackByValue" [value]="alarm.value">
                                {{ alarm.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    *ngIf="isMetricUnitType"
                    class="custom-error-wdoverflow inline-Block"
                    [ngClass]="{
                        'pad-b-25':
                            alarmRainUnitInput?.errors?.pattern ||
                            alarmRainUnitInput?.value < 0.254 ||
                            alarmRainUnitInput?.value > 381
                    }"
                >
                    <mat-form-field class="unitsfield">
                        <input
                            matInput
                            id="alarmRainUnitInput"
                            name="alarmRainUnit"
                            placeholder="{{ 'CUSTOMER_EDITOR.UNITS_TEXT' | translate }} (mm)"
                            [(ngModel)]="alarmRainUnitValue"
                            #alarmRainUnitInput="ngModel"
                            required
                            [pattern]="numericPattern3Decimal"
                            (change)="onCustomAlarmRainUnitChange(alarmRainUnitInput.value, 'metric')"
                        />
                    </mat-form-field>
                    <div
                        class="customer-error-message-wdoverflow danger"
                        *ngIf="
                            alarmRainUnitInput?.errors?.pattern ||
                            alarmRainUnitInput?.value < 0.254 ||
                            alarmRainUnitInput?.value > 381
                        "
                    >
                        {{ 'CUSTOMER_EDITOR.ALARM_RAIN_UNIT_ERR' | translate }}
                    </div>
                </div>
                <div
                    *ngIf="!isMetricUnitType"
                    class="each-Item custom-error-wdoverflow units-width"
                    [ngClass]="{
                        'pad-b-25':
                            alarmRainUnitInput?.errors?.pattern ||
                            alarmRainUnitInput?.value < 0.01 ||
                            alarmRainUnitInput?.value > 15
                    }"
                >
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            id="alarmRainUnitInput"
                            name="alarmRainUnit"
                            placeholder="{{ 'CUSTOMER_EDITOR.UNITS_TEXT' | translate }} (in)"
                            [(ngModel)]="alarmRainUnitValue"
                            #alarmRainUnitInput="ngModel"
                            required
                            [pattern]="numericPattern"
                            (change)="onCustomAlarmRainUnitChange(alarmRainUnitInput.value, 'non-metric')"
                        />
                    </mat-form-field>
                    <div
                        class="customer-error-message-wdoverflow danger"
                        *ngIf="
                            alarmRainUnitInput?.errors?.pattern ||
                            alarmRainUnitInput?.value < 0.01 ||
                            alarmRainUnitInput?.value > 15
                        "
                    >
                        {{ 'CUSTOMER_EDITOR.UNITS_VALIDATION' | translate }}
                    </div>
                </div>
                <div
                    class="each-Item custom-error-wdoverflow"
                    [ngClass]="{
                        'pad-b-15':
                            alarmPeriodInput?.errors?.pattern ||
                            alarmPeriodInput?.value < 1 ||
                            alarmPeriodInput?.value > 72
                    }"
                >
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            id="alarmPeriodInput"
                            name="alarmPeriod"
                            placeholder="{{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.TIME' | translate }} (Hours)"
                            [(ngModel)]="alarmPeriodValue"
                            #alarmPeriodInput="ngModel"
                            required
                            [pattern]="numeric1_72Pattern"
                            (change)="onCustomAlarmPeriodChange(alarmPeriodInput.value)"
                        />
                    </mat-form-field>
                    <div
                        class="customer-error-message-wdoverflow danger"
                        *ngIf="
                            alarmPeriodInput?.errors?.pattern ||
                            alarmPeriodInput?.value < 1 ||
                            alarmPeriodInput?.value > 72
                        "
                    >
                        {{ 'CUSTOMER_EDITOR.TIME_PATTERN_ERR' | translate }}
                    </div>
                </div>
                <div
                    class="each-Item custom-error-wdoverflow collection-width"
                    [ngClass]="{
                        'pad-b-15':
                            alarmCollectionInput?.errors?.pattern ||
                            alarmCollectionInput?.value < 1 ||
                            alarmCollectionInput?.value > 60
                    }"
                >
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            id="alarmCollectionInput"
                            name="alarmCollection"
                            placeholder="{{ 'CUSTOMER_EDITOR.MAX_COLLECTION_TIME' | translate }} (Minutes)"
                            [(ngModel)]="alarmCollectionValue"
                            #alarmCollectionInput="ngModel"
                            required
                            [pattern]="numeric1_60Pattern"
                            (change)="onCustomAlarmCollectionChange(alarmCollectionInput.value)"
                        />
                    </mat-form-field>
                    <div
                        class="customer-error-message-wdoverflow danger"
                        *ngIf="
                        alarmCollectionInput?.errors?.pattern ||
                        alarmCollectionInput?.value < 1 ||
                        alarmCollectionInput?.value > 60
                        "
                    >
                        {{ 'CUSTOMER_EDITOR.COLLECTION_PATTERN_ERR' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <span>{{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.ASSIGNED_RAIN_GAUGES' | translate }}</span>
        <br />
        <div class="auto-margin">
            <app-loader [isLoading]="isLoading"></app-loader>
            <mat-checkbox
                class="select"
                [checked]="selectAll"
                ngDefaultControl
                (change)="toggleSelectAll()"
                name="selectAll"
                ngDefaultControl
                >{{ 'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.SELECT' | translate }}</mat-checkbox
            >
            <mat-form-field class="search">
                <mat-icon matPrefix>search</mat-icon>
                <input
                    matInput
                    type="text"
                    [formControl]="customerSearch"
                    placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                />
            </mat-form-field>
        </div>
        <div class="wetdryoverflow">
            <div class="raingaugeList">
                <div class="eachItem" *ngFor="let rainUserPreferences of filteredAssignedRainGauge.rainGaugeList; trackBy: trackByLocationId">
                    <mat-checkbox
                        [checked]="rainUserPreferences.isChecked"
                        name="rainUserPreferences.isSelected"
                        (change)="changeSelecteState($event, rainUserPreferences)"
                        >{{ rainUserPreferences.locationName }}</mat-checkbox
                    >
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="cancel(false)">{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button [disabled]="isEditButtonDisabled" (click)="updateLocationInfo()">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
