import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { AdsPrismAddScheduleComponent } from 'app/pages/dashboards/schedule-collect-dashboard';
import { DataCollectionComponent } from 'app/pages/data-collection/data-collection.component';
import { DeviceTypeCode } from 'app/shared/enums/device-type-code';
import { LocationDetails } from 'app/shared/models/location-details';
import { CollectService } from 'app/shared/services/collect.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { LocationService } from 'app/shared/services/location.service';
import { MonitorDiagnosticsService } from 'app/shared/services/monitor-diagnostics.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { Subscription } from 'rxjs';
import { MonitorSeriesNames } from '../../location-card.constants';
import { LocationCardData } from '../../models/location-card.model';

@Component({
    selector: 'app-dialog-actions',
    templateUrl: './dialog-actions.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class DialogActionsComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() data: LocationCardData;
    @Output() openLocationDashboard = new EventEmitter();
    @Output() activateLocation = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() cancelClicked = new EventEmitter();
    @Output() collectLocationClicked = new EventEmitter();
    @Output() scheduleCollectClicked = new EventEmitter();
    @Output() exportLocationClicked = new EventEmitter();

    public isHomeMap: boolean;
    public showLocationDiagnostics = false;
    public deviceTypes: DeviceTypeCode[] = [];
    public isMonitorCanAddEdit: boolean;
    private scheduleCollectDashboardURL = '/pages/menuDashboard/schedule-collect/overview';
    private duration = 3000;
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private locationService: LocationService,
        private dateutilService: DateutilService,
        private dialog: MatDialog,
        private collectService: CollectService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
        private statusCodeService: StatusCodeService,
        private scheduleCollectionService: ScheduleCollectionService,
        private snackBarNotificationService: SnackBarNotificationService,
        private usersService: UsersService,
        private monitorDiagnosticsService: MonitorDiagnosticsService,
    ) {}

    ngOnInit() {
        const url = this.router.url;
        this.isHomeMap = url.indexOf('dashboard') > -1;

        this.setDiagnosticDetails();
        this.isMonitorCanAddEdit = this.usersService.isMonitorEditor.getValue();
    }

    public isUSGSseriesSelected() {
        if (!this.form) return null;

        return this.form.get('basicInfo').get('series').value === 'USGS';
    }

    public exportLocationXml() {
        this.locationService
            .locationsExport(this.data.customerId, this.data.locationDetails.locationID)
            .subscribe((result) => {
                if (result) {
                    this.snackBarNotification(
                        `"${result}" ${this.translateService.instant('COMMON.EXPORTED_SUCCESSFULLY_IN_VAULT')}`,
                        this.duration,
                        '',
                    );
                }
            });
    }

    public navigateToScheduleCollectDashboard() {
        const queryParamsItems = { c: this.data.customerId };
        const locationId = this.data.locationDetails.locationID;

        if (!locationId) {
            this.router.navigate([this.scheduleCollectDashboardURL], {
                queryParams: queryParamsItems,
            });

            return;
        }

        const scheduleDialogOptions = {
            disableClose: true,
            data: {
                customerId: this.data.customerId,
                locationId: locationId,
                isEditMode: false,
            },
            autoFocus: false
        };

        this.scheduleCollectionService.getScheduleByLocationId(this.data.customerId, locationId).subscribe(
            (response) => {
                if (response && response.scheduleId) {
                    scheduleDialogOptions.data['selectedScheduleId'] = response.scheduleId;
                    scheduleDialogOptions.data['isEditMode'] = true;
                }

                this.openScheduleDialog(scheduleDialogOptions);
            },
            () => this.openScheduleDialog(scheduleDialogOptions),
        );
    }

    public onCollectLocation() {
        this.locationService
            .getLocationDetails(this.data.customerId, this.data.locationDetails.locationID)
            .subscribe((result: LocationDetails) => {
                const lastCollectDate = this.calculateLastCollectDate(result);
                this.openDataCollectDialog(lastCollectDate);
            });
    }

    public isActivateButtonDisabled() {
        if (
            !this.form ||
            this.form.get('basicInfo').get('communicationType').value !== 4 ||
            !this.form.get('basicInfo').get('series').value
        ) {
            return true;
        }

        const ms = this.form.get('basicInfo').get('series').value;

        return (
            ms !== MonitorSeriesNames.triton && ms !== MonitorSeriesNames.rainAlert && ms !== MonitorSeriesNames.echo && ms?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && ms?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD
        );
    }

    private calculateLastCollectDate(locationDetails: LocationDetails) {
        const dateFormat = this.dateutilService.dateFormat.getValue().toUpperCase();

        if (locationDetails) {
            if (dateFormat === 'MM/DD/YYYY' || dateFormat === 'YYYY/MM/DD') {
                return new Date(locationDetails.lastCollectedDate);
            } else if (dateFormat === 'DD/MM/YYYY') {
                return new Date(
                    String(locationDetails.lastCollectedDate).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'),
                );
            }
        }
        return '';
    }

    private openScheduleDialog(options) {
        this.dialog.open(AdsPrismAddScheduleComponent, options);
    }

    private openDataCollectDialog(lastCollectDate: Date | string) {
        const dialogRef = this.dialog.open(DataCollectionComponent, {
            disableClose: true,
            data: lastCollectDate,
        });

        const closeSubs = dialogRef.afterClosed().subscribe((response) => {
            if (!response || !response.success || !response.startDate || !response.endDate) return;

            const start = this.formatCollectDates(response.startDate);
            const end = this.formatCollectDates(response.endDate);

            this.collectLocation(start, end);
        });

        this.subscriptions.add(closeSubs);
    }

    private formatCollectDates(date: Date) {
        const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        return isoDate.split('.')[0];
    }

    private collectLocation(start: string, end: string) {
        this.collectService
            .collectLocation(this.data.customerId, this.data.locationDetails.locationID, start, end)
            .subscribe(() => {
                this.snackBar.open(
                    this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.COLLECT_INIT_SUCCESS_MSG'),
                    this.translateService.instant('COMMON.DISMISS_TEXT'),
                    {
                        duration: 10000,
                    },
                );
            }, this.collectionError);
    }

    private collectionError(err: HttpErrorResponse) {
        if (err.status === 400) {
            this.snackBar.open(
                this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.INVALID_IP_COLLECTIONS'),
                this.translateService.instant('COMMON.DISMISS_TEXT'),
                {
                    panelClass: 'custom-error-snack-bar',
                },
            );

            return;
        }

        const subs = this.statusCodeService.statusCode409.subscribe((statusResponse: boolean) => {
            if (statusResponse) {
                this.snackBar.open(
                    this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.COLLECT_INIT_SUCCESS_MSG'),
                    this.translateService.instant('COMMON.DISMISS_TEXT'),
                    {
                        duration: 10000,
                        panelClass: 'custom-error-snack-bar',
                    },
                );
            }
        });

        this.subscriptions.add(subs);
    }

    private snackBarNotification(message: string, duration, notificationClass, dismiss = true) {
        this.snackBarNotificationService.raiseNotification(
            message,
            this.translateService.instant('COMMON.DISMISS_TEXT'),
            {
                panelClass: notificationClass,
            },
            dismiss
        );
    }

    private setDiagnosticDetails() {
        if (!this.isMonitorCanAddEdit || !this.data.locationDetails) return;

        const { series, locationID } = this.data.locationDetails;
        this.monitorDiagnosticsService
            .getDiagnosticsDetails(series as any, locationID, this.data.customerId)
            .subscribe(({ showDiagnostics, deviceTypes }) => {
                this.showLocationDiagnostics = showDiagnostics;
                this.deviceTypes = deviceTypes;
            });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
