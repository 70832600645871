<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'surface-combo-section-error': form.invalid }">
        <mat-expansion-panel-header class="mat-expasion-header-background">
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 0">
                {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 1">
                {{ 'ADD_EDIT.MP2' | translate }} {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount
                    }}{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="form-input-bottom-space">
                <div>
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_PHYSICAL')
                                )
                            "
                            id="surfaceComboPhysicalOffset"
                            formControlName="physicalOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('physicalOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
            </div>

            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_ELECTRONIC')
                                )
                            "
                            id="lastNameInput"
                            formControlName="ultrasonicElectronicOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('ultrasonicElectronicOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MODE' | translate }}"
                            formControlName="ultrasonicMode"
                            required
                        >
                            <mat-option *ngFor="let flow of modeList; trackBy: trackByValue" [value]="flow.value">{{ flow.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_BLANKING' | translate }}"
                            formControlName="ultrasonicBlanking"
                            required
                        >
                            <mat-option *ngFor="let option of blankingList; trackBy: trackByValue" [value]="option.value"
                                >{{ option.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="custom-50p pad-b-15">
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="ultrasonicDisableSurchargeDetection">
                        {{ 'ADD_EDIT.SURCHARGE_DETECTION' | translate }}</mat-slide-toggle
                    >
                </div>
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="ultrasonicStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}</mat-slide-toggle
                    >
                </div>
            </div>
            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="surfaceVelocityEnable"
                    (change)="
                        onToggleChange($event, [
                            'surfaceVelocityFrequency',
                            'surfaceVelocitySensitivity',
                            'surfaceVelocityDirection',
                            'surfaceComboSVmaxVelocity',
                            'surfaceVelocityMaximum',
                            'surfaceVelocityGain',
                            'surfaceVelocityDuration',
                            'surfaceVelocityScrubbing',
                            'surfaceVelocityStoreData'
                        ])
                    "
                >
                    {{ 'ADD_EDIT.SURFACE_VELOCITY' | translate }}</mat-slide-toggle
                >
            </div>

            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.TRANSMIT_FREQUENCY' | translate }}"
                            formControlName="surfaceVelocityFrequency"
                            required
                        >
                            <mat-option *ngFor="let option of sVTransmitFrequencyList; trackBy: trackByValue" [value]="option.value">
                                {{ option.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSITIVITY' | translate }}"
                            formControlName="surfaceVelocitySensitivity"
                            required
                        >
                            <mat-option *ngFor="let option of sensitivityList; trackBy: trackByValue" [value]="option.value"
                                >{{ option.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DIRECTION' | translate }}"
                            formControlName="surfaceVelocityDirection"
                            required
                        >
                            <mat-option *ngFor="let direction of svDirectionList; trackBy: trackByValue" [value]="direction.value">
                                {{ direction.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_VALUE_MAXIMUM' | translate }}"
                            id="surfaceComboSVMaxVelocityInput"
                            formControlName="surfaceVelocityMaximum"
                            class="full-width"
                        >
                            <mat-option *ngFor="let velocity of surfaceMaxVelocityValues; trackBy: trackByValue" [value]="velocity.value">{{
                                velocity.text
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <div class="gain-container">
                        <mat-form-field class="required">
                            <input
                                matInput
                                placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MONITORGAIN' | translate }}"
                                id="surfaceComboSvGain"
                                formControlName="surfaceVelocityGain"
                                required
                            />
                        </mat-form-field>
                    </div>
                    <div class="mat-error" *ngIf="form.get('surfaceVelocityGain').errors?.pattern">
                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.INVALID_INPUT_TXT' | translate }}
                    </div>
                </div>
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DURATION' | translate }}"
                            formControlName="surfaceVelocityDuration"
                            required
                        >
                            <mat-option *ngFor="let duration of sVDurationList; trackBy: trackByValue" [value]="duration.value">
                                {{ duration.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-checkbox formControlName="surfaceVelocityScrubbing">
                        {{ 'ADD_EDIT.INPUT_PLACEHOLDER_SCRUBBLING' | translate }}</mat-checkbox
                    >
                </div>
            </div>
            <div class="pad-b-15">
                <mat-slide-toggle class="custom-slide-toggle" formControlName="surfaceVelocityStoreData">
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}</mat-slide-toggle
                >
            </div>
            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="velocityEnable"
                    (change)="
                        onToggleChange($event, [
                            'velocityFrequency',
                            'velocitySensitivity',
                            'velocityDirection',
                            'surfaceComboPVmaxVelocity',
                            'velocityMaximum',
                            'velocityGain',
                            'velocityStoreData'
                        ])
                    "
                >
                    {{ 'ADD_EDIT.PEAK_VELOCITY' | translate }}
                </mat-slide-toggle>
            </div>

            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.TRANSMIT_FREQUENCY' | translate }}"
                            formControlName="velocityFrequency"
                            required
                        >
                            <mat-option
                                *ngFor="let pvTransmitFrequency of pVTransmitFrequencyList; trackBy: trackByValue"
                                [value]="pvTransmitFrequency.value"
                                >{{ pvTransmitFrequency.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSITIVITY' | translate }}"
                            formControlName="velocitySensitivity"
                            required
                        >
                            <mat-option *ngFor="let option of sensitivityList; trackBy: trackByValue" [value]="option.value"
                                >{{ option.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DIRECTION' | translate }}"
                            formControlName="velocityDirection"
                            required
                        >
                            <mat-option *ngFor="let pvDirection of pvDirectionList; trackBy: trackByValue" [value]="pvDirection.value">
                                {{ pvDirection.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_VALUE_MAXIMUM' | translate }}"
                            id="surfaceComboPVMaxVelocityInput"
                            formControlName="velocityMaximum"
                            class="full-width"
                        >
                            <mat-option *ngFor="let velocity of maxVelocityValues; trackBy: trackByValue" [value]="velocity.value">{{
                                velocity.text
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <div class="gain-container">
                        <mat-form-field class="required">
                            <input
                                matInput
                                placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MONITORGAIN' | translate }}"
                                id="surfaceComboPvGainInput"
                                formControlName="velocityGain"
                                required
                            />
                        </mat-form-field>
                    </div>
                    <div class="mat-error" *ngIf="form.get('velocityGain').errors?.pattern">
                        {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.INVALID_INPUT_TXT' | translate }}
                    </div>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="velocityStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>

                <div class="each-Item item-flex-center">
                    <mat-checkbox formControlName="velocityScrubbing">
                        {{ 'ADD_EDIT.INPUT_PLACEHOLDER_SCRUBBLING' | translate }}</mat-checkbox
                    >
                </div>
            </div>
            <div class="mt-15">
                <span class="toggle toggle-text input-text"> {{ 'ADD_EDIT.ADD_EDIT_TEXT' | translate }}</span>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="temperatureStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}</mat-slide-toggle
                    >
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSOR' | translate }}"
                            formControlName="sensor"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let item of sensorsList; trackBy: trackByValue" [value]="item.value">
                                {{ item.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="pressureEnable"
                    (change)="
                        onToggleChange($event, ['pressureElectronicOffset', 'pressureCalibration', 'pressureStoreData'])
                    "
                >
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_PRESSURE' | translate }}
                </mat-slide-toggle>
            </div>

            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_ELECTRONIC')
                                )
                            "
                            formControlName="pressureElectronicOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('pressureElectronicOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-checkbox formControlName="pressureCalibration">
                        {{ 'ADD_EDIT.AUTOMATIC_CALIBRATION' | translate }}</mat-checkbox
                    >
                </div>
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="pressureStoreData"
                        >{{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
