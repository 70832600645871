import { OnInit, Component, Input } from '@angular/core';
import { REGEX_CONFIG } from '../../../../utils/regex-utils';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VelocityGainTableComponent } from 'app/shared/components/velocity-gain-table/velocity-gain-table.component';
import {
    DeadBandList,
    DirectionList,
    FlowList,
    MaxVelocityMetricValues,
    MaxVelocityNonMetricValues,
    PrimarySensors,
    PVTransmitFrequencyList,
    SensitivityList,
    SensorsList,
    VelocityList,
} from 'app/shared/components/location-card/location-card.constants';
import { TranslateService } from '@ngx-translate/core';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { LocationFormBuilder } from 'app/shared/components/location-card/services/location-form-builder';
import { FormGroup } from '@angular/forms';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UsersService } from 'app/pages/admin/users.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-add-edit-location-peak-combo-sensor',
    templateUrl: './add-triton-location-peak-combo-sensor.component.html',
    styleUrls: ['./add-triton-location-peak-combo-sensor.component.scss'],
})
export class AddTritonLocationPeakComboSensorComponent implements OnInit {
    @Input() public monitoringPoint: 0 | 1;
    @Input() public index: 0 | 1;
    @Input() public form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;

    // Reg Ex. Pattern for numeric -+ Decimal value 2Place
    public negativeWithTwoDecimalPattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public flowList = FlowList;
    public transmitFrequencyList = PVTransmitFrequencyList;
    public sensorsList = SensorsList;
    public sensitivityList = SensitivityList;
    public deadBandList = [];
    public maxVelocityValues = [];
    public sensorDirectionList = DirectionList(false);
    public sensorVelocityList = VelocityList;
    public isMonitorEditor = false;
    public isMetric: boolean;
    public trackByValue = TrackBy.byValue();

    constructor(
        private matDialog: MatDialog,
        public translateService: TranslateService,
        public locationCardService: LocationCardService,
        private locationFormBuilder: LocationFormBuilder,
        private usersService: UsersService,
    ) {}

    public ngOnInit() {
        this.isMetric = this.locationCardService.isMetric;
        this.maxVelocityValues = this.isMetric ? MaxVelocityMetricValues() : MaxVelocityNonMetricValues();
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        this.deadBandList = DeadBandList(this.locationCardService.isMetric);

        const formValue = this.form.getRawValue();

        if (!this.maxVelocityValues.find((v) => v.value === formValue.velocityMaximum)) {
            formValue.velocityMaximum = 0;
        }

        if (this.monitorConfigs) {
            const offsetRounder = this.isMetric ? 10 : 100; // for offset values we allow 1 decimal for Metric and 2 for non metric

            formValue.physicalOffset = Math.round(formValue.physicalOffset * offsetRounder) / offsetRounder;
            formValue.pressureElectronicOffset = Math.round(formValue.pressureElectronicOffset * offsetRounder) / offsetRounder;
            formValue.ultrasonicElectronicOffset = Math.round(formValue.ultrasonicElectronicOffset * offsetRounder) / offsetRounder;
        }
        this.form.patchValue({ ...formValue });
    }

    public onChangeFlowType(event: MatSelectChange) {
        if (!event.value) {
            return;
        }

        this.form.patchValue({ ultrasonicFlowType: event.value });
    }


    public onChangeVelocityFlowType(event: MatSelectChange) {
        if (!event.value) {
            return;
        }

        this.form.patchValue({ velocityFlowType: event.value });
    }
    
    public onToggleChange(event: MatSlideToggleChange, keys: string[]) {
        this.locationFormBuilder.enableDisableControls(this.form, !event.checked, keys);
    }
}
