<div
    class="updatesWrapper"
    *ngIf="updatesWidgetService.updates"
    [ngClass]="{ withSkin: updatesWidgetService.updatesLoader || reviewChanges }"
>
    <div class="updatesSkin white"></div>
    <div
        class="updatesContainer"
        [anchor]="anchorAt"
    >
        <div
            class="actionButtons"
            [ngClass]="{ expanded: reviewChanges }"
            *ngIf="!updatesWidgetService.updatesLoader"
        >
            <button
                mat-button
                (click)="updatesAction(UpdatesAction.undoChanges)"
            >
                {{ 'UPDATES_WIDGET.UNDO_CHANGES' | translate }}
            </button>
            <button
                mat-button
                (click)="updatesAction(UpdatesAction.applyChanges)"
            >{{ 'UPDATES_WIDGET.APPLY' | translate }}</button>
        </div>
        <mat-expansion-panel
            hideToggle
            [disabled]="updatesWidgetService.updatesLoader"
            (opened)="setReviewChanges(true)"
            (closed)="setReviewChanges(false)"
            [expanded]="reviewChanges"
        >
            <mat-expansion-panel-header>
                <ng-container *ngIf="!updatesWidgetService.updatesLoader">
                    {{ 'UPDATES_WIDGET.TITLE' | translate }}
                </ng-container>
                <ng-container *ngIf="updatesWidgetService.updatesLoader">
                    <div class="loadingTitle">
                        <span>{{ 'UPDATES_WIDGET.LOADING_TITLE' | translate }}</span>
                        <mat-progress-spinner
                            diameter="25"
                            mode="indeterminate"
                            color="primary"
                        ></mat-progress-spinner>
                    </div>
                </ng-container>
            </mat-expansion-panel-header>
            <div
                class="updatesBody"
                matExpansionPanelContent
                *ngIf="updatesWidgetService.updatesInfo && updatesWidgetService.updatesInfo.length > 0"
            >
                <div
                    *ngFor="let updateInfo of updatesWidgetService.updatesInfo; trackBy: trackByIndex"
                    class="sliicerUpdatesInfo"
                >
                    <div class="reviewTitle">
                        <span>{{ 'UPDATES_WIDGET.MODELS.' + updateInfo['title'] | translate }}</span>
                        <span *ngIf="updateInfo.appendTitle">{{ updateInfo.appendTitle }}</span>
                    </div>
                    <div
                        class="reviewValues"
                        *ngIf="updateInfo['values'].length > 0"
                    >
                        <span *ngFor="let value of updateInfo['values']; let i = index; trackBy: trackByIndex">
                            {{ value + (i < updateInfo['values'].length - 1 ? ',' : '') }}
                        </span>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>
