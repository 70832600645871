import { Injectable } from '@angular/core';
import { CustomerService } from 'app/shared/services/customer.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Customer, customerQueryParam } from '../models/customer';
import { primeMeridian } from '../constant';

// WARNING: These values _MUST_ match what is provided to us by the CustomerService
//          getCustomerById subscription.
const CFS = 1; // US Standard using cubic feet per second for flow.
const METRIC = 2; // Metric. (liters / second) for flow
const MGD = 3; // US Standard using million gallons per day for flow.
export const TIME_12HOUR = 'h:mm:ss tt';
export const DATE_YMD = 'YYYY/MM/DD';
export const DATE_MDY = 'MM/DD/YYYY';
export const DATE_DMY = 'DD/MM/YYYY';
export const FILE_DATE_FORMAT = 'YYYYMMDD';

const CFS_STRING = 'cfs';
const MGD_STRING = 'MGD';
const LPS_STRING = 'l/s';
const LPD_STRING = 'l/day';
const CUBIC_FEET = 'ft3';
const MG_STRING = 'MG';
const ML_STRING = 'ML';
const IN_STRING = 'in';
const MM_STRING = 'mm';
const IN_PER_HR_STRING = 'in/hr';
const MM_PER_HR_STRING = 'mm/hr';

export const TIME_FORMAT_12HR = 'hh:mm:ss a';
export const TIME_FORMAT_24HR = 'HH:mm:ss';
export const TIME_FORMAT_12HR_NO_SECONDS = 'hh:mm a';
export const TIME_FORMAT_24HR_NO_SECONDS = 'HH:mm';

export const ONE_MINUTE_MS = 60000;

@Injectable()
export class DateutilService {
    private subscriptions: Subscription[] = [];

    public dateFormat = new BehaviorSubject<string>(DATE_MDY);
    public timeFormat = new BehaviorSubject<string>(TIME_12HOUR);
    public timeZone = new BehaviorSubject<string>('en_US');

    // Default to values should be MGD because that is how the data
    // is stored.
    public isCustomerUnitsMetric = new BehaviorSubject<boolean>(false);
    public customerUnit = new BehaviorSubject<string>(MGD_STRING);
    public volumneUnit = new BehaviorSubject<string>(MG_STRING);
    public volumnePerTimeUnit = new BehaviorSubject<string>(MGD_STRING);
    public peakVolumeUnit = new BehaviorSubject<string>(MGD_STRING);
    public unitOfMeasure = new BehaviorSubject<string>(IN_STRING);
    public unitOfMeasurePerHour = new BehaviorSubject<string>(IN_PER_HR_STRING);

    constructor(private customerService: CustomerService, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const customerID = Number(params.get(customerQueryParam));
            if (customerID > 0) {
                const customerDetailSubscription = this.customerService
                    .getCustomerById(customerID)
                    .subscribe((response: Customer) => {
                        // setting date format
                        if (this.dateFormat.getValue() !== response.dateFormat) {
                            this.dateFormat.next(response.dateFormat);
                        }

                        //  setting time format
                        if (this.timeFormat.getValue() !== response.timeFormat) {
                            this.timeFormat.next(response.timeFormat);
                        }

                        this.timeZone.next(response.timeZone);

                        // unit set on customer profile
                        if (response.unitsType) {
                            this.setUnits(response.unitsType);
                        }
                    });
                this.subscriptions.push(customerDetailSubscription);
            }
        });
    }

    public startDate = {
        date: { year: new Date().getFullYear(), month: this.getPreviousMonth(), day: this.getDay() },
    };
    public endDate = {
        date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() },
    };

    // min and max dates for datepicker input field validation
    public maxDate = new Date();
    public minDate = new Date(2000, 1, 1);

    public getPreviousMonth() {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return date.getMonth() + 1;
    }

    public getDifferenceByTimezone(timezone: string) {
        const difference = timezone.replace('UTC', '');

        if (!difference) return 0;

        const formatted = difference.match(/(\+|-)(\d*):(\d*)/);
        if (!formatted) {
            return 0;
        }
        const [str, operator, hours, minutes] = formatted;
        const oneMinute = 1000 * 60;
        const convertedHours = Number(hours) ? oneMinute * 60 * Number(hours) : '';
        const convertedMinutes = Number(minutes) ? oneMinute * Number(minutes) : '';
        const invertedOperator = operator === '+' ? '-' : '+';
        return Number(invertedOperator + convertedHours + convertedMinutes);
    }

    public formatDateWithoutOffset(date: Date)
    {
          let formattedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
          formattedDate = formattedDate.substring(0, 19);
          formattedDate += primeMeridian;

          return formattedDate;
    }

    public getDay() {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return date.getDate();
    }

    public formatDateToBeginningOfDay(dt: Date) {
        if (dt instanceof Date === false) {
            dt = new Date(dt);
        }
        dt.setHours(0, 0, 0, 0);
        let startDate = new Date(dt.getTime() - dt.getTimezoneOffset() * ONE_MINUTE_MS).toISOString();
        startDate = startDate.substring(0, 10);
        startDate += 'T00:00:00.000Z';
        return startDate;
    }

    public formatDateToEndOfDay(input: Date) {
        const dt = new Date(input);
        dt.setHours(0, 0, 0, 0);
        let endDate = new Date(dt.getTime() - dt.getTimezoneOffset() * ONE_MINUTE_MS).toISOString();
        endDate = endDate.substring(0, 10);
        endDate += 'T23:59:59.999Z';
        return endDate;
    }

    public getUTCDate(d: Date) {
        return new Date(d.getTime() + d.getTimezoneOffset() * ONE_MINUTE_MS);
    }
    public getUTCDateFromString(dateStr: Date) {
        return new Date(this.getUTCDate(new Date(dateStr)));
    }
    public getLocalDateFromUTCDate(d: Date) {
        const date = new Date(d);
        return new Date(date.getTime() - date.getTimezoneOffset() * ONE_MINUTE_MS);
    }

    public getPreviousDate(date: Date, numberOfDays: number) {
        const todayTimeStamp = +date; // Unix timestamp in milliseconds
        const oneDayTimeStamp = 1000 * 60 * 60 * 24 * numberOfDays; // Milliseconds in a day
        const diff = todayTimeStamp - oneDayTimeStamp;
        const yesterdayDate = new Date(diff);
        const yesterdayString =
            yesterdayDate.getFullYear() + '-' + (yesterdayDate.getMonth() + 1) + '-' + yesterdayDate.getDate();
        return yesterdayString;
    }

    /**
     * method for diplaying date format as per customer selected date format across all widgets
     */
    public getFormat() {
        const format = this.dateFormat.getValue();
        return this.getStringFormat(format);
    }

    public getStringFormat(customerFormat: string) {
        switch (customerFormat) {
            case DATE_MDY:
            default:
                return 'MM/dd/yyyy';
            case DATE_YMD:
                return 'yyyy/MM/dd';
            case DATE_DMY:
                return 'dd/MM/yyyy';
        }
    }

    // TODO: Methinks we should DEPRECATE this function. `dateFormat` is already public
    public getDateFormat() {
        return this.dateFormat.getValue();
    }

    public getTimeFormatFor(timeFormat: string) {
        if (timeFormat === TIME_12HOUR) {
            return TIME_FORMAT_12HR;
        }
        return TIME_FORMAT_24HR;
    }

    public getTimeFormat() {
        return this.getTimeFormatFor(this.timeFormat.getValue());
    }

    public getTimeFormatWithoutSeconds() {
        const timeFormat = this.timeFormat.getValue();
        if (timeFormat === TIME_12HOUR) {
            return TIME_FORMAT_12HR_NO_SECONDS;
        }
        return TIME_FORMAT_24HR_NO_SECONDS;
    }

    public formatDateAsTimeZone(date: Date): Date {
        if (date === undefined) {
            return;
        }

        if (!date.getTime) {
            date = new Date(date);
        }

        date = new Date(date.getTime() + date.getTimezoneOffset() * ONE_MINUTE_MS);

        return date;
    }

    /**
     * method for diplaying date format as per customer selected date format across all graphs
     */
    public getGraphDateFormat() {
        const customerDateFormat = this.dateFormat.getValue();
        switch (customerDateFormat) {
            case DATE_MDY:
                return '%b %e %Y';
            case DATE_YMD:
                return '%Y %b %e';
            case DATE_DMY:
                default:
                return '%e %b %Y';
        }
    }

    public calculateDiff(start: Date, end: Date): number {
        return new Date(end.getTime() - end.getTimezoneOffset() * ONE_MINUTE_MS).getTime() -
            new Date(start.getTime() - start.getTimezoneOffset() * ONE_MINUTE_MS).getTime();
    }

    /**
     * Method takes a string as parameter and returns adds to/subtracts days from/to the date of the string
     * taking into consideration customer's date format and returns a new date object
     * @param offsetNumberOfDays - number of days to add or subtract from the date in the timestamp
     * @param timestamp - date/time in a string
     */
    public getNewDate(offsetNumberOfDays: number, timestamp: string) {
        if (timestamp.includes(' ')) {
            timestamp = timestamp.split(' ')[0];
        }
        const timestampArray = timestamp.split('/');

        let newDate;
        const dateFormat = this.dateFormat.getValue();
        switch (dateFormat) {
            case DATE_YMD:
            default:
                newDate = new Date(Number(timestampArray[0]), Number(timestampArray[1]) - 1, Number(timestampArray[2]));
                break;
            case DATE_MDY:
                newDate = new Date(Number(timestampArray[2]), Number(timestampArray[0]) - 1, Number(timestampArray[1]));
                break;
            case DATE_DMY:
                newDate = new Date(Number(timestampArray[2]), Number(timestampArray[1]) - 1, Number(timestampArray[0]));
                break;
        }

        newDate.setDate(newDate.getDate() + offsetNumberOfDays);
        return newDate;
    }

    /**
     * @getStartDateAsTimeZone - will use to convert date w.r.t. timeZone and string.
     * @startDate the input start date
     */
    public getStartDateAsTimeZone(startDate: Date): string {
        if (startDate === undefined) {
            return;
        }

        if (!startDate.getTime) {
            startDate = new Date(startDate);
        }

        // convert date w.r.t. TimeZone and make as string
        return new Date(startDate.getTime() - startDate.getTimezoneOffset() * ONE_MINUTE_MS).toISOString();
    }

    /**
     * @getEndDateAsTimeZone - will use to convert date w.r.t. timeZone and string.
     * @edate repersent input end date and make time 23:59:59 for the day.
     */
    public getEndDateAsTimeZone(edate: Date): string {
        if (edate === undefined) {
            return;
        }
        // convert date w.r.t. TimeZone and make as string
        // set time value as 23:59:59 for the day to get full day data.

        const endDate = new Date(edate.getTime() - edate.getTimezoneOffset() * ONE_MINUTE_MS).toISOString();

        return endDate;
    }

    /**
     * Get total days between two dates
     * @param startDate
     * @param endDate
     */
    public differenceInDays(startDate: Date, endDate: Date) {
        const ONEDAY = 1000 * 60 * 60 * 24;
        // Convert both dates to milliseconds
        const startTimstamp = startDate.getTime();
        const endTimstamp = endDate.getTime();
        // Calculate the difference in milliseconds
        const diffTimestamp = Math.abs(startTimstamp - endTimstamp);
        // Convert back to days and return
        return Math.round(diffTimestamp / ONEDAY);
    }

    public weekCount(date: Date): number {
        // month_number is in the range 1..12
        const year = date.getUTCFullYear();
        const month = date.getMonth();
        const firstOfMonth = new Date(year, month - 1, 1);
        const lastOfMonth = new Date(year, month, 0);
        const used = firstOfMonth.getDay() + lastOfMonth.getDate();
        return Math.ceil(used / 7);
    }

    public getDateRange(startDate: Date, endDate: Date) {
        const arr = [];
        const date = new Date(startDate);
        while (date <= endDate) {
            arr.push(new Date(date).getTime());
            date.setDate(date.getDate() + 1);
        }
        return arr;
    }

    public getSystemPreferenceDate(timestamp) {
        return moment(timestamp).format(this.dateFormat.getValue().toUpperCase());
    }

    /**
     * Set the various behavior subjects for units based on what the customer service tells us.
     *   unit type == 1 means US Standard with CFS for flow (cubit feet per second)
     *   unit type == 2 means metric
     *   unit type == 3 means US Standard with MGD for flow (million gallons daily)
     * @param unitsType
     */
    private setUnits(unitsType: number) {
        let flowString: string;
        let volumeString: string;
        let volumePerTimeString: string;
        let lengthString: string;
        let rainfallString: string;
        let peakVolumeString: string;
        switch (unitsType) {
            case MGD:
            default:
                flowString = MGD_STRING;
                volumeString = MG_STRING;
                volumePerTimeString = MGD_STRING;
                peakVolumeString = MGD_STRING;
                lengthString = IN_STRING;
                rainfallString = IN_PER_HR_STRING;
                break;
            case CFS:
                flowString = CFS_STRING;
                volumeString = CUBIC_FEET;
                volumePerTimeString = CFS_STRING;
                peakVolumeString = CFS_STRING;
                lengthString = IN_STRING;
                rainfallString = IN_PER_HR_STRING;
                break;
            case METRIC:
                flowString = LPS_STRING;
                volumeString = ML_STRING;
                volumePerTimeString = LPD_STRING;
                peakVolumeString = LPS_STRING;
                lengthString = MM_STRING;
                rainfallString = MM_PER_HR_STRING;
                break;
        }
        this.customerUnit.next(flowString);
        this.volumneUnit.next(volumeString);
        this.volumnePerTimeUnit.next(volumePerTimeString);
        this.peakVolumeUnit.next(peakVolumeString);
        this.unitOfMeasure.next(lengthString);
        this.unitOfMeasurePerHour.next(rainfallString);
        this.isCustomerUnitsMetric.next(unitsType === METRIC);
    }

    /** Formats a value for entity, #21671 */
    public formatMetricsValue(val: number, precision: number) {
        if (val === undefined || val === null) return '-';

        const optionsFormat = {
            minimumIntegerDigits: 1,
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
            useGrouping: false,
        };

        return val.toLocaleString('en-US', optionsFormat);
    }

    public formatTooltipForCharts() {
        const customerDateFormat = this.getFormat();
        const customerTimeFormat = this.getTimeFormatWithoutSeconds();

        let formatted: string;
        if (customerDateFormat === 'dd/MM/yyyy') {
            formatted = 'DD MMM YYYY ';
        } else if (customerDateFormat === 'MM/dd/yyyy') {
            formatted = 'MMM DD YYYY ';
        } else {
            formatted = 'YYYY MMM DD ';
        }

        if (customerTimeFormat === 'HH:mm') { // 24 hour time
            formatted += 'HH:mm';
        } else { // am/pm time
            formatted += 'hh:mm A';
        }

        return formatted;
    }

    public generateTooltipDateFormatForHydrodgraph() {
        const customerDateFormat = this.getFormat();
        const customerTimeFormat = this.getTimeFormatWithoutSeconds();

        // %b is short month like 'Jan'
        // %e is day of month, 1-31
        // %l is hours in 12 hour format
        // %M is 2 digit miniutes, 00-59
        // %p is upper case AM or PM
        // %H is 2 digit hours 0-23

        let formatted: string;
        if (customerDateFormat === 'dd/MM/yyyy') {
            formatted = '%e %b ';
        } else { // Whether they use month/day/year or year/month/day OR not set somehow
            formatted = '%b %e ';
        }

        if (customerTimeFormat === 'HH:mm') { // 24 hour time
            formatted += '%H:%M';
        } else { // am/pm time
            formatted += '%l:%M %p';
        }

        return formatted;
    }

    public roundToNearestHour(date: Date) {
        date.setMinutes(date.getMinutes() + 30);
        date.setMinutes(0, 0, 0);

        return date;
    }

    public roundToNearest30(date: Date) {
        const minutes = 30;
        const ms = 1000 * 60 * minutes;

        return new Date(Math.round(date.getTime() / ms) * ms);
    }

    public checkIfInsideDateRange(ts: number, dateRanges: string[][]) {
        return dateRanges.some((dateRange: string[]) => {
            const [start, end] = dateRange;

            const isWithinStart = new Date(start).getTime() <= ts;
            const isWithinEnd = end === null || new Date(end).getTime() >= ts;  // null means it is the last entry

            return isWithinStart && isWithinEnd;
        })
    }

    public formatDateToUTC(date: Date) {
        const timezoneFormatted = this.getLocalDateFromUTCDate(date);

        return timezoneFormatted ? timezoneFormatted.toISOString().slice(0,-5) : null;
    }
}
