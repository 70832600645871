import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UsersService } from 'app/pages/admin/users.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import sortBy from 'lodash/sortBy';
import { StatusCodeService } from '../../../shared/services/status-code.service';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import {
    CreateVariable,
    CalculatedBaseEntity,
    TimeInterval,
    ELEMENT_DATA,
    CalculationType,
    CALCULATION_TYPE_DEFAULTS,
    CalculatedEntityDetails,
} from './calculated-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { AdsPrismSingleSelectGroupComponent } from 'app/shared/components/ads-prism-single-select-group/ads-prism-single-select-group.component';
import {
    RAIN_DISPLAY_GROUP,
    FLOW_DISPLAY_GROUP,
    NOTIFICATION_TIMEOUT,
    FLOW_METRIC_UNIT,
    FLOW_NON_METRIC_UNIT,
    STANDARD_FLOW_ENTITIES,
    DEPTH_DISPLAY_GROUP,
    DFINAL_ENTITY,
    VELOCITY_DISPLAY_GROUP,
    TEMPERATURE_DISPLAY_GROUP,
    CUSTOM_SNACKBAR_ERROR,
    ANSR_FORMULA_CALC,
    ANSR_CUMULATIVE_CALC,
    ANSR_MANNING_CALC,
    ANSR_DELTA_CALC,
    ANSR_REPEAT_CALC,
    ANSR_INTERPOLATE_CALC,
    ANSR_ROLLING_AVG_CALC,
    ANSR_STEP_CALC,
} from 'app/shared/constant';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { CalculatedEntitiesService } from 'app/shared/services/calculated-entities.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { EntityService } from 'app/shared/services/entity.service';
import { customerQueryParam, locationIdQueryParam } from 'app/shared/models/customer';
import { DisplayGroupModel, EntityUnit, Units } from 'app/shared/models/units';
import { EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';
import { Selectable, SelectableGroup } from 'app/shared/models/selectable';
import { NgForm } from '@angular/forms';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-calculated-entity',
    templateUrl: './calculated-entity.component.html',
    styleUrls: ['./calculated-entity.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculatedEntityComponent implements OnInit, OnDestroy {
    public unitId: number;
    public entityId: number;
    public displayGroupId: number;
    public locationId: number;
    public isLoading: boolean;
    public isloaderForMappingEntity: boolean;
    public unitsType = Array<Units>();
    public entityList = Array<EntityUnit>();
    public locationList = Array<Locations>();
    public selectedlocationList = Array<Locations>();
    public customerId: number;
    public newLocationIds: number[];
    public calculatedEntityId: number;
    public displayGroupsList = Array<DisplayGroupModel>();
    public selected: number;
    public hideCreateArea: boolean;
    public selectedItems: Array<CreateVariable>;
    public timeInterval = new TimeInterval();
    public maxDate = new Date();
    public equation: string;
    public variableList = Array<string>();
    public isFormulaValidated = false;
    // By default, we are putting this as true, once other screen will work,
    // we will make this false.
    public isEditScreen = false;
    public updateMessageCalculatedEntity: string;
    public isCalculatedEntitySaved = false;

    public calcEntity: CalculatedBaseEntity = new CalculatedBaseEntity();
    /**
     * Represents snackbar message
     */
    public requiredFieldsMessage: string;
    public validationMessage: string;
    public successMessageCalculatedEntity: string;
    public successMessage: string;
    public mapLocationSuccessMessage: string;
    public mapLocationFailureMessage: string;
    // public dashText: string;
    // public invalidDateRangeMessage: string;
    // public invalidDateMessage: string;
    /**
     * Represents snackbar dismiss button text
     */
    public dismissBtn: string;
    /**
     * Variable which represents the filtered entity collection
     */
    public filteredEntities: Array<Selectable> = new Array<Selectable>();

    /**
     * Object for getting common methods for loading entities.
     */

    private subscriptions = new Array<Subscription>();

    public calculatedEntityColumns = ['selectuser', 'name', 'location_name', 'description'];
    public calculatedEntityDataSource = new MatTableDataSource(ELEMENT_DATA);

    // public startDate: Date;
    // public endDate: Date;
    // public displayStartDateErrMsg: boolean;
    // public displayEndDateErrMsg: boolean;
    // public invalidDateRange: boolean;
    // public invalidStartDate: boolean;
    // public invalidEndDate: boolean;
    // public isCustomDateTouched: boolean;
    // public customerDateFormat: string;

    public showFormula: boolean;
    public showCumulative: boolean;
    public showManning: boolean;
    public isEntitySaved: boolean;
    public showSum: boolean;
    public showRolling: boolean;
    public showDelta: boolean;
    public showRepeat: boolean;
    public showDiscrete: boolean;
    public showInterpolate: boolean;
    public showAverage: boolean;
    public showMedian: boolean;
    public showConstant: boolean;
    public showMinimum: boolean;
    public showMaximum: boolean;
    public showShift: boolean;
    public showStep: boolean;
    public showWeighted: boolean;
    public isRowFilled: boolean;
    public calculationTypePdfUrl: string;
    public calculationTypeCollection = [
        {
            name: 'FORMULA',
            id: 0,
            text: 'CALCULATED_ENTITY_DIALOG.FORMULA_CALCULATION',
        },
        {
            name: 'CUMULATIVE',
            id: 1,
            text: 'CALCULATED_ENTITY_DIALOG.CUMULATIVE_CALCULATION',
        },
        {
            name: 'DELTA',
            id: 2,
            text: 'CALCULATED_ENTITY_DIALOG.DELTA_CALCULATION',
        },
        {
            name: 'MANNING',
            id: 4,
            text: 'CALCULATED_ENTITY_DIALOG.MANNING_CALCULATION',
        },
        {
            name: 'ROLLINGAVERAGE',
            id: 6,
            text: 'CALCULATED_ENTITY_DIALOG.AVERAGE_CALCULATION',
        },
        {
            name: 'INTERPOLATE',
            id: 7,
            text: 'CALCULATED_ENTITY_DIALOG.INTERPOLATE_CALCULATION',
        },
        {
            name: 'STEP',
            id: 8,
            text: 'CALCULATED_ENTITY_DIALOG.STEP_CALCULATION',
        },
    ];
    public invalidEntitySelected: boolean;
    public invalidEntitySelectedMessage: string;
    // flags to toggle disabled state of units and displaygroups for formula and non-formulaic calculation types
    public disableDisplayGroups: boolean;
    public disableUnits: boolean;
    public duration = 10000;

    // Variable which represents the entity collection
    public singleSelectEntityList: Array<SelectableGroup> = [];
    @ViewChild('entitiesGroupSingleselect') public entitiesGroupSingleselect: AdsPrismSingleSelectGroupComponent;
    @ViewChild('calculatedEntityForm') public form: NgForm;
    // Array sent to ads-single-select component to filter Entities based on displayGroups for particular calculationtypes
    public filterDisplayGroups: Array<number> = [];
    // varibales to track and hold manning type validations and static notification messages
    public invalidManningRoughness: boolean;
    public invalidManningSlope: boolean;
    public numericDecimalPattern = new RegExp(REGEX_CONFIG.numeric0_100With2DecimalPlacesPattern);
    public numericDecimalPattern5precision = new RegExp(REGEX_CONFIG.numeric0_100With5DecimalPlacesPattern);
    public invalidManningRoughnessText: string;
    public invalidManningSlopeText: string;
    public invalidStepMinutesInterval: boolean;
    public invalidStepIntervalMessage: string;
    @Input() public data;
    @Input() public ansrEntities: CalculatedEntityDetails[];
    @Output() public renderSection: EventEmitter<boolean> = new EventEmitter<boolean>();

    public existingEntityNameError: boolean;
    public availableEntities$ = new BehaviorSubject<EntitySelectorObject[]>(undefined);
    public selectedEntityNames: string;

    public trackById = TrackBy.byId;
    public trackByUnitId = TrackBy.byUniqueKey<Units>()('unitId');
    public trackByIndex = TrackBy.byIndex;
    public trackByLocationId = TrackBy.byLocationId;
    constructor(
        private calculatedEntitiesService: CalculatedEntitiesService,
        private uiUtilsService: UiUtilsService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private entityService: EntityService,
        private usersService: UsersService,
        private statusCodeService: StatusCodeService,
        private translate: TranslateService,
        private snackBar: SnackBarNotificationService,
        private dateUtilService: DateutilService,
    ) {}

    public ngOnInit() {
        this.existingEntityNameError = false;
        this.applyTranslations();
        this.calculation(CalculationType.FORMULA);

        this.isEntitySaved = false;
        this.hideCreateArea = false;

        //  subscribe to customer and location group changes
        const activatedRouteSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            if (this.customerId) {
                this.customerId = Number(params.get(customerQueryParam));
                this.locationId = Number(params.get(locationIdQueryParam));
            } else {
                this.customerId = Number(params.get(customerQueryParam));
                this.locationId = Number(params.get(locationIdQueryParam));
            }
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.push(activatedRouteSubscription);
        this.selected = 0;
        this.getUnits();
        this.getEntities();

        // subscribe to changes in dateFormat
        // const subscriptionDate = this.dateUtilService.dateFormat.subscribe(() => {
        //   this.customerDateFormat = this.dateUtilService.getFormat().toUpperCase();
        //   this.uiUtilsService.safeChangeDetection(this.cdr);
        // });
        // this.subscriptions.push(subscriptionDate);

        this.getCustomerLocations();
        if (this.data && this.data.name) {
            this.isEditScreen = true;
            this.calcEntity = this.data;
            this.getDisplayGroups(this.calcEntity.unitId);
            // if (this.data.startDate) {
            //   this.calcEntity.startDate = this.startDate = new Date(this.data.startDate);
            // }
            // if (this.data.endDate) {
            //   this.calcEntity.endDate = this.endDate = new Date(this.data.endDate);
            // }
            this.selectedItems = this.data.formulaEntities;
            this.selected = +this.data.formulaType;
            this.calculation(this.calculationTypeCollection.find((x) => x.id === Number(this.data.formulaType)).name);
            // Assume that both formula was already validated and row is valid as well when enter EDIT mode
            this.isFormulaValidated = true;
            this.isRowFilled = true;
        } else {
            // this.calcEntity.startDate = this.startDate;
            // this.calcEntity.endDate = this.endDate;
            this.selectedItems = [
                {
                    locationId: -1,
                    entityId: -1,
                    variableName: undefined,
                    orderId: undefined,
                    manningRoughness: undefined,
                    manningSlope: undefined,
                    depthEntityId: undefined,
                    timeInterval: this.timeInterval,
                },
            ];
        }
        // this.dashText = (this.startDate && this.endDate) ? '-' : '';
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public onUnitsTypeChange($event) {
        this.getDisplayGroups($event.value);
    }

    public selectedGroupEntity(selectedEntity: number) {
        this.invalidEntitySelected = false;
        this.selectedItems[0].entityId = selectedEntity;
        const entityObject = this.singleSelectEntityList.find((x) => x.id === selectedEntity);
        this.displayGroupsList = [];
        if (this.showManning && entityObject && Object.keys(entityObject).length) {
            this.dateUtilService.isCustomerUnitsMetric.getValue()
                ? (this.calcEntity.unitId = this.unitId = FLOW_METRIC_UNIT)
                : (this.calcEntity.unitId = this.unitId = FLOW_NON_METRIC_UNIT);
            this.getDisplayGroups(this.calcEntity.unitId);
            this.displayGroupsList.push({
                id: FLOW_DISPLAY_GROUP,
                name: STANDARD_FLOW_ENTITIES,
            });
            this.setSelectedDisplayGroup();
        } else if (!this.showFormula && entityObject && Object.keys(entityObject).length) {
            this.calcEntity.unitId = this.unitId = entityObject.unitId;
            this.getDisplayGroups(this.calcEntity.unitId);
            this.displayGroupsList.push({
                id: entityObject.groupId,
                name: entityObject.groupName,
            });
            this.setSelectedDisplayGroup();
        }
    }

    public resetScreen() {
        this.showFormula = false;
        this.showCumulative = false;
        this.showManning = false;
        this.showSum = false;
        this.showRolling = false;
        this.showDelta = false;
        this.showRepeat = false;
        this.showDiscrete = false;
        this.showInterpolate = false;
        this.showAverage = false;
        this.showMedian = false;
        this.showConstant = false;
        this.showMinimum = false;
        this.showMaximum = false;
        this.showShift = false;
        this.showStep = false;
        this.showWeighted = false;
        this.disableDisplayGroups = false;
        this.disableUnits = false;
        this.filterDisplayGroups = [];
        if (!this.isEditScreen) {
            this.selectedItems = [
                {
                    locationId: -1,
                    entityId: -1,
                    variableName: undefined,
                    orderId: undefined,
                    manningRoughness: undefined,
                    manningSlope: undefined,
                    depthEntityId: undefined,
                    timeInterval: (this.timeInterval = new TimeInterval()),
                },
            ];
            // resetting unit id and displaygroupid since they will be prefilled based on selected entity for non-formulaic calculation type
            this.calcEntity.unitId = 41;
            this.getDisplayGroups(this.calcEntity.unitId);
            this.calcEntity.displayGroupId = -1;
        }
    }

    public calculation(screen: string) {
        this.resetScreen();
        switch (screen) {
            case CalculationType.FORMULA:
                this.showFormula = true;
                break;
            case CalculationType.CUMULATIVE:
                this.showCumulative = true;
                // Cumulative calculation currently supports only flow and rain entities
                this.filterDisplayGroups.push(RAIN_DISPLAY_GROUP, FLOW_DISPLAY_GROUP);
                break;
            case CalculationType.MANNING:
                this.showManning = true;
                this.setManningCalculationTypeDefaults();
                break;
            case CalculationType.SUM:
                this.showSum = true;
                break;
            case CalculationType.ROLLING:
                this.showRolling = true;
                // Rolling sum calculation currently supports only flow and rain entities
                this.filterDisplayGroups.push(FLOW_DISPLAY_GROUP, RAIN_DISPLAY_GROUP);
                break;
            case CalculationType.DELTA:
                this.showDelta = true;
                break;
            case CalculationType.REPEAT:
                this.showRepeat = true;
                break;
            case CalculationType.DISCRETE:
                this.showDiscrete = true;
                break;
            case CalculationType.INTERPOLATE:
                this.showInterpolate = true;
                this.filterDisplayGroups.push(
                    DEPTH_DISPLAY_GROUP,
                    VELOCITY_DISPLAY_GROUP,
                    FLOW_DISPLAY_GROUP,
                    TEMPERATURE_DISPLAY_GROUP,
                );
                break;
            case CalculationType.AVERAGE:
                this.showAverage = true;
                // Rolling average calculation currently supports depth,velocity,flow, temperature groups
                this.filterDisplayGroups.push(
                    DEPTH_DISPLAY_GROUP,
                    VELOCITY_DISPLAY_GROUP,
                    FLOW_DISPLAY_GROUP,
                    TEMPERATURE_DISPLAY_GROUP,
                );
                break;
            case CalculationType.MEDIAN:
                this.showMedian = true;
                break;
            case CalculationType.CONSTANT:
                this.showConstant = true;
                break;
            case CalculationType.MINIMUM:
                this.showMinimum = true;
                break;
            case CalculationType.MAXIMUM:
                this.showMaximum = true;
                break;
            case CalculationType.SHIFT:
                this.showShift = true;
                break;
            case CalculationType.STEP:
                this.showStep = true;
                this.filterDisplayGroups.push(
                    DEPTH_DISPLAY_GROUP,
                    VELOCITY_DISPLAY_GROUP,
                    FLOW_DISPLAY_GROUP,
                    RAIN_DISPLAY_GROUP,
                    TEMPERATURE_DISPLAY_GROUP,
                );
                break;
            case CalculationType.WEIGHTED:
                this.showWeighted = true;
                break;
            default:
                break;
        }

        if (!this.showFormula) {
            this.disableDisplayGroups = true;
            this.disableUnits = true;
            this.isFormulaValidated = true;
        } else {
            this.isFormulaValidated = false;
            this.disableDisplayGroups = false;
            this.disableUnits = false;
        }
        // slicing and assigning back the entity list to invoke ngonchanges on ads-single-select-component
        this.singleSelectEntityList = this.singleSelectEntityList.slice(0);
        // calling change detection since changes to entity to be triggered in ads-prism-single-select
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    // public checkStartDateChange(event: MatDatepickerInputEvent<Date>) {
    //   this.displayStartDateErrMsg = false;
    //   this.startDate = event.target.value;
    //   if (!!this.startDate && !!this.endDate) {
    //     this.monthDifference(String(event.target.value), String(this.endDate));
    //   } else {
    //     this.dashText = '';
    //   }
    //   this.isCustomDateTouched = true;
    //   if (this.showFormula) {
    //     this.isFormulaValidated = false;
    //   }
    // }

    // public checkEndDateChange(event: MatDatepickerInputEvent<Date>) {
    //   this.displayEndDateErrMsg = false;
    //   this.endDate = event.target.value;
    //   if (!!this.startDate && !!this.endDate) {
    //     this.monthDifference(this.startDate.toString(), event.target.value.toString());
    //   } else {
    //     this.dashText = '';
    //   }
    //   if (this.showFormula) {
    //     this.isFormulaValidated = false;
    //   }
    //   this.isCustomDateTouched = true;
    // }

    // public checkDateVaidation() {
    //   if (this.isCustomDateTouched) {
    //     this.uiUtilsService.safeChangeDetection(this.cdr);
    //     this.isCustomDateTouched = false;
    //   }
    // }
    // public monthDifference(startDateValue, endDateValue) {
    //   if (this.dashText === '') {
    //     this.dashText = '-';
    //   }
    //   let startDate = new Date(startDateValue);
    //   let endDate = new Date(endDateValue);

    //   // ensure end date is greater
    //   if (endDate < startDate) {
    //     this.invalidEndDate = true;
    //     this.invalidDateRange = true;
    //     return;
    //   }

    //   this.invalidEndDate = false;
    //   this.invalidDateRange = false;

    //   let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    //   let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    // }

    // get all units
    public getUnits() {
        this.isLoading = true;
        this.calculatedEntitiesService.getUnits().subscribe(
            (units) => {
                this.unitsType = units || [];
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    public getEntities() {
        this.isLoading = true;
        const entitySubscription = this.calculatedEntitiesService.getPreDefinedEntities(this.customerId).subscribe(
            (entities: EntityUnit[]) => {
                if (entities && entities.length) {
                    this.entityList = entities;
                    const seriesEntityGroup: EntitySelectorObject[] =
                        this.entityService.selectableModelToEntitySelectorObject(entities);

                    this.availableEntities$.next(seriesEntityGroup);
                    // creating the custom objects for ads-single-select-component to display entities grouping with display groups
                    entities.forEach((entity: EntityUnit) => {
                        this.singleSelectEntityList.push({
                            name: entity.name,
                            id: entity.id,
                            groupName: entity.displayGroupName,
                            groupId: entity.displayGroupId,
                            isChecked: false,
                            unitId: entity.unitId,
                        });
                    });
                }
                this.isLoading = false;
                /* slicing and assigning back the singleSelectEntityList to trigger ngonchanges on ads-prism-single-select-group component
      to help set the selected entity on edit mode */
                this.singleSelectEntityList = this.singleSelectEntityList.slice(0);
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.isLoading = false;
            },
        );
        this.subscriptions.push(entitySubscription);
    }

    public getDisplayGroups(unitId: number) {
        this.isLoading = true;
        const displayGroupsSubscription = this.calculatedEntitiesService.getDisplayGroupsByUnitId(unitId).subscribe(
            (displayGroups) => {
                if (displayGroups && displayGroups.length > 0) {
                    this.displayGroupsList = displayGroups;
                    this.calcEntity.displayGroupId =
                        this.data && this.data.displayGroupId &&
                            displayGroups.some((a) => a.id === this.data.displayGroupId)
                            ? this.data.displayGroupId
                            : displayGroups[0].id;
                } else {
                    this.displayGroupsList = [];
                    this.calcEntity.displayGroupId = null;
                }
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(displayGroupsSubscription);
    }

    public getCustomerLocations() {
        // initialize location parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            locationGroupId: 0,
        };
        this.isLoading = true;
        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();
        const locationsObservableService = this.usersService.getLocationsList(locationArgs).subscribe(
            (result: Array<Locations>) => {
                if (result) {
                    if (activeInactiveHandler) {
                        this.locationList = result.filter((x) => x.viewable);
                    } else {
                        this.locationList = result.filter(
                            (x) =>
                                x.viewable &&
                                (x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance),
                        );
                    }
                    this.locationList = sortBy(this.locationList, (x) => x.locationName);
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    public onNameChange(event: string) {
        this.existingEntityNameError = this.ansrEntities.some((ent) => ent.name.toLowerCase().trim() === event.toLowerCase().trim());
    }

    public applyFormula() {
        if (!this.isCalculatedEntitySaved) {
            if (this.invalidEntitySelected) {
                this.snackBar.raiseNotification(this.invalidEntitySelectedMessage, this.dismissBtn, {
                    duration: this.duration,
                });
                return;
            }

            if (
                this.selectedItems.some((x) => x.locationId === -1 && this.showFormula) ||
                this.selectedItems.some((x) => x.entityId === -1)
            ) {
                this.snackBar.raiseNotification(this.requiredFieldsMessage, this.dismissBtn, {
                    duration: this.duration,
                });
                return;
            }
            // method to set calculation entity object on formula type chosen
            this.setCalculationEntityObject();
            this.calcEntity.locationId = this.locationId;
            this.calcEntity.formulaType = this.calculationTypeCollection.find((x) => x.id === this.selected).name;
            if (!this.isFormulaValidated && this.showFormula) {
                this.snackBar.raiseNotification(this.validationMessage, this.dismissBtn, {
                    duration: this.duration,
                });
                return;
            }
            if (
                this.calcEntity.name === undefined ||
                this.calcEntity.unitId === undefined ||
                this.calcEntity.formulaEntities === undefined
            ) {
                this.snackBar.raiseNotification(this.requiredFieldsMessage, this.dismissBtn, {
                    duration: this.duration,
                });
                return;
            }
            // if (this.calcEntity.formulaType !== CalculationType.FORMULA &&
            //   this.calcEntity.formulaType !== CalculationType.INTERPOLATE && this.isDateRangeInvalid()) {
            //   return;
            // }
            if (this.showManning && !this.validateManningConstants()) {
                return;
            } else if (this.invalidStepMinutesInterval) {
                this.snackBar.raiseNotification(this.invalidStepIntervalMessage, this.dismissBtn, {
                    panelClass: CUSTOM_SNACKBAR_ERROR,
                }, false);
                return;
            }

            const isApplyButtonClicked = true;
            // this.calcEntity.startDate = this.startDate;
            // this.calcEntity.endDate = this.endDate;

            if (!this.showFormula) {
                this.isLoading = true;
                this.calcEntity.calculatedEntityId
                    ? this.updateEqutionForCalculatedEntity()
                    : this.saveEquationForCalculatedEntity();
                return;
            }
            // Checking the equation again before saving.
            this.validateEquation(isApplyButtonClicked);
        } else {
            this.mapCalculatedEntityToLocation();
        }
    }

    private mapCalculatedEntityToLocation() {
        this.isloaderForMappingEntity = true;
        const mapCalculatedEntitySubscription = forkJoin(
            this.newLocationIds.map((newLocationId) =>
                this.calculatedEntitiesService.mapEntityToLocation(
                    this.calculatedEntityId,
                    this.customerId,
                    this.locationId,
                    newLocationId,
                ),
            ),
        ).subscribe(
            () => {
                this.snackBar.raiseNotification(this.mapLocationSuccessMessage, this.dismissBtn, {
                    duration: this.duration,
                });
                this.renderSection.emit(false);
                this.isloaderForMappingEntity = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.snackBar.raiseNotification(this.mapLocationFailureMessage, this.dismissBtn, {
                }, false);
                this.isloaderForMappingEntity = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(mapCalculatedEntitySubscription);
    }

    public getLocationIdForMap(mapLocationId) {
        this.newLocationIds = mapLocationId.value;
    }

    public enableAddMore() {
        this.isRowFilled = !this.selectedItems.some(
            (x) => x.locationId === -1 || x.entityId === -1 || x.variableName === '',
        );
    }

    public addMoreRow() {
        this.selectedItems.push({
            locationId: -1,
            entityId: -1,
            variableName: '',
            orderId: !this.selectedItems ? 1 : this.selectedItems.length + 1,
            manningRoughness: 0,
            manningSlope: 0,
            depthEntityId: 5,
            timeInterval: this.timeInterval,
        });
        this.isRowFilled = false;

        this.equationChanged();
    }

    public removeRow(index: number) {
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        }

        this.enableAddMore();

        this.equationChanged();
    }

    private saveEquationForCalculatedEntity() {
        const applyValidatedSubscription = this.calculatedEntitiesService
            .saveCalculatedEntityWithEquation(this.calcEntity, this.customerId, this.locationId)
            .subscribe(
                (response) => {
                    this.onEquationUpdated(true, response.payload);
                    this.successMessage = `${this.calcEntity.name.toUpperCase()} ${
                        this.successMessageCalculatedEntity
                    }`;
                },
                (error) => {
                    this.handleError(error);
                    this.onEquationUpdated(false);
                },
            );
        this.subscriptions.push(applyValidatedSubscription);
    }

    private updateEqutionForCalculatedEntity() {
        // If any dates happen to have been previously saved, remove them
        delete this.calcEntity.startDate;
        delete this.calcEntity.endDate;

        const applyValidatedSubscription = this.calculatedEntitiesService
            .updateCalculatedEntity(this.calcEntity, this.customerId, this.locationId)
            .subscribe(
                () => {
                    this.onEquationUpdated(true, this.data.calculatedEntityId);
                    this.successMessage = `${this.calcEntity.name.toUpperCase()} ${this.updateMessageCalculatedEntity}`;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.handleError(error);
                    this.onEquationUpdated(true);
                },
            );
        this.subscriptions.push(applyValidatedSubscription);
    }

    private onEquationUpdated(success: boolean, entityId?: number) {
        this.close();
        this.isLoading = false;
        // to be uncommented when 10937 or 13991 are completed
        // this.isEntitySaved = success;
        // this.hideCreateArea = success;
        this.isCalculatedEntitySaved = success;
        if (entityId !== undefined) {
            this.calculatedEntityId = entityId;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public equationChanged() {
        this.isFormulaValidated = false;
    }

    public validateEquation(isApplyButton?: boolean) {
        // ensure end date is greater
        // if (this.invalidDateRange) {
        //   this.snackBar.raiseNotification(this.invalidDateRangeMessage, this.dismissBtn, {
        //     duration: this.duration
        //   });
        //   this.invalidEndDate = true;
        //   return;
        // }
        this.isLoading = true;
        const completeEquation = this.calcEntity.equation;
        const equationValidateSubscription = this.calculatedEntitiesService
            .validateEquation(
                completeEquation,
                this.selectedItems.map((x) => x.variableName),
            )
            .subscribe(
                (equation) => {
                    if (isApplyButton) {
                        // saving the calculated entities.
                        this.calcEntity.calculatedEntityId
                            ? this.updateEqutionForCalculatedEntity()
                            : this.saveEquationForCalculatedEntity();
                    } else {
                        this.snackBar.raiseNotification(equation.message, this.dismissBtn, {
                            duration: this.duration,
                        });
                        this.isLoading = false;
                        this.isFormulaValidated = !equation.isError;
                        isApplyButton = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                },
                (error) => {
                    this.handleError(error);
                    this.isLoading = false;
                    this.isFormulaValidated = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );
        this.subscriptions.push(equationValidateSubscription);
    }

    public handleError(error) {
        if (error.statusText.includes('Conflict')) {
            this.snackBar.raiseNotification(error.message, this.dismissBtn, {
                panelClass: CUSTOM_SNACKBAR_ERROR,
            }, false);
        }
    }

    /**
     * Close dialoge box method
     * @param permissionsUpdated
     */
    public close(permissionsUpdated?: boolean) {
        this.renderSection.emit(false);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Method to set final formulaentities object to be sent in API call
     * object modified based on the formula type
     */
    public setCalculationEntityObject() {
        if (this.showFormula) {
            this.calcEntity.formulaEntities = this.selectedItems;
        } else {
            // assigning location id as current location for non-formulaic types
            this.selectedItems[0].locationId = this.locationId;
            this.calcEntity.formulaEntities = this.selectedItems;
        }
    }

    public applyTranslations() {
        const translationKeys: Array<string> = [
            'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.VALIDATE_EQUATION_WARNING_MESSAGE',
            'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ENTITY_SUCCESS',
            'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ENTITY_UPDATE',
            'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FILL_REQUIRED_WARNING_MESSAGE',
            'CALCULATED_ENTITY_DIALOG.MAP_LOCATION_TO_CALCULATED_ENTITY_SUCCESS',
            'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.INVALID_DATE_RANGE_WARNING_MESSAGE',
            'CALCULATED_ENTITY_DIALOG.MAP_LOCATION_TO_CALCULATED_ENTITY_FAILURE',
            'COMMON.INVALID_DATE_TEXT_MESSAGE',
            'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE',
            'CALCULATED_ENTITY_DIALOG.INVALID_MANNING_ROUGHNESS_NOTIFICATION_MESSAGE',
            'CALCULATED_ENTITY_DIALOG.INVALID_SLOPE_NOTIFICATION_MESSAGE',
            'CALCULATED_ENTITY_DIALOG.INVALID_STEP_INTERVAL_MINUTES_NOTIFICATION_MESSAGE',
        ];

        const calculationTypeCollectionTranslations: Array<string> = [
            'CALCULATED_ENTITY_DIALOG.FORMULA_CALCULATION',
            'CALCULATED_ENTITY_DIALOG.CUMULATIVE_CALCULATION',
            'CALCULATED_ENTITY_DIALOG.DELTA_CALCULATION',
            'CALCULATED_ENTITY_DIALOG.MANNING_CALCULATION',
            'CALCULATED_ENTITY_DIALOG.AVERAGE_CALCULATION',
            'CALCULATED_ENTITY_DIALOG.INTERPOLATE_CALCULATION',
            'CALCULATED_ENTITY_DIALOG.STEP_CALCULATION',
        ];

        this.translate.get(translationKeys).subscribe((values) => {
            this.validationMessage =
                values['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.VALIDATE_EQUATION_WARNING_MESSAGE'];
            this.successMessageCalculatedEntity =
                values['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ENTITY_SUCCESS'];
            this.updateMessageCalculatedEntity = values['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ENTITY_UPDATE'];
            this.requiredFieldsMessage =
                values['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FILL_REQUIRED_WARNING_MESSAGE'];
            this.mapLocationSuccessMessage =
                values['CALCULATED_ENTITY_DIALOG.MAP_LOCATION_TO_CALCULATED_ENTITY_SUCCESS'];
            // this.invalidDateRangeMessage = values['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.INVALID_DATE_RANGE_WARNING_MESSAGE'];
            this.mapLocationFailureMessage =
                values['CALCULATED_ENTITY_DIALOG.MAP_LOCATION_TO_CALCULATED_ENTITY_FAILURE'];
            // this.invalidDateMessage = values['COMMON.INVALID_DATE_TEXT_MESSAGE'];
            this.invalidEntitySelectedMessage = values['CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE'];
            this.invalidManningRoughnessText =
                values['CALCULATED_ENTITY_DIALOG.INVALID_MANNING_ROUGHNESS_NOTIFICATION_MESSAGE'];
            this.invalidManningSlopeText = values['CALCULATED_ENTITY_DIALOG.INVALID_SLOPE_NOTIFICATION_MESSAGE'];
            this.invalidStepIntervalMessage =
                values['CALCULATED_ENTITY_DIALOG.INVALID_STEP_INTERVAL_MINUTES_NOTIFICATION_MESSAGE'];
        });

        this.translateCalculationTypeCollection(calculationTypeCollectionTranslations);
    }

    /** Method to handle event data emitted by ads-prism-single-select-group to set validity of entity/entity name manually entered by user
     * @param isEntityInvalid is used for showing error message and for validation check if entity selected is valid
     */
    public handleEntityInput(isEntityInvalid: boolean) {
        this.invalidEntitySelected = isEntityInvalid;
    }

    /** Method to set Displaygroup for edit flow and create flow based on the unit id and displaygrouplist data.
     */
    private setSelectedDisplayGroup() {
        if (this.displayGroupsList && this.displayGroupsList.length > 0) {
            this.calcEntity.displayGroupId =
                this.data &&
                this.data.displayGroupId &&
                this.displayGroupsList.some((a) => a.id === this.data.displayGroupId)
                    ? this.data.displayGroupId
                    : this.displayGroupsList[0].id;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    // Method to validate ManningRoughness Constant on input entry
    public validateManningRoughness(roughnessConstant: number) {
        this.invalidManningRoughness = !(
            roughnessConstant &&
            roughnessConstant >= CALCULATION_TYPE_DEFAULTS.MANNING_ROUGHNESS_START_RANGE &&
            roughnessConstant <= CALCULATION_TYPE_DEFAULTS.MANNING_ROUGHNESS_END_RANGE
        );
    }

    // Method to validate ManningSlope Constant on input entry
    public validateManningSlope(manningSlopeConstant: number) {
        this.invalidManningSlope = !(
            manningSlopeConstant && this.numericDecimalPattern5precision.test(manningSlopeConstant.toString())
        );
    }

    // Method to set Manning Calculation defaults on selection
    public setManningCalculationTypeDefaults() {
        this.filterDisplayGroups.push(DEPTH_DISPLAY_GROUP);
        if (this.selectedItems && this.selectedItems.length && this.selectedItems[0].entityId < 0) {
            this.selectedItems[0].entityId = DFINAL_ENTITY;
            this.selectedItems[0].manningRoughness = CALCULATION_TYPE_DEFAULTS.DEFAULT_MANNING_ROUGHNESS;
            this.selectedGroupEntity(DFINAL_ENTITY);
        }
    }

    // Method which return boolean on manning constants validation on click of apply
    public validateManningConstants(): boolean {
        if (this.invalidManningRoughness || !this.selectedItems[0].manningRoughness) {
            this.snackBar.raiseNotification(this.invalidManningRoughnessText, this.dismissBtn, {
                duration: NOTIFICATION_TIMEOUT,
            });
            return false;
        } else if (this.invalidManningSlope || !this.selectedItems[0].manningSlope) {
            this.snackBar.raiseNotification(this.invalidManningSlopeText, this.dismissBtn, {
                duration: NOTIFICATION_TIMEOUT,
            });
            return false;
        }
        return true;
    }

    // method to translate and sort calculationType collection
    public translateCalculationTypeCollection(calculationTypeCollectionTranslations: Array<string>) {
        this.translate.get(calculationTypeCollectionTranslations).subscribe((values) => {
            this.calculationTypeCollection.forEach((calculationType) => {
                if (values[calculationType.text]) {
                    calculationType.text = values[calculationType.text];
                }
            });
        });
        this.calculationTypeCollection.sort(function (a, b) {
            return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
        });
    }

    // method to validate step minutes interval
    public validateStepMinutesInterval(sampleRateMinutes) {
        !sampleRateMinutes
            ? (this.invalidStepMinutesInterval = false)
            : (this.invalidStepMinutesInterval = !Number.isInteger(Number(sampleRateMinutes)));
    }

    // public isDateRangeInvalid(): Boolean {
    //   // ensure end date is greater
    //   if (this.invalidDateRange && (!this.showManning && !this.showStep)) {
    //     this.snackBar.raiseNotification(this.invalidDateRangeMessage, this.dismissBtn, {
    //       duration: NOTIFICATION_TIMEOUT
    //     });
    //     this.invalidEndDate = true;
    //     return true;
    //   } else if ((!this.startDate || !this.endDate ) && (!this.showManning && !this.showStep)) {
    //     this.snackBar.raiseNotification(this.invalidDateMessage, this.dismissBtn, {
    //       duration: NOTIFICATION_TIMEOUT
    //     });
    //     this.invalidEndDate = true;
    //     return true;
    //   }
    //   return false;
    // }

    public pdfCalculationType(screen: string) {
        this.resetScreen();
        switch (screen) {
            case CalculationType.FORMULA:
                this.showFormula = true;
                this.calculationTypePdfUrl = ANSR_FORMULA_CALC;
                break;
            case CalculationType.CUMULATIVE:
                this.showCumulative = true;
                this.calculationTypePdfUrl = ANSR_CUMULATIVE_CALC;
                break;
            case CalculationType.MANNING:
                this.showManning = true;
                this.calculationTypePdfUrl = ANSR_MANNING_CALC;
                break;
            case CalculationType.DELTA:
                this.showDelta = true;
                this.calculationTypePdfUrl = ANSR_DELTA_CALC;
                break;
            case CalculationType.REPEAT:
                this.showRepeat = true;
                this.calculationTypePdfUrl = ANSR_REPEAT_CALC;
                break;
            case CalculationType.INTERPOLATE:
                this.showInterpolate = true;
                this.calculationTypePdfUrl = ANSR_INTERPOLATE_CALC;
                break;
            case CalculationType.AVERAGE:
                this.showAverage = true;
                this.calculationTypePdfUrl = ANSR_ROLLING_AVG_CALC;
                break;
            case CalculationType.STEP:
                this.showStep = true;
                this.calculationTypePdfUrl = ANSR_STEP_CALC;
                break;
            default:
                break;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public downloadMyFile() {
        this.calcEntity.formulaType = this.calculationTypeCollection.find((x) => x.id === this.selected).name;
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        this.pdfCalculationType(this.calcEntity.formulaType);
        link.setAttribute('href', this.calculationTypePdfUrl);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    public selectEntities(entityList: EntitySelectorEntity[], index: number) {
        if (entityList && entityList[0]) {
            this.selectedItems[index].entityId = entityList[0].id;
        }
    }
}
