<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'echo-section-error': form.invalid }">
        <mat-expansion-panel-header class="mat-expasion-header-background">
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 0">
                {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 1">
                {{ 'ADD_EDIT.MP2' | translate }} {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount
                    }}{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-50p form-input-bottom-space">
                <div class="custom-50p form-input-bottom-space">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_PHYSICAL')
                                )
                            "
                            id="PhysicalOffsetInput"
                            formControlName="physicalOffset"
                            required
                        />
                    </mat-form-field>
                </div>
                <div class="mat-error" *ngIf="form.get('physicalOffset').errors?.pattern">
                    {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                </div>
            </div>
            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_ELECTRONIC')
                                )
                            "
                            id="UltrasonicElectronicOffset"
                            formControlName="ultrasonicElectronicOffset"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('ultrasonicElectronicOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MODE' | translate }}"
                            formControlName="ultrasonicMode"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let mode of modeList; trackBy: trackByValue" [value]="mode.value">{{ mode.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_BLANKING' | translate }}"
                            formControlName="ultrasonicBlanking"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let blanking of blankingList; trackBy: trackByValue" [value]="blanking.value"
                                >{{ blanking.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="custom-50p pad-b-15">
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="ultrasonicStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="ultrasonicDisableSurchargeDetection">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_SURCHARGE' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="input-text mt-15">
                {{ 'ADD_EDIT.ADD_EDIT_TEXT' | translate }}
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="temperatureStoreData">
                        {{ 'ADD_EDIT.ACTIVE_TOGGLE_STORE' | translate }}
                    </mat-slide-toggle>
                </div>

                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSOR' | translate }}"
                            formControlName="temperatureSensor"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let item of sensorsList; trackBy: trackByValue" [value]="item.value">
                                {{ item.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
