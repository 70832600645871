<div class="setUpStudy study-tab">
    <app-loader [isLoading]="isLoadingState"></app-loader>
    <div class="custom-vertical-tab">
        <mat-tab-group (selectedTabChange)="checkMonitorDistributionMethod(); tabChanged($event)" class="ink-bar-none">
            <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_BASIN_NETWORK' | translate }}" [disabled]="updatesWidgetService.updates">
                <div class="summary-details">
                    <p>{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_BASIN_LABEL' | translate }}</p>
                    <div class="invalid-err-msg pull-left" *ngIf="invalidBasinText?.length > 0">
                        <ul>
                            <li *ngFor="let text of invalidBasinText; trackBy: trackByIndex">
                                {{ text }}
                            </li>
                        </ul>
                    </div>
                    <div class="basin-wrapper flex full-width">
                        <div id="basinTable">
                            <label>{{ 'SLIICER_TABLE.SLICER_SUMMARY.SELECT_BASIN' | translate }}</label>
                            <div class="tab">
                                <mat-table #table [dataSource]="basinDataSource" matSort (matSortChange)="sortData($event)">
                                    <ng-container matColumnDef="changedName">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                            'SLIICER_TABLE.SLICER_SUMMARY.BASINS' | translate
                                        }}</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <!-- #33460 Disable change name field-->
                                            <span>{{element.changedName}}</span>
                                            <!-- <mat-form-field>
                                                <input
                                                    matInput
                                                    [(ngModel)]="element.changedName"
                                                    [debounce]="debounceData"
                                                    (debounced)="basinDataChange()"
                                                    required
                                                    (ngModelChange)="onNameChange()"
                                                />
                                            </mat-form-field> -->
                                            <!-- end of #33460 Disable change name field-->
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="outletFlow">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                            'SLIICER_TABLE.SLICER_SUMMARY.OUTLET_FLOW_MONITOR' | translate
                                        }}</mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{ element.outletFlow }} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="upStream">
                                        <mat-header-cell *matHeaderCellDef>{{
                                            'SLIICER_TABLE.SLICER_SUMMARY.UPSTREAM_BASINS' | translate
                                        }}</mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{ element.upStream }} </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="basinColumns"></mat-header-row>
                                    <mat-row
                                        *matRowDef="let row; columns: basinColumns"
                                        [ngClass]="{ highlight: row === selectedBasinRow }"
                                        (click)="selectBasin(row)"
                                    ></mat-row>
                                </mat-table>
                            </div>
                        </div>
                        <div *ngIf="selectedBasin?.length > 0" class="upstream-wrapper">
                                <label>
                                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.SELECT_UPSTREAM_BASIN' | translate }} |
                                    {{ 'COMMON.SELECTED' | translate }} {{ selectedUpstreamBasins?.length }} /
                                    {{ filteredUpstreamBasins.length }}</label
                                >
                                <div class="pull-right select-options">
                                    <span
                                        class="custom-btn-color"
                                        (click)="isStudyLocked ? null : clearAll()"
                                        [class.disabled]="!selectedUpstreamBasins?.length || isStudyLocked"
                                        >{{ 'COMMON.CLEAR_ALL' | translate }}</span
                                    >
                                </div>
                                <mat-selection-list
                                    [(ngModel)]="selectedUpstreamBasins"
                                    name="selectUpstreamBasins"
                                    [disabled]="isStudyLocked"
                                    [class.cursor-not-allowed]="isStudyLocked"
                                    (ngModelChange)="selectUpstreamBasins($event)"
                                    #selectUpstream
                                >
                                    <mat-list-option
                                        *ngFor="let upstreamBasin of filteredUpstreamBasins; trackBy: trackByIndex"
                                        [value]="upstreamBasin"
                                        >{{ upstreamBasin }}</mat-list-option
                                    >
                                </mat-selection-list>
                            </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_GEOMETRY' | translate }}" [disabled]="updatesWidgetService.updates">
                <div class="summary-details">
                    <div id="geometry-table" class="fixCellWidth30">

                        <input type="file" name="" id="fileInput" (change)="uploadFile($event.target.files)" class="file-display">
                        <div class="custom-flat-button-cap left-align">
                            <button class="mat-button" [disabled]="isStudyLocked" (click)="importBasinProperties()" >
                                {{
                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_IMPORT_BASIN' | translate
                                    }}
                            </button>
                        </div>

                        <mat-table #geometrytable [dataSource]="geometryDataSource" matSort>
                            <ng-container matColumnDef="Basinname">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'SLIICER_TABLE.SLICER_SUMMARY.GEOMETRY.BASIN_NAME' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <label>{{ element.name }}</label>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Area(ac)">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'SLIICER_TABLE.SLICER_SUMMARY.GEOMETRY.AREA' | translate }} ( {{ areaUnit }} )
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            step="any"
                                            class="text-right"
                                            [(ngModel)]="element.area"
                                            (ngModelChange)="element.area = $event"
                                            (keyup)="onPropertyInputChange(element, 'area')"
                                            [debounce]="debounceData"
                                            (debounced)="geometryChange()"
                                            name="area{{ i + 1 }}"
                                            type="number"
                                            [disabled]="isStudyLocked"
                                        />
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="SewerLength(ft)">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'SLIICER_TABLE.SLICER_SUMMARY.GEOMETRY.SEWER_LENGTH' | translate }} (
                                    {{ lengthUnit }} )
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            step="any"
                                            class="text-right"
                                            [(ngModel)]="element.length"
                                            (ngModelChange)="element.length = $event"
                                            (keyup)="onPropertyInputChange(element, 'length')"
                                            [debounce]="debounceData"
                                            (debounced)="geometryChange()"
                                            type="number"
                                            [disabled]="isStudyLocked"
                                        />
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Footprint(in-ml)">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'SLIICER_TABLE.SLICER_SUMMARY.GEOMETRY.FOOT_PRINT' | translate }} (
                                    {{ footprintUnit }} )
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            step="any"
                                            class="text-right"
                                            [(ngModel)]="element.footprint"
                                            (ngModelChange)="element.footprint = $event"
                                            (keyup)="onPropertyInputChange(element, 'footprint')"
                                            [debounce]="debounceData"
                                            (debounced)="geometryChange()"
                                            type="number"
                                            [disabled]="isStudyLocked"
                                        />
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Centroid Latitude">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'SLIICER_TABLE.SLICER_SUMMARY.GEOMETRY.CENTROID_LATITUDE' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            [(ngModel)]="element.centroid.latitude"
                                            [debounce]="debounceData"
                                            (debounced)="geometryChange()"
                                            [disabled]="isStudyLocked"
                                            type="number"
                                            step="any"
                                            (ngModelChange)="element.centroid.latitude = $event"
                                            (keydown)="onLonLatInput($event)"
                                            (keyup)="onPropertyInputChange(element.centroid, 'latitude')"
                                        />
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Centroid Longitude">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'SLIICER_TABLE.SLICER_SUMMARY.GEOMETRY.CENTROID_LONGITUDE' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            [(ngModel)]="element.centroid.longitude"
                                            [debounce]="debounceData"
                                            (debounced)="geometryChange()"
                                            [disabled]="isStudyLocked"
                                            type="number"
                                            step="any"
                                            (ngModelChange)="element.centroid.longitude = $event"
                                            (keydown)="onLonLatInput($event)"
                                            (keyup)="onPropertyInputChange(element.centroid, 'longitude')"
                                        />
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="geometryColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: geometryColumns"></mat-row>
                        </mat-table>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_RAINFALL_DISTRIBUTION' | translate }}" [disabled]="updatesWidgetService.updates">
                <div class="summary-details">
                    <mat-form-field class="field-width">
                        <mat-label
                            >{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.SELECT_RAINFALLMONITOR' | translate }}
                        </mat-label>
                        <mat-select
                            [(ngModel)]="monitorDistributionMethodValue"
                            (ngModelChange)="checkMonitorDistributionMethod()"
                            [disabled]="isStudyLocked"
                        >
                            <mat-option
                                *ngFor="let method of methodValues; trackBy: trackByIndex"
                                [value]="method.name"
                                [disabled]="method.disabled"
                                [matTooltip]="getDistributionMethodError(method)"
                            >
                                {{ method.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <input type="file" name="" id="bFileInput" (change)="uploadBfile($event.target.files)" class="file-display">
                        <span class="custom-flat-button-cap basin-left-align">
                            <button class=" mat-button" [disabled]="isStudyLocked" (click)="importBasinRainfallDistribution()" >
                                {{
                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_IMPORT_BASIN_RAINFALL' | translate
                                    }}
                            </button>
                        </span>

                    <div class="no-coords-error custom-error" *ngIf="showNoCoordsOnRGsError">
                        <span class="custom-error-message">{{ noCoordsOnRGsErrorText }}</span>
                    </div>
                    <div id="rainfall-distribution-table">
                        <fm-pivot
                            #pivot
                            [toolbar]="true"
                            [report]="report"
                            (datachanged)="fmDataChanged($event)"
                            (beforetoolbarcreated)="customizeToolbar($event)"
                            [licenseKey]="license"
                            >
                        </fm-pivot>
                    </div>

                    <div>
                        <p *ngIf="monitorDistributionMethodValue === MONITOR_DISTRIBUTION_METHODS_ENUM.UserDefined">
                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DISTRIBUTION_INFO' | translate }}
                        </p>

                        <p *ngIf="monitorDistributionMethodValue === MONITOR_DISTRIBUTION_METHODS_ENUM.Closest">
                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DISTRIBUTION_CLOSEST_INFO' | translate }}
                        </p>

                        <p *ngIf="monitorDistributionMethodValue === MONITOR_DISTRIBUTION_METHODS_ENUM.InverseDistance">
                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DISTRIBUTION_INVERSE_INFO' | translate }}
                        </p>

                        <p *ngIf="monitorDistributionMethodValue === MONITOR_DISTRIBUTION_METHODS_ENUM.InverseDistanceSquared">
                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DISTRIBUTION_INVERSE_SQ_INFO' | translate }}
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ 'COMMON.EXPORT_BTN' | translate }}">
                <div class="summary-details w-61">
                    <div class="export-item">
                        <div class="export-title">
                            <h3>{{ 'SLIICER_TABLE.SLICER_SUMMARY.EXPORT_BASIN_PROPERTIES' | translate }}</h3>
                        </div>
                        <div class="stepper-action text-right padd-right-20">
                            <div class="auto-margin custom-Flat-Button">
                                <button
                                    class="mat-button min-width"
                                    (click)="exportBasinProperties()"
                                    mat-button
                                >
                                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.EXPORT_BTN' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
