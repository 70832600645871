import { OnInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import {
    TritonSensorPower,
    TritonSensorTemp,
    VelocityList,
} from 'app/shared/components/location-card/location-card.constants';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { TrackBy } from 'app/shared/utils/track-by';

const ECHO_GAIN_VALUE = 840;
const _WEIR = 'Weir';

@Component({
    selector: 'app-add-edit-location-long-range-depth-sensor',
    templateUrl: './add-triton-location-long-range-depth-sensor.component.html',
    styleUrls: ['./add-triton-location-long-range-depth-sensor.component.scss'],
})
export class AddTritonLocationLongRangeDepthSensorComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;
    public tritonSensorTemp = TritonSensorTemp;
    public powerList = TritonSensorPower(false);
    public gainControlList = VelocityList;
    public isMonitorEditor = false;
    public gainValues: number[] = [];
    public isMetric: boolean;

    public trackByValue = TrackBy.byValue();
    public trackByIndex = TrackBy.byIndex;
    constructor(
        public translateService: TranslateService,
        public locationCardService: LocationCardService,
        private usersService: UsersService,
    ) {}

    public ngOnInit() {
        this.isMetric = this.locationCardService.isMetric;
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        if (this.monitorConfigs) {
            this.monitorConfigs['physicalOffset'] = this.monitorConfigs.physicaloffset;
            this.form.patchValue(this.monitorConfigs);

            const formValue = this.form.getRawValue();
            formValue.gainControlSelection =
                formValue.gainControl === 840 ? this.gainControlList[0].text : this.gainControlList[1].text;
            
            this.onChangeGainControl({ value: formValue.gainControlSelection } as any);
            this.form.patchValue({ ...formValue });
        }

        if (this.form.get('gainControlSelection').value === this.gainControlList[0].text) {
          this.gainValues.push(840)
        }
        for(let i = -24; i <= 24; i ++) {
            this.gainValues.push(i);
        }
    }

    public onChangeGainControl(event: MatSelectChange) {
        const { value } = event;

        if (value === this.gainControlList[1].text) {
            this.gainValues = this.gainValues.filter(v => v !== 840);
            this.form.get('gainControl').enable();
        } else {
            this.form.patchValue({ gainControl: ECHO_GAIN_VALUE });
            this.gainValues.push(840);
            this.form.get('gainControl').disable();
        }
    }
}
