<div *ngIf="state" class="select-method custom-settings">
    <mat-form-field class="select-method-list">
        <mat-select
            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SELECT_SEASON_TYPE' | translate }}"
            name="season"
            [disabled]="isStudyLocked"
            [ngModel]="state.seasonType"
            (selectionChange)="seasonTypeChanged($event.value)"
        >
            <mat-option *ngFor="let option of seasonTypes; trackBy: trackByIndex" [value]="option">
                {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <seasons-bar
        *ngIf="state.seasons | asSome; let seasons"
        [startDate]="caseStudyDetails.config.startDate"
        [endDate]="caseStudyDetails.config.endDate"
        [seasons]="seasons.value"
        [seasonType]="state.seasonType"
    >
    </seasons-bar>
    <form novalidate #seasonsForm="ngForm">
        <div *ngIf="state.customSeasonsForm | asSome; let form" class="custom-50p custom-date-selection column">
            <div class="note">
                {{ 'SLIICER.EDIT_SETTINGS.SEASONS.NOTE_SEASONS_CHRONOLOGICAL_ORDER' | translate }}
            </div>
            <div
                *ngFor="let item of form.value; trackBy: trackByIndex; index as i; first as isFirst"
                class="season-start-content row"
            >
                <mat-form-field class="width-80-percent form-item">
                    <input
                        matInput
                        required
                        [ngModel]="item.name.raw"
                        [eitherValidation]="item.name.val"
                        (ngModelChange)="customSeasonNameChanged(item, $event)"
                        name="{{ 'name' + i }}"
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_SEASON_NAME' | translate }}"
                    />
                    <mat-error *ngIf="item.name.val | asLeft; let error">{{ error.value | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-item">
                    <mat-select
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_SEASON_START_MONTH' | translate }}"
                        required
                        [ngModel]="item.start.raw.month"
                        [eitherValidation]="item.start.val"
                        name="{{ 'startMonth' + i }}"
                        (ngModelChange)="customSeasonStartMonthChanged(item, $event)"
                    >
                        <mat-option *ngFor="let m of months; trackBy: trackByIndex" [value]="m.month">{{ m.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="item.start.val | asLeft; let error">{{ error.value | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-item">
                    <mat-select
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_SEASON_START_DAY' | translate }}"
                        required
                        [ngModel]="item.start.raw.day"
                        name="{{ 'startDay' + i }}"
                        [eitherValidation]="item.start.val"
                        (ngModelChange)="customSeasonStartDayChanged(item, $event)"
                    >
                        <mat-option *ngFor="let d of days[item.start.raw.month]; trackBy: trackByIndex" [value]="d">{{ d }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" class="mat-button" (click)="removeCustomSeason(item)">
                    <mat-icon matTooltip="{{ 'COMMON.DELETE_SEASON' | translate }}">clear</mat-icon>
                </button>
            </div>

            <div class="error" *ngIf="error">
                <div>{{ 'COMMON.ERRORS' | translate }}:</div>
                <div></div>
                <div *ngIf="atLeastTwoCustomSeasonsError">{{ 'SLIICER.EDIT_SETTINGS.SEASONS.AT_LEAST_2_SEASONS_REQUIRED' | translate }}</div>
                <div *ngIf="seasonsChronologicalOrderError">{{ 'SLIICER.EDIT_SETTINGS.SEASONS.SEASONS_CHRONOLOGICAL_ORDER' | translate }}</div>
                <div *ngIf="maxSeasonsLimitError">{{ 'SLIICER.EDIT_SETTINGS.SEASONS.SEASONS_MAX_LIMIT' | translate }}</div>
                <div *ngIf="uniqueNamesError">{{ 'SLIICER.EDIT_SETTINGS.SEASONS.SEASON_NAME_UNIQUE' | translate }}</div>
                <div *ngIf="emptyNamesError">{{ 'SLIICER.EDIT_SETTINGS.SEASONS.SEASON_NAME_REQUIRED' | translate }}</div>
                <div *ngIf="emptyStartDatesError">{{ 'SLIICER.EDIT_SETTINGS.SEASONS.SEASON_START_DATE_REQUIRED' | translate }}</div>
            </div>

            <div class="actions">
                <div class="add">
                    <button type="button" [disabled]="isStudyLocked" mat-fab matTooltip="{{ 'COMMON.ADD_NEW_SEASON' | translate }}" (click)="addCustomSeason()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <mat-error *ngIf="formError" class="custom-error">{{ formError | translate }}</mat-error>
    <table *ngIf="state.displaySeasons | asSome; let displaySeasons" mat-table [dataSource]="displaySeasons.value">
        <!-- Type Column -->
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Season</th>
            <td mat-cell *matCellDef="let element">{{ element.season }}</td>
        </ng-container>

        <!-- Start Date Column -->
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Start Date</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.startDiffers" [ngClass]="'second-col-icon'">fiber_manual_record</mat-icon>
                <span class="second-col-span">{{ element.periodStart }}</span>
            </td>
        </ng-container>

        <!-- End Date Column -->
        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>End Date</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.endDiffers" [ngClass]="'third-col-icon'">fiber_manual_record</mat-icon>
                <span class="third-col-span">{{ element.periodEnd }}</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
