<div id="DailyRainReportPageId" class="contentLayout">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin">{{ 'REPORT.DAILY_RAINFALL_REPORT.TITLE' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button mat-button (click)="downloadReport()" [disabled]="!reportData || reportData.length === 0">
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <app-loader [isLoading]="isLoading"></app-loader>


            <div>
                <div class="custom-50p">
                    <div class="each-Item">
                        <mat-form-field class="full-width">
                            <mat-select
                                placeholder="{{ 'COMMON.TYPE_PLACEHOLDER' | translate }} *"
                                #locationTypeInput="ngModel"
                                [(ngModel)]="locationType"
                                (selectionChange)="handleLocationTypeChange($event)"
                                name="typedlocation"
                            >
                                <mat-option *ngFor="let location of locationTypeCollection; trackBy: trackByValue" [value]="location.value">
                                    {{ location.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="each-Item" [hidden]="locationType !== 'locationGroup'">
                        <app-auto-complete
                            [items]="locationGroupList"
                            [preselectedItem]="[]"
                            [isShowAll]="false"
                            (selectedItems)="handleSelectedLocationsGroup($event)"
                            placeholder="{{ 'COMMON.SELECT_LOCATION_GROUP' | translate }} *"
                        >
                        </app-auto-complete>
                        <!-- <div class="mat-error" *ngIf="isLocationGroupInvalid && !locationGroupsUnavailable">
                            <span>{{ 'FLOW_MONITORING.LOCATIONGROUP_INVALID_MESSAGE' | translate }}</span>
                        </div> -->
                        <div class="mat-error" *ngIf="locationGroupsUnavailable">
                            <span>{{ 'FLOW_MONITORING.LOCATIONGROUP_UNAVAILABLE_MESSAGE' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mat-error" *ngIf="locationsUnavailable && locationType === 'location'">
                        <span>{{ 'FLOW_MONITORING.LOCATIONS_UNAVAILABLE_MESSAGE' | translate }}</span>
                    </div>
                    <div class="custom-Section margin-b-10" *ngIf="!locationsUnavailable">
                        <ng-container *ngIf="locationType === 'location'">
                        <mat-checkbox
                            class="each-location-item auto-margin"
                            (change)="onSelectAllLocations($event)"
                            [checked]="selectAllLocations"
                            name="selectallcheckbox"
                            ngDefaultControl
                            [hidden]="!locations.length"
                        >
                            {{ 'COMMON.SELECT_All_OPTION' | translate }}
                        </mat-checkbox>
                        </ng-container>
                        <span class="app-flex-filler"></span>
                        <div class="auto-margin search-section">
                            <span class="each-Item includeLocations fmrHintIncludeInactiveLocations">
                                <mat-slide-toggle
                                    class="toggle"
                                    [(ngModel)]="shouldIncludeInactiveLocations"
                                    (change)="getLocations(true)"
                                >
                                </mat-slide-toggle>
                                <span>Include Inactive Locations</span>
                            </span>
                            <ng-container *ngIf="locationType === 'location'">
                            <mat-form-field class="half-width fmrHintSearch">
                                <mat-icon matPrefix class="search-icon">search</mat-icon>
                                <input
                                    class=""
                                    matInput
                                    type="text"
                                    placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                                    [formControl]="locationSearch"
                                />
                            </mat-form-field>
                        </ng-container>
                        </div>
                    </div>
                    <div class="customMessage" *ngIf="minimumLocationError">
                        <span class="mat-error">{{ 'COMMON.SECARCH_LOCATION_ERROR' | translate }}</span>
                    </div>
                    <div class="custom-SectionList margin-b-10 fmrHintLocationGroupList" *ngIf="!locationsUnavailable && locationType === 'location'">
                        <div class="each-location-item" *ngFor="let location of filteredLocations; trackBy: trackById">
                            <mat-checkbox
                                [checked]="location.isChecked"
                                name="location.locationName"
                                [(ngModel)]="location.isChecked"
                                (change)="onLocationChecked()"
                            >
                                <span>{{ location.name }}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Date Range and Entities -->
            <div class="custom-50p date-range-wrapper">
                <div class="each-Item custom-error fmrHintDateRange">
                    <app-date-range-picker
                        class="full-width"
                        [buttonClasses]="['full-width', 'datepicker-escape-margin']"
                        [startDate]="startDate"
                        [endDate]="endDate"
                        [dateRangeType]="'date'"
                        [hideHours]="true"
                        (emitDateChanged)="onDateRangeChange($event)"
                        [maxDate]="today"
                        [oneWeekDoNotIncludeToday]="true"
                        [(selectedTimespan)]="selectedTimespan"
                    ></app-date-range-picker>
                </div>
            </div>

            <div class="rain-entity-select-wrapper">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="selectedEntityId"
                        (selectionChange)="entityChange($event)"
                        name="typedlocation"
                    >
                        <mat-option *ngFor="let entity of rainEntities; trackBy: trackById" [value]="entity.id">
                            {{ entity.name }} ({{ rainUnit }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="table-wrapper tableScrollWraper mh-800">
                <table class="light-table light-table-bordered light-table-hover light-table-responsive-vertical black-theme-table"
                    *ngIf="reportData.length">

                    <thead>
                        <th class="text-align-center rain-header" *ngFor="let item of reportHeaders; let i = index; trackBy: trackByIndex"
                            [ngClass]="{'sticky sticky-header': i === 0}">
                            {{ item }}
                        </th>
                    </thead>

                    <tbody>
                        <tr *ngFor="let row of reportData; trackBy: trackByIndex">
                            <td class="rain-colomn" *ngFor="let item of row; let i = index; trackBy: trackByIndex" [ngClass]="{'sticky sticky-col': i === 0}">
                                {{ item }}
                            </td>
                        </tr>
                        <tr>
                            <td class="total-rain-column" *ngFor="let item of reportTotals; let i = index; trackBy: trackByIndex" [ngClass]="{'sticky sticky-col': i === 0}">
                                <ng-container *ngIf="i === 0">
                                    {{ item | uppercase }} ({{ rainUnit }})
                                </ng-container>
                                <ng-container *ngIf="i !== 0">
                                    {{ item }}
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="no-data-found-section dont-print" *ngIf="!reportData || reportData.length === 0 && noDataError">
                    <div class="no-data-content">
                        <div class="no-data-found-img"></div>
                        <div class="no-data-found-text custom-header">
                            <span>{{ 'REPORT.NO_DATA_MESSAGE' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>
</div>
