<div
    id="location-dashbord-filter"
    class="flex-container"
    [ngClass]="{ 'data-edit-mode-filter': isDataEditingEnabled }"
>
    <div
        class="full-width dont-print"
        *ngIf="selectableLocations"
        [ngClass]="{ 'mt-100': (separateWindowHydrographService.isNewTab && selectedHintPageName === 'locationDashboardHint') }"
    >
        <app-location-selection-bar
            [hidden]="isDataEditingEnabled"
            [selectableLocations]="selectableLocations"
            [disabled]="hasDateOrEntityError || locationId === 0"
            [filterSettings]="filterSettings"
            [locationId]="locationId"
            (emitChangeLocation)="onChangeLocation($event)"
            [customerId]="customerId"
            (exportTelemetryData)="exportTelemetryData.emit($event)"
            (printDashboard)="printDashboard.emit($event)"
            (showHideAnnotation)="showHideAnnotation($event)"
        >
        </app-location-selection-bar>
        <div
            class="full-width location-info-bar border-radius-5 mat-box-shadow"
            [ngClass]="{ 'edit-mode': isDataEditingEnabled }"
        >
            <!-- Data Filter Bar -->
            <div
                class="flex-container location-info-bar-controls"
                [ngClass]="{ 'mb-150': (selectedHintPageName === 'locationDashboardHint' || selectedHintPageName === 'locationDashboardSecondHint') }"
            >
                <!-- Location Name -->
                <div class="filter-item location-info location-name-container">
                    <div class="popover" [class.active-popover]="showPopover">
                        <app-site-name-popover *ngIf="popoverDetails"
                            [isMetric]="isMetric" [details]="popoverDetails"
                            [customerId]="customerId" [locationGroupID]="locationGroupId" [includeInactiveLocations]="includeInactiveLocations"
                        ></app-site-name-popover>
                    </div>
                    <a
                        (mouseenter)="togglePopover(true)"
                        (mouseleave)="togglePopover(false)"
                        *ngIf="!isDataEditingEnabled; else editModeLocationName"
                        [routerLink]="['/pages/viewLocationDetails']"
                        class="ld-selected-hint"
                        [queryParams]="{
                            c: customerId,
                            lid: selectedLocation?.lid,
                            lg: locationGroupId,
                            lt: includeInactiveLocations ? 1 : 0,
                            rc: 0
                        }"
                    >{{ selectedLocation?.n }}</a>
                    <mat-icon
                        class="add-other-data-icon"
                        matTooltip="{{ 'LOCATION_DASHBOARD.OTHER_DATA_TOOLTIP' | translate }}"
                        (click)="openOtherDataDialog()"
                        *ngIf="!isDataEditingEnabled"
                    >add_location
                    </mat-icon>
                    <ng-template #editModeLocationName>
                        <img [ngSrc]="'/assets/images/edit_mode.gif'" height="50" width="50" />
                        <strong>EDIT MODE</strong> - {{ locationName }}
                    </ng-template>
                </div>

                <!-- Monitor Series -->
                <div
                    class="filter-item titled"
                    *ngIf="!isDataEditingEnabled"
                >
                    <div class="title ld-series-hint">Monitor Series</div>
                    {{ selectedLocation?.s ? selectedLocation?.s : isUSGS && isUSGS.getValue() ? 'USGS' : '' }}
                </div>

                <!-- Rain Gauge -->
                <div
                    class="filter-item titled"
                    *ngIf="!isDataEditingEnabled"
                >
                    <div class="title ld-rain-hint">Assigned Rain Gauge</div>
                    <a
                        *ngIf="rainGaugeId"
                        [routerLink]="['/pages/viewLocationDetails']"
                        [queryParams]="{
                            c: customerId,
                            lid: rainGaugeId,
                            refId: selectedLocation?.lid,
                            lg: locationGroupId,
                            lt: includeInactiveLocations || 0
                        }"
                    >
                        {{ getLocationName(rainGaugeId) }}</a>
                </div>

                <!-- Date Range-->
                <div class="filter-item titled">
                    <div class="title ld-date-hint">Date Range</div>
                    {{ startDate | date: customerDateFormat }} - {{ endDate | date: customerDateFormat }}
                </div>

                <!-- Entities -->
                <div class="filter-item titled ellipsable ld-entity-hint">
                    <div class="title">Entities</div>
                    <span>{{ selectedEntityNames }}</span>
                </div>

                <!-- Editors -->
                <div
                    class="filter-item editors-button-wrapper"
                    *ngIf="isDataEditingEnabled"
                >
                    <button
                        mat-button
                        class="editors-button"
                        [matMenuTriggerFor]="editorsMenu"
                    >
                        <span class="editors-text">
                            {{ 'LOCATION_DASHBOARD.EDITORS' | translate }}
                            <mat-icon>arrow_drop_down</mat-icon>
                        </span>
                    </button>
                    <mat-menu #editorsMenu="matMenu">
                        <button
                            mat-menu-item
                            (click)="openRollbackEditor()"
                            [disabled]="!customerHasAdvancedEditPermission"
                        >
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.TITLE' | translate }}
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="!customerHasAdvancedEditPermission"
                            (click)="openBlockDataEditor()"
                        >
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BLOCK_DATA_EDITOR' | translate }}
                        </button>
                    </mat-menu>
                </div>

                <!-- Worker Button -->
                <div class="filter-item worker-icon">
                    <button
                        class="external-link ld-work-hint"
                        mat-button
                        matTooltip="Link to external Work Order application"
                        [disabled]="!workOrderLink"
                        (click)="openCustomerLink()"
                    >
                        <img
                            [ngSrc]="'/assets/images/construction_worker.png'"
                            height="36" width="36"
                            alt="Link to external Work Order application"
                        />
                    </button>
                </div>
                <!-- Approve Button -->
                <div
                    class="filter-item loc-approve-button"
                    *ngIf="!isDataEditingEnabled && userHasPermissionOnEdit && customerHasBasicEditPermission"
                >
                    <button
                        mat-button
                        (click)="approveData()"
                        class="ld-approve-hint"
                        [disabled]="isUSGS | async"
                    >
                        <mat-icon>done</mat-icon> APPROVE DATA
                    </button>
                </div>
                <div
                    class="filter-item loc-button"
                    *ngIf="userHasPermissionOnEdit && customerHasBasicEditPermission"
                >
                    <button
                        class="editors-button"
                        mat-button
                        (click)="openLocationNotesDialog()"
                    >
                        <mat-icon>book</mat-icon> {{ 'COMMON.NOTES' | translate }}
                    </button>
                </div>
                <!-- Edit Button -->
                <div
                    class="filter-item loc-edit-button"
                    *ngIf="userHasPermissionOnEdit && customerHasBasicEditPermission"
                    [matTooltip]="
                        isComposite
                            ? ('LOCATION_DASHBOARD.CANNOT_EDIT_COMPOSITE' | translate)
                            : !isDataEditingEnabled && editingDisabled
                            ? missingPipeInformation
                            : null
                    "
                >
                    <!-- Tooltip needs to be on parent since 'mouseenter' doesn't fire on disabled component -->
                    <button
                        *ngIf="!isDataEditingEnabled"
                        mat-button
                        class="ld-edit-hint"
                        [disabled]="isComposite || editingDisabled"
                        (click)="dataEditingClick.emit(true)"
                    >
                        <mat-icon>edit</mat-icon> {{ 'COMMON.EDIT' | translate }}
                    </button>
                    <button
                        *ngIf="isDataEditingEnabled"
                        class="cancel-button-hint data-edit-cancel"
                        mat-stroked-button
                        (click)="closeDataEditingMode()"
                    >
                        {{ 'COMMON.CANCEL_BUTTON' | translate }}
                    </button>
                    <button
                        *ngIf="isDataEditingEnabled"
                        class="accept-button-hint data-edit-accept"
                        mat-raised-button
                        color="accent"
                        [disabled]="!enableAccept && !hasUnsavedEdits"
                        (click)="acceptDataEditing()"
                    >
                        {{ 'COMMON.ACCEPT_BTN' | translate }}
                    </button>
                </div>

                <!-- Filter Collapse -->
                <div class="filter-item setting-button">
                    <button
                        mat-button
                        class="ld-filter-hint"
                        (click)="filterAccordion.toggle()"
                    >
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </div>

                <!-- Settings Button  -->
                <div
                    class="filter-item setting-button"
                    #toggleButton
                >
                    <button
                        mat-button
                        (click)="showHideAnnotation(true)"
                        [disabled]="availablePageHint"
                        matTooltip="{{ 'COMMON.SETTINGS' | translate }}"
                        class="ld-options-hint"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
            </div>

            <!-- Filters: Bottom -->
            <mat-accordion
                class="full-width"
                hideToggle="true"
            >
                <mat-expansion-panel
                    class="location-info-expansion-panel"
                    [expanded]="true"
                    #filterAccordion
                >
                    <!-- Entity Selector Drop Down-->
                    <div
                        class="flex-item-al-s-ju-s ld-entity-hg-hint"
                        [ngClass]="{
                            'default-width': !annotationSettings || !annotationSettings.isConfirmationPoints,
                            'confirmation-width': annotationSettings && annotationSettings.isConfirmationPoints
                        }"
                        style="justify-content: space-between"
                        id="entities"
                        [ngClass]="{
                            'default-width': !annotationSettings || !annotationSettings.isConfirmationPoints,
                            'confirmation-width': annotationSettings && annotationSettings.isConfirmationPoints
                        }"
                    >
                        <app-entity-selector
                            [availableEntities]="availableEntities$ | async"
                            [availableANSREntities]="availableANSREntities$ | async"
                            [selectedEntitiyIds]="selectedEntityIds"
                            [defaultEntities]="defaultEntities"
                            (selectedEntities)="selectEntities($event)"
                            (emitBlur)="onHGSelectorBlur()"
                            (isTouched)="configEntitiesArePopulated = false"
                            (selectedEntitiesList)="selectedEntityNames = $event"
                            (customizedEntities)= "customizeEntities($event)"
                        ></app-entity-selector>
                        <span
                            class="danger absoluteError"
                            *ngIf="displayEntityError"
                        >{{ entityErrorMessage }}</span>
                    </div>
                    <div class="ld-entity-sg-hint mr-20p">
                        <app-entity-selector
                            [availableEntities]="availableSGEntities"
                            [selectedEntitiyIds]="selectedSGEntityIds"
                            (isTouched)="configEntitiesArePopulated = false"
                            (selectedEntities)="selectSGEntities($event)"
                            [oneEntityPerGroup]="true"
                            (emitBlur)="onEntitySelectorBlur()"
                            [staticPlaceholder]="sgEntitiesPlaceholder"
                        ></app-entity-selector>
                    </div>

                    <!-- Date Selector -->
                    <div class="ld-date-select-hint">
                        <app-date-range-picker
                            [(startDate)]="startDate"
                            [(endDate)]="endDate"
                            [minimumDataDate]="minDate"
                            [maximumDataDate]="maxDate"
                            [(tickInterval)]="tickInterval"
                            (emitClose)="onDateRangeClosed()"
                            (emitDateChanged)="updateConfirmationFiltersDateByDashboard()"
                        ></app-date-range-picker>
                    </div>

                    <!-- Data Averaging -->
                    <div
                        class="data-averaging-options ld-average-hint"
                        *ngIf="!isDataEditingEnabled"
                    >
                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'FLOW_BALANCE.DATA_AVERAGING' | translate }}"
                                [(ngModel)]="summarizeInterval"
                                name="dataAveraging"
                                (ngModelChange)="handleDataAveraging()"
                            >
                                <mat-option
                                    *ngFor="let each of dataAveragingList; trackBy: trackById"
                                    [value]="each.id"
                                >
                                    {{ each.text | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Confirmations Entities -->
                    <div *ngIf="annotationSettings && annotationSettings.isConfirmationPoints">
                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'LOCATION_DASHBOARD.CONFIRMATION_SELECTOR_PLACEHOLDER' | translate }}"
                                name="confirmationEntities"
                                [value]="selectedConfirmationEntity"
                                (selectionChange)="onConfirmationEntityChange($event)"
                            >
                                <mat-option
                                    *ngFor="let item of confirmationEntitiesList; trackBy: trackById"
                                    [value]="item.id"
                                >
                                    {{ item.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <!-- Annotations -->
    <div
        class="dont-print"
        id="locationDashboardAnnotation"
        *ngIf="displayAnnotations"
        #accordionMenu
        (mouseover)="closeRecentsMenu($event)"
    >
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ALARAM_THRESHOLDS_LABEL' | translate }}
                    </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="annotationList">
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isHighHigh"
                        [disabled]="noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'COMMON.HIGH_HIGH_ALARAM' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isHighFlow"
                        [disabled]="noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'COMMON.HIGH_FLOW_ALARM' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isHighLevel"
                        [disabled]="noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'COMMON.HIGH_LEVEL_ALARM' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isLowDepth"
                        [disabled]="noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LOW_DEPTH_TOGGLE' | translate }}
                    </mat-slide-toggle>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'COMMON.COMMON_TEXT' | translate }}
                    </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="annotationList">
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isManholeDepth"
                        [disabled]="isRAIII || noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.MANHOLE_DEPTH_LABEL' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isPipeHeight"
                        [disabled]="isRAIII || noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.PIPE_HEIGHT_LABEL' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isDataQuality"
                        [disabled]="noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.DATA_QUALITY_LABEL' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isSilt"
                        [disabled]="isRAIII || noDataForHydrographChart && noDataForScatterChart"
                        (change)="onSiltChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SILT_LABEL' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [checked]="markEachDataPoint"
                        (change)="onMarkEachPointChanged($event)"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ENABLE_MARKERS' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [checked]="annotationSettings.includeZeroRainValues"
                        (change)="includeZeroRainChange($event)"
                        [matTooltip]="'LOCATION_DASHBOARD.DASHBOARD_FILTER.SHOW_RAIN_ZERO' | translate"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SHOW_RAIN_ZERO' | translate }}
                    </mat-slide-toggle>
                    <div class="confirmation-points">
                        <button
                            mat-icon-button
                            (click)="$event.stopPropagation()"
                            [disabled]="isRAIII"
                            #confirmationMenuTrigger="matMenuTrigger"
                            (menuOpened)="confirmationMenuOpened()"
                            [matMenuTriggerFor]="appMenu"
                        >
                            <mat-icon>more_vert</mat-icon> &nbsp;
                        </button>
                        <mat-slide-toggle
                            [(ngModel)]="annotationSettings.isConfirmationPoints"
                            [disabled]="isRAIII"
                            (change)="annotationChange()"
                        >
                            {{ 'PLOTTING_CONFIRMATION.CONFIRMATION_POINTS' | translate }}
                        </mat-slide-toggle>
                        <mat-menu
                            #appMenu="matMenu"
                            xPosition="before"
                            [hasBackdrop]="false"
                        >
                            <div
                                class="confirmation-dialog-wrapper"
                                (click)="$event.stopPropagation()"
                            >
                                <h4>Filter confirmations</h4>
                                <div class="confirmation-options">
                                    <p>{{ confirmationFilterText }}</p>
                                    <button
                                        mat-button
                                        [color]="confimationDisplayFilter.type === 'all' ? 'primary' : null"
                                        (click)="onConfirmationFilterChange('all')"
                                    >
                                        All
                                    </button>
                                    <button
                                        mat-button
                                        [color]="confimationDisplayFilter.type === 'date' ? 'primary' : null"
                                        (click)="onConfirmationFilterChange('date')"
                                    >
                                        Select date range
                                    </button>
                                    <app-date-range-picker
                                        *ngIf="confimationDisplayFilter.type === 'date'"
                                        [hideInput]="false"
                                        [startFromMonth]="true"
                                        [startDate]="confimationDisplayFilter.dateRange.start"
                                        [endDate]="confimationDisplayFilter.dateRange.end"
                                        [dateRangeType]="'date'"
                                        (emitDateChanged)="confirmationDateChanged($event)"
                                    ></app-date-range-picker>
                                </div>
                                <div class="divider"></div>
                                <div class="confirmation-footer">
                                    <button
                                        mat-button
                                        (click)="onConfirmationDialogCancel()"
                                    >Cancel</button>
                                    <button
                                        mat-button
                                        [disabled]="confirmationDialogAcceptDisabled()"
                                        color="primary"
                                        (click)="onConfirmationDialogAccept()"
                                    >
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </mat-menu>
                    </div>
                    <div
                        *ngIf="userHasPermissionOnEdit"
                        class="annotationList__editors"
                    >
                        <button
                            class="margin-t-10 recalculate-btn"
                            (click)="onRecalculateEntities()"
                            [disabled]="isDataEditingEnabled"
                            mat-button
                            [matTooltip]="'COMMON.RECALC_TOOLTIP' | translate"
                            matTooltipClass="recalc-tooltip"
                        >
                            <span>{{ 'COMMON.RECALC_BUTTON' | translate }}</span>
                        </button>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'COMMON.HYDROGRAPH_TEXT' | translate }}
                    </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="annotationList">
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isRainOnTop"
                        [disabled]="noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.RAIN_ON_TOP_LABEL' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        *ngIf="!approvedDataOnly"
                        [(ngModel)]="annotationSettings.isShowEdits"
                        [disabled]="noDataForHydrographChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SHOW_EDITS_LABEL' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        *ngIf="customerPermissionOnEvent"
                        [(ngModel)]="isShowEvents"
                        [disabled]="noDataForHydrographChart"
                        (change)="eventChange()"
                    >
                        {{ 'COMMON.SHOW_EVENTS' | translate }}
                    </mat-slide-toggle>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'COMMON.SCATTERGRAPH_TEXT' | translate }}
                    </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="annotationList">
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isScatterInvert"
                        [disabled]="noDataForScatterChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SCATTER_INVERT_LABEL' | translate }}
                    </mat-slide-toggle>

                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isFroude"
                        [disabled]="noDataForScatterChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.FROUDE_OPTION' | translate }}
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LINES_TEXT' | translate }}
                    </mat-slide-toggle>

                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isIsoQ"
                        [disabled]="noDataForScatterChart"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ISO_Q_OPTION' | translate }}
                        <sup> {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SUP_TM' | translate }}</sup>
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LINES_TEXT' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [(ngModel)]="annotationSettings.isPipeOverlay"
                        [disabled]="noDataForScatterChart || annotationSettings.isScatterInvert"
                        (change)="annotationChange()"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.PIPE_OVERLAY_OPTION' | translate }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                        [checked]="isToolbarEnabled"
                        (change)="onShowToolbarChanged($event)"
                    >
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.TOOLBAR_ENABLED' | translate }}
                    </mat-slide-toggle>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> Tables </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="annotationList__editors">
                    <button
                        class="margin-t-10"
                        (click)="openSiltEditor()"
                        mat-button
                        [disabled]="!userHasPermissionOnEdit && !customerHasBasicEditPermission"
                    >
                        <span>{{ 'COMMON.SILT' | translate }}</span>
                    </button>
                </div>
                <div class="annotationList__editors">
                    <button
                        class="margin-t-10"
                        (click)="openVelocityGainTable()"
                        mat-button
                        [disabled]="!userHasPermissionOnEdit && !customerHasBasicEditPermission || !selectedLocation || selectedLocation.s !== monitorSeriesTypes.TRITON_PLUS || isVelocityGainDisabled"
                    >
                        <span>Velocity Gain</span>
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="anotationBorder">
            <button
                class="full-width"
                mat-button
                (click)="refreshGraphs()"
            >
                <mat-icon>refresh</mat-icon>
                <span>{{ 'COMMON.REFRESH_TOOLTIP' | translate }}</span>
            </button>
        </div>
        <div class="anotationBorder">
            <button
                class="full-width button-menu"
                mat-button
                [disabled]="!enableButtons"
                (click)="exportTelemetryData.emit(true)"
            >
                <mat-icon>import_export</mat-icon>
                <span>{{ 'COMMON.EXPORT_TEXT' | translate }}</span>
            </button>
        </div>

        <div class="anotationBorder">
            <button
                class="full-width button-menu"
                mat-button
                [disabled]="!enableButtons"
                (click)="printDashboard.emit(true)"
                (mouseover)="closeRecentsMenu($event)"
            >
                <mat-icon>print</mat-icon>
                <span>{{ 'COMMON.PRINT_TEXT' | translate }}</span>
            </button>
        </div>
        <div
            *ngIf="recentLoads && recentLoads.length > 0"
            class="anotationBorder"
            id="mru"
        >
            <button
                id="mru"
                class="full-width"
                mat-button
                (mouseover)="openRecentsMenu($event)"
                (click)="$event.preventDefault()"
                [matMenuTriggerFor]="appMenu2"
            >
                <span id="mru">{{ 'COMMON.RECENTCONFIG' | translate }}</span>
                <mat-icon id="mru">keyboard_arrow_right</mat-icon>
            </button>
            <mat-menu
                #appMenu2="matMenu"
                [overlapTrigger]="false"
                class="mat-menu-recent"
                backdropClass="mruOverlay"
            >
                <div class="confirmation-dialog-wrapper">
                    <div
                        *ngFor="let item of recentLoads | slice: 0:10; first as isFirst; trackBy: trackByIndex"
                        [class.mruBorder]="!isFirst"
                    >
                        <button
                            mat-button
                            class="full-width"
                            (click)="loadConfiguration(item)"
                        >{{ item.name }}</button>
                    </div>
                </div>
            </mat-menu>
        </div>

        <div class="anotationBorder">
            <button
                class="full-width"
                mat-button
                (click)="toggleLoadConfiguration()"
            >
                <mat-icon>move_to_inbox</mat-icon>
                <span>{{ 'COMMON.LOADCONFIG' | translate }}</span>
            </button>
        </div>
        <div class="anotationBorder">
            <button
                class="full-width"
                mat-button
                (click)="toggleSaveConfiguration()"
            >
                <mat-icon>save</mat-icon>
                <span>{{ 'COMMON.SAVECONFIG' | translate }}</span>
            </button>
        </div>
        <div class="anotationBorder close">
            <button
                class="full-width"
                mat-button
                (click)="showHideAnnotation(false)"
            >
                {{ 'COMMON.CLOSE' | translate }}
            </button>
        </div>
    </div>

    <!-- Print view -->
    <div class="only-print">
        <div class="one-row">
            <div class="header-item">
                <div class="title">Site Name</div>
                {{ selectedLocation?.n }}
            </div>
            <div class="header-item">
                <div class="title">Monitor Series</div>
                {{ selectedLocation?.s }}
            </div>
            <div class="header-item">
                <div class="title">Assigned Rain Gauge</div>
                {{ selectedLocation?.rg?.name }}
            </div>
            <div class="header-item">
                <div class="title">Data Averaging</div>
                {{ dataAveragingText | translate }}
            </div>
        </div>
        <div class="header-item">
            <div class="title">Date Range</div>
            {{ startDate | date: customerDateFormat }} -
            {{ endDate | date: customerDateFormat }}
        </div>
        <div class="header-item">
            <div class="title">Entities</div>
            {{ selectedEntityNames }}
        </div>

    </div>
    <!-- Save Configuration Dialog -->

    <div
        class="customDialogLayer"
        *ngIf="saveConfigOpen"
    >
        <div class="customDialog">
            <div class="customDialogPosition">
                <div id="customDialogConfig">
                    <div
                        class="popupHeaderWithButton"
                        mat-dialog-title
                    >
                        <div class="auto-margin pad-l-15">
                            {{ 'COMMON.SAVECONFIG' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                    </div>
                    <div
                        mat-dialog-content
                        class="eachTabContent"
                    >
                        <mat-form-field class="full-width">
                            <textarea
                                class="textarea-max-height"
                                required
                                matInput
                                placeholder="{{ 'COMMON.NAME' | translate }}"
                                [(ngModel)]="configName"
                                maxlength="255"
                                rows="3"
                            ></textarea>
                        </mat-form-field>
                    </div>
                    <div
                        mat-dialog-content
                        class="eachTabContent"
                    >
                        <mat-slide-toggle [(ngModel)]="isPublicConfig">
                            {{ 'COMMON.PUBLICCONFIG' | translate }}
                        </mat-slide-toggle>
                    </div>
                    <div mat-dialog-actions>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin">
                            <button
                                (click)="toggleSaveConfiguration()"
                                mat-button
                            >
                                {{ 'COMMON.CANCEL_BUTTON' | translate }}
                            </button>
                        </div>
                        <div class="auto-margin">
                            <button
                                mat-button
                                (click)="preSaveConfig()"
                                [disabled]="!configName"
                            >
                                {{ 'COMMON.SAVE_BUTTON' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Load Configuration Dialog -->

    <div
        class="customDialogLayer"
        *ngIf="loadConfigOpen"
    >
        <div class="config-dialog-container">
            <div class="config-dialog">
                <div id="customDialogConfig">
                    <div
                        class="popupHeaderWithButton"
                        mat-dialog-title
                    >
                        <div class="auto-margin pad-l-15">
                            {{ 'COMMON.LOADCONFIG' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                    </div>
                    <app-loader [isLoading]="isLoadConfigLoading"></app-loader>
                    <mat-tab-group>
                        <mat-tab label="My Configurations">
                            <div
                                mat-dialog-content
                                class="eachTabContent"
                            >
                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input
                                        matInput
                                        (keyup)="applyFilterPrivate($event)"
                                        #input
                                    />
                                </mat-form-field>
                                <table
                                    mat-table
                                    [dataSource]="locationConfigs"
                                    class="mat-elevation-z8"
                                >
                                    <ng-container matColumnDef="name">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                        >Name</th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element"
                                            class="truncate"
                                            (click)="loadConfiguration(element)"
                                        >
                                            {{ element.name }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="createdOn">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                        >Created On</th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element"
                                            (click)="loadConfiguration(element)"
                                        >
                                            {{ element.createdOn | date: customDateFormat }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="public">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                        >Public</th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element"
                                        >
                                            <mat-icon *ngIf="element.is_public">done</mat-icon>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                            class="fixCellWidth110"
                                        ></th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let j = index"
                                            class="text-right"
                                        >
                                            <button
                                                mat-icon-button
                                                [satPopoverAnchor]="popover"
                                                (click)="popover?.open()"
                                            >
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <sat-popover
                                                #popover
                                                hasBackdrop
                                                (closed)="editConfig(element, $event)"
                                            >
                                                <inline-edit [value]="element?.name"></inline-edit>
                                            </sat-popover>
                                            <button
                                                mat-icon-button
                                                (click)="deleteConfig(element)"
                                            >
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr
                                        mat-header-row
                                        *matHeaderRowDef="myConfigColumns"
                                    ></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let row; columns: myConfigColumns"
                                        [matTooltip]="row.name"
                                    ></tr>
                                </table>
                                <mat-paginator
                                    #locPagPrivate
                                    [pageSize]="10"
                                    [pageSizeOptions]="[10, 20, 50, 100]"
                                >
                                </mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab label="Public Configurations">
                            <div
                                mat-dialog-content
                                class="eachTabContent"
                            >
                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input
                                        matInput
                                        (keyup)="applyFilterPublic($event)"
                                        #input
                                    />
                                </mat-form-field>
                                <table
                                    mat-table
                                    [dataSource]="locationConfigsPublic"
                                    class="mat-elevation-z8"
                                >
                                    <ng-container matColumnDef="name">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                        >Name</th>
                                        <td
                                            mat-cell
                                            class="truncate"
                                            *matCellDef="let element; let i = index"
                                            (click)="loadConfiguration(element)"
                                        >
                                            {{ element.name }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="createdOn">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                        >Created On</th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element"
                                            (click)="loadConfiguration(element)"
                                        >
                                            {{ element.createdOn | date: customDateFormat }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                        ></th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element; let j = index"
                                            class="text-right"
                                        >
                                            <button
                                                mat-icon-button
                                                [satPopoverAnchor]="p"
                                                (click)="p?.open()"
                                                [disabled]="!element.is_writable"
                                            >
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <sat-popover
                                                #p
                                                hasBackdrop
                                                (closed)="editConfig(element, $event)"
                                            >
                                                <inline-edit [value]="element.name"></inline-edit>
                                            </sat-popover>
                                            <button
                                                mat-icon-button
                                                (click)="deleteConfig(element)"
                                                [disabled]="!element.is_writable"
                                            >
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr
                                        mat-header-row
                                        *matHeaderRowDef="publicConfigColumns"
                                    ></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let row; columns: publicConfigColumns"
                                        [matTooltip]="row.name"
                                    ></tr>
                                </table>
                                <mat-paginator
                                    #locPagPublic
                                    [pageSize]="10"
                                    [pageSizeOptions]="[10, 20, 50, 100]"
                                >
                                </mat-paginator>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                    <div mat-dialog-actions>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin">
                            <button
                                (click)="toggleLoadConfiguration()"
                                mat-button
                            >
                                {{ 'COMMON.CANCEL_BUTTON' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
