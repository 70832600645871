import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy, AfterViewInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { LocationNote } from 'app/shared/models/location-notes';
import { Locations } from 'app/shared/models/locations';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { LocationService } from 'app/shared/services/location.service';
import { TrackBy } from 'app/shared/utils/track-by';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';


const SNACKBAR_DURATION = 4000;
const TR_ID_PREFIX = 'locationId';

export interface LocationNotesDialogData {
    customerId: number;
    locationId: number;
    locations: Locations[];
}

@Component({
  selector: 'app-location-notes-dialog',
  templateUrl: './location-notes-dialog.component.html',
  styleUrls: ['./location-notes-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationNotesDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('editArea') public editArea: ElementRef<HTMLTextAreaElement>;
    public TR_ID_PREFIX = TR_ID_PREFIX;

    public isLoading = true;
    public searchText: string;
    public filteredLocations: Locations[];
    public selectedLocationId: number;
    public selectedLocationName: string;
    public locationText: string;
    public originalLocationText: string;
    public textWasModified = false;
    public notes: LocationNote[] = [];
    public editIndex: number = null;
    public isAddNote = false;
    public customerDateFormat: string;
    private editedNoteOriginalText: string;

    private subscriptions = new Array<Subscription>();

    public translations = {
        noteSaveOk: '',
        noteSaveError: '',
        noteDeleteError: '',
        noteLoadError: '',
        dismissBtnText: '',
        saveConfirmation: '',
        areYouSure: '',
        save: '',
        noteFilename: '',
        deleteConfirmation: '',
        confirm: '',
        createDateByUser: '',
        modifiedDateByUser: ''
    }


    public trackByLocationId = TrackBy.byLocationId;
    public trackByIndex = TrackBy.byIndex;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: LocationNotesDialogData,
        public dialogRef: MatDialogRef<LocationNotesDialogComponent>,
        private locationService: LocationService,
        private uiUtils: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private dateutilService: DateutilService
    ) {
        this.filteredLocations = this.data.locations;
        this.selectedLocationId = this.data.locationId;
        const selectedLocation = this.data.locations.find(l => l.locationId === this.data.locationId);
        this.selectedLocationName = selectedLocation && selectedLocation.name ? selectedLocation.name : '';

        this.applyTranslations();
    }

    ngOnInit(): void {
        this.searchText = '';
        this.loadLocation(this.selectedLocationId);

        // Subscribe to close dialog whenever user will change view, skip initial first value
        const activatedRouteSubscripton = this.activatedRoute.queryParamMap.pipe(skip(1)).subscribe((params: ParamMap) => {
            this.close();
        });
        this.subscriptions.push(activatedRouteSubscripton);

        this.customerDateFormat = this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormat();
    }

    ngAfterViewInit(): void {
        this.scrollToSelected();
    }

    ngOnDestroy(): void {
        for(const sub of this.subscriptions) sub.unsubscribe();
        this.subscriptions = [];
    }

    private applyTranslations() {
        this.translate.get([
            'LOCATION_DASHBOARD.NOTES.NOTE_SAVE_SUCCESS',
            'LOCATION_DASHBOARD.NOTES.NOTE_SAVE_ERROR',
            'LOCATION_DASHBOARD.NOTES.NOTE_SAVE_CONFIRMATION',
            'LOCATION_DASHBOARD.NOTES.NOTE_FILENAME',
            'LOCATION_DASHBOARD.NOTES.NOTE_DELETE_CONFIRMATION',
            'LOCATION_DASHBOARD.NOTES.NOTE_DELETE_ERROR',
            'LOCATION_DASHBOARD.NOTES.NOTE_LOAD_ERROR',
            'COMMON.CREATED_DATE_BY_USER',
            'COMMON.MODIFIED_DATE_BY_USER',
            'COMMON.DISMISS_TEXT',
            'COMMON.ARE_YOU_SURE',
            'COMMON.SAVE_BUTTON',
            'COMMON.CONFIRM_BTN',

        ]).subscribe((translateValues) => {
            this.translations.noteSaveOk = translateValues['LOCATION_DASHBOARD.NOTES.NOTE_SAVE_SUCCESS'];
            this.translations.noteSaveError = translateValues['LOCATION_DASHBOARD.NOTES.NOTE_SAVE_ERROR'];
            this.translations.saveConfirmation = translateValues['LOCATION_DASHBOARD.NOTES.NOTE_SAVE_CONFIRMATION'];
            this.translations.noteFilename = translateValues['LOCATION_DASHBOARD.NOTES.NOTE_FILENAME'];
            this.translations.deleteConfirmation = translateValues['LOCATION_DASHBOARD.NOTES.NOTE_DELETE_CONFIRMATION'];
            this.translations.noteDeleteError = translateValues['LOCATION_DASHBOARD.NOTES.NOTE_DELETE_ERROR'];
            this.translations.noteLoadError = translateValues['LOCATION_DASHBOARD.NOTES.NOTE_LOAD_ERROR'];
            this.translations.createDateByUser = translateValues['COMMON.CREATED_DATE_BY_USER'];
            this.translations.modifiedDateByUser = translateValues['COMMON.MODIFIED_DATE_BY_USER'];
            this.translations.dismissBtnText = translateValues['COMMON.DISMISS_TEXT'];
            this.translations.areYouSure = translateValues['COMMON.ARE_YOU_SURE'];
            this.translations.save = translateValues['COMMON.SAVE_BUTTON'];
            this.translations.confirm = translateValues['COMMON.CONFIRM_BTN'];

        });

    }

    private scrollToSelected() {
        const id = TR_ID_PREFIX+this.selectedLocationId;
        const row = document.getElementById(id);
        if(row) row.scrollIntoView({block: 'center'});
    }

    private finishLoading() {
        this.isLoading = false;
        this.uiUtils.safeChangeDetection(this.cdr);
    }

    private changeLocation(item: Locations) {
        this.selectedLocationId = item.locationId;
        this.selectedLocationName = item.name;

        this.loadLocation(item.locationId);
    }

    private loadLocation(lid: number) {
        this.isLoading = true;
        this.notes = [];
        this.locationText = '';
        this.editIndex = null;
        this.locationService.locationNotesGET(this.data.customerId, lid).subscribe(
            (res) => {
                this.notes =
                    res.sort((n1, n2) => {
                        if(n1.isPinned === n2.isPinned) {
                            return n1.createdDate > n2.createdDate ? -1 : n1.createdDate < n2.createdDate ? 1 : 0
                        }
                        return n1.isPinned ? -1 : 1;
                    });
                this.finishLoading();
            },
            (err) => {
                this.notes = [];
                this.finishLoading();
            }
        )
    }

    clearFilter() {
        this.searchText = '';
        this.filterLocations();
        this.scrollToSelected();
    }

    filterLocations() {
        const search = this.searchText.toLowerCase();
        this.filteredLocations = this.data.locations.filter(l => l.name && l.name.toLowerCase().includes(search));
    }


    confirmNoteSave() {
        return this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: <IComponentDialog>{
                title: this.translations.areYouSure,
                message: this.translations.saveConfirmation,
                cancelText: this.translations.dismissBtnText,
                okText: this.translations.save,
                hidebackContent: false,
            },
        });
    }

    selectRow(item: Locations) {
        if((this.isAddNote && this.locationText !== '')
            || (this.editIndex !== null && this.editIndex !== undefined &&  this.notes[this.editIndex].text && this.notes[this.editIndex].text !== '')) {
            this.confirmNoteSave().afterClosed().subscribe((res: IComponentDialogConfirmationResult) => {
                if(res.whichButtonWasPressed === 'ok') {
                    if(this.isAddNote) {
                        this.addNoteSave(item);
                    } else if (this.editIndex || this.editIndex === 0) {
                        const note = this.notes[this.editIndex];
                        this.editNoteSave(note, item);
                    }
                } else {
                    this.isAddNote = false;
                    this.locationText = '';
                    this.editIndex = null;
                    this.changeLocation(item);
                }
            });

        } else {
            this.isAddNote = false;
            this.changeLocation(item);
        }
    }

    addNoteClose() {
        this.isAddNote = false;
    }

    addNoteOpen() {
        if(this.isAddNote && this.locationText !== '') {
            this.confirmNoteSave().afterClosed().subscribe((res: IComponentDialogConfirmationResult) => {
                if(res.whichButtonWasPressed === 'ok') {
                    if(this.isAddNote) {
                        this.addNoteSave();
                    }
                } else {
                }
            });
        } else {
            this.isAddNote = true;
            this.editIndex = null;
            this.locationText = '';
        }
    }

    editNoteOpen(i: number) {
        this.isAddNote = false;
        this.editIndex = i;
        this.editedNoteOriginalText = this.notes[this.editIndex].text;

        setTimeout(() => {
            // #42574 need to fire it on next Angular tick, so the list will update after ngif change

            if (this.editArea) {
                this.autoGrow(this.editArea.nativeElement);
                this.editArea.nativeElement.focus();
            }
        });
    }
    editNoteCancel() {
        this.notes[this.editIndex].text = this.editedNoteOriginalText;
        this.editedNoteOriginalText = null;
        this.editIndex = null;
    }
    editNoteSave(note: LocationNote, switchToLocation: Locations = null) {
        this.isLoading = true;

        this.locationService.locationNotesPUT(this.data.customerId, this.selectedLocationId, note.id, note.text, note.isPinned).subscribe(
            () => {
                this.snackBar.open(this.translations.noteSaveOk, this.translations.dismissBtnText, {
                    duration: SNACKBAR_DURATION
                });

                this.editIndex = null;
                this.finishLoading();

                if(switchToLocation) {
                    this.changeLocation(switchToLocation);
                } else {
                    this.loadLocation(this.selectedLocationId);
                }
            },
            () => {
                this.finishLoading();

                if(switchToLocation) {
                    this.selectRow(switchToLocation);
                }
            }
        );
    }

    notePin(note: LocationNote, isPinned: boolean) {
        note.isPinned = isPinned;
        this.editNoteSave(note);
    }

    noteDelete(noteId: string) {
        this.isLoading = true;
        this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: <IComponentDialog>{
                title: this.translations.areYouSure,
                message: this.translations.deleteConfirmation,
                cancelText: this.translations.dismissBtnText,
                okText: this.translations.confirm,
                hidebackContent: false,
            },
        }).afterClosed().subscribe((res: IComponentDialogConfirmationResult) => {
            if(res.whichButtonWasPressed === 'ok') {
                this.locationService.locationNotesDELETE(this.data.customerId, this.selectedLocationId, noteId)
                    .subscribe(() => {
                        this.loadLocation(this.selectedLocationId);
                        this.finishLoading();
                    },
                    () => {
                        this.snackBar.open(this.translations.noteDeleteError, this.translations.dismissBtnText, {
                            panelClass: 'custom-error-snack-bar',
                            duration: SNACKBAR_DURATION
                        });
                        this.finishLoading();
                    })
            } else {
                this.finishLoading();
            }
        });
    }

    close() {
        this.locationService.setCloseNotesDialog();

        this.dialogRef.close();
    }

    addNoteSave(switchToLocation: Locations = null) {
        this.isLoading = true;
        this.locationService.locationNotesPOST(this.data.customerId, this.selectedLocationId, this.locationText).subscribe(
            () => {
                this.snackBar.open(this.translations.noteSaveOk, this.translations.dismissBtnText, {
                    duration: SNACKBAR_DURATION
                });

                this.isAddNote = false;

                if(switchToLocation) {
                    this.changeLocation(switchToLocation);
                } else {
                    this.loadLocation(this.selectedLocationId);
                }
            },
            () => {
                this.snackBar.open(this.translations.noteSaveError, this.translations.dismissBtnText, {
                    panelClass: 'custom-error-snack-bar',
                    duration: SNACKBAR_DURATION
                });
                this.finishLoading();

                if(switchToLocation) {
                    this.selectRow(switchToLocation);
                }
            }
        )
    }


    download() {
        let text = '';
        for(const note of this.notes) {
            text += '\n';
            text += note.text ? note.text : '';
            text += '\n---\n';
            text += this.translations.createDateByUser
                .replace('%1',  moment(note.createdDate).format(this.dateutilService.getFormat().toUpperCase() + ' ' + this.dateutilService.getTimeFormat()))
                .replace('%2',  note.createdBy);
            if(note.modifiedBy) {
                text += '\n'
                text += this.translations.modifiedDateByUser
                .replace('%1',  moment(note.modifiedDate).format(this.dateutilService.getFormat().toUpperCase() + ' ' + this.dateutilService.getTimeFormat()))
                .replace('%2',  note.modifiedBy);

            }
            text += '\n------------------\n';
        }

        const file = new Blob([text], {type: '.txt'});

        const a = document.createElement("a");
        const url = URL.createObjectURL(file);

        a.href = url;
        a.download = this.translations.noteFilename + this.selectedLocationName;
        document.body.appendChild(a);

        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    /** #42574 auto grow textarea */
    public autoGrow(area: HTMLTextAreaElement) {
        if(!area) return;
        area.style.height = '5px';
        area.style.height = `${area.scrollHeight}px`;
    }


}
