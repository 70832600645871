<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'surface-combo-section-error': form.invalid }">
        <mat-expansion-panel-header>
            <mat-panel-title class="input-text">
                {{ 'ADD_EDIT.RAIN' | translate }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount }}
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            type="number"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 44 || event.charCode == 46)"
                            matInput
                            placeholder="{{ 'ADD_EDIT.RAIN_PER_TIP' | translate }}"
                            formControlName="rainpertip"
                        />
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.DATA_LOG_MODE' | translate }}"
                            formControlName="datalogmode"
                        >
                            <mat-option *ngFor="let datalogMode of datalogModeList; trackBy: trackByValue" [value]="datalogMode.value">
                                {{ datalogMode.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
