<div class="block-data-editor" [ngClass]="{ 'block-editor-hint-dialog-background': showpageHint }">
    <app-loader [isLoading]="isLoading"></app-loader>
        <div
            class="popupHeader"
            mat-dialog-title
            cdkDrag
            cdkDragHandle
            cdkDragRootElement=".cdk-overlay-pane">
            <span [ngClass]="{ 'block-editor-dialog-header-hint': showpageHint }" *ngIf="loadedConfig">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.TITLE' | translate }} - {{ loadedConfig.name.length > 50 ? (loadedConfig.name.slice(0, 50) + '...') : loadedConfig.name }}
            </span>
            <span [ngClass]="{ 'block-editor-dialog-header-hint': showpageHint }" *ngIf="!loadedConfig">
                {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.TITLE' | translate }} - {{ 'COMMON.DEFAULT' | translate }}
            </span>
        </div>
    <div mat-dialog-content class="full-width">
        <form [formGroup]="form">
            <div [ngClass]="{ 'block-editor-dialog-date-range-hint': showpageHint }">
                <app-date-range-picker
                    class="date-range-filter"
                    [title]="'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.TIMESPAN' | translate"
                    [(startDate)]="startDate"
                    [(endDate)]="endDate"
                    [showQuickRanges]="true"
                    (emitClose)="onCloseDateMenu()"
                ></app-date-range-picker>
            </div>
            <div class="conditions">
                <h2 class="subtitle">
                    <mat-label>{{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.CONDITIONS' | translate }}</mat-label>
                </h2>
                <ul class="conditions__list" formArrayName="conditions">
                    <li *ngFor="let condition of conditions.controls; first as isFirst; index as i; trackBy: trackByIndex">
                        <div [formGroupName]="i" class="condition">
                            <mat-form-field [ngClass]="{ 'block-editor-dialog-condition-location-hint': showpageHint && i === 0 }">
                                <mat-label>{{ 'COMMON.LOCATION' | translate }}</mat-label>
                                <mat-select formControlName="locationId">
                                    <mat-option *ngFor="let location of locations; trackBy: trackByLocationId" [value]="location.locationId">
                                        {{ location.locationName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div [ngClass]="{ 'block-editor-dialog-condition-entity-hint': showpageHint && i === 0 }">
                                <app-entity-selector
                                    #conditionEntities
                                    *ngIf="condition?.value?.locationId && entitiesByLocation[condition?.value?.locationId]"
                                    formControlName="entities"
                                    [single]="true"
                                    [availableEntities]="entitiesByLocation[condition.value.locationId]"
                                    [availableANSREntities]="ansrEntitiesByLocation[condition.value.locationId]"
                                ></app-entity-selector>
                            </div>
                            <mat-form-field [ngClass]="{ 'block-editor-dialog-condition-operator-hint': showpageHint && i === 0 }">
                                <mat-label>{{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.OPERATOR' | translate }}</mat-label>
                                <mat-select formControlName="operator">
                                    <mat-option *ngFor="let operator of operators; trackBy: trackByIndex" [value]="operator">
                                        {{ operator }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field [ngClass]="{ 'block-editor-dialog-condition-value-hint': showpageHint && i === 0 }">
                                <mat-label
                                    >{{ 'COMMON.VALUE' | translate }}
                                    {{ getUnitName(condition.value.entities) }}</mat-label
                                >
                                <input matInput type="number" formControlName="value" />
                            </mat-form-field>
                            <button *ngIf="!isFirst" mat-button (click)="removeCondition(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </li>
                </ul>
                <div class="add-more-button-wrapper">
                    <button mat-button class="add-more-button" (click)="addCondition()" [ngClass]="{ 'block-editor-dialog-add-hint': showpageHint }">
                        {{ 'COMMON.ADD_MORE' | translate }}
                    </button>
                </div>
            </div>
            <div class="location">
                <h2 class="subtitle">
                    <mat-label>{{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.RESULTS' | translate }}</mat-label>
                </h2>
                <span class="location-label">
                    {{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.LOCATION' | translate }}: {{ locationName }}
                </span>
                <div class="location__settings">
                    <app-entity-selector
                        #entityToEditSelector
                        [ngClass]="{ 'block-editor-dialog-entity-hint': showpageHint}"
                        formControlName="entityToEdit"
                        [single]="true"
                        [availableEntities]="entitiesToEdit"
                        [selectedEntitiyIds]="[]"
                    ></app-entity-selector>
                    <mat-form-field [ngClass]="{ 'block-editor-dialog-action-hint': showpageHint}">
                        <mat-label>{{ 'LOCATION_DASHBOARD.BLOCK_DATA_EDITOR.ACTIONS' | translate }}</mat-label>
                        <mat-select formControlName="action">
                            <mat-option *ngFor="let action of actions; trackBy: trackByIndex" [value]="action.value">
                                {{ action.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-radio-group
                        [ngClass]="{ 'block-editor-dialog-valueentity-hint': showpageHint}"
                        formControlName="typeToBaseOf"
                        *ngIf="(action?.value && action?.value !== FLAG && action?.value !== UNFLAG) || showpageHint"
                    >
                        <mat-label>{{ 'COMMON.TYPE' | translate }}</mat-label>
                        <mat-radio-button value="VALUE">{{ 'COMMON.VALUE' | translate }}</mat-radio-button>
                        <mat-radio-button value="ENTITY">{{ 'COMMON.ENTITY' | translate }}</mat-radio-button>
                    </mat-radio-group>
                    <ng-container *ngIf="(action?.value && action?.value !== FLAG && action?.value !== UNFLAG) && (typeToBaseOf?.value === ENTITY || showpageHint)">
                        <app-entity-selector
                            #entityToBaseOfSelector
                            *ngIf="entityToEdit?.value.length && entitiesByLocation[locationId]"
                            formControlName="entityToBaseOf"
                            [single]="true"
                            [availableEntities]="
                                filterByGroupId(entitiesByLocation[locationId], entityToEdit.value[0].groupId)
                            "
                            [availableANSREntities]="
                                filterByGroupId(ansrEntitiesByLocation[locationId], entityToEdit.value[0].groupId)
                            "
                            [selectedEntitiyIds]="[]"
                        ></app-entity-selector>
                    </ng-container>
                    <mat-form-field *ngIf="(action?.value && action?.value !== FLAG && action?.value !== UNFLAG) && (typeToBaseOf?.value === VALUE || showpageHint)" [ngClass]="{ 'block-editor-dialog-entityvalue-hint': showpageHint}">
                        <mat-label>{{ 'COMMON.VALUE' | translate }} {{ getUnitName(entityToEdit.value) }}</mat-label>
                        <input matInput type="number" formControlName="valueToCopy" />
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
        <div class="configuration-wrapper">
            <button mat-button [matMenuTriggerFor]="configurationsMenu">{{ 'COMMON.CONFIGURATIONS' | translate }}</button>
            <mat-menu #configurationsMenu="matMenu">
                <button mat-menu-item (click)="openConfigurationDialog()">
                    {{ 'COMMON.LOAD' | translate }}
                </button>
                <button mat-menu-item (click)="saveConfiguration()">
                    {{ 'COMMON.SAVE' | translate }}
                </button>
                <button [disabled]="!recentConfigs || !recentConfigs.length" mat-menu-item [matMenuTriggerFor]="configurationsRecentMenu">
                    {{ 'COMMON.RECENT' | translate }}
                </button>
            </mat-menu>
            <mat-menu #configurationsRecentMenu="matMenu">
                <button mat-menu-item *ngFor="let conf of recentConfigs; trackBy: trackById" (click)="loadRecentConfig(conf.id)"> {{conf.name}} </button>
            </mat-menu>
        </div>
        <div class="actions-wrapper">
            <button mat-button (click)="cancel()" [ngClass]="{ 'block-editor-dialog-close-hint': showpageHint }">{{ 'COMMON.CLOSE' | translate }}</button>
            <button (click)="apply()" mat-button [disabled]="form.invalid || isLoading" [ngClass]="{ 'block-editor-dialog-apply-hint': showpageHint }">
                {{ 'COMMON.APPLY_BTN' | translate }}
            </button>
        </div>
    </div>
</div>
