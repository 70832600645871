<mat-form-field [ngClass]="{ 'full-width': true, invalid: !isValid }">
    <div class="mat-focused multi-entity-input">
        <input
            matInput
            [attr.data-identifier]="surrogateIdentifier"
            [(ngModel)]="initPlaceholderText"
            autocomplete="off"
            tabindex="-1"
            [formControl]="searchString"
            [placeholder]="initialPlaceholder"
            (focus)="onInputFocusIn($event)"
            (focusout)="onInputFocusOut($event)"
        />
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</mat-form-field>
<mat-selection-list
    id="entityGroupLimit"
    [attr.data-identifier]="surrogateIdentifier"
    class="multi-select-group-list"
    (focus)="onSelectionListFocused($event)"
    (focusout)="onSelectionListNotFocused($event)"
    *ngIf="isResultsVisible"
>
    <div class="mat-error" *ngIf="isExceedLimit">
        {{ 'COMMON.MAX_LIMIT_REACHED' | translate }}
    </div>
    <mat-selection-list *ngIf="isShowAll" class="multi-select-list" [attr.data-identifier]="surrogateIdentifier">
        <mat-list-option
            value="select-all"
            [attr.data-identifier]="surrogateIdentifier"
            (click)="onSelectAllChange($event)"
        >
            <span *ngIf="!isAllSelected; else deselect">{{ 'COMMON.SELECT_All_OPTION' | translate }}</span>
            <ng-template #deselect>
                <span>{{ 'COMMON.DESELECT_OPTION' | translate }}</span>
            </ng-template>
        </mat-list-option>
    </mat-selection-list>
    <mat-accordion [attr.data-identifier]="surrogateIdentifier" [multi]="isSearching">
        <mat-expansion-panel
            [attr.data-identifier]="surrogateIdentifier"
            [expanded]="true"
            *ngIf="selectedItemList?.length > 0"
        >
            <mat-expansion-panel-header [attr.data-identifier]="surrogateIdentifier">
                <mat-panel-title>
                    {{ 'COMMON.SELECTED' | translate }}
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-selection-list class="multi-select-list" [attr.data-identifier]="surrogateIdentifier">
                    <mat-list-option
                        *ngFor="let item of selectedItemList; trackBy: trackById"
                        [attr.data-item]="item.id"
                        [attr.data-identifier]="surrogateIdentifier"
                        (click)="onSelectionChange($event, item.id)"
                        [disabled]="isExceedLimit && !item.isChecked"
                        [selected]="item.isChecked"
                        [attr.data-id]="item.id"
                        value="{{ item }}"
                        >{{ item.name }}</mat-list-option
                    >
                </mat-selection-list>
                <div></div>
            </div>
        </mat-expansion-panel>
        <div *ngFor="let groupName of itemGroupNameList; index as i; trackBy: trackByIndex">
            <mat-expansion-panel
                [attr.data-identifier]="surrogateIdentifier"
                [expanded]="isSearching"
                *ngIf="itemGroupByList[groupName]?.length > 0"
            >
                <mat-expansion-panel-header [attr.data-identifier]="surrogateIdentifier">
                    <mat-panel-title>
                        {{ groupName }}
                    </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <mat-selection-list class="multi-select-list" [attr.data-identifier]="surrogateIdentifier">
                        <mat-list-option
                            *ngFor="let item of itemGroupByList[groupName]; trackBy: trackById"
                            [attr.data-item]="item.id"
                            [attr.data-identifier]="surrogateIdentifier"
                            (click)="onSelectionChange($event, item.id)"
                            [disabled]="isExceedLimit && !item.isChecked"
                            [selected]="item.isChecked"
                            [attr.data-id]="item.id"
                            value="{{ item }}"
                            >{{ item.name }}</mat-list-option
                        >
                    </mat-selection-list>
                    <div></div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</mat-selection-list>
