import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UptimeReportService } from '../uptime-report.service';
import { PreLoaderService } from 'app/shared/services/pre-loader.service';
import { Router } from '@angular/router';
import { FilterDataSource } from '../../../../shared/components/paging/filter-data-source';

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Sort, MatSort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { UptimeReportItem } from 'app/shared/models/uptime';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { SelectableGroup } from 'app/shared/models/selectable';
import { EntitySelectorObject } from 'app/shared/models/entities';
import {
    LocationData,
    LocationEntitiesData,
    LocationListArgs,
    EntityAttributeData,
} from 'app/shared/models/locations-entities-data';
import { get, uniqWith } from 'lodash';
import { EntityService } from 'app/shared/services/entity.service';
import { LocationService } from 'app/shared/services/location.service';
import { EntityDayValuePipe } from './uptime-day-value.pipe';
import {
    DEPTH_DISPLAY_GROUP,
    DEPTH_ENTITY,
    DFINAL_ENTITY,
    ANGLE_DISPLAY_GROUP,
    FEET_DISPLAY_GROUP,
    FLOW_DISPLAY_GROUP,
    QUANTITY_ENTITY,
    Q_FINAL_ENTITY,
    RAIN_DISPLAY_GROUP,
    RAIN_ENTITY,
    R_FINAL_ENTITY,
    VELOCITY_DISPLAY_GROUP,
    VELOCITY_ENTITY,
    V_FINAL_ENTITY,
    ELEVATION_ENTITY,
    ELEVATION_DISPLAY_GROUP,
} from 'app/shared/constant';
import { UsersService } from 'app/pages/admin/users.service';
import { VaultService } from 'app/pages/vault';
import { TrackBy } from 'app/shared/utils/track-by';
export interface SampleRateElement {
    id: number;
    value: number;
    text: string;
}

const DEFAULT_SELECTED_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: VELOCITY_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VELOCITY', isChecked: true, isANSR: false },
    { id: QUANTITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QUANTITY', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
    { id: ELEVATION_ENTITY, groupId: ELEVATION_DISPLAY_GROUP, name: 'ELEVATION', isChecked: true, isANSR: false },
];

const DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: DFINAL_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DFINAL', isChecked: true, isANSR: false },
    { id: V_FINAL_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VFINAL', isChecked: true, isANSR: false },
    { id: Q_FINAL_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QFINAL', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
    { id: ELEVATION_ENTITY, groupId: ELEVATION_DISPLAY_GROUP, name: 'ELEVATION', isChecked: true, isANSR: false },
];

// #21357 filter action types
enum QUEUE_ACTION {
    runQueuedDateRangeChange,
    runQueuedLocationSelected,
    runQueuedRemoveLocation,
    runQueuedEntitiesChange,
    runQueuedSampleRateChange
};

@Component({
    selector: 'app-uptime-overview',
    templateUrl: './uptime-overview.component.html',
    styleUrls: ['./uptime-overview.component.scss'],
    providers: [OrderByPipe, EntityDayValuePipe],
})

/**
 * Uptime overview report
 */
export class UptimeOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Array<Subscription>();
    private customerID: number;
    private locationGroupID: number;
    public uptimeData: Array<UptimeReportItem>;
    public displayedColumns: string[] = ['location', 'avrUptime'];
    public dayColumns = [];
    public sortedData: Array<UptimeReportItem>;
    public uptimeLoadingState: boolean;
    public uptimeOverviewDisplayedColumns = [];
    public uptimeOverviewDataSource: FilterDataSource | null;
    @ViewChild(MatPaginator) public uptimeOverviewPaginator: MatPaginator;
    @ViewChild(MatSort) public uptimeOverviewSort: MatSort;
    public uptimeOverviewDataChange: BehaviorSubject<Object[]> = new BehaviorSubject<Object[]>([]);
    public get uptimeOverviewData(): Object[] {
        return this.uptimeOverviewDataChange.value;
    }
    public totalPaginationLength: number;
    public displayFilters: boolean;
    public availableEntities: EntitySelectorObject[] = [];
    public locationsList: LocationData[] = [];
    public sampleRateList: Array<SampleRateElement>;
    public paginatedLocations: LocationData[] = [];
    public formattedUptimeData = [];
    public paginatedUptimeData = [];
    public spans = {};
    public selectedLocations: any[] = [];
    public selectedSampleRate: number = 2;
    public defaultEntities: SelectableGroup[] = [];
    public selectedEntityIds: number[] = [];
    public entitiesLength = 4;

    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
    public endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
    /**
     * Represents the state of active and inactive locations
     * True will represent the both (active and inactive) locations
     * False will represent only active locations
     */
    public includeInactiveLocations: boolean;
    private allowedEntityGroups = [
        DEPTH_DISPLAY_GROUP,
        ELEVATION_DISPLAY_GROUP,
        ANGLE_DISPLAY_GROUP,
        FEET_DISPLAY_GROUP,
        FLOW_DISPLAY_GROUP,
        RAIN_DISPLAY_GROUP,
        VELOCITY_DISPLAY_GROUP,
    ];
    /** #21357 Filter actions queue and run once user will click outside filter area. Indexed by type - we want to process only last action of same type */
    private actionsQueue: {[index in QUEUE_ACTION]?: () => boolean} = {};

    public trackById = TrackBy.byId;
    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private uptimeReportService: UptimeReportService,
        private preloaderService: PreLoaderService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private orderByPipe: OrderByPipe,
        private activatedRoute: ActivatedRoute,
        private dateutilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private locationService: LocationService,
        private entityService: EntityService,
        private usersService: UsersService,
        private vaultService: VaultService
    ) {}

    public ngOnInit() {
        this.setSelectedEntitiesByPermission();
        this.sampleRateList = <Array<SampleRateElement>>[
            { id: 1, value: 8, text: '2 Minutes' },
            { id: 2, value: 1, text: '5 Minutes' },
            { id: 3, value: 2, text: '15 Minutes' },
            { id: 4, value: 3, text: 'One Hour' },
            { id: 5, value: 4, text: 'One Day' },
            { id: 6, value: 6, text: 'One Month' },
        ];
        const customerIDSuscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // get updated customer id
            this.customerID = Number(params.get(customerQueryParam));
            this.locationGroupID = Number(params.get(customerLocationGroupQueryParam));
            // get updated locations
            this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

            // set default page size 10
            if (this.uptimeOverviewPaginator) {
                this.uptimeOverviewPaginator.pageSize = 5;
                this.uptimeOverviewPaginator.pageIndex = 0;
            }

            //  if data is already saved for this customer and location group, pull it from the service
            //  otherwise run the report
            if (
                this.customerID === this.uptimeReportService.uptimeOverviewCustomerID &&
                this.locationGroupID === this.uptimeReportService.uptimeOverviewLocationGroupID &&
                this.includeInactiveLocations === this.uptimeReportService.uptimeOverviewInactiveLocation
            ) {
                this.setSelectedEntitiesByPermission();
                this.uptimeData = this.uptimeReportService.uptimeOverviewData;
                this.uptimeData = this.orderByPipe.transform(this.uptimeData, 'locationName', false);
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.generateUptimeOverviewTable();
            } else {
                this.loadEntities();
            }
        });
        this.subscriptions.push(customerIDSuscription);
    }

    private setSelectedEntitiesByPermission() {
        const userHasRawDataPermission = this.usersService.isRawDataEditingAllowed.getValue();

        this.defaultEntities = userHasRawDataPermission
            ? [...DEFAULT_SELECTED_ENTITIES]
            : DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS;

        if(!this.selectedEntityIds || this.selectedEntityIds.length === 0)
        {
            this.selectedEntityIds = this.defaultEntities.map((v) => v.id);
        }
        
        this.entitiesLength = this.selectedEntityIds.length;
    }

    private loadEntities() {
        if (this.uptimeOverviewPaginator) {
            this.uptimeOverviewPaginator.firstPage();
        }
        this.locationService
            .getLocationData(<LocationListArgs>{
                cid: this.customerID,
                IncludeInactiveLocations: this.includeInactiveLocations,
                LocationGroupId: this.locationGroupID,
            })
            .subscribe((entityData: LocationEntitiesData) => {
                entityData.d = entityData.d.map((d) => {
                    if (d.s === 'Echo') {
                        d.s = 'ECHO';
                    }
                    return d;
                });
                this.locationsList = entityData.l.map((v) => ({ ...v, name: v.n, id: v.lid }));
                this.selectedLocations = [...this.locationsList];
                this.availableEntities = this.mergeAllDeviceEntities(entityData.d);
                this.selectedEntityIds = this.selectedEntityIds.filter(
                    eid => this.availableEntities.some(g => g.entities && g.entities.some(e => e.id === eid))
                );

                this.entitiesLength = this.selectedEntityIds.length;
                this.generateUptimeOverviewReport();
            });
    }

    // method gets entity groups from each device type, merges them and apply them to correct model
    private mergeAllDeviceEntities(devices: EntityAttributeData[]) {
        const allEntityGroups = devices
            .map((v) => v.g)
            .reduce((acc, curr) => [...acc, ...curr], [])
            .filter((v) => this.allowedEntityGroups.includes(v.id));

        return allEntityGroups
            .reduce((acc, curr) => {
                const sameEntityGroup = acc.find((v) => v.id === curr.id);

                if (!sameEntityGroup) {
                    return [...acc, curr];
                }

                sameEntityGroup.entities = [...sameEntityGroup.entities, ...curr.entities];
                sameEntityGroup.entities = sameEntityGroup.entities.filter(
                    (v, i) => i === sameEntityGroup.entities.findIndex((x) => x.id === v.id),
                );
                return acc;
            }, [])
            .map((v) => ({
                groupName: v.name,
                groupId: v.id,
                entities: v.entities.map((i) => ({ id: i.id, groupId: v.id, name: i.e })),
            }));
    }

    public ngOnDestroy() {
        this.uptimeReportService.uptimeOverviewCustomerID = null;
        this.subscriptions.forEach((s: Subscription) => {
            s.unsubscribe();
        });
    }
    /**
     * Generates data for the uptime report overview
     */

    private generateUptimeOverviewReport() {
        this.uptimeLoadingState = true;
        this.uptimeData = [];
        this.formattedUptimeData = [];
        const { pageIndex, pageSize } = this.uptimeOverviewPaginator;

        const uptimeDataSubscription = this.uptimeReportService
            .getUptimeReport(
                this.customerID,
                this.selectedSampleRate,
                this.locationGroupID,
                this.includeInactiveLocations,
                this.startDate,
                this.endDate,
                this.selectedEntityIds,
                this.getLocationsForRequest(),
                pageSize,
                1,      // we filter location ids so page index should be always 1
            )
            .subscribe(
                (response: Array<UptimeReportItem>) => {
                    if (response) {
                        this.setSelectedEntitiesByPermission();
                        this.uptimeData = response;
                        this.formatUptimeData();
                        this.spans['locationName'] = this.spanDeep(['locationName'], this.formattedUptimeData);

                        this.uptimeData = this.orderByPipe.transform(response, 'locationName', false);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                    this.generateUptimeOverviewTable();
                    this.uptimeLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.uptimeLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );
        this.subscriptions.push(uptimeDataSubscription);
    }

    private getLocationsForRequest() {
        const { pageIndex, pageSize } = this.uptimeOverviewPaginator;

        const startPoint = pageIndex * pageSize;
        const endPoint = startPoint + pageSize;

        return this.selectedLocations.map(v => v.lid).slice(startPoint, endPoint);
    }

    private runQueuedActions() {
        if(!this.actionsQueue || !Object.entries(this.actionsQueue).length) return;

        let generateReport = false;
        Object.values(this.actionsQueue).forEach(a => {
            generateReport = a() || generateReport;
        });
        this.actionsQueue = {};

        if(generateReport) {
            this.generateUptimeOverviewReport();
        }
    }

    public applyFilters() {
        this.runQueuedActions();
    }

    public overlayClick() {
        if(this.displayFilters) {
            this.runQueuedActions();
        }
    }

    public runQueuedDateRangeChange(event: { startDate: Date; endDate: Date }) {
        if (this.startDate === event.startDate && this.endDate === event.endDate) {
            return false;
        }
        this.startDate = event.startDate;
        this.endDate = event.endDate;
        return true;
    }

    public runQueuedLocationSelected(locations) {
        this.selectedLocations = JSON.parse(JSON.stringify(locations));
        this.uptimeOverviewPaginator.firstPage();
        return true;
    }

    public runQueuedSampleRateChange(sampleRate: any)
    {
        this.selectedSampleRate= sampleRate.value;
        this.uptimeOverviewPaginator.firstPage();
        return true;
    }

    public runQueuedRemoveLocation(location) {
        this.selectedLocations = this.selectedLocations.filter((v) => v.lid !== location.lid);
        this.uptimeOverviewPaginator.firstPage();
        return true;
    }
    public runQueuedEntitiesChange(entities) {
        if (
            entities.length === this.selectedEntityIds.length &&
            entities.every((v) => this.selectedEntityIds.includes(v))
        ) {
            return false;
        }

        this.selectedEntityIds = entities;
        return true;
    }

    public onDateRangeChange(event: { startDate: Date; endDate: Date }) {
        this.actionsQueue[QUEUE_ACTION.runQueuedDateRangeChange] = () => this.runQueuedDateRangeChange(event);
    }

    public onLocationSelected(locations) {
        this.actionsQueue[QUEUE_ACTION.runQueuedLocationSelected] = () => this.runQueuedLocationSelected(locations);
    }

    public onRemoveLocation(location) {
        this.actionsQueue[QUEUE_ACTION.runQueuedRemoveLocation] = () => this.runQueuedRemoveLocation(location);
    }

    public onEntitiesChange(entities: number[]) {
        this.actionsQueue[QUEUE_ACTION.runQueuedEntitiesChange] = () => this.runQueuedEntitiesChange(entities);
    }

    public onSampleRateChange(sampleRate: any){
        this.actionsQueue[QUEUE_ACTION.runQueuedSampleRateChange] = () => this.runQueuedSampleRateChange(sampleRate);
    }

    private formatUptimeData() {
        let data: UptimeReportItem[] = [];
        if (this.uptimeData.length) {
            data = this.uptimeData.filter((v) => this.selectedLocations.find((i) => i.lid === v.locationId));
        } else {
            data = [...this.uptimeData];
        }

        if (data && data.length) {
            this.entitiesLength = data[0].uptimeAverages.length;
        }

        this.totalPaginationLength = data.length;
        this.formattedUptimeData = [];
        data.forEach((v) => {
            v.uptimeAverages.forEach((i) => {
                this.formattedUptimeData.push({ ...v, average: i });
            });
        });

        this.displayedColumns = ['location', 'avrUptime'];
        this.dayColumns = [];
        const item = this.formattedUptimeData[0];
        if (!item) {
            return;
        }
        item.uptimeDays.forEach((element, i) => {
            // Remove the 4 character year from start, end, or middle of date
            const day = element.day.replace(/\/\d{4}/, '').replace(/\d{4}\//, '');
            this.displayedColumns.push(element.day);
            this.dayColumns.push({id: element.day, value: day});
        });
    }

    spanDeep(paths: string[] | null, data: any[]) {
        if (!paths.length) {
            return [...data].fill(0).fill(data.length, 0, 1);
        }

        const copyPaths = [...paths];
        const path = copyPaths.shift();

        const uniq = uniqWith(data, (a, b) => get(a, path) === get(b, path)).map((item) => get(item, path));

        return uniq
            .map((uniqItem) =>
                this.spanDeep(
                    copyPaths,
                    data.filter((item) => uniqItem === get(item, path)),
                ),
            )
            ['flat'](paths.length);
    }

    getRowSpan(path, idx) {
        return this.spans[path] && this.spans[path][idx] ? this.spans[path][idx] : 0;
    }

    /**
     * Navigate to the location details page for uptime report
     * @param event
     * @param id    - location ID
     */

    public itemSelected(event: MouseEvent, id: number, locationName: string): void {
        this.uptimeReportService.selectedEntities = this.selectedEntityIds;
        // navigate to details route
        this.router.navigate(['/pages/report/uptime/' + id + '/details'], {
            queryParams: {
                ln: locationName,
            },
            queryParamsHandling: 'merge',
        });
    }

    public generateUptimeOverviewTable() {
        this.uptimeOverviewDisplayedColumns = [];
        this.uptimeOverviewDataSource = null;
        this.uptimeOverviewDataChange = new BehaviorSubject<UptimeReportItem[]>([]);
        if (this.uptimeData.length > 0) {
            this.uptimeOverviewDisplayedColumns.push('locationName');
            for (const item of this.uptimeData[0].uptimeDays) {
                for (const value of item.uptimeValues) {
                    this.uptimeOverviewDisplayedColumns.push(value.entityName);
                }
            }
            for (const uptimeData of this.uptimeData) {
                const data: any = {};
                for (const columnsValues of this.uptimeOverviewDisplayedColumns) {
                    data[columnsValues] = '';
                }
                data.locationName = uptimeData.locationName;
                data.locationId = uptimeData.locationId;
                for (const uptimeDay of uptimeData.uptimeDays) {
                    uptimeDay.uptimeValues.forEach((element) => {
                        const temp: string = element.entityName;
                        if (element.uptime) {
                            data[temp] = element.uptime;
                        } else {
                            data[temp] = '-';
                        }
                    });
                }
                const uptimeOverviewCopiedData = this.uptimeOverviewData.slice();
                uptimeOverviewCopiedData.push(data);
                this.uptimeOverviewDataChange.next(uptimeOverviewCopiedData);
                this.uptimeOverviewDataSource = new FilterDataSource(
                    this.uptimeOverviewDataChange,
                    this.uptimeOverviewData,
                    this.uptimeOverviewPaginator,
                    this.uptimeOverviewSort,
                    this.uptimeOverviewDisplayedColumns,
                );
                this.totalPaginationLength = this.uptimeOverviewData.length;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        } else {
            this.totalPaginationLength = 1;
        }
    }
    public onPaginateChange() {
        this.domOperationUtilsService.scrollToTop('html');
        this.generateUptimeOverviewReport();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Function to sort the uptime overview data
     * @param sort is the angular materail sort event
     */
    public sortUptimeOverviewData(sort: Sort) {
        const uptimeDataToBeSorted = this.uptimeData.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = uptimeDataToBeSorted;
            return;
        }

        // sort data based on locationName field
        this.sortedData = uptimeDataToBeSorted.sort((currentItem, nextItem) => {
            const isAscending = sort.direction === 'asc';
            switch (sort.active) {
                case 'locationName':
                    return this.compare(currentItem.locationName, nextItem.locationName, isAscending);
                default:
                    return 0;
            }
        });
    }

    /**
     * Compare the default elements
     * @param current represents first element of array
     * @param next represents next element of array
     * @param isAsc represents ascending order
     */
    public compare(current, next, isAsc) {
        return (current < next ? -1 : 1) * (isAsc ? 1 : -1);
    }

    public downloadCSV() {
        this.uptimeLoadingState = true;

        const selectedLocationsIds = this.selectedLocations.map(v => v.lid);
        this.uptimeReportService
            .exportUptimeReport(
                this.customerID,
                this.selectedSampleRate,
                this.locationGroupID,
                this.includeInactiveLocations,
                this.startDate,
                this.endDate,
                this.selectedEntityIds,
                selectedLocationsIds
            )
            .subscribe((files: string) => {
                if (!files || !files.length) {
                    return;
                }

                this.uptimeLoadingState = true;
                const selectedFiles = [];

                if (Array.isArray(files)) {
                    files.forEach((file) => {
                        selectedFiles.push('/' + file);
                    });
                } else {
                    selectedFiles.push('/' + files);
                }

                const splitFile = selectedFiles[0].split('/');
                const fileName = selectedFiles.length === 1 ? splitFile.find((x) => x.includes('.')) : 'Files';
                this.uptimeLoadingState = false;

                this.vaultService.downloadFile(selectedFiles).subscribe((res) => {
                    importedSaveAs(res, fileName);
                });
            },
            () => (this.uptimeLoadingState = false),
        );
    }

}
