import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SeasonType } from 'app/shared/models/sliicer';
import { Season } from '../seasons-settings.utils';
import * as Utils from './seasons-timeline.utils';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'seasons-bar',
    templateUrl: './seasons-timeline.component.html',
    styleUrls: ['./seasons-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonsTimelineComponent implements OnInit, OnChanges {
    @Input() public startDate: string;
    @Input() public endDate: string;
    @Input() public seasons: Season[];
    @Input() public seasonType: SeasonType;

    public state: Utils.TimelineState;
    public dateFormat: string;

    public trackByIndex = TrackBy.byIndex;
    constructor(private dateutilService: DateutilService) { }

    ngOnInit(): void {
        this.dateFormat = this.dateutilService.getFormat();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.seasons) {
            this.state = Utils.buildTimeline(this.seasonType, this.startDate, this.endDate, this.seasons);
        }
    }
}
