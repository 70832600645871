<div
    id="vaultFileUploadDialog"
    class="modal-dialog 2"
>
    <div
        class="popupHeader"
        mat-dialog-title
    >
        <span *ngIf="!isCsvUpload">{{ 'VAULT.VAULT_UPLOAD_DIALOG.FILE_UPLOAD_TITLE' | translate }}</span>
        <span *ngIf="isCsvUpload">{{ 'COMMON.UPLOAD_DATA' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <mat-dialog-content>
        <div
            class="file-upload-dialog"
            *ngIf="!isOverwriting"
        >
            <div
                ng2FileDrop
                [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver, 'upload-errors': failedUploads.length > 0 }"
                (fileOver)="fileOverBase($event)"
                (onFileDrop)="fileDrop($event)"
                (click)="resetState(); resetErrorMsg(false)"
                [uploader]="uploader"
                class="my-drop-zone"
            >
                <input
                    id="files"
                    name="files"
                    type="file"
                    (change)="fileSelectChangeHandler($event)"
                    multiple
                />
                <label for="files">
                    <div *ngIf="!hasFinished && failedUploads.length < 1">
                        <div *ngIf="!hasFileDropped">
                            <div class="uploadIcon">
                                <mat-icon>cloud_upload</mat-icon>
                            </div>
                            <div class="uploadMessage">{{ displayMessage }}</div>
                            <span class="smallLink">{{ 'VAULT.CLICK_BROWSE' | translate }}</span>
                        </div>
                        <app-loader [isLoading]="hasFileDropped && !hasFinished"></app-loader>
                    </div>

                    <div
                        class="error-upload-box"
                        *ngIf="failedUploads.length > 0"
                    >
                        <p>{{ uploadFailCount }}/{{ selectedFileCount }} {{ 'VAULT.FILE_UPLOAD_ERR' | translate }}</p>
                        <mat-list *ngIf="hasFinished && failedUploads.length > 0">
                            <mat-list-item *ngFor="let failedFile of failedUploads; trackBy: trackByIndex">{{ failedFile }}</mat-list-item>
                        </mat-list>
                    </div>
                    <div
                        class="error-upload-box"
                        *ngIf="isWrongFileTypeCSVCopy"
                    >
                        <p>{{ 'VAULT.VAULT_UPLOAD_DIALOG.FILE_PATTERN_ERR' | translate }}</p>
                        <mat-list>
                            <mat-list-item *ngFor="let failedFile of failedUploads; trackBy: trackByIndex">{{ failedFile }}</mat-list-item>
                        </mat-list>
                    </div>
                    <div
                        class="error-upload-box"
                        *ngIf="isWrongFileTypeCopy"
                    >
                        <p>{{ 'VAULT.VAULT_UPLOAD_DIALOG.FILE_TYPE_ERR1' | translate }}</p>
                        <p>{{ 'VAULT.VAULT_UPLOAD_DIALOG.FILE_TYPE_ERR2' | translate }}</p>
                        <mat-list>
                            <mat-list-item *ngFor="let failedFile of failedUploads; trackBy: trackByIndex">{{ failedFile }}</mat-list-item>
                        </mat-list>
                    </div>
                    <div
                        class="error-upload-box"
                        *ngIf="isMaxFileSize"
                    >
                        <p>{{ 'VAULT.FILE_SIZE_ERR' | translate }}</p>
                        <mat-list>
                            <mat-list-item *ngFor="let failedFile of failedUploads; trackBy: trackByIndex">{{ failedFile }}</mat-list-item>
                        </mat-list>
                    </div>
                </label>
            </div>
            <div
                class="maxFilesExceed danger"
                *ngIf="maxFilesExceeded"
            >
                {{ 'VAULT.FILE_SELECTION_ERR' | translate }}
            </div>
        </div>
        <app-vault-upload-overwrite
            *ngIf="overwritableFiles?.length"
            [files]="overwritableFiles"
            (uploadeOverwriteComplete)="uploadeOverwriteComplete($event)"
        ></app-vault-upload-overwrite>
        <app-loader [isLoading]="showProgress"></app-loader>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button
                mat-button
                mat-dialog-close
                *ngIf="!isCsvUpload"
            >{{ 'COMMON.CLOSE' | translate }}</button>
            <button
                mat-button
                mat-dialog-close
                *ngIf="isCsvUpload"
            >{{ 'COMMON.CANCEL' | translate }}</button>
            <button
                *ngIf="overwritableFiles && overwritableFiles.length > 0"
                mat-button
                (click)="filesMarkedForOverwrite()"
            >
                {{ 'COMMON.DONE_BTN' | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</div>