<mat-card-content id="alarmWidgetCardContentId" [ngClass]="{ widgetFixPagination: totalPaginationLength > 10 }">
    <div class="scrollOverlay"></div>
    <div id="alarmWidgetId">
        <app-widget-filters
            (sendDataToWidget)="notifyWidget($event)"
            [enableDateRangeLimit]="false"
            [filterSettings]="alarmFilterSettings"
            [customerID]="customerID"
            [locationGroupID]="locationGroupID"
            [toggleState]="toggleState"
            [includeInactiveLocations]="includeInactiveLocations"
            [hidden]="!showAlarmSearch"
            [limitStartDate]="false"
            *ngIf="alarmFilterSettings"
        >
        </app-widget-filters>
    </div>

    <app-loader [isLoading]="alarmWidgetLoadingState"></app-loader>

    <div *ngIf="alarmWidgetData?.length == 0 || !alarmWidgetData" class="notabledataFound custom-table">
        <span
            >{{ 'COMMON.NO_TEXT' | translate }}
            <span *ngIf="!showAlarmSearch">{{ 'COMMON.ACTIVE_TEXT' | translate }} </span>
            {{ 'HOME.ALARM_WIDGET.ALARM_FOUND' | translate }}</span
        >
    </div>
    <div
        [hidden]="alarmWidgetData?.length === 0 || !alarmWidgetData"
        id="alarmLeaderBoardTable"
        class="clsAlarmLeaderBoardTable filtersOff widget-table"
    >
        <mat-table
            #table
            [dataSource]="alarmWidgetDataSource"
            matSort
            (matSortChange)="sortData()"
            matSortActive="timestamp"
            matSortStart="desc"
        >
            <!-- Status Column -->
            <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef class="alarm-sm-status-hint">{{
                    'COMMON.STATUS_COLUMN_TITLE' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">
                    <span
                        [matTooltip]="
                            activeAlarm.state === 0
                                ? 'Unacknowledged'
                                : activeAlarm.state === 1
                                ? 'Acknowledged'
                                : activeAlarm.state === 2
                                ? 'Cleared'
                                : null
                        "
                    >
                        <button mat-icon-button [disabled]="!isDisableAlarm" (click)="changeAlarmStatus(activeAlarm)">
                            <mat-icon
                                [ngClass]="{
                                    'red-c': activeAlarm.state === 0,
                                    'orange-c': activeAlarm.state === 1,
                                    'gray-c': activeAlarm.state === 2
                                }"
                                >notifications</mat-icon
                            >
                        </button>
                    </span>
                </mat-cell>
            </ng-container>

            <!-- Type -->
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="alarm-sm-type-hint">{{
                    'COMMON.TYPE_COLUMN_TITLE' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">{{ activeAlarm.type }}</mat-cell>
            </ng-container>

            <!-- Date/Time Column -->
            <ng-container matColumnDef="timestamp">
                <mat-header-cell
                    matTooltipPosition="above"
                    *matHeaderCellDef
                    mat-sort-header
                    class="text-right alarm-sm-date-hint"
                    >{{ 'COMMON.TIMESTAMP_COLUMN_TITLE' | translate }}</mat-header-cell
                >
                <mat-cell *matCellDef="let activeAlarm" class="text-right"> {{ activeAlarm.timestamp }} </mat-cell>
            </ng-container>

            <!-- Location Column -->
            <ng-container matColumnDef="location">
                <mat-header-cell
                    *matHeaderCellDef
                    class="alarm-location-card-hint alarm-sm-location-hint"
                    mat-sort-header
                    >{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">
                    <div
                        class="nowrap-white-space fullScreenShow"
                        [ngClass]="{ customLongName: validateLocationNameLength(activeAlarm.location, 48) }"
                        [title]="validateLocationNameLength(activeAlarm.location, 48) ? activeAlarm.location : ''"
                        [innerHTML]="activeAlarm.location | sanitize: 'html'"
                    ></div>
                    <a
                        class="nowrap-white-space fullScreenHide"
                        [ngClass]="{ customLongName: validateLocationNameLength(activeAlarm.location, 25) }"
                        [title]="validateLocationNameLength(activeAlarm.location, 25) ? activeAlarm.location : ''"
                        (click)="getMarkerLocationDetails(activeAlarm.locationID)"
                        [innerHTML]="activeAlarm.location | sanitize: 'html'"
                    ></a>
                </mat-cell>
            </ng-container>

            <!-- GRAPH -->
            <ng-container matColumnDef="graph">
                <mat-header-cell *matHeaderCellDef class="alarm-graph-hint alarm-sm-graph-hint">
                    {{ 'COMMON.GRAPH_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">
                    <a
                        *ngIf="rawDataActive"
                        (click)="
                            openHydrograph(
                                activeAlarm.locationID,
                                activeAlarm.location,
                                activeAlarm.timestamp,
                                activeAlarm.type
                            )
                        "
                    >
                        <mat-icon matTooltip="{{ 'COMMON.GENERATE_GRAPH_TOOLTIP' | translate }}">show_chart</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>

            <!-- Acknowledged By -->
            <ng-container matColumnDef="acknowledgeBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'HOME.ALARM_WIDGET.ACKNOWLEDGE_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">{{ activeAlarm.acknowledgeBy }}</mat-cell>
            </ng-container>

            <!-- Acknowledged Date/Time -->
            <ng-container matColumnDef="acknowledgeTime">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'HOME.ALARM_WIDGET.ACKNOWLEDGE_DATE_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">{{ activeAlarm.acknowledgeTime }}</mat-cell>
            </ng-container>

            <!-- Cleared By -->
            <ng-container matColumnDef="clearBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'HOME.ALARM_WIDGET.CLEARED_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">{{ activeAlarm.clearBy }}</mat-cell>
            </ng-container>

            <!-- Cleared Date/Time -->
            <ng-container matColumnDef="clearTime">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'HOME.ALARM_WIDGET.CLEARED_DATE_TIME_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">{{ activeAlarm.clearTime }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="rtnDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'HOME.ALARM_WIDGET.RTN_DATE_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let activeAlarm">{{ activeAlarm.returnToNormal }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="alarmWidgetDisplayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: alarmWidgetDisplayedColumns"></mat-row>
        </mat-table>
    </div>
</mat-card-content>
<mat-card-actions class="widgetFooter">
    <div class="auto-margin" [hidden]="!(totalPaginationLength > 10)">
        <div class="auto-margin">
            <mat-paginator
                #alarmWidgetPaginator
                [length]="totalPaginationLength"
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 100]"
            >
            </mat-paginator>
        </div>
    </div>
    <div class="app-flex-filler"></div>
    <div *ngIf="!toggleState" class="auto-margin custom-flat-button">
        <button class="alarm-download-hint" mat-button (click)="exportDataToCSV()">
            {{ 'COMMON.DOWNLOAD_BTN' | translate }}
        </button>
    </div>
</mat-card-actions>
