<!-- <div class="file-upload-dialog" *ngIf="!isOverwriting; else overwriteView"> -->
<progress-bars
    [progressBars]="flowloadFileUploadService.importProgressBars[caseStudyId]"
    *ngIf="flowloadFileUploadService.importActive[caseStudyId]"
></progress-bars>
<div class="file-upload-dialog">
    <div
        ng2FileDrop
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver, 'upload-errors': failedUploads.length > 0 }"
        (fileOver)="fileOverBase($event)"
        (onFileDrop)="fileDrop($event)"
        (click)="resetState(); resetErrorMsg(false)"
        [uploader]="uploader"
        class="my-drop-zone"
    >
        <input id="files" name="files" type="file" (change)="fileSelectChangeHandler($event)" multiple />
        <label for="files">
            <div>
                <div class="uploadIcon">
                    <mat-icon>cloud_upload</mat-icon>
                </div>

                <div class="uploadMessage">{{ displayMessage }}</div>
                <!-- <span class="smallLink">{{'VAULT.CLICK_BROWSE' | translate}}</span> -->
                <div class="text-top-space">
                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_UPLOAD_MESSAGE' | translate }}
                </div>
                <span class="smallLink">{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_IMPORT_LINK' | translate }} </span>
            </div>

            <div class="error-upload-box" *ngIf="failedUploads.length > 0">
                <p>{{ uploadFailCount }}/{{ selectedFileCount }} {{ 'VAULT.FILE_UPLOAD_ERR' | translate }}</p>
                <mat-list *ngIf="hasFinished && failedUploads.length > 0">
                    <mat-list-item *ngFor="let failedFile of failedUploads; trackBy: trackByIndex">{{ failedFile }}</mat-list-item>
                </mat-list>
            </div>
            <div class="error-upload-box" *ngIf="isWrongMimeType || isWrongFileExtension">
                <p>{{ 'SLIICER_TABLE.SLIICER_UPLOAD_FILE.FILE_TYPE_ERR1' | translate }}</p>
                <mat-list>
                    <mat-list-item *ngFor="let failedFile of failedUploads; trackBy: trackByIndex">{{ failedFile }}</mat-list-item>
                </mat-list>
            </div>
            <div class="error-upload-box" *ngIf="isMaxFileSize">
                <p>{{ 'VAULT.FILE_SIZE_ERR' | translate }}</p>
                <mat-list>
                    <mat-list-item *ngFor="let failedFile of failedUploads; trackBy: trackByIndex">{{ failedFile }}</mat-list-item>
                </mat-list>
            </div>
        </label>
    </div>
    <div class="maxFilesExceed danger" *ngIf="maxFilesExceeded">
        {{ 'VAULT.FILE_SELECTION_ERR' | translate }}
    </div>
</div>
<!-- <ng-template #overwriteView>
  <app-vault-upload-overwrite [files]="overwritableFiles" (uploadeOverwriteComplete)="uploadeOverwriteComplete($event)">
  </app-vault-upload-overwrite>
</ng-template> -->
<app-loader [isLoading]="showProgress"></app-loader>
