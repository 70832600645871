export interface Units {
    unitId: number;
    unitName: string;
    shortSuffix: string;
    longSuffix: string;
    mediumSuffix: string;
    unitPrecision?: string;
}

export interface EntityUnit {
    id: number;
    name: string;
    displayGroupId: number;
    displayGroupName: string;
    unitId: number;
    unitName: string;
}

export interface ApiResponse<T> {
    payload: T;
    count?: number;
    isError?: boolean;
    message: string;
}

export interface DisplayGroupModel {
    id: number;
    name: string;
}

export  enum UnitCategoryType
{
    Unknown = 0,
    Scalar = 1,
    Linear = 2,
    Area = 3,
    WetWelVolume = 4,
    Volume = 5,
    Velocity = 6,
    Flow = 7,
    Temperature = 8,
    Voltage = 9,
    Pressure = 10,
    Time = 11,
    Count = 12,
    LinearFeet = 13,
    RainIntensity = 14,
    Percentage = 15,
    Sound = 16,
    TotalFlow = 17
}

export interface ApiUnitsRow {
    offset: number;
    factor: number;
    precision: number;
    unitSuffix: string;
    unitCategory: number;
    unitTotalSuffix?: string;
}

export enum UNITS {
    MGD = 'mgd',
    CFS = 'cfs',
    LitSec = 'l/s',
    In = 'in',
    mm = 'mm'
}

export const IN_TO_MM_FACTOR = 25.4;
export const MM_TO_MM_FACTOR = 1;

export const MAX_RAIN_IN = 1;
export const MAX_RAIN_MM = 25;
