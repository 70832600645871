<div id="LocationNotificationConfigurationReport" class="contentLayout">
    <app-loader [isLoading]="isLoading"></app-loader>
    <mat-card>
        <mat-card-header>
            <div class="auto-margin">{{ 'REPORT.NOTIFICATION_CONFIGURATION.NOTIFICATION_CONFIGURATION_TITLE' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button (click)="download()" mat-button>
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    class="backIcon"
                    [ngClass]="{ active: displayFilters }"
                    (click)="toggleFilters()"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <div class="filters" *ngIf="displayFilters">
            <mat-form-field>
                <mat-select [placeholder]="'REPORT.NOTIFICATION_CONFIGURATION.GROUP_BY' | translate " [(ngModel)]="filters.groupByValue" (ngModelChange)="onGroupBy($event)" name="group_by">
                    <mat-option *ngFor="let f of filters.groupByOptions; trackBy: trackByKey" [value]="f.key">
                        {{ f.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-card-content>
            <div id="dataTable" *ngIf="!isLoading && dataSource.data.length > 0">
                <mat-table #table [dataSource]="dataSource">
                    <ng-container matColumnDef="locationName">
                        <mat-header-cell *matHeaderCellDef>{{
                            'COMMON.LOCATION' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="word-overflow-break">
                            {{ row.locationName }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="eventType">
                        <mat-header-cell *matHeaderCellDef>{{
                            'REPORT.NOTIFICATION_CONFIGURATION.EVENT_TYPE' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let row; let i = index">
                            {{notificationAlarms[row.eventType]}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="notificationGroup">
                        <mat-header-cell *matHeaderCellDef>{{
                            'REPORT.NOTIFICATION_CONFIGURATION.NOTIFICATION_GROUP' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{ row.notificationGroup }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="isActiveEvent">
                        <mat-header-cell *matHeaderCellDef>{{
                            'REPORT.NOTIFICATION_CONFIGURATION.ACTIVE_EVENT' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{ (row.isActiveEvent ? translations.YES : translations.NO) }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="isReturnToNormalEvent">
                        <mat-header-cell *matHeaderCellDef>{{
                            'REPORT.NOTIFICATION_CONFIGURATION.RETURN_TO_NORMAL_EVENT' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{ (row.isReturnToNormalEvent ? translations.YES : translations.NO) }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="notificationTree">
                        <mat-header-cell *matHeaderCellDef>{{
                            'REPORT.NOTIFICATION_CONFIGURATION.NOTIFICATION_TREE' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <ng-container *ngFor="let user of row.notificationTree; let isLast=last; trackBy: trackByIndex">{{ user.displayName }}{{isLast ? '' : ', '}}</ng-container>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
                </mat-table>
            </div>
            <div class="notabledataFound custom-table" *ngIf="!isLoading && dataSource.data.length === 0">
                {{ 'ADMIN.NO_DATA_AVAILABLE' | translate }}
            </div>
            <div class="notabledataFound custom-table" *ngIf="isLoading">
                {{ 'COMMON.LOADING' | translate }}
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <mat-paginator
                    [length]="0"
                    [pageIndex]="pagination.pageIndex"
                    [pageSize]="pagination.pageSize"
                    (page)="changePage($event)"
                    [pageSizeOptions]="pagination.pageSizeOptions"
                >
                </mat-paginator>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
