<div id="dailySummaryOverviewId" class="contentLayout">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin dailySummaryOverviewlabelHint">
                {{ 'REPORT.DAILY_SUMMARY.SUMMARY_OVERVIEW_TITLE' | translate }}
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button
                    class="dailySummaryOverviewDownloadHint"
                    (click)="downloadCSV()"
                    mat-button
                    [disabled]="!fullData || fullData?.data.length === 0"
                >
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
        </mat-card-header>
        <mat-card-content *ngIf="fullData as data">
            <app-loader [isLoading]="dataSummaryOverviewLoadingState"></app-loader>
            <div class="tableScrollWraper">
                <table
                    matSort
                    (matSortChange)="sortDailySummaryData($event)"
                    *ngIf="data?.headers?.length > 0 && data?.data?.length > 0"
                    class="
                        light-table light-table-bordered light-table-hover light-table-responsive-vertical
                        black-theme-table
                    "
                >
                    <colgroup>
                        <col *ngFor="let item of data.headers[0]; trackBy: trackByIndex" [attr.span]="item.colspan" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th mat-sort-header="Location" [attr.colspan]="1" class="textAlignCenter">
                                {{ data.headers[0][0].name | uppercase }}
                            </th>
                            <ng-container *ngFor="let item of data.headers[0]; trackBy: trackByIndex">
                                <th
                                    [attr.colspan]="item.colspan"
                                    *ngIf="item.name !== 'Location'"
                                    class="textAlignCenter"
                                >
                                    {{ item.name | uppercase }}
                                    <span *ngIf="item.name !== 'QCONTINUITY'">{{ item.suffix }}</span>
                                    <span *ngIf="item.name === 'QCONTINUITY'"
                                        >({{ item.suffix | slice: 1:-1 }} - Total
                                        {{ item.totalSuffix | slice: 1:-1 }})</span
                                    >
                                </th>
                            </ng-container>
                        </tr>
                        <tr>
                            <th
                                *ngFor="let item of data.headers[1]; trackBy: trackByIndex"
                                [attr.colspan]="item.colspan || 1"
                                class="headerSecondRow textAlignCenter"
                            >
                                {{ item.name | uppercase }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let datum of pageData; trackBy: trackByIndex">
                            <td *ngFor="let item of datum; trackBy: trackByIndex">
                                <div class="dailySummaryValue" *ngIf="!item.isIdentifier; else identityBlock">
                                    <div>
                                        <b>{{ item.value.split('\n')[0] }}</b>
                                    </div>
                                    <div>{{ item.value.split('\n')[1] }}</div>
                                </div>

                                <ng-template #identityBlock>
                                    <a href="javascript:;" (click)="itemSelected($event, item.value, item.name)">{{
                                        item.name
                                    }}</a>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator
                [length]="totalPaginationLength"
                [pageIndex]="0"
                [pageSize]="10"
                (page)="onPaginateChange($event)"
                [pageSizeOptions]="[10, 25, 100]"
                *ngIf="totalPaginationLength > 10"
            >
            </mat-paginator>
        </mat-card-content>

        <ng-template *ngIf="totalPaginationLength === 0">
            <mat-card-content>
                <p>{{ 'REPORT.NO_DATA_IN_OVERVIEW' | translate }}</p>
            </mat-card-content>
        </ng-template>
    </mat-card>
</div>
