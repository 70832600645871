<div class="vault-overwrite-component">
    <p>{{ 'VAULT.VAULT_OVERWRITE_MSG' | translate }}</p>
    <div class="vault-overwrite-item-list">
        <mat-selection-list>
            <mat-list-option *ngFor="let file of files; trackBy: trackByIndex" [value]="file.fileName" (click)="fileSelected($event)">{{
                file.fileName
            }}</mat-list-option>
        </mat-selection-list>
    </div>
</div>
