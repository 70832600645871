export class MonitorSetting {
    public SampleRateUI?: Object;
    public DataLogMode?: Object;
    public TimeZone?: Object;
    public NormalRate?: Object;
    public FastRate?: Object;
    public rainExceedingFlag?: boolean;
    public samplerate?: number;
    public rainpertip?: number;
    public logintensity?: boolean;
    public logukintensity?: boolean;
    public datalogmode?: number;
    public intensityinterval?: number;
    public timezone?: string;
    public usedaylightsaving?: boolean;
    public datadeliveryipaddress?: string;
    public datadeliverynormalrate?: number;
    public datadeliveryfastrate?: number;
    public alarmenable?: boolean;
    public serialnumber?: string;
    public locationid?: number;
    public ipaddress?: string;
    public alarmthreshold?: number;
    public isRainIntensity = false;
    public fastrate?: number;
    public manholedepth?: number;
    public physicaloffset?: number;
    public unidepthtoaverage?: number;
    public lowlevelenable?: boolean;
    public lowlevelthreshold?: number;
    public fullpipeenable?: boolean;
    public highlevelenable?: boolean;
    public highlevelthreshold?: number;
    public highhighenable?: boolean;
    public highhighthreshold?: number;
    public overflowenable?: boolean;
    public tiltenable?: boolean;
    public tiltthreshold?: number;
    public batterylowenable?: boolean;
    public batterylowthreshold?: number;
    public datadeliverynormal?: number;
    public datadeliveryfast?: number;
    public echoFastRate?: Object;
    public echoNormalRate?: Object;
    public echoDataFastRate?: Object;
    public monitorSeries?: string;
    public pipeHeight?: number;
    public customerId?: number;
    public alarmreturntonormal?: number;
    public storetemperature?: boolean;
    public storeBatteryVoltageUI?: Object;
    public storebattery?: number;
    public modem?: boolean;
}

export interface MonitorAddLocationSelectable {
    monitorSeriesID: MonitorIdentifier;
    value: string;
    text: string;
}

export enum MonitorIdentifier {
    None = 0,
    Unknown,
    FlowAlert,
    RainAlertII,
    Gateway,
    FlowShark = 5,
    FlowSharkIS,
    FlowSharkRT,
    OneThousandFiveHundredTwo,
    OneThousandFiveHundredTwoEM,
    OneThousandFiveHundredSix = 10,
    ThreeThousandFiveHundre,
    ThreeThousandFiveHundreS,
    ThreeThounsandSixHundred,
    FourThousand,
    FourThousandFiveHundred = 15,
    FourThousandFiveHundredS,
    FiveThousandFiveHundred,
    FiveThousandSixHundred,
    SevenThousandFiveHundredTen,
    SevenThousandFiveHundredTenPlus = 20,
    Triton,
    FlowHawk,
    ThreeThousand,
    TwoThousand,
    TritonIM = 25,
    TritonPlus,
    RU33,
    LeakTest,
    RainAlertIII,
    Pulse = 30,
    Echo,
    ICE3,
    USGS,
    ForeSITE_UL = 34,
    ForeSITE_XD
}

export interface RainAlertConfiguration {
    rainpertip?: number;
    logintensity?: boolean;
    logukintensity?: boolean;
    datalogmode?: number;
    intensityinterval?: number;
    alarmenable?: boolean;
    alarmthreshold?: number;
    alarmreturntonormal?: number;
    serialnumber?: string;
    ipaddress?: string;
    fastRate?: number;
    locationid?: number;
    samplerate?: number;
    timezone?: string;
    usedaylightsaving?: boolean;
    prismdeliveryipaddress?: string;
    prismdeliverynormal?: number;
    prismdeliveryfast?: number;
    storetemperature?: boolean;
    storebattery?: number;
    connectionType?: number;
    synctime?: boolean;
    modem?: boolean;
    apn?: string;
}

export interface EchoConfiguration {
    fastrate?: number;
    manholedepth?: number;
    pipeheight?: number;
    physicaloffset?: number;
    unidepthtoaverage?: number;
    lowlevelenable?: boolean;
    lowlevelthreshold?: number;
    fullpipeenable?: boolean;
    highlevelenable?: boolean;
    highlevelthreshold?: number;
    highhighenable?: boolean;
    highhighthreshold?: number;
    overflowenable?: boolean;
    tiltenable?: boolean;
    tiltthreshold?: number;
    batterylowenable?: boolean;
    batterylowthreshold?: number;
    serialNumber?: string;
    ipaddress?: string;
    blanking?: number;
    power?: number;
    compensatedTemperatureMarch?: number;
    compensatedTemperatureSeptember?: number;
    pressureStore?: boolean;
    storeData?: boolean;
    temperatureSensor?: number;
    gainControl?: number;
    electronicOffset?: number;
    unidepthFiltering?: boolean;
    hc?: number;
    silt?: number;
    locationid?: number;
    samplerate?: number;
    timezone?: string;
    usedaylightsaving?: boolean;
    prismdeliveryipaddress?: string;
    prismdeliverynormal?: number;
    prismdeliveryfast?: number;
    storetemperature?: boolean;
    storebattery?: number;
    connectionType?: number;
    synctime?: boolean;
    modem?: boolean;
    apn?: string;
}

export enum ConfirmationType {
    LocationDashboard,
    DataCollection,
    ScheduleCollection,
    Close,
    LocationExport,
}

export interface TritonConfiguration {
    fastrate?: number;
    manholedepth?: number;
    pipeheight?: number;
    physicaloffset?: number;
    unidepthtoaverage?: number;
    highlevelthreshold?: number;
    highhighenable?: boolean;
    highhighthreshold?: number;
    overflowenable?: boolean;
    tiltenable?: boolean;
    tiltthreshold?: number;
    batterylowenable?: boolean;
    batterylowthreshold?: number;
    serialNumber?: string;
    ipaddress?: string;
    locationid?: number;
    samplerate?: number;
    timezone?: string;
    usedaylightsaving?: boolean;
    datadeliveryipaddress?: string;
    datadeliverynormal?: number;
    datadeliveryfast?: number;
    storetemperature?: boolean;
    storebattery?: number;
    lowlevelenable?: boolean;
    lowlevelthreshold?: number;
    modem?: boolean;
    fullpipeenable?: boolean;
    highlevelenable: boolean;
    prismdeliveryipaddress: string;
    prismdeliverynormal: number;
    prismdeliveryfast: number;
    ftpdeliverynormal: number;
    ftpdeliveryfast: number;
    ftpfolder: string;
    ftppassword: string;
    ftpusername: string;
    ftpserver: string;
    ftpmode: string;
    ftpformat: string;
    devices: any[];
    synctime?: boolean;
    silts: number[];
    comport: string;
    inclinometerthreshold: number;
    apn?: string;
}
