<mat-expansion-panel [ngClass]="{ 'triton-plus-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ 'ADD_EDIT.FORESITE_XD' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>

        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span> </span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <div [class.disable-field]="!isMonitorEditor">
            <ng-container formArrayName="monitors" *ngFor="let sensor of getMonitors().controls; let i = index; trackBy: trackByIndex">
                <mat-panel-subtitle class="input-text">
                    {{ 'ADD_EDIT.ADD_EDIT_SUBTITLE' | translate }}
                </mat-panel-subtitle>

                <ng-container [formGroupName]="i">
                    <div class="custom-33p">
                        <div class="each-Item">
                            <mat-form-field class="full-width required">
                                <mat-select
                                    placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSOR' | translate }}"
                                    formControlName="sensor"
                                    (selectionChange)="sensorChangeEvent($event.value, i, sensor.value.mp)"
                                >
                                    <mat-option
                                        *ngFor="let sensorData of ForeSITEXDSensors; trackBy: trackById"
                                        [value]="sensorData.value"
                                    >
                                        {{ sensorData.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </form>
</mat-expansion-panel>
