import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdsPrismSingleSelectGroupComponent } from 'app/shared/components/ads-prism-single-select-group/ads-prism-single-select-group.component';
import { NOTIFICATION_TIMEOUT } from 'app/shared/constant';
import { customerQueryParam, locationIdQueryParam } from 'app/shared/models/customer';
import { EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';
import { Locations } from 'app/shared/models/locations';
import {
    LocationData,
    LocationEntitiesData,
    LocationListArgs,
    SeriesEntity,
    SeriesEntityGroup,
} from 'app/shared/models/locations-entities-data';
import { Selectable } from 'app/shared/models/selectable';
import { EntityUnit } from 'app/shared/models/units';
import { HydrographTickInterval, QUICK_DATE_RANGES } from 'app/shared/models/view-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { EntityService } from 'app/shared/services/entity.service';
import { LocationService } from 'app/shared/services/location.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';

import { CustomDashboardService } from '../custom-dashboard.service';
import {
    AccessType,
    CustomGraphs,
    GraphConfiguration,
    GraphType,
    SeriesProperty,
    TimeInterval,
} from './custom-dashboard.model';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { TrackBy } from 'app/shared/utils/track-by';

const HG_ENTITY_ID_KEY = 'eid';
const SG_ENTITY_X_ID_KEY = 'eidX';
const SG_ENTITY_Y_ID_KEY = 'eidY';

@Component({
    selector: 'ads-prism-custom-dashboard-model',
    templateUrl: './custom-dashboard-model.component.html',
    styleUrls: ['./custom-dashboard-model.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsCustomDashboardModelComponent implements OnInit, OnDestroy {
    @ViewChild('entitiesGroupSingleselect') public entitiesGroupSingleselect: AdsPrismSingleSelectGroupComponent;
    public multiLocationDashboardDataSource = new MatTableDataSource([]);
    public maxDate = new Date();
    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date();
    public unitId: number;
    public hydrograph = true;
    public scattergraph: boolean;
    public selectedDataAveraging: number;
    public selectedColor: string;
    public customerId: number;
    public locationId: number;
    public isLoading: boolean;
    public locationList = Array<Locations>();
    public selectedItems: Array<SeriesProperty>;
    public autocompleteData: Array<{ text: string | Locations; locations: Array<Locations> }> = [];
    public timeInterval = new TimeInterval();
    public isRowFilled = true;
    public entityList = Array<EntityUnit>();
    public singleSelectEntityList = {};
    public invalidEntitySelected: boolean;
    public invalidEntitySelectedMessage: string;
    public graphConfiguration: GraphConfiguration = new GraphConfiguration();
    public isUnitSelected = false;
    public selectedLocation: Array<Locations> = [];
    public isCustomDateTouched: boolean;
    public displayStartDateErrMsg: boolean;
    public dashText: string;
    public displayEndDateErrMsg: boolean;
    public invalidStartDate: boolean;
    public invalidEndDate: boolean;
    public invalidDateRange: boolean;
    public isMandatoryFilled: boolean;
    public isSameSGentitiesSelected = false;
    public graphType: string;
    public requiredFieldsMessage: string;
    public dismissBtn: string;
    public snackbarMessage: string;
    public addGraphBeforeSaving: string;
    public addAtLeastOneSeries: string;
    public customerDateFormat: string;
    public excludeSelectedItem = false;
    public tickInterval: HydrographTickInterval;
    private filtergraphTypes: Selectable[];
    public is12HourFormat = false;
    public dateFormat: string;
    public customDateFormat: string;
    public dataAveragingList = [
        { id: 0, text: 'COMMON.NONE' },
        { id: 8, text: 'COMMON.AVERAGING_TWO_MIN' },
        { id: 1, text: 'COMMON.AVERAGING_FIVE_MIN' },
        { id: 2, text: 'COMMON.AVERAGING_FIFTEEN_MIN' },
        { id: 3, text: 'COMMON.AVERAGING_HOUR' },
        { id: 4, text: 'COMMON.AVERAGING_DAILY' },
    ];
    public QUICK_DATE_RANGES = QUICK_DATE_RANGES;

    private subscriptions = new Array<Subscription>();
    public translateKeys: Array<string> = [
        'COMMON.RESERVED_FLAGGED_DATA',
        'COMMON.RESERVED_EDITED_DATA',
        'COMMON.RESERVED_SELECTED_DATA',
        'COMMON.RESERVED_RAIN_DATA',
    ];
    public flaggedColorReserved: string;
    public editedColorReserved: string;
    public selectdColorReserved: string;
    public rainColorReserved: string;
    public entitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};
    public ansrEntitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};
    // #30084 Those are modified. If selector is twice.
    public entitiesByLocationClone: { [key: number]: EntitySelectorObject[] } = {};
    public ansrEntitiesByLocationClone: { [key: number]: EntitySelectorObject[] } = {};

    public isRollingDate = true;
    private selectedDateSpan = 1;
    public showpageHint = false;
    public hintSelectedItems = [{ lid: -1, eid: -1, color: '', eidX: -1, eidY: -1, eidXName: '', eidYName: '' }];
    public isDirty = false;

    public trackByIndex = TrackBy.byIndex;
    
    constructor(
        private dialogRef: MatDialogRef<AdsCustomDashboardModelComponent>,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private customDashboardService: CustomDashboardService,
        private dateutilService: DateutilService,
        private locationService: LocationService,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: GraphConfiguration,
        private snackBarNotificationService: SnackBarNotificationService,
        private entityService: EntityService,
        private domOperationService: DomOperationUtilsService,
    ) {
        this.graphConfiguration.graphType = GraphType.Hydrograph;
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.flaggedColorReserved = translateValues['COMMON.RESERVED_FLAGGED_DATA'];
            this.editedColorReserved = translateValues['COMMON.RESERVED_EDITED_DATA'];
            this.selectdColorReserved = translateValues['COMMON.RESERVED_SELECTED_DATA'];
            this.rainColorReserved = translateValues['COMMON.RESERVED_RAIN_DATA'];
        });
    }

    public ngOnInit() {
        this.subscriptions.push(this.domOperationService.showpageHint.subscribe((data) => {
            this.showpageHint = data;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }));
        this.isLoading = true;
        this.applyTranslations();
        this.selectedDataAveraging = this.dataAveragingList.find((x) => x.id === 0).id;
        const activatedRouteSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerId = Number(params.get(customerQueryParam));
            this.locationId = Number(params.get(locationIdQueryParam));
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        this.subscriptions.push(activatedRouteSubscription);

        const QuickRangeInDateFilter = this.statusCodeService.setQuickRangeInDateFilter.subscribe((val) => {
            this.selectedDateSpan = val;
        });
        this.subscriptions.push(QuickRangeInDateFilter);

        this.dateutilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat =
                this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
            // this.isLoading = false;
        });
        this.statusCodeService.timeInterval.next('lastWeek');

        this.loadEntities();
        if (this.data && this.data.name) {
            this.isUnitSelected = true;
            this.graphConfiguration.name = this.data.name;
            this.graphConfiguration.graphType =
                this.data.graphType === 0 ? GraphType.Hydrograph : this.data.graphType === 1 ? GraphType.Scattergraph : this.data.graphType;
            this.selectedDataAveraging = +this.data.dataAverage;
            this.graphConfiguration.dataAverage = this.data.dataAverage;
            this.graphConfiguration.startDate = this.dateutilService.getUTCDate(new Date(this.data.startDate));
            this.startDate = new Date(this.dateutilService.getUTCDate(new Date(this.data.startDate)));
            this.graphConfiguration.endDate = this.dateutilService.getUTCDate(new Date(this.data.endDate));
            this.endDate = new Date(this.dateutilService.getUTCDate(new Date(this.data.endDate)));
            this.selectedItems = this.data.series;
            this.autocompleteData = (this.data.series as SeriesProperty[]).map((x) => ({ text: '', locations: [] })); // TODO:
            this.graphConfiguration.graphId = this.data.graphId;
            this.graphConfiguration.rolling = this.data.rolling;
            this.isRollingDate = this.data.rolling;
            this.isMandatoryFilled = true;
            this.selectedDateSpan = this.data.dateSpan;
            this.isRowFilled = false;
            if(this.data.graphType === 0) {
              this.showHydroGraph({value:0})
            }
            else if(this.data.graphType === 1){
              this.showScatterGraph({value:1})
            }
          } else {
            this.graphConfiguration.startDate = this.startDate;
            this.graphConfiguration.endDate = this.endDate;
            this.selectedItems = [
              {
                lid: -1, eid: -1, color: '', eidX: -1, eidY: -1, eidXName: '', eidYName: ''
              }];
            this.autocompleteData = [{ text: '', locations: [] }];
        }

        this.domOperationService.customDashboardDialogGraphType.next(String(this.graphConfiguration.graphType));
        this.subscriptions.push(this.domOperationService.customDashboardDialogGraphType.subscribe((type: string) => {
            this.graphConfiguration.graphType = String(type);
            this.graphType = String(type);

            this.hydrograph = Number(type) === 0 ? true : false;
            this.scattergraph = Number(type) === 1 ? true : false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }));
        this.dashText = this.startDate && this.endDate ? '-' : '';
        // set time format to UI
        this.dateFormat = this.dateutilService.getFormat();
        this.is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customDateFormat = this.is12HourFormat
            ? `${this.dateFormat}, ${'hh:mm a'}`
            : `${this.dateFormat}, ${'HH:mm'}`;
    }

    public ngOnDestroy() {
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }
    }

    public close() {
        this.dialogRef.close({ success: false });
    }

    public showHydroGraph(event) {
      this.graphType = event ? event.value : GraphType.Hydrograph;
      this.hydrograph = true;
      this.scattergraph = false;
      this.domOperationService.customDashboardDialogGraphType.next(String(0));
    }

    public showScatterGraph(event) {
      this.graphType = event ? event.value : GraphType.Scattergraph;
      this.hydrograph = false;
      this.scattergraph = true;
      this.domOperationService.customDashboardDialogGraphType.next(String(1));
    }

    public hideScatterGraph() {
      this.scattergraph = true;
      this.hydrograph = false;
    }

    public selectedStartDate(date?: Date) {
        if (date) {
            this.startDate = date;
        }
    }

    public selectedEndDate(date?: Date) {
        if (date) {
            this.endDate = date;
        }
    }

    public selectedGroupEntity(selectedEntities: EntitySelectorEntity[], selectedSeries: SeriesProperty) {
        if (!this.selectedItems) {
            return;
        }
        this.invalidEntitySelected = false;
        const index = this.selectedItems.indexOf(selectedSeries);

        if (index === -1) {
            return;
        }

        if (!this.checkIfSameEntity(this.selectedItems[index], selectedEntities[0], HG_ENTITY_ID_KEY)) {
          this.isDirty = true;
        }
        if (selectedEntities && selectedEntities.length) {
            this.selectedItems[index].eid = selectedEntities[0].id;
        } else {
            this.selectedItems[index].eid = null;
        }

        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.enableAddMore();
    }

    private checkIfSameEntity(selectedItem: SeriesProperty, selectedEntity: EntitySelectorEntity, key: string) {
        if (!selectedEntity && !selectedItem[key]) {
            return true;
        }

        if (!selectedEntity && selectedItem[key]) {
            return false;
        }

        return selectedEntity.id === selectedItem[key];
    }

    public selectedGroupEntityScatterY(selectedEntities: EntitySelectorEntity[], selectedSeries: SeriesProperty) {
      this.invalidEntitySelected = false;
      const index = this.selectedItems.indexOf(selectedSeries);

      if (index === -1) {
        return;
      }

      if (!this.checkIfSameEntity(this.selectedItems[index], selectedEntities[0], SG_ENTITY_Y_ID_KEY)) {
        this.isDirty = true;
      }

      if (selectedEntities && selectedEntities.length) {
        this.selectedItems[index].eidY = selectedEntities[0].id;
        this.selectedItems[index].eidYName = selectedEntities[0].name;
      } else {
        this.selectedItems[index].eidY = null;
        this.selectedItems[index].eidYName = null;
      }

      this.validateSGentities(index);
      this.enableAddMore();
      this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public selectedGroupEntityScatterX(selectedEntities: EntitySelectorEntity[], selectedSeries: SeriesProperty) {
      this.invalidEntitySelected = false;
      const index = this.selectedItems.indexOf(selectedSeries);

      if (index === -1) {
        return;
      }

      if (!this.checkIfSameEntity(this.selectedItems[index], selectedEntities[0], SG_ENTITY_X_ID_KEY)) {
        this.isDirty = true;
      }

      if (selectedEntities && selectedEntities.length) {
        this.selectedItems[index].eidX = selectedEntities[0].id;
        this.selectedItems[index].eidXName = selectedEntities[0].name;
      } else {
        this.selectedItems[index].eidX = null;
        this.selectedItems[index].eidXName = null;
      }

      this.validateSGentities(index);
      this.enableAddMore();
      this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private validateSGentities(index: number) {
        if (this.hydrograph) {
            this.isSameSGentitiesSelected = false;
            return;
        }

        this.isSameSGentitiesSelected = this.selectedItems.some((v) => v.eidX && v.eidY && v.eidX === v.eidY);
    }

    public scatterEntitiesDifferent(): boolean {
        if(this.graphConfiguration.graphType !== Number(GraphType.Scattergraph)) return true;

        for(const el of this.selectedItems) {
            if(el.eidY === el.eidX) return false;
        }
        return true;
    }

    private loadEntities() {
        // for some reason the subscribe looses focus of this and TS is unable to capture it in debug
        const self = this;

        const loadEntitiesSubscription = this.locationService
            .getLocationData(<LocationListArgs>{
                cid: this.customerId,
                IncludeInactiveLocations: this.statusCodeService.activeInactiveHandler.getValue(),
            })
            .subscribe((entityData: LocationEntitiesData | never) => {
                if (!entityData) {
                    loadEntitiesSubscription.unsubscribe();
                    return;
                }

                entityData.l = entityData.l.map((l) => {
                    if (l.s === 'Echo') {
                        l.s = 'ECHO';
                    }
                    return l;
                });
                entityData.d = entityData.d.map((d) => {
                    if (d.s === 'Echo') {
                        d.s = 'ECHO';
                    }
                    return d;
                });

                // handle entities
                this.distributeEntitiesAndLocations(entityData);
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                loadEntitiesSubscription.unsubscribe();
            });
    }

    private distributeEntitiesAndLocations(data: LocationEntitiesData) {
        this.locationList = data.l.map(
            (locData: LocationData) =>
                <Locations>{
                    locationId: locData.lid,
                    name: locData.n,
                    ae: locData.ae,
                    locationName: locData.n,
                    latitude: locData.lat,
                    longitude: locData.lon,
                    locationType: locData.t,
                    status: locData.st,
                    series: locData.s,
                },
        );

        for (let i = 0; i < this.selectedItems.length; i++) {
            const item = this.selectedItems[i];
            this.autocompleteData[i].locations = this.locationList;

            const location = this.locationList.find((x) => x.locationId === item.lid);
            this.autocompleteData[i].text = location ? location : '';
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.entitiesByLocation = data.l.reduce((acc, val) => {
            const groups = this.entityService.getSelectedDevices(data, val.s, val.t);
            if (!groups) { return acc; }

            const entityGroups = [...this.entityService.seriesEntityGroupToEntitySelectorObject([...groups[0].g])];

            return {
                ...acc,
                [val.lid]: entityGroups,
            };
        }, {});

        this.ansrEntitiesByLocation = data.l.reduce((acc, val) => {
            const entityGroups = [...this.entityService.ansrEntityGroupToEntitySelectorObject([...val.ae], [], true)];

            return {
                ...acc,
                [val.lid]: entityGroups,
            };
        }, {});

        // #30084 Those are modified. If selector is twice.
        this.entitiesByLocationClone = cloneDeep(this.entitiesByLocation);
        this.ansrEntitiesByLocationClone = cloneDeep(this.ansrEntitiesByLocation);
    }

    /**
     * Process and distribute entiteis
     */
    private processEntities(seriesGroup: SeriesEntityGroup) {
        // map the selectable group
        const selectableGroup = {
            groupName: seriesGroup.name,
            name: seriesGroup.name,
            id: seriesGroup.id,
            items: seriesGroup.entities.map(
                (entity: SeriesEntity) =>
                    <Selectable>{
                        id: entity.id,
                        name: entity.e,
                    },
            ),
        };

        return selectableGroup;
    }

    /** Method to handle event data emitted by ads-prism-single-select-group to set validity of entity/entity name manually entered by user
     * @param isEntityInvalid is used for showing error message and for validation check if entity selected is valid
     */
    public handleEntityInput(isEntityInvalid: boolean) {
        this.invalidEntitySelected = isEntityInvalid;
    }

    public removeRow(index: number) {
        if (index > -1) {
            this.selectedItems.splice(index, 1);
            this.autocompleteData.splice(index, 1);
        }
        if (!this.selectedItems || this.selectedItems.length === 0) {
            this.isRowFilled = true;
        }
        this.enableAddMore();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public addMoreRow() {
        this.selectedItems.push({
            lid: -1,
            eid: -1,
            color: '',
        });
        this.autocompleteData.push({ text: '', locations: this.locationList });
        this.isRowFilled = true;
        this.isMandatoryFilled = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public locationChoosed(index: number, loc: Locations) {
        this.markAsDirty();
        const lid = loc.locationId;
        this.selectedItems[index].lid = lid;
        this.selectedItems[index].locationName = loc.locationName;
        this.enableAddMore(index, lid);
    }

    public enableAddMore(i?, id?) {
        if (this.hydrograph) {
          this.isRowFilled = this.selectedItems.some(x => x.lid === -1 || x.eid === -1 || x.eid === null || x.color === '') && this.autocompleteData.some(x => typeof x.text !== 'string');
          this.isMandatoryFilled = !(this.isRowFilled && this.startDate && this.endDate);
        }
        if(this.scattergraph) {
          this.isRowFilled = !this.isSameSGentitiesSelected && this.selectedItems.some(x => x.lid === -1 || x.eidX === -1 || x.eidX === null || x.eidY === -1 || x.eidY === null || x.color === '') && this.autocompleteData.some(x => typeof x.text !== 'string');
          this.isMandatoryFilled = !(this.isRowFilled && this.startDate && this.endDate);
        }
    }

    public selectedColorPicker($event, selectedSeries: SeriesProperty) {
        const index = this.selectedItems.indexOf(selectedSeries);
        if (index !== -1) {
            this.selectedItems[index].color = $event;
        }
        if (this.uiUtilsService.reserveColors && this.uiUtilsService.reserveColors.indexOf($event) >= 0) {
            this.isRowFilled = true;
            this.isMandatoryFilled = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            this.snackBarNotification(
                this.reserveColorsMessage($event),
                'custom-error-snack-bar',
                false
            );
            return;
        }
        this.enableAddMore();
    }

    public reserveColorsMessage(color) {
        let reserveColorsMessage = '';
        switch (color) {
            case '#FF0000':
                reserveColorsMessage = this.flaggedColorReserved;
                break;
            case '#FFAD26':
                reserveColorsMessage = this.editedColorReserved;
                break;
            case '#FFFF00':
                reserveColorsMessage = this.selectdColorReserved;
                break;
            case '#1F1F9C':
                reserveColorsMessage = this.rainColorReserved;
                break;
            default:
                break;
        }
        return reserveColorsMessage;
    }

    public snackBarNotification(message: string, notificationClass, auto) {
        this.snackBarNotificationService.raiseNotification(message, this.dismissBtn, {
            panelClass: notificationClass,
        }, auto);
    }

    public saveGraph() {
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        const graphConfiguration = new GraphConfiguration();
        if (this.graphConfiguration.name) {
            graphConfiguration.name = this.graphConfiguration.name;
        } else {
            this.snackBar.open(this.addGraphBeforeSaving, this.dismissBtn, {
                panelClass: 'custom-error-snack-bar',
            });
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }
        graphConfiguration.startDate = this.dateutilService.getStartDateAsTimeZone(this.startDate);
        graphConfiguration.endDate = this.dateutilService.getEndDateAsTimeZone(this.endDate);
        if (this.selectedItems.length) {
            graphConfiguration.series = this.selectedItems;
        } else {
            this.snackBar.open(this.addAtLeastOneSeries, this.dismissBtn, {
                panelClass: 'custom-error-snack-bar',
            });
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }
        graphConfiguration.graphType = this.graphConfiguration.graphType;
        graphConfiguration.rolling = this.isRollingDate;
        graphConfiguration.dateSpan = this.selectedDateSpan;
        graphConfiguration.users = graphConfiguration.users || [];
        graphConfiguration.graphAccess = AccessType.Public;
        if (this.graphConfiguration.graphId) {
            graphConfiguration.graphId = this.graphConfiguration.graphId;
        }
        graphConfiguration.dataAverage = this.selectedDataAveraging;
        this.customDashboardService.saveCustomDashboard(this.customerId, graphConfiguration).subscribe(
            (graph: CustomGraphs) => {
                this.isLoading = false;
                this.statusCodeService.timeInterval.next(0);
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.dialogRef.close({ success: true, data: graph.configurations });
            },
            (err) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    private applyTranslations() {
        const translationKeys: Array<string> = [
            'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE',
            'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FILL_REQUIRED_WARNING_MESSAGE',
            'COMMON.DISMISS_TEXT',
            'DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_GRAPH_BEFORE_SAVING',
            'DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_ATLEAST_ONE_SERIES',
        ];
        this.translate.get(translationKeys).subscribe((values) => {
            this.invalidEntitySelectedMessage = values['CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE'];
            this.requiredFieldsMessage =
                values['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FILL_REQUIRED_WARNING_MESSAGE'];
            this.dismissBtn = values['COMMON.DISMISS_TEXT'];
            this.addGraphBeforeSaving = values['DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_GRAPH_BEFORE_SAVING'];
            this.addAtLeastOneSeries = values['DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_ATLEAST_ONE_SERIES'];
        });
    }

    private monthDifference(startDateValue: Date, endDateValue: Date): boolean {
        if (!startDateValue || !endDateValue) {
            return false;
        }
        return endDateValue < startDateValue;
    }

    public locationSearchDisplay(obj): string {
        return obj && obj.name && obj.name.length > 0 ? obj.name : '';
    }

    public filterLocations(i: number, search: { text: string | Locations; locations: Locations[] }): Array<Locations> {
        if (!this.locationList) return [];

        if (typeof search.text === 'string') {
            this.isRowFilled = true;
            this.isMandatoryFilled = false;
        }

        const text = typeof search.text === 'string' ? search.text : search.text.locationName;

        this.autocompleteData[i].locations = this.locationList.filter((x) =>
            x.locationName.toLowerCase().includes(text.toLowerCase()),
        );
    }

    public onDateTypeChange(value: boolean) {
        this.markAsDirty();
        this.isRollingDate = value;
    }

    public markAsDirty() {
        this.isDirty = true;
    }
}
