<div id="gis-add-edit-dialog" style="overflow: hidden">
    <!-- ADD GIS Services -->
    <form #gisaddmore="ngForm">
        <div class="popupHeader" mat-dialog-title>
            <span *ngIf="!validated && !editMode">ADD CONFIGURATION</span>
            <span *ngIf="validated || editMode">EDIT CONFIGURATION</span>
            <span class="app-flex-filler"></span>
        </div>
        <div mat-dialog-content ng2FileDrop>
            <p *ngIf="hasError" style="color: red">{{ errorMessage }}</p>
            <div class="custom-error full-width">
                <mat-form-field class="full-width edit-cus-gis-type-hint">
                    <mat-select
                        placeholder="{{ PAGE_TEXT.SERVICE_TYPE }}"
                        [(ngModel)]="gisServicetype"
                        name="gisServicetype"
                        class="full-width"
                        id="gisServicetypeInput"
                        #gisServicetypeInput="ngModel"
                        required
                    >
                        <mat-option *ngFor="let type of servicetypelist; trackBy: trackByName" [value]="type.name">{{
                            type.text
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="custom-error full-width">
                <mat-form-field class="full-width edit-cus-gis-mapping-hint">
                    <mat-label>{{ PAGE_TEXT.MAPPING_SERVICE }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [(ngModel)]="gisServiceurl"
                        name="gisServiceurl"
                        id="gisServiceurlInput"
                        #gisServiceurlInput="ngModel"
                        placeholder="https://xxxxxxx.xxx/xxxx/wms"
                        [pattern]="urlPattern"
                        required
                    />
                </mat-form-field>
                <div class="custom-error-message" *ngIf="gisServiceurlInput?.errors?.pattern">
                    {{ PAGE_TEXT.INVALID_URL }}
                </div>
            </div>
            <div class="single-line">
                <mat-form-field class="edit-cus-gis-auth-hint">
                    <mat-select
                        placeholder="Authentication Method"
                        [(ngModel)]="gisServicemethod"
                        name="gisServicemethod"
                    >
                        <mat-option
                            *ngFor="let item of authMethodlist; trackBy: trackByName"
                            [value]="item.name"
                            (click)="authMethodChange(item)"
                            >{{ item.text }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="gisServicemethod === 'basic'" class="edit-cus-gis-username-hint">
                    <mat-label>{{ PAGE_TEXT.USERNAME }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [(ngModel)]="gisServiceusername"
                        name="gisServiceusername"
                        placeholder="username"
                    />
                </mat-form-field>
                <mat-form-field *ngIf="gisServicemethod === 'basic'" class="edit-cus-gis-password-hint">
                    <mat-label>{{ PAGE_TEXT.PASSWORD }}</mat-label>
                    <input
                        matInput
                        type="password"
                        [(ngModel)]="gisServicepassword"
                        name="gisServicepassword"
                        placeholder="password"
                    />
                </mat-form-field>
                <mat-form-field *ngIf="gisServicemethod === 'custom'" class="edit-cus-gis-params-hint">
                    <mat-label>{{ PAGE_TEXT.AUTH_PARAMETERS }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [(ngModel)]="gisServiceauth"
                        name="gisServiceauth"
                        placeholder="username=xxx&password=xxx"
                    />
                </mat-form-field>
            </div>
            <div class="single-line" *ngIf="['basic', 'custom'].includes(gisServicemethod)">
                <div class="custom-error full-width">
                    <mat-form-field class="full-width edit-cus-gis-token-hint">
                        <mat-label>{{ PAGE_TEXT.GETTOKEN_SERVICE }}</mat-label>
                        <input
                            matInput
                            type="text"
                            [(ngModel)]="gisServiceAuthurl"
                            name="gisServiceAuthurl"
                            id="gisServiceAuthurlInput"
                            #gisServiceAuthurlInput="ngModel"
                            placeholder="https://xxxxxxx.xxx/xxxx/xxxx/generateToken"
                            [pattern]="urlPattern"
                        />
                    </mat-form-field>
                    <div class="custom-error-message" *ngIf="gisServiceAuthurlInput?.errors?.pattern">
                        {{ PAGE_TEXT.INVALID_URL }}
                    </div>
                </div>
            </div>
        </div>

        <div mat-dialog-actions>
            <button mat-button (click)="close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
            <button mat-button [disabled]="gisaddmore.form.invalid" (click)="uiSave()">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </form>
</div>
