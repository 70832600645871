<mat-expansion-panel [ngClass]="{ 'triton-plus-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ 'ADD_EDIT.ADD_EDIT_TRITON' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>

        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span> </span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <div [class.disable-field]="!isMonitorEditor">
            <ng-container formArrayName="monitors" *ngFor="let sensor of getMonitors().controls; let i = index; trackBy: trackByIndex">
                <mat-panel-subtitle class="input-text" *ngIf="!i">
                    {{ 'ADD_EDIT.ADD_EDIT_SUBTITLE' | translate }}
                </mat-panel-subtitle>
                <mat-panel-subtitle class="input-text" *ngIf="i">
                    {{ 'ADD_EDIT.ADD_EDIT_SECOND_SUBTITLE' | translate }}
                </mat-panel-subtitle>

                <ng-container [formGroupName]="i">
                    <div class="custom-33p">
                        <div class="each-Item">
                            <mat-form-field class="full-width required">
                                <mat-select
                                    placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SENSOR' | translate }}"
                                    formControlName="sensor"
                                    (selectionChange)="sensorChangeEvent($event.value, i, sensor.value.mp)"
                                >
                                    <mat-option
                                        *ngFor="let sensorData of getSensorList(sensor.value, i); trackBy: trackByIndex"
                                        [value]="sensorData.value"
                                    >
                                        {{ sensorData.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width required">
                                <mat-select
                                    placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MONITORING_POINT' | translate }}"
                                    class="full-width"
                                    formControlName="mp"
                                    (selectionChange)="onMpChange(i, $event.value)"
                                >
                                    <mat-option
                                        *ngFor="let monitoringPoint of monitoringPoints; trackBy: trackByIndex"
                                        [value]="monitoringPoint.value"
                                    >
                                        {{ monitoringPoint.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width required">
                                <mat-select
                                    placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_CHANNEL' | translate }}"
                                    class="full-width"
                                    (selectionChange)="onChannelChange($event.value, i)"
                                    formControlName="channel"
                                >
                                    <mat-option *ngFor="let channel of channelList; trackBy: trackByIndex" [value]="channel.value">
                                        {{ channel.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <!-- TODO -->
                            <!-- <div class="mat-error" *ngIf="channelOneError">
                {{ 'COMMON.TRITON_CHANNEL_ERROR' | translate }}
              </div> -->
                        </div>
                    </div>

                    <div class="custom-33p">
                        <div class="each-Item custom-flat-button slide-active-display">
                            <mat-form-field class="full-width required">
                                <input
                                    matInput
                                    [placeholder]="siltPlaceholder"
                                    readonly
                                    type="number"
                                    formControlName="silt"
                                    [name]="i ? 'siltTwo' : 'siltOne'"
                                />
                            </mat-form-field>
                            <div class="mat-error" *ngIf="getMonitors() && getMonitors()?.get(i + '')?.get('silt').errors?.pattern">
                                {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                            </div>
                            <span
                                matTooltip="{{ 'COMMON.SILT_EDITOR' | translate }}"
                                matTooltipPosition="above"
                                *ngIf="userHasPermissionOnEdit || customerHasBasicEditPermission"
                            >
                                <button
                                    mat-button
                                    class="no-margin"
                                    (click)="openSiltEditor(i)"
                                    [disabled]="
                                        i
                                            ? !data.editMode ||
                                              !getMonitors().controls[1].get('sensor').value ||
                                              !details[i]
                                            : !data.editMode
                                    "
                                >
                                    <mat-icon class="mat-24">edit</mat-icon>
                                </button>
                            </span>
                            <!-- TODO -->
                            <!-- <div class="mat-error"
                *ngIf="(tritonSensorOne.tritonSilt?.errors?.pattern || (tritonSensorOne.tritonSilt?.value < 0.00) || (tritonSensorOne.tritonSilt?.value > addTritonLocationService.pipe.height))">
                {{ 'COMMON.TRITON_SILT_PRECISION_ERROR' | translate }}
              </div> -->
                        </div>
                        <div class="app-flex-filler" *ngIf="!getMonitors()?.get(i + '').get('velocitySensor')"></div>

                    </div>
                </ng-container>
                <div class="divider m-25" *ngIf="i === 0"></div>
            </ng-container>
            <ng-container formArrayName="monitors" *ngFor="let sensor of getMonitors().controls; let i = index; trackBy: trackByIndex">
                <ng-container
                    [formGroupName]="i"
                    *ngIf="(getMonitors()?.get(i + '').get('depthSensor') || getMonitors()?.get(i + '').get('velocitySensor'))
                    && (i === 0 || (i !== 0 && getMonitors()?.get('0')?.get('mp')?.value !== getMonitors()?.get(i + '')?.get('mp')?.value))"
                    >
                    <mat-panel-subtitle class="input-text" *ngIf="!i">
                        {{ 'ADD_EDIT.MP1_SENSOR_PREFERENCE' | translate }}
                    </mat-panel-subtitle>
                    <mat-panel-subtitle class="input-text" *ngIf="i">
                        {{ 'ADD_EDIT.MP2_SENSOR_PREFERENCE' | translate }}
                    </mat-panel-subtitle>

                    <hr />
                    <ng-container>
                        <div class="custom-33p">
                            <div class="each-Item" *ngIf="getMonitors()?.get(i + '').get('depthSensor')">
                                <mat-form-field class="full-width required">
                                    <mat-select
                                        placeholder="{{ 'ADD_EDIT.INACTIVE_TOGGLE_DEPTH' | translate }}"
                                        formControlName="depthSensor"
                                        >

                                        <mat-option
                                            *ngFor="let sensorData of (
                                                i === 0 ?
                                                    (getMonitors()?.length > 0 && getMonitors()?.get('0')?.get('mp')?.value === getMonitors()?.get('1')?.get('mp')?.value)
                                                    ? depthMergedPreferences
                                                    : depthPreferencesFirst
                                                : depthPreferencesSecond);
                                                trackBy: trackByIndex"
                                            [value]="sensorData.value"
                                        >{{ sensorData.text }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="each-Item" *ngIf="getMonitors()?.get(i + '').get('velocitySensor')">
                                <mat-form-field class="full-width required">
                                    <mat-select
                                        placeholder="{{ 'ADD_EDIT.INACTIVE_TOGGLE_VELOCITY' | translate }}"
                                        formControlName="velocitySensor"
                                    >
                                        <mat-option
                                            *ngFor="let sensorData of (
                                                i === 0 ?
                                                    (getMonitors()?.length > 0 && getMonitors()?.get('0')?.get('mp')?.value === getMonitors()?.get('1')?.get('mp')?.value)
                                                    ? velMergedPreferences
                                                    : velPreferencesFirst
                                                : velPreferencesSecond);
                                                trackBy: trackByIndex"
                                            [value]="sensorData.value"
                                        >{{ sensorData.text }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <hr />
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.COMM_PORT' | translate }}"
                            class="full-width"
                            formControlName="comport"
                            (selectionChange)="onCommPortChange($event)"
                        >
                            <mat-option value="None">{{ 'COMMON.NONE' | translate }}</mat-option>
                            <mat-option value="Inclinometer">{{ 'COMMON.INCLINOMETER' | translate }}</mat-option>
                            <mat-option value="Sampler"> {{ 'COMMON.SAMPLER' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item" *ngIf="form.get('comport').value === 'Inclinometer'">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.TILT_ALARM_THRESHOLD' | translate }} "
                            id="TiltAlarmThreshold"
                            formControlName="inclinometerthreshold"
                        />
                    </mat-form-field>
                    <div class="mat-error"
                        *ngIf="form.controls['inclinometerthreshold'].invalid">
                            {{ 'ADD_EDIT.TILT_ALARM_THRESHOLD_ERROR_MESSAGE' | translate }}
                        </div>
                </div>
            </div>

            <div class="mat-error" *ngIf="getMonitors() && getMonitors()?.errors?.sameSensors">
                {{ 'ADD_EDIT.LC_SAME_SENSORS_ERROR' | translate }}
            </div>
        </div>
    </form>
</mat-expansion-panel>
