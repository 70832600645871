export interface GetAlarmsArgs {
    customerId: number;
    StartTime?: string;
    EndTime?: string;
    LocationIds?: Array<number>;
    LocationGroupID?: number;
    Active?: boolean;
    Acknowledged?: boolean;
    Cleared?: boolean;
    UserIds?: Array<number>;
    AlarmDefinitionIds?: Array<number>;
    IncludeInactiveLocations?: boolean;
}

export interface AlarmsTotal {
    alarmstotal: number;
    timestamp: string;
}

export enum AlarmTypes {
    LOW_BATTERY_ALARM = 1,
    HIGH_HIGH_ALARM = 2,
    HIGH_LEVEL_ALARM = 3,
    LOW_LEVEL_ALARM = 4,
    RAIN_ALARM = 5,
    LOW_FLOW_ALARM = 7,
    SUBMERGED_ALARM = 8,
    TILT_ALARM = 10,
    FULL_PIPE_ALARM = 11,
    OVERFLOW_ALARM = 12,
    WET_OVERFLOW_ALARM = 13,
    DRY_OVERFLOW_ALARM = 14,
    HIGH_FLOW_ALARM = 15
}

export enum DailyReportTypes {
    COMMUNICATION = 0,
    LOW_BATTERY = 1,
}

export interface AcknowledgeAlarms {
    startTime?: string,
    endTime?: string,
    locationIds?: number[],
    locationGroupId?: number,
    alarmDefinitionIds?: number[]
}
