import { OnInit, Component, ViewChild, Input } from '@angular/core';
import { REGEX_CONFIG } from '../../../../utils/regex-utils';
import { FormGroup, NgForm } from '@angular/forms';
import {
    DeadBandList,
    DirectionList,
    FlowList,
    MaxVelocityMetricValues,
    MaxVelocityNonMetricValues,
    MinVelocityList,
    VelocityAcquisition,
    VelocityList,
} from 'app/shared/components/location-card/location-card.constants';
import { TranslateService } from '@ngx-translate/core';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { LocationFormBuilder } from 'app/shared/components/location-card/services/location-form-builder';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UsersService } from 'app/pages/admin/users.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-add-edit-location-avGated-sensor',
    templateUrl: './add-triton-location-avGated-sensor.component.html',
    styleUrls: ['./add-triton-location-avGated-sensor.component.scss'],
})
export class AddTritonLocationAvGatedSensorComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() public index: 0 | 1;
    @Input() public monitoringPoint: number;
    @Input() monitorConfigs: TritonConfiguration;

    public flowList = FlowList;
    public bandList = [];
    public directionList = DirectionList(false);
    public minVelocityList = MinVelocityList;
    public velocityList = VelocityList;
    public velocityAcquisition = VelocityAcquisition;
    public maxVelocityValues = [];

    public velocityMaximumAVGatedValue = 0;
    public isInvalidVelocityMax: boolean;
    // Reg Ex. Pattern for numeric -+ Decimal value 2Place
    public negativeWithTwoDecimalPattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public isMonitorEditor = false;
    public isMetric: boolean;

    public trackByValue = TrackBy.byValue();
    constructor(
        public translateService: TranslateService,
        private usersService: UsersService,
        public locationCardService: LocationCardService,
        private locationFormBuilder: LocationFormBuilder,
    ) {}

    public ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        this.bandList = DeadBandList(this.locationCardService.isMetric);

        this.isMetric = this.locationCardService.isMetric;
        this.maxVelocityValues = this.isMetric ? MaxVelocityMetricValues() : MaxVelocityNonMetricValues();

        const formValue = this.form.getRawValue();

        if (!this.maxVelocityValues.find((v) => v.value === formValue.velocityMaximum)) {
            formValue.velocityMaximum = 0;
        }

        if (this.monitorConfigs) {
            const offsetRounder = this.isMetric ? 10 : 100; // for offset values we allow 1 decimal for Metric and 2 for non metric

            formValue.physicalOffset = Math.round(formValue.physicalOffset * offsetRounder) / offsetRounder;
            formValue.pressureElectronicOffset = Math.round(formValue.pressureElectronicOffset * offsetRounder) / offsetRounder;
            formValue.ultrasonicElectronicOffset = Math.round(formValue.ultrasonicElectronicOffset * offsetRounder) / offsetRounder;
        }
        this.form.patchValue({ ...formValue });
    }

    public velocityMaximumInput() {
        const maxVelocity = this.form.get('velocityMaximum').value;
        if (
            maxVelocity < this.locationCardService.minVelocityRange ||
            maxVelocity > this.locationCardService.maxVelocityRange
        ) {
            this.isInvalidVelocityMax = true;
        } else {
            this.isInvalidVelocityMax = false;
        }
    }

    public onToggleChange(event: MatSlideToggleChange, keys: string[]) {
        this.locationFormBuilder.enableDisableControls(this.form, !event.checked, keys);
    }
}
