<mat-form-field [ngClass]="{ 'full-width': true }">
    <input
        matInput
        (focus)="selectSearchBox()"
        (input)="searchBoxStringEntered()"
        [disabled]="disabled"
        autocomplete="off"
        [placeholder]="staticPlaceholder ? staticPlaceholder : selectedEntsListPlaceholder"
        [(ngModel)]="selectedEntsListString"
    />
</mat-form-field>

<mat-selection-list #selectionList class="multi-select-group-list" *ngIf="isSearching">
    <mat-accordion [multi]="isSearching">
        <!-- If selected options are present -->
        <mat-expansion-panel [expanded]="true" *ngIf="availabeSelectedEntsListObject.length > 0">
            <mat-expansion-panel-header>
                <mat-panel-title>{{ 'COMMON.SELECTED' | translate }}</mat-panel-title>
            </mat-expansion-panel-header>

            <mat-selection-list class="multi-select-list">
                <ng-container *ngIf="!single; else singleBlock">
                    <mat-list-option
                        *ngFor="let ent of availabeSelectedEntsListObject; trackBy: trackById"
                        (click)="onSelectionChange(ent.groupId, ent.id, ent.isANSR)"
                        [selected]="true"
                    >
                        {{ ent.name }}
                    </mat-list-option>
                </ng-container>
                <ng-template #singleBlock>
                    <mat-list-item
                        *ngFor="let ent of availabeSelectedEntsListObject; trackBy: trackById"
                        (click)="onSelectionChange(ent.groupId, ent.id, ent.isANSR)"
                    >
                        {{ ent.name }}
                    </mat-list-item>
                </ng-template>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- If ANSR entities are present -->
        <mat-expansion-panel
            *ngIf="filteredANSREntities.length > 0"
            [expanded]="isSearching && selectedEntsListString !== ''"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>ANSR&trade; Entities</mat-panel-title>
            </mat-expansion-panel-header>

            <mat-selection-list class="multi-select-list">
                <ng-container *ngIf="!single; else singleBlock">
                    <mat-list-option
                        *ngFor="let ent of filteredANSREntities; trackBy: trackById"
                        (click)="onSelectionChange(ent.groupId, ent.id, ent.isANSR)"
                        [selected]="ent.isChecked"
                    >
                        {{ ent.name }}
                    </mat-list-option>
                </ng-container>
                <ng-template #singleBlock>
                    <mat-list-item
                        *ngFor="let ent of filteredANSREntities; trackBy: trackById"
                        (click)="onSelectionChange(ent.groupId, ent.id, ent.isANSR)"
                    >
                        {{ ent.name }}
                    </mat-list-item>
                </ng-template>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- All available options -->
        <mat-expansion-panel
            *ngFor="let entGroup of filteredAvailableEntities; trackBy: trackByIndex"
            [expanded]="isSearching && selectedEntsListString !== ''"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>{{ entGroup.groupName }}</mat-panel-title>
            </mat-expansion-panel-header>

            <mat-selection-list class="multi-select-list">
                <ng-container *ngIf="!single; else singleBlock">
                    <mat-list-option
                        *ngFor="let ent of entGroup.entities; trackBy: trackById"
                        (click)="onSelectionChange(ent.groupId, ent.id, ent.isANSR)"
                        [selected]="ent.isChecked"
                    >
                        {{ ent.name }}
                    </mat-list-option>
                </ng-container>
                <ng-template #singleBlock>
                    <mat-list-item
                        *ngFor="let ent of entGroup.entities; trackBy: trackById"
                        (click)="onSelectionChange(ent.groupId, ent.id, ent.isANSR)"
                    >
                        {{ ent.name }}
                    </mat-list-item>
                </ng-template>
            </mat-selection-list>
        </mat-expansion-panel>
    </mat-accordion>
</mat-selection-list>
