import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'app/shared/services/confirmation.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SelectItem } from 'app/shared/models/selected-Item';
import saveAs from 'file-saver';
import { ConfirmationElement, ConfirmationReportTypes } from 'app/shared/models/confirmation-points';
import moment from 'moment';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-download-confirmations-dialog',
    templateUrl: './download-confirmations-dialog.component.html',
    styleUrls: ['./download-confirmations-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadConfirmationsDialogComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    private customerDateFormat: string;
    public includeMeterValues: boolean = false;
    private dismissText: string;
    public reportType: number = 0;
    private duration: number = 10000;
    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public confirmationReportTypes: Array<SelectItem> = [];
    public includeIgnored: boolean = true;
    public translations = {
        keys: [
            'COMMON.TIMESTAMP', 'COMMON.MEASURED_SILT', 'COMMON.AVG_TO_PEAK_RATIO',
            'COMMON.MEASURED_DEPTH', 'COMMON.MEASURED_PEAK_VELOCITY', 'COMMON.FLOW', 'COMMON.CALCULATED_AVG_VELOCITY',
            'COMMON.CALCULATED_FLOW', 'COMMON.COMMENT', 'COMMON.DISMISS_TEXT',
            'PLOTTING_CONFIRMATION.NO_CONFIRMATIONS_FOUND', 'COMMON.DOWNLOADED_SUCCESSFULLY', 'COMMON.CONFIRMATIONS', 
            'COMMON.BEFORE', 'COMMON.AFTER', 'COMMON.CLOSEST', 'COMMON.AVG', 'COMMON.IGNORE',
            'COMMON.TYPE_COLUMN_TITLE', 'COMMON.DEPTH', 'COMMON.DEPTH_DIFF', 'COMMON.VELOCITY', 'COMMON.VELOCITY_DIFF', 'COMMON.CALCULATED_GAIN',
        ],
        timestamp: '',
        measuredSilt: '',
        avgToPeakRatio: '',
        measuredDepth: '',
        measuredPeakVelocity: '',
        measuredFlow: '',
        calculatedAvgVelocity: '',
        calculatedFlow: '',
        comment: '',
        dismissText: '',
        noConfirmationsForRange: '',
        exportSuccessMsg: '',
        confirmations: '',
        before: '',
        after: '',
        closest: '',
        avg: '',
        ignore: '',
        type: '',
        depth: '',
        depth_diff: '',
        velocity: '',
        velocity_diff: '',
        calculatedGain: ''
    }
    public endDate = new Date();

    public trackByValue = TrackBy.byValue();

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            locationId: number;
            locationName: string;
            locationGroupId: number;
            includeInactiveLocations: boolean;
            customerId: number;
            isMetric: number;
            flowSmallerUnit: string;
        },
        private confirmationService: ConfirmationService,
        private datePipe: DatePipe,
        public translateService: TranslateService,
        private dateutilService: DateutilService,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<DownloadConfirmationsDialogComponent>,
    ) {}

    ngOnInit() {
        const timeFormatSubs = this.dateutilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat =
                this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
        });
        this.confirmationReportTypes =  <Array<ConfirmationElement>>[
            { id: ConfirmationReportTypes.Default, value: ConfirmationReportTypes.Default, text: this.translateService.instant('DOWNLOAD_CONFIRMATIONS.CONFIRMATION_STANDARD_REPORT') },
            { id: ConfirmationReportTypes.Final, value: ConfirmationReportTypes.Final, text: this.translateService.instant('DOWNLOAD_CONFIRMATIONS.CONFIRMATIONS_FINAL_REPORT') },
            { id: ConfirmationReportTypes.DepthVel, value: ConfirmationReportTypes.DepthVel, text: this.translateService.instant('DOWNLOAD_CONFIRMATIONS.CONFIRMATIONS_DEPTHFINAL_REPORT') },
        ];
        const translateSubs = this.translate.get(this.translations.keys).subscribe((translateValues) => {
            this.translations.timestamp = translateValues['COMMON.TIMESTAMP'];
            this.translations.measuredSilt = translateValues['COMMON.MEASURED_SILT'];
            this.translations.avgToPeakRatio = translateValues['COMMON.AVG_TO_PEAK_RATIO'];
            this.translations.measuredDepth = translateValues['COMMON.MEASURED_DEPTH'];
            this.translations.measuredPeakVelocity = translateValues['COMMON.MEASURED_PEAK_VELOCITY'];
            this.translations.measuredFlow = translateValues['COMMON.FLOW'];
            this.translations.calculatedAvgVelocity = translateValues['COMMON.CALCULATED_AVG_VELOCITY'];
            this.translations.calculatedFlow = translateValues['COMMON.CALCULATED_FLOW'];
            this.translations.comment = translateValues['COMMON.COMMENT'];
            this.translations.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.translations.noConfirmationsForRange = translateValues['PLOTTING_CONFIRMATION.NO_CONFIRMATIONS_FOUND'];
            this.translations.exportSuccessMsg = translateValues['COMMON.DOWNLOADED_SUCCESSFULLY'];
            this.translations.confirmations = translateValues['COMMON.CONFIRMATIONS'];
            this.translations.before = translateValues['COMMON.BEFORE'];
            this.translations.after = translateValues['COMMON.AFTER'];
            this.translations.closest = translateValues['COMMON.CLOSEST'];
            this.translations.avg = translateValues['COMMON.AVG'];
            this.translations.ignore = translateValues['COMMON.IGNORE'];
            this.translations.type = translateValues['COMMON.TYPE_COLUMN_TITLE'];
            this.translations.depth = translateValues['COMMON.DEPTH'];
            this.translations.depth_diff = translateValues['COMMON.DEPTH_DIFF'];
            this.translations.velocity = translateValues['COMMON.VELOCITY'];
            this.translations.velocity_diff = translateValues['COMMON.VELOCITY_DIFF'];
            this.translations.calculatedGain = translateValues['COMMON.CALCULATED_GAIN'];
        });

        this.subscriptions.add(timeFormatSubs);
        this.subscriptions.add(translateSubs);
    }

    download() {
        const { locationId, customerId, locationGroupId, includeInactiveLocations, locationName } = this.data;

        const isoStartDate = this.dateutilService.getEndDateAsTimeZone(this.startDate);
        const rawStartDate = isoStartDate.split('.');
        const start = rawStartDate[0];

        const end = this.dateutilService.getEndDateAsTimeZone(this.endDate);
        const reportParams = {
            locationIds: [locationId],
            locationGroupId,
            includeInactiveLocations,
            startTime: start,
            endTime: end,
            reportType: this.reportType,
            includeIgnored: this.includeIgnored,
            includeMonitorValues : this.includeMeterValues
        };

        this.confirmationService
        .generateConfirmationReport(customerId, reportParams)
        .subscribe(
                (file) => {
                    if(!file){
                        this.snackBar.open(this.translations.noConfirmationsForRange, this.translations.dismissText, {
                                            duration: this.duration,
                                        });
                        return;
                    }
                    else{
                        const blob = new Blob([file], {type: 'text/csv' })
                        const reportName = 'Confirmations'+ '_' + ConfirmationReportTypes[this.reportType] + '_' + locationName + '_'+ moment(start).format('YYYY-MM-DD') + '-'+ moment(end).format('YYYY-MM-DD') +'.csv' ;
                        saveAs(blob, reportName);
                        this.snackBar.open(reportName + ' ' + this.translations.exportSuccessMsg, this.translations.dismissText, {
                                        duration: this.duration,
                                    });
                        this.dialogRef.close();
                        return;
                    }
                }
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
