import { RAIN } from 'app/shared/constant';
import { DeviceTypeCode } from 'app/shared/enums/device-type-code';
import { AddLocationRain } from 'app/shared/models/add-location-rain';
import { DefinedInstallationType, InstallationType, LocationDetails } from 'app/shared/models/location-details';
import { LocationStatus } from 'app/shared/models/locations';
import { MonitorSeriesNames, PrimarySensors, SecondarySensors, ForeSITEXDSensors, MonitorSeries } from '../location-card.constants';
import { BasicInfo, HIGH_FLOW_ENABLE, HIGH_FLOW_THRESHOLD, LocationCardData, TritonMonitor } from './location-card.model';

const installationTypeNames = ['pipe', 'flume', 'weir', 'channel', 'rainGauge', 'composite', '', '', '', 'elevationInstallation'];

export function assembleFormValues(formValues: any, data: LocationCardData, details: LocationDetails[] = null) {
    let monitors: TritonMonitor[] = [];

    if (formValues.tritonInfo) {
        monitors = formValues.tritonInfo.monitors;
    }

    if (formValues.foreSITEXDInfo) {
        monitors = formValues.foreSITEXDInfo.monitors;
    }

    const basicInfo = getBasicInfo(formValues.basicInfo, data, details, monitors[0]);

    let mp2BasicInfo;
    if (formValues.mp2BasicInfo) {
        mp2BasicInfo = getMp2BasicInfo({ ...formValues.basicInfo, ...formValues.mp2BasicInfo }, data, details, monitors[1]);
    }

    return mp2BasicInfo ? [basicInfo, mp2BasicInfo] : [basicInfo];
}

function getBasicInfo(formValues: BasicInfo, data: LocationCardData, details: LocationDetails[], tritonMonitor: TritonMonitor) {
    const basicInfoKeys = [
        'ipaddress',
        'assignedRainGaugeId',
        'communicationType',
        'description',
        'installationDate',
        'installationID',
        'monitorDeploymentID',
        'latitude',
        'locationName',
        'longitude',
        'manholeaddress',
        'manholedepth',
        'qFinalEntityID',
        'serialnumber',
        'series',
        'status',
        'locationid',
        'maintenanceInteval',
        'installationShapeType',
        'apnProvider',
        'samplerate',
        'fastrate',
        'usedaylightsaving',
        'synctime',
        'customProperties'
    ];

    const basicInfo: any = basicInfoKeys.reduce((acc, curr) => ({ ...acc, [curr]: formValues[curr] }), {});

    if (formValues.installationType && formValues.installationType.value) {
        basicInfo.installationtypeid = getInstallationTypeId(formValues.installationType);
        setInstallationForm(basicInfo, formValues, 1, tritonMonitor);
    }

    if (formValues.assignedRainGauge && data.rainGaugeLocations && data.rainGaugeLocations.length) {
        const assignedRainGauge = data.rainGaugeLocations.find((v) => v.locationName === formValues.assignedRainGauge);
        if (assignedRainGauge) {
            basicInfo.assignedRainGaugeId = assignedRainGauge.locationId;
        }
    }

    if (formValues.status === LocationStatus.Maintenance) {
        basicInfo.maintenanceInterval = formValues.maintenanceInterval;
    }

    if(basicInfo.series && MonitorSeries.some(a => a.value === basicInfo.series)){
        basicInfo.series = MonitorSeries.find(a => a.value === basicInfo.series).text ;
    }

    if (data.editMode && details && details[0]) {
        basicInfo.locationid = details[0].locationID;
    }

    if (formValues.series === MonitorSeriesNames.rainAlert) {
        populateRainAlertInfo(basicInfo);
        basicInfo.installationtypeid = 5;
    }
    return basicInfo;
}

function getMp2BasicInfo(formValues: any, data: LocationCardData, details: LocationDetails[], tritonMonitor: TritonMonitor) {
    const mp2BasicInfoKeys = [
        'communicationType',
        'description',
        'installationDate',
        'installationID',
        'latitude',
        'locationName',
        'longitude',
        'qFinalEntityID',
        'serialnumber',
        'series',
        'status',
        'installationShapeType',
        'customProperties'
    ];

    const mp2BasicInfo: any = mp2BasicInfoKeys.reduce((acc, curr) => ({ ...acc, [curr]: formValues[curr] }), {});
    mp2BasicInfo.locationName = mp2BasicInfo.locationName + '(2)';
    mp2BasicInfo.installationtypeid = getInstallationTypeId(formValues.installationType);
    setInstallationForm(mp2BasicInfo, formValues, 2, tritonMonitor);
    if (data.editMode && details && details[1]) {
        mp2BasicInfo.locationid = details[1].locationID;
    } else {
        mp2BasicInfo.locationid = 0;
    }

    if (formValues.status === LocationStatus.Maintenance) {
        mp2BasicInfo.maintenanceInterval = formValues.maintenanceInterval;
    }

    return mp2BasicInfo;
}

function populateRainAlertInfo(rainInfo) {
    const rain = new AddLocationRain();

    rainInfo.rain = rain;
}

function getInstallationTypeId(installationType: InstallationType): number {
    const { value } = installationType;
    const type = value.replace(/\:.*$/, ''); // Get only installation type name

    // API returning "Rain Gauge" for "RainGauge" hence added below check.
    if (type === 'Rain Gauge' || type === RAIN) {
        return 5;
    } else {
        for (const definedInstallationType in DefinedInstallationType) {
            if (definedInstallationType && DefinedInstallationType[definedInstallationType] === type) {
                return parseInt(definedInstallationType, 10);
            }
        }
    }
}

function setInstallationForm(value: any, formValues: any, mp: number, tritonMonitor: TritonMonitor) {
    if (!formValues.installationType || !formValues.installationForm) {
        return;
    }

    const installationID = getInstallationTypeId(formValues.installationType);
    value.installationID = installationID;
    const installationFormName = pickInstallationNameById(installationID);
    value[installationFormName] = {
        ...getInstallationTypeForm(formValues.installationForm),
        MonitoringPoint: mp,
        installationID,
    };
    value[installationFormName].installationType = formValues.installationType.value.match(/(\w+)/)[0];
    value[installationFormName].shapeType = formValues.installationShapeType;
    value[installationFormName].installationShapeType = formValues.installationShapeType;

    if (tritonMonitor) {
        value[installationFormName].depthSensor = tritonMonitor.depthSensor;
        value[installationFormName].velocitySensor = tritonMonitor.velocitySensor;
    }
}

function pickInstallationNameById(id: number) {
    return installationTypeNames[id - 1];
}

function getInstallationTypeForm(installationForm: any) {
    return Object.keys(installationForm).reduce((acc, curr) => ({ ...acc, [curr]: installationForm[curr] }), {});
}

export function assembleTritonMonitoringPoints(formValues: any, data: LocationCardData) {
    const dataStoreConfig = {
        storetemperature: false,
        storebattery: 1,
    }
    const initialConfig = {
        ftpdeliverynormal: 0,
        ftpdeliveryfast: 0,
        ftpfolder: '',
        ftppassword: '',
        ftpusername: '',
        ftpserver: '',
        ftpmode: 'Active' || '',
        ftpformat: 'ADS' || '',
        ...dataStoreConfig,
        ...formValues.dataDelivery
    };

    if (formValues.basicInfo.series === MonitorSeriesNames.triton) {
        initialConfig['devices'] = gatherTritonDevicesInfo(formValues);
        initialConfig['silts'] = gatherTritonSiltsInfo(formValues);
        populateTritonConfig(initialConfig, {
            ...formValues.basicInfo,
            ...formValues.alarmsInfo,
            ...formValues.dataDelivery,
            ...formValues.tritonInfo,
        });

        return initialConfig;
    } else if (formValues.basicInfo.series === MonitorSeriesNames.echo) {
        return {
            ...initialConfig,
            ...populateEchoConfig({
                ...formValues.basicInfo,
                ...formValues.alarmsInfo,
                ...formValues.dataDelivery,
                ...formValues.echoInfo,
                ...formValues.echoLongRange,
            }),
        };
    } else if (formValues.basicInfo.series === MonitorSeriesNames.rainAlert) {
        return {
            ...initialConfig,
            ...populateRainInfo({
                ...formValues.basicInfo,
                ...formValues.rainAlarms,
                ...formValues.dataDelivery,
                ...formValues.rainSensor,
                ...formValues.rainalertInfo,
            }),
        };
    } else if (formValues.basicInfo.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL) {
        return {
            ...dataStoreConfig,
            ...populateFsUlConfig({
                ...formValues.basicInfo,
                ...formValues.alarmsInfo,
                ...formValues.dataDelivery
            }),
        };
    } else if (formValues.basicInfo.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD) {
        initialConfig['devices'] = gatherForeSITEXDDevicesInfo(formValues);
        return {
            ...initialConfig,
            ...populateForeSITEXDConfig({
                ...formValues.basicInfo,
                ...formValues.alarmsInfo,
                ...formValues.dataDelivery,
                ...formValues.foreSITEXDInfo
            }),
        };
    }
}

function populateTritonConfig(config, formValues) {
    const keys = [
        'samplerate',
        'usedaylightsaving',
        'synctime',
        'apnProvider',
        'fastrate',
        'lowLevelEnable',
        'lowLevelThreshold',
        'fullPipeEnable',
        'highLevelEnable',
        'highLevelThreshold',
        'highHighEnable',
        'highHighThreshold',
        HIGH_FLOW_ENABLE,
        HIGH_FLOW_THRESHOLD,
        'overflowEnable',
        'batteryLowEnable',
        'batteryLowThreshold',
        'prismdeliveryipaddress',
        'prismdeliverynormal',
        'prismdeliveryfast',
        'comport',
        'ipaddress',
        'locationid',
        'inclinometerthreshold',
    ];

    keys.forEach((key: string) => {
        config[key] = formValues[key];
    });

    config.apn = config.apnProvider;
}

function populateFsUlConfig(formValues) {
    const keys = [
        'locationID',
        'samplerate',
        'timeZone',
        'useDaylightSavingTime',
        'normalIntervalHours',
        'fastModeEnabled',
        'storeTemperature',
        'storeBattery',
        'connectionType',
        'syncTime',
        'fastRate',
        'manholeDepth',
        'lowLevelEnable',
        'lowLevelThreshold',
        'highLevelEnable',
        'highLevelThreshold',
        'highHighEnable',
        'highHighThreshold',
        'batteryLowEnable',
        'batteryLowThreshold',
        'serialnumber'
    ];
    const deviceInfo = keys.reduce((acc, curr) => ({ ...acc, [curr]: formValues[curr] }), {});
    return deviceInfo;
}

function populateForeSITEXDConfig(formValues) {
    const keys = [
        'locationID',
        'samplerate',
        'timeZone',
        'useDaylightSavingTime',
        'normalIntervalHours',
        'fastModeEnabled',
        'storeTemperature',
        'storeBattery',
        'connectionType',
        'syncTime',
        'fastRate',
        'manholedepth',
        'lowLevelEnable',
        'lowLevelThreshold',
        'highLevelEnable',
        'highLevelThreshold',
        'highHighEnable',
        'highHighThreshold',
        'batteryLowEnable',
        'batteryLowThreshold',
        'serialnumber'
    ];
    const deviceInfo = keys.reduce((acc, curr) => ({ ...acc, [curr]: formValues[curr] }), {});
    return deviceInfo;
}

function populateEchoConfig(formValues) {
    const keys = [
        'fastrate',
        'manholedepth',
        'height',
        'physicalOffset',
        'lowLevelEnable',
        'lowLevelThreshold',
        'fullPipeEnable',
        'highLevelEnable',
        'highLevelThreshold',
        'highHighEnable',
        'highHighThreshold',
        'overflowEnable',
        'tiltEnable',
        'tiltThreshold',
        'batteryLowEnable',
        'batteryLowThreshold',
        'serialNumber',
        'ipaddress',
        'blanking',
        'power',
        'compensatedTemperatureMarch',
        'compensatedTemperatureSeptember',
        'pressureStore',
        'storeData',
        'temperatureSensor',
        'gainControl',
        'electronicOffset',
        'unidepthFiltering',
        'hcValue',
        'silt',
        'locationid',
        'hc',
        'hcValue',
        'samplerate',
        'timezone',
        'usedaylightsaving',
        'prismdeliveryipaddress',
        'prismdeliverynormal',
        'prismdeliveryfast',
        'connectionType',
        'synctime',
        'apnProvider',
    ];

    const echoInfo: any = keys.reduce((acc, curr) => ({ ...acc, [curr]: formValues[curr] }), {});
    echoInfo.pipeHeight = echoInfo.height;
    echoInfo.hc = echoInfo.hcValue;
    echoInfo.apn = echoInfo.apnProvider;

    return echoInfo;
}

function populateRainInfo(formValues) {
    const keys = [
        'rainpertip',
        'logintensity',
        'logukintensity',
        'datalogmode',
        'intensityinterval',
        'alarmEnable',
        'alarmThreshold',
        'alarmReturnToNormal',
        'serialNumber',
        'ipaddress',
        'fastrate',
        'locationid',
        'samplerate',
        'timezone',
        'usedaylightsaving',
        'prismdeliveryipaddress',
        'prismdeliverynormal',
        'prismdeliveryfast',
        'connectionType',
        'synctime',
        'apnProvider',
        'storetemperature',
        'storebattery'
    ];

    const rainInfo: any = keys.reduce((acc, curr) => ({ ...acc, [curr]: formValues[curr] }), {});
    rainInfo.apn = rainInfo.apnProvider;
    if (formValues.samplerate !== 120) {
        rainInfo.logukintensity = false;
    }
    return rainInfo;
}

function gatherTritonDevicesInfo(formValues: any) {
    const devices = [];
    devices.push({
        ...formValues.alarmsInfo,
        ...formValues.dataDelivery,
        name: 'Alarm 1',
        type: 255,
        installationNumber: 0,
    });

    if (formValues.alarmsInfo.flowLossAlarm) {
        const { clearPatternHistory, flowLossType, percent, sensitivity } = formValues.alarmsInfo;
        devices.push({
            name: 'Flow Loss 1',
            active: true,
            installationNumber: 0,
            type: 258,
            clearPatternHistory,
            flowLossType,
            percent,
            sensitivity,
        });
    }
    if (formValues.mp2AlarmsInfo) {
        devices.push({ ...formValues.mp2AlarmsInfo, name: 'Alarm 2', type: 256, installationNumber: 1 });

        if (formValues.mp2AlarmsInfo.flowLossAlarm) {
            const { clearPatternHistory, flowLossType, percent, sensitivity } = formValues.mp2AlarmsInfo;
            devices.push({
                name: 'Flow Loss 2',
                active: true,
                installationNumber: 0,
                type: 259,
                clearPatternHistory,
                flowLossType,
                percent,
                sensitivity,
            });
        }
    }

    if (formValues.samplerInfo) {
        devices.push({ ...formValues.samplerInfo, name: 'Sampler', installationNumber: 0, type: 4, active: true });
    }

    const sensors = [...formValues.tritonSensors];
    const tritonMonitors = formValues.tritonInfo.monitors;

    if (tritonMonitors && tritonMonitors.every((v) => !!v.sensor) && tritonMonitors[1].mp < tritonMonitors[0].mp) {
        sensors.reverse();
    }

    const sensorNames = [...PrimarySensors, ...SecondarySensors];

    tritonMonitors.forEach((monitor, index) => {
        const sensor = sensors[index];
        if (!monitor.sensor || !sensor) return;

        const defaultSensor = sensorNames.find((v) => v.value === monitor.sensor);
        const list = monitor.mp === 1 ? SecondarySensors : PrimarySensors;
        sensor.installationNumber = sensor.mp;
        sensor.name = list.find((v) => defaultSensor.text === v.text).value;
        sensor.channel = monitor.channel;
        sensor.type = DeviceTypeCode.getDeviceTypeCode(sensor.name);
    });

    devices.push(...sensors);

    return devices.filter((v) => !!v);
}

function gatherForeSITEXDDevicesInfo(formValues: any) {
    const devices = [];

    const sensors = formValues.ForeSITEXDSensors;

    sensors.forEach(sensor => {
        sensor.type = DeviceTypeCode.getDeviceTypeCode(sensor.name);
    });

    devices.push(...sensors);

    return devices.filter((v) => !!v);
}


function gatherTritonSiltsInfo(formValues: any) {
    return formValues.tritonInfo.monitors.map((element) => element.silt).filter((v) => v !== null);
}
