<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'echo-section-error': form.invalid }">
        <mat-expansion-panel-header class="mat-expasion-header-background">
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 0">
                {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-title class="input-text" *ngIf="monitoringPoint === 1">
                {{ 'ADD_EDIT.MP2' | translate }} {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount
                    }}{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_FLOW' | translate }}"
                            formControlName="ultrasonicFlowType"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let flow of flowList; trackBy: trackByValue" [value]="flow.value">{{ flow.text }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            class="input-text"
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_PHYSICAL')
                                )
                            "
                            id="PhysicalOffset"
                            formControlName="physicalOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('physicalOffset')?.errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
            </div>

            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="ultrasonicEnable"
                    (change)="
                        onToggleChange($event, [
                            'ultrasonicElectronicOffset',
                            'ultrasonicDeadband',
                            'ultrasonicStoreData'
                        ])
                    "
                >
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_MEASUREMENT' | translate }}
                </mat-slide-toggle>
            </div>

            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_ELECTRONIC')
                                )
                            "
                            id="PhysicalOffsetDepth"
                            formControlName="ultrasonicElectronicOffset"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('ultrasonicElectronicOffset')?.errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DEAD_BAND' | translate }}"
                            required
                            formControlName="ultrasonicDeadband"
                            class="full-width"
                        >
                            <mat-option *ngFor="let band of bandList; trackBy: trackByValue" [value]="band.value">{{ band.text }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="ultrasonicStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="pressureEnable"
                    (change)="
                        onToggleChange($event, ['pressureElectronicOffset', 'pressureStoreData', 'pressureCalibration'])
                    "
                >
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_PRESSURE' | translate }}
                </mat-slide-toggle>
            </div>

            <div class="custom-33p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_ELECTRONIC')
                                )
                            "
                            id="PressureElectronicOffset"
                            formControlName="pressureElectronicOffset"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('pressureElectronicOffset')?.errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="pressureStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item item-flex-center">
                    <mat-checkbox
                        name="{{ 'ADD_EDIT.INACTIVE_TOGGLE_AUTO_CALIBER' | translate }}"
                        formControlName="pressureCalibration"
                    >
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_AUTO_CALIBER' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="pad-b-15">
                <mat-slide-toggle
                    class="custom-slide-toggle highlight-text"
                    formControlName="velocityEnable"
                    (change)="
                        onToggleChange($event, [
                            'velocityDirection',
                            'velocityMinimum',
                            'velocityMaximumAVGatedValue',
                            'velocityMaximum',
                            'velocityAcquisition',
                            'velocityStoreData',
                            'velocityScrubbing'
                        ])
                    "
                >
                    {{ 'ADD_EDIT.INACTIVE_TOGGLE_VELOCITY' | translate }}
                </mat-slide-toggle>
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DIRECTION' | translate }}"
                            formControlName="velocityDirection"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let direction of directionList; trackBy: trackByValue" [value]="direction.value"
                                >{{ direction.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MINIMUM_VELOCITY' | translate }}"
                            formControlName="velocityMinimum"
                            required
                            class="full-width"
                        >
                            <mat-option *ngFor="let velocity of minVelocityList; trackBy: trackByValue" [value]="velocity.value">
                                {{ velocity.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_VALUE_MAXIMUM' | translate }}"
                            id="VelocityMaximum"
                            formControlName="velocityMaximum"
                            class="full-width"
                        >
                            <mat-option *ngFor="let velocity of maxVelocityValues; trackBy: trackByValue" [value]="velocity.value">{{
                                velocity.text
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_DATA_ACQUISITION' | translate }}"
                            name="Data Acquisition"
                            formControlName="velocityAcquisition"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let velocity of velocityAcquisition; trackBy: trackByValue" [value]="velocity.value">
                                {{ velocity.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-50p pad-b-15">
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="velocityStoreData">
                        {{ 'ADD_EDIT.INACTIVE_TOGGLE_STORE_DATA' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-slide-toggle class="custom-slide-toggle" formControlName="velocityScrubbing">
                        {{ 'ADD_EDIT.INPUT_PLACEHOLDER_SCRUBBLING' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="custom-50p text-bottom-space input-text form-input-bottom-space">
                {{ 'ADD_EDIT.ADD_EDIT_TEXT' | translate }}
            </div>
            <div class="pad-b-15">
                <mat-slide-toggle class="custom-slide-toggle" formControlName="temperatureStoreData">
                    {{ 'ADD_EDIT.ACTIVE_TOGGLE_STORE' | translate }}
                </mat-slide-toggle>
            </div>
        </div>
    </mat-expansion-panel>
</form>
