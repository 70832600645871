import { ChangeDetectionStrategy, Component, ViewEncapsulation, Inject } from '@angular/core';
import { MonitorDiagnosticLog } from 'app/shared/services/monitor-diagnostics.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    templateUrl: './location-diagnostics-results.component.html',
    styleUrls: ['./location-diagnostics-results.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDiagnosticsResultsComponent {
    public dateFormat: string;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MonitorDiagnosticLog,
        private dialogRef: MatDialogRef<LocationDiagnosticsResultsComponent>,
        private dateUtilService: DateutilService,
    ) {
        this.dateFormat = `${this.dateUtilService.getFormat()} ${this.dateUtilService.getTimeFormat()}`;
    }

    public close() {
        this.dialogRef.close();
    }
}
