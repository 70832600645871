import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { CustomerService } from 'app/shared/services/customer.service';
import { SharedService } from 'app/shared/services/shared.service';
import {
    activeInactiveLocationQueryParam,
    AppQueryParams,
    customerEditorQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
    GetCustomerFeatureResponse,
    ProjectDetailsAdditionalFeature,
    SubscriptionLevel,
    SubscriptionTier,
    userSearchPageIndexParam,
    userSearchPageSizeParam,
    userSearchParam,
} from 'app/shared/models/customer';
import { CUSTOMER_SLIICER_FEATURE_ID } from 'app/shared/services/sliicer.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-customer-detail-feature',
    templateUrl: './customer-detail-feature.component.html',
    styleUrls: ['./customer-detail-feature.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDetailFeatureComponent implements OnInit, OnDestroy {
    @Input() private customerName: string;
    @Input() public subscriptionLevel: number;
    @Input() public additionalFeaturesList: ProjectDetailsAdditionalFeature[];
    @Output() public featureselected = new EventEmitter<boolean>();
    @Output() public featureSave = new EventEmitter<ProjectDetailsAdditionalFeature[]>();
    private customerId: number;
    public subscriptionName = '';
    public adonsFeatureList: GetCustomerFeatureResponse[] = [];
    public selectedFeatureList: GetCustomerFeatureResponse[] = [];
    public allUserFeatureList: GetCustomerFeatureResponse[] = [];
    public allFeatureList: GetCustomerFeatureResponse[] = []; 
    public customerFeatureLoadingState: boolean;
    public snackbarSuccessMsg: string;
    public snackbarErrMsg: string;
    public dismissBtnText: string;
    public isFeatureSelected = false;

    public SubscriptionLevel = SubscriptionLevel;

    private subscriptions = new Subscription();

    translations = {
        noaccess: '',
        addons: ''
    }

    public trackById = TrackBy.byId;
    constructor(
        private activatedRoute: ActivatedRoute,
        private statusCodeService: StatusCodeService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private sharedService: SharedService,
        private customerService: CustomerService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private router: Router,
    ) {
        this.dismissBtnText = this.translate.instant('COMMON.DISMISS_TEXT');
        this.snackbarSuccessMsg = this.translate.instant('ADMIN.CUSTOMER_FEATURES.FEATURE_ADDED_SUCCESS_MESSAGE');
        this.snackbarErrMsg = this.translate.instant('ADMIN.CUSTOMER_FEATURES.FEATURE_EDIT_ERROR_MESSAGE');
        this.translations.addons = this.translate.instant('ADMIN.CUSTOMER_FEATURES.ADD_ONS');
        this.translations.noaccess = this.translate.instant('ADMIN.CUSTOMER_FEATURES.NO_ACCESS');
    }

    public ngOnInit() {
        this.customerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerEditorQueryParam));

        // Leave timeout for other things to resolve first
        // Currently there is no way to load to this tab, so the delay makes no difference
        setTimeout(() => this.getCustomerFeatures(), 1000);
    }

    private updateFeatures(features: GetCustomerFeatureResponse[], allAvailableFeatures : GetCustomerFeatureResponse[], tiers: SubscriptionTier[]) {
        this.allUserFeatureList = features;
        this.allFeatureList = allAvailableFeatures;
        const currentSubscriptionTier = tiers?.find((v) => v.id === this.subscriptionLevel);
        this.selectedFeatureList = this.allFeatureList.filter(f => currentSubscriptionTier.features.includes(f.id));

        this.subscriptionName = tiers && tiers.length && tiers[this.subscriptionLevel] ? tiers[this.subscriptionLevel].tier : '';

        const assignedFeaturesAssoc = [];

        for(const feature of this.additionalFeaturesList) {
            assignedFeaturesAssoc[feature.id] = assignedFeaturesAssoc;
        }
        this.adonsFeatureList = [allAvailableFeatures?.find((feature) => feature.id === CUSTOMER_SLIICER_FEATURE_ID)];

        for(const feature of this.adonsFeatureList) {
            feature.isChecked = assignedFeaturesAssoc[feature.id];
        }
    }
    private getCustomerFeatures() {
        const featuresRequestSubs =
            combineLatest([
                this.sharedService.getFeatures(),
                this.sharedService.getAllFeatures(),
                this.customerService.getSubscriptionTiers()
            ])
        .subscribe(([response, allFeatures, tiers]) => {
            // TODO: FIX any type issue, returning type not declared at this.sharedService.getFeatures()
            this.updateFeatures(response as GetCustomerFeatureResponse[], allFeatures as GetCustomerFeatureResponse[],  tiers);
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        this.subscriptions.add(featuresRequestSubs);
    }

    /**
     * handle the feature list change event from UI
     * @param event
     * @param feature
     */
    public featureChangeHandler(event: MatCheckboxChange, selectedFeature: GetCustomerFeatureResponse) {
        this.isFeatureSelected = true;
        this.featureselected.emit(this.isFeatureSelected);
        // Marked as ischecked to true which will be assigned to customer.
        const customerFeat = this.adonsFeatureList.find((v) => v.id === selectedFeature.id);
        customerFeat.isChecked = event.checked;
        selectedFeature.isChecked = event.checked;

    }


    /**
     * Function to setup the admin route in case of success
     */
    public setupAdminRoute() {
        const currentCustomerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));

        // get current query params for location group
        const locationGroupId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam));

        // get current query params for showing all locations
        const isShowAllLocations = Number(
            this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
        );

        // get search value
        const userSearchParamValue = this.activatedRoute.snapshot.queryParamMap.get(userSearchParam);

        // get search value
        const userSearchPageIndexParamValue = Number(
            this.activatedRoute.snapshot.queryParamMap.get(userSearchPageIndexParam),
        );

        // get search value
        const userSearchPageSizeParamValue = Number(
            this.activatedRoute.snapshot.queryParamMap.get(userSearchPageSizeParam),
        );

        // get global query params
        const appQueryParams = <AppQueryParams>{
            c: currentCustomerId,
            lg: locationGroupId || undefined,
            lt: isShowAllLocations || undefined,
            s: userSearchParamValue,
            pi: userSearchPageIndexParamValue,
            ps: userSearchPageSizeParamValue,
        };

        // Navigating with required params

        this.router.navigate(['/pages/customers'], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }
    /**
     * Update the customer feature association.
     */
    public saveCustomerAssignedFeatures() {
        // this.customerFeatureLoadingState = true;

        // #39852 Save only ADONS features
        // select only those features from the customerFeatureList(old) which ischecked property marked to true
        const updatedFeatureModel = [];
        for(const feature of this.adonsFeatureList) {
            if(feature.isChecked) {
                updatedFeatureModel.push({
                    id: feature.id,
                });
            }
        }

        // #39852 Because currently we use project call to save features which need whole dataset
        this.featureSave.emit(updatedFeatureModel);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
