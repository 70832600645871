import { Component, ViewEncapsulation, Input } from '@angular/core';
import { ProgressBar } from './progress-bars.models';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'progress-bars',
    templateUrl: './progress-bars.component.html',
    styleUrls: ['./progress-bars.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProgressBarsComponent {
    @Input() progressBars: Array<ProgressBar> = [];

    trackByIndex = TrackBy.byIndex;
    constructor() {}
}
