<div class="setUpStudy">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="study-stepper">
        <mat-tab-group class="pad-l-15 pad-r-15">
            <!-- study setup -->
            <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_SETUP' | translate }}">
                <div class="summary-details">
                    <form #studySetUpForm="ngForm" novalidate>
                        <mat-horizontal-stepper #stepper="matHorizontalStepper" [linear]="true"
                            class="study-color stepper">
                            <!-- mat step for location -->
                            <mat-step [editable]="!isCaseStudyCreated" [completed]="!invalidMonitors && !noDataRainMonitorError && !noDataFlowMonitorError && locationGroupSelect.valid">
                                <ng-template matStepLabel>
                                    <mat-icon class="done-icon"
                                        *ngIf="locationCompleteStatus && locationGroupSelect.valid && !invalidMonitors && !noDataFlowMonitorError && !noDataRainMonitorError">
                                        check</mat-icon>
                                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_LOCATION' | translate }}
                                </ng-template>
                                <div class="stepperContent">
                                    <div class="mat-content-text">
                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_LOCATION_TEXT' | translate }}
                                    </div>
                                    <div class="custom-50p setup-field width-280px">
                                        <div class="custom-error mat-content-text study-start-content">
                                            <mat-form-field class="full-width">
                                                <mat-select name="locationGroupSelect" #locationGroupSelect="ngModel"
                                                    [(ngModel)]="selectedLocationGroupId"
                                                    [disabled]="locationGroups.length < 1" placeholder=" {{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_LOCATION_PLACEHOLDER'
                                                            | translate
                                                    }}" (selectionChange)="onLocationGroupSelected($event)">
                                                    <mat-option *ngFor="let locationGroup of locationGroups; trackBy: trackById"
                                                        [value]="locationGroup?.id">
                                                        {{ locationGroup.text }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div *ngIf="locationGroupsUnavailable" class="custom-error-message">
                                                {{
                                                'SLIICER_TABLE.SLICER_SUMMARY.LOCATIONS_GROUPS-UNAVILABLE'
                                                | translate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wizard-step-description" *ngIf="locationGroupSelect.valid">
                                        <app-loader class="inline-loader" [isPositionAbsolute]="false"
                                            [isLoading]="loadingLocations"></app-loader>
                                        <span class="study-button-background">
                                            <span class="border-right">
                                                <span class="online-offline-dates">{{
                                                    currentLocationGroupSummary?.flowMonitorNames?.length
                                                    }}</span>
                                                <ng-container
                                                    *ngIf="currentLocationGroupSummary?.flowMonitorNames?.length != 1">
                                                    {{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITORS'
                                                    | translate
                                                    }}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="currentLocationGroupSummary?.flowMonitorNames?.length == 1">
                                                    {{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITOR'
                                                    | translate
                                                    }}
                                                </ng-container>
                                            </span>
                                            <span class="border-right padding-le">
                                                <span class="online-offline-dates">{{
                                                    currentLocationGroupSummary?.rainfallMonitorNames?.length
                                                    }}</span>
                                                <ng-container *ngIf="
                                                        currentLocationGroupSummary?.rainfallMonitorNames?.length != 1
                                                    ">
                                                    {{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITORS'
                                                    | translate
                                                    }}
                                                </ng-container>
                                                <ng-container *ngIf="
                                                        currentLocationGroupSummary?.rainfallMonitorNames?.length == 1
                                                    ">
                                                    {{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITOR'
                                                    | translate
                                                    }}
                                                </ng-container>
                                            </span>
                                            <span class="padding-le online-offline-dates">
                                                <a class="normal-link" (click)="showMonitorDetails()">{{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DETAILS' | translate
                                                    }}</a>
                                            </span>
                                        </span>
                                        <span class="each-Item padding-le includeLocations">
                                            <span>Include Inactive Locations</span>
                                            <mat-slide-toggle class="toggle padding-leh"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="includeInactiveLocations"
                                                (ngModelChange)="switchInactive()">
                                            </mat-slide-toggle>
                                        </span>
                                    </div>
                                    <div class="sliicer-error-text" *ngIf="invalidMonitors">
                                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_TEXT_WARNING' | translate }}
                                    </div>
                                    <div class="sliicer-error-text" *ngIf="noDataFlowMonitorError">
                                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_TABLE2_NO_DATA_FLOW_MONITOR_ERROR' | translate }}
                                    </div>
                                    <div class="sliicer-error-text" *ngIf="noDataRainMonitorError">
                                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_TABLE2_NO_DATA_RAIN_MONITOR_ERROR' | translate }}
                                    </div>
                                    <div class="sliicer-error-text"
                                        *ngIf="currentLocationGroupSummary?.levelMonitorNames?.length > 0">
                                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                                        {{ currentLocationGroupSummary?.levelMonitorNames?.length
                                        }}{{
                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_LEVEL_MONITORS_EXCLUDING_TXT'
                                        | translate
                                        }}
                                    </div>
                                </div>
                                <div class="stepper-action text-align">
                                    <div class="app-flex-filler"></div>
                                    <div class="auto-margin custom-flat-button">
                                        <button mat-button type="button" matStepperNext
                                            [disabled]="!locationGroupSelect.valid || invalidMonitors || noDataFlowMonitorError || noDataRainMonitorError"
                                            (click)="locationCompleteStatus = true">
                                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_NEXT_BUTTON' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </mat-step>
                            <!-- mat step for study period -->
                            <mat-step [editable]="!isCaseStudyCreated" class="!locationCompleteStatus" [completed]="locationGroupSelect.valid && !startdte.invalid &&
                            !enddte.invalid &&
                            !invalidDates &&
                            !invalidMonitors &&
                            !noDataFlowMonitorError &&
                            !noDataRainMonitorError &&
                            !invalidStartDate &&
                            !invalidEndDate">
                                <ng-template matStepLabel>
                                    <mat-icon class="done-icon" *ngIf="
                                            periodCompleteStatus  && !invalidDates && !enddte.invalid && !startdte.invalid
                                        ">check</mat-icon>{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_PERIOD' |
                                    translate }}
                                </ng-template>
                                <div class="stepperContent">
                                    <div class="mat-content-text">
                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_TEXT' | translate }}
                                    </div>
                                    <div class="custom-50p setup-field">
                                        <div class="custom-error study-start-content">
                                            <mat-form-field class="width-80-percent">
                                                <input matInput [min]="earliestAllowedDate" [value]="startDate | date: dateFormat" #startdte
                                                    [max]="latestAllowedDate" placeholder="{{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_START'
                                                            | translate
                                                    }}" (change)="onStartDateChanged($event)" required />
                                                <input matInput hidden [(ngModel)]="startDate"
                                                    [matDatepicker]="caseStudyStartDate" [min]="earliestAllowedDate" [max]="latestAllowedDate" placeholder="{{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_START'
                                                            | translate
                                                    }}" (dateChange)="onStartDateChanged($event)" name="startDate" />
                                                <mat-datepicker-toggle matSuffix [for]="caseStudyStartDate">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #caseStudyStartDate></mat-datepicker>
                                            </mat-form-field>
                                            <div class="custom-error-message" *ngIf="invalidStartDate">
                                                {{
                                                'SLIICER_TABLE.SLICER_SUMMARY.SLICER_INVALID_START_DATE'
                                                | translate
                                                | replace: '%':dateFormatUppercase
                                                }}
                                            </div>
                                        </div>
                                        <div class="custom-error study-start-content">
                                            <mat-form-field class="width-80-percent">
                                                <input matInput [value]="endDate | date: dateFormat" [min]="earliestAllowedDate" #enddte
                                                    [max]="latestAllowedDate" placeholder="{{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_END'
                                                            | translate
                                                    }}" (change)="onEndDateChanged($event)" required />
                                                <input matInput hidden [(ngModel)]="endDate" [min]="earliestAllowedDate"
                                                    [matDatepicker]="caseStudyEndDate" [max]="latestAllowedDate" placeholder="{{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_END'
                                                            | translate
                                                    }}" (dateChange)="onEndDateChanged($event)" name="endDate" />
                                                <mat-datepicker-toggle matSuffix [for]="caseStudyEndDate">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #caseStudyEndDate></mat-datepicker>
                                            </mat-form-field>
                                            <div class="custom-error-message" *ngIf="invalidEndDate">
                                                {{
                                                'SLIICER_TABLE.SLICER_SUMMARY.SLICER_INVALID_END_DATE'
                                                | translate
                                                | replace: '%':dateFormatUppercase
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wizard-error" *ngIf="datesTouched && invalidDates">
                                        {{ 'COMMON.START_END_DATE_DIFF_ERROR' | translate }}
                                    </div>
                                    <div class="wizard-error" *ngIf="datesTouched && isDatesOutside">
                                        {{ 'COMMON.SLIICER_DATE_OUTSIDE_ERROR' | translate : dateOutsideErrorParams }}
                                    </div>
                                    <div class="wizard-step-description"
                                        *ngIf="!invalidDates && !invalidStartDate && !invalidEndDate">
                                        <span class="study-button-background block">
                                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_DURATION' | translate }}:
                                            <span class="online-offline-dates">
                                                {{ daysInStudy }}
                                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DAYS' | translate }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="stepper-action text-align">
                                    <div class="auto-margin custom-flat-button">
                                        <button mat-button type="button" matStepperPrevious>
                                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_BACK_BUTTON' | translate }}
                                        </button>
                                        <button mat-button type="button" matStepperNext
                                            (click)="periodCompleteStatus = true" [disabled]="
                                                startdte.invalid ||
                                                enddte.invalid ||
                                                invalidDates ||
                                                invalidStartDate ||
                                                invalidEndDate
                                            ">
                                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_NEXT_BUTTON' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </mat-step>
                            <!-- mat step length-->
                            <mat-step [editable]="!isCaseStudyCreated" class="!periodCompleteStatus" [completed]="locationGroupSelect.valid && !invalidMonitors && !invalidDates && !noDataFlowMonitorError && !noDataRainMonitorError">
                                <ng-template matStepLabel>
                                    <mat-icon class="done-icon" *ngIf="stepLengthStatus">check</mat-icon>{{
                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STEP_LENGTH' | translate }}
                                </ng-template>
                                <div class="stepperContent">
                                    <div class="mat-content-text">
                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_STEP' | translate }}
                                    </div>
                                    <div class="custom-50p setup-field">
                                        <div class="custom-error mat-content-text study-start-content">
                                            <mat-form-field class="full-width">
                                                <mat-select name="caseStudyStepLength" matInput
                                                    #caseStudyStepLength="ngModel" [(ngModel)]="stepLength" placeholder=" {{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_STEP_PLACEHOLDER'
                                                            | translate
                                                    }}">
                                                    <mat-option *ngFor="let studyLengthOption of studyStepLengthOptions; trackBy: trackById"
                                                        [value]="studyLengthOption.id">{{ studyLengthOption.text }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="stepper-action text-align">
                                    <div class="auto-margin custom-flat-button">
                                        <button mat-button type="button" matStepperPrevious>
                                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_BACK_BUTTON' | translate }}
                                        </button>
                                        <button mat-button type="button" matStepperNext
                                            (click)="stepLengthStatus = true" [disabled]="invalidDates">
                                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_NEXT_BUTTON' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </mat-step>
                            <!-- mat step for confirmation-->
                            <mat-step [editable]="!isCaseStudyCreated" [completed]="locationGroupSelect.valid && !invalidMonitors && !invalidDates && !noDataFlowMonitorError && !noDataRainMonitorError">
                                <ng-template matStepLabel>
                                    <mat-icon class="done-icon" *ngIf="isCaseStudyCreated">check</mat-icon>{{
                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_CONFIRM_CONFIGURATION' | translate }}
                                </ng-template>
                                <div *ngIf="!isCaseStudyCreated">
                                    <div class="stepperContent">
                                        <div class="stepperContent study-start-content">
                                            <div class="grouplist-item">
                                                <p>
                                                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_CONFIRMATION' | translate }}
                                                </p>
                                                <ul class="items">
                                                    <li>
                                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_LIST1' | translate }}
                                                        <strong>{{
                                                            currentLocationGroupSummary?.locationGroupName
                                                            }}</strong>
                                                    </li>
                                                    <li>
                                                        {{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STARTDATE' | translate
                                                        }}
                                                        <strong>{{ startDate | date: dateFormat }}</strong> |
                                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_ENDDATE' | translate }}
                                                        <strong>{{ endDate | date: dateFormat }}</strong> |
                                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DURATION' | translate }}
                                                        <strong>{{ daysInStudy }}</strong>
                                                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DAYS' | translate }}
                                                    </li>
                                                    <li>
                                                        {{
                                                        'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STEPLENGTH' | translate
                                                        }}
                                                        <strong>{{ stepLength | customTimePipe }}</strong>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-stepper text-align">
                                        <div class="auto-margin custom-flat-button">
                                            <button mat-button matStepperPrevious>
                                                {{
                                                'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_BACK_BUTTON' | translate
                                                }}
                                            </button>
                                            <button mat-button matStepperNext (click)="createCaseStudy(stepper)"
                                                [disabled]="
                                                    !(locationGroupSelect.valid && !invalidMonitors && !noDataFlowMonitorError && !noDataRainMonitorError && !invalidDates)
                                                ">
                                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_CONFIRM' | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isCaseStudyCreated">
                                    <div class="stepperContent" style="text-align: center">
                                        <div class="mat-content-text pad-t" style="width: max-content; margin: auto">
                                            <p class="success-msg">
                                                <app-loader [isPositionAbsolute]="false"
                                                    [isLoading]="!createStudyComplete"></app-loader>
                                                <mat-icon *ngIf="createStudyComplete"
                                                    class="sliicer-check-icone-success">check</mat-icon>
                                                <span class="completion-header">{{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.CREATE_STUDY' | translate
                                                    }}</span>
                                            </p>
                                            <p class="success-msg">
                                                <span *ngIf="!createStudyComplete"
                                                    style="display: block; width: 41px"></span>
                                                <app-loader *ngIf="createStudyComplete" [isPositionAbsolute]="false"
                                                    [isLoading]="!copyTelemetryComplete"></app-loader>
                                                <mat-icon *ngIf="copyTelemetryComplete"
                                                    class="sliicer-check-icone-success">check</mat-icon>
                                                <span class="completion-header">{{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.COPY_TELEMETRY' | translate
                                                    }}</span>
                                            </p>
                                            <p class="success-msg">
                                                <span *ngIf="!copyTelemetryComplete"
                                                    style="display: block; width: 41px"></span>
                                                <app-loader *ngIf="copyTelemetryComplete" [isPositionAbsolute]="false"
                                                    [isLoading]="!initialCalculationsComplete"></app-loader>
                                                <mat-icon *ngIf="initialCalculationsComplete"
                                                    class="sliicer-check-icone-success">check</mat-icon>
                                                <span class="completion-header">{{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.INITIAL_CALCULATIONS' | translate
                                                    }}</span>
                                            </p>
                                            <p *ngIf="
                                                    createStudyComplete &&
                                                    copyTelemetryComplete &&
                                                    initialCalculationsComplete
                                                " class="success-msg text-color" (click)="beginAnalysis()">
                                                <span>{{
                                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_BEGIN_ANALYSIS'
                                                    | translate
                                                    }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </form>
                </div>
            </mat-tab>

            <!-- US #30064 TASK #30442 SLiiCER - New Study - for January release - Remove import existing study -->
            <!-- <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_IMPORT' | translate }}">
                <app-flowload-file-upload (flowLoadFileUploaded)="flowLoadFileUploaded($event)">
                </app-flowload-file-upload>
                <div class="file-upload-dialog">
                    <div class="text-top-space">
                        <button mat-button class="vault-button" (click)="importVaultStudy(null, null)">
                            {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_VALUT_BUTTON' | translate }}
                        </button>
                    </div>
                </div>
            </mat-tab> -->
        </mat-tab-group>
    </div>
</div>
