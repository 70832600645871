import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { UnitOfMeasureType } from 'app/shared/constant';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { Selectable } from 'app/shared/models/selectable';
import { CustomerService } from 'app/shared/services/customer.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { LocationCardService } from '../../services/location-card.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-sampler-info',
    templateUrl: './sampler-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class SamplerInfoComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;

    public triggerPlaceholder: string;
    public samplerTypes: Selectable[] = [];
    public samplerPerWakeupOptions: Selectable[] = [];
    public samplerPulseLengthOptions: Selectable[] = [];
    public triggerErrorMessage: string;

    public trackById = TrackBy.byId;
    constructor(
        private translateService: TranslateService,
        private customerService: CustomerService,
        private locationCardService: LocationCardService,
    ) {}

    ngOnInit() {
        this.triggerPlaceholder = this.translateService.instant('COMMON.TRIGGER');
        this.samplerTypes = [
            { id: 0, name: this.translateService.instant('COMMON.OFF_TEXT') },
            { id: 1, name: this.translateService.instant('COMMON.DEPTH') },
            { id: 2, name: this.translateService.instant('COMMON.FLOW_RATE') },
            { id: 3, name: this.translateService.instant('COMMON.FLOW_TOTAL') },
        ];

        for (let i = 1; i <= 10; i++) {
            this.samplerPerWakeupOptions.push({ id: i, name: String(i) });
            this.samplerPulseLengthOptions.push({ id: i, name: String(i) });
        }

        if (
            this.monitorConfigs &&
            this.monitorConfigs.devices &&
            this.monitorConfigs.devices.find((v) => v.name === 'Sampler')
        ) {
            const samplerForm = this.monitorConfigs.devices.find((v) => v.name === 'Sampler');

            this.form.patchValue(samplerForm);
        }
    }

    public onTypeChange(event: MatSelectChange) {
        let units = '';

        if (!event.value) {
            this.triggerPlaceholder = this.translateService.instant('COMMON.TRIGGER');
            this.form.get('trigger').clearValidators();
            return;
        }

        if (event.value === 3) {
            units = this.getFlowTotalMeasure();
        } else if (event.value === 2) {
            units = this.getFlowRateValues();
        } else if (event.value === 1) {
            units = this.locationCardService.depthUnit;
        }

        this.setTriggerValidator(units);
        this.triggerPlaceholder = `${this.translateService.instant('COMMON.TRIGGER')} (${units})`;
    }

    private getFlowTotalMeasure() {
        const units = this.customerService.customer.unitsType;

        if (units === UnitOfMeasureType.MGD) {
            return 'MG';
        } else if (units === UnitOfMeasureType.CFS) {
            return 'Ft3';
        } else {
            return 'ML';
        }
    }

    private getFlowRateValues() {
        const units = this.customerService.customer.unitsType;

        if (units === UnitOfMeasureType.MGD) {
            return 'MGD';
        } else if (units === UnitOfMeasureType.CFS) {
            return 'CFS';
        } else {
            return 'l/s';
        }
    }

    private setTriggerValidator(units: string) {
        const triggerControl = this.form.get('trigger');

        switch (units) {
            case 'Ft3':
                {
                    triggerControl.setValidators(Validators.pattern(REGEX_CONFIG.numericOnlyPattern));
                    this.triggerErrorMessage = this.translateService.instant('COMMON.NUMERIC_ERROR');
                }
                break;

            case 'mm':
            case 'l/s':
                {
                    triggerControl.setValidators(Validators.pattern(REGEX_CONFIG.numericDecimal1PlacePattern));
                    this.triggerErrorMessage = this.translateService.instant('COMMON.NUMERIC_PRECISION_ERROR_METRIC');
                }
                break;

            case 'in':
                {
                    triggerControl.setValidators(Validators.pattern(REGEX_CONFIG.numericDecimal2PlacePattern));
                    this.triggerErrorMessage = this.translateService.instant('COMMON.NUMERIC_PRECISION_ERROR');
                }
                break;

            case 'MGD':
            case 'CFS':
            case 'ML':
            case 'MG':
                {
                    triggerControl.setValidators(Validators.pattern(REGEX_CONFIG.numericDecimal3PlacePattern));
                    this.triggerErrorMessage = this.translateService.instant(
                        'COMMON.NUMERIC_PRECISION_ERROR_3_DECIMALS',
                    );
                }
                break;
        }
    }
}
