import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';

import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subscription, forkJoin } from 'rxjs';
import { StormEvent, BasinStormSettings, Overrides, BasinStormResult, PrecompensationType, SliicerCaseStudy } from 'app/shared/models/sliicer';
import { StormEventAdjustment, StormSettings } from 'app/shared/models/sliicer/overrides';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import {
  EditStormEventsComponent,
  EditStormEventsDialogOutput,
} from '../edit-storm-events/edit-storm-events.component';
import { EditStormEvents, ExpandedStormEvent, ExpandedViewStormEvent, QvsIConfigurations } from 'app/shared/models/sliicer/results/storm-events';
import { ExcludeStormChanged } from '../../flow-monitor.model';
import { QvsIGroupDialogComponent } from '../q-vs-i-group-dialog/q-vs-i-group-dialog.component';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { CustomerUnits } from 'app/shared/models/location-details';
import { QVI_GROUP_ALL_STORMS, QVI_GROUP_ALL_STORMS_CONFIG } from 'app/shared/models/sliicer/basins';
import { UsersService } from 'app/pages/admin/users.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
  selector: 'app-expanded-storm-chooser',
  templateUrl: './expanded-storm-chooser.component.html',
  styleUrls: ['./expanded-storm-chooser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpandedStormChooserComponent implements OnInit, OnChanges, OnDestroy {

  private paginator: MatPaginator;
  private sort: MatSort;
  unitPerHour: string;
  unitsPrecision: unknown;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.stormDataSource.paginator = this.paginator;
    this.stormDataSource.sort = this.sort;
  }
  @Input() public defaultPrecompType: PrecompensationType;
  @Input() public caseStudyId: string;
  @Input() public customerId: number;
  @Input() public basinStormSettings: BasinStormSettings[];
  @Input() public selectedBasin: string;
  @Input() public stormSettings: StormSettings[] = [];
  @Input() public addedStormEvents: StormEventAdjustment[] = [];
  @Input() public removedStormEvents: StormEventAdjustment[] = [];
  @Input() public stormBasinResults: BasinStormResult[];
  @Input() public isLoading: boolean;
  @Input() public basinStormResult: BasinStormResult;
  @Input() public qvsIConfigurations: QvsIConfigurations[];
  @Input() public originalQvsIConfigurations: QvsIConfigurations[];
  @Input() public regimes: [];
  @Input() public availableSeasons: [];
  @Output() public excludedStormToggled = new EventEmitter<ExcludeStormChanged>();
  @Output() public stormSelected = new EventEmitter<number>();
  @Output() public stormEventsEdited = new EventEmitter<Overrides>();
  @Output() public qvsiConfChanged = new EventEmitter<QvsIConfigurations[]>();
  @Output() public expandChooser = new EventEmitter<boolean>();
  private subscriptions: Subscription[] = [];

  public PrecompensationType = PrecompensationType;

  public isStudyLocked: boolean;
  public selectedStorm = {} as StormEvent;
  public stormDataSource = new MatTableDataSource([]);
  public customDateFormat: string;
  public stormColumns = ['color', 'group', 'stormStartTime', 'RainPK', 
    // 'rollingPeakRain', 'rollingRainPeakTime',  columns are hidden by US 31871
    'rainTotal', 'preCtype', 'preComp', 'durStorm', 'stormRain', 
    'groQPkSto', 'groQVolSto', 'groIIPkSto', 'groIIVolSto', 'netIIPkSto', 'netIIVolSto', 
    'eventDuration', 'eventRainfall', 'groQPkEvt', 'groQVolEvt', 'groIIPkEvt',
    'groIIVolEvt', 'netIIPkEvt', 'netIIVolEvt', 'groQPkR1', 'groQVolR1', 'groIIPkR1',
    'groIIVolR1', 'netIIPkR1', 'netIIVolR1', 'groQPkR2', 'groQVolR2', 'groIIPkR2',
    'groIIVolR2', 'netIIPeakR2', 'netIIVolR2'];
  public QvsiGroup = 'All Storms'
  public stormEvents = [];
  public pageSize = 10;
  public sortColumn: string;
  public caseStudy: SliicerCaseStudy;
  public paginationMaxLength;
  public selectedStormConfig: QvsIConfigurations;
  public units: CustomerUnits;

  public isOwnStudy = false;

  public trackByIndex = TrackBy.byIndex;
  constructor(
    private sliicerService: SliicerService,
    private dateutilService: DateutilService,
    private dialog: MatDialog,
    private uiUtilsService: UiUtilsService,
    private changeDetector: ChangeDetectorRef,
    private userService: UsersService
  ) { }

  public ngOnInit() {
    // set Locked study notifiaction
    this.subscriptions.push(
      this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
        this.isStudyLocked = !editable;
      })
    );

    this.subscriptions.push(
        this.sliicerService.studyDetailsData$.subscribe((caseStudyDetails: SliicerCaseStudy) => {
            this.caseStudy = caseStudyDetails;
            this.isOwnStudy = caseStudyDetails.meta.authors.some(v => v.id === this.userService.userID.getValue());
        })
    );
    this.subscriptions.push(this.sliicerService.units.subscribe(res => {
      this.units = res;
    }));
    this.subscriptions.push(this.sliicerService.unitsPrecision.subscribe(res => {
      this.unitsPrecision = res;
    }));
    this.unitPerHour = this.dateutilService.unitOfMeasurePerHour.getValue();
    this.subscriptions.push(this.sliicerService.qvsiSelectedConfig.subscribe(qvsiConfig => {
        this.processSelectedConfig(qvsiConfig);
    }))

    this.customDateFormat = this.sliicerService.dateTimeFormatEditStorms();
    this.isLoading = true;
  }

  public loadStormEvents(basinName: string): void {
    this.isLoading = true;
    // #33898 and #33839 Removed ones do not have basin results (API response will return nothing), however we should display them
    forkJoin([
        this.sliicerService.getStorms(this.customerId, this.caseStudyId),
        this.sliicerService.getAllStormBasinResults(this.customerId, this.caseStudyId, basinName)
    ]).subscribe(
      ([stormsResult, stormsBasinResults]) => {
        if (stormsResult || stormsBasinResults) {
          // #33898 and #33839 Depends on where we have data, display storms or full stats
          this.stormEvents =
            (stormsBasinResults && stormsBasinResults.length)
            ? [...stormsBasinResults]
            : (stormsResult && stormsResult.storms && stormsResult.storms.length)
            ? [...stormsResult.storms]
            : [];
          this.paginationMaxLength = this.stormEvents.length;

          this.refreshTable();

          this.stormDataSource.paginator = this.paginator;
        } else {
          this.stormEvents = [];
        }
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
      },
      () => {
        this.stormEvents = [];
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
      },
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
  }

  public ngOnChanges(changes: SimpleChanges) {
    let needUpdate = false;

    if(changes.originalQvsIConfigurations) {
        const config = this.sliicerService.qvsiSelectedConfig.getValue();
        this.processSelectedConfig(config, true);
    }

    if (
      changes.addedStormEvents ||
      changes.removedStormEvents ||
      changes.selectedBasin ||
      changes.basinStormSettings ||
      changes.stormBasinResults
    ) {
      needUpdate = true;
    }

    if (needUpdate) {
      this.refreshTable();
    }
  }

  private processSelectedConfig(qvsiConfig: {conf: QvsIConfigurations, afterupdate: boolean}, forceGroupSelect = false) {
    if (!qvsiConfig || qvsiConfig.afterupdate) return;

    if (qvsiConfig == null) qvsiConfig.conf = QVI_GROUP_ALL_STORMS_CONFIG;
    if (forceGroupSelect || (qvsiConfig && this.selectedStormConfig !== qvsiConfig.conf)) {
        this.QvsiGroup = qvsiConfig.conf.name;
        this.selectedStormConfig = qvsiConfig.conf;
        this.qvsiGroupSelect(qvsiConfig.conf.name, false);
    }
}

  /**
   * Called from UI when user toggles a storm to exclude from results
   * @param event the UI click event
   * @param stormId the storm that has been toggled
   */
  public toggleStormBasinExclusion(event, stormId: number): void {
    this.excludedStormToggled.emit({
      stormId: stormId,
      excluded: event.checked,
    });
  }

  /**
   * Called when the user selects a storm
   * @param stormId the selected row in the list of storms
   */
  public setSelectedStorm(stormId: number) {
    this.selectedStorm = this.stormEvents.find((s) => s.stormId === stormId);
  }

  public editStormEvents(): void {
    if (!this.isStudyLocked) {
      this.dialog
        .open<EditStormEventsComponent, EditStormEvents, EditStormEventsDialogOutput>(
          EditStormEventsComponent,
          {
            disableClose: true,
            data: {
              stormEvents: this.stormEvents,
              basinStormResult: this.basinStormResult,
              stormSettings: this.stormSettings,
              addedStormEvents: this.addedStormEvents,
              removedStormEvents: this.removedStormEvents,
              basinStormSettings: this.basinStormSettings,
              selectedBasin: this.selectedBasin,
            },
          },
        )
        .afterClosed()
        .subscribe((data) => {
          if (data.updated) {
            this.stormEventsEdited.emit(data.overrides);
          }
        });
    }
  }

  public expandChooserFull() {
    this.expandChooser.emit(false);
  }

  private bindStorms(
    stormEvents: ExpandedViewStormEvent[],
    selectedBasin: string,
    stormSettings: StormSettings[],
    basinStormSettings: BasinStormSettings[],
  ): MatTableDataSource<any> {
    let currentExcludedBasinStorms = [];
    if (basinStormSettings) {
        currentExcludedBasinStorms = basinStormSettings
            .filter((s) => s.basinName === selectedBasin && s.exclude === true)
            .map((s) => s.stormId);
    }

    const stormData = stormEvents.map((s) => {
      const basinSetting = basinStormSettings.find(
        (x) => x.stormId === s.stormId && x.basinName === selectedBasin,
      );
      const stormSetting = stormSettings.find((x) => x.stormId === s.stormId);
      const stormExists = this.stormBasinResults ? this.stormBasinResults.find((x) => x.stormId === s.stormId) : null;

      let isManuallyAdded = false;

      if (s.manuallyAdded || this.caseStudy?.overrides?.addedStormEvents?.find((x) => x.stormStartTime === s.stormStartTime)) {
        isManuallyAdded = true;
      }
      
      const labelExtra = basinSetting
        ? this.sliicerService.stormLabelExtras(s.stormStartTime, {
          season: basinSetting && basinSetting.season ? basinSetting.season : undefined,
          regime: basinSetting && basinSetting.regime ? basinSetting.regime : undefined,
        })
        : s.labelExtra;

      const stormConfigGroup = this.processQvsiFilter(s)

      return {
        isExcluded: currentExcludedBasinStorms.indexOf(s.stormId) > -1,
        stormId: s.stormId,
        stormStartTime:
          stormSetting && stormSetting.stormStartTime ? stormSetting.stormStartTime : s.stormStartTime,
        statusTag: s.statusTag,
        saved: s.saved,
        qvsiGroup: stormConfigGroup.group,
        color: stormConfigGroup.color,
        labelExtra: labelExtra,
        preCtype: s.preCompType,
        preComp: s.precompAdjust,
        RainPK: s.rainPeak,
        rainStorm: s.stormRain,
        rollingPeakRain: s.rollingRainPeak,
        rollingRainPeakTime: s.rollingRainPeakTime,
        durStorm: s.stormDuration,
        rainTotal: s.totalRain,
        groQPkSto: s.grossQPeakStorm,
        groQVolSto: s.grossQVolumeStorm,
        groIIPkSto: s.grossIIPeakStorm,
        groIIVolSto: s.grossIIVolumeStorm,
        netIIPkSto: s.netIIPeakStorm,
        netIIVolSto: s.netIIVolumeStorm,
        eventDuration: s.eventDuration,
        eventRainfall: s.eventRain,
        groQPkEvt: s.grossQPeakEvent,
        groQVolEvt: s.grossQVolumeEvent,
        groIIPkEvt: s.grossIIPeakEvent,
        groIIVolEvt: s.grossIIVolumeEvent,
        netIIPkEvt: s.netIIPeakEvent,
        netIIVolEvt: s.netIIVolumeEvent,
        groQPkR1: s.grossQPeakR1,
        groQVolR1: s.grossQVolumeR1,
        groIIPkR1: s.grossIIPeakR1,
        groIIVolR1: s.grossIIVolumeR1,
        netIIPkR1: s.netIIPeakR1,
        netIIVolR1: s.netIIVolumeR1,
        groQPkR2: s.grossQPeakR2,
        groQVolR2: s.grossQVolumeR2,
        groIIPkR2: s.grossIIPeakR2,
        groIIVolR2: s.grossIIVolumeR2,
        netIIPeakR2: s.netIIPeakR2,
        netIIVolR2: s.netIIVolumeR2,
        stormExists: stormExists || isManuallyAdded ? true : false,
      };
    });

    stormData.sort((a,b) => new Date(a.stormStartTime).getTime() - new Date(b.stormStartTime).getTime());
    return new MatTableDataSource(stormData);
  }

  public updateStormEntry(storm: BasinStormResult) {
    if (!this.stormDataSource || !this.stormDataSource.data.length) {
      return;
    }

    this.stormDataSource.data = this.stormDataSource.data.map((item) => {
      if (item.stormId !== storm.stormId) {
        return item;
      }

      return {
        isExcluded: storm.exclude,
        stormId: storm.stormId,
        stormStartTime: storm.stormStartTime,
        statusTag: item.statusTag,
        saved: item.saved,
        qvsiGroup: item.qvsiGroup,
        color: item.color,
        labelExtra: item.labelExtra,
        preCtype: storm.preCompType,
        preComp: storm.precompAdjust,
        RainPK: storm.rainPeak,
        rainStorm: storm.stormRain,
        rollingPeakRain: storm.rollingRainPeak,
        rollingRainPeakTime: storm.rollingRainPeakTime,
        durStorm: storm.stormDuration,
        rainTotal: storm.totalRain,
        groQPkSto: storm.grossQPeakStorm,
        groQVolSto: storm.grossQVolumeStorm,
        groIIPkSto: storm.grossIIPeakStorm,
        groIIVolSto: storm.grossIIVolumeStorm,
        netIIPkSto: storm.netIIPeakStorm,
        netIIVolSto: storm.netIIVolumeStorm,
        eventDuration: storm.eventDuration,
        eventRainfall: storm.eventRain,
        groQPkEvt: storm.grossQPeakEvent,
        groQVolEvt: storm.grossQVolumeEvent,
        groIIPkEvt: storm.grossIIPeakEvent,
        groIIVolEvt: storm.grossIIVolumeEvent,
        netIIPkEvt: storm.netIIPeakEvent,
        netIIVolEvt: storm.netIIVolumeEvent,
        groQPkR1: storm.grossQPeakR1,
        groQVolR1: storm.grossQVolumeR1,
        groIIPkR1: storm.grossIIPeakR1,
        groIIVolR1: storm.grossIIVolumeR1,
        netIIPkR1: storm.netIIPeakR1,
        netIIVolR1: storm.netIIVolumeR1,
        groQPkR2: storm.grossQPeakR2,
        groQVolR2: storm.grossQVolumeR2,
        groIIPkR2: storm.grossIIPeakR2,
        groIIVolR2: storm.grossIIVolumeR2,
        netIIPeakR2: storm.netIIPeakR2,
        netIIVolR2: storm.netIIVolumeR2,
        stormExists: storm.totalRain > 0,
      };
    });
  }

  public setCustomFilter() {
    this.dialog
      .open<QvsIGroupDialogComponent>(
        QvsIGroupDialogComponent,
        {
          data: {
            qvsIConfigurations: this.qvsIConfigurations,
            originalQvsIConfigurations: this.originalQvsIConfigurations,
            regimes: this.regimes,
            availableSeasons: this.availableSeasons,
          },
        },
      )
      .afterClosed()
      .subscribe((res) => {
        this.qvsiConfChanged.emit(res);
      });
  }

  public qvsiGroupSelect(event, trigger) {
    this.selectedStormConfig = this.originalQvsIConfigurations.find(item => item.name === event);

    this.refreshTable();
    this.stormDataSource.paginator = this.paginator;
    if (trigger) {
      this.sliicerService.qvsiSelectedConfig.next({ conf: this.selectedStormConfig, afterupdate: false });
    }
  }

  public refreshTable() {
    this.stormEvents = this.stormEvents.filter(v => this.stormBasinResults.find(i => i.stormId === v.stormId));
    
    this.stormDataSource = this.bindStorms(
      this.stormEvents,
      this.selectedBasin,
      this.stormSettings,
      this.basinStormSettings,
    );
  }

  private processQvsiFilter(storm) {
    const configs = {
      color: '#0000FF',
      group: 'All Storms'
    }

    if (this.selectedStormConfig) {
      configs['color'] = '';
      configs['group'] = '';
      if (storm.configurationGroups && storm.configurationGroups[this.selectedStormConfig.name]) {
        const configGroup = this.selectedStormConfig.groups.find(x => x.name === storm.configurationGroups[this.selectedStormConfig.name])
        if (configGroup) {
          configs['color'] = configGroup.color;
          configs['group'] = configGroup.name;
        }
      }
    }

    return configs
  }
}

