<div id="monitorSettingsDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'HOME.MAP.MONITOR_SETTINGS.TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div class="monitorSettingsContainer">
        <div *ngIf="monitorSetting.monitorSeries == 'RAINALERT III'">
            <form #rainMoniterForm="ngForm">
                <div mat-dialog-content>
                    <div class="customersDropdown full-width top">
                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.SAMPLE_RATE_PLACEHOLDER' | translate }}"
                                name="monitorSetting.samplerate"
                                [(ngModel)]="monitorSetting.samplerate"
                                class="full-width"
                                required
                                ngDefaultControl
                            >
                                <mat-option *ngFor="let Rates of monitorSetting.SampleRateUI; trackBy: trackByIndex" [value]="Rates.value">{{
                                    Rates.text
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="errorMessage">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.RAIN_PER_TIP_TEXT' | translate }}"
                                id="rainpertipInput"
                                name="monitorSetting.rainpertip"
                                [(ngModel)]="monitorSetting.rainpertip"
                                #rainpertipInput="ngModel"
                                required
                                maxlength="12"
                                [pattern]="rainPerTipPattern"
                            />
                        </mat-form-field>
                        <div class="errorMsgText" *ngIf="rainpertipInput?.errors?.pattern">
                            {{ 'HOME.MAP.MONITOR_SETTINGS.RAIN_PER_TIP_VALIDATION' | translate }}
                        </div>
                    </div>
                    <div class="pad-b-20 width-45-p">
                        <mat-checkbox
                            name="monitorSetting.logintensity"
                            (change)="checkRainIntensityReqd()"
                            [(ngModel)]="monitorSetting.logintensity"
                            ngDefaultControl
                        >
                            <strong>{{ 'HOME.MAP.MONITOR_SETTINGS.LOG_INTENSITY_TEXT' | translate }}</strong>
                        </mat-checkbox>
                    </div>
                    <!-- Added store tempareture and battery voltage-->
                    <div class="pad-b-20 width-45-p">
                        <mat-checkbox
                            name="monitorSetting.storetemperature"
                            [(ngModel)]="monitorSetting.storetemperature"
                            ngDefaultControl
                        >
                            <strong>{{ 'HOME.MAP.MONITOR_SETTINGS.STORE_TEMPERATURE_TEXT' | translate }}</strong>
                        </mat-checkbox>
                    </div>
                    <div class="customersDropdown full-width">
                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.STORE_BATTERY_PLACEHOLDER' | translate }}"
                                name="monitorSetting.storebattery"
                                [(ngModel)]="monitorSetting.storebattery"
                                class="full-width"
                                required
                                ngDefaultControl
                            >
                                <mat-option
                                    *ngFor="let storeBV of monitorSetting.storeBatteryVoltageUI; trackBy: trackByIndex"
                                    [value]="storeBV.value"
                                    >{{ storeBV.text }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- End -->
                    <div class="pad-b-20 width-45-p">
                        <mat-checkbox
                            name="monitorSetting.logukintensity"
                            (change)="checkRainIntensityReqd()"
                            [(ngModel)]="monitorSetting.logukintensity"
                            ngDefaultControl
                        >
                            <strong>{{ 'HOME.MAP.MONITOR_SETTINGS.UK_INTENSITY' | translate }}</strong>
                        </mat-checkbox>
                    </div>
                    <div class="customersDropdown full-width">
                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.DATA_LOG_MODE' | translate }}"
                                name="monitorSetting.datalogmode"
                                [(ngModel)]="monitorSetting.datalogmode"
                                class="full-width"
                                required
                                ngDefaultControl
                            >
                                <mat-option *ngFor="let Mode of monitorSetting.DataLogMode; trackBy: trackByIndex" [value]="Mode.value">{{
                                    Mode.text
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="errorMessage">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                placeholder="{{
                                    'HOME.MAP.MONITOR_SETTINGS.RAIN_INTENSITY_MINUTE_PLACEHOLDER' | translate
                                }}"
                                id="rainIntensityIntervalsInput"
                                name="monitorSetting.intensityinterval"
                                [(ngModel)]="monitorSetting.intensityinterval"
                                #rainIntensityIntervalsInput="ngModel"
                                [pattern]="numeric1_1440Pattern"
                                [required]="monitorSetting.isRainIntensity"
                            />
                        </mat-form-field>
                        <span *ngIf="rainIntensityIntervalsInput?.errors?.pattern" class="errorMsgText">
                            {{ 'HOME.MAP.MONITOR_SETTINGS.RAIN_INTENSITY_VALIDATION' | translate }}
                        </span>
                    </div>

                    <div class="pad-b-20">
                        <mat-checkbox
                            name="rainExceedingFlag"
                            [checked]="monitorSetting.alarmenable"
                            [(ngModel)]="monitorSetting.alarmenable"
                            ngDefaultControl
                        >
                            <strong>{{ 'HOME.MAP.MONITOR_SETTINGS.RAIN_EXCEEDING_LABEL' | translate }}</strong>
                        </mat-checkbox>
                    </div>

                    <div class="custom-50p" *ngIf="monitorSetting.alarmenable">
                        <div
                            class="each-Item errorMessage"
                            [ngClass]="{ 'pad-b-15': alarmThresholdInput?.errors?.pattern }"
                        >
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    placeholder="{{
                                        'HOME.MAP.MONITOR_SETTINGS.ALARAM_THRESHOLD_PLACEHOLDER' | translate
                                    }}"
                                    id="alarmThresholdInput"
                                    name="monitorSetting.alarmthreshold"
                                    [(ngModel)]="monitorSetting.alarmthreshold"
                                    #alarmThresholdInput="ngModel"
                                    [pattern]="rainPerTipPattern"
                                    [required]="monitorSetting.alarmenable"
                                />
                            </mat-form-field>
                            <div class="errorMsgText" *ngIf="alarmThresholdInput?.errors?.pattern">
                                {{ 'HOME.MAP.MONITOR_SETTINGS.ALARAM_THRESHOLD_VALIDATION' | translate }}
                            </div>
                        </div>
                        <div
                            class="each-Item errorMessage"
                            [ngClass]="{ 'pad-b-15': returnToNormalInput?.errors?.pattern }"
                        >
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    placeholder="{{
                                        'HOME.MAP.MONITOR_SETTINGS.RETURN_TO_NORMAL_PLACEHOLDER' | translate
                                    }}"
                                    id="returnToNormalInput"
                                    name="monitorSetting.alarmreturntonormal"
                                    [(ngModel)]="monitorSetting.alarmreturntonormal"
                                    #returnToNormalInput="ngModel"
                                    [pattern]="rainPerTipPattern"
                                    [required]="monitorSetting.alarmenable"
                                />
                            </mat-form-field>
                            <div class="errorMsgText" *ngIf="returnToNormalInput?.errors?.pattern">
                                {{ 'HOME.MAP.MONITOR_SETTINGS.RETURN_TO_NORMAL_VALIDATION' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="customersDropdown full-width">
                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'COMMON.TIMEZONE_PLACEHOLDER' | translate }}"
                                name="monitorSetting.timezone"
                                [(ngModel)]="monitorSetting.timezone"
                                class="full-width"
                                required
                                ngDefaultControl
                            >
                                <mat-option
                                    *ngFor="let TimeZoneType of monitorSetting.TimeZone; trackBy: trackByIndex"
                                    [value]="TimeZoneType.text"
                                    >{{ TimeZoneType.text }} ({{ TimeZoneType.utcValue }})</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="pad-b-20">
                        <mat-checkbox
                            name="monitorSetting.usedaylightsaving"
                            [(ngModel)]="monitorSetting.usedaylightsaving"
                            ngDefaultControl
                        >
                            <strong>{{ 'COMMON.ENABLE_DAYLIGHT_TEXT' | translate }}</strong>
                        </mat-checkbox>
                    </div>
                    <strong>{{ 'HOME.MAP.MONITOR_SETTINGS.DATA_DELIVERY_TEXT' | translate }}</strong>
                    <div class="custom-33p margin-t-15">
                        <div class="each-Item errorMessage">
                            <mat-form-field class="full-width">
                                <input
                                    matTooltip="{{ 'HOME.MAP.IP_ADDRESS_TOOLTIP' | translate }}"
                                    matInput
                                    id="rainAlertMonitorSettingIpaddressInput"
                                    placeholder="PRISM IP Address"
                                    name="monitorSetting.datadeliveryipaddress"
                                    [(ngModel)]="monitorSetting.datadeliveryipaddress"
                                    #rainAlertMonitorSettingIpaddressInput="ngModel"
                                    [pattern]="ipaddressPattern"
                                    maxlength="100"
                                />
                            </mat-form-field>
                            <div class="errorMsgText" *ngIf="rainAlertMonitorSettingIpaddressInput?.errors?.pattern">
                                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.IP_ADDRESS_ERROR' | translate }}
                            </div>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.NORMAL_RATE_TEXT' | translate }}"
                                    name="monitorSetting.datadeliverynormal"
                                    [(ngModel)]="monitorSetting.datadeliverynormal"
                                    class="full-width"
                                    ngDefaultControl
                                >
                                    <mat-option [value]="0">{{ 'COMMON.OFF_TEXT' | translate }}</mat-option>
                                    <mat-option *ngFor="let Rates of monitorSetting.NormalRate; trackBy: trackByIndex" [value]="Rates.value">{{
                                        Rates.text
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.FAST_RATE_TEXT' | translate }}"
                                    name="monitorSetting.datadeliveryfast"
                                    [(ngModel)]="monitorSetting.datadeliveryfast"
                                    class="full-width"
                                    ngDefaultControl
                                >
                                    <mat-option [value]="0">{{ 'COMMON.OFF_TEXT' | translate }}</mat-option>
                                    <mat-option *ngFor="let Rates of monitorSetting.FastRate" [value]="Rates.value">{{
                                        Rates.text
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>

            <div mat-dialog-actions>
                <app-loader [isLoading]="activateLocationLoadingState"></app-loader>

                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="emitMonitorSettings()">{{ 'COMMON.CLOSE' | translate }}</button>
                </div>
                <div class="auto-margin">
                    <button
                        mat-button
                        [disabled]="
                            !rainMoniterForm.valid ||
                            rainMoniterForm.pristine ||
                            monitorSetting.intensityinterval <= 0 ||
                            monitorSetting.intensityinterval > 1440
                        "
                        (click)="activateLocation()"
                    >
                        <img class="img-icon-24" [ngSrc]="'/assets/images/activate.png'" height="24" width="24" />
                    </button>
                </div>
            </div>
        </div>
        <div id="echoMonitor" *ngIf="monitorSetting.monitorSeries == 'ECHO'">
            <form #echoMoniterForm="ngForm" name="echoMoniterForm" novalidate>
                <div mat-dialog-content>
                    <div class="custom-50p top">
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.SAMPLE_RATE_PLACEHOLDER' | translate }}"
                                    name="monitorSetting.samplerate"
                                    [(ngModel)]="monitorSetting.samplerate"
                                    class="full-width"
                                    required
                                >
                                    <mat-option
                                        *ngFor="let Rates of monitorSetting.SampleRateUI; trackBy: trackByIndex"
                                        [value]="Rates.value"
                                        >{{ Rates.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.FAST_RATE_TEXT' | translate }}"
                                    name="monitorSetting.fastrate"
                                    [(ngModel)]="monitorSetting.fastrate"
                                    class="full-width"
                                    required
                                >
                                    <mat-option
                                        *ngFor="let Rates of monitorSetting.echoFastRate; trackBy: trackByIndex"
                                        [value]="Rates.value"
                                        >{{ Rates.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="custom-50p">
                        <div class="each-Item errorMessage">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    placeholder="{{ 'COMMON.MANHOLE_DEPTH' | translate }}"
                                    name="monitorSetting.manholedepth"
                                    id="manholeDepthInput"
                                    [(ngModel)]="monitorSetting.manholedepth"
                                    #manholeDepthInput="ngModel"
                                    required
                                    maxlength="100"
                                    [pattern]="numericDecimal2PlacePattern"
                                />
                            </mat-form-field>
                            <div class="errorMsgText" *ngIf="manholeDepthInput?.errors?.pattern">
                                {{ 'HOME.MAP.MONITOR_SETTINGS.MANHOLE_DEPTH_VALIDATION' | translate }}
                            </div>
                        </div>
                        <div class="each-Item errorMessage">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.PHYSICAL_OFFSET_TEXT' | translate }}"
                                    name="monitorSetting.physicaloffset"
                                    id="physicaloffsetInput"
                                    [(ngModel)]="monitorSetting.physicaloffset"
                                    #physicaloffsetInput="ngModel"
                                    required
                                    maxlength="100"
                                    [pattern]="numericDecimal2PlacePattern"
                                />
                            </mat-form-field>
                            <div class="errorMsgText" *ngIf="physicaloffsetInput?.errors?.pattern">
                                {{ 'HOME.MAP.MONITOR_SETTINGS.MANHOLE_DEPTH_VALIDATION' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="custom-50p">
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'COMMON.TIMEZONE_PLACEHOLDER' | translate }}"
                                    name="monitorSetting.timezone"
                                    [(ngModel)]="monitorSetting.timezone"
                                    class="full-width"
                                    required
                                >
                                    <mat-option
                                        *ngFor="let TimeZoneType of monitorSetting.TimeZone; trackBy: trackByIndex"
                                        [value]="TimeZoneType.text"
                                        >{{ TimeZoneType.text }} ({{ TimeZoneType.utcValue }})</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="each-Item pad-t-20">
                            <mat-checkbox
                                name="monitorSetting.usedaylightsavingtime"
                                [(ngModel)]="monitorSetting.usedaylightsaving"
                            >
                                <strong>{{ 'COMMON.ENABLE_DAYLIGHT_TEXT' | translate }}</strong>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="errorMessage">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.UNIDEPTH_READIND_PLACEHOLDER' | translate }}"
                                id="unidepthtoaverageInput"
                                name="monitorSetting.unidepthtoaverage"
                                [(ngModel)]="monitorSetting.unidepthtoaverage"
                                #unidepthtoaverageInput="ngModel"
                                required
                                [pattern]="numeric1_24Pattern"
                            />
                        </mat-form-field>
                        <div class="errorMsgText" *ngIf="unidepthtoaverageInput?.errors?.pattern">
                            {{ 'HOME.MAP.MONITOR_SETTINGS.UNIDEPTH_AVERAGE_VALIDATION' | translate }}
                        </div>
                    </div>
                    <strong>{{ 'COMMON.ALARAMS_TEXT' | translate }}</strong>

                    <table class="echoTable">
                        <tr>
                            <td>
                                <div class="errorMessage">
                                    <mat-checkbox
                                        name="monFitorSetting.lowlevelenable"
                                        [(ngModel)]="monitorSetting.lowlevelenable"
                                    >
                                        {{ 'HOME.MAP.LOW_LEVEL_CHECKBOX' | translate }}
                                    </mat-checkbox>
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder="{{ 'HOME.MAP.THRESHOLD_TEXT' | translate }}"
                                            id="lowlevelthresholdInput"
                                            name="monitorSetting.lowlevelthreshold"
                                            [(ngModel)]="monitorSetting.lowlevelthreshold"
                                            #lowlevelthresholdInput="ngModel"
                                            maxlength="100"
                                            [pattern]="numericDecimal2PlacePattern"
                                        />
                                    </mat-form-field>
                                    <div class="errorMsgText" *ngIf="lowlevelthresholdInput?.errors?.pattern">
                                        {{ 'HOME.MAP.MONITOR_SETTINGS.MANHOLE_DEPTH_VALIDATION' | translate }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <mat-checkbox
                                        name="monitorSetting.fullpipeenable"
                                        [(ngModel)]="monitorSetting.fullpipeenable"
                                    >
                                        {{ 'HOME.MAP.MONITOR_SETTINGS.FULL_PIPE_CHECKBOX' | translate }}
                                    </mat-checkbox>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="errorMessage">
                                    <mat-checkbox
                                        name="monitorSetting.highlevelenable"
                                        [(ngModel)]="monitorSetting.highlevelenable"
                                    >
                                        {{ 'HOME.MAP.HIGH_LEVEL_CHECKBOX' | translate }}
                                    </mat-checkbox>
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder="Threshold (in)"
                                            id="highlevelthresholdInput"
                                            name="monitorSetting.highlevelthreshold"
                                            [(ngModel)]="monitorSetting.highlevelthreshold"
                                            #highlevelthresholdInput="ngModel"
                                            maxlength="100"
                                            [pattern]="numericDecimal2PlacePattern"
                                        />
                                    </mat-form-field>
                                    <div class="errorMsgText" *ngIf="highlevelthresholdInput?.errors?.pattern">
                                        {{ 'HOME.MAP.MONITOR_SETTINGS.MANHOLE_DEPTH_VALIDATION' | translate }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="errorMessage">
                                    <mat-checkbox
                                        name="monitorSetting.highhighenable"
                                        [(ngModel)]="monitorSetting.highhighenable"
                                    >
                                        {{ 'HOME.MAP.HIGH_HIGH_CHECKBOX' | translate }}
                                    </mat-checkbox>
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder="{{ 'HOME.MAP.THRESHOLD_TEXT' | translate }}"
                                            id="highhighthresholdInput"
                                            name="monitorSetting.highhighthreshold"
                                            [(ngModel)]="monitorSetting.highhighthreshold"
                                            #highhighthresholdInput="ngModel"
                                            maxlength="100"
                                            [pattern]="numericDecimal2PlacePattern"
                                        />
                                    </mat-form-field>
                                    <div class="errorMsgText" *ngIf="highhighthresholdInput?.errors?.pattern">
                                        {{ 'HOME.MAP.MONITOR_SETTINGS.MANHOLE_DEPTH_VALIDATION' | translate }}
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>
                                    <mat-checkbox
                                        name="monitorSetting.overflowenable"
                                        [(ngModel)]="monitorSetting.overflowenable"
                                    >
                                        {{ 'HOME.MAP.OVERFLOW_CHECKBOX' | translate }}
                                    </mat-checkbox>
                                </div>
                            </td>
                            <td>
                                <div class="errorMessage">
                                    <mat-checkbox
                                        name="monitorSetting.tiltenable"
                                        [(ngModel)]="monitorSetting.tiltenable"
                                    >
                                        {{ 'HOME.MAP.TILT_CHECKBOX' | translate }} (&#176;)
                                    </mat-checkbox>
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder="Threshold (&#176;)"
                                            id="tiltthresholdInput"
                                            name="monitorSetting.tiltthreshold"
                                            [(ngModel)]="monitorSetting.tiltthreshold"
                                            #tiltthresholdInput="ngModel"
                                            maxlength="100"
                                            [pattern]="numericDecimal2PlacePattern"
                                        />
                                    </mat-form-field>
                                    <div class="errorMsgText" *ngIf="tiltthresholdInput?.errors?.pattern">
                                        {{ 'HOME.MAP.MONITOR_SETTINGS.MANHOLE_DEPTH_VALIDATION' | translate }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="errorMessage">
                                    <mat-checkbox
                                        name="monitorSetting.batterylowenable"
                                        [(ngModel)]="monitorSetting.batterylowenable"
                                        (change)="setBatteryLow($event)"
                                    >
                                        {{ 'HOME.MAP.BATTERY_LOW_CHECKBOX' | translate }}
                                    </mat-checkbox>
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.THRESHOLD' | translate }} {{
                                                'COMMON.VOLTAGE_ABBREVIATION' | translate
                                            }}"
                                            id="batterylowthresholdInput"
                                            name="monitorSetting.batterylowthreshold"
                                            [(ngModel)]="monitorSetting.batterylowthreshold"
                                            #batterylowthresholdInput="ngModel"
                                            maxlength="100"
                                            [pattern]="numericDecimal2PlacePattern"
                                        />
                                    </mat-form-field>
                                    <div class="errorMsgText" *ngIf="batterylowthresholdInput?.errors?.pattern">
                                        {{ 'HOME.MAP.MONITOR_SETTINGS.MANHOLE_DEPTH_VALIDATION' | translate }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <strong>{{ 'HOME.MAP.MONITOR_SETTINGS.ECHO_DATA_DELIVERY_TEXT' | translate }}</strong>
                    <div class="custom-33p margin-t-15">
                        <div class="each-Item errorMessage">
                            <mat-form-field class="full-width">
                                <input
                                    matTooltip="{{ 'HOME.MAP.IP_ADDRESS_TOOLTIP' | translate }}"
                                    matInput
                                    id="echoMonitorSettingIpaddressInput"
                                    placeholder="{{ 'COMMON.PRISM' | translate }} {{
                                        'COMMON.IP_ADDRESS_PLACEHOLDER' | translate
                                    }}"
                                    name="monitorSetting.datadeliveryipaddress"
                                    [(ngModel)]="monitorSetting.datadeliveryipaddress"
                                    #echoMonitorSettingIpaddressInput="ngModel"
                                    [pattern]="ipaddressPattern"
                                    maxlength="100"
                                />
                            </mat-form-field>
                            <div class="errorMsgText" *ngIf="echoMonitorSettingIpaddressInput?.errors?.pattern">
                                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.IP_ADDRESS_ERROR' | translate }}
                            </div>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.NORMAL_RATE_TEXT' | translate }}"
                                    name="monitorSetting.datadeliverynormal"
                                    [(ngModel)]="monitorSetting.datadeliverynormal"
                                    class="full-width"
                                >
                                    <mat-option [value]="0">{{ 'COMMON.OFF_TEXT' | translate }}</mat-option>
                                    <mat-option
                                        *ngFor="let Rates of monitorSetting.echoNormalRate; trackBy: trackByIndex"
                                        [value]="Rates.value"
                                        >{{ Rates.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'HOME.MAP.MONITOR_SETTINGS.FAST_RATE_TEXT' | translate }}"
                                    name="monitorSetting.datadeliveryfast"
                                    [(ngModel)]="monitorSetting.datadeliveryfast"
                                    class="full-width"
                                >
                                    <mat-option [value]="0">{{ 'COMMON.OFF_TEXT' | translate }}</mat-option>
                                    <mat-option
                                        *ngFor="let Rates of monitorSetting.echoDataFastRate; trackBy: trackByIndex"
                                        [value]="Rates.value"
                                        >{{ Rates.text }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>

            <div mat-dialog-actions>
                <app-loader [isLoading]="activateLocationLoadingState"></app-loader>
                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="emitMonitorSettings()">{{ 'COMMON.CLOSE' | translate }}</button>
                </div>
                <div class="auto-margin">
                    <button
                        mat-button
                        [disabled]="
                            !echoMoniterForm.valid ||
                            echoMoniterForm.pristine ||
                            monitorSetting.unidepthtoaverage <= 0 ||
                            monitorSetting.unidepthtoaverage > 24
                        "
                        (click)="echoMonitorConfiguration()"
                    >
                        <img class="img-icon-24" [ngSrc]="'/assets/images/activate.png'" height="24" width="24" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
