<div class="add-graph" id="customEntityDialog">
    <app-loader [isLoading]="isLoading"></app-loader>
    <!-- add graph header -->
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'DASHBOARDS.MULTI_LOCATION_DASHBOARD.ADD_GRAPH' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->
    <div mat-dialog-content [ngClass]="{ 'hint-dialog-background': showpageHint }">
        <h4>{{'DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_TYPE' | translate}}</h4>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="graphConfiguration.graphType"
            [disabled]="showpageHint" name="type">
            <mat-radio-button name="graphRadioGroup" [ngClass]="{'cd-hg-hint': showpageHint && graphConfiguration.graphType == 0}" value="0" class="select-graph" (click)="showHydroGraph($event)">
                {{'COMMON.HYDROGRAPH_TEXT'| translate}} </mat-radio-button>
            <mat-radio-button name="graphRadioGroup" [ngClass]="{'cd-sg-hint': showpageHint && graphConfiguration.graphType == 1}" value="1" class="select-graph" (click)="showScatterGraph($event)">
                {{'COMMON.SCATTERGRAPH_TEXT'| translate}} </mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="full-width top">
            <input
                matInput
                [(ngModel)]="graphConfiguration.name"
                [disabled]="showpageHint"
                [ngClass]="{'cd-dialog-name-hint': showpageHint}"
                type="name"
                name="graphName"
                (change)="markAsDirty()"
                placeholder="{{ 'DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_NAME' | translate }}"
                required
            />
        </mat-form-field>
        <div class="date-range-container">
            <app-date-range-picker
                class="full-width"
                [buttonClasses]="['full-width', 'datepicker-escape-margin']"
                [defaultTimeSpan]="QUICK_DATE_RANGES.PREVIOUS_MONTH"
                [startDate]="startDate"
                [endDate]="endDate"
                [disabled]="showpageHint"
                [ngClass]="{'cd-dialog-date-hint': showpageHint}"
                (startDateChange)="markAsDirty(); selectedStartDate($event)"
                (endDateChange)="markAsDirty(); selectedEndDate($event)"
                [(disableInputs)]="isRollingDate"
            ></app-date-range-picker>
            <div class="date-range-type-container">
                <p
                    class="date-range-label"
                    matTooltip="{{ 'COMMON.STATIC_TOOLTIP' | translate }}"
                    (click)="showpageHint ? $event.preventDefault() : (onDateTypeChange(false))"
                >
                    Static
                </p>
                <mat-slide-toggle [(ngModel)]="isRollingDate" (ngModelChange)="markAsDirty()" class="date-range-toggle"
                    [disabled]="showpageHint" name="rolling" [ngClass]="{'cd-dialog-rolling-hint': showpageHint}"></mat-slide-toggle>
                <p
                    class="date-range-label"
                    matTooltip="{{ 'COMMON.ROLLING_TOOLTIP' | translate }}"
                    (click)="showpageHint ? $event.preventDefault() : (onDateTypeChange(true))"
                >
                    Rolling
                </p>
            </div>
        </div>

        <!-- series location and entity -->
        <h4>{{ 'DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_SERIES' | translate }}</h4>
        <!-- hydrograph -->
        <div *ngIf="hydrograph" class="entity-container margin-b-30">
            <!-- first row -->
            <div class="composite-list" *ngFor="let item of (showpageHint ? hintSelectedItems : selectedItems); let i = index; trackBy: trackByIndex">
                <div class="each-hydroph-item pad-l-15">
                    <mat-form-field class="full-width" [ngClass]="{'cd-dialog-location-hint': showpageHint}">
                        <mat-icon class="locationSearch" matPrefix>search</mat-icon>
                        <input
                            type="text"
                            placeholder="{{ 'CALCULATED_ENTITY_DIALOG.LOCATION_VALUE_PLACEHOLDER' | translate }}"
                            matInput
                            [(ngModel)]="showpageHint ? null : autocompleteData[i].text"
                            (ngModelChange)="filterLocations(i, autocompleteData[i])"
                            [matAutocomplete]="matLocationSearchHG"
                            name="locationsHG"
                            [disabled]="showpageHint"
                        />
                        <mat-autocomplete
                            #matLocationSearchHG="matAutocomplete"
                            (optionSelected)="locationChoosed(i, $event.option.value)"
                            [displayWith]="locationSearchDisplay"
                        >
                            <mat-option
                                *ngFor="let option of (showpageHint ? [] : autocompleteData[i].locations); trackBy: trackByIndex"
                                [value]="option"
                                [class.inactive-location]="!option.isActiveLocation"
                            >
                                {{ option.locationName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="each-hydroph-item" [ngClass]="{'cd-dialog-entity-hint': showpageHint}">
                    <app-entity-selector
                        *ngIf="showpageHint || (entitiesByLocation[selectedItems[i].lid] && ansrEntitiesByLocation[selectedItems[i].lid])"
                        [single]="true"
                        [disabled]="showpageHint"
                        [availableEntities]="showpageHint ? [] : entitiesByLocation[selectedItems[i].lid]"
                        [availableANSREntities]="showpageHint ? [] : ansrEntitiesByLocation[selectedItems[i].lid]"
                        [selectedEntitiyIds]="showpageHint ? [] : [selectedItems[i].eid]"
                        (selectedEntities)="selectedGroupEntity($event, item)"
                    ></app-entity-selector>
                    <div class="mat-error" *ngIf="!showpageHint && invalidEntitySelected && selectedItems[i].eid === -1">
                        <span>{{ 'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE' | translate }}</span>
                    </div>
                </div>
                <div class="each-hydroph-item auto-margin-t-b" [ngClass]="{'cd-dialog-color-hint': showpageHint}">
                    {{ 'DASHBOARDS.MULTI_LOCATION_DASHBOARD.SELECT_COLOR' | translate }}
                    <mcc-color-picker
                        usedSizeColors="7"
                        (selected)="selectedColorPicker($event, item)"
                        [selectedColor]="showpageHint ? '' : selectedItems[i].color"
                        (change)="markAsDirty(); enableAddMore()"
                    ></mcc-color-picker>
                </div>
                <div class="auto-margin-t-b">
                    <button type="button" mat-button class="lightBackGround" (click)="markAsDirty(); removeRow(i)" [ngClass]="{'cd-dialog-delete-hint': showpageHint}">
                        <mat-icon class="mat-24">delete</mat-icon>
                    </button>
                </div>
            </div>
            <div class="composite-list top-border height-50">
                <div class="app-flex-filler"></div>
                <div class="auto-margin custom-flat-button">
                    <button mat-button class="add-button" (click)="addMoreRow()" [disabled]="isRowFilled" [ngClass]="{'cd-dialog-add-more-hint': showpageHint}">
                        {{ 'CALCULATED_ENTITY_DIALOG.ADDMORE_BUTTON' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <!-- Scattergraph -->
        <div *ngIf="scattergraph" class="entity-container margin-b-30">
            <!-- first row -->
            <div class="composite-list" *ngFor="let item of (showpageHint ? hintSelectedItems : selectedItems); let i = index; trackBy: trackByIndex">
                <div class="each-hydroph-item pad-l-15">
                    <mat-form-field class="full-width" [ngClass]="{'cd-dialog-location-hint': showpageHint}">
                        <mat-icon class="locationSearch" matPrefix>search</mat-icon>
                        <input
                            type="text"
                            placeholder="{{'CALCULATED_ENTITY_DIALOG.LOCATION_VALUE_PLACEHOLDER' | translate}}" matInput
                            [(ngModel)]="showpageHint ? null : autocompleteData[i].text"
                            (ngModelChange)='filterLocations(i, autocompleteData[i])'
                            [matAutocomplete]="matLocationSearchSG"
                            name="locationsSG"
                            [disabled]="showpageHint"
                            >
                        <mat-autocomplete #matLocationSearchSG="matAutocomplete"
                            (optionSelected)='locationChoosed(i, $event.option.value)'
                            [displayWith]="locationSearchDisplay">
                            <mat-option *ngFor="let option of (showpageHint ? [] : autocompleteData[i].locations); trackBy: trackByIndex" [value]="option"
                                [class.inactive-location]="!option.isActiveLocation">
                                {{option.locationName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="each-hydroph-item" [ngClass]="{'cd-dialog-y-entity-hint': showpageHint}">
                    <app-entity-selector
                        *ngIf="showpageHint || (entitiesByLocation[selectedItems[i].lid] && ansrEntitiesByLocation[selectedItems[i].lid])"
                        [single]="true"
                        [disabled]="showpageHint"
                        staticPlaceholder="Y Entity"
                        [availableEntities]="showpageHint ? [] : entitiesByLocation[selectedItems[i].lid]"
                        [availableANSREntities]="showpageHint ? [] : ansrEntitiesByLocation[selectedItems[i].lid]"
                        [selectedEntitiyIds]="showpageHint ? [] : [selectedItems[i].eidY]"
                        (selectedEntities)="selectedGroupEntityScatterY($event, item)"
                    ></app-entity-selector>
                    <div class="mat-error" *ngIf="!showpageHint && invalidEntitySelected && (selectedItems[i].eidY === -1)">
                        <span>{{'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE' | translate}}</span>
                    </div>
                </div>
                <div class="each-hydroph-item" [ngClass]="{'cd-dialog-x-entity-hint': showpageHint}">
                    <app-entity-selector
                        *ngIf="showpageHint || (entitiesByLocationClone[selectedItems[i].lid] && ansrEntitiesByLocationClone[selectedItems[i].lid])"
                        [single]="true"
                        staticPlaceholder="X Entity"
                        [disabled]="showpageHint"
                        [availableEntities]="showpageHint ? [] : entitiesByLocationClone[selectedItems[i].lid]"
                        [availableANSREntities]="showpageHint ? [] : ansrEntitiesByLocationClone[selectedItems[i].lid]"
                        [selectedEntitiyIds]="showpageHint ? [] : [selectedItems[i].eidX]"
                        (selectedEntities)="selectedGroupEntityScatterX($event, item)"
                    ></app-entity-selector>
                    <div class="mat-error" *ngIf="!showpageHint && invalidEntitySelected && (selectedItems[i].eidX === -1)">
                        <span>{{'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE' | translate}}</span>
                    </div>
                    <div class="mat-error" *ngIf="!showpageHint && selectedItems[i] && selectedItems[i].lid !== -1
                        && selectedItems[i].eidX && (selectedItems[i].eidX === selectedItems[i].eidY)">
                        <span>{{'CALCULATED_ENTITY_DIALOG.SAME_ENTITIES_SELECTED_ERROR_MESSAGE' | translate}}</span>
                    </div>

                </div>
                <div class="each-hydroph-item auto-margin-t-b " [ngClass]="{'cd-dialog-color-hint': showpageHint}">
                    {{'DASHBOARDS.MULTI_LOCATION_DASHBOARD.SELECT_COLOR' | translate}}
                    <mcc-color-picker usedSizeColors=7 (selected)="selectedColorPicker($event, item)"
                        [selectedColor]="showpageHint ? '' : selectedItems[i].color" (change)="markAsDirty(); enableAddMore()"></mcc-color-picker>
                </div>
                <div class="auto-margin-t-b ">
                    <button type="button" mat-button class="lightBackGround" (click)="markAsDirty();  removeRow(i)" [ngClass]="{'cd-dialog-delete-hint': showpageHint}">
                        <mat-icon class="mat-24">delete</mat-icon>
                    </button>
                </div>
            </div>
            <div class="composite-list top-border height-50">
                <div class="app-flex-filler"></div>
                <div class="auto-margin custom-flat-button">
                    <button mat-button class="add-button" (click)="addMoreRow()" [disabled]="isRowFilled" [ngClass]="{'cd-dialog-add-more-hint': showpageHint}">
                        {{'CALCULATED_ENTITY_DIALOG.ADDMORE_BUTTON' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="data-averaging-options">
            <mat-form-field class="full-width" [ngClass]="{'cd-dialog-data-averag-hint': showpageHint}">
                <mat-select
                    placeholder="{{ 'FLOW_BALANCE.DATA_AVERAGING' | translate }}"
                    [(ngModel)]="selectedDataAveraging"
                    (ngModelChange)="markAsDirty()"
                    name="dataAveraging"
                >
                    <mat-option *ngFor="let each of dataAveragingList; trackBy: trackByIndex" [value]="each.id"
                        >{{ each.text | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- </form> -->
    <!--Till here-->
    <!-- add graph action -->
    <div mat-dialog-actions [ngClass]="{ 'hint-dialog-background': showpageHint }">
        <div class="app-flex-filler"></div>
        <span class="mat-error" *ngIf="isRowFilled && !showpageHint">{{ 'LOCATION_DASHBOARD.COLOR_NEED_SELECTED' | translate }}</span>
        <div class="auto-margin">
            <button mat-button (click)="close()" [ngClass]="{'cd-dialog-close-hint': showpageHint}">{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button (click)="saveGraph()" [disabled]="!isDirty || isSameSGentitiesSelected || !isMandatoryFilled || !scatterEntitiesDifferent()"
                [ngClass]="{'cd-dialog-save-hint': showpageHint}">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
