import {
    ChangeDetectionStrategy,
    Component,
    ChangeDetectorRef,
    ElementRef,
    Inject,
    OnDestroy,
    ViewEncapsulation,
    OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Subscription } from 'rxjs';

import { VaultService } from '../vault.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { VaultLinkShare, VaultShareDialogData } from 'app/shared/models/vault';
import { TrackBy } from 'app/shared/utils/track-by';

/**
 * Represents the vault share dialog window.
 */
@Component({
    selector: 'app-vault-item-share',
    templateUrl: './vault-item-share.component.html',
    styleUrls: ['./vault-item-share.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultItemShareComponent implements OnInit, OnDestroy {
    /**
     * Represents the uri for the file item.
     */
    public uri: string;

    /**
     * Represents the file that the uri is based on.
     */
    public file: VaultShareDialogData;

    /**
     * Indicates the loading state of the component.
     */
    public isLoading: boolean;

    /**
     * Represents a value that indicates whether a copy operation was performed.
     */
    public isValueCopied: boolean;

    /**
     * Represents the subscriptions clean-up object.
     */
    private subscriptions = new Array<Subscription>();

    public timePeriods?: Array<{ value: number; text: string; }>;
    public hourText: string;
    public hoursText: string;

    public selectedPeriod: number;

    public trackByValue = TrackBy.byValue();

    constructor(
        @Inject(DOCUMENT) private angularDocument: Document,
        @Inject(MAT_DIALOG_DATA) private data: VaultShareDialogData,
        private dialogRef: MatDialogRef<VaultItemShareComponent>,
        private vaultService: VaultService,
        private elements: ElementRef,
        private changeDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
    ) {
        this.file = data;
        translate.get('VAULT.VAULT_ITEM_SHARE.HOUR_TEXT').subscribe((res: string) => {
            this.hourText = res;
        });
        translate.get('VAULT.VAULT_ITEM_SHARE.HOURS_TEXT').subscribe((res: string) => {
            this.hoursText = res;
        });
    }

    /**
     * Framework level licecycle hook.
     */
    public ngOnInit() {
        this.timePeriods = [
            { value: 1, text: '1 ' + this.hourText },
            { value: 12, text: '12 ' + this.hoursText },
            { value: 24, text: '24 ' + this.hoursText },
        ];
        this.selectedPeriod = 1;
        this.shareTimePeriodChange({ value: 1 });
    }
    /**
     * Framework level licecycle hook.
     */
    public ngOnDestroy(): void {
        this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
    }

    public copyShareLink(event: MouseEvent): void {
        this.copyToClipboard('.share-link');
    }

    public copyToClipboard(el): void {
        // resolve the element
        el = typeof el === 'string' ? document.querySelector(el) : el;

        // handle iOS as a special case
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            // save current contentEditable/readOnly status
            const editable = el.contentEditable;
            const readOnly = el.readOnly;

            // convert to editable with readonly to stop iOS keyboard opening
            el.contentEditable = true;
            el.readOnly = true;

            // create a selectable range
            const range = document.createRange();
            range.selectNodeContents(el);

            // select the range
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            el.setSelectionRange(0, 999999);

            // restore contentEditable/readOnly to original state
            el.contentEditable = editable;
            el.readOnly = readOnly;
        } else {
            el.select();
        }

        // execute copy command
        this.isValueCopied = document.execCommand('copy');
    }

    /**
     * Responds to the share time period selection for the file and
     * fetches the share link for that file with the associated time
     * frame allowance.
     * @param event Represents the time period change event.
     */
    public shareTimePeriodChange(event): void {
        // set loading state
        this.isLoading = true;

        // reset copied state
        this.isValueCopied = false;

        // query vault service for share link
        const vaultLinkSubscription = this.vaultService.getSharedLink(event.value, this.file.id).subscribe(
            (share: VaultLinkShare) => {
                // set share link
                this.uri = share.link;

                // set loading state to false
                this.isLoading = false;

                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            () => {
                this.isLoading = false;
            },
        );

        this.subscriptions.push(vaultLinkSubscription);
    }
}
