import { EventsKey } from 'ol/events';
import OLMap from 'ol/Map';
import { LAYER_ADDED_EVENT_NAME, SOURCE_CHANGE_EVENT_NAME } from './map-type';
import TileLayer from 'ol/layer/Tile';
import { TileJSON } from 'ol/source';

export class MapView {
    public id: number;
    public name: string;
    public longitude: number;
    public latitude: number;
    constructor(data: MapView) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.longitude = data.longitude;
            this.latitude = data.latitude;
        }
    }
}

export interface IStormYearsColor {
    color: string;
    textColor: string;
    translationKey: string;
    rtype: FrequencyType
}

export interface MapRain {
    lid: number;
    res: number;
    runit?: string;
    rtype?: number;
    /** Whenever show graph. Threat as TRUE by default (when value=undefined | null) */
    showBar?: boolean;
    /** Whenever show empty values. Threat as TRUE by default (when value=undefined | null). */
    showEmpty?: boolean;
}

export const MapRainColors: {
    color: string;
    textColor: string;
    value: number;
    range: Array<number>
}[] = [
    {color: '#ffffff', textColor: '#000000', value: 8, range: [8, Number.MAX_VALUE]},
    {color: '#9854bd', textColor: '#ffffff', value: 6, range: [6, 8]},
    {color: '#f800fd', textColor: '#ffffff', value: 4, range: [4, 6]},
    {color: '#bc0000', textColor: '#ffffff', value: 3, range: [3, 4]},
    {color: '#d40000', textColor: '#ffffff', value: 2.5, range: [2.5, 3]},
    {color: '#d40000', textColor: '#ffffff', value: 1.7, range: [1.7, 2.5]},
    {color: '#e5bc00', textColor: '#000000', value: 1.5, range: [1.5, 1.7]},
    {color: '#fdf802', textColor: '#000000', value: 1.2, range: [1.2, 1.5]},
    {color: '#008e00', textColor: '#ffffff', value: 1, range: [1, 1.2]},
    {color: '#01c501', textColor: '#000000', value: 0.75, range: [0.75, 1]},
    {color: '#02fd02', textColor: '#000000', value: 0.5, range: [0.5, 0.75]},
    {color: '#0300f4', textColor: '#ffffff', value: 0.25, range: [0.25, 0.5]},
    {color: '#019ff4', textColor: '#ffffff', value: 0.1, range: [0.1, 0.25]},
    {color: '#646464', textColor: '#ffffff', value: 0, range: [Number.MIN_VALUE, 0.1]}
];

export enum FrequencyType {
    LessThenOne = 0,
    OneToTwo,
    TwoToFive,
    FiveToTen,
    TenToTwentyFive,
    TwentyFiveToFifty,
    FiftyToHundred,
    OneHundredToTwoHundred,
    TwoHundredToFiveHundred,
    FiveHundredToThousand,
    GreaterThenThousand
}

export const StormYearsColors: IStormYearsColor[] = [
    {color: '#ffffff', textColor: '#000000', translationKey: 'LessThenOne', rtype: FrequencyType.LessThenOne},
    {color: '#DAEED8', textColor: '#000000', translationKey: 'OneToTwo', rtype: FrequencyType.OneToTwo},
    {color: '#45A065', textColor: '#000000', translationKey: 'TwoToFive', rtype: FrequencyType.TwoToFive},
    {color: '#09662B', textColor: '#ffffff', translationKey: 'FiveToTen', rtype: FrequencyType.FiveToTen},
    {color: '#FFF987', textColor: '#000000', translationKey: 'TenToTwentyFive', rtype: FrequencyType.TenToTwentyFive},
    {color: '#F9CB52', textColor: '#000000', translationKey: 'TwentyFiveToFifty', rtype: FrequencyType.TwentyFiveToFifty},
    {color: '#FD8C34', textColor: '#000000', translationKey: 'FiftyToHundred', rtype: FrequencyType.FiftyToHundred},
    {color: '#FB4F2E', textColor: '#000000', translationKey: 'OneHundredToTwoHundred', rtype: FrequencyType.OneHundredToTwoHundred},
    {color: '#A40D0D', textColor: '#ffffff', translationKey: 'TwoHundredToFiveHundred', rtype: FrequencyType.TwoHundredToFiveHundred},
    {color: '#4D0072', textColor: '#ffffff', translationKey: 'FiveHundredToThousand', rtype: FrequencyType.FiveHundredToThousand},
    {color: '#FDDDF9', textColor: '#000000', translationKey: 'GreaterThenThousand', rtype: FrequencyType.GreaterThenThousand}
];


const MAX_SUPPORTED_TILE_ZOOM = 16;
export function TileLayerSourceLoadRestriction(map: OLMap): EventsKey[] {
    const subscriptions: EventsKey[] = [];

    subscriptions.push(map.getLayers().on(LAYER_ADDED_EVENT_NAME, (event) => {
        const addedLayer = event.element as TileLayer;
        
        if (addedLayer.get('name') === undefined && addedLayer instanceof TileLayer) {                
            subscriptions.push(addedLayer.on(SOURCE_CHANGE_EVENT_NAME, (event) => {
                const source: TileJSON = addedLayer.getSource() as TileJSON;

                if (source) {
                    const originalUrlFunction = source.getTileUrlFunction();
                    source.setTileUrlFunction((tileCoord, pixelRatio, projection) => {
                        if (tileCoord[0] > MAX_SUPPORTED_TILE_ZOOM) {
                            return undefined; // This prevents the request
                        }

                        return originalUrlFunction(tileCoord, pixelRatio, projection);
                    });
                }
            }));
        }
    }));

    return subscriptions;
}