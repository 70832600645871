<div id="vault-location-export-dialog">
    <div class="popupHeader" mat-dialog-title>
        <span [ngClass]="{'opacity-on-hint': showpageHint}">{{ 'COMMON.EXPORT_LOCATION' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content [ngClass]="{ 'disable-field': showSaveSetting, 'hint-dialog-background': showpageHint }">
        <!-- dialog content -->
        <div class="custom-50p-group flex-container">
            <div class="each-containt">
                <mat-form-field class="full-width" [ngClass]="{'vault-dialog-type-hint': showpageHint}">
                    <mat-select
                        [disabled]="showpageHint"
                        placeholder="{{ 'COMMON.SELECT_TYPE' | translate }}"
                        [(ngModel)]="vaultExport.locationType"
                        name="locationType"
                    >
                        <mat-option *ngFor="let option of locationTypeArray; trackBy: trackById" [value]="option.id" required>
                            {{ option.value }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="each-containt includeLocations" [ngClass]="{'vault-dialog-inactive-hint': showpageHint}" *ngIf="vaultExport.locationType == 0 || showpageHint">
                <mat-slide-toggle [disabled]="showpageHint" [(ngModel)]="includeInactiveLocations" (change)="loadLocations(true)"> </mat-slide-toggle>
                <span>Include Inactive Locations</span>
            </div>
        </div>
        <div class="top custom-error" *ngIf="vaultExport.locationType == 0 || showpageHint" [ngClass]="{'vault-dialog-locations-hint': showpageHint}">
            <app-multi-select-auto-complete
                #multiSelectAutoCompleteLocations
                [items]="filteredLocations"
                [preselectedItems]="preSelectedLocation"
                [isShowAll]="true"
                [disabled]="showpageHint"
                (currentlySelectedItems)="handleSelectedLocations($event)"
                placeholder="{{ 'COMMON.LOCATIONS' | translate }} *"
            ></app-multi-select-auto-complete>
            <div class="custom-error-message" *ngIf="locationsInvalid()">
                <span>{{ 'COMMON.LOCATIONS_VALIDATION_MESSSAGE' | translate }}</span>
            </div>
        </div>
        <div class="custom-error" *ngIf="vaultExport.locationType == 1" [ngClass]="{'vault-dialog-locations-hint': showpageHint}">
            <app-auto-complete
                [items]="locationGroups"
                [isShowAll]="false"
                [disabled]="showpageHint"
                [preselectedItem]="preSelectedLocationGroup"
                (selectedItems)="handleSelectedLocationsGroup($event)"
                placeholder="{{ 'COMMON.SELECT_LOCATION_GROUP' | translate }}"
            >
            </app-auto-complete>
            <div class="custom-error-message" *ngIf="isLocationGroupInvalid">
                <span>{{ 'COMMON.LOCATION_GROUP_VALIDATION_MESSSAGE' | translate }}</span>
            </div>
        </div>
        <app-loader [isLoading]="isLoading"></app-loader>

        <div class="custom-50p-group flex-container">
            <div class="each-containt custom-error" [ngClass]="{'vault-dialog-entity-hint': showpageHint}">
              <ng-container *ngIf="showpageHint">
                <app-entity-selector
                    [disabled]="showpageHint"
                    [selectedEntitiyIds]="[]"
                    [availableEntities]="[]"
                ></app-entity-selector>
              </ng-container>
              <ng-container *ngIf="!showpageHint">
                <app-entity-selector
                    *ngIf="entitySelectorObjects$ | async as entitySelectorObjects"
                    [selectedEntitiyIds]="selectedEntityIds"
                    [availableEntities]="entitySelectorObjects"
                    [availableANSREntities]="entitySelectorANSRObjects$ | async"
                    (selectedEntities)="handleSelectedEntities($event)"
                    (selectedEntitiesList)="selectedEntitySet = $event"
                ></app-entity-selector>
              </ng-container>
                <div class="custom-error-message left-15" *ngIf="isEntitiesInvalid">
                    <span>{{ 'COMMON.ENTITY_VALIDATION_MESSSAGE' | translate }}</span>
                </div>
            </div>

            <div class="each-containt vault-location-export-date" [ngClass]="{'vault-dialog-date-hint': showpageHint}">
                <app-date-range-picker
                    [startDate]="preStartDate"
                    [disabled]="showpageHint"
                    [endDate]="preEndDate"
                    [dateRangeType]="'date'"
                    (startDateChange)="selectedStartDate($event)"
                    (endDateChange)="selectedEndDate($event)"
                    (isValidDateRange)="validDateRange($event)"
                ></app-date-range-picker>
            </div>
            <div class="each-containt margin-b-15" [ngClass]="{'vault-dialog-timestamp-hint': showpageHint}">
                <div class="timespan-space">{{ 'FLOW_BALANCE.TIMESTAMPS' | translate }}</div>
                <mat-radio-group
                    [disabled]="showpageHint"
                    name="timeStampType"
                    [(ngModel)]="vaultExport.timeStampType"
                    aria-label="Select an option"
                >
                    <mat-radio-button value="omit">{{ 'FLOW_BALANCE.OMIT' | translate }}</mat-radio-button>
                    <mat-radio-button value="blank">{{ 'FLOW_BALANCE.BLANK' | translate }}</mat-radio-button>
                    <mat-radio-button value="custom">{{ 'FLOW_BALANCE.CUSTOM_VALUE' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="each-containt" [ngClass]="{'vault-dialog-value-hint': showpageHint}">
                <mat-form-field class="full-width" *ngIf="vaultExport.timeStampType == 'custom' || showpageHint">
                    <input
                        matInput
                        name="missingTimestampValue"
                        [(ngModel)]="vaultExport.missingTimestampValue"
                        (keydown)="restrictKeys($event)"
                        [disabled]="showpageHint"
                        (ngModelChange)="missingTimestamp(vaultExport.missingTimestampValue)"
                        placeholder="{{ 'LOCATION_DASHBOARD.DATA_EDITING.ENTER_VALUE_PLACEHOLDER' | translate }}"
                    />
                </mat-form-field>
            </div>
            <div class="each-containt" [ngClass]="{'vault-dialog-rounding-hint': showpageHint}">
                <mat-slide-toggle
                    class="pad-b-15 pad-t-15"
                    name="isRoundingEnabled"
                    [disabled]="showpageHint"
                    checked
                    [(ngModel)]="vaultExport.isRoundingEnabled"
                    >{{ 'COMMON.ROUNDING' | translate }}</mat-slide-toggle
                >
            </div>
            <div class="each-containt"></div>
            <div class="each-containt" [ngClass]="{'vault-dialog-file-type-hint': showpageHint}">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'COMMON.FILE_TYPE' | translate }}"
                        [(ngModel)]="vaultExport.fileType"
                        name="selectedType"
                        [disabled]="showpageHint"
                        (ngModelChange)="changeMultiple()"
                    >
                        <mat-option *ngFor="let file of fileTypeArray; trackBy: trackById" [value]="file.id"> {{ file.value }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="each-containt" [ngClass]="{'vault-dialog-multiple-hint': showpageHint}">
                <mat-form-field class="full-width">
                    <mat-select
                        [disabled]="showpageHint"
                        placeholder="{{ 'FLOW_BALANCE.SINGLE/MULTIPLE' | translate }}"
                        name="selectedMutiple"
                        [(ngModel)]="vaultExport.singleOrMultiple"
                        (ngModelChange)="changeDataToBeExported()"
                    >
                        <mat-option *ngFor="let file of csvFileArray; trackBy: trackById" [value]="file.id"> {{ file.value }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="each-containt" *ngIf="vaultExport.fileType === 0" [ngClass]="{'vault-dialog-entity-col-hint': showpageHint}">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'FLOW_BALANCE.ENTITY_PER_ROW_COLUMN' | translate }}"
                        name="selectedColumn"
                        [disabled]="showpageHint"
                        [(ngModel)]="vaultExport.entityRowColumn"
                    >
                        <mat-option *ngFor="let file of entityPerColumnRowArray; trackBy: trackById" [value]="file.id">
                            {{ file.value }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="each-containt" *ngIf="vaultExport.fileType !== 0">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'FLOW_BALANCE.DATA_TO_BE_EXPORTED_SHEET' | translate }}"
                        name="selectedColumn"
                        [disabled]="showpageHint"
                        [(ngModel)]="vaultExport.exportedPerSheet"
                        [disabled]="vaultExport.singleOrMultiple === 1"
                    >
                        <mat-option *ngFor="let file of dataToBeexportedPerSheet; trackBy: trackById" [value]="file.id">
                            {{ file.value }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="each-containt" [ngClass]="{'vault-dialog-average-hint': showpageHint}">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'FLOW_BALANCE.DATA_AVERAGING' | translate }}"
                        name="selectedAverage"
                        [disabled]="showpageHint"
                        [(ngModel)]="vaultExport.dataAverage"
                    >
                        <mat-option *ngFor="let each of dataAveraging; trackBy: trackById" [value]="each.id">{{ each.value }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-slide-toggle class="pad-b-15 pad-t-15" [ngClass]="{'vault-dialog-download-hint': showpageHint}" [disabled]="showpageHint" name="isExportDownload" checked [(ngModel)]="isExportDownload">{{
            'COMMON.DOWNLOAD_FILE' | translate
        }}</mat-slide-toggle>
    </div>
    <!-- dialog actions -->
    <div mat-dialog-actions class="content-position" [ngClass]="{'hint-dialog-background': showpageHint}">
        <div class="auto-margin btn-position" *ngIf="!forceApplySettings">
            <button
                mat-button
                class="margin-l-10" [ngClass]="{'vault-dialog-settings-hint': showpageHint}"
                [disabled]="!isValidDateRange || !selectedEntityIds.length || showpageHint"
                (click)="saveSetting()"
                *ngIf="!settingNameReadonly"
            >
                {{ saveSettingText }}
            </button>
            <button
                mat-button
                class="margin-l-10"
                [disabled]="!isValidDateRange || !selectedEntityIds.length || showpageHint"
                (click)="saveExportSetting()"
                *ngIf="settingNameReadonly"
            >
                {{ updateSettingText }}
            </button>
        </div>
        <div *ngIf="showSaveSetting" class="save-setting-dialog">
            <h5 class="text-weight uppercaseTransform">{{ saveSettingText }}</h5>
            <mat-form-field class="full-width pad-t-12">
                <input
                    matInput
                    placeholder="{{ 'COMMON.SETTING_NAME' | translate }}"
                    minlength="1"
                    maxlength="255"
                    [readonly]="settingNameReadonly"
                    name="exportSettingName"
                    [(ngModel)]="exportSettingName"
                    (ngModelChange)="validateSettingName()"
                />
            </mat-form-field>
            <div class="save-setting-footer" *ngIf="!forceApplySettings">
                <div class="app-flex-filler"></div>
                <div class="auto-margin">
                    <button mat-button (click)="closeExportSetting()" [disabled]="showpageHint">{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
                </div>
                <div class="auto-margin">
                    <button mat-button (click)="saveExportSetting()" [disabled]="!validSettingName || showpageHint">
                        {{ 'COMMON.SAVE_BUTTON' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin" [ngClass]="{ 'disable-field': showSaveSetting }">
            <button mat-button (click)="close(false)" [ngClass]="{'vault-dialog-close-hint': showpageHint}" [disabled]="showpageHint">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin" [ngClass]="{ 'disable-field': showSaveSetting }">
            <button
                mat-button
                [ngClass]="{'vault-dialog-export-hint': showpageHint}"
                [disabled]="showpageHint || !isValidDateRange || !(selectedEntityIds.length > 0)"
                (click)="onExportSubmit()"
            >
                {{ 'COMMON.EXPORT_BTN' | translate }}
            </button>
        </div>
    </div>
</div>
