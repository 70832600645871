<div id="editWetDryDialog">
    <div>
        <div class="popupHeader" mat-dialog-title>
            <span
                >{{ 'CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.LOCATION_DETAILS' | translate }} :
                {{ 'CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.EDIT_TEXT' | translate }} ( {{ selectedLocationName }} )</span
            >
            <span class="app-flex-filler"></span>
        </div>

        <div mat-dialog-content>
            <div class="custom-radio-check-full-width">
                {{ 'CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.ASSIGN_RAIN_GAUGE' | translate }}
            </div>

            <div class="full-width">
                <app-multi-select-auto-complete
                    [items]="selectableRainGauges"
                    [preselectedItems]="selectedRainGauges"
                    (currentlySelectedItems)="selectedNewRainGauges($event)"
                    placeholder="Rain Gauges"
                ></app-multi-select-auto-complete>
            </div>
        </div>

        <div mat-dialog-actions>
            <app-loader [isLoading]="customAlarmsLoadingState"></app-loader>
            <app-loader></app-loader>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="updateLocationInfo()" [disabled]="!isApplyButtonEnabled">
                    {{ 'COMMON.APPLY_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
