<div #printSection id="location-flow-balance-report" class="contentLayout">
    <app-loader [isLoading]="isLoading"></app-loader>
    <mat-card>
        <mat-card-header>
            <div data-html2canvas-ignore class="auto-margin non-printable-fb">
                <mat-icon class="backIcon" (click)="redirectToFlowBalance()">arrow_back</mat-icon>
            </div>
            <div class="auto-margin">
                <span>{{ 'FLOW_BALANCE.FLOW_BALANCE_REPORT' | translate }} : {{ locationName }} </span>
            </div>
            <div class="auto-margin" style="width: 450px; padding: 10px;">
                <app-date-range-picker
                    (startDateChange)="selectedStartDate($event)"
                    (endDateChange)="selectedEndDate($event)"
                    (selectionChange)="dateChanged($event)"
                ></app-date-range-picker>
            </div>
            <div class="app-flex-filler"></div>
            <div data-html2canvas-ignore class="auto-margin custom-flat-button non-printable-fb">
                <button mat-button (click)="generatePDF()" [disabled]="!isDataAvailable">
                    <mat-icon>print</mat-icon> {{ 'COMMON.PRINT' | translate }}
                </button>
            </div>
        </mat-card-header>
        <div class="notabledataFound custom-table" *ngIf="!isDataAvailable">
            {{ 'COMMON.NO_DATA_AVAILABLE' | translate }}
        </div>
        <mat-card-content *ngIf="isDataAvailable">
            <div id="location-flow-balance-graph">
                <app-hydro-graph #hydroGraphChart [showGraph]="showGraph"></app-hydro-graph>
            </div>

            <div id="location-flow-balance-table" *ngIf="isDataAvailable">
                <div class="tableScrollWraper">
                    <mat-table #flowBalanceTable [dataSource]="flowBalanceDataSource" matSort>
                        <ng-container *ngFor="let column of flowBalanceColumns; trackBy: trackById">
                            <ng-container matColumnDef="{{ column.id }}">
                                <mat-header-cell *matHeaderCellDef>{{ column.text }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <label>{{ element[column.id] }}</label>
                                </mat-cell>
                            </ng-container>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="rowColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: rowColumns"></mat-row>
                    </mat-table>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
