<mat-form-field class="full-width">
    <input
        #locationSearchBox
        type="text"
        matInput
        [formControl]="autoCompleteCtrl"
        [matAutocomplete]="sharedAutoComplete"
        [matAutocompleteDisabled]="!sharedAutoComplete"
        placeholder="{{ placeholder }}"
        (focus)="handleFocus()"
        (focusout)="handleFocusOut()"
        [disabled]="disabled"
        (keyup.escape)="onEscape()"
    />
    <mat-icon class="clickable" matTooltip="Clear" matSuffix (click)="clearInputField($event)" *ngIf="!isSelfClearing"
        style="vertical-align: middle;">clear</mat-icon
    >
</mat-form-field>

<mat-autocomplete
    [disabled]="disabled"
    #sharedAutoComplete="matAutocomplete"
    (optionSelected)="handleOptionSelected($event)"
    [panelWidth]="panelWidth ? panelWidth : null"
>
    <mat-option *ngIf="isShowAll" [value]="allValue">All</mat-option>
    <mat-option
        [ngClass]="{ 'selected-item-bg': markSelectedOption && preselectedItem && item && preselectedItem.name === item.name }"
        *ngFor="let item of filteredItems | async; trackBy: trackByIndex"
        [value]="item?.name"
        [matTooltip]="(showTooltipMinLength && item.name.length >= showTooltipMinLength) ? item.name : null"
    >{{ item?.name }}
</mat-option>
</mat-autocomplete>
