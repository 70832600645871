<div id="showDailySummaryReportId">
    <div class="locations daily-locations-hint">
        <app-location-selection-bar [hidden]="false" [trackSelectedLocation]="true" [selectableLocations]="selectedLocations" [focusOnClear]="false" [disabled]="isLoading" [filterSettings]="filterSettings" [locationId]="locationId" (emitChangeLocation)="onChangeLocation($event)" [customerId]="customerID">
        </app-location-selection-bar>
    </div>
    <div class="topmenu">
        <!-- Entity Selector Drop Down-->
        <div class="flex-item-al-s-ju-s daily-entity-hint" style="justify-content: space-between" id="entities">
            <app-entity-selector [availableEntities]="availableEntities$ | async" [availableANSREntities]="availableANSREntities$ | async" [selectedEntitiyIds]="selectedEntityIds" [defaultEntities]="defaultEntities" (selectedEntities)="selectEntities($event)" (emitBlur)="entityOnBlur()"></app-entity-selector>
            <span class="danger absoluteError" *ngIf="displayEntityError">{{ entityErrorMessage }}</span>
        </div>
        <!-- Date Selector -->
        <div class="daily-date-picker-hint" style="width: 420px;">
            <app-date-range-picker [startDate]="startDate" [endDate]="endDate" [minDate]="minDate" [maxDate]="maxDate" [maxDayRange]="93" [quickOneYear]="false" [quickSixMonths]="false" [defaultTimeSpan]="selectedTimespan" [(selectedTimespan)]="selectedTimespan"
                [oneWeekDoNotIncludeToday]="true" (emitDateChanged)="updateDateRange($event)" (emitClose)="datepickerOnClose()" [emitCloseOnChanges]="false" [hideHours]="true" [dateRangeType]="'date'"></app-date-range-picker>
        </div>
    </div>
    <mat-card>
        <mat-card-header>
            <div class="auto-margin">
                <span class="daily-title-hint">{{ 'REPORT.DAILY_SUMMARY.DETAILS_TITLE' | translate }} {{ locationName }}
                </span>
                <span *ngIf="dateFormat">:
                    <span class="strong">{{ startDate | date: dateFormat }} </span>
                <span> - </span>
                <span class="strong">{{ endDate | date: dateFormat }} </span>
                </span>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button class="daily-download-hint" (click)="openDailySummaryExport()" mat-button [disabled]="!dailySummaryData?.report">
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
        </mat-card-header>

        <div *ngIf="isLoading; else loaded">
            <app-loader [isLoading]="isLoading"></app-loader>
        </div>

        <ng-template #loaded>
            <div id="dailySummaryAccordion" *ngIf="dailySummaryData?.report as data; else noDataFound">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="daily-hide-tile-hint">
                            <mat-panel-title>
                                <span>{{ 'REPORT.DAILY_SUMMARY.REPORT_SUMMARY' | translate }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div id="location-dashbord-synopsis-leaderBoard">
                            <mat-tab-group class="daily-tiles-hint">
                                <mat-tab class="hide">
                                    <ng-template mat-tab-label class="hide"> </ng-template>
                                </mat-tab>

                                <mat-tab disabled *ngFor="let item of synopsisList; index as i; trackBy: trackByIndex">
                                    <ng-template mat-tab-label>
                                        <div class="location-dashbord-synopsis synopsis_{{ i }}">
                                            <div class="lB-item-top">
                                                <div class="lB-item-top-rgt">
                                                    <div class="item-col">
                                                        <div class="item-content" *ngIf="
                                                                item.group === GROUPS.FLOW_DISPLAY_GROUP ||
                                                                item.group === GROUPS.RAIN_DISPLAY_GROUP ||
                                                                item.group === GROUPS.RAIN_INTENSITY_GROUP ||
                                                                item.group === GROUPS.INVALID_DISPLAY_GROUP
                                                            ">
                                                            <div class="item-value">
                                                                {{ item.total !== '-' ? item.total : '--' }} {{ item.totalSuffix }}
                                                            </div>
                                                            <div class="item-text">
                                                                {{ 'REPORT.DAILY_SUMMARY.TOTAL_TEXT' | translate }}
                                                            </div>
                                                        </div>
                                                        <div class="item-content" *ngIf="
                                                                item.group === GROUPS.FLOW_DISPLAY_GROUP ||
                                                                item.group === GROUPS.ELEVATION_DISPLAY_GROUP ||
                                                                item.group === GROUPS.DEPTH_DISPLAY_GROUP ||
                                                                item.group === GROUPS.VELOCITY_DISPLAY_GROUP ||
                                                                item.group === GROUPS.TEMPERATURE_DISPLAY_GROUP ||
                                                                item.group === GROUPS.INVALID_DISPLAY_GROUP ||
                                                                item.group === GROUPS.FEET_DISPLAY_GROUP
                                                            ">
                                                            <div class="item-value">
                                                                {{ item.average !== '-' ? item.average : '--' }} {{ item.suffix }}
                                                            </div>
                                                            <div class="item-text">
                                                                {{ 'REPORT.DAILY_SUMMARY.AVERAGE_TEXT' | translate }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="lB-item-but">
                                                <span>{{ item.name }}</span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-card-content>
                    <table *ngIf="data?.headers.length > 0 && data?.data?.length > 0; else noDataFound" class="
                                light-table light-table-bordered light-table-hover light-table-responsive-vertical
                                black-theme-table
                            ">
                        <colgroup>
                            <col *ngFor="let item of data.headers[0]; trackBy: trackByIndex" [attr.span]="item.colspan" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th *ngFor="let item of data.headers[0]; let i = index; trackBy: trackByIndex" [ngClass]="{ 'daily-table-hint': i === 0 }" [attr.colspan]="item.colspan || 1" class="textAlignCenter">
                                    {{ item.name | uppercase }}
                                    <span *ngIf="
                                                item.group !== GROUPS.FLOW_DISPLAY_GROUP &&
                                                (item.suffix !== '(MGD)' || item.suffix !== '(cfs)')
                                            ">{{ item.suffix }}</span
                                        >
                                        <span
                                            *ngIf="
                                                item.group !== GROUPS.FLOW_DISPLAY_GROUP &&
                                                (item.totalSuffix === '(MG)' || item.totalSuffix === '(ft3)')
                                            "
                                            >({{ item.suffix | slice: 1:-1 }} - Total
                                            {{ item.totalSuffix | slice: 1:-1 }})</span
                                        >
                                        <span *ngIf="item.group === GROUPS.FLOW_DISPLAY_GROUP"
                                            >({{ item.suffix | slice: 1:-1 }} - Total
                                            {{ item.totalSuffix | slice: 1:-1 }})</span
                                        >
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        *ngFor="let item of data.headers[1]; trackBy: trackByIndex"
                                        [attr.colspan]="item.colspan || 1"
                                        class="headerSecondRow textAlignCenter"
                                    >
                                        {{ item.name | uppercase }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let datum of pageData; let i = index; trackBy: trackByIndex"
                                    [ngClass]="{ 'daily-values-hint': i === 0 }"
                                >
                                    <td *ngFor="let item of datum; trackBy: trackByIndex">
                                        <div class="dailySummaryValue">
                                            <div>
                                                <b>{{ item.value.split('\n')[0] }}</b>
                                            </div>
                                            <div>{{ item.value.split('\n')[1] }}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    <mat-paginator
                        [length]="dailySummaryData?.report?.data?.length"
                        [pageIndex]="startIndex"
                        [pageSize]="pageSize"
                        (page)="onPaginateChange($event)"
                        [pageSizeOptions]="[50, 25, 100]"
                    >
                    </mat-paginator>
                </mat-card-content>
            </div>
            <ng-template #noDataFound>
                <mat-card-content>
                    <div class="notabledataFound custom-table">
                        <span *ngIf="!selectedEntities || selectedEntities.length < 1">{{
                            'REPORT.NO_ENTITIES_SELECTED' | translate
                        }}</span>
                                    <span *ngIf="selectedEntities && selectedEntities.length > 0">{{
                            'REPORT.NO_DATA_MESSAGE' | translate
                        }}</span>
            </div>
            </mat-card-content>
        </ng-template>
        </ng-template>
    </mat-card>
</div>
