<mat-form-field [ngClass]="{ 'full-width': true, invalid: !isValid }">
    <div class="mat-focused multi-entity-input">
        <input
            matInput
            [attr.data-identifier]="surrogateIdentifier"
            [(ngModel)]="initPlaceholderText"
            autocomplete="off"
            tabindex="-1"
            [formControl]="searchString"
            [placeholder]="initialPlaceholder"
            (focus)="onInputFocusIn($event)"
            (focusout)="onInputFocusOut($event)"
            (ngModelChange)="onInputEntry($event)"
            required
        />
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</mat-form-field>
<mat-selection-list
    id="entityGroupLimit"
    class="multi-select-group-list"
    (focus)="onSelectionListFocused($event)"
    (focusout)="onSelectionListNotFocused($event)"
    [attr.data-identifier]="surrogateIdentifier"
    *ngIf="isResultsVisible"
>
    <mat-accordion [attr.data-identifier]="surrogateIdentifier" [multi]="isSearching">
        <ng-container *ngFor="let groupName of itemGroupNameList; index as i; trackBy: trackByIndex">
            <mat-expansion-panel
                [attr.data-identifier]="surrogateIdentifier"
                [expanded]="isSearching"
                *ngIf="itemGroupByList[groupName]?.length > 0"
            >
                <mat-expansion-panel-header [attr.data-identifier]="surrogateIdentifier">
                    <mat-panel-title>
                        {{ groupName }}
                    </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <mat-selection-list class="multi-select-list" [attr.data-identifier]="surrogateIdentifier">
                        <mat-list-option
                            *ngFor="let item of itemGroupByList[groupName]; trackBy: trackById"
                            [attr.data-item]="item.id"
                            [attr.data-identifier]="surrogateIdentifier"
                            (click)="onSelectionChange($event, item.id)"
                            [disabled]="!item.isChecked"
                            [selected]="item.isChecked"
                            [attr.data-id]="item.id"
                            value="{{ item }}"
                            >{{ item.name }}</mat-list-option
                        >
                    </mat-selection-list>
                    <div></div>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</mat-selection-list>
