import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { UsersService } from 'app/pages/admin/users.service';
import { FLOW_DISPLAY_GROUP } from 'app/shared/constant';
import { InstallationType, LocationDetails } from 'app/shared/models/location-details';
import { LocationStatus } from 'app/shared/models/locations';
import { EntityResponseItem } from 'app/shared/models/view-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { EntityService } from 'app/shared/services/entity.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { Subscription } from 'rxjs';
import { CUSTOM_TYPE_VALUE, InstallationTypeValidators, LocationStatuses } from '../../location-card.constants';
import { LocationCardService } from '../../services/location-card.service';
import { LocationFormBuilder } from '../../services/location-form-builder';
import { LocationCardData, RainGaugeLocation } from '../../models/location-card.model';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-mp2-basic-info',
    templateUrl: './mp2-basic-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class Mp2BasicInfoComponent implements OnInit, OnChanges, OnDestroy {
    @Input() form: FormGroup;
    @Input() data: LocationCardData;
    @Input() details: LocationDetails;
    @Input() timezone: { value: string, text: string; utcValue: string };
    @Input() defaultRoughness: number;
    @Input() installationTypes: InstallationType[] = [];
    @Input() customProperties: string[];

    @Output() pipeHeightChange = new EventEmitter();
    @Output() installationSeriesChange = new EventEmitter();

    public LocationStatus = LocationStatus;
    public flowEntities: EntityResponseItem[] = [];
    public installationTypeSeries: number;
    public locationStatuses: { key: number; name: string }[] = LocationStatuses;
    public filteredAssignedRainGauge: RainGaugeLocation[];
    public rainGaugeLocations: RainGaugeLocation[];
    public isMonitorEditor = false;
    public dateFormat = 'yyyy/MM/dd';
    private isFirstCall = true;
    private shouldPatchInstallationForm = false;
    private subscriptions = new Subscription();
    public locationMaintenanceHours: number[] = [];

    public tagsAutocomplete: string[] = [];
    public trackByIndex = TrackBy.byIndex;
    public trackByEntityId = TrackBy.byEntityId;
    public trackByLocationId = TrackBy.byLocationId;
    public trackByKey = TrackBy.byUniqueKey<{ key: number; name: string }>()('key');

    constructor(
        private entityService: EntityService,
        private locationFormBuilder: LocationFormBuilder,
        private dateUtilService: DateutilService,
        private usersService: UsersService,
        private locationCardService: LocationCardService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        for (let i = 1; i <= 48; i++) {
            this.locationMaintenanceHours.push(i);
        }

        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        const dateSubs = this.dateUtilService.dateFormat.subscribe((result) => {
            this.dateFormat = this.dateUtilService.getFormat();
        });
        this.subscriptions.add(dateSubs);

        this.getFlowEntities();

        if (this.data.rainGaugeLocations) {
            this.rainGaugeLocations = [...this.data.rainGaugeLocations];
            this.filteredAssignedRainGauge = [...this.rainGaugeLocations];
            const rainGaugeSubs = this.form.get('assignedRainGauge').valueChanges.subscribe((item: string) => {
                this.filterAssignedRainGauge(item);
            });

            this.subscriptions.add(rainGaugeSubs);
        }

        if (this.details && this.details.assignedRainGaugeLocationId) {
            const selectedRainGauge = this.data.rainGaugeLocations.find(
                (v) => v.locationId === this.details.assignedRainGaugeLocationId,
            );

            if (selectedRainGauge) {
                this.form.patchValue({ assignedRainGauge: selectedRainGauge.locationName });
            }
        }

        if (!this.data.editMode) {
            this.form.patchValue({ installationDate: new Date() });
        }

        if (!this.usersService.isMonitorEditor.getValue()) {
            this.form.get('installationDate').disable();
        }

        const interval = this.form.get('maintenanceEnd').value;
        if (interval) {
            const difference = this.dateUtilService.getDifferenceByTimezone(this.timezone.value);
            const returnToActive =
                new Date(interval).getTime() +
                difference -
                this.dateUtilService.formatDateAsTimeZone(new Date()).getTime();
            const maintenanceInterval = Math.floor(returnToActive / 1000 / 60 / 60);
            this.form.patchValue({
                maintenanceEnd: this.locationCardService.convertDate(interval),
                maintenanceInterval: maintenanceInterval < 1 ? 2 : maintenanceInterval,
            });
        }

        if (this.details && this.details.customProperties && this.details.customProperties.length) {
            this.details.customProperties.forEach(() => this.addNewTag());

            this.tags.patchValue(this.details.customProperties);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.installationTypes && changes.installationTypes.currentValue.length && this.isFirstCall) {
            this.isFirstCall = false;
            this.populateInstallationForm();
        }
    }

    public onStatusChange(event: MatSelectChange) {
        if (event.value === LocationStatus.Maintenance) {
            this.form.patchValue({ maintenanceEnd: null, maintenanceInterval: 2 });
        }
    }

    public onInstallationTypeChange(event: MatSelectChange) {
        this.form.removeControl('installationForm');
        this.locationFormBuilder.addInstallationForm(this.form, event.value.series, this.locationCardService.isMetric);
        this.installationTypeSeries = event.value.series;

        if (event.value.value === CUSTOM_TYPE_VALUE) {
            this.form.get('installationForm').disable();
        }

        const installationShape = event.value.value.substr(event.value.value.indexOf(' ') + 1);
        if (event.value.series === 400 && (installationShape === '405' || installationShape === '406')) {
            this.form
                .get('installationForm')
                .get('width')
                .setValidators(InstallationTypeValidators(this.locationCardService.isMetric));
        } else if (event.value.series === 400) {
            this.form
                .get('installationForm')
                .get('width')
                .setValidators([Validators.maxLength(100), Validators.pattern(REGEX_CONFIG.installationValuePattern)]);
        }

        if (this.shouldPatchInstallationForm && this.data.editMode && this.details) {
            if (event.value.value.includes('Channel')) {
                this.populateChannelForm();
            } else {
                // #15820 - read field names differs from write field names
                if (this.details.designHC) this.details.hc = this.details.designHC;
                if (this.details.designSlope) this.details.slope = this.details.designSlope;
                if (this.details.designRoughness && this.details.designRoughness !== 0)
                    this.details.roughness = this.details.designRoughness;
                if (!this.details.effectiveRoughness) delete this.details.effectiveRoughness;
            }

            this.form.get('installationForm').patchValue(this.details);
            this.shouldPatchInstallationForm = false;

            if (event.value.series === 200) {
                this.pipeHeightChange.emit({ value: this.details.height, isMp2: true });
            }
        } else {
            const patch = {
                roughness: this.defaultRoughness,
                effectiveRoughness: this.defaultRoughness,
            };
            this.form.get('installationForm').patchValue(patch);
        }

        this.form.patchValue({ installationShapeType: event.value.value.match(/(\d+)/)[0] });

        this.installationSeriesChange.emit ({ value : this.installationTypeSeries }) ;
    }

    public onInstallationDateChange(event) {
        if (event.target) {
            if (event.target.value) {
                if (typeof event.target.value === 'string' && !Number.isNaN(Date.parse(event.target.value))) {
                    this.form.patchValue({ installationDate: new Date(event.target.value) });
                } else if (event.target.value.getTime && !isNaN((event.target.value as Date).getTime())) {
                    this.form.patchValue({ installationDate: event.target.value as Date });
                } else {
                    this.form.patchValue({ installationDate: null });
                }
            } else {
                this.form.patchValue({ installationDate: null });
            }
        }
    }

    public clearAssignedRainGauge(event: PointerEvent) {
        this.form.patchValue({ assignedRainGauge: null });
        this.form.markAsDirty();
        event.stopPropagation();
    }

    public get tags() {
        return this.form.get('customProperties') as FormArray;
    }

    public addNewTag() {
        this.tags.push(this.fb.group({ key: ['', Validators.required], value: ['', [Validators.required, Validators.maxLength(128)]] }));

        this.tags.controls[this.tags.controls.length - 1].markAllAsTouched();
    }

    public deleteTag(index: number) {
        this.tags.removeAt(index);
        this.form.markAsDirty();

        this.validateTags();
    }

    public onTagInput(data: string, index: number) {
        const allTags = this.tags.value.map(v => v.key).filter((v, i) => i !== index);
        const autocompleteTags = Array.from(new Set([...this.customProperties, ...allTags]));

        this.tagsAutocomplete = autocompleteTags.filter(v => v.toLowerCase().includes(data.toLowerCase()));

        this.validateTags();
    }

    public onTagSelected(value: string, index: number) {
        this.tags.controls[index].get('key').setValue(value);

        this.validateTags();
    }

    private validateTags() {
        const allTags: string[] = this.tags.value.map(v => v.key);

        this.tags.controls.forEach((control: AbstractControl, index: number) => {
            const keyControl = control.get('key');

            const isDuplicate = index !== allTags.findIndex(v => v === control.value.key);
            const isEmpty = control.getError('required');

            if (isDuplicate) {
                isEmpty ? keyControl.setErrors({ required: true, unique: true }) : keyControl.setErrors({ unique: true });
            } else {
                isEmpty ? keyControl.setErrors({ required: true }) : keyControl.setErrors(null);
            }
        });
    }

    private populateInstallationForm() {
        if (this.data.editMode && this.details) {
            const installationType = this.pickInstallationType(this.details.installationType, this.installationTypes);
            if (installationType) {
                this.form.patchValue({ installationType });
                this.shouldPatchInstallationForm = true;
                this.onInstallationTypeChange({ value: installationType } as MatSelectChange);
            }
        }
    }

    private pickInstallationType(installationType: string, types: InstallationType[]) {
        const channel = types.find(v => v.value.includes('Channel'));

        if (typeof installationType === 'string') {
            return installationType.includes('Channel') ? channel : types.find(v => v.value === installationType);
        }
    }

    private populateChannelForm() {
        const installationForm = this.form.get('installationForm');
        const numberOfEntries = this.details && this.details.entries ? this.details.entries.length : 0;
        for (let i = 0; i < numberOfEntries; i++) {
            (installationForm.get('entries') as FormArray).push(this.locationFormBuilder.generateEntitiesInfo(this.locationCardService.isMetric));
        }

        installationForm.patchValue({ numberOfEntries, entries: this.details.entries, range: this.details.range });
    }

    private getFlowEntities() {
        const locationId = this.data.locationDetails.locationID || 0;

        this.entityService.getEntitiesByLocation(this.data.customerId, [locationId]).subscribe((res) => {
            this.flowEntities = res.filter(
                (o) =>
                    o.displayGroup === FLOW_DISPLAY_GROUP && o.entityName !== 'QFINAL' && o.entityName !== 'QUANTITY',
            );
        });
    }

    private filterAssignedRainGauge(searchValue: string) {
        if (!searchValue) {
            this.filteredAssignedRainGauge = [...this.rainGaugeLocations];
            return;
        }

        this.filteredAssignedRainGauge = this.rainGaugeLocations.filter((rainGauge) =>
            rainGauge.locationName.toLowerCase().startsWith(searchValue.toLowerCase()),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
