import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    DIRECTION_LIST_DEFAULT_VALUE,
    InstallationTypeValidators,
    LocationCommunicationType,
    ForeSITESensors,
    TritonSensorNames,
    MIN_MEASUREMENT_RANGE,
    BATTERY_LOW_THRESHOLD_DEFAULT_VALUE
} from '../location-card.constants';
import { DEFAULT_APN_PROVIDER, QCONTINUITY_ENTITY} from 'app/shared/constant';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { Config } from 'app/shared/services/config';
import { LocationStatus } from 'app/shared/models/locations';

@Injectable()
export class LocationFormBuilder {
    constructor(private formBuilder: FormBuilder) {}

    public createTritonForm(isMetric: boolean) {
        const form = this.formBuilder.group({
            basicInfo: this.formBuilder.group({
                locationName: [null, Validators.required],
                locationid: [null],
                description: [
                    null,
                    [Validators.pattern(REGEX_CONFIG.alphaNumericWitSpecialSymbols_Pattern), Validators.maxLength(257)],
                ],
                fastrate: [null, Validators.required],
                ipaddress: [
                    null,
                    [Validators.required, Validators.pattern(REGEX_CONFIG.ipaddressPattern), Validators.maxLength(100)],
                ],
                installationType: [{ value: '', disabled: true }, Validators.required],
                installationShapeType: [''],
                latitude: [0, Validators.pattern(REGEX_CONFIG.latitudePattern)],
                longitude: [0, Validators.pattern(REGEX_CONFIG.longitudePattern)],
                manholeaddress: [null, Validators.pattern(REGEX_CONFIG.alphaNumericWitSpecialSymbols_Pattern)],
                manholedepth: [
                    null,
                    [
                        Validators.pattern(
                            isMetric
                                ? REGEX_CONFIG.numericDecimal1PlacePattern
                                : REGEX_CONFIG.numeric0_100000With2DecimalPlacesPattern,
                        ),
                        Validators.maxLength(50),
                    ],
                ],
                series: [null, Validators.required],
                samplerate: [null, Validators.required],
                serialnumber: [
                    null,
                    [
                        Validators.required,
                        Validators.maxLength(10),
                        Validators.pattern(REGEX_CONFIG.numericOnlyPattern),
                    ],
                ],
                assignedRainGauge: [null, Validators.maxLength(50)],
                communicationType: [LocationCommunicationType.Wireless, Validators.required],
                apnProvider: [DEFAULT_APN_PROVIDER, Validators.required],
                status: [{ value: 0, disabled: true }, Validators.required],
                maintenanceInterval: [2],
                qFinalEntityID: [QCONTINUITY_ENTITY],
                installationDate: [],
                synctime: [true],
                usedaylightsaving: [true],
                maintenanceEnd: [null],
                customProperties: this.formBuilder.array([])
            }),
            dataDelivery: this.generateTritonDataDelivery(),
            alarmsInfo: this.generateConfigureAlarms(false, isMetric)
        });

        return form;
    }

    public generateTritonInfo(isMetric: boolean) {
        let form = this.formBuilder.group({
            monitors: this.formBuilder.array([
                this.formBuilder.group({
                    sensor: [null, Validators.required],
                    mp: [null, Validators.required],
                    channel: [null, Validators.required],
                    silt: [
                        null,
                        Validators.pattern(
                            isMetric
                                ? REGEX_CONFIG.installationMetricValuePattern
                                : REGEX_CONFIG.installationValuePattern,
                        ),
                    ],
                }),
                this.formBuilder.group({
                    sensor: [null],
                    mp: [null],
                    channel: [null],
                    silt: [
                        null,
                        Validators.pattern(
                            isMetric
                                ? REGEX_CONFIG.installationMetricValuePattern
                                : REGEX_CONFIG.installationValuePattern,
                        ),
                    ],
                }),
            ]),
            comport: ['None'],
            inclinometerthreshold: [5, [Validators.pattern(REGEX_CONFIG.numericOnlyPattern), Validators.min(1), Validators.max(90)]],
        });
        return form;
    }


    public generateEchoInfo(isMetric: boolean) {
        return this.formBuilder.group({
            sensor: [null, Validators.required],
            mpOne: ['MP1'],
            silt: [
                0,
                Validators.pattern(
                    isMetric ? REGEX_CONFIG.installationMetricValuePattern : REGEX_CONFIG.installationValuePattern,
                ),
            ],
            hcValue: [0.0, Validators.pattern(REGEX_CONFIG.hcValuePattern)],
        });
    }

    public generateForeSITEXDInfo(isMetric: boolean) {
        let form = this.formBuilder.group({
            monitors: this.formBuilder.array([
                this.formBuilder.group({
                    sensor: [null, Validators.required],
                }),
            ]),
        });
        return form;
    }

    public generateRainalertInfo() {
        return this.formBuilder.group({
            sensor: [null, Validators.required],
            mpOne: ['MP1'],
            intensityinterval: [60, [Validators.min(1), Validators.max(1440)]],
            logintensity: [false],
            logukintensity: [false],
            storetemperature: [false],
            storebattery: [1]
        });
    }

    public generateEchoLongRange(isMetric: boolean) {
        return this.formBuilder.group({
            manholedepth: [0.0],
            physicalOffset: [
                isMetric ? Number(355.6).toFixed(1) : 14,
                Validators.pattern(
                    isMetric
                        ? REGEX_CONFIG.negativeWithOneDecimalPattern
                        : REGEX_CONFIG.negativeWithTwoDecimalPattern)
            ],
            storeData: [true],
            blanking: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern)
                ],
            ],
            temperatureSensor: [0],
            power: [0],
            gainControlSelection: ['Auto'],
            electronicOffset: [
                0.0,
                Validators.pattern(
                    isMetric
                        ? REGEX_CONFIG.negativeWithOneDecimalPattern
                        : REGEX_CONFIG.negativeWithTwoDecimalPattern)
            ],
            gainControl: [{ value: 840, disabled: true }],
            unidepthFiltering: [true],
            pressureStore: [true],
            compensatedTemperatureMarch: [
                isMetric ? 12.78 : 55,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal2PlacePattern : REGEX_CONFIG.numericDecimal1PlacePattern,
                    ),
                ],
            ],
            compensatedTemperatureSeptember: [
                isMetric ? 22.78 : 73,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal2PlacePattern : REGEX_CONFIG.numericDecimal1PlacePattern,
                    ),
                ],
            ],
        });
    }

    public generateRainSensorInfo(isMetric: boolean) {
        return this.formBuilder.group({
            rainpertip: [isMetric ? 0.254 : 0.01],
            datalogmode: [0],
        });
    }

    public generateBlankSensors() {
        return this.formBuilder.array([null, null]);
    }

    public generateBlankForeSITEXDSensors() {
        return this.formBuilder.array([null]);
    }

    public addMp2(form: FormGroup, isMetric: boolean, isInactive: boolean) {
        form.addControl('mp2BasicInfo', this.generateMp2BasicInfo(isInactive));
        form.addControl('mp2AlarmsInfo', this.generateConfigureAlarms(true, isMetric));
    }

    public getSensorFormByName(name: string, index: 0 | 1, mp: 0 | 1, isMetric: boolean) {
        switch (name) {
            case TritonSensorNames.peakCombo:
            case TritonSensorNames.avMax: {
                return this.generatePeakComboInfo(name, index, mp, isMetric);
            }
            case TritonSensorNames.avGated: {
                return this.generateAvGatedInfo(name, index, mp, isMetric);
            }
            case TritonSensorNames.longRange: {
                return this.generateLongRangeInfo(name, index, mp, isMetric);
            }
            case TritonSensorNames.paraDepth:
            case TritonSensorNames.smartDepth: {
                return this.generateSmartDepthInfo(name, index, mp, isMetric);
            }
            case TritonSensorNames.paraFlow:
            case TritonSensorNames.surfaceCombo: {
                return this.generateSurfaceComboInfo(name, index, mp, isMetric);
            }
            case ForeSITESensors.radar: {
                return this.generateForeSITERadarInfo(name, isMetric);
            }
        }
    }

    public addInstallationForm(form: FormGroup, installationSeries: number, isMetric: boolean) {
        let installationForm: FormGroup;
        switch (installationSeries) {
            case 200: {
                installationForm = this.generatePipeInfo(isMetric);
                break;
            }
            case 300: {
                installationForm = this.generateWeirInfo(isMetric);
                break;
            }
            case 400: {
                installationForm = this.generateFlumeInfo();
                break;
            }
            case 500: {
                installationForm = this.generateChannelInfo(isMetric);
                break;
            }
            case 1000: {
                installationForm = this.generateElevationInfo(isMetric);
                break;
            }
        }
        if(installationForm){
            this.updateInstallationForm(installationForm);
            form.removeControl('installationForm');
            form.addControl('installationForm', installationForm);
            form.updateValueAndValidity();
        }
    }

    public generateEntitiesInfo(isMetric: Boolean) {
        if (isMetric) {
            return this.formBuilder.group({
                depth: [
                    null,
                    [
                        Validators.required,
                        Validators.maxLength(10),
                        Validators.pattern(REGEX_CONFIG.numeric0_10000With1DecimalPlacePattern),
                    ],
                ],
                quantity: [
                    null,
                    [
                        Validators.required,
                        Validators.maxLength(10),
                        Validators.pattern(REGEX_CONFIG.numeric0_10000With1DecimalPlacePattern),
                        Validators.max(10000)
                    ],
                ],
            });
        }
        else {
            return this.formBuilder.group({
                depth: [
                    null,
                    [
                        Validators.required,
                        Validators.maxLength(10),
                        Validators.pattern(REGEX_CONFIG.numericDecimal2PlacePattern),
                        Validators.max(393)
                    ],
                ],
                quantity: [
                    null,
                    [
                        Validators.required,
                        Validators.maxLength(10),
                        Validators.pattern(REGEX_CONFIG.numericDecimal3PlacePattern),
                        Validators.max(1000)
                    ],
                ],
            });
        }
    }

    public enableDisableControls(form: FormGroup, disable: boolean, keys: string[]) {
        keys.forEach((v) => {
            if (!form.get(v)) {
                return;
            }

            disable ? form.get(v).disable() : form.get(v).enable();
        });
    }

    public generateSamplerInfo() {
        return this.formBuilder.group({
            samplingType: [0],
            trigger: [0.0],
            samplesPerWakeup: [1],
            delay: [2],
        });
    }

    public generateRainAlarmInfo() {
        return this.formBuilder.group({
            alarmEnable: [false],
            alarmThreshold: [{ value: 0, disabled: true }],
            alarmReturnToNormal: [{ value: 0, disabled: true }],
        });
    }

    public generateConfigureAlarms(isMp2 = false, isMetric: boolean) {

        const alarmsInfoGroup = this.formBuilder.group({
            flowLossAlarm: [null],
            percent: [{ value: 25, disabled: true }],
            sensitivity: [{ value: 1, disabled: true }],
            flowLossType: [{ value: 0, disabled: true }],
            clearPatternHistory: [{ value: false, disabled: true }],
            fullPipethreshold: [
                { value: isMp2 ? 0 : null, disabled: true },
                Validators.pattern(
                    isMetric
                        ? REGEX_CONFIG.negativeWithOneDecimalPattern
                        : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                ),
            ],
            fullPipeEnable: [null],
            highAlaramreshold: [{ value: isMp2 ? 0 : null, disabled: true }, Validators.pattern(isMetric ? REGEX_CONFIG.negativeWithOneDecimalPattern : REGEX_CONFIG.negativeWithTwoDecimalPattern)],
            highHighEnable: [null],
            highHighThreshold: [{ value: isMp2 ? 0 : null, disabled: true }, Validators.pattern(isMetric ? REGEX_CONFIG.negativeWithOneDecimalPattern : REGEX_CONFIG.negativeWithTwoDecimalPattern)],
            highLevelEnable: [null],
            highLevelThreshold: [{ value: isMp2 ? 0 : null, disabled: true }, Validators.pattern(isMetric ? REGEX_CONFIG.negativeWithOneDecimalPattern : REGEX_CONFIG.negativeWithTwoDecimalPattern)],
            lowLevelEnable: [null],
            lowLevelThreshold: [{ value: isMp2 ? 0 : null, disabled: true },Validators.pattern(isMetric ? REGEX_CONFIG.negativeWithOneDecimalPattern : REGEX_CONFIG.negativeWithTwoDecimalPattern)],
            highFlowEnable: [null],
            highFlowThreshold: [{ value: isMp2 ? 0 : null, disabled: true }, [Validators.pattern(isMetric ? REGEX_CONFIG.negativeWithOneDecimalPattern : REGEX_CONFIG.negativeWithThreeDecimalPattern), Validators.min(0), Validators.max(100000)]],
            overflowEnable: [null],
            batteryLowEnable: [false],
            batteryLowThreshold: [{ value: BATTERY_LOW_THRESHOLD_DEFAULT_VALUE, disabled: true }]
        });

        if (!isMp2) {
            alarmsInfoGroup.addControl('batteryLowEnable', new FormControl(false));
            alarmsInfoGroup.addControl(
                'batteryLowThreshold',
                new FormControl({ value: BATTERY_LOW_THRESHOLD_DEFAULT_VALUE, disabled: true }, InstallationTypeValidators(isMetric))
            );
        }

        return alarmsInfoGroup;
    }

    private generatePeakComboInfo(name: string, index: 0 | 1, mp: 0 | 1, isMetric: boolean) {
        const form = this.formBuilder.group({
            name: [name],
            mp: [mp],
            index: [index],
            ultrasonicFlowType: [0, Validators.required],
            physicalOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            ultrasonicEnable: [true],
            ultrasonicElectronicOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            ultrasonicDeadband: [0, Validators.required],
            ultrasonicStoreData: [true],
            pressureEnable: [true],
            pressureElectronicOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            pressureCalibration: [true],
            pressureStoreData: [true],
            velocityEnable: [true],
            velocitySensitivity: [10, Validators.required],
            velocityDirection: [1, Validators.required],
            velocityGain: [0.9, [Validators.required, Validators.pattern(REGEX_CONFIG.numericDecimal2PlacePattern), Validators.max(2), Validators.min(0)]],
            peakComboMaxVelocityInput: [0],
            velocityMaximum: [0, Validators.required],
            velocityScrubbing: [true],
            velocityStoreData: [true],
            temperatureStoreData: [true],
            velocityFlowType: [null],
            flowType: [0],
            transmitFrequency: [1],
            sensor: [1]
        });

        return form;
    }

    private generateSmartDepthInfo(name: string, index: 0 | 1, mp: 0 | 1, isMetric: boolean) {
        const form = this.formBuilder.group({
            name: [name],
            mp: [mp],
            index: [index],
            temperatureSensor: [1],
            physicalOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            ultrasonicElectronicOffset: [
                0.0,
                [
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            ultrasonicMode: [0, Validators.required],
            ultrasonicBlanking: [0, Validators.required],
            ultrasonicStoreData: [true],
            ultrasonicDisableSurchargeDetection: [true],
            temperatureStoreData: [true],
            sensor: [1]
        });

        return form;
    }

    private generateAvGatedInfo(name: string, index: 0 | 1, mp: 0 | 1, isMetric: boolean) {
        return this.formBuilder.group({
            name: [name],
            mp: [mp],
            index: [index],
            ultrasonicFlowType: [0, Validators.required],
            physicalOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            ultrasonicElectronicOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal1PlacePattern : REGEX_CONFIG.numericDecimal2PlacePattern,
                    ),
                ],
            ],
            ultrasonicDeadband: [0, Validators.required],
            ultrasonicStoreData: [true],
            ultrasonicEnable: [true],
            pressureEnable: [true],
            pressureElectronicOffset: [
                0.0,
                [
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal1PlacePattern : REGEX_CONFIG.numericDecimal2PlacePattern,
                    ),
                ],
            ],
            pressureStoreData: [true],
            pressureCalibration: [true],
            velocityEnable: [true],
            velocityDirection: [DIRECTION_LIST_DEFAULT_VALUE, Validators.required],
            velocityMinimum: [0.05, Validators.required],
            velocityMaximumAVGatedValue: [0, Validators.required],
            velocityMaximum: [0, Validators.required],
            velocityAcquisition: [0, Validators.required],
            velocityStoreData: [true],
            velocityScrubbing: [true],
            temperatureStoreData: [true],
        });
    }

    private generateSurfaceComboInfo(name: string, index: 0 | 1, mp: 0 | 1, isMetric: boolean) {
        const form = this.formBuilder.group({
            name: [name],
            mp: [mp],
            index: [index],
            physicalOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            ultrasonicElectronicOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal1PlacePattern : REGEX_CONFIG.numericDecimal2PlacePattern,
                    ),
                ],
            ],
            ultrasonicMode: [0, Validators.required],
            ultrasonicBlanking: [0, Validators.required],
            ultrasonicDisableSurchargeDetection: [true],
            ultrasonicStoreData: [true],
            surfaceVelocityEnable: [true],
            surfaceVelocityFrequency: [0, Validators.required],
            surfaceVelocitySensitivity: [12, Validators.required],
            surfaceVelocityDirection: [1, Validators.required],
            surfaceComboSVmaxVelocity: [0],
            surfaceVelocityMaximum: [0, Validators.required],
            surfaceVelocityGain: [
                0.9,
                [Validators.required, Validators.pattern(REGEX_CONFIG.numericDecimal2PlacePattern), Validators.min(0), Validators.max(2)],
            ],
            surfaceVelocityDuration: [3, Validators.required],
            surfaceVelocityScrubbing: [true],
            surfaceVelocityStoreData: [true],
            velocityEnable: [true],
            velocityFrequency: [1, Validators.required],
            velocitySensitivity: [10, Validators.required],
            velocityDirection: [1, Validators.required],
            surfaceComboPVmaxVelocity: [0],
            velocityMaximum: [0, Validators.required],
            velocityGain: [0.9, [Validators.required, Validators.pattern(REGEX_CONFIG.numericDecimal2PlacePattern), Validators.max(2), Validators.min(0)]],
            velocityStoreData: [true],
            temperatureStoreData: [true],
            pressureEnable: [true],
            pressureElectronicOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal1PlacePattern : REGEX_CONFIG.numericDecimal2PlacePattern,
                    ),
                ],
            ],
            pressureCalibration: [true],
            pressureStoreData: [true],
            velocityScrubbing: [true],
            sensor: [1]
        });

        return form;
    }

    private generateLongRangeInfo(name: string, index: 0 | 1, mp: 0 | 1, isMetric: boolean) {
        return this.formBuilder.group({
            name: [name],
            mp: [mp],
            index: [index],
            physicalOffset: [
                isMetric ? Number(355.6).toFixed(1) : 14,
                Validators.pattern(
                    isMetric ? REGEX_CONFIG.negativeWithOneDecimalPattern : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                ),
            ],
            storeData: [true],
            blanking: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal1PlacePattern : REGEX_CONFIG.numericDecimal2PlacePattern,
                    ),
                ],
            ],
            temperatureSensor: [0],
            longRangeDepthTritonSensorPowerModel: ['Auto'],
            power: [0],
            electronicOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.negativeWithOneDecimalPattern
                            : REGEX_CONFIG.negativeWithTwoDecimalPattern,
                    ),
                ],
            ],
            gainControlSelection: ['Auto'],
            gainControl: [null],
            compensatedTemperatureMarch: [
                isMetric ? 12.78 : 55,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal2PlacePattern : REGEX_CONFIG.numericDecimal1PlacePattern,
                    ),
                ],
            ],
            compensatedTemperatureSeptember: [
                isMetric ? 22.78 : 73,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric ? REGEX_CONFIG.numericDecimal2PlacePattern : REGEX_CONFIG.numericDecimal1PlacePattern,
                    ),
                ],
            ],
            disableCapacitance: [true],
            logCapacitance: [false],
            surchargeThreshold: [85, [Validators.required, Validators.max(99), Validators.min(70)]],
        });
    }

    private generateForeSITERadarInfo(name: string, isMetric: boolean) {
        return this.formBuilder.group({
            name: [name],
            maxMeasurementRange: [
                MIN_MEASUREMENT_RANGE,
                [
                    Validators.required
                ],
            ],
            physicalOffset: [
                0.0,
                [
                    Validators.required,
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.numericWith1DecimalPlaces
                            : REGEX_CONFIG.numericWith2DecimalPlaces,
                    ),
                ],
            ],
        });
    }

    public generateMp2BasicInfo(isInactive: boolean): FormGroup {
        return this.formBuilder.group({
            qFinalEntityID: [QCONTINUITY_ENTITY],
            assignedRainGauge: [null, Validators.maxLength(50)],
            installationType: [null, Validators.required],
            installationShapeType: [''],
            installationDate: [null],
            status: [{ value: isInactive ? LocationStatus.Inactive : null, disabled: false }],
            maintenanceEnd: [null],
            maintenanceInterval: [2],
            customProperties: this.formBuilder.array([])
        });
    }

    public generateTritonDataDelivery() {
        return this.formBuilder.group({
            prismdeliveryipaddress: [
                Config.urls.tritonDataDeliveryIPAdress,
                [Validators.required, Validators.maxLength(100)],
            ],
            prismdeliveryfast: [0, Validators.required],
            prismdeliverynormal: [0, Validators.required],
        })
    }

    public generateFSdataDelivery() {
        return this.formBuilder.group({
            normalIntervalHours: [24, Validators.required],
            fastModeEnabled: [false, Validators.required],
        });
    }

    public generateFlowInfo() {
        return this.formBuilder.group({
            flowType: [{ value: QCONTINUITY_ENTITY, disabled: true }],
        })
    }

    private generatePipeInfo(isMetric: boolean) {
        // TODO: Fetch it from customer defaults
        return this.formBuilder.group({
            height: [null, InstallationTypeValidators(isMetric)],
            width: [null, InstallationTypeValidators(isMetric)],
            lookupDesc: ['Pipe'],
            hc: [0, [Validators.pattern(REGEX_CONFIG.numericWith2DecimalPlaces)]],
            roughness: [null, [Validators.pattern(REGEX_CONFIG.numericWith5DecimalPlaces)]],
            effectiveRoughness: [null, [Validators.pattern(REGEX_CONFIG.numericWith5DecimalPlaces)]],
            slope: [0],
            frictionFactor: [3],
            nominalDiameter: [0],
            startDate: [new Date()],
            installationType: ['Pipe'],
        });
    }

    private generateWeirInfo(isMetric: boolean) {
        return this.formBuilder.group({
            range: [null, InstallationTypeValidators(isMetric)],
            capacity: [null, InstallationTypeValidators(isMetric)],
            height: [null, InstallationTypeValidators(isMetric)],
            coefficient: [null, InstallationTypeValidators(isMetric)],
            length: [null, InstallationTypeValidators(isMetric)],
            breadth: [null, InstallationTypeValidators(isMetric)],
            lookupDesc: ['Weir'],
            installationType: ['Weir'],
        });
    }

    private generateFlumeInfo() {
        return this.formBuilder.group({
            range: [null, InstallationTypeValidators(false)],
            capacity: [null, InstallationTypeValidators(false)],
            width: [null, [Validators.maxLength(100), Validators.pattern(REGEX_CONFIG.installationValuePattern)]],
            lookupDesc: ['Flume'],
            installationType: ['Flume'],
        });
    }

    private generateChannelInfo(isMetric: boolean) {
        return this.formBuilder.group({
            range: [null, InstallationTypeValidators(isMetric)],
            numberOfEntries: [
                null,
                [Validators.required, Validators.maxLength(3), Validators.pattern(REGEX_CONFIG.channelEntriesLimit)],
            ],
            entries: this.formBuilder.array([]),
            lookupDesc: ['Channel'],
            installationType: ['Channel'],
        });
    }

    private generateElevationInfo(isMetric: boolean) {
        return this.formBuilder.group({
            referencePoint: [null,  [Validators.required, Validators.maxLength(100),
                Validators.pattern(
                    isMetric
                        ? REGEX_CONFIG.numeric0_99999with1DecimalPlacePattern
                        : REGEX_CONFIG.numeric0_99999with2DecimalPlacesPattern,
                )]],
                datum: ['',[Validators.required, Validators.maxLength(16), Validators.pattern(REGEX_CONFIG.alphaNumericWithoutSpace_16CharsPattern)]],
            physicalOffset: [null, [Validators.required, Validators.maxLength(100), 
                Validators.pattern(
                    isMetric
                        ? REGEX_CONFIG.negative0_9999with1DecimalPlacePattern
                        : REGEX_CONFIG.negative0_9999with2DecimalPlacesPattern,
                )]],
            lookupDesc: ['Elevation'],
            installationType: ['Elevation'],
        });
    }

    
    private updateInstallationForm(form: FormGroup) {
        form.addControl('installationDesc1', this.formBuilder.control(''));
        form.addControl('installationDesc2', this.formBuilder.control(''));
        form.addControl('installationDesc3', this.formBuilder.control(''));
        form.addControl('installationDesc4', this.formBuilder.control(''));
        form.addControl('installationDesc5', this.formBuilder.control(''));
        form.addControl('wasCreatedFromImport', this.formBuilder.control(false));
    }
}
