<form name="preCompensation" #preCompensation="ngForm" novalidate>
    <mat-form-field class="width-250">
        <mat-select
            [disabled]="isStudyLocked"
            [(ngModel)]="selectedPrecompType"
            (selectionChange)="onChangePreCompType($event.value)"
            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.CHOOSE_PRECOMP_TYPE' | translate }}"
            name="constant"
        >
            <mat-option *ngFor="let each of precompTypes; trackBy: trackByValue" [value]="each.value">{{ each.value }} </mat-option>
        </mat-select>
    </mat-form-field>
</form>
