<div class="location-diagnostics-results">
    <div class="header popupHeader" mat-dialog-title>
        {{ 'DIAGNOSTICS.DIAGNOSTICS_RESULT' | translate }}: {{ data.status.currentMonitorTime | date: dateFormat }}
    </div>
    <div mat-dialog-content>
        <ul class="content">
            <li class="row">
                <span>{{ 'DIAGNOSTICS.CURRENT_MONITOR_TIME' | translate }}:</span
                ><span>{{ data.status.currentMonitorTime | date: dateFormat }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.OLDEST_ENTRY_TIME' | translate }}:</span
                ><span>{{ data.status.oldestEntryTime | date: dateFormat }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.NEWEST_ENTRY_TIME' | translate }}:</span
                ><span>{{ data.status.newestEntryTime | date: dateFormat }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.MONITOR_TEMPERATURE' | translate }}:</span
                ><span>{{ data.status.monitorTemperature }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.FIRMWARE_VERSION' | translate }}:</span
                ><span>{{ data.status.firmwareVersion }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.MODEL_NUMBER' | translate }}:</span><span>{{ data.status.modelNumber }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.EVENT_PENDING' | translate }}:</span
                ><span>{{ data.status.eventsPendingStatus }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.ABNORMAL_RESETS' | translate }}:</span
                ><span>{{ data.status.numberOfAbnormalResets }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.BATTERY_VOLTAGE' | translate }}:</span
                ><span>{{ data.status.batteryVoltage }}</span>
            </li>
            <li class="row">
                <span>{{ 'DIAGNOSTICS.TCP_IP_SIGNAL_STRENGTH' | translate }}:</span
                ><span>{{ data.wirelessStatus.signalStrength }}</span>
            </li>
        </ul>
        <ng-container *ngFor="let reading of data.readings; trackBy: trackByIndex">
            <h3>{{ reading.sensor }}</h3>
            <ul class="content">
                <li *ngIf="reading.monitoringPoint" class="row">
                    <span>{{ 'DIAGNOSTICS.MONITORING_POINT' | translate }}:</span
                    ><span>{{ reading.monitoringPoint }}</span>
                </li>
                <li *ngIf="reading.reading" class="row">
                    <span>{{ 'DIAGNOSTICS.READING' | translate }}:</span><span>{{ reading.reading }}</span>
                </li>
                <li *ngIf="reading.quality" class="row">
                    <span>{{ 'DIAGNOSTICS.QUALITY' | translate }}:</span><span>{{ reading.quality }}</span>
                </li>
                <li *ngIf="reading.temperature" class="row">
                    <span>{{ 'DIAGNOSTICS.TEMPERATURE' | translate }}:</span><span>{{ reading.temperature }}</span>
                </li>
                <li *ngIf="reading.maximumSignal" class="row">
                    <span>{{ 'DIAGNOSTICS.MAXIMUM_SIGNAL' | translate }}:</span><span>{{ reading.maximumSignal }}</span>
                </li>
                <li *ngIf="reading.gain" class="row">
                    <span>{{ 'DIAGNOSTICS.GAIN' | translate }}:</span><span>{{ reading.gain }}</span>
                </li>
                <li *ngIf="reading.x" class="row">
                    <span>{{ 'DIAGNOSTICS.X' | translate }}:</span><span>{{ reading.x }}</span>
                </li>
                <li *ngIf="reading.y" class="row">
                    <span>{{ 'DIAGNOSTICS.Y' | translate }}:</span><span>{{ reading.y }}</span>
                </li>
                <li *ngIf="reading.z" class="row">
                    <span>{{ 'DIAGNOSTICS.Z' | translate }}:</span><span>{{ reading.z }}</span>
                </li>
                <li *ngIf="reading.xReference" class="row">
                    <span>{{ 'DIAGNOSTICS.X_REFERENCE' | translate }}:</span><span>{{ reading.xReference }}</span>
                </li>
                <li *ngIf="reading.yReference" class="row">
                    <span>{{ 'DIAGNOSTICS.Y_REFERENCE' | translate }}:</span><span>{{ reading.yReference }}</span>
                </li>
                <li *ngIf="reading.zReference" class="row">
                    <span>{{ 'DIAGNOSTICS.Z_REFERENCE' | translate }}:</span><span>{{ reading.zReference }}</span>
                </li>
                <li *ngIf="reading.tilt" class="row">
                    <span>{{ 'DIAGNOSTICS.TILT' | translate }}:</span><span>{{ reading.tilt }}</span>
                </li>
                <li *ngIf="reading.tiltDetected" class="row">
                    <span>{{ 'DIAGNOSTICS.TILT_DETECTED' | translate }}:</span><span>{{ reading.tiltDetected }}</span>
                </li>
                <li *ngIf="reading.tamperDetected" class="row">
                    <span>{{ 'DIAGNOSTICS.TAMPER_DETECTED' | translate }}:</span
                    ><span>{{ reading.tamperDetected }}</span>
                </li>
            </ul>
        </ng-container>
    </div>
    <div class="actions" mat-dialog-actions>
        <button class="close-button" mat-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
    </div>
</div>
