<mat-expansion-panel [ngClass]="{ 'basic-info-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ 'COMMON.MP_BASIC_INFORMATION' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>
        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'COMMON.QFINALTEXT' | translate }}"
                            formControlName="qFinalEntityID"
                        >
                            <mat-option *ngFor="let f of flowEntities; trackBy: trackByEntityId" [value]="f.entityId"
                                >{{ f.entityName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item" *ngIf="!data.editMode">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_ASSIGNED' | translate }}"
                            aria-label="Assigned Rain Gauge"
                            formControlName="assignedRainGauge"
                            [matAutocomplete]="auto"
                        />
                        <mat-icon class="clickable" matTooltip="{{'COMMON.CLEAR_TOOLTIP' | translate}}" matSuffix (click)="clearAssignedRainGauge($event)">clear</mat-icon>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                *ngFor="let rainGauge of filteredAssignedRainGauge; trackBy: trackByLocationId"
                                [value]="rainGauge.locationName"
                            >
                                <span>{{ rainGauge.locationName }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="custom-width slide-active-display" *ngIf="data.editMode">
                    <mat-form-field class="field-status">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status">
                            <mat-option *ngFor="let status of locationStatuses; trackBy: trackByKey" [value]="status.key" (selectionChange)="onStatusChange($event)">
                                {{ status.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        class="field-status-hours"
                        *ngIf="form.get('status').value === LocationStatus.Maintenance"
                    >
                        <mat-label>{{ 'CUSTOMER_EDITOR.HOURS' | translate }}</mat-label>
                        <mat-select
                            [placeholder]="'CUSTOMER_EDITOR.HOURS' | translate"
                            formControlName="maintenanceInterval"
                        >
                            <mat-option *ngFor="let hour of locationMaintenanceHours; trackBy: trackByIndex" [value]="hour">
                                {{ hour }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="field-status-info" *ngIf="form.get('status').value === LocationStatus.Maintenance">
                        {{ 'CUSTOMER_EDITOR.RETURN_TO_ACTIVE' | translate }}<br />{{
                            !form.get('maintenanceEnd').value ? 'unknown' : form.get('maintenanceEnd').value
                        }}
                    </span>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.INSTALLATION_TYPE' | translate }}"
                            formControlName="installationType"
                            class="full-width"
                            (selectionChange)="onInstallationTypeChange($event)"
                        >
                            <mat-option
                                *ngFor="let installationType of installationTypes | filterByUnpickable; trackBy: trackByIndex"
                                [value]="installationType"
                                >{{ installationType.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="Installation Date"
                            (change)="onInstallationDateChange($event)"
                            [value]="form.get('installationDate').value | date: dateFormat"
                            tabindex="-1"
                            class="datepickerStyles"
                            [disabled]="form.get('installationDate').disabled"
                            appDatetimepickerPosition
                            [matDatetimepickerComp]="installationDte"
                        />
                        <input
                            matInput
                            hidden
                            formControlName="installationDate"
                            [matDatetimepicker]="installationDte"
                        />
                        <mat-datetimepicker-toggle
                            matSuffix
                            [for]="installationDte"
                            [disabled]="form.get('installationDate').disabled"
                        ></mat-datetimepicker-toggle>
                        <mat-datetimepicker #installationDte timeInterval="1" type="date"></mat-datetimepicker>
                    </mat-form-field>
                </div>
            </div>

            <app-installation-type-info
                *ngIf="form.get('installationForm')"
                [form]="form.get('installationForm')"
                [installationType]="installationTypeSeries"
                [type]="form.get('installationType')?.value"
                (pipeHeightChange)="pipeHeightChange.emit({ value: $event, isMp2: true })"
            >
            </app-installation-type-info>

            <div *ngIf="data.editMode">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_ASSIGNED' | translate }}"
                        aria-label="Assigned Rain Gauge"
                        formControlName="assignedRainGauge"
                        [matAutocomplete]="auto"
                    />
                    <mat-icon class="clickable" matTooltip="{{'COMMON.CLEAR_TOOLTIP' | translate}}" matSuffix (click)="clearAssignedRainGauge($event)">clear</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                            *ngFor="let rainGauge of filteredAssignedRainGauge; trackBy: trackByLocationId"
                            [value]="rainGauge.locationName"
                        >
                            <span>{{ rainGauge.locationName }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="tags-container">
                <div class="tags-header">
                    <p>{{ 'ADD_EDIT.INPUT_TAG_TITLE' | translate }}:</p>

                    <button mat-button [disabled]="tags.controls.length >= 64" color="primary" type="button" (click)="addNewTag()">{{ 'COMMON.ADD_MORE' | translate }}</button>
                </div>
                <ng-container formArrayName="customProperties" *ngFor="let tagGroup of tags?.controls; let i = index; trackBy: trackByIndex">
                    <div class="tags-content">
                        <div class="custom-50p" [formGroupName]="i">
                            <div class="custom-width">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        #tagNameInput
                                        placeholder="{{ 'ADD_EDIT.INPUT_TAG_NAME' | translate }}"
                                        formControlName="key"
                                        [matAutocomplete]="tagNameAutocomplete"
                                        (input)="onTagInput(tagNameInput.value, i)"
                                        (focus)="onTagInput(tagNameInput.value, i)"
                                    />
                                    <mat-autocomplete
                                        #tagNameAutocomplete="matAutocomplete"
                                        (optionSelected)="onTagSelected($event.option.value, i)"
                                    >
                                        <mat-option
                                            *ngFor="let option of tagsAutocomplete; trackBy: trackByIndex"
                                            [value]="option"
                                        >
                                            {{ option }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div class="mat-error" *ngIf="tagGroup.get('key')?.errors?.unique">
                                    {{ 'ADD_EDIT.DUPLICATE_TAG_NAME_ERROR' | translate }}
                                </div>
                            </div>
                            <div class="custom-width">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        placeholder="{{ 'ADD_EDIT.INPUT_TAG_VALUE' | translate }}"
                                        formControlName="value"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <button mat-icon-button type="button" (click)="deleteTag(i)"><mat-icon>delete</mat-icon></button>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</mat-expansion-panel>
