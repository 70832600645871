import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { ModemSetupComponent, ModemSetupItem } from 'app/shared/components/map/modem-setup';
import { Series} from 'app/shared/constant';
import { FLOW_DISPLAY_GROUP } from 'app/shared/constant';
import { CustomerUnits, InstallationType, LocationDetails, MonitorSeriesUI } from 'app/shared/models/location-details';
import { InstallationTypes, LocationStatus } from 'app/shared/models/locations';
import { MonitorAddLocationSelectable, MonitorIdentifier, TritonConfiguration } from 'app/shared/models/monitorsetting';
import { Selectable } from 'app/shared/models/selectable';
import { RAINALERT_SERIES } from 'app/shared/models/TritonLocation';
import { EntityResponseItem } from 'app/shared/models/view-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { EntityService } from 'app/shared/services/entity.service';
import { GISService } from 'app/shared/services/gis-service';
import { LocationService } from 'app/shared/services/location.service';
import { ModemSetupService } from 'app/shared/services/modem-setup.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiService } from 'app/shared/services/ui.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { StringUtils } from 'app/shared/utils/string-utils';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {
    CommunicationTypes,
    ForeSiteSampleRateList,
    InstallationTypeValidators,
    LocationCommunicationType,
    LocationStatuses,
    MonitorSeriesNames,
    SampleRateList,
} from '../../location-card.constants';
import { LocationCardService } from '../../services/location-card.service';
import { LocationFormBuilder } from '../../services/location-form-builder';
import { InstallationTypeInfoComponent } from '../installation-type-info/installation-type-info.component';
import { LocationCardData, RainGaugeLocation, SampleRateElement } from '../../models/location-card.model';
import { CustomerService } from 'app/shared/services/customer.service';
import { Entries } from 'app/shared/models/add-location-channel';
import { TrackBy } from 'app/shared/utils/track-by';

const CUSTOM_TYPE_VALUE = 'Pipe: 211';
const CHANNEL_STANDARD_VALUE = 'Channel: 500';

// Series Ids for Rain gauge and USGS locations
const RainUSGSLocationTypes = [0,1,29];

@Component({
    selector: 'app-triton-basic-info',
    templateUrl: './triton-basic-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class TritonBasicInfoComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() data: LocationCardData;
    @Input() details: LocationDetails;
    @Input() monitorConfigs: TritonConfiguration;
    @Input() timezone: { value: string, text: string; utcValue: string };
    @Input() defaultRoughness: number;
    @Input() customProperties: string[] = [];


    @Output() monitorSeriesChange = new EventEmitter<string>();
    @Output() manholeDepthChange = new EventEmitter();
    @Output() pipeHeightChange = new EventEmitter();
    @Output() statusChange = new EventEmitter();
    @Output() installationSeriesChange = new EventEmitter();

    public LocationStatus = LocationStatus;

    public communicationTypes: MonitorAddLocationSelectable[] = CommunicationTypes;
    public modemProviders: Selectable[] = [];
    public rateList: SampleRateElement[] = SampleRateList;
    public foreSiteRateList: SampleRateElement[] = ForeSiteSampleRateList;
    public installationTypes: InstallationType[] = [];
    public monitorSeries: MonitorAddLocationSelectable[] = [];
    public flowEntities: EntityResponseItem[] = [];
    public locationStatuses: { key: number; name: string }[] = LocationStatuses;
    public locationMaintenanceHours: number[] = [];
    public installationTypeSeries: number;
    public selectedMonitorSeries: string;

    public filteredAssignedRainGauge: RainGaugeLocation[];
    public rainGaugeLocations: RainGaugeLocation[];

    public dateFormat = 'yyyy/MM/dd';
    public isAdmin: boolean;
    public isMonitorEditor = false;
    private subscriptions = new Subscription();
    private shouldPatchInstallationForm = false;

    public numericWith5DecimalPlaces = REGEX_CONFIG.numericWith5DecimalPlaces;
    public numericWith2DecimalPlaces = REGEX_CONFIG.numericWith2DecimalPlaces;

    public selectedRainGauge = '';
    public tagsAutocomplete: string[] = [];

    public MonitorSeriesNames = MonitorSeriesNames;

    public trackByIndex = TrackBy.byIndex;
    public trackByLocationId = TrackBy.byLocationId;
    public trackByEntityId = TrackBy.byEntityId;
    public trackById = TrackBy.byId;
    public trackByValue = TrackBy.byValue();
    public trackByKey = TrackBy.byUniqueKey<{ key: number }>()('key');
    public trackByMonitorSeriesId = TrackBy.byUniqueKey<MonitorAddLocationSelectable>()('monitorSeriesID');

    constructor(
        private dialog: MatDialog,
        private modemSetupService: ModemSetupService,
        private locationService: LocationService,
        private entityService: EntityService,
        private gisService: GISService,
        public translateService: TranslateService,
        private locationFormBuilder: LocationFormBuilder,
        private dateUtilService: DateutilService,
        private statusCodeService: StatusCodeService,
        private usersService: UsersService,
        public locationCardService: LocationCardService,
        public uiService: UiService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        const dateSubs = this.dateUtilService.dateFormat.subscribe((result) => {
            this.dateFormat = this.dateUtilService.getFormat();
        });

        if (this.data.editMode) {
            this.onCommunicationTypeChange({ value: this.details.communicationType } as any);
            this.form.get('status').enable();
        } else {
            this.form.patchValue({ installationDate: new Date() });
        }

        this.subscriptions.add(dateSubs);
        this.setMonitorSeries();
        this.bindProviders();
        this.getFlowEntities();

        for (let i = 1; i <= 48; i++) {
            this.locationMaintenanceHours.push(i);
        }

        if (this.data.rainGaugeLocations) {
            this.rainGaugeLocations = [...this.data.rainGaugeLocations.filter(v => v.status !== LocationStatus.Inactive || (RainUSGSLocationTypes.includes(v.seriesID)))];
            this.filteredAssignedRainGauge = [...this.rainGaugeLocations].sort(function(a, b) {
              return a.locationName.localeCompare(b.locationName)
            });
            const rainGaugeSubs = this.form.get('assignedRainGauge').valueChanges.subscribe((item: string) => {
                this.filterAssignedRainGauge(item);
            });

            this.subscriptions.add(rainGaugeSubs);
        }

        this.form.get('samplerate').setValidators([Validators.required, this.sampleRateValidator.bind(this)]);
        this.form.get('fastrate').setValidators([Validators.required, this.sampleRateValidator.bind(this)]);

        if (
            this.statusCodeService.userInfo.getValue().userRole &&
            this.statusCodeService.userInfo.getValue().userRole.length
        ) {
            this.isAdmin = this.statusCodeService.userInfo.getValue().userRole[0] !== 'None';
        }

        if (!this.usersService.isMonitorEditor.getValue()) {
            this.form.get('installationDate').disable();
        }

        const interval = this.form.get('maintenanceEnd').value;
        if (interval && this.timezone && this.timezone.value) {
            const difference = this.dateUtilService.getDifferenceByTimezone(this.timezone.value);
            const returnToActive =
                new Date(interval).getTime() +
                difference -
                this.dateUtilService.formatDateAsTimeZone(new Date()).getTime();
            const maintenanceInterval = Math.floor(returnToActive / 1000 / 60 / 60);
            this.form.patchValue({
                maintenanceEnd: this.locationCardService.convertDate(interval),
                maintenanceInterval: maintenanceInterval < 1 ? 2 : maintenanceInterval,
            });
        }

        setTimeout(() => {
            if (this.details && this.details.assignedRainGaugeLocationId) {
                const selectedRainGauge = this.data.rainGaugeLocations.find(
                    (v) => v.locationId === this.details.assignedRainGaugeLocationId,
                );
                this.form.patchValue({ assignedRainGauge: selectedRainGauge ? selectedRainGauge.locationName : null });
            }

            if (this.details && this.details.customProperties && this.details.customProperties.length) {
                this.details.customProperties.forEach(() => this.addNewTag());
                this.tags.patchValue(this.details.customProperties);
            }
        });
    }

    public editModemSetup() {
        this.dialog
            .open(ModemSetupComponent, {
                disableClose: true,
                data: '',
            })
            .afterClosed()
            .subscribe(() => {
                this.bindProviders();
            });
    }

    public onMoveIconClick() {
        this.uiService.enableMap();
        const coordsSubs = this.gisService.coordinatePickerFromMap
            .pipe(first())
            .subscribe((data: { lat: number; lon: number }) => {
                this.form.patchValue({ latitude: Number(data.lat.toFixed(4)), longitude: Number(data.lon.toFixed(4)) });
                this.form.markAsDirty();
                this.uiService.disableMap();
            });

        this.subscriptions.add(coordsSubs);
    }

    public rainGaugeSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedRainGauge = event.option.value;
    }

    public rainGaugeOpened() {
        this.selectedRainGauge = this.form.get('assignedRainGauge').value;
        this.form.patchValue({ assignedRainGauge: '' });
    }

    private bindProviders() {
        const modemProvider = this.form.get('apnProvider').value;

        this.modemSetupService.getAll().subscribe((res: ModemSetupItem[]) => {
            if (!res || !res.length) {
                return;
            }

            this.modemProviders = [];
            if (modemProvider && res.findIndex((x) => x.provider === modemProvider) === -1) {
                this.modemProviders.push({
                    id: -1,
                    name: modemProvider,
                });
            }
            this.modemProviders.push(
                ...res.map((m, index) => {
                    const item: Selectable = {
                        id: index,
                        name: m.provider,
                        isChecked: m.provider === modemProvider,
                    };

                    return item;
                }),
            );
        });
    }

    setMonitorSeries() {
        if (!this.data.editMode) {
            this.monitorSeries = <Array<MonitorAddLocationSelectable>>[
                {
                    monitorSeriesID: MonitorIdentifier.TritonPlus,
                    value: 'TRITON+',
                    text: this.translateService.instant('COMMON.TEST_TRI'),
                },
                {
                    monitorSeriesID: MonitorIdentifier.Echo,
                    value: 'ECHO',
                    text: this.translateService.instant('COMMON.TEST_ECHO'),
                },
                {
                    monitorSeriesID: MonitorIdentifier.RainAlertIII,
                    value: 'RAINALERT III',
                    text: this.translateService.instant('COMMON.TEST_RAIN'),
                },
                {
                    monitorSeriesID: MonitorIdentifier.ForeSITE_UL,
                    value: 'ForeSITE-UL',
                    text: this.translateService.instant('COMMON.TEST_ForeSITE_UL'),
                },
                {
                    monitorSeriesID: MonitorIdentifier.ForeSITE_XD,
                    value: 'FORESITE-XD',
                    text: this.translateService.instant('COMMON.TEST_ForeSITE_XD'),
                },
            ];
        } else {
            this.getMonitorSeries();
        }
    }

    getMonitorSeries() {
        this.locationService.getMonitorSeries().subscribe((monitors: MonitorSeriesUI[]) => {
            this.monitorSeries = monitors.map((monitor) => {
                return <MonitorAddLocationSelectable>{
                    monitorSeriesID: monitor.monitorSeriesID,
                    value: monitor.modelName.toUpperCase(),
                    text: monitor.modelName + '',
                };
            });

            if (this.form.get('series').value === 'USGS') {
                this.monitorSeries.push({ monitorSeriesID: 0, value: 'USGS', text: 'USGS' });
            }
            if (this.data.editMode && this.form.get('series').value) {
                this.onChangeMonitorSeries({ value: this.form.get('series').value } as MatSelectChange, false, true);
            }
        });
    }

    public ipAddressControlEnable(isEnabled: boolean) {
        const ipAddressControl = this.form.get('ipaddress');
        if (!isEnabled) {
            ipAddressControl.clearValidators();
            ipAddressControl.disable();
        } else {
            ipAddressControl.enable();
            ipAddressControl.setValidators([
                Validators.required,
                Validators.pattern(REGEX_CONFIG.ipaddressPattern),
                Validators.maxLength(100),
            ]);
        }
    }

    public onCommunicationTypeChange(event: MatSelectChange) {
        this.ipAddressControlEnable(Number(event.value) === 4);
    }

    public onInstallationTypeChange(event: MatSelectChange) {
        this.shouldPatchInstallationForm = true;
        //this.form.removeControl('installationForm');
        this.locationFormBuilder.addInstallationForm(this.form, event.value.series, this.locationCardService.isMetric);

        this.installationTypeSeries = event.value.series;

        this.installationSeriesChange.emit ({ value : this.installationTypeSeries }) ;

        if (event.value.value === CUSTOM_TYPE_VALUE) {
            this.form.get('installationForm').disable();
        }

        const installationShape = event.value.value.substr(event.value.value.indexOf(' ') + 1);
        if (event.value.series === 400 && (installationShape === '405' || installationShape === '406')) {
            this.form
                .get('installationForm')
                .get('width')
                .setValidators(InstallationTypeValidators(this.locationCardService.isMetric));
        } else if (event.value.series === 400) {
            this.form
                .get('installationForm')
                .get('width')
                .setValidators([Validators.maxLength(100), Validators.pattern(REGEX_CONFIG.installationValuePattern)]);
        }

        if (this.shouldPatchInstallationForm && this.data.editMode && this.details) {
            if (event.value.series === Series.ChannelStandard) {
                this.populateChannelForm();
            } else if (event.value.series === Series.Elevation) {
                this.populateElevationForm();
            } else {
                // #15820 - read field names differs from write field names
                if (this.details.designHC) this.details.hc = this.details.designHC;
                if (this.details.designSlope) this.details.slope = this.details.designSlope;
                if (this.details.designRoughness !== null && this.details.designRoughness !== undefined) this.details.roughness = this.details.designRoughness;
                this.form.get('installationForm').patchValue(this.details);
            }
            this.shouldPatchInstallationForm = false;
            if (event.value.series === 200) {
                this.pipeHeightChange.emit({ value: this.details.height, isMp2: false });
            }
        } else {
            const patch = {
                roughness: this.defaultRoughness,
                effectiveRoughness: this.defaultRoughness,
            };
            this.form.get('installationForm').patchValue(patch);
        }

        if(this.form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD || this.form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL)
        {
            (this.form.get('installationForm') as FormGroup)?.removeControl('numberOfEntries');
            (this.form.get('installationForm') as FormGroup)?.removeControl('entries');
        }


        if(this.form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD)
        {
            if( event.value.series !== Series.ChannelStandard){
                const isMetric = this.locationCardService.isMetric;
                if (!this.form.get('manholedepth')) {
                    this.form.addControl('manholedepth', this.fb.control(
                        null,
                        [
                            Validators.pattern(
                                isMetric
                                    ? REGEX_CONFIG.numericDecimal1PlacePattern
                                    : REGEX_CONFIG.numeric0_100000With2DecimalPlacesPattern,
                            ),
                            Validators.maxLength(50),
                        ],
                    ));
                    if(this.details && this.details.manholedepth)
                    {
                        this.form.get('manholedepth').patchValue(this.details.manholedepth);
                    }
                }
            }
            else
            {
                this.form.removeControl('manholedepth');
            }
        }

        if ((this.form.get('installationForm') as FormGroup).get('numberOfEntries')){
            (this.form.get('installationForm') as FormGroup).get('numberOfEntries').updateValueAndValidity();
        }

        this.form.patchValue({ installationShapeType: event.value.value.match(/(\d+)/)[0] });
    }

    public onInstallationDateChange(event) {
        if (event.target) {
            if (event.target.value) {
                if (typeof event.target.value === 'string' && !Number.isNaN(Date.parse(event.target.value))) {
                    this.form.patchValue({ installationDate: new Date(event.target.value) });
                    this.form.markAsDirty();
                } else if (event.target.value.getTime && !isNaN((event.target.value as Date).getTime())) {
                    this.form.patchValue({ installationDate: event.target.value as Date });
                } else {
                    this.form.patchValue({ installationDate: null });
                }
            } else {
                this.form.patchValue({ installationDate: null });
                this.form.markAsDirty();
            }
        }
    }

    public capitalizeValue(key: string) {
        const value = this.form.get(key).value;
        this.form.patchValue({ [key]: StringUtils.capitalizedStringWithSpace(value) });
    }

    public removeSpaces(key: string) {
        let value = this.form.get(key).value;
        this.form.patchValue({ [key]: value.replace(/\s/g, '') });
    }

    public clearAssignedRainGauge(event: PointerEvent) {
        this.selectedRainGauge = '';
        this.form.patchValue({ assignedRainGauge: null });
        this.form.markAsDirty();
        event.stopPropagation();
    }

    public onLocationNameChange() {
        const locationName = this.form.get('locationName').value.trim();

        this.form.patchValue({ locationName: locationName.charAt(0).toUpperCase() + locationName.slice(1) });
    }

    public get tags() {
        return this.form.get('customProperties') as FormArray;
    }

    public addNewTag() {
        this.tags.push(this.fb.group({
            key: ['', [Validators.required, Validators.maxLength(128)]],
            value: ['', [Validators.required, Validators.maxLength(128)]]
        }));

        this.tags.controls[this.tags.controls.length - 1].markAllAsTouched();
    }

    public deleteTag(index: number) {
        this.tags.removeAt(index);
        this.form.markAsDirty();

        this.validateTags();
    }

    public onTagInput(data: string, index: number) {
        const allTags = this.tags.value.map(v => v.key).filter((v, i) => i !== index);
        const autocompleteTags = Array.from(new Set([...this.customProperties, ...allTags]));

        this.tagsAutocomplete = autocompleteTags.filter(v => v.toLowerCase().includes(data.toLowerCase()));

        this.validateTags();
    }

    public onTagSelected(value: string, index: number) {
        this.tags.controls[index].get('key').setValue(value);

        this.validateTags();
    }

    public validateTags() {
        const allTags: string[] = this.tags.value.map(v => v.key);

        this.tags.controls.forEach((control: AbstractControl, index: number) => {
            const keyControl = control.get('key');

            const isDuplicate = index !== allTags.findIndex(v => v === control.value.key);
            const isEmpty = control.getError('required');
            const isMaxLengthExceeded = keyControl.value.length > 128;

            let errors = null;

            if (isEmpty) {
                errors = { required: true };
            }
            if (isDuplicate) {
                errors = { ...errors, unique: true };
            }
            if (isMaxLengthExceeded) {
                errors = { ...errors, maxlength: true };
            }

            keyControl.setErrors(errors);
        });
    }

    public onChangeMonitorSeries(monitorName: MatSelectChange, isManual = false, firstCall = false): Observable<unknown> {
        if (!monitorName) {
            return;
        }
        this.selectedMonitorSeries = monitorName.value;

        this.form.removeControl('installationForm');

        this.monitorSeriesChange.emit(monitorName.value);
        this.form.get('installationType').enable();


        // #34913 for ForeSITE-UL we make some adjustments to form
        if (monitorName.value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || monitorName.value?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD) {
            this.onForeSiteSeriesSelect(isManual);
        } else {
            this.onNonForeSiteSeriesSelect(firstCall);
        }

        if (this.monitorSeries && this.monitorSeries.length) {
            const installationId = this.monitorSeries.find(
                (monitor: MonitorAddLocationSelectable) =>
                    monitor.text.toUpperCase() === monitorName.value.toUpperCase(),
            ).monitorSeriesID;

            this.locationService.getInstallationType(installationId).subscribe((types: Array<InstallationType>) => {
                this.installationTypes = types;

                const installationTypeFormValue = this.form.get('installationType');

                if (installationTypeFormValue.value && monitorName.value?.toUpperCase()  !== MonitorSeriesNames.ForeSITE_UL) {
                    const installationType = this.pickInstallationType(installationTypeFormValue, types);
                    if (installationType) {
                        this.form.patchValue({ installationType });
                        this.shouldPatchInstallationForm = true;
                        this.onInstallationTypeChange({ value: installationType } as MatSelectChange);
                    }
                }

                if (monitorName.value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL) {
                    const installationType = this.pickInstallationType(installationTypeFormValue, types);

                    if (installationType) {
                        this.form.get('installationType').patchValue(installationType);
                        this.shouldPatchInstallationForm = true;
                        this.onInstallationTypeChange({ value: installationType } as MatSelectChange);
                    }
                }


                if (monitorName.value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || monitorName.value?.toUpperCase() === MonitorSeriesNames.ForeSITE_XD) {
                    (this.form.get('installationForm') as FormGroup)?.removeControl('numberOfEntries');
                    (this.form.get('installationForm') as FormGroup)?.removeControl('entries');
                }
            });
        }

        const isRainAlertPicked =
            monitorName.value.toUpperCase() === RAINALERT_SERIES || monitorName.value.toUpperCase() === 'RAINALERT II';

        this.locationFormBuilder.enableDisableControls(this.form, isRainAlertPicked, [
            'assignedRainGauge',
            'manholedepth',
        ]);

        const ms = monitorName.value;
        const isOtherMonitorsPicked =
            ms !== MonitorSeriesNames.triton && ms !== MonitorSeriesNames.rainAlert && ms !== MonitorSeriesNames.echo;

        const shouldDisableSampleRate = isOtherMonitorsPicked && ms?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && ms?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD;

        this.locationFormBuilder.enableDisableControls(this.form, shouldDisableSampleRate, ['samplerate']);
        this.locationFormBuilder.enableDisableControls(this.form, isOtherMonitorsPicked, ['fastrate']);

        if (ms.toUpperCase() === MonitorSeriesNames.echo && !this.data.editMode) {
            this.form.patchValue({ qFinalEntityID: 3307 });
        }
        if (ms.toUpperCase() === MonitorSeriesNames.echo) {
            this.form
                .get('manholedepth')
                .setValidators([
                    Validators.required,
                    Validators.pattern(
                        this.locationCardService.isMetric
                            ? REGEX_CONFIG.numericDecimal1PlacePattern
                            : REGEX_CONFIG.numeric0_100000With2DecimalPlacesPattern,
                    ),
                    Validators.maxLength(50),
                ]);
        } else if (this.form.get('manholedepth')) {
            this.form
                .get('manholedepth')
                .setValidators([
                    Validators.pattern(
                        this.locationCardService.isMetric
                            ? REGEX_CONFIG.numericDecimal1PlacePattern
                            : REGEX_CONFIG.numeric0_100000With2DecimalPlacesPattern,
                    ),
                    Validators.maxLength(50),
                ]);
        }

        if (this.form.get('manholedepth')) {
            this.form.get('manholedepth').updateValueAndValidity();
        }

        if (isRainAlertPicked) {
            this.form.updateValueAndValidity;
            this.form.get('installationType').clearValidators();
            this.form.removeControl('installationForm');
        } else if (monitorName.value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && monitorName.value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_XD) {
            this.form.get('installationType').setValidators(Validators.required);

            if (!this.form.get('installationForm') && isManual) {
                this.form.get('installationType').setValue('');
            }
        }
        this.form.get('installationType').updateValueAndValidity();
    }

    // #34913 need to revert changes made when Fore Site series was picked
    private onNonForeSiteSeriesSelect(firstCall = false) {
        this.form.get('serialnumber').setValidators([Validators.required, Validators.maxLength(10), Validators.pattern(REGEX_CONFIG.numericOnlyPattern)]);
        const isMetric = this.locationCardService.isMetric;

        if (!this.form.get('manholedepth')) {
            this.form.addControl('manholedepth', this.fb.control(
                null,
                [
                    Validators.pattern(
                        isMetric
                            ? REGEX_CONFIG.numericDecimal1PlacePattern
                            : REGEX_CONFIG.numeric0_100000With2DecimalPlacesPattern,
                    ),
                    Validators.maxLength(50),
                ],
            ));

            this.form.addControl('synctime', this.fb.control(true));
            this.form.addControl('usedaylightsaving', this.fb.control(true));
        }

        if (!firstCall) {
            this.ipAddressControlEnable(true);
        }

        if (this.form.get('communicationType').value === LocationCommunicationType.External) {
            this.form.get('communicationType').setValue(LocationCommunicationType.Wireless);
        }
    }

    // #34913 for ForeSITE-UL we make some adjustments to form
    private onForeSiteSeriesSelect(isManual: boolean) {
        this.form.get('serialnumber').setValidators([Validators.required, Validators.maxLength(10), Validators.pattern(REGEX_CONFIG.alphaNumericPattern)]);
        this.form.removeControl('manholedepth');
        this.form.removeControl('synctime');
        this.form.removeControl('usedaylightsaving');

        if (isManual) {
            this.ipAddressControlEnable(false);
            this.form.get('communicationType').setValue(LocationCommunicationType.External);
        }

        // #37470 when switching from non fore site monitor with samplerate set higher than 4hours, we need to null the samplerate
        // because for foresites 4 hours is the max value for samplerate
        const sampleRateValue = this.foreSiteRateList.find(val => val.value === this.form.get('samplerate').value)?.value;
        this.form.patchValue({ samplerate: sampleRateValue || null });
    }

    private pickInstallationType(control: AbstractControl, types: InstallationType[]) {
        const channel = types.find(v => v.value.includes('Channel'));
        const elevation = types.find(v => v.value.includes('Elevation: 1000'));

        if (typeof control.value === 'string' && control.value.includes('10: 1000')) {
            return elevation;
        }
        if (typeof control.value === 'string') {
            return control.value.includes('Channel') ? channel : types.find(v => v.value === control.value);
        }

        if (channel === undefined) {
            return types[0];
        }

        if (control.value.value.includes('Channel')) {
          return channel;
        }

        return types.find((v) => v.value === control.value.value);
    }

    private populateChannelForm() {
        const installationForm = this.form.get('installationForm');
        const numberOfEntries = this.details && this.details.entries ? this.details.entries.length : 0;

        for (let i = 0; i < numberOfEntries; i++) {
            (installationForm.get('entries') as FormArray).push(this.locationFormBuilder.generateEntitiesInfo(this.locationCardService.isMetric));
        }

        const patch = {
            numberOfEntries: numberOfEntries,
            entries: this.details.entries ?? [],
            range: this.details.range ?? 0
        };
        
        installationForm.patchValue(patch);
    }

    private populateElevationForm() {
        const installationForm = this.form.get('installationForm');
        const patch = {
            referencePoint: this.details.referencePoint ? this.details.referencePoint : 0,
            datum: this.details.datum ? this.details.datum : '',
            physicalOffset: this.details.physicalOffset ? this.details.physicalOffset : 0
        };

        installationForm.patchValue(patch);
    }

    private filterAssignedRainGauge(searchValue: string) {
        if (!searchValue) {
            this.filteredAssignedRainGauge = [...this.rainGaugeLocations].sort(function(a, b) {
              return a.locationName.localeCompare(b.locationName)
            });
            return;
        }

        this.filteredAssignedRainGauge = this.rainGaugeLocations.filter((rainGauge) =>
            rainGauge.locationName.toLowerCase().startsWith(searchValue.toLowerCase()),
        );
        this.filteredAssignedRainGauge = this.filteredAssignedRainGauge.sort(function(a, b) {
          return a.locationName.localeCompare(b.locationName)
        });

    }

    private getFlowEntities() {
        const locationId = this.data.locationDetails.locationID || 0;

        this.entityService.getEntitiesByLocation(this.data.customerId, [locationId]).subscribe((res) => {
            this.flowEntities = res.filter(
                (o) =>
                    o.displayGroup === FLOW_DISPLAY_GROUP && o.entityName !== 'QFINAL' && o.entityName !== 'QUANTITY',
            );
        });
    }

    // #22722 Sample Rates - clear validation on opposite field
    public resetSampleRates(isFastControl: boolean) {
        if(isFastControl) {
            const sampleControl = this.form.get('samplerate');
            sampleControl.reset(sampleControl.value);
        } else {
            const fastControl = this.form.get('fastrate');
            fastControl.reset(fastControl.value);
        }
    }

    private sampleRateValidator(): {[key: string]: boolean} | null {
        const fastControl = this.form.get('fastrate');
        const sampleControl = this.form.get('samplerate');

        if (fastControl.value === null || sampleControl.value === null) {
          return null;
        }

        if (fastControl.value > sampleControl.value) {
          return { rateError: true };
        }

        return null;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    validateSerialNumber(object) {
        const value = this.form.get(object).value;
        this.form.patchValue({ [object]: value !== '' ? parseInt(value.replace(/[^0-9]/g, '')): '' });
    }
}
