import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DataTableArgs, DataTableResponse } from 'app/pages/data-editing-table/data-editing-table.model';
import { SelectableGroup } from 'app/shared/models/selectable';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { HydrographNEWService } from 'app/shared/services/hydrographNEW.service';

import { ViewDataService } from 'app/shared/services/view-data.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { SILT_ENTITY } from '../../../../shared/constant';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
  selector: 'app-min-max-avg-total-table',
  templateUrl: './min-max-avg-total-table.component.html',
  styleUrls: ['./min-max-avg-total-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinMaxAvgTotalTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public customerId: number;
    @Input() public locationId: number;
    @Input() public start: Date;
    @Input() public end: Date;
    @Input() public entityIds: Array<number>;
    @Input() public summarizeInterval: number;

    private subscriptions: Subscription[] = [];

    public isExpanded = false;
    public isLoading = true;

    public data: DataTableResponse;

    public customDateFormat: string;
    private previousArgs?: DataTableArgs;
    private previousLocationId: number;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private viewDataService: ViewDataService,
        private dateutilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private hydrographNewService: HydrographNEWService,
        private dataEditService: DataEditService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.isExpanded = false;
        this.isLoading = false;

        this.subscriptions.push(this.dateutilService.timeFormat.subscribe((value) => {
            const dateFormat = this.dateutilService.getFormat();
            const is12HourFormat = value !== 'hh:mm:ss';
            this.customDateFormat = is12HourFormat
                ? `${dateFormat} 'at' ${'hh:mm a'}`
                : `${dateFormat} 'at' ${'HH:mm:ss'}`;
        }));

        this.subscriptions.push(this.dataEditService.siltLevelEdited.subscribe((value) => {
            if (this.isExpanded) {
                const args: DataTableArgs = {
                    start: this.start,
                    end: this.end,
                    entityIds: this.entityIds.filter(x => x !== SILT_ENTITY),
                    summarizeInterval: this.summarizeInterval,
                    paging: {
                        pageSize: 1000,
                        startPage: 1
                    }
                }
                this.getDataEditingTable(args);
            }
        }));

        this.subscriptions.push(this.hydrographNewService.entityColorsSubscription().subscribe(
            () => {
                if(!this.data || !this.data.statistics || !this.data.statistics.length) return;

                this.data.statistics.forEach((row) => {
                    row.backgroundColor = this.hydrographNewService.entityColorGet(row.eid);
                    row.fontColor = this.uiUtilsService.calculateFontColor(row.backgroundColor);
                });

                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        ));

    }

    ngOnChanges(changes: SimpleChanges): void {
        let reloadTable = false;

        if(changes && changes.locationId && changes.locationId.currentValue) {
            reloadTable = true;
        }

        if(changes && changes.entityIds && changes.entityIds.currentValue) {
            reloadTable = true;
        }

        if(changes && changes.start && changes.start.currentValue) {
            reloadTable = true;
        }

        if(changes && changes.end && changes.end.currentValue) {
            reloadTable = true;
        }

        if(reloadTable) {
            this.expandTable();
        }
    }


    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }


    public expandTable() {
        if(this.previousArgs) {
            if(
                this.previousLocationId === this.locationId
                && this.previousArgs.start.getTime() === this.start.getTime()
                && this.previousArgs.end.getTime() === this.end.getTime()
                && _.isEqual(this.previousArgs.entityIds, this.entityIds)
                && this.previousArgs.summarizeInterval === this.summarizeInterval
            ) {
                // Nothing change, do not make call
                return;
            }
        }

        if(!this.isExpanded || !this.entityIds || !this.entityIds.length) {
            this.data = null;
            return;
        }

        if(!this.locationId) {
            return;
        }

        if(!this.start || !this.end) {
            return;
        }

        this.isLoading = true;

        const args: DataTableArgs = {
            start: this.start,
            end: this.end,
            entityIds: this.entityIds.filter(x => x !== SILT_ENTITY),
            summarizeInterval: this.summarizeInterval,
            paging: {
                pageSize: 1000,
                startPage: 1
            }
        }

        this.previousArgs = args;
        this.previousLocationId = this.locationId;
        this.data = null;

        this.getDataEditingTable(args);
    }

    private getDataEditingTable(args: DataTableArgs) {
        this.viewDataService.getDataEditingTable(this.customerId, this.locationId, args).subscribe(
            (res) => {
                if(res && res.statistics) {
                    const statistics = [];

                    this.data = res;

                    res.statistics.forEach((row) => {
                        const entityGroup = this.data.displayGroups.find((dg) => dg.entities.find((e) => e.entityId === row.eid));
                        const entity = entityGroup.entities.find((e) => e.entityId === row.eid);

                        row.name = entity.name;

                        const isA = row.a !== undefined && row.a !== null;
                        const isT = row.t !== undefined && row.t !== null;

                        const isRain = row.name && row.name.toUpperCase().includes('RAIN');

                        const isMn = row.mn !== undefined && row.mn !== null;
                        const isMx = row.mx !== undefined && row.mx !== null;

                        if((!isRain && isA) || isRain || (!isRain && (isMn || isMx))) {
                            row.precision = entityGroup.precision;
                            row.totalUnit = entityGroup.totalUnit;
                            row.unit = entityGroup.unit;
                            row.backgroundColor = this.hydrographNewService.entityColorGet(row.eid);
                            row.fontColor = this.uiUtilsService.calculateFontColor(row.backgroundColor);
                            statistics.push(row);
                        }
                    })

                    this.data.statistics = statistics;
                    this.isLoading = false;

                    this.data.statistics.forEach((row) => {
                    });

                    const sortAssocTable = [];
                    this.data.displayGroups.forEach(dg => {
                        dg.entities.forEach(e => {
                            sortAssocTable[e.entityId] = dg.sort;
                        })
                    })

                    this.data.statistics = this.data.statistics.sort(
                        (d1, d2) => {
                            const s1 = sortAssocTable[d1.eid] ? sortAssocTable[d1.eid] : 9999;
                            const s2 = sortAssocTable[d2.eid] ? sortAssocTable[d2.eid] : 9999;

                            return s1 > s2 ? 1 : s1 === s2 ? 0 : -1;
                        }
                    );
                } else {
                    this.data = null;
                }

                this.isLoading = false;

                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            () => {
                this.isLoading = false;
            }
        );
    }

}
