<p class="approve-label-highlight" *ngIf="!prevApprStartDate && prevApprEndDate">
    {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.PREV_APPROVAL' | translate }} 
    {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.THROUGH' | translate }} 
    {{ prevApprEndDate | date: customerDateFormat }}  
    {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.BY' | translate }} 
    {{ approvedBy }}
</p>
<p class="approve-label-highlight" *ngIf="prevApprStartDate && prevApprEndDate">
    {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.PREV_APPROVAL' | translate }} 
    {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.FROM' | translate }} 
    {{ prevApprStartDate | date: customerDateFormat }}  
    {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.THROUGH' | translate }}
    {{ prevApprEndDate | date: customerDateFormat }} 
    {{ 'LOCATION_DASHBOARD.DATA_APPROVAL.BY' | translate }} 
    {{ approvedBy }}
</p>
<div>
    <mat-form-field class="width-40-p">
        <mat-label>{{ 'LOCATION_DASHBOARD.DATA_APPROVAL.START_DATE' | translate  }}</mat-label>
            <input
                #startDateInput
                matInput
                matTooltip="{{ 'LOCATION_DASHBOARD.DATA_APPROVAL.START_DATE_TOOLTIP' | translate }}"
                [disabled]="disabled"
                [max]="today"
                placeholder="{{ 'LOCATION_DASHBOARD.DATA_APPROVAL.OPTIONAL_DATE' | translate }}"
                (change)="eventStartChanged($event)"
                [value]="startDate | date: customerDateFormat"
                class="datepickerStyles"
                appDatetimepickerPosition
                [matDatetimepickerComp]="eventStartDatePicker"
            />
            <input
                hidden
                [max]="today"
                [matDatetimepicker]="eventStartDatePicker"
                name="eventStart"
                [ngModel]="startDate"
                (dateChange)="eventStartChanged($event)"
            />
        <mat-datetimepicker-toggle [disabled]="disabled" matSuffix [for]="eventStartDatePicker">
        </mat-datetimepicker-toggle>
        <mat-datetimepicker
                #eventStartDatePicker
                timeInterval="1"
                [type]="'datetime'"
        >
        </mat-datetimepicker>
    </mat-form-field>

    <mat-form-field class="width-40-p pad-l-100">
        <mat-label>{{ 'LOCATION_DASHBOARD.DATA_APPROVAL.END_DATE' | translate }}</mat-label>
            <input
                    matInput
                    [disabled]="disabled" 
                    [max]="today"
                    (change)="eventEndChanged($event)"
                    [value]="endDate | date: customerDateFormat"
                    class="datepickerStyles"
                    appDatetimepickerPosition
                    [matDatetimepickerComp]="eventEndDatePicker"
                />
            <input
                    hidden
                    [max]="today"
                    [matDatetimepicker]="eventEndDatePicker"
                    name="eventEnd"
                    [ngModel]="endDate"
                    (dateChange)="eventEndChanged($event)"
                />
        <mat-datetimepicker-toggle [disabled]="disabled"  matSuffix [for]="eventEndDatePicker">
        </mat-datetimepicker-toggle>
        <mat-datetimepicker
                    #eventEndDatePicker
                    [type]="'datetime'"
                >
        </mat-datetimepicker>
    </mat-form-field>
    <div class="mat-error" *ngIf="isDateError">
        <span>{{'LOCATION_DASHBOARD.DATA_EDITING.START_DATE_RELATIVE_INVALID_MSG' | translate}}</span>
    </div>
    <div class="mat-error" *ngIf="isEndDateError">
        <span>{{'COMMON.END_DATE_REQUIRED' | translate}}</span>
    </div>
</div>