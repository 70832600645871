import { Component, OnInit, ChangeDetectorRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { OrderByPipe } from '../../shared/pipes/order-by-pipe';
import { Subscription } from 'rxjs';
import { FilterDataSource } from '../../shared/components/paging/filter-data-source';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../admin/users.service';
import { SharedService } from 'app/shared/services/shared.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { Selectable } from 'app/shared/models/selectable';
import { ScheduleCollection } from 'app/shared/models/schedule-collection';
import {
    IComponentCustomizedConfirmation,
    IComponentCustomizedConfirmationResult,
} from 'app/shared/models/customized-confirmation-box';
import { customerLocationGroupQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-collection-widget-schedule',
    templateUrl: './collection-widget-schedule.component.html',
    styleUrls: ['./collection-widget-schedule.component.scss'],
})
export class CollectionWidgetScheduleComponent implements OnInit, OnDestroy {
    public pagedItems: Array<LocalLocation>;
    public uuid: UUID;
    public guid: UUID;
    public hideCreateButton: boolean;
    public showCreateSchedule: boolean;
    public listOfDays: Array<ListOfDaysItem>;
    public scheduleName: string;
    public frequency: number;
    public alarmingFrequency: number;
    public collectionAlarmingFrequency: Selectable;
    public actionSaveEdit: string;
    public listOfHours: Array<object>;
    public alarmFrequencyItems: Array<Selectable>;
    public scheduleCollectionHeader = 'Scheduled Collects Editor';
    public schedules: Array<ScheduleCollection>;
    public setFirstPage: boolean;
    public localScheduleCollection: Array<LocalScheduleCollection>;
    public schedulePagedItems: Array<LocalScheduleCollection>;
    public disableSave: boolean;
    public selectedLocations = new Array<Selectable>();
    public locations = new Array<Selectable>();
    public isFormPristine: boolean;
    public collectionScheduleLoadingState: boolean;

    private subscriptions = new Array<Subscription>();
    private scheduleCollection: ScheduleCollection;
    private scheduleIdUpdate: number;
    private customerID: number;
    public locationGroupId: number;

    public localScheduleDisplayedColumns = ['name', 'editDeleteScheduleCollection'];
    public localScheduleFilterColumns = ['name'];
    public localScheduleDataSource: FilterDataSource | null;
    @ViewChild(MatPaginator) public localSchedulePaginator: MatPaginator;
    @ViewChild(MatSort) public localScheduleSort: MatSort;
    public localScheduleDataChange: BehaviorSubject<LocalScheduleCollection[]> = new BehaviorSubject<
        LocalScheduleCollection[]
    >([]);
    public monitorLocation = new Array<Selectable>();
    public get localScheduleData(): LocalScheduleCollection[] {
        return this.localScheduleDataChange.value;
    }
    public totalPaginationLength: number;
    /**
     * Reg Ex. Pattern for alpha numeric
     */
    public alphaNumericWithSpecialChars = REGEX_CONFIG.alphaNumericWithSpecialChars;

    /**
     * falg to show duplicate schedule name
     */
    public isDuplicateScheduleName: boolean;

    /**
     * retain schedule name to edit
     */
    public oldScheduleName: string;

    /**
     * Represents inpus for Confirmation Box
     */
    public conformationDataInput: IComponentCustomizedConfirmation;

    /**
     * Represents show Confirmation box flag
     */
    public showConfirmation: boolean;

    public selectableLocations: Array<Selectable>;

    /**
     * Represents Selected Schedule Collection
     */
    private selectedScheduleCollection: ScheduleCollection;

    /**
     * checks confirmation Type i.e Update , Delete etc
     */
    public confirmationType: string;

    /**
     * Represents snackbar message
     */
    public addScheduleText: string;

    /**
     * Represents snackbar message
     */
    public scheduleCollectEditor: string;

    /**
     * Represents snackbar message
     */
    public editCollectSchedule: string;

    /**
     * Represents snackbar message
     */
    public scheduleCreateSuccess: string;

    /**
     * Represents snackbar message
     */
    public scheduleExists: string;

    /**
     * Represents snackbar message
     */
    public deleteConfirmMsg: string;

    /**
     * Represents snackbar message
     */
    public updateConfirmMsg: string;

    /**
     * Represents snackbar message
     */
    public scheduleDeleteSuccess: string;

    /**
     * Represents snackbar message
     */
    public scheduleUpdateSuccess: string;

    /**
     * Represents snackbar dismiss button text
     */
    public dismissBtn: string;
    /**
     * Represents snackbar proceed button text
     */
    public proceedBtn: string;
    /**
     * Represents snackbar cancel button text
     */
    public cancelBtn: string;
    /**
     * Represents snackbar delete button text
     */
    public deleteBtn: string;
    /**
     * Represents snackbar delete dialog title text
     */
    public deleteTitle: string;
    /**
     * Represents snackbar update dialog title text
     */
    public updateDialogTitle: string;

    public hourlyText: string;
    public hourText: string;
    public minutesText: string;

    public trackByApiValue = TrackBy.byUniqueKey<ListOfDaysItem>()('apiValue');
    constructor(
        private scheduleCollectionService: ScheduleCollectionService,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private sharedService: SharedService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CollectionWidgetScheduleComponent>,
        private activatedRoute: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private usersService: UsersService,
        private snackBarNotificationService: SnackBarNotificationService,
    ) {}

    public ngOnInit() {
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                this.customerID = Number(params.get(customerQueryParam));
                this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
            }),
        );
        this.translate.get('HOME.WIDGET_SCHEDULE.HOURLY_TEXT').subscribe((res: string) => {
            this.hourlyText = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.MINUTES_TEXT').subscribe((res: string) => {
            this.minutesText = res;
        });
        this.translate.get('VAULT.VAULT_ITEM_SHARE.HOURS_TEXT').subscribe((res: string) => {
            this.hourText = res;
        });
        // configure location drop down options
        this.setupLocationDropdown();

        this.collectionScheduleLoadingState = false;
        const filterPipe = new OrderByPipe();
        this.actionSaveEdit = 'Save';
        this.uuid = UUID.UUID();
        this.guid = UUID.UUID();
        this.schedules = this.data.schedules;
        this.localScheduleCollection = this.schedules;
        this.localScheduleCollection = this.createSchedules(this.schedules);
        this.localScheduleCollection = filterPipe.transform(this.localScheduleCollection, 'name', false);
        this.generateCollectionWidgetScheduleTable();
        this.listOfDays = [
            { text: 'S', isSelected: false, apiValue: 0 },
            { text: 'M', isSelected: false, apiValue: 1 },
            { text: 'T', isSelected: false, apiValue: 2 },
            { text: 'W', isSelected: false, apiValue: 3 },
            { text: 'T', isSelected: false, apiValue: 4 },
            { text: 'F', isSelected: false, apiValue: 5 },
            { text: 'S', isSelected: false, apiValue: 6 },
        ];

        this.listOfHours = [
            { text: this.hourlyText, value: 1 },
            { text: '4 ' + this.hourText, value: 4 },
            { text: '6 ' + this.hourText, value: 6 },
            { text: '8 ' + this.hourText, value: 8 },
            { text: '12 ' + this.hourText, value: 12 },
            { text: '24 ' + this.hourText, value: 24 },
        ];
        this.alarmFrequencyItems = [
            { name: '15 ' + this.minutesText, id: 15 },
            { name: this.hourlyText, id: 1 },
            { name: '4 ' + this.hourText, id: 4 },
            { name: '6 ' + this.hourText, id: 6 },
            { name: '8 ' + this.hourText, id: 8 },
            { name: '12 ' + this.hourText, id: 12 },
        ];

        this.translate.get('HOME.WIDGET_SCHEDULE.ADD_SCHEDULE').subscribe((res: string) => {
            this.addScheduleText = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.SCHEDULE_COLLECT_EDITOR').subscribe((res: string) => {
            this.scheduleCollectEditor = res;
            this.scheduleCollectionHeader = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.EDIT_COLLECT_SCHEDULE').subscribe((res: string) => {
            this.editCollectSchedule = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.SCHEDULE_CREATE_SUCCESS').subscribe((res: string) => {
            this.scheduleCreateSuccess = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.SCHEDULE_EXISTS').subscribe((res: string) => {
            this.scheduleExists = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.DELETE_CONFIRM_MSG').subscribe((res: string) => {
            this.deleteConfirmMsg = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.UPDATE_CONFIRMATION_MSG').subscribe((res: string) => {
            this.updateConfirmMsg = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.SCHEDULE_DELETE_SUCCESS').subscribe((res: string) => {
            this.scheduleDeleteSuccess = res;
        });
        this.translate.get('HOME.WIDGET_SCHEDULE.SCHEDULE_UPDATE_SUCCESS').subscribe((res: string) => {
            this.scheduleUpdateSuccess = res;
        });

        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissBtn = res;
        });
        this.translate.get('COMMON.PROCEED_BTN').subscribe((res: string) => {
            this.proceedBtn = res;
        });
        this.translate.get('COMMON.CANCEL_BUTTON').subscribe((res: string) => {
            this.cancelBtn = res;
        });
        this.translate.get('COMMON.DELETE_TOOLTIP').subscribe((res: string) => {
            this.deleteBtn = res;
        });
        this.translate.get('COMMON.DELETE_TITLE').subscribe((res: string) => {
            this.deleteTitle = res;
        });
        this.translate.get('COMMON.UPDATE_CONFIRMATION_TITLE').subscribe((res: string) => {
            this.updateDialogTitle = res;
        });
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public generateCollectionWidgetScheduleTable() {
        this.localScheduleDataSource = null;
        this.localScheduleDataChange = new BehaviorSubject<LocalScheduleCollection[]>([]);
        if (this.localScheduleCollection.length > 0) {
            //  for (let scheduleCollection of this.localScheduleCollection) {
            this.localScheduleCollection.forEach((scheduleCollection, index) => {
                const locationGroupCopiedData = this.localScheduleData.slice();
                // Flag to show the edited row
                let highLight = false;

                if (this.scheduleName !== null && this.scheduleName === scheduleCollection.name) {
                    const pageIndex = index / this.localSchedulePaginator.pageSize;
                    const finalPageIndex = +pageIndex.toString().split('.')[0];
                    this.localSchedulePaginator.pageIndex = finalPageIndex;
                    highLight = true;
                }
                const data = {
                    name: scheduleCollection.name,
                    scheduleId: scheduleCollection.scheduleId,
                    frequency: scheduleCollection.frequency,
                    alarmingFrequency: scheduleCollection.alarmingFrequency,
                    locations: scheduleCollection.locations,
                    collectionDays: scheduleCollection.collectionDays,
                    isSelected: highLight,
                };
                locationGroupCopiedData.push(data);
                this.localScheduleDataChange.next(locationGroupCopiedData);
            });
            // to highlight the newly/modified record
            if (this.scheduleName) {
                this.localScheduleData.find((x) => x.name === this.scheduleName).isSelected = true;
            }
            this.localScheduleDataSource = new FilterDataSource(
                this.localScheduleDataChange,
                this.localScheduleData,
                this.localSchedulePaginator,
                this.localScheduleSort,
                this.localScheduleFilterColumns,
            );
            this.totalPaginationLength = this.localScheduleData.length;
        }
        // else {
        // this.totalPaginationLength = 1;
        // }
    }

    public emitScheduleCollection() {
        this.data.locations.forEach((element) => {
            if (element && element.isChecked) {
                delete element.isChecked;
            }
        });
        this.dialogRef.close({ success: false });
    }

    public selectedNewLocations(locations) {
        this.selectLocations(locations.filter((x) => x.isChecked));
        this.isFormPristine = false;
        this.disableSave = this.validateForm();
    }

    private selectLocations(locations?: Array<Selectable>) {
        if (!locations || (locations && locations.length === 0)) {
            this.selectedLocations = new Array<Selectable>();
            this.selectableLocations.forEach((location) => {
                location.isChecked = false;
            });
            return;
        }
        this.selectableLocations.forEach((location) => {
            location.isChecked = locations.some((x) => x.id === location.id);
        });
        this.selectedLocations = locations;
    }

    public createScheduleCollection() {
        this.disableSave = this.validateForm();
        this.selectLocations();
        this.showCreateSchedule = true;
        this.actionSaveEdit = 'Save';
        this.deselectDays();
        this.scheduleName = '';
        this.frequency = null;
        this.alarmingFrequency = null;
        this.collectionAlarmingFrequency = null;
        this.scheduleCollectionHeader = this.addScheduleText;
    }

    private deselectDays() {
        this.listOfDays = [
            { text: 'S', isSelected: false, apiValue: 0 },
            { text: 'M', isSelected: false, apiValue: 1 },
            { text: 'T', isSelected: false, apiValue: 2 },
            { text: 'W', isSelected: false, apiValue: 3 },
            { text: 'T', isSelected: false, apiValue: 4 },
            { text: 'F', isSelected: false, apiValue: 5 },
            { text: 'S', isSelected: false, apiValue: 6 },
        ];
    }

    private contains(arr, element) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === element) {
                return true;
            }
        }
        return false;
    }

    public exitScheduleCollection(evt) {
        this.isDuplicateScheduleName = false;
        this.showCreateSchedule = false;
        this.hideCreateButton = false;
        this.setFirstPage = true;
        evt.preventDefault();
        this.scheduleCollectionHeader = this.scheduleCollectEditor;
    }

    public validateDateSelection(day: ListOfDaysItem) {
        this.isFormPristine = false;
        this.disableSave = this.validateForm();
    }

    private validateForm() {
        const selectedDays = this.listOfDays.filter((x) => x.isSelected);
        if (!selectedDays || selectedDays.length === 0) {
            return true;
        }
        if (!this.selectedLocations || this.selectedLocations.length === 0) {
            return true;
        }
        return false;
    }

    public alarmingFrequencySelected(event) {
        this.isFormPristine = false;
        if (event.length === 0) {
            this.alarmingFrequency = null;
            return;
        }
        this.alarmingFrequency = event[0].id;
        this.disableSave = this.validateForm();
    }

    public updateScheduleCollection(scheduleCollection: any) {
        this.isFormPristine = true;
        this.selectLocations(scheduleCollection.locations);
        this.showCreateSchedule = true;
        this.hideCreateButton = true;
        this.actionSaveEdit = 'Update';
        this.scheduleCollectionHeader = this.editCollectSchedule;
        this.scheduleName = scheduleCollection.name;
        this.oldScheduleName = scheduleCollection.name;
        // tslint:disable-next-line:radix
        this.frequency = parseInt(scheduleCollection.frequency.split('Hrs')[0]);

        this.collectionAlarmingFrequency = null;
        this.alarmingFrequency = null;

        if (scheduleCollection.alarmingFrequency.includes('15mins')) {
            this.alarmingFrequency = 15;
        } else {
            this.alarmingFrequency = +scheduleCollection.alarmingFrequency.split('Hrs')[0];
        }
        this.scheduleIdUpdate = scheduleCollection.scheduleId;

        if (this.alarmingFrequency) {
            this.collectionAlarmingFrequency = this.alarmFrequencyItems.find(
                (item) => item.id === this.alarmingFrequency,
            );
        }

        this.deselectDays();
        for (const scheduleDay of scheduleCollection.collectionDays) {
            for (const menuDay of this.listOfDays) {
                if (scheduleDay === menuDay.apiValue.toString()) {
                    menuDay.isSelected = true;
                }
            }
        }

        this.disableSave = this.validateForm();
    }

    public saveScheduleCollection() {
        const scheduleCollection: ScheduleCollection = new ScheduleCollection();
        scheduleCollection.name = this.scheduleName;
        scheduleCollection.frequency = this.frequency;
        scheduleCollection.alarmingFrequency = this.alarmingFrequency;
        scheduleCollection.collectionDays = this.listOfDays
            .filter((element) => element.isSelected)
            .map((element) => element.apiValue);

        scheduleCollection.locations = this.selectedLocations.map((x) => x.id);

        if (this.actionSaveEdit === 'Update') {
            scheduleCollection.scheduleId = this.scheduleIdUpdate;
            this.showEditConfirmationScheduleCollection(this.customerID, scheduleCollection);
        } else if (this.actionSaveEdit === 'Save') {
            scheduleCollection.frequency = scheduleCollection.frequency * 60;

            if (scheduleCollection.alarmingFrequency) {
                if (scheduleCollection.alarmingFrequency === 15) {
                    scheduleCollection.alarmingFrequency = scheduleCollection.alarmingFrequency;
                } else {
                    scheduleCollection.alarmingFrequency = scheduleCollection.alarmingFrequency * 60;
                }
            }

            //  Below will check if it is to Add Schedule Collect
            const displayedMessage = this.scheduleName + ' ' + this.scheduleCreateSuccess;
            this.collectionScheduleLoadingState = true;

            const subscription1 = this.scheduleCollectionService
                .postScheduleCollection(this.customerID, scheduleCollection)
                .subscribe(
                    (result) => {
                        const simpleSnackBarRef = this.snackBar.open(displayedMessage, this.dismissBtn);
                        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
                        this.loadScheduleCollection();
                        this.data.locations.forEach((element) => {
                            if (element && element.isChecked) {
                                delete element.isChecked;
                            }
                        });
                        this.guid = UUID.UUID();
                        this.collectionScheduleLoadingState = false;
                        this.sharedService.setCloseFlagValue(true);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                    (error) => {
                        this.sharedService.setCloseFlagValue(false);
                        this.handleError(error);
                        this.collectionScheduleLoadingState = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                );
            this.subscriptions.push(subscription1);
        }
        this.scheduleCollectionHeader = this.scheduleCollectEditor;
    }

    public handleError(error) {
        if (error.error) {
            this.snackBarNotification(error.error, 'custom-error-snack-bar');
            return;
        }
        if (error.statusText.includes('Conflict')) {
            this.snackBarNotification(this.scheduleExists, 'custom-error-snack-bar');
        }
    }

    public snackBarNotification(message: string, notificationClass) {
        this.snackBarNotificationService.raiseNotification(message, this.dismissBtn, {
            panelClass: notificationClass,
        }, false);
    }

    private loadScheduleCollection() {
        const subscription3 = this.scheduleCollectionService.getScheduleCollection(this.customerID).subscribe(
            (result) => {
                this.schedules = result;
                this.localScheduleCollection = this.createSchedules(this.schedules);
                const filterPipe = new OrderByPipe();
                this.localScheduleCollection = filterPipe.transform(this.localScheduleCollection, 'name', false);
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.showCreateSchedule = false;
                this.generateCollectionWidgetScheduleTable();
                this.setFirstPage = true;
            },
            (error) => {
                //
            },
        );
        this.subscriptions.push(subscription3);
    }

    /**
     * Represents the Delete Action of Schedule Collection
     * @param scheduleCollection -selected Schedule Collection
     */
    public showDeleteConfirmationScheduleCollection(scheduleCollection: ScheduleCollection) {
        this.selectedScheduleCollection = scheduleCollection;
        this.conformationDataInput = {
            title: this.deleteTitle,
            message: this.deleteConfirmMsg,
            okText: this.deleteBtn,
            cancelText: this.cancelBtn,
        };
        this.showConfirmation = true;
        this.confirmationType = 'Delete';
    }

    /**
     * Represents the Update Action of Schedule Collection
     * @param scheduleCollection -selected Schedule Collection
     */
    public showEditConfirmationScheduleCollection(customerId: number, scheduleCollection: ScheduleCollection) {
        this.selectedScheduleCollection = scheduleCollection;
        this.conformationDataInput = {
            title: this.updateDialogTitle,
            message: this.updateConfirmMsg,
            okText: this.proceedBtn,
            cancelText: this.cancelBtn,
        };
        this.showConfirmation = true;
        this.confirmationType = 'Update';
    }

    /**
     * Represents the Confirmation Action of Delete/Update Schedule Collection
     * @param confirmation -confirmation response
     */
    public listenConfirmation(confirmation: IComponentCustomizedConfirmationResult) {
        if (confirmation.whichButtonPressed === 'ok') {
            this.showConfirmation = false;

            if (this.confirmationType === 'Delete') {
                // Represents the Confirmation Action of Schedule Collection
                this.deleteScheduleCollection();
            } else if (this.confirmationType === 'Update') {
                // Represents the Confirmation Action of Update Schedule Collection
                this.editScheduleCollection();
            }
        } else {
            this.showConfirmation = false;
            if (this.confirmationType === 'Update') {
                this.showCreateSchedule = false;
                this.hideCreateButton = false;
                this.setFirstPage = true;
                if (event !== undefined) {
                    event.preventDefault();
                }
            }
        }
    }

    /**
     * Represents the Delete Action of Schedule Collection
     */
    private deleteScheduleCollection() {
        this.collectionScheduleLoadingState = true;

        const subscription4 = this.scheduleCollectionService
            .deleteScheduleCollection(this.selectedScheduleCollection.scheduleId)
            .subscribe(
                (result) => {
                    this.localScheduleCollection.splice(
                        this.localScheduleCollection.indexOf(this.selectedScheduleCollection),
                        1,
                    );
                    this.schedules = <ScheduleCollection[]>this.localScheduleCollection;

                    const simpleSnackBarRef = this.snackBar.open(
                        this.selectedScheduleCollection.name + ' ' + this.scheduleDeleteSuccess,
                        this.dismissBtn,
                    );
                    setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
                    this.loadScheduleCollection();

                    this.guid = UUID.UUID();
                    this.collectionScheduleLoadingState = false;

                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.sharedService.setCloseFlagValue(true);
                },
                (error) => {
                    this.collectionScheduleLoadingState = false;
                    this.sharedService.setCloseFlagValue(false);
                },
            );
        this.scheduleName = '';
        this.subscriptions.push(subscription4);
    }

    /**
     * Represents the Update Action of Schedule Collection
     */
    private editScheduleCollection() {
        this.collectionScheduleLoadingState = true;

        const subscription2 = this.scheduleCollectionService
            .updateScheduleCollection(
                this.scheduleIdUpdate,
                this.selectedScheduleCollection.locations,
                this.scheduleName,
                this.frequency * 60,
                this.alarmingFrequency
                    ? this.alarmingFrequency === 15
                        ? this.alarmingFrequency
                        : this.alarmingFrequency * 60
                    : null,
                this.selectedScheduleCollection.collectionDays,
                '',
            )
            .subscribe(
                (result) => {
                    const simpleSnackBarRef = this.snackBar.open(
                        this.selectedScheduleCollection.name + ' ' + this.scheduleUpdateSuccess,
                        this.dismissBtn,
                    );
                    setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
                    this.showCreateSchedule = false;
                    this.hideCreateButton = false;
                    this.loadScheduleCollection();
                    this.guid = UUID.UUID();
                    this.collectionScheduleLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.sharedService.setCloseFlagValue(true);
                },
                (error) => {
                    this.handleError(error);
                    this.collectionScheduleLoadingState = false;
                    this.sharedService.setCloseFlagValue(false);
                },
            );
        this.subscriptions.push(subscription2);
    }

    private createSchedules(scheduleCollection: ScheduleCollection[]) {
        const tempScheduleCollection: LocalScheduleCollection[] = [];
        if (this.schedules !== null && this.schedules !== undefined) {
            for (let counter = 0; counter < this.schedules.length; counter++) {
                tempScheduleCollection.push({
                    scheduleId: scheduleCollection[counter].scheduleId,
                    name: scheduleCollection[counter].name,
                    frequency: scheduleCollection[counter].frequency,
                    alarmingFrequency: scheduleCollection[counter].alarmingFrequency,
                    collectionDays: scheduleCollection[counter].collectionDays,
                    locations: scheduleCollection[counter].locations,
                    isSelected: false,
                });
            }
        }
        return tempScheduleCollection;
    }

    /**
     * This function capitalized the string value with maximum one space in between word and
     * Checks for duplicate Schedule Name
     */
    public validateScheduleNameField() {
        this.scheduleName = this.scheduleName.trim();

        if (this.scheduleName.length > 0) {
            if (
                this.localScheduleData.find((x) => x.name.toLowerCase() === this.scheduleName.toLowerCase()) &&
                this.oldScheduleName &&
                this.oldScheduleName.toLowerCase() !== this.scheduleName.toLowerCase()
            ) {
                this.isDuplicateScheduleName = true;
            } else {
                this.scheduleName = StringUtils.capitalizedStringWithSpace(this.scheduleName);
                this.isDuplicateScheduleName = false;
            }
        }
    }

    /**
     * checks if the name length is more than specific length
     */
    public validateLocationgroupNameLength(str: string, strLength: number): boolean {
        return (str + '').length > strLength ? true : false;
    }

    private setupLocationDropdown() {
        const locationArgs = <LocationArgs>{
            customerId: this.customerID,
            locationGroupId: this.locationGroupId || 0,
            IncludeInactiveLocations: false,
        };
        const locationsSubscription: Subscription = this.usersService
            .getLocationsList(locationArgs)
            .subscribe((locations) => {
                if (locations && locations.length) {
                    this.selectableLocations = locations
                        .filter((x) => x.monitoringPoint !== 2)
                        .map(
                            (location: Locations) =>
                                <Selectable>{
                                    id: location.locationId,
                                    name: location.locationName,
                                    isChecked: this.data.schedules
                                        ? this.data.schedules
                                              .filter((item) => item.name === this.scheduleName)
                                              .some((loc) => loc.id === location.locationId)
                                        : false,
                                },
                        );
                }
            });
        this.subscriptions.push(locationsSubscription);
    }
}

// supporting class for location.
class LocalLocation {
    public locationId: number;
    public locationName: string;
    public isSelected: boolean;
}

class LocalScheduleCollection {
    public scheduleId: number;
    public name: string;
    public frequency: number;
    public alarmingFrequency: number;
    public collectionDays: number[];
    public locations: number[];
    public isSelected: boolean;
}

class ListOfDaysItem {
    public text: string;
    public apiValue: number;
    public isSelected: boolean;
}
