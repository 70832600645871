import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    OnChanges,
    AfterContentInit,
    SimpleChanges,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UsersService } from 'app/pages/admin/users.service';
import { TranslateService } from '@ngx-translate/core';
import { DateutilService } from 'app/shared/services/dateutil.service';
import _ from 'lodash';
import { HOUR_12_FORMAT, HOUR_24_FORMAT, HOUR_FORMAT } from 'app/shared/constant';

@Component({
    selector: 'app-approve-data',
    templateUrl: './approve-data.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ApproveDataComponent implements OnInit, OnChanges {
    constructor(
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private userService: UsersService,
        private translate: TranslateService,
        private dateutilService: DateutilService
    ) { }

    public customerDateFormat: string;
    public userName: string;
    public today = new Date();
    public isEndDateError: boolean;
    public isDateError: boolean;
    @ViewChild('startDateInput') public startDateInput: ElementRef;

    @Input() public endDate: Date;

    @Input() public startDate: Date;

    @Input() public disabled: boolean = false;

    @Input() public prevApprStartDate: Date;

    @Input() public prevApprEndDate: Date;

    @Input() public approvedBy: string;

    @Output() public startDateChange = new EventEmitter<Date>();
    @Output() public endDateChange = new EventEmitter<Date>();

    @Output() public isErrorOnApproveData = new EventEmitter<boolean>();

    ngOnChanges(changes: SimpleChanges): void {
        if(changes &&  !_.isEqual(changes.disabled?.currentValue, changes.disabled?.previousValue))
        {
            setTimeout(() => {
                this.startDateInput.nativeElement.focus();
                this.cdr.detectChanges();
            }, 200);
        }
    }

    ngOnInit() {
        if(this.disabled)
        {
            this.startDate = null;
        }
        const dateFormat = this.dateutilService.getFormat();
        const is12HourFormat = this.dateutilService.timeFormat.getValue() !== HOUR_FORMAT;
        this.customerDateFormat = is12HourFormat ? `${dateFormat}, ${HOUR_12_FORMAT}` : `${dateFormat}, ${HOUR_24_FORMAT}`;
        this.userName = this.userService.userName;
    }

    public eventStartChanged(event: MatDatepickerInputEvent<Date>) {
        event.value = !event.value && event.target.value ? new Date(event.target.value) : event.value;
        if(event.value !== undefined && isNaN(event.value.getTime()))
        {
            this.isErrorOnApproveData.emit(true);
            return;
        }
          
       this.startDate = event.value;
       if(this.approvalDateChange())
       {
            this.startDateChange.emit(this.startDate);
       }
    }

    public eventEndChanged(event: MatDatepickerInputEvent<Date>) {
        this.isEndDateError = false;
        event.value = !event.value ? new Date(event.target.value) : event.value;

        if(!event.value || isNaN(event.value.getTime()))
        {
            this.isEndDateError = true;
            this.isErrorOnApproveData.emit(true);
            return;
        }

        this.endDate = event.value;
        if(this.approvalDateChange())
        {
            this.endDateChange.emit(this.endDate);
        }
    }

    public approvalDateChange() {
        this.isDateError = false;
        const startDate = new Date(this.startDate)?.getTime();
        const endDate = new Date(this.endDate)?.getTime();

        if(startDate > endDate)
        {
            this.isDateError = true;
            this.isErrorOnApproveData.emit(true);
            return false;
        }
        
        this.isErrorOnApproveData.emit(false);
        return true;
        
    }
}
