import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import * as moment from 'moment';
import { ConfirmationService } from 'app/shared/services/confirmation.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { customerQueryParam, locationIdQueryParam } from 'app/shared/models/customer';
import { ConfirmationReportUIRow, ConfirmationReportUIRowDetails, ConfirmationUnitsAndAverages, CustomerUnits, DEFAULT_AVERAGE_TO_PEAK } from 'app/shared/models/location-details';
import { Subscription } from 'rxjs';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { ConfirmationPoint, ConfirmationReadingEntryUI, ConfirmationReadingResponse } from 'app/shared/models/confirmation-points';
import { GPD_TO_MPD_DIVIDER, LPD_TO_LPS_DIVIDER, UnitOfMeasureType } from 'app/shared/constant';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-add-edit-plotting-confirmation',
    templateUrl: './add-edit-plotting-confirmation.component.html',
    styleUrls: ['./add-edit-plotting-confirmation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditPlottingConfirmationComponent implements OnInit, OnDestroy {
    public cid: string;
    public lid: string;
    private unableToAdd: string;
    public isLoading: boolean;
    private successfulAdd: string;
    private unableToUpdate: string;
    private successfulUpdate: string;
    public customerId: number;
    public depthUnit: string;
    public siltUnit: string;
    public velocityUnit: string;
    public quantityUnit: string;
    public flowSmallerUnit: string;
    public flowUnit: string;
    public dateFormat: string;
    public customDateFormat: string;
    public momentDateFormat: string;
    public maxConfirmationPointDate = new Date();
    public isValidConfirmationDate: boolean = true;
    public is12HourFormat = false;
    public data: ConfirmationPoint;
    public numericGeneralPattern = REGEX_CONFIG.numericGeneralPattern;
    public numericWith1DecimalPlaces = REGEX_CONFIG.numericWith1DecimalPlaces;
    public numericWith2DecimalPlaces = REGEX_CONFIG.numericWith2DecimalPlaces;
    public siltValidator = this.numericWith2DecimalPlaces;
    public depthValidator = this.numericWith2DecimalPlaces;
    public numericGreaterThanZeroPattern  = REGEX_CONFIG.numericGreaterThanZeroPattern;
    public readings: ConfirmationReadingEntryUI[];
    public calculatedAvgVelocity?: number;
    public translateKeys: Array<string> = [
        'CONFIRMATION_POINT_DIALOG.UNABLE_TO_ADD',
        'CONFIRMATION_POINT_DIALOG.SUCCESSFUL_ADD',
        'CONFIRMATION_POINT_DIALOG.UNABLE_TO_UPDATE',
        'CONFIRMATION_POINT_DIALOG.SUCCESSFUL_UPDATE',
    ];

    public isEdit = false;
    private subscriptions = new Subscription();
    public customerUnits: ConfirmationUnitsAndAverages;
    private isMetric: boolean;
    public isFlowLoading = false;
    public moment = moment; // so can be accessed in HTML template

    public trackByIndex = TrackBy.byIndex;
    constructor(
        @Inject(MAT_DIALOG_DATA) public inputData: ConfirmationReportUIRow,
        public refDialog: MatDialogRef<AddEditPlottingConfirmationComponent>,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        public confirmationService: ConfirmationService,
        public snackBarNotificationService: SnackBarNotificationService,
        public customerService: CustomerService,
        private uiUtilsService: UiUtilsService,
        private dateUtilService: DateutilService,
        private cdr: ChangeDetectorRef,
    ) {
        this.maxConfirmationPointDate.setHours(this.maxConfirmationPointDate.getHours() + 24);

        const translationSubs = translate.get(this.translateKeys).subscribe((translateValues) => {
            this.unableToAdd = translateValues['CONFIRMATION_POINT_DIALOG.UNABLE_TO_ADD'];
            this.successfulAdd = translateValues['CONFIRMATION_POINT_DIALOG.SUCCESSFUL_ADD'];
            this.unableToUpdate = translateValues['CONFIRMATION_POINT_DIALOG.UNABLE_TO_UPDATE'];
            this.successfulUpdate = translateValues['CONFIRMATION_POINT_DIALOG.SUCCESSFUL_UPDATE'];
        });

        this.subscriptions.add(translationSubs);
    }

    public ngOnInit() {
        this.isLoading = true;

        this.isMetric = this.customerService.customer && (Number(this.customerService.customer.unitsType) === UnitOfMeasureType.METRIC);

        if (!this.inputData || !this.inputData.data) {
            this.isEdit = true;
            const cDate = new Date();

            const atp = this.inputData.atp ? this.inputData.atp : DEFAULT_AVERAGE_TO_PEAK;

            this.data = {
                confirmationDate: cDate,
                depth: null,
                velocity: null,
                comment: '',
                flow: null,
                calculatedFlow: null,
                depthAccuracy: null,
                velocityAccuracy: null,
                silt: 0,
                averageToPeak: atp,
                ignore: false
            };
        } else {
            const v = this.inputData.data[0];
            this.data = {
                id: v.id,
                confirmationDate: v.time,
                depth: v.depth,
                velocity: v.peak_velocity,
                comment: v.comment,
                flow: v.measured_flow,
                calculatedFlow: v.calculated_flow,
                depthAccuracy: v.depthacc,
                velocityAccuracy: v.velacc,
                silt: v.silt,
                averageToPeak: v.ap_ratio,
                ignore: v.ignore
            };
            this.calculatedAvgVelocity = v.calculated_avg_velocity;

            this.readings = [];
            for(let i=1; i<=3; i++) {
                this.readings.push(this.tableRowToReadingsRow(this.inputData.data[i]));
            }
        }


        const routeParamsSubs = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.cid = params.get(customerQueryParam);
            this.lid = params.get(locationIdQueryParam);
        });

        this.customerUnits = this.inputData.units;
        this.getCustomerUnits();
        this.uiUtilsService.safeChangeDetection(this.cdr);

        if(this.customerUnits.dp === 1) {
            this.depthValidator = this.numericWith1DecimalPlaces;
            this.siltValidator = this.numericWith1DecimalPlaces;
        }

        const dateSubs = this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
            this.dateFormat = this.dateUtilService.getFormat();
            this.is12HourFormat = this.dateUtilService.timeFormat.getValue() !== 'hh:mm:ss';
            this.customDateFormat = this.is12HourFormat
                ? `${this.dateFormat}, ${'hh:mm a'}`
                : `${this.dateFormat}, ${'HH:mm'}`;
            this.momentDateFormat = this.dateFormat.toUpperCase() + (this.is12HourFormat ? `hh:mm a`: `HH:mm`);
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.add(routeParamsSubs);
        this.subscriptions.add(dateSubs);
    }

    private tableRowToReadingsRow(row: ConfirmationReportUIRowDetails): ConfirmationReadingEntryUI {
        if(!row) return null;

        return {
            name: row.type,
            ts: row.time,
            dr: row.depth,
            dd: row.depth_diff,
            vr: row.calculated_avg_velocity,
            vd: row.velocity_diff,
            cg: row.calculatedGain,
            qr: row.calculated_flow ? row.calculated_flow : row.quantity,
            qd: row.quantity_diff
        }
    }

    /**
     * Close dialoge box method
     * @param permissionsUpdated
     */
    public close(permissionsUpdated?: boolean) {
        this.refDialog.close({ success: permissionsUpdated });
    }

    public updateReadings() {
        if(!this.data) return;

        const depth = this.data.depth ? this.data.depth : 0;
        const velocity = this.data.velocity ? this.data.velocity : 0;
        const silt = this.data.silt ? this.data.silt : 0;
        const averageToPeak = this.data.averageToPeak ? this.data.averageToPeak : 0;

        const confirmationDate = this.confirmationDateGet();

        this.confirmationService.getConfirmationReadings(this.customerId, this.lid, confirmationDate,
            depth, velocity, silt, averageToPeak
            )
            .subscribe((res) => {
                this.readings = [];
                this.readings.push({...res.before, name: 'Before'});
                this.readings.push({...res.closest, name: 'Closest'});
                this.readings.push({...res.after, name: 'After'});

                this.uiUtilsService.safeChangeDetection(this.cdr);
            })
    }

    private confirmationDateGet() {
        return this.data.confirmationDate && moment.isMoment(this.data.confirmationDate)
                ? this.data.confirmationDate.format('YYYY-MM-DDTHH:mm:ss')
                : moment(this.data.confirmationDate, this.momentDateFormat).format('YYYY-MM-DDTHH:mm:ss');
    }

    public save() {
        this.isLoading = true;

        this.onDepthVelocitySiltAvgToPeakBlur();

        const reqBody = {...this.data};
        reqBody.confirmationDate = this.confirmationDateGet();

        reqBody.flow = this.data.flow / (this.isMetric ? 1 : GPD_TO_MPD_DIVIDER);

        if (!this.data.id) {
            this.confirmationService.create({ lid: this.lid, cid: this.cid }, reqBody).subscribe(
                (res) => {
                    this.isLoading = false;
                    if (res.isError) {
                        this.snackBarNotificationService.raiseNotification(this.unableToAdd, 'Dismiss', {
                            panelClass: 'custom-error-snack-bar',
                        }, false);
                    } else {
                        this.snackBarNotificationService.raiseNotification(this.successfulAdd, 'Dismiss', {
                            duration: 10000,
                        });
                        this.close(true);
                    }
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                () => {
                    this.snackBarNotificationService.raiseNotification(this.unableToAdd, 'Dismiss', {
                    }, false);
                },
            );
        } else {
            this.confirmationService.update(reqBody).subscribe(
                (res) => {
                    this.snackBarNotificationService.raiseNotification(this.successfulUpdate, 'Dismiss', {
                        duration: 10000,
                    });
                    this.close(true);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                () => {
                    this.snackBarNotificationService.raiseNotification(this.unableToUpdate, 'Dismiss', {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                },
            );
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    public getCustomerUnits() {
        this.customerId = Number(this.cid);
        this.customerService.getCustomerUnits(this.customerId).subscribe(
            (response: CustomerUnits) => {
                if (response) {
                    this.depthUnit = response.depthUnit;
                    this.velocityUnit = response.velocityUnit;
                    this.flowUnit = response.flowUnit;
                    this.flowSmallerUnit = response.flowSmallerUnit;
                    this.siltUnit = response.depthUnit;
                    this.quantityUnit = this.flowUnit;
                }
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    public onDepthVelocityChange(event) {
        const { value } = event.target;

        if (!this.isNumber(value) || value < 0) {
            this.data.calculatedFlow = 0;
        }
    }

    public onDepthVelocitySiltAvgToPeakBlur() {
        this.updateReadings();

        const { depth, velocity, averageToPeak } = this.data;

        if (this.isNumber(depth) && this.isNumber(velocity)) {
            if (depth >= 0 && velocity >= 0) {
                this.calculateFlow();
            }
        }

        if (this.isNumber(velocity) && this.isNumber(averageToPeak)) {
            if (depth >= 0 && velocity >= 0) {
                this.calculateAvgVelocity(velocity, averageToPeak);
            }
        }
    }

    public calculateAvgVelocity(velocity: number, averageToPeak: number) {
        this.calculatedAvgVelocity = velocity * averageToPeak;
    }

    public calculateFlow() {
        const { depth, velocity, silt, averageToPeak } = this.data;

        this.isFlowLoading = true;
        this.confirmationService
            .calculateFlow({ cid: this.cid, lid: this.lid, depth, velocity, silt, averageToPeak })
            .subscribe((data: number) => {
                this.isFlowLoading = false;
                this.data.calculatedFlow = data;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            () => {
                this.isFlowLoading = false;
            });
    }

    private isNumber(value) {
        return /^[-]?\d/.test(value);
    }

    public onDateChange(event) {
        this.isValidConfirmationDate = true;
        this.data.confirmationDate = moment(event.target.value, this.momentDateFormat).toDate();
        //new Date(event.target.value);
        if (this.data.confirmationDate >= this.maxConfirmationPointDate) {
            this.isValidConfirmationDate = false;
        }

        this.updateReadings();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
