import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    SimpleChanges,
    OnChanges,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    OnInit,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChangesAction } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import { Debounce } from 'app/pages/sliicer/shared/directives/debounce/debounce.directive';
import { validateRule } from 'app/pages/sliicer/shared/utils/base-validation';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { Regime, Settings } from 'app/shared/models/sliicer/settings';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { TrackBy } from 'app/shared/utils/track-by';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import moment from 'moment';
import * as _ from 'underscore';

const dateFormat = 'YYYY-MM-DD';
const validationRules: RegimeValidation = { name: 'validString', periodEnd: 'validDate' };
const millisecondsInDay: number = 24 * 60 * 60 * 1000;

export interface TimelineElement {
    name: string;
    size: number;
    periodEnd: string;
}

export interface ValidationData {
    validated: boolean;
    errors: Object;
}

export interface RegimeValidation {
    name: string & 'validString';
    periodEnd: string & 'validDate';
}

@Component({
    selector: 'app-regime-settings',
    templateUrl: './regime-settings.component.html',
    styleUrls: ['./regime-settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegimeSettingsComponent implements OnChanges, OnInit {
    @ViewChild('regimesForm') public regimesForm: NgForm;
    @Input() public isStudyLocked: boolean;
    @Input() public caseStudyDetails: SliicerCaseStudy;
    @Input() public changesAction: ChangesAction;
    @Output() public checkChanges: EventEmitter<string> = new EventEmitter();
    public regimes: Regime[];
    public caseStudyStartDate: string;
    public caseStudyEndDate: string;
    public timelineElements: TimelineElement[] = [];
    public errors: Object = {};
    public activeChanges = false;
    public dateFormat: string;

    public error = false;
    public errorType = {
        regimeComplete: false,
    }
    public debounceData: Debounce = { value: 200, initial: true };
    public trackByIndex = TrackBy.byIndex;
    constructor(private uiUtilsService: UiUtilsService, private cdr: ChangeDetectorRef, private dateutilService: DateutilService) { }

    ngOnInit(): void {
        this.dateFormat = this.dateutilService.getFormat();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.caseStudyDetails) {
            this.caseStudyStartDate = (this.caseStudyDetails.config && this.caseStudyDetails.config.startDate) || '';
            this.caseStudyEndDate = (this.caseStudyDetails.config && this.caseStudyDetails.config.endDate) || '';
            this.setRegimes();
            this.setTimelineElements();
        }
        if (changes.changesAction && this.getChangeValue()) {
            if (this.changesAction.action === 'undoChanges') {
                this.undoChanges();
                this.checkChanges.emit('');
            }
        }
    }

    public onDebounce() {
        if (this.regimesForm.valid) {
            this.checkChanges.emit('');
        } else {
            let shouldRedraw = false;

            const formControlKeys = Object.keys(this.regimesForm.controls);

            formControlKeys.forEach(key => {
                const ctr = this.regimesForm.controls[key];

                if (ctr.valid === false) {
                    ctr.markAsDirty();
                    ctr.markAsTouched();
                    shouldRedraw = true;
                }
            });

            if (shouldRedraw) {
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        }
    }

    // This method will handle regime name change
    public regimeNameChange(index: number): void {
        this.errors[index + '.name'] = validateRule({
            rule: validationRules.name,
            value: this.regimes[index].name || '',
        });

        if (this.regimesForm.valid) {
            this.setTimelineElements();
        }
    }

    // This method will handle regime name change
    public regimePeriodEndChange(index: number): void {
        this.errors[index + '.periodEnd'] = validateRule({
            rule: validationRules.periodEnd,
            value: this.regimes[index].periodEnd || '',
        });

        this.setRegimesPeriods();
        if (this.regimesForm.valid) {
            this.setTimelineElements();
        }
    }

    // This method will remove selected regime
    public removeRegime(index: number): void {
        this.regimes.splice(index, 1);
        this.setRegimesPeriods();
        this.setTimelineElements();
        this.checkChanges.emit('');
    }

    // This method will add new regime object if the array is validated
    public addRegime(): void {
        const validation: ValidationData = this.validateRegimes();
        this.errors = validation.errors;
        if (validation.validated) {
            let valid = true;
            if (this.regimes.length > 0) {
                const lastRegimeEnd = moment(this.regimes[this.regimes.length - 1].periodEnd)
                    .endOf('day')
                    .valueOf();
                const endOfCaseStudy = moment(this.caseStudyEndDate).endOf('day').valueOf();
                valid = lastRegimeEnd < endOfCaseStudy;
            }

            if (valid) {
                this.regimes.push({ name: '', periodStart: '', periodEnd: '', minPeriodStart: '' });
                this.setRegimesPeriods();
                this.setTimelineElements();
            }
        }
    }

    // This method will return regimes changes
    public getChanges(): Regime[] {
        const validation: ValidationData = this.validateRegimes();
        this.errors = validation.errors;
        if (validation.validated) {
            this.regimes.map((r) => { });
            return this.formatRegimePeriods(this.regimes);
        }
        return null;
    }

    // This method will undo all changes
    public undoChanges(): void {
        this.setRegimes();
        this.errors = this.validateRegimes().errors;
        this.setTimelineElements();
    }

    // This method will return the changes value
    public getChangeValue(): boolean {

        this.errorType.regimeComplete = false;

        if (this.regimes && this.regimes.length > 0) {
            if ((this.regimes.length == 1 && this.regimes.find(a => a.periodEnd == '')) || this.regimes.find(a => moment(a.periodEnd).format(dateFormat) == moment(this.caseStudyDetails.config.endDate).format(dateFormat))) {

                this.errorType.regimeComplete = false;
            }
            else {

                this.errorType.regimeComplete = true;
            }
        }

        this.updateError();

        const caseStudyRegimes = (this.caseStudyDetails.settings && this.caseStudyDetails.settings.regimes) || [];
        const regimes = this.formatRegimePeriods(this.regimes);
        regimes.forEach(function (v) { delete v.minPeriodStart });
        const caseStudyRegs = this.formatRegimePeriods(caseStudyRegimes)
        caseStudyRegs.forEach(function (v) { delete v.minPeriodStart });
        return !_.isEqual(regimes, caseStudyRegs) && !this.error;
    }

    private updateError() {
        this.error = this.errorType.regimeComplete;
    }

    // This method will return formated regime periods
    private formatRegimePeriods(regimes: Regime[]): Regime[] {
        return regimes.map((r) => {
            return {
                name: r.name,
                periodStart: moment(r.periodStart).format(dateFormat),
                periodEnd: moment(r.periodEnd).format(dateFormat),
                minPeriodStart: moment(r.minPeriodStart).format(dateFormat),
            };
        });
    }

    // This method will adjust regime periods
    private setRegimesPeriods(): void {
        for (let i = 0; i < this.regimes.length; i++) {
            if (i === 0) {
                this.regimes[i].periodStart = this.caseStudyStartDate;
                this.regimes[i].minPeriodStart = this.caseStudyStartDate;
            } else if (this.regimes[i - 1].periodEnd) {
                this.regimes[i].periodStart = moment(this.regimes[i - 1].periodEnd)
                    .add(1, 'days')
                    .startOf('day')
                    .format(dateFormat);
                this.regimes[i].minPeriodStart = moment(this.regimes[i - 1].periodEnd)
                    .add(2, 'days')
                    .startOf('day')
                    .format(dateFormat);
            }
        }
    }

    // This method will validate the regimes array
    private validateRegimes(): ValidationData {
        const validation: ValidationData = { validated: true, errors: {} };
        for (let i = 0; i < this.regimes.length; i++) {
            for (const key in validationRules) {
                validation.errors[i + '.' + key] = validateRule({
                    rule: validationRules[key],
                    value: this.regimes[i][key] || '',
                });
                if (validation.errors[i + '.' + key] !== '' && validation.validated) {
                    validation.validated = false;
                }
            }
        }
        return validation;
    }

    // This method will set regimes
    private setRegimes(): void {
        const caseStudyRegimes = (this.caseStudyDetails.settings && this.caseStudyDetails.settings.regimes) || [];
        this.regimes = JSON.parse(JSON.stringify(caseStudyRegimes));
    }

    // This method will check weather or not there are active changes in the regimes
    private setActiveChanges(): void {
        this.activeChanges = this.getChangeValue();
    }

    // This method will set the timeline elements and will call the active changes method
    private setTimelineElements(): void {
        this.timelineElements = this.getTimelineElements(this.regimes, this.caseStudyStartDate, this.caseStudyEndDate);
        this.setActiveChanges();
    }

    // This method will generate and return the timeline elements for the regimes data
    private getTimelineElements(regimes: Regime[], studyStartDate: string, studyEndDate: string): TimelineElement[] {
        const studyLength = this.getDays(
            moment(studyEndDate).endOf('day').valueOf() - moment(studyStartDate).startOf('day').valueOf(),
        );
        return regimes
            .filter((r) => r.periodStart && r.periodEnd)
            .map((r) => {
                return {
                    name: r.name,
                    size: this.getTimelineSize(r, studyLength),
                    periodEnd: r.periodEnd,
                };
            });
    }

    // This method will calculate and return the timeline size
    private getTimelineSize(regime: Regime, studyLength: number): number {
        const periodLength = this.getDays(
            moment(regime.periodEnd).endOf('day').valueOf() - moment(regime.periodStart).startOf('day').valueOf(),
        );
        return (periodLength * 100) / studyLength;
    }

    // This method converts milliseconds to days, rounding to the nearest full day
    private getDays(milliseconds: number): number {
        return milliseconds / millisecondsInDay;
    }
}
