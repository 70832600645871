<mat-card-content class="widgetFixPagination">
    <app-loader [isLoading]="loading.isLoadingLocations || loading.isLoadingReport"></app-loader>
    <div class="filters-container" *ngIf="filters">
        <div class="filter-item event-filter-locations-hint" matTooltip="{{ 'HOME.CUMULATIVE_RAIN_WIDGET.LOCATION_FILTER_TOOLTIP' | translate }}">
            <app-multi-select-auto-complete
                [items]="locations"
                [preselectedItems]="preSelectedLocations"
                [isShowAll]="true"
                (currentlySelectedItems)="userHandleSelectedLocations($event)"
                placeholder="{{ 'COMMON.LOCATIONS' | translate }}"
            ></app-multi-select-auto-complete>
        </div>

        <div class="filter-item event-filter-date-hint" matTooltip="{{ 'HOME.CUMULATIVE_RAIN_WIDGET.DATE_FILTER_TOOLTIP' | translate }}">
            <app-date-range-picker
                [(startDate)]="filterOptions.startDate"
                [dateRangeType]="'date'"
                [(endDate)]="filterOptions.endDate"
                (emitClose)="handleSelectedDateRange()"
                (isDateChanged)="isDateChanged = $event"
                (selectedTimespanChange)="onSelectedTimespanChange($event)"
            ></app-date-range-picker>
        </div>
    </div>
    <div id="CumulativeRainContent">
        <div class="reportInfo" *ngIf="!filters">
            <div class="title">Report Period</div>
            <div class="dateRange">{{filterOptions.startDate | date: customerDateFormat }} - {{filterOptions.endDate | date: customerDateFormat }}</div>
        </div>
        <div class="legenddiv" *ngIf="units.unitFactor">
            <table>
                <tr *ngFor="let item of legendItems; let i = index; trackBy: trackByIndex">
                    <td class="colorbox"><div [ngStyle]="{'background-color': item.color}"></div></td>
                    <td>{{units.unitFactor ? ((item.value * units.unitFactor) | number : units.unitPrecisionString) : ''}} {{units.unitTitle}} {{
                        i != 0 ? '' : 'HOME.CUMULATIVE_RAIN_WIDGET.OR_ABOVE' | translate
                    }}</td>
                </tr>
            </table>
        </div>

    </div>
</mat-card-content>
