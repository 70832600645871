import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocationDashboardComponent } from 'app/pages/dashboards/location-dashboard/location-dashboard.component';
import { AdvanceScattergraphContainerComponent } from 'app/pages/view-data/graphs/advance-scattergraph-container/advance-scattergraph-container.component';
import { ViewDataFilterComponent } from 'app/pages/view-data/view-data-filter/view-data-filter.component';
import { uniqBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { isNewTab, parentId } from '../models/customer';
import { DataEditPreview, StoredEdit, UpdatePointForSG, UpdatePoints } from '../models/data-edit';
import { LocationDashboardFilterData } from '../models/location-dashboard-filter-data';
import { LocationData } from '../models/locations-entities-data';
import { EntityData } from '../models/scatter-data';
import { SelectableGroup } from '../models/selectable';
import { Action, AnnotationSettings, SeparateWindowActionTypes } from '../models/view-data';
import { ConfigService } from './config.service';
import { DataEditService } from './data-edit.service';
import { ViewDataService } from './view-data.service';
import { ConfirmationEntitiesEnum } from '../models/view-data-filter';
import { LightningChartObject } from '../components/hydrograph/lightning-chart-object/lightning-chart-object';

@Injectable({ providedIn: 'root' })
export class SeparateWindowHydrographService {
    public broadcastChannel: BroadcastChannel;
    public dataHoverSubject = new Subject<{ id: 0 | 1, dateTime: number }>();
    public isNewWindowOpened = false;
    public isNewTab = false;

    public parentId: string;
    public id: string;
    private childId: string;
    private componentInstance: LocationDashboardComponent;

    private newWindowInterval: NodeJS.Timeout;
    private newWindowInstance: Window;
    constructor(private viewDataService: ViewDataService, private router: Router,
        private dataEditService: DataEditService, private configService: ConfigService) {

        this.id = (Math.random() + 1).toString(36).substring(7);
        this.broadcastChannel = new BroadcastChannel('new-tab-hg');
        this.broadcastChannel.onmessage = (e: MessageEvent<Action>) => this.handleAction(e.data);

        this.listenRouteChanges();

        this.dataHoverSubject.pipe(debounceTime(200)).subscribe((payload) => {
            this.dispatchAction({ type: SeparateWindowActionTypes.dataHover, payload })
        });

        window.addEventListener('beforeunload', () => {
            this.dispatchAction({ type: SeparateWindowActionTypes.close, payload: null });
        });

        this.dataEditService.notifyOtherWindow.subscribe((action: Action) => {
            this.dispatchAction(action);
        });
    }

    public init(componentInstance: LocationDashboardComponent) {
        this.componentInstance = componentInstance;
    }

    public destroy() {
        this.componentInstance = null;
    }

    public dispatchAction<T>(action: Action<T>) {
        let destinationId: string;

        if (this.isNewTab) {
            destinationId = this.parentId;
        } else if (this.isNewWindowOpened) {
            destinationId = this.childId;
        }

        if (!destinationId) {
            return;
        }

        action.destinationId = destinationId;
        this.broadcastChannel.postMessage(action);
    }

    public checkIfEntitiesDifferent(prevEntities: SelectableGroup[], currentEntities: SelectableGroup[]) {
        return prevEntities.length !== currentEntities.length || !prevEntities.every(v => currentEntities.find(i => i.id === v.id));
    }

    // need to track new window if user will close it before it's fully launched (onclose, onunload are not working)
    public onNewWindowOpened(win: Window) {
        this.newWindowInstance = win;
        this.newWindowInterval = setInterval(() => {
            if (win.closed) {
                this.newWindowInstance = null;
                this.onCloseOtherWindow();
                clearInterval(this.newWindowInterval);
            }
        }, 500);
    }

    private handleAction(action: Action) {
        if (action.destinationId !== this.id) {
            return;
        }

        const ld = this.getLocationDashboardInstance();
        const filters = this.getViewDataFiltersInstance();

        if (!ld || !filters) {
            return;
        }

        switch(action.type) {
            case SeparateWindowActionTypes.ping: return this.onPing(action.payload);
            case SeparateWindowActionTypes.pong: return this.onGetParamsFromSource(action.payload);
            case SeparateWindowActionTypes.routeChange: return this.onRouteChange(action.payload);
            case SeparateWindowActionTypes.locationChange: return this.onLocationChange(action.payload);
            case SeparateWindowActionTypes.dataHover: return this.onDataHover(action.payload);
            case SeparateWindowActionTypes.enterEdit: return ld.editData(action.payload);
            case SeparateWindowActionTypes.cancelEdit: return ld.cancelDataEditing(false);
            case SeparateWindowActionTypes.dateClose: return this.onDateClose(action.payload);
            case SeparateWindowActionTypes.hgEntitiesChange: return this.hgEntitiesChange(action.payload);
            case SeparateWindowActionTypes.sgEntitiesChange: return this.sgEntitiesChange(action.payload);
            case SeparateWindowActionTypes.dataAverageChange: return this.dataAverageChange(action.payload);
            case SeparateWindowActionTypes.annotationChange: return this.annotationChange(action.payload);
            case SeparateWindowActionTypes.selectSnapPoints: return ld.onDataSelectForSnap(action.payload, true, false);
            case SeparateWindowActionTypes.ignoreSGpoints: return this.ignoreSGpoints(action.payload);
            case SeparateWindowActionTypes.successSnap: return ld.scatterGraphApplySnapCurveChange(true, false);
            case SeparateWindowActionTypes.applyEditsToSg: return ld.onEditPointsForSG(action.payload, false);
            case SeparateWindowActionTypes.hgPreviewResponse: return this.hgPreviewResponse(action.payload);
            case SeparateWindowActionTypes.zoomPoints: return ld.zoomForScatter$.next(action.payload);
            case SeparateWindowActionTypes.submitEdits: return this.submitEdits();
            case SeparateWindowActionTypes.close: return this.onCloseOtherWindow();
            case SeparateWindowActionTypes.revertChanges: return this.revertChanges();
            case SeparateWindowActionTypes.siltChange: return this.siltChange(action.payload);
            case SeparateWindowActionTypes.blockEditor: return this.blockEditor(action.payload);
            case SeparateWindowActionTypes.loadConfig: return filters.loadConfiguration(action.payload, false, false);
            case SeparateWindowActionTypes.updateConfigs: return this.updateConfigs();
            case SeparateWindowActionTypes.updateStoredEdits: return this.onUpdateStoredEdits(action.payload);
            case SeparateWindowActionTypes.undoEdit: return this.undoEdit(action.payload);
            case SeparateWindowActionTypes.redoEdit: return this.redoEdit(action.payload);
            case SeparateWindowActionTypes.confirmationEntity: return this.confirmationEntityChange(action.payload);
            case SeparateWindowActionTypes.tracerPosition: return this.tracerPositionChange(action.payload);

            default: return;
        }
    }

    private onDateClose(payload: { start: Date, end: Date }) {
        const filterComp = this.getViewDataFiltersInstance();
        const ldComp = this.getLocationDashboardInstance();

        if (new Date(filterComp.startDate).getTime() !== new Date(payload.start).getTime() || new Date(filterComp.endDate).getTime() !== new Date(payload.end).getTime()) {
            filterComp.startDate = payload.start;
            filterComp.endDate = payload.end;

            const dateRange = { start: payload.start, end: payload.end };
            filterComp.confimationDisplayFilter.dateRange = dateRange;
            filterComp.confirmationFilter.dateRange = dateRange;

            if (ldComp.scatterGraphConfirmationData) {
                ldComp.scatterGraphConfirmationData.dateRange = dateRange;
            }

            filterComp.notifyParentComponent();
        }
    }

    private onUpdateStoredEdits(payload: { edits: StoredEdit[], currentTS: string }) {
        this.dataEditService.storedEdits = payload.edits;
        this.dataEditService.currentEditTimestamp = payload.currentTS;

        this.dataEditService.onEditsChanged.next(null);
    }

    private undoEdit(cid: number) {
        this.dataEditService.undoChanges(cid, false);
    }

    private redoEdit(cid: number) {
        this.dataEditService.redoChanges(cid, false);
    }

    private annotationChange(newAnnotations: AnnotationSettings) {
        const filterComp = this.getViewDataFiltersInstance();

        filterComp.annotationSettings = newAnnotations;
        filterComp.annotationChange(false);
    }

    private confirmationEntityChange(newEnt: ConfirmationEntitiesEnum) {
        this.componentInstance.confirmationEntityChange(newEnt, false);
    }

    private dataAverageChange(interval: number) {
        const filterComp = this.getViewDataFiltersInstance();

        if (filterComp.summarizeInterval !== interval) {
            filterComp.summarizeInterval = interval;
            filterComp.notifyParentComponent();
        }
    }

    private hgEntitiesChange(entities: SelectableGroup[]) {
        const filterComp = this.getViewDataFiltersInstance();
        const isDiff = this.checkIfEntitiesDifferent(filterComp.selectedEntities, entities);

        if (isDiff) {
            filterComp.selectEntities(entities, false);
        }
    }

    private blockEditor(edits: { hg: DataEditPreview, sg: UpdatePointForSG[] }) {
        const { hg, sg } = edits;
        const comp = this.getLocationDashboardInstance();

        comp.resetEditingParams();
        this.dataEditService.handleAPIpreviewResponse(hg);
        comp.onEditPointsForSG(sg, false);
        comp.notifyGraph({ enableAcceptButtonEditor: true });
    }

    private revertChanges() {
        const ld = this.getLocationDashboardInstance();
        const filters = this.getViewDataFiltersInstance();

        ld.notifyGraph({
            filtersData: filters.locationDashboardFilterData,
            forceAPICall: true,
            enableAcceptButtonNoReason: true,
            reloadSg: true,
            reloadHg: true
        });
    }

    private updateConfigs() {
        const filters = this.getViewDataFiltersInstance();

        this.configService.clearCachedDashboardConfig();
        this.configService.clearCachedMruSettings();
        filters.loadAllConfigs();
        filters.loadMruConfig();
    }

    private sgEntitiesChange(entities: SelectableGroup[]) {
        const filterComp = this.getViewDataFiltersInstance();
        const isDiff = this.checkIfEntitiesDifferent(filterComp.selectedSGEntities, entities);
        if (isDiff) {
            filterComp.selectSGEntities(entities, false);
        }
    }

    private submitEdits() {
        const ld = this.getLocationDashboardInstance();

        if (!this.isNewTab) {
            ld.getScatterGraphData(ld.filterSelectedValues);
        }
        ld.resetAfterSubmit();
        DataEditService.clearUUID();
        ld.editedEntitiesTracker = [];
    }

    private ignoreSGpoints(payload: { res: DataEditPreview, points: EntityData[] }) {
        const { res, points } = payload;
        const { graph } = this.getScattergraphContainerInstance();

        graph.ignoredPointsData = points;
        graph.graphBuilder.onIgnorePointsResponse(res);
        graph.ignoredSeriesNotify();
    }

    private siltChange(newValue: boolean) {
        const filters = this.getViewDataFiltersInstance();

        filters.annotationSettings.isSilt = newValue;
        filters.notifyParentComponent();
    }

    private hgPreviewResponse(payload: { res: DataEditPreview, points: UpdatePoints[] }) {
        const ld = this.getLocationDashboardInstance();
        const { res, points } = payload;

        ld.enableAcceptNoReason = false;
        ld.enableAcceptEditor = false;

        ld.entitiesEdited = [];
        ld.editedEntitiesTracker = ld.editedEntitiesTracker.concat(points);
        const editedEntityIds = uniqBy(points, (x) => x.eid);
        editedEntityIds.forEach((x) => {
            const editedEntity = this.dataEditService.newHydroData$.value.find((y) => y.entityId === x.eid);

            if (editedEntity) {
                ld.entitiesEdited.push(editedEntity.entityName);
            }
        });
        this.dataEditService.handleAPIpreviewResponse(res);
    }

    private onGetParamsFromSource(params: LocationDashboardFilterData) {
        const { annotationsSettings, customerID, startDate, endDate, locationIDs, summarizeInterval, entities, sgEntities, editMode, confirmationEntity } = params;
        const comp = this.getLocationDashboardInstance();
        const filters = this.getViewDataFiltersInstance();

        comp.confirmationEntity = confirmationEntity;
        // get copies before assigning new values, needed to check on what should be updated
        const annots = { ...filters.annotationSettings };
        const start = new Date(filters.startDate);
        const end = new Date(filters.endDate);
        const interval = filters.summarizeInterval;

        comp.annotationSettings = {...annotationsSettings};
        filters.annotationSettings = {...annotationsSettings};
        comp.startDate = startDate;
        comp.endDate = endDate;
        filters.startDate = startDate;
        filters.endDate = endDate;
        filters.summarizeInterval = summarizeInterval;

        const selectedLocation = filters.selectableLocations.find(v => v.id === locationIDs[0]);
        if (locationIDs[0] !== comp.locationId && selectedLocation) {
            filters.onChangeLocation(selectedLocation.name);
        } else if (annotationsSettings.isSilt && !annots.isSilt) {
            filters.notifyParentComponent(true);
        } else if (!Object.keys(annots).every(v => annots[v] === annotationsSettings[v])) {
            comp.annotationSettings = annots;
            comp.notifyAnnotationSelection({...annotationsSettings});
        } else if (interval !== summarizeInterval) {
            filters.notifyParentComponent();
        } else if (start.getTime() !== new Date(startDate).getTime() || end.getTime() !== new Date(endDate).getTime() ) {
            comp.notifyGraph({ filtersData: params, reloadHg: true, reloadSg: true });
        }

        if (this.checkIfEntitiesDifferent(filters.selectedSGEntities, sgEntities)) {
            filters.selectSGEntities(sgEntities, false, true);
        }

        if (this.checkIfEntitiesDifferent(filters.selectedEntities, entities)) {
            filters.selectEntities(entities, false);
        }

        if (editMode) {
            comp.editData(editMode);
        }

        this.viewDataService.filterValues.next(params);
    }

    private onLocationChange(location: LocationData) {
        const comp = this.getLocationDashboardInstance();
        if (comp.locationId === location.lid) {
            return
        }

        comp.viewDataFilter.onChangeLocation(location.n, true, false);
    }

    private onDataHover(payload: { id: 1 | 0, dateTime: number | null }) {
        if (payload.dateTime === null) {
            const chart = this.viewDataService.advancedCharts[payload.id];
            if(chart instanceof LightningChartObject) {
                (chart as LightningChartObject).tooltip.tooltipHide();
            } else {
                chart.tooltip.hide();
            }
        }

        const containerIndex = payload.id === 1 ? 0 : 1;
        const chart = this.viewDataService.advancedCharts[containerIndex];

        const container =
            (chart instanceof LightningChartObject) ? (chart as LightningChartObject).dashboard?.engine?.container
            : chart.container;

        this.viewDataService.correlateAdvancedGraphs(container, payload.dateTime);
    }

    private onRouteChange(url: string) {
        const formatterUrl = this.formatUrlBeforeCheck(url);
        if (this.formatUrlBeforeCheck(this.router.url) === formatterUrl) {
            return;
        }

        if (this.isNewTab) {
            this.router.navigateByUrl(formatterUrl + `&${isNewTab}=1` + `&${parentId}=${this.parentId}`);
        } else {
            this.router.navigateByUrl(formatterUrl);
        }
    }

    private formatUrlBeforeCheck(url: string) {
        const childRouteEndingIndex = url.indexOf(`&${isNewTab}`);

        if (childRouteEndingIndex !== -1) {
            url = url.substring(0, childRouteEndingIndex);
        }

        return url;
    }

    private onPing(childId: string) {
        if (this.newWindowInterval) {
            clearInterval(this.newWindowInterval);
        }
        this.childId = childId;
        const ld = this.getLocationDashboardInstance();
        const filtersComp = this.getViewDataFiltersInstance();
        const filters = this.viewDataService.filterValues.getValue();
        const customerID = this.getLocationDashboardInstance().customerId;
        const payload: LocationDashboardFilterData = {
            ...filters, customerID, sgEntities: filtersComp.selectedSGEntities,
            editMode: ld.showEditingMenu ? DataEditService.getUUID() : null,
            confirmationEntity: ld.confirmationEntity
        };
        this.dispatchAction<LocationDashboardFilterData>({ type: SeparateWindowActionTypes.pong, payload });
    }

    private listenRouteChanges() {
        this.router.events.pipe(filter((v) => v instanceof NavigationEnd), debounceTime(300)).subscribe((event: NavigationEnd) => {
            this.dispatchAction<string>({ type: SeparateWindowActionTypes.routeChange, payload: event.url });
        });
    }

    private getLocationDashboardInstance(): LocationDashboardComponent {
        return this.componentInstance;
    }

    private getViewDataFiltersInstance(): ViewDataFilterComponent {
        return this.componentInstance.viewDataFilter;
    }

    private getScattergraphContainerInstance(): AdvanceScattergraphContainerComponent {
        return this.componentInstance.advanceScatterGraph;
    }

    private onCloseOtherWindow() {
        if (this.isNewTab) {
            window.close();
        } else if (!this.newWindowInstance || this.newWindowInstance.closed) {      // new hg window is closed
            this.childId = null;
            this.newWindowInstance = null;
            this.isNewWindowOpened = false;
            this.setLocationDashboardToDefault();
        } else {                                    // user refreshed new hg window, need to wait for first response or window close
            this.onNewWindowOpened(this.newWindowInstance);
        }
    }

    private setLocationDashboardToDefault() {
        const ld = this.getLocationDashboardInstance();

        ld.toggleScatterGraph(false);
        this.refreshLocationDashboard();
    }

    private refreshLocationDashboard() {
        const ld = this.getLocationDashboardInstance();

        ld.getHydroGraphData(ld.getHydrographParams, false);
        if (ld) {
            ld.uiUtilService.safeChangeDetection(ld.changeDetectorRef);
        }
    }

    private tracerPositionChange(isStatic: boolean) {
        const ld = this.getLocationDashboardInstance();
        
        if (ld) {
            ld.tracerOptionChanged(isStatic, false);
        }
    }
}
