import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UsersService } from 'app/pages/admin/users.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-rain-sensor',
    templateUrl: './rain-sensor.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainSensorComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;

    public isMonitorEditor = false;
    public datalogModeList = [
        { value: 0, text: 'Synchronous' },
        { value: 1, text: 'Asynchronous' },
    ];

    public trackByValue = TrackBy.byValue();

    constructor(private usersService: UsersService) {}

    ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        if (this.monitorConfigs) {
            this.form.patchValue(this.monitorConfigs);
        }
    }
}
