<div
    id="rainFallReportId"
    class="contentLayout"
>
    <div class="topmenu">
        <div class="com-width">
            <app-multi-select-auto-complete
                [items]="filterLocations"
                [preselectedItems]="preSelected"
                #locationSelector
                [isShowAll]="true"
                (currentlySelectedItems)="selectedLocations($event)"
                placeholder=" {{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}"
            >
            </app-multi-select-auto-complete>
        </div>
        <div>
            <mat-form-field class="com-width pad-l-15">
                <mat-select
                    #userSelection
                    placeholder="{{ 'COMMON.STORMS' | translate }}"
                    [(ngModel)]="selectedStorm"
                    (selectionChange)="selectStorm(selectedStorm)"
                >
                    <mat-option
                        *ngFor="let each of storms; trackBy: trackByGuid"
                        [value]="each.guid"
                        name="storms"
                    >
                        {{ each.start }} - {{ each.end }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin pad-r-15">
            <div class="daily-date-picker-hint com-width">
                <app-date-range-picker
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [quickOneYear]="false"
                    [quickSixMonths]="false"
                    (emitDateChanged)="updateDateRange($event)"
                    (emitClose)="datepickerOnClose()"
                ></app-date-range-picker>
            </div>
        </div>
    </div>
    <mat-card class="percent-full-content">
        <app-loader [isLoading]="!showrainFallReport"></app-loader>
        <div
            class="dont-print no-data-found-section"
            *ngIf="showrainFallReport && errorData"
        >
            <div class="notabledataFound custom-table print-nodata-space">
                <div class="no-data-found-img"></div>
                <span *ngIf="errorData === 1">{{ 'REPORT.RAINFALL_FREQ.NO_STORMS' | translate }}</span>
                <span *ngIf="errorData === 2">{{ 'REPORT.RAINFALL_FREQ.NO_LOCATIONS' | translate }}</span>
                <span *ngIf="errorData === 3">{{ 'REPORT.RAINFALL_FREQ.NO_DDFTABLE' | translate }}</span>
            </div>
        </div>
        <mat-card-header>
            <div
                class="percentTitleHint"
                *ngIf="showrainFallReport && !errorData"
            >
                {{ 'REPORT.RAINFALL_FREQ.TITLE' | translate }}
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button
                    class="uptimeDetailsDownloadHint"
                    (click)="downloadCSV()"
                    mat-button
                    [disabled]="!data|| data?.length === 0"
                >
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div *ngIf="showrainFallReport && !errorData">
                <div class="tableScrollWraper">
                    <mat-table
                        [dataSource]="reportDataSource"
                        matSort
                        (matSortChange)="sortData($event)"
                    >
                        <ng-container matColumnDef="location">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                {{ 'REPORT.RAINFALL_FREQ.TABLE.col1' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{ column.name }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="start">
                            <mat-header-cell *matHeaderCellDef>
                                {{ 'REPORT.RAINFALL_FREQ.TABLE.col2' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{ column.start }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="end">
                            <mat-header-cell *matHeaderCellDef>
                                {{ 'REPORT.RAINFALL_FREQ.TABLE.col3' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{ column.end }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="hours">
                            <mat-header-cell *matHeaderCellDef>
                                {{ 'REPORT.RAINFALL_FREQ.TABLE.col4' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{ column.hrs }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="minutes">
                            <mat-header-cell *matHeaderCellDef>
                                {{ 'REPORT.RAINFALL_FREQ.TABLE.col5' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{ column.mins }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col6' | translate }}</div>
                                <div>({{unit}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.total | number:'.2-2') : (column.total | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="avg">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col7' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.avgint | number:'.2-2') : (column.avgint | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="one">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col8' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int1 | number:'.2-2') : (column.int1 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="two">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col9' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int2 | number:'.2-2') : (column.int2 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="five">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col10' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int5 | number:'.2-2') : (column.int5 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="ten">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col11' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int10 | number:'.2-2') : (column.int10 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="twentyfive">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col12' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int25 | number:'.2-2') : (column.int25 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="fifty">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col13' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int50 | number:'.2-2') : (column.int50 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="onehundred">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col14' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int100 | number:'.2-2') : (column.int100 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="twohundred">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col15' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int200 | number:'.2-2') : (column.int200 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="fivehundred">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col16' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int500 | number:'.2-2') : (column.int500 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="thousand">
                            <mat-header-cell *matHeaderCellDef>
                                <div>{{ 'REPORT.RAINFALL_FREQ.TABLE.col17' | translate }}</div>
                                <div>({{unithr}})</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{unit === 'in' ? (column.int1000 | number:'.2-2') : (column.int1000 | number:'.1-1')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="freq">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                {{ 'REPORT.RAINFALL_FREQ.TABLE.col18' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let column; let i = index">{{ column.rtnfreq }}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
                    </mat-table>
                    <mat-paginator
                        class="mat-paginator-sticky"
                        #rainFallPaginator
                        [length]="filterLocations.length"
                        [pageSize]="pageSize"
                        (page)="pageChange($event)"
                        [pageSizeOptions]="[10, 50, 100, 500, 1000]"
                    >
                    </mat-paginator>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>