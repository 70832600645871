import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChildren,
    QueryList,
    HostListener,
    OnDestroy,
    Inject
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LEVEL_ENTITY, NOTIFICATION_TIMEOUT } from 'app/shared/constant';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { HydrographService } from 'app/shared/services/hydrograph.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { GraphConfiguration, SeriesProperty, CustomGraphs, GraphConfigurationNoData, CustomDashboardGraphType, CustomDashboardGraphObject } from './custom-dashboard-model/custom-dashboard.model';
import { CustomDashboardService } from './custom-dashboard.service';
import { AdsCustomDashboardModelComponent } from './custom-dashboard-model/custom-dashboard-model.component';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { customerQueryParam, locationIdQueryParam } from 'app/shared/models/customer';
import { BasicSeriesData, DataType, HGGraphData } from 'app/shared/models/hydrographNEW';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { ScatterData } from 'app/shared/models/scatter-data';
import { AdvanceScattergraphComponent } from 'app/pages/view-data/graphs/advance-scattergraph/advance-scattergraph.component';
import { AnsrEntityGroup, LocationEntitiesData, LocationListArgs, SeriesEntityGroup } from 'app/shared/models/locations-entities-data';
import { LocationService } from 'app/shared/services/location.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LightningChartBuilder } from 'app/shared/components/hydrograph/lightning-chart-builder/lightning-chart-builder';
import { LightningChartObject } from 'app/shared/components/hydrograph/lightning-chart-object/lightning-chart-object';
import { ChartSerieType, GraphChartConfiguration, GraphSerieConfiguration, GraphYAxisConfiguration } from 'app/shared/components/hydrograph/hydrograph-data/hydrograph-data-model';
import { LightningChartConfig } from 'app/shared/components/hydrograph/lightning-chart-builder/lightning-chart-data-model';
import { DOCUMENT } from '@angular/common';
import { TrackBy } from 'app/shared/utils/track-by';

enum GraphType {
    Hydrograph = 0,
    Scattergraph = 1
}

@Component({
    selector: 'ads-prism-custom-dashboard',
    templateUrl: './custom-dashboard.component.html',
    styleUrls: ['./custom-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsCustomDashboardComponent implements OnInit, OnDestroy {
    @ViewChildren(AdvanceScattergraphComponent) public scatterGraphList: QueryList<AdvanceScattergraphComponent>;

    public GraphType = CustomDashboardGraphType;
    @HostListener('window:beforeprint', ['$event']) public onBeforePrint(event?) {
        setTimeout(() => {
            // this.hydrographChartList.forEach((hgComponent: HydrographNEWComponent) => {
            //     const chart = hgComponent.getChart();

            //     chart.setSize(650, 450, false);
            // });

            this.scatterGraphList.forEach((sgComponent: AdvanceScattergraphComponent) => {
                sgComponent.chart.setSize(650, 600, false);
            });
        }, 0);
    }
    @HostListener('window:afterprint', ['$event']) public onAfterPrint(event) {
        // After printing remove hard sizes on charts
        setTimeout(() => {
            // this.hydrographChartList.forEach((hgComponents: HydrographNEWComponent) => hgComponents.getChart().setSize(null, null));
            this.scatterGraphList.forEach((sgComponents: AdvanceScattergraphComponent) => sgComponents.chart.setSize(null, null));
        }, 0);
    }

    public multiLocationDashboardDataSource = new MatTableDataSource([]);
    public removeConfirmationTitle: string;
    public removeConfirmationMessage: string;
    public customerId: number;
    public graphId: string;
    public locationId: number;
    public deleteSuccess: string;
    public dismissText: string;
    public deleteFailure: string;
    public entityLevelText: string;
    public isLoading: boolean;
    public graphConfiguration: GraphConfiguration = new GraphConfiguration();
    public selectedItems: Array<SeriesProperty>;
    public locationName: string;
    public graphsObject: CustomDashboardGraphObject[] = [];

    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date();

    public btnCancel: string;
    public btnRemove: string;
    public btnYes: string;
    public btnNo: string;
    public graphConfigurations: Array<GraphConfiguration> = [];
    public showGraph = true;
    public snackbarMessage: string;
    public reflowChart = true;
    public scatterGraphConfirmationData = {};

    // private members
    private autoCorrectedTitle: string;
    private subscriptions: Array<Subscription> = [];
    private isDisplayAutoCorrectedData: boolean;
    public customerDateFormat: string;
    public scattergraphLoading: boolean;
    public scatterGraphData = [];
    public scatterDateFormat: string;
    public tooltipTimeFormat: string;
    public scatterTimeFormat: string;
    public dateFormat: string;
    private editingSGIndex: number = null;
    private editingHGIndex: number = null;

    // #22592 cache to store axis labels
    private graphCache: {
        [index: string]: {
            xLabel: string;
            yLabel: string;
        }
    }[];

    readonly OTHERTEXT = 'other';
    private overallEntityGroupingInformation: (SeriesEntityGroup | AnsrEntityGroup)[];

    // #22524 Store entities (to map them with precendence)
    private locationEntityData: LocationEntitiesData;
    private dialogRef: MatDialogRef<AdsCustomDashboardModelComponent>;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private dialog: MatDialog,
        public cdr: ChangeDetectorRef,
        private customDashboardService: CustomDashboardService,
        private activatedRoute: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private statusCodeService: StatusCodeService,
        private snackBar: MatSnackBar,
        public dateutilService: DateutilService,
        private translate: TranslateService,
        private hydrographService: HydrographService,
        private router: Router,
        private viewDataService: ViewDataService,
        private locationService: LocationService,
        private domOperationUtils: DomOperationUtilsService,
        private lightningChartBuilder: LightningChartBuilder,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.applyTranslations();
    }

    public ngOnInit() {
        this.initializeObservableValues();
        // Getting customer dateformat from dateUtil Service
        const dateSubs = combineLatest([this.dateutilService.dateFormat, this.dateutilService.timeFormat]).subscribe(() => {
            this.dateFormat = this.dateutilService.getFormat();
            const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
            // this.customerDateFormat = is12HourFormat ? `${dateFormat} ${'hh:mm a'}` : `${dateFormat} ${'HH:mm'}`;
            this.customerDateFormat = `${this.dateFormat} ${this.dateutilService.getTimeFormatWithoutSeconds()}`;
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        this.subscriptions.push(dateSubs);
        this.scatterDateFormat = this.dateutilService.getGraphDateFormat();
        const preSelectedDateTimeFormat = this.viewDataService.dateTimeFormats.getValue();
        if (preSelectedDateTimeFormat) {
            this.tooltipTimeFormat = preSelectedDateTimeFormat.tooltipTimeFormat;
        } else {
            // subscribe to changes in dateFormat
            this.subscriptions.push(
                this.dateutilService.dateFormat.subscribe(() => {
                    // Getting customer dateformat from dateUtil Service
                    this.scatterDateFormat = this.dateutilService.getGraphDateFormat();
                    const timeSubscription = this.dateutilService.timeFormat.subscribe((value) => {
                        this.tooltipTimeFormat = this.scatterTimeFormat = value;
                    });
                    this.subscriptions.push(timeSubscription);
                }),
            );
        }

        const isBlackTheme = this.statusCodeService.userInfoThemeBS.getValue();
        if(isBlackTheme) {
            StringUtils.setHighChartTheme();
        } else {
            StringUtils.setHighchartWhiteTheme();
        }

        this.subscriptions.push(this.domOperationUtils.openCustomDashboardDialog.subscribe((shouldOpen: boolean) => {
            if (shouldOpen) {
                this.openCustomDashboardModal();
            } else if (this.dialogRef) {
                this.dialogRef.close();
            }
        }));
    }

    public ngOnDestroy() {
        this.graphsObject.forEach((graph: CustomDashboardGraphObject, graphIndex) => {
            const isHydrograph = graph.graphType === CustomDashboardGraphType.Hydrograph;
            if(isHydrograph) {
                graph.chart?.destroy();
            }
        });

        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach((sub) => sub.unsubscribe());
        }
        this.graphsObject = [];
    }

    // #22524 Need to fetch entities to be able to map entity with precendence
    private loadEntities() {
        const loadEntitiesSubscription = this.locationService
            .getLocationData(<LocationListArgs>{
                cid: this.customerId,
                IncludeInactiveLocations: false,
            }).subscribe((entityData: LocationEntitiesData | never) => {
                if (!entityData) {
                    loadEntitiesSubscription.unsubscribe();
                    return;
                }

                entityData.l = entityData.l.map((l) => {
                    if (l.s === 'Echo') {
                        l.s = 'ECHO';
                    }
                    return l;
                });
                entityData.d = entityData.d.map((d) => {
                    if (d.s === 'Echo') {
                        d.s = 'ECHO';
                    }
                    return d;
                });
                this.locationEntityData = entityData;
                this.renderGraphs(this.customerId);
                loadEntitiesSubscription.unsubscribe();
            });
    }


    private formatDataForGraph(graphConfig: GraphConfiguration[]) {
        const updateGraph = this.graphsObject;
        const dirtyGraphs = [];
        let dateRange: number[];
        let headerDateRange: number[];
        let seriesData: GraphSerieConfiguration;
        let graphData: GraphSerieConfiguration[];
        let originalData: BasicSeriesData[];
        let graphSGIndex: number = this.editingSGIndex !== null ? this.editingSGIndex : 0;
        let graphHGIndex: number = this.editingHGIndex !== null ? this.editingHGIndex : 0;

        this.graphCache = [];

        graphConfig.forEach((graph: GraphConfiguration, graphIndex) => {
            originalData = [];
            graphData = [];
            // Thanks to how data is stored these are different...
            if (!graph.rolling) {
                const sdStr = String(graph.startDate);
                const edStr = String(graph.endDate);
                const sdInd = sdStr.indexOf('+');
                const edInd = edStr.indexOf('+');

                if(sdInd !== -1) graph.startDate = sdStr.substring(0, sdInd);
                if(edInd !== -1) graph.endDate = edStr.substring(0, edInd);
            } else {
                const sdStr = String(graph.startDate);
                const edStr = String(graph.endDate);
                if(sdStr.indexOf('Z') > 0) graph.startDate = sdStr.substring(0, sdStr.indexOf('Z'));
                if(edStr.indexOf('Z') > 0) graph.endDate = edStr.substring(0, edStr.indexOf('Z'));
            }

            const d1 = new Date(graph.startDate);
            const d1off = d1.getTimezoneOffset() * 60 * 1000; // in ms
            const d2 = new Date(graph.endDate);
            const d2off = d2.getTimezoneOffset() * 60 * 1000; // in ms
            dateRange = [d1.getTime(), d2.getTime()];
            headerDateRange = [
                d1.getTime() - d1off,
                d2.getTime() - d2off,
            ];

            graph.isLoading = true;

            const isHydrograph = graph.graphType === CustomDashboardGraphType.Hydrograph;

            if (isHydrograph) {
                
                graph.data.forEach((data, index) => {
                    const series = graph.series[index];
                    const eid = series.eid;
                    const lid = series.lid;
                    const thisLocation = this.locationEntityData.l.find((x) => x.lid === lid);

                    if (!thisLocation) {
                        graph.inactiveLocation = true;
                    }

                    const monitorType = thisLocation && thisLocation.s ? thisLocation.s.toLowerCase() : null;
                    const ansrEntities = thisLocation ? thisLocation.ae : [];
                    let entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === monitorType);
                    if (!entityGroups) {
                        entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === this.OTHERTEXT);
                    }
                    if (data === null) {
                        if(!graph.noData) {
                            graph.noData = [];
                        }

                        // #22994 find out entity name, there is none passed
                        let entityName = null;
                        if(entityGroups) {
                            for(const g of entityGroups.g) {
                                for(const e of g.entities) {
                                    if(e.id === eid) {
                                        entityName = e.e;
                                        break;
                                    }
                                }
                                if(entityName !== null) {
                                    break;
                                }
                            }
                        }
                        graph.noData.push({location: series['locationName'], eidXName: entityName})
                        return;
                    }

                    this.overallEntityGroupingInformation = [...(entityGroups ? entityGroups.g : []), ...ansrEntities];

                    const entityInformation = this.overallEntityGroupingInformation.find((y) =>
                        y.entities.some((z) => z.id === Math.abs(eid)),
                    );

                    // #40157 LEVEL entity has the same display group as DEPTH but we want the graph to show Level as the label.
                    // Might think about mapping our entity IDs from constant.ts to string values to do this for all entities in the future.
                    if (data.entityIds[0] === LEVEL_ENTITY) {
                        seriesData = {
                            name: data.displayGroups[0].label,
                            showInLegend: true,
                            hasLegend: true,
                            hasTooltip: true,
                            color: data.color,
                            data: this.convertCompactData(data.compactData),
                            id: data.entityIds[0],
                            displayGroupId: data.displayGroups[0].id,
                            dataType: entityInformation ? entityInformation.precendence : null,
                            entityId: data.entityIds[0],
                            entityName: data.title + ' ' + this.entityLevelText,
                            precision: data.displayGroups[0].precision,
                            unitOfMeasure: data.displayGroups[0].unit,
                            serieType: ChartSerieType.Column
                        };
                    } else {
                        seriesData = {
                            name: data.displayGroups[0].label,
                            showInLegend: true,
                            hasLegend: true,
                            hasTooltip: true,
                            color: data.color,
                            data: this.convertCompactData(data.compactData),
                            id: data.entityIds[0],
                            displayGroupId: data.displayGroups[0].id,
                            dataType: entityInformation ? entityInformation.precendence : null,
                            entityId: data.entityIds[0],
                            entityName: data.title + ' (' + data.displayGroups[0].entities[0].name + ')',
                            precision: data.displayGroups[0].precision,
                            unitOfMeasure: data.displayGroups[0].unit,
                            serieType: ChartSerieType.Column
                        };
                    }
                   

                    const originalSeriesData = {
                        ...seriesData,
                        type: 'line',
                        dataType: entityInformation ? entityInformation.precendence : null,
                        annotations: [],
                        axisName: data.displayGroups[0].label,
                        displayGroupId: data.displayGroups[0].id,
                        entityId: data.entityIds[0],
                    } as BasicSeriesData;

                    originalData.push(originalSeriesData);
                    graphData.push(seriesData);
                });
                graph.isLoading = false;
                graph.index = graphHGIndex;
                graphHGIndex++;



            } else if (graph.graphType === CustomDashboardGraphType.Scattergraph) {
                graph.dataLoading = new BehaviorSubject(true);
                graph.noData = [];
                graph.series.forEach((config) => {
                    this.getScatterGraphData(graph, config, graphSGIndex)
                })
                graph.index = graphSGIndex;
                graphSGIndex++;
            }

            const affectedGraph = updateGraph.findIndex((x) => x.graphId === graph.graphId);
            if (affectedGraph !== -1) {
                updateGraph[affectedGraph] = {
                    name: graph.name,
                    graphId: graph.graphId,
                    isExpand: false,
                    originalData: originalData,
                    config: {
                        yAxis: [],
                        series: graphData,
                        graphGrouping: null
                    },
                    dates: dateRange,
                    dataAverage: graph.dataAverage,
                    isLoading: graph.dataLoading,
                    inactiveLocation: graph.inactiveLocation,
                    headerDates: headerDateRange,
                    graphType: graph.graphType === 0 || graph.graphType === '0' ? CustomDashboardGraphType.Hydrograph : CustomDashboardGraphType.Scattergraph,
                    groupings: this.createGroupings(originalData),
                    index: graph.index,
                    noData: graph.noData
                };
                dirtyGraphs.push(updateGraph[affectedGraph]);
            } else {
                updateGraph.push({
                    name: graph.name,
                    graphId: graph.graphId,
                    isExpand: false,
                    originalData: originalData,
                    config: {
                        yAxis: [],
                        series: graphData,
                        graphGrouping: null
                    },
                    dates: dateRange,
                    dataAverage: graph.dataAverage,
                    isLoading: graph.dataLoading,
                    headerDates: headerDateRange,
                    inactiveLocation: graph.inactiveLocation,
                    graphType: graph.graphType === 0 || graph.graphType === '0' ? CustomDashboardGraphType.Hydrograph : CustomDashboardGraphType.Scattergraph,
                    groupings: this.createGroupings(originalData),
                    index: graph.index,
                    noData: graph.noData
                });
                dirtyGraphs.push(updateGraph[updateGraph.length - 1]);

            }
        });

        this.graphsObject = [...updateGraph];
        this.showGraph = true;
        this.redrawCharts();
        this.uiUtilsService.safeChangeDetection(this.cdr);

        // #27825 Resize other graphs so they will fit into containers they belong to
        for(const g of updateGraph) {
            if(!dirtyGraphs.includes(g)) {

                const chart = this.getGraphChart(g);

                if(chart) {
                    chart.setSize(null, null);
                }
            }
        }
    }

    private getGraphChart(g?: {graphType: string | number, index: number}) {
        if(
            !g
            || (g.index === undefined || g.index === null)
            || (g.graphType === undefined || g.graphType === null)
        ) return null;

        const index = g.index;
        const sgGraph = g.graphType === CustomDashboardGraphType.Scattergraph ? this.scatterGraphList.get(index) : null;

        if(sgGraph && sgGraph.chart) {
            return sgGraph.chart;
        }

    }

    private getScatterGraphData(graph: GraphConfiguration, config, graphID): void {
        this.subscriptions.push(this.viewDataService
            .getScatterGraphOptimized(
                this.customerId,
                config['lid'],
                (new Date(graph.startDate)),
                (new Date(graph.endDate)),
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                [],
                this.scatterGraphConfirmationData,
                [config['eidX'], config['eidY']],
                graph.dataAverage
            )
            .subscribe(
                (scatterResult: ScatterData) => {
                    if (!scatterResult) {
                        /** Handling in case we have 204 No content in Response */
                        this.scatterGraphList.get(graphID).data = null;
                        graph.isLoading = false;
                        graph.noData.push({location: config['locationName'], eidXName: config['eidXName'], eidYName: config['eidYName']})
                        this.uiUtilsService.safeChangeDetection(
                            this.cdr
                        );
                        return;
                    }

                    // #22592 use cache to determine if label should be displayed or not
                    const graphId = graph.graphId;
                    if(!this.graphCache[graphId]) this.graphCache[graphId] = {};

                    if(this.graphCache[graphId].xLabel || this.graphCache[graphId].xLabel === '') {
                        if(this.graphCache[graphId].xLabel !== scatterResult.xAxis.label) {
                            scatterResult.xAxis.label = '';
                            this.graphCache[graphId].xLabel = '';
                        }
                    } else {
                        this.graphCache[graphId].xLabel = scatterResult.xAxis.label;
                    }

                    if(this.graphCache[graphId].yLabel || this.graphCache[graphId].yLabel === '') {
                        if(this.graphCache[graphId].yLabel !== scatterResult.yAxis.label) {
                            scatterResult.yAxis.label = '';
                            this.graphCache[graphId].yLabel = '';
                        }
                    } else {
                        this.graphCache[graphId].yLabel = scatterResult.yAxis.label;
                    }

                    this.scatterGraphList.get(graphID).displayScatterGraphCustom(scatterResult, config);
                    graph.dataLoading.next(false);
                    graph.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                error => {
                    graph.noData.push({location: config['locationName'], eidXName: config['eidXName'], eidYName: config['eidYName']})
                    this.scattergraphLoading = false;
                    this.scatterGraphData = null;
                    graph.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            ));
    }

    public setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    private convertCompactData(data) {
        const convertedData: HGGraphData[] = [];
        Object.keys(data).forEach(function (key) {
            if (!data[key].includes('{')) {
                convertedData.push({ x: +key * 1000, y: +data[key].split(':')[1].split(']')[0] });
            } else {
                convertedData.push({
                    x: +key * 1000,
                    y: +data[key]
                        .split(':')[1]
                        .replace(/[{()}\]]/g, '')
                        .split(',')[0],
                });
            }
        });
        return convertedData;
    }

    public mouseOutFromHydro() {
        this.viewDataService.highlightScatterPoint.next(null);
    }

    private createGroupings(graphData: BasicSeriesData[]) {
        const groupings = [[], [], [], []];

        graphData.filter((x) => x.dataType === DataType.Depth).forEach((x) => groupings[0].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.Velocity).forEach((x) => groupings[0].push(x.entityId));

        graphData.filter((x) => x.dataType === DataType.Quantity).forEach((x) => groupings[1].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.Rain).forEach((x) => groupings[1].push(x.entityId));

        graphData.filter((x) => x.dataType === DataType.Temperature).forEach((x) => groupings[2].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.Voltage).forEach((x) => groupings[2].push(x.entityId));

        graphData.filter((x) => x.dataType === DataType.PumpFlow).forEach((x) => groupings[3].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.RainIntensity).forEach((x) => groupings[3].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.ElapsedTime).forEach((x) => groupings[3].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.Feet).forEach((x) => groupings[3].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.Sample).forEach((x) => groupings[3].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.TotalFlow).forEach((x) => groupings[3].push(x.entityId));
        graphData.filter((x) => x.dataType === DataType.Other).forEach((x) => groupings[3].push(x.entityId));

        return groupings;
    }

    public openCustomDashboardModal(graphConfiguration?: GraphConfiguration) {
        if(!graphConfiguration) {
            this.editingSGIndex = this.scatterGraphList.length;
        }

        this.domOperationUtils.customDashboardDialogOpened = true;
        this.dialogRef = this.dialog
            .open(AdsCustomDashboardModelComponent, {
                disableClose: true,
                data: graphConfiguration || <GraphConfiguration>{},
                hasBackdrop: false
            });

            this.dialogRef.afterClosed()
            .subscribe((result) => {
                this.dialogRef = null;
                this.isLoading = false;
                this.domOperationUtils.customDashboardDialogOpened = false;
                if (result && result.data && result.data.length && this.graphsObject) {
                    if (result.data[0].graphType === CustomDashboardGraphType.Hydrograph) {
                        this.editingSGIndex = null;
                    } else {
                        this.editingHGIndex = null;
                    }
                    this.formatDataForGraph(result.data);
                    this.redrawCharts();
                }

                this.createHydrographs();
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });
    }

    public editGraph(graphId: string, index: number) {
        this.editingSGIndex = index;
        this.customDashboardService.getGraphsByConfigurationId(this.customerId, graphId).subscribe(
            (result: GraphConfiguration) => {
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.redrawCharts();
                this.openCustomDashboardModal(result);
            },
            (error) => {
                this.snackBar.open('', this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                });
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    public removeGraph(graphId?: string) {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.removeConfirmationTitle,
                    message: this.removeConfirmationMessage,
                    cancelText: this.btnCancel,
                    okText: this.btnRemove,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res && res.whichButtonWasPressed.toLowerCase() === 'ok') {
                    this.isLoading = true;
                    this.customDashboardService.deleteGraphByConfigurationId(this.customerId, graphId).subscribe(
                        (result) => {
                            const removeIndex = this.graphsObject.findIndex((graph) => graph.graphId === graphId);
                            this.graphsObject.splice(removeIndex, 1);
                            this.showGraph = this.graphsObject && this.graphsObject.length > 0;
                            this.snackBar.open(this.deleteSuccess, this.dismissText, {
                                duration: NOTIFICATION_TIMEOUT,
                            });

                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);

                            // #27825 Resize other graphs so they will fit into containers they belong to
                            for(const g of this.graphsObject) {
                                const chart = this.getGraphChart(g);

                                if(chart) {
                                    chart.setSize(null, null);
                                }
                            }
                        },
                        (error) => {
                            this.snackBar.open(this.deleteFailure, this.dismissText, {
                                panelClass: 'custom-error-snack-bar',
                            });
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        },
                    );
                }
            });
    }

    public generatePDF() {
        // Force call because otherwise resizing doesn't happen correctly/all the time
        this.onBeforePrint();
        setTimeout(() => window.print(), 0);
    }

    public expandGraph(graphId: string) {
        const affectedGraph = this.graphsObject.find((x) => x.graphId === graphId);
        affectedGraph.isExpand = !affectedGraph.isExpand;
        this.uiUtilsService.safeChangeDetection(this.cdr);

        const chart = this.getGraphChart(affectedGraph);

        // #27825 Resize other graphs so they will fit into containers they belong to
        if(chart) {
            setTimeout(() => {
                chart.setSize(null, null);
            }, 0);
        }
    }

    private applyTranslations() {
        const translateKeys: Array<string> = [
            'COMMON.CORRECTED_TEXT',
            'DASHBOARDS.MULTI_LOCATION_DASHBOARD.REMOVE_CONFIRMATION_TITLE',
            'DASHBOARDS.MULTI_LOCATION_DASHBOARD.REMOVE_CONFIRMATION_MESSAGE',
            'COMMON.CANCEL_BUTTON',
            'COMMON.DELETE_TOOLTIP',
            'DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_DELETE_SUCCESS',
            'COMMON.DISMISS_TEXT',
            'DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_DELETE_FAILURE',
            'COMMON.ENTITY_LEVEL'
        ];
        this.translate.get(translateKeys).subscribe((translateValues) => {
            this.autoCorrectedTitle = translateValues['COMMON.CORRECTED_TEXT'];
            this.removeConfirmationTitle =
                translateValues['DASHBOARDS.MULTI_LOCATION_DASHBOARD.REMOVE_CONFIRMATION_TITLE'];
            this.removeConfirmationMessage =
                translateValues['DASHBOARDS.MULTI_LOCATION_DASHBOARD.REMOVE_CONFIRMATION_MESSAGE'];
            this.btnCancel = translateValues['COMMON.CANCEL_BUTTON'];
            this.btnRemove = translateValues['COMMON.DELETE_TOOLTIP'];
            this.deleteSuccess = translateValues['DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_DELETE_SUCCESS'];
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.deleteFailure = translateValues['DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_DELETE_FAILURE'];
            this.entityLevelText = translateValues['COMMON.ENTITY_LEVEL'];
        });
    }

    private initializeObservableValues() {
        const activatedRouteSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerId = Number(params.get(customerQueryParam));
            this.locationId = Number(params.get(locationIdQueryParam));
            this.loadEntities();
            // subscribe to changes in dateFormat
            const subscription = this.dateutilService.dateFormat.subscribe(() => {
                // Getting customer dateformat from dateUtil Service
                const timeSubscription = this.dateutilService.timeFormat.subscribe((value) => { });
                this.subscriptions.push(timeSubscription);
            });
            this.subscriptions.push(subscription);
        });
        this.subscriptions.push(activatedRouteSubscription);

        const routerSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd && this.graphConfigurations != null) {
                this.redrawCharts();
            }
        });
        this.subscriptions.push(routerSubscription);
    }

    private renderGraphs(customerId: number) {
        this.graphsObject = [];
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        const customGraphsSubscription =
        combineLatest([
            this.customDashboardService.fetchCustomDashboardGraph(customerId),
            this.statusCodeService.userInfoThemeBS
        ]).subscribe(
            ([response, darkTheme]) => {
                if (response && response.configurations && response.configurations.length) {
                    this.formatDataForGraph(response.configurations);
                    this.showGraph = true;
                } else {
                    this.showGraph = false;
                }
                this.isLoading = false;
                this.redrawCharts();
                this.uiUtilsService.safeChangeDetection(this.cdr);

                this.createHydrographs();
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.showGraph = false;
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );

        this.subscriptions.push(customGraphsSubscription);
    }

    detectChanges() {
        this.uiUtilsService.safeChangeDetection(this.cdr)
    }

    private createHydrographs() {
        const self = this;
        this.graphsObject.forEach((graph: CustomDashboardGraphObject, graphIndex) => {
            const isHydrograph = graph.graphType === CustomDashboardGraphType.Hydrograph;
            if(isHydrograph) {
                graph.chart?.destroy();

                const config: LightningChartConfig = {
                    chartId: `${graphIndex}`,
                    lightningChartReceiver: {
                        detectChanges() {
                            self.detectChanges();
                        }
                    },
                    seriesData$: of(graph.originalData),
                    darkTheme: this.statusCodeService.userInfoThemeBS.getValue(),
                    startDate: new Date(graph.dates[0]),
                    endDate: new Date(graph.dates[1]),
                    dataAveraging: graph.dataAverage
                }
                const yAxisAssoc = [];
                const yAxis = [];
                for(const serie of graph.config.series) {
                    if(yAxisAssoc[serie.id]) {
                        serie.yAxis = yAxisAssoc[serie.id].index;
                        continue;
                    }

                    const yAxisDef: GraphYAxisConfiguration = {
                        title: `${serie.name} (${serie.unitOfMeasure})`,
                        chartIndex: 0
                    }

                    yAxisAssoc[serie.id] = {...yAxisDef, index: yAxis.length};
                    yAxis.push(yAxisDef);
                    serie.yAxis = yAxisAssoc[serie.id].index;

                }
                graph.config.yAxis = yAxis;

                graph.chart = this.lightningChartBuilder.createDashboard(config, graph.config);
            }
        });
    }


    private redrawCharts() {
        this.reflowChart = !this.reflowChart;
    }
}
