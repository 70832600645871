<div class="sli-header">
    <h4 class="graph-heading">
        {{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH_HEADING' | translate }}
    </h4>
    <div class="spacer"></div>
    <div class="form-undo-offset">
        <span class="pad-r-15">{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.SELECT_OPTION' | translate }}</span>
        <mat-form-field>
            <mat-select [(ngModel)]="selectedRainEvent" (ngModelChange)="rainEventChanged()">
                <mat-option *ngFor="let event of allRainEvents; trackBy: trackByIndex" [value]="event">
                    {{ event }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="sli-tabs">
    <mat-tab-group>
        <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_CHARACTERISTICS' | translate }}">
            <div class="data-head">
                <div class="duration">{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.DURATION' | translate }}</div>
                <div class="rainfall">
                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH' | translate }} ({{ unit }})
                </div>
            </div>
            <div id="rainfall-event-table" class="mat-elevation-z4">
                <mat-table #table [dataSource]="rainfallmonitorDataSource" matSort>
                    <!-- rainfallmonitorColumns = [Duration, rainDuration, MonitorName1, MonitorName2...] -->
                    <ng-container *ngFor="let columnDef of rainfallTableColumnDefinitions; trackBy: trackByIndex">
                        <ng-container matColumnDef="{{ columnDef.definition }}">
                            <ng-container *ngIf="columnDef.visible">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ columnDef.title }}
                                    <ng-container *ngIf="columnDef.titleExtra">
                                        <br />{{ columnDef.titleExtra | number: rainPrecision }}
                                    </ng-container>
                                </mat-header-cell>
                            </ng-container>

                            <ng-container *ngIf="!columnDef.visible">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            </ng-container>

                            <mat-cell *matCellDef="let element">
                                <ng-container *ngIf="!columnDef.visible && element[columnDef.definition]">
                                    {{ element[columnDef.definition] }}
                                </ng-container>
                                <ng-container *ngIf="columnDef.visible && element[columnDef.definition]">
                                    {{ element[columnDef.definition] | number: rainPrecision }}
                                </ng-container>
                                <span *ngIf="!element[columnDef.definition]">-</span>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="rainfallTableColumnNames"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: rainfallTableColumnNames"></mat-row>
                </mat-table>
            </div>
            <div *ngIf="!dataExists" class="notabledataFound custom-table print-nodata-space">
                <div class="no-ddf-found-img"></div>
                <span>{{ 'REPORT.RAINFALL_FREQ.NO_DDFTABLE' | translate }}</span>
            </div>
        </mat-tab>

        <!-- Rainfall Depth Duration Frequency -->
        <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DDF' | translate }}">
            <div class="horizontal">
                <ng-template mat-tab-label>
                    <div class="edit-cus-ddf-data-tab-hint">
                        {{'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DDF' | translate}}
                    </div>
                </ng-template>
                <div class="rainfall-content">
                    <div class="auto-margin">
                        <button
                            (click)="openUploadDepthDurationDialog($event)"
                            [disabled]="isLocked"
                            class="upload-button"
                            mat-raised-button
                        >
                            {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.UPLOAD_BUTTON' | translate }}
                        </button>
                        {{
                        'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.UPLOAD_BUTTON_CONTENT'
                        | translate
                        }}
                    </div>
                    <div class="app-flex-filler"></div>
                    <div
                        style="display: none"
                        class="auto-margin column-filter"
                    >
                        <button
                            mat-button
                            [matMenuTriggerFor]="menu"
                            class="select-box"
                        >
                            {{'SLIICER_TABLE.CHOOSE_COLUMNS_TO_HIDE' | translate}}
                        </button>
                        <mat-menu
                            #menu="matMenu"
                            yPosition="below"
                            xPosition="before"
                        >
                            <div class="select-container">
                                <mat-selection-list
                                    #ddfOptions
                                    [(ngModel)]="selectedOptions"
                                    (ngModelChange)="ddfFilter($event)"
                                    (click)="$event.stopPropagation()"
                                >
                                    <mat-list-option
                                        *ngFor="let option of filterOptions; trackBy: trackByIndex"
                                        [value]="option"
                                    >
                                        {{ option }}
                                    </mat-list-option>
                                </mat-selection-list>
                            </div>
                        </mat-menu>
                    </div>
                </div>
                <!-- rainfalldurationtable -->
                <div class="rainfalldurationtable">
                    <div class="ddfcolumns-checkbox-container">
                        <mat-checkbox
                            *ngFor="let column of ddfTableColumnDefs; let rowIndex = index; trackBy: trackByIndex"
                            (change)="onShowDDFColumnChange(column.name, $event)"
                            [checked]="column.show"
                        >{{ column.name }}</mat-checkbox>
                    </div>
                    <mat-table
                        #table
                        [dataSource]="ddfTableSource"
                        matSort
                        class="edit-cus-ddf-data-table-hint"
                    >
                        <ng-container matColumnDef="checked">
                            <mat-header-cell *matHeaderCellDef>
                                <mat-checkbox
                                    [checked]="isDepthDurationHeaderChecked"
                                    (change)="depthDurationCheckBoxAll($event)"
                                    name="depthDurationCheckbox"
                                ></mat-checkbox>
                            </mat-header-cell>
                            <!-- <mat-cell *matCellDef="let element; let i = index;">
                                    <mat-checkbox [(ngModel)]="element.delete" (change)="rowSelection(i,element.delete)"></mat-checkbox>
                                </mat-cell> -->
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-checkbox
                                    [(ngModel)]="element.checked"
                                    [checked]="element.checked"
                                    (change)="depthDurationCheckBox($event)"
                                    name="depthDurationCheckbox"
                                ></mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="duration">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_1'
                                | translate
                                }}</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <div class="rainfall-content">
                                    <div class="duration-value">
                                        <mat-form-field>
                                            <input
                                                #durationMinutesInputs
                                                matInput
                                                [disabled]="isLocked"
                                                [(ngModel)]="element.durationMinutes"
                                                required
                                                name="durationMinutes{{ i + 1 }}"
                                                id="{{INPUT_DURATION_ID}}{{i}}_0"
                                                type="number"
                                                (change)="onDdfChanges()"
                                                (click)="setColumn(0)"
                                            />
                                        </mat-form-field>
                                    </div>
                                    <div class="duration-units">
                                        <mat-form-field>
                                            <mat-select
                                                [(ngModel)]="element.duration"
                                                [disabled]="isLocked"
                                                (selectionChange)="onDurationUnitChange($event, i)"
                                                name="duration{{ i + 1 }}"
                                            >
                                                <mat-option
                                                    *ngFor="let selectvalue of durationOptions; trackBy: trackByIndex"
                                                    [value]="selectvalue"
                                                >{{ selectvalue }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="2mo">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_2'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_1"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.month2"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(1)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="3mo">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_3'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_2"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.month3"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(2)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="4mo">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_4'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_3"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.month4"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(3)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="6mo">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_5'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_4"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.month6"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(4)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="9mo">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_6'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_5"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.month9"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(5)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="1yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_7'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_6"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year1"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(6)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="2yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_8'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_7"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year2"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(7)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="5yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_9'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_8"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year5"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(8)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="10yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_10'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_9"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year10"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(9)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="25yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_11'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_10"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year25"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(10)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="50yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_12'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_11"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year50"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(11)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="100yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_13'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_12"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year100"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(12)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="200yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_14'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_13"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year200"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(13)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="500yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_15'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_14"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year500"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(14)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="1000yr">
                            <mat-header-cell *matHeaderCellDef>{{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_16'
                                | translate
                                }}
                                ({{ unit }})</mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index">
                                <mat-form-field>
                                    <input
                                        matInput
                                        id="{{INPUT_DURATION_ID}}{{i}}_15"
                                        [disabled]="isLocked"
                                        [(ngModel)]="element.year1000"
                                        type="number"
                                        placeholder="-"
                                        (change)="onDdfChanges()"
                                        (click)="setColumn(15)"
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="rainfallDataColumns"></mat-header-row>
                        <mat-row
                            *matRowDef="let row; columns: rainfallDataColumns; let i = index"
                            (click)="setPointer(i)"
                            (keydown.arrowdown)="arrowDownEvent($event, i)"
                            (keydown.arrowup)="arrowUpEvent($event, i)"
                            (keydown.arrowleft)="arrowLeftEvent($event, i)"
                            (keydown.arrowright)="arrowRightEvent($event, i)"
                            (keydown.Tab)="arrowRightEvent($event, i)"
                        ></mat-row>
                    </mat-table>
                    <mat-card-actions>
                        <div class="auto-margin">
                            <button
                                #addNewRowBtn
                                [disabled]="isLocked"
                                mat-button
                                class="edit-cus-ddf-data-add-hint"
                                (click)="addNewRow()"
                            >
                                {{'COMMON.ADD_ROW' | translate}}
                            </button>
                            <button
                                [disabled]="isLocked"
                                mat-button
                                class="edit-cus-ddf-data-delete-hint"
                                (click)="deleteRow()"
                                *ngIf="!deleteButton"
                            >
                                {{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.DELETE_ROW_BUTTON'
                                | translate
                                }}
                            </button>
                            <button
                                mat-button
                                class="edit-cus-ddf-data-delete-hint"
                                (click)="cancel()"
                                *ngIf="cancelButton"
                            >
                                {{'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.CANCEL_ADDED_ROW' | translate}}
                            </button>
                            <button
                                mat-button
                                class="edit-cus-ddf-data-delete-hint"
                                (click)="deleteSelectedRow()"
                                *ngIf="deleteButton"
                            >
                                {{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.DELETED_ROW_BUTTON'
                                | translate
                                }}
                            </button>
                            <button
                                mat-button
                                (click)="clearSelectedRow()"
                                *ngIf="deleteButton"
                            >
                                {{
                                'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.CANCEL_DELETE_ROWS'
                                | translate
                                }}
                            </button>
                        </div>
                    </mat-card-actions>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.GRAPH' | translate }}">
            <div class="rainGaugeContent">
                <div class="graph-container" id="rainfall-ddf-graph">
                    <ads-ddf-graph
                        #ddfGraph
                        [showLegend]="false"
                        [gaugeData]="gaugeData"
                        (legendClick)="onClickOfLegend($event)"
                    ></ads-ddf-graph>
                </div>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>
