<div class="minmaxavgtotaltable">
    <mat-accordion>
        <mat-expansion-panel [(expanded)]="isExpanded" (opened)="expandTable()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <b>{{ 'COMMON.SUMMARY' | translate }}</b>
                    <!-- new page icon -->
                    <button *ngIf="false" mat-icon-button class="new-page-icon">
                        <mat-icon matTooltip="New Page">open_in_new</mat-icon>
                    </button>

                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="table-scroll-wraper" *ngIf="isExpanded">
                <div>
                    <div *ngIf="isLoading">
                        <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
                    </div>
                </div>
                <div *ngIf="!isLoading && (!data || !data.statistics || !data.statistics.length)">
                    {{ 'COMMON.NO_DATA_AVAILABLE' | translate }}
                </div>
                <div *ngIf="!isLoading && data && data.statistics && data.statistics.length" id="location-dashbord-synopsis-leaderBoard">
                    <mat-tab-group class="daily-tiles-hint" class="background-light background-padding">
                        <mat-tab class="hide">
                            <ng-template mat-tab-label class="hide"> </ng-template>
                        </mat-tab>

                        <mat-tab disabled *ngFor="let item of data.statistics; index as i; trackBy: trackByIndex" class="background-default">
                            <ng-template mat-tab-label class="background-default">
                                <div class="location-dashbord-synopsis">
                                    <div class="lB-item-top">
                                        <div class="lB-item-top-rgt">
                                           
                                                <div class="item-col padding-leftright" *ngIf="item.mn !== undefined && item.mn !== null">
                                                    Min: {{item.mn}} ({{item.unit}}) on {{ item.mnt | date: customDateFormat }}
                                                </div>
                                                <div class="item-col padding-leftright" *ngIf="item.mx !== undefined && item.mx !== null">
                                                    Max: {{item.mx}} ({{item.unit}}) on {{ item.mxt | date: customDateFormat }}
                                                </div>
                                            
                                            <div class="item-col padding-top">
                                                <div class="item-content" *ngIf="item.t !== undefined && item.t !== null">
                                                    <div class="item-value">
                                                        {{ item.t !== '-' ? item.t : '--' }} {{ item.totalUnit }}
                                                    </div>
                                                    <div class="item-text">
                                                        {{ 'REPORT.DAILY_SUMMARY.TOTAL_TEXT' | translate }}
                                                    </div>
                                                </div>
                                                <div class="item-content" *ngIf="item.a !== undefined && item.a !== null">
                                                    <div class="item-value">
                                                        {{ item.a !== '-' ? item.a : '--' }} {{ item.unit }}
                                                    </div>
                                                    <div class="item-text">
                                                        {{ 'REPORT.DAILY_SUMMARY.AVERAGE_TEXT' | translate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lB-item-but" [ngStyle]="{
                                        'color': item.fontColor,
                                        'background': item.backgroundColor,
                                        'border-top': '2px solid ' + item.backgroundColor,
                                        'border-bottom': '3px solid ' + item.backgroundColor
                                    }">
                                        <span>{{ item.name }}</span>
                                        <span></span>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
