<div id="flow-balance-dialog">
    <div class="dateRangeHeader bottom-border pad-l-0">
        <div class="auto-margin">
            <mat-icon class="backIcon" (click)="close()">arrow_back</mat-icon>
        </div>
        <div class="dateRangeText auto-margin pad-l-0">
            {{ 'FLOW_BALANCE.EDIT_FLOW_BALANCE' | translate }}
        </div>
        <div class="app-flex-filler"></div>
    </div>
    <div>
        <div class="each-flow" *ngFor="let num of locationName; let i = index; trackBy: trackByIndex" [attr.data-index]="i">
            <div class="each-item">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'COMMON.LOCATION' | translate }}"
                        [matAutocomplete]="auto"
                        matInput
                        #LocationName="ngModel"
                        [(ngModel)]="locationName[i].name"
                        (ngModelChange)="changeLocationDropdown($event, i)"
                        (input)="removeError(i)"
                        tabindex="-1"
                        [isShowAll]="false"
                        required
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of locations; trackBy: trackById" [value]="option.name">
                            {{ option.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="mat-error">
                    <span *ngIf="isLocationAlreadyAdded[i]" class="mat-error">{{
                        'HOME.MAP.COMPOSITE_LOCATION.LOCATION_ALREADY_SELECTED' | translate
                    }}</span>
                </div>
            </div>
            <div class="each-item">
                <mat-form-field class="full-width">
                    <mat-select
                        (ngModelChange)="changeFlowDropdown()"
                        name="flowDirection{{ i }}"
                        placeholder=" {{ 'COMMON.FLOW' | translate }}"
                        #LocationFlow="ngModel"
                        [(ngModel)]="locationName[i].flow"
                        ngDefaultControl
                        tabindex="-1"
                        required
                    >
                        <mat-option value="Upstream">Upstream </mat-option>
                        <mat-option value="Overflow">Overflow</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="action custom-icon-button">
                <button type="button" mat-button (click)="removeRow(i)">
                    <mat-icon class="mat-24">delete</mat-icon>
                </button>
            </div>
        </div>
        <app-loader [isLoading]="isLoading"></app-loader>
        <div class="notabledataFound custom-table" *ngIf="isMinLocationSelected">
            {{ 'COMMON.NO_LOCATION_ADDED' | translate }}
        </div>
        <div class="each-flow">
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button mat-button class="add-button" (click)="addMoreLocation()" [disabled]="isAddMoreDisabled">
                    {{ 'COMMON.ADD_MORE' | translate }}
                </button>
            </div>
        </div>
    </div>

    <mat-card-actions class="top-border">
        <div class="app-flex-filler"></div>
        <div class="auto-margin custom-flat-button">
            <button mat-button (click)="close()">{{ 'COMMON.CANCEL' | translate }}</button>
        </div>
        <div class="auto-margin custom-flat-button">
            <button mat-button [disabled]="isSaveDisabled" (click)="saveFlowBalanceDetails()">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </mat-card-actions>
</div>
