<div id="customer-user-association" class="contentLayout">
    <!-- Use this to create style sheet for international phone number location calculation -->
    <div [innerHTML]="dropdownLocation"></div>
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
    ></app-customized-confirmation-box>
    <app-loader [isLoading]="isLoading"></app-loader>

    <mat-card>
        <mat-card-header>
            <div class="auto-margin">
                <mat-icon class="backIcon" (click)="setupAdminRoute(false)">arrow_back</mat-icon>
            </div>
            <div class="auto-margin">
                {{ 'ADMIN.CUSTOMER_USER_ASSOCIATION.USER_TEXT' | translate }} : {{ userInformation.userEmail }}
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin pad-r-15">
                <span class="user-information-status">{{ 'COMMON.ACTIVE_INACTIVE' | translate }}</span>
                <mat-slide-toggle
                    class="pad-l-10 activate-user-hint"
                    matTooltipPosition="before"
                    [disabled]="!isUser || disableEditIcon"
                    (change)="activateUser(userInformation, $event)"
                    [checked]="userInformation.isActive"
                ></mat-slide-toggle>
            </div>
            <div class="auto-margin pad-r-15 edit-button custom-flat-button">
                <button
                    mat-button
                    class="edit-button-hint"
                    (click)="resetPassword(userInformation)"
                    [disabled]="!userInformation.isActive || disableEditIcon"
                >
                    {{ 'ADMIN.CUSTOMER_USER_ASSOCIATION.RESET_PASSWORD' | translate }}
                </button>
            </div>
        </mat-card-header>

        <form #customerUSerAssociationForm="ngForm">
            <mat-card-content class="customerAssociationContiner">
                <mat-tab-group
                    dynamicHeight
                    [(selectedIndex)]="domOperationUtilsService.editUserSelectedTab"
                    [ngClass]="{ 'active-hint': showpageHint && domOperationUtilsService.editUserSelectedTab === 0 }"
                >
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="userDetailsHint">{{
                                'ADMIN.CUSTOMER_USER_ASSOCIATION.USER_DETAILS' | translate
                            }}</span>
                        </ng-template>
                        <div class="custom-50p">
                            <div class="each-Item custom-error">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        [disabled]="disableInputFields || disableEditIcon"
                                        #firstNameInput="ngModel"
                                        placeholder="{{ 'COMMON.FIRST_NAME_TEXT' | translate }}"
                                        name="fistName"
                                        [(ngModel)]="userInformation.firstName"
                                        ngDefaultControl
                                        maxlength="20"
                                        [pattern]="firstNamePattern"
                                        required
                                        (ngModelChange)="isFormChanged = true"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="firstNameInput?.errors?.pattern">
                                    {{ 'COMMON.FIRST_NAME_VALIDATION' | translate }}
                                </div>
                            </div>
                            <div class="each-Item custom-error">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        [disabled]="disableInputFields || disableEditIcon"
                                        #lastNameInput="ngModel"
                                        placeholder="{{ 'COMMON.LAST_NAME_TEXT' | translate }}"
                                        name="lastName"
                                        [(ngModel)]="userInformation.lastName"
                                        ngDefaultControl
                                        maxlength="20"
                                        [pattern]="lastNamePattern"
                                        required
                                        (ngModelChange)="isFormChanged = true"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="lastNameInput?.errors?.pattern">
                                    {{ 'COMMON.LAST_NAME_VALIDATION' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="custom-50p">
                            <div class="each-Item custom-error">
                                <mat-form-field class="full-width">
                                    <div class="toggleContainer">
                                        <div class="inputfield">
                                            <input
                                                matInput
                                                [disabled]="disableInputFields || disableEditIcon"
                                                placeholder="{{
                                                    'ADMIN.CUSTOMER_USER_ASSOCIATION.Email_ID_TEXT' | translate
                                                }}"
                                                name="emailId"
                                                [(ngModel)]="userInformation.userEmail"
                                                ngDefaultControl
                                                (focusout)="
                                                    primaryEmailInput?.errors?.pattern ||
                                                    userInformation.userEmail === ''
                                                        ? (userNotification.notificationPrimaryEmail = false)
                                                        : null
                                                "
                                                (ngModelChange)="isFormChanged = true"
                                                (input)="removeError()"
                                                (blur)="onEmailChange()"
                                                #primaryEmailInput="ngModel"
                                                maxlength="64"
                                                [pattern]="emailPattern"
                                                required
                                            />
                                        </div>
                                        <div class="togglefield">
                                            <div>{{ 'COMMON.NOTIFICATIONS_TEXT' | translate }}</div>
                                            <mat-slide-toggle
                                                name="notificationPrimaryEmail"
                                                [(ngModel)]="userNotification.notificationPrimaryEmail"
                                                (ngModelChange)="isFormChanged = true"
                                                [disabled]="
                                                    disableInputFields || disableEditIcon ||
                                                    primaryEmailInput?.errors?.pattern ||
                                                    !userInformation.userEmail ||
                                                    userInformation.userEmail === ''
                                                "
                                            >
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="primaryEmailInput?.errors?.pattern">
                                    {{ 'COMMON.EMAIL_ID_VALIDATION' | translate }}
                                </div>
                            </div>
                            <div class="each-Item custom-error">
                                <mat-form-field class="full-width">
                                    <div class="toggleContainer">
                                        <div class="inputfield">
                                            <input
                                                matInput
                                                [disabled]="disableInputFields || disableEditIcon"
                                                placeholder="{{ 'COMMON.SECONDARY_Email_ID' | translate }}"
                                                [(ngModel)]="userInformation.secondaryEmail"
                                                ngDefaultControl
                                                (ngModelChange)="isFormChanged = true"
                                                (focusout)="
                                                    secondaryEmailInput?.errors?.pattern ||
                                                    userInformation.secondaryEmail === ''
                                                        ? (userNotification.notificationSecondaryEmail = false)
                                                        : null
                                                "
                                                name="secondaryEmailId"
                                                #secondaryEmailInput="ngModel"
                                                maxlength="64"
                                                [pattern]="emailPattern"
                                            />
                                        </div>
                                        <div class="togglefield">
                                            <div>{{ 'COMMON.NOTIFICATIONS_TEXT' | translate }}</div>
                                            <mat-slide-toggle
                                                name="notificationSecondaryEmail"
                                                [(ngModel)]="userNotification.notificationSecondaryEmail"
                                                (ngModelChange)="isFormChanged = true"
                                                [disabled]="
                                                    disableInputFields || disableEditIcon ||
                                                    secondaryEmailInput?.errors?.pattern ||
                                                    !userInformation.secondaryEmail ||
                                                    userInformation.secondaryEmail === ''
                                                "
                                            >
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="secondaryEmailInput?.errors?.pattern">
                                    {{ 'COMMON.SECONDARY_EMAIL_VALIDATION' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="custom-50p align-center">
                            <div class="each-Item custom-error">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        [disabled]="disableOnEditFields || disableUsrName || disableEditIcon"
                                        #userNameInput="ngModel"
                                        placeholder="{{ 'COMMON.USER_NAME_TEXT' | translate }}"
                                        name="userName"
                                        [(ngModel)]="userInformation.userName"
                                        (blur)="onUsernameBlur()"
                                        ngDefaultControl
                                        (ngModelChange)="isFormChanged = true"
                                        minlength="4"
                                        required
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="userNameInput?.hasError('minlength')">
                                    {{ 'COMMON.USER_NAME_VALIDATION' | translate }}
                                </div>
                                <div class="custom-error-message" *ngIf="!isUnique">
                                    {{ 'COMMON.USER_NAME_UNIQUE' | translate }}
                                </div>
                            </div>
                            <div class="each-Item custom-error">
                                <div class="full-width toggleContainer custom-phone-input"
                                [ngClass]="{ error: phoneNumberInput && phoneNumberInput.touched && phoneNumberInput.invalid }">
                                    <label class="custom-phone-label">{{ 'COMMON.PHONE_NUMBER' | translate }}</label>
                                    <div class="inputfield" matTooltip="{{ 'USER_PREFERENCES.PHONE_NUMBER_TOOLTIP' | translate }}">
                                        <ngx-intl-tel-input
                                            appTelInputDropdownPosition
                                            [cssClass]="'phone-input'"
                                            [disabled]="disableInputFields || disableEditIcon"
                                            [preferredCountries]="[CountryISO.UnitedStates]"
                                            [enableAutoCountrySelect]="true"
                                            [enablePlaceholder]="true"
                                            [searchCountryFlag]="true"
                                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                            [selectedCountryISO]="CountryISO.UnitedStates"
                                            [phoneValidation]="true"
                                            name="phoneNumber"
                                            #phoneNumberInput="ngModel"
                                            (ngModelChange)="onPhoneNumberChange()"
                                            [(ngModel)]="phoneNumberObject">
                                        </ngx-intl-tel-input>
                                    </div>
                                    <div class="togglefield">
                                        <div>{{ 'COMMON.NOTIFICATIONS_TEXT' | translate }}</div>
                                        <mat-slide-toggle
                                            name="notificationSMS"
                                            (ngModelChange)="isFormChanged = true"
                                            [(ngModel)]="userNotification.notificationSMS"
                                            [disabled]="disableInputFields || disableEditIcon || phoneNumberObject === null || (phoneNumberObject && phoneNumberObject.e164Number === '') ||
                                                (phoneNumberInput && phoneNumberInput.invalid)"
                                        >
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                                <div class="custom-error-message phone-input-error"
                                    *ngIf="phoneNumberInput && phoneNumberInput.touched && phoneNumberInput.invalid"
                                >
                                {{ 'USER_PREFERENCES.PHONE_NUMBER_VALIDATION' | translate }}
                            </div>
                        </div>

                        </div>
                        <div class="custom-50p align-center">
                            <div class="each-Item custom-error">
                                <mat-form-field class="full-width">
                                    <input
                                        *ngIf="selectedCustomer"
                                        matInput
                                        [disabled]="true"
                                        placeholder="{{ 'COMMON.LAST_LOGIN' | translate }}"
                                        [ngModelOptions]="{ standalone: true }"
                                        [ngModel]="userInformation.lastLogin | date: dateFormat:timeZone"
                                    />
                                    <input
                                        *ngIf="!selectedCustomer"
                                        matInput
                                        [disabled]="true"
                                        placeholder="{{ 'COMMON.LAST_LOGIN' | translate }}"
                                        [ngModelOptions]="{ standalone: true }"
                                        [ngModel]="userInformation.lastLogin | date: dateFormat"
                                    />
                                </mat-form-field>
                                <div class="cust-error-message" *ngIf="!isUniqueEmail">
                                    {{ 'COMMON.USER_EMAIL_UNIQUE' | translate }}
                                </div>
                            </div>
                            <div class="each-Item custom-error">
                                <mat-slide-toggle
                                    name="isAAD"
                                    [(ngModel)]="userInformation.isAAD"
                                    (ngModelChange)="isFormChanged = true"
                                    [disabled]="isAADDisabled || disableEditIcon"
                                >
                                    Use Corporate AD
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab *ngIf="loggedInUserRole === USER_ROLES.ADMIN || loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN">
                        <ng-template mat-tab-label>
                            <span class="assignRolesUserHint">{{
                                'ADMIN.CUSTOMER_USER_ASSOCIATION.ASSIGN_ROLE' | translate
                            }}</span>
                        </ng-template>
                        <div class="rolesType">
                            <mat-radio-group
                                name="errorThreshold"
                                [(ngModel)]="userRole"
                                (ngModelChange)="isFormChanged = true"
                                ngDefaultControl
                                class="roles-hint"
                            >
                                <div class="eachItem" *ngFor="let role of roles; trackBy: trackById">
                                    <!-- Add this line to disabled attribute  || disableCustomerLocationTab -->
                                    <mat-radio-button
                                        [disabled]="(loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN && (role.name === USER_ROLES.ADMIN || role.name === USER_ROLES.CUSTOMER_ADMIN)) || loggedInUserRole === USER_ROLES.NONE || disableEditIcon"
                                        [checked]="role.isSelected"
                                        name="role.id"
                                        ngDefaultControl
                                        (change)="changeSelectedRole(role)"
                                        [value]="role.name"
                                        >{{ role.displayRole }}
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="loggedInUserRole === USER_ROLES.ADMIN || loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN || loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER">
                        <ng-template mat-tab-label>
                            <span [ngClass]="{'tab-error': customerSelectionErrorMessage || !customerSelected, 'assignCustomersUserHint' : true}">{{
                                'ADMIN.CUSTOMER_USER_ASSOCIATION.ASSIGN_CUSTOMER' | translate
                            }}</span>
                        </ng-template>
                        <section class="customeSection">
                            <mat-checkbox
                                *ngIf="userInformation.userRole !== 'CustomerUserManager'"
                               [disabled]="(userRole === USER_ROLES.ADMIN && disableSelectionForAdmin) || disableEditIcon"
                                [checked]="selectAll"
                                class="eachItem auto-margin"
                                ngDefaultControl
                                (change)="toggleSelectAll(); isFormChanged = true"
                                name="selectAll"
                                ngDefaultControl
                            >
                                {{ 'COMMON.SELECT_All_OPTION' | translate }}
                            </mat-checkbox>
                            <span class="app-flex-filler"></span>
                            <div class="search-section">
                                <mat-form-field class="full-width customer-search-hint">
                                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                                    <input
                                        matInput
                                        type="text"
                                        placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                                        [formControl]="customerSearch"
                                    />
                                </mat-form-field>
                            </div>
                        </section>
                        <div class="customMessage" *ngIf="!showErrorMessage || customerSelectionErrorMessage">
                            <span class="mat-error">{{
                                'ADMIN.CUSTOMER_USER_ASSOCIATION.SEARCH_ERROR' | translate
                            }}</span>
                        </div>
                        <div class="customeSectionList customer-list-hint">
                            <div class="eachItem" *ngFor="let customer of customers; trackBy: trackByCustomerId">
                                <mat-checkbox
                                   [disabled]="(userRole === USER_ROLES.ADMIN && disableSelectionForAdmin) || disableEditIcon"
                                    [checked]="customer.isSelected"
                                    name="customer.isSelected"
                                    ngDefaultControl
                                    (change)="changeSelecteState(customer); isFormChanged = true"
                                    >{{ customer.customer.customerName }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                                <span [ngClass]="{'tab-error': !locationsSelected, 'assignLocationsUserHint' : true}">{{
                                'ADMIN.CUSTOMER_USER_ASSOCIATION.ASSIGN_LOCATION' | translate
                            }}</span>
                        </ng-template>
                        <section class="customeSection">
                            <mat-checkbox
                                [disabled]="disableSelectionForAdmin || disableEditIcon"
                                class="eachItem auto-margin select-all-hint"
                                (ngModelChange)="toggleSelectAllLocations(); isFormChanged = true"
                                [(ngModel)]="selectAllLocations"
                                name="selectAll"
                                ngDefaultControl
                            >
                                {{ 'COMMON.SELECT_All_OPTION' | translate }}
                            </mat-checkbox>
                            <span class="app-flex-filler"></span>

                            <mat-form-field class="width-50-p customer-select-hint">
                                <mat-select
                                    placeholder="{{
                                        'ADMIN.CUSTOMER_USER_ASSOCIATION.SELECT_CUSTOMERS_PLACEHOLDER' | translate
                                    }}"
                                    [(ngModel)]="selectedCustomerIdForLocation"
                                    (ngModelChange)="setSelectedLocations($event); isFormChanged = true"
                                    name="customer"
                                    ngDefaultControl
                                >
                                    <mat-option
                                        *ngFor="let customer of selectedCustomers; trackBy: trackByCustomerId"
                                        [value]="customer?.customer.customerID"
                                    >
                                        {{ customer.customer.customerName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </section>
                        <div class="customeSectionList location-list-hint">
                            <div class="eachItem" *ngFor="let location of locations; trackBy: trackByLocationId">
                                <mat-checkbox
                                    [disabled]="disableSelectionForAdmin || disableEditIcon || location?.disabled || location?.isChecked"
                                    [checked]="location.viewable && !location?.disabled"
                                    name="location.isSelected"
                                    (change)="onLocationChange(location, selectedCustomerIdForLocation); isFormChanged = true"
                                >
                                    <span>{{ location.name }}</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="allowFeatureEdit()">
                        <ng-template mat-tab-label>
                            <span class="assignFeaturesUserHint">{{
                                'ADMIN.CUSTOMER_USER_ASSOCIATION.ASSIGN_FEATURE_TAB_TITLE' | translate
                            }}</span>
                        </ng-template>
                        <div class="features-hint">
                            <div class="assignEachFeature" *ngFor="let availableFeature of availableFeatures; trackBy: trackById">
                                <!-- Change the ng-model based on selection -->
                                <mat-checkbox
                                    [checked]="availableFeature.viewable"
                                    [disabled]="userRole === 'Admin' || availableFeature.disabled === true || disableEditIcon"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="changeSelectedState(availableFeature); isFormChanged = true"
                                    name="chkboxday"
                                    required="true"
                                    ngDefaultControl
                                >
                                    <strong> {{ availableFeature.displayname }} </strong>
                                </mat-checkbox>
                                <div class="assignDescription">{{ availableFeature.desc }}</div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </form>
        <mat-card-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button
                    mat-button
                    [disabled]="!isFormChanged || !showErrorMessage || customerUSerAssociationForm.invalid
                                || !isUnique || !isUniqueEmail || customerSelectionErrorMessage || disableEditIcon || !locationsSelected
                                || !customerSelected"
                    (click)="saveUserInformation()"
                    [ngClass]="{ 'save-user-hint': showpageHint && domOperationUtilsService.editUserSelectedTab === 0 }"
                >
                    {{ 'COMMON.SAVE_BUTTON' | translate }}
                </button>
            </div>
        </mat-card-actions>
    </mat-card>

    <div class="popupHeader" mat-dialog-title *ngIf="false">
        <span>{{ 'COMMON.EDIT_TOOLTIP' | translate }} : {{ selectedUser?.userEmail }}</span>
        <span class="app-flex-filler"></span>
    </div>

    <form #customerAssociationForm="ngForm" novalidate *ngIf="false">
        <div mat-dialog-content class="customerAssociationContiner">
            <h3>{{ 'COMMON.CUSTOMERS_TEXT' | translate }}</h3>
            <section class="customeSection">
                <mat-checkbox
                    class="eachItem auto-margin"
                    (ngModelChange)="toggleSelectAll()"
                    [(ngModel)]="selectAll"
                    name="selectAll"
                    ngDefaultControl
                >
                    {{ 'COMMON.SELECT_All_OPTION' | translate }}
                </mat-checkbox>
                <span class="app-flex-filler"></span>

                <mat-form-field class="eachItem">
                    <input
                        matInput
                        type="text"
                        placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                        [formControl]="customerSearch"
                    />
                </mat-form-field>
            </section>
            <div class="customMessage" *ngIf="!showErrorMessage">
                <span class="mat-error">{{ 'ADMIN.CUSTOMER_USER_ASSOCIATION.SEARCH_ERROR' | translate }}</span>
            </div>
            <div class="customeSectionList">
                <div class="eachItem" *ngFor="let customer of customers; trackBy: trackByCustomerId">
                    <mat-checkbox
                        [checked]="customer.isSelected"
                        name="customer.isSelected"
                        ngDefaultControl
                        (change)="changeSelecteState(customer)"
                    >
                        {{ customer.customerName }}</mat-checkbox
                    >
                </div>
            </div>
        </div>
    </form>
</div>
