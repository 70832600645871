import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { QvsIConfigurations } from 'app/shared/models/sliicer/results/storm-events';
import { Regime } from 'app/shared/models/sliicer/settings';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import * as _ from 'underscore';
import { Season } from '../../../study-settings/seasons-settings/seasons-settings.utils';
import { TrackBy } from 'app/shared/utils/track-by';

export interface QvsIGroupDialogData {
    qvsIConfigurations: QvsIConfigurations[];
    originalQvsIConfigurations: QvsIConfigurations[];
    regimes: Regime[];
    availableSeasons: Season[];
    availableYears: number[];
    hasYears: boolean;
}

enum FILTER_OPTIONS {
    Year = 'Year',
    Regime = 'Regime',
    Season = 'Season'
}

enum LOGICAL_CONDITION {
    AND = 'AND',
    OR = 'OR'
}

const NAME_FIELD = 'name';

@Component({
  selector: 'app-q-vs-i-group-dialog',
  templateUrl: './q-vs-i-group-dialog.component.html',
  styleUrls: ['./q-vsi-group-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QvsIGroupDialogComponent implements OnInit {
  @ViewChild('formhandle') public form: NgForm;

  public isLoading = false;
  public qvsIConfigurations: QvsIConfigurations[];
  public selectedQvsIConfiguration: QvsIConfigurations;
  subscription: [];
  duplicatedName: boolean;
  public duplicatedFilterName: boolean;
  private originalQvsIConfigurations: QvsIConfigurations[];
  public regimes: Regime[] = [];
  public updated: boolean;
  public availableSeasons: Season[] = [];
  public filterOptions = [];
  private hasYears: boolean;
  private availableYears: number[] = [];

  public LOGICAL_CONDITION = LOGICAL_CONDITION;
  public NAME_FIELD = NAME_FIELD;

  private lastEditedName: string = null;

  public trackByIndex = TrackBy.byIndex;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: QvsIGroupDialogData,
    private dialogRef: MatDialogRef<QvsIGroupDialogComponent>,
    private uiUtilsService: UiUtilsService,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.qvsIConfigurations = JSON.parse(JSON.stringify(this.data.qvsIConfigurations));
    this.originalQvsIConfigurations = [...this.data.originalQvsIConfigurations];
    this.regimes = this.data.regimes;
    this.availableYears = this.data.availableYears;
    this.availableSeasons = this.data.availableSeasons;
    this.hasYears = this.data.hasYears;

    if(this.hasYears) {
        this.filterOptions.push(FILTER_OPTIONS.Year)
    }
    if (this.regimes.length) {
      this.filterOptions.push(FILTER_OPTIONS.Regime)
    }
    if (this.availableSeasons.length) {
      this.filterOptions.push(FILTER_OPTIONS.Season)
    }
    this.selectedQvsIConfiguration = this.qvsIConfigurations[0];

  }

  public groupSelected(group) {
    let previous = false;
    group.query = '';
    group.rawQuery.forEach(item => {
      group.query = group.query + `${item.conditional === LOGICAL_CONDITION.AND && !previous ? '(' : ''} ${item.variable} ${item.compare} '${item.value}'${item.conditional !== LOGICAL_CONDITION.AND && previous ? ')' : ''} ${item.conditional} `
      previous = item.conditional === LOGICAL_CONDITION.AND ? true : false
    });
    group.query = group.query.trimEnd();
    this.checkUpdate();
  }

  userInput() {
    this.updateLastEdited();
  }

  private updateLastEdited() {
    this.lastEditedName = this.selectedQvsIConfiguration ? this.selectedQvsIConfiguration.name : null;
  }

  checkName() {
    const verify = this.qvsIConfigurations.filter(x => (x.name === this.selectedQvsIConfiguration.name || x.name.length === 0))
    if (verify.length > 1) {
      this.duplicatedName = true
    } else {
      this.duplicatedName = false;
    }
    this.checkUpdate();
  }

  checkFilterUpdate() {
    const namesArr: boolean[] = [];
    for(let i = 0; i < this.selectedQvsIConfiguration.groups.length; i++) {
        const q = this.selectedQvsIConfiguration.groups[i];
        if(q) {
            if(q.name.length === 0 || namesArr[q.name]) {
                this.duplicatedFilterName = true;
                this.checkUpdate();
                return;
            }

            namesArr[q.name] = true;
        }
    }

    this.checkUpdate();
    this.duplicatedFilterName = false;
  }

  checkDescription() {
    this.checkUpdate();
  }

  checkUpdate() {
    this.updated = !_.isEqual(this.qvsIConfigurations, this.originalQvsIConfigurations);
  }

  deleteGroup() {
    this.qvsIConfigurations = this.qvsIConfigurations.filter(item => item.name !== this.selectedQvsIConfiguration.name);
    this.selectedQvsIConfiguration = this.qvsIConfigurations ? this.qvsIConfigurations[0] : null;
    this.checkUpdate();
    this.uiUtilsService.safeChangeDetection(this.cdr);
  }

  newGroup() {
    this.updateLastEdited();
    this.qvsIConfigurations.push({
      name: '',
      description: '',
      groups: []
    });
    this.selectedQvsIConfiguration = this.qvsIConfigurations[this.qvsIConfigurations.length - 1];
    this.uiUtilsService.safeChangeDetection(this.cdr);
  }

  deleteGroupFilter(filter) {
    this.updateLastEdited();
    this.selectedQvsIConfiguration.groups = this.selectedQvsIConfiguration.groups.filter(item => item.name !== filter.name);
    this.checkFilterUpdate();
    this.uiUtilsService.safeChangeDetection(this.cdr);
  }

  newGroupFilter() {
    this.updateLastEdited();
    this.selectedQvsIConfiguration.groups.push({
      name: '',
      query: '',
      color: '',
      rawQuery: [{
        variable: '',
        compare: '=',
        value: '',
        conditional: ''
      }]
    });
    this.checkFilterUpdate();
    this.uiUtilsService.safeChangeDetection(this.cdr);
  }

  addGroupFilterSeries(conditional: LOGICAL_CONDITION, serie, i, index) {
    this.updateLastEdited();
    if (serie.conditional === '') {
      serie.conditional = conditional;
      this.selectedQvsIConfiguration.groups[index].rawQuery.push({
        variable: '',
        compare: '=',
        value: '',
        conditional: ''
      })
    } else {
      if (serie.conditional === conditional) {
        // this.selectedQvsIConfiguration.groups[index].rawQuery.splice(i, 1);
      } else {
        this.selectedQvsIConfiguration.groups[index].rawQuery[i].conditional = conditional
      }
    }
    this.groupSelected(this.selectedQvsIConfiguration.groups[index])
  }
  deleteGroupFilterSeries(i: number, index: number) {
    this.updateLastEdited();
    const queryLength = this.selectedQvsIConfiguration.groups[index].rawQuery.length;
    if (queryLength === 1) {
      // Do not delete last group element
      return;
    } else if(queryLength === i + 1) {
      // Clear last conditional so user can add new row
      this.selectedQvsIConfiguration.groups[index].rawQuery[i - 1].conditional = '';
    }
    this.selectedQvsIConfiguration.groups[index].rawQuery.splice(i, 1);
    this.groupSelected(this.selectedQvsIConfiguration.groups[index]);
    this.checkUpdate();
    // Have to refresh form validity
    this.form.controls[NAME_FIELD].updateValueAndValidity();
  }

  selectedColorPicker(event, index) {
    this.updateLastEdited();
    this.selectedQvsIConfiguration.groups[index].color = event;
    this.checkUpdate();
  }

  get years() {
    return this.availableYears.map(String) as Array<string>;
  }

  save() {
    this.dialogRef.close({qvi: this.qvsIConfigurations, lastEditedName: this.lastEditedName});
  }

  close() {
    this.dialogRef.close();
  }
}
