import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    OnChanges,
    AfterContentInit,
    SimpleChanges,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

import { Observable, Subject, throwError, of } from 'rxjs';
import { AutoCompleteItem } from 'app/shared/models/selectable';
import { catchError, debounceTime, map, startWith, tap, timeout } from 'rxjs/operators';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS_FACTORY as MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY } from '@angular/material/legacy-tooltip';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TrackBy } from 'app/shared/utils/track-by';

const AUTOCOMPLETE_OPTION_HEIGHT = 48;
const AUTOCOMPLETE_WRAPPER_ROLE_ATTR = 'listbox';

@Component({
    selector: 'app-auto-complete',
    templateUrl: './auto-complete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
                ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
                disableTooltipInteractivity: true,
            }
        }
    ],
    styles: ['.selected-item-bg { background: rgba(0, 0, 0, 0.04) }']
})
export class AutoCompleteComponent implements OnInit, OnChanges, AfterContentInit {
    @ViewChild('locationSearchBox') private locationSearchBox: ElementRef<HTMLInputElement>;

    /**
     * Will display tooltip on hover if option text exceeds provided value
     */
    @Input() public showTooltipMinLength: number;

    /**
     * Fixed width for autocomplete input
     */
    @Input() public panelWidth: string;

    /**
     * Defines if all options should be presented on focus
     */
    @Input() public showAllOptionsOnFocus: boolean = false;

    /**
     * Defines if options should be scrolled to preselected items on focus
     */
    @Input() public scrollToSelectedOnFocus: boolean = false;

    /**
     * Defines if selected option should be marked with slightly grey background
     */
    @Input() public markSelectedOption: boolean = false;

    /**
     * Populates selected value on focus out
     */
    @Input() public populateSelectedValueonFocusOut: boolean = false;

    /**
     * Represents the collection of entities.
     */
    @Input() public items: Array<AutoCompleteItem>;

    /**
     * Defines if auto complete input should be disabled.
     */
    @Input() public disabled = false;

    /**
     * Defines if clear button should be disabled.
     */
    @Input() public disableClear = false;

    /**
     * Defines the placeholder string value for the control.
     */
    @Input() public placeholder: string;

    /**
     * Indicates whether to show the "All" feature. Enabled by default.
     */
    @Input() public isShowAll = true;

    /**
     * Indicates whether the input field will reset itself after a selection is made.
     */
    @Input() public isSelfClearing = false;

    /**
     * Indicates whether the input field will reset itself after a selection is made.
     */
    @Input() public onlyManualClear = false;

    /**
     * Indicates whether the first option is selected by default.
     */
    @Input() public autoActiveFirstOption = false;

    /**
     * Preselected items
     */
    @Input() public preselectedItem: AutoCompleteItem;

    /** Whenever choice should be limited to list items */
    @Input() public strict = false;

    /** Whenever options should be sorted by starting letters */
    @Input() public sortStartWith = false;

    /** Whenever should focus input field when clear value */
    @Input() public focusOnClear = true;

    /** # */
    @Input() public filterDebounceTime: number = 300;

    /**
     * Emits the selected location.
     */
    @Output() public selectedItems = new EventEmitter<Array<AutoCompleteItem>>();

    /**
     * Emits a change to the input text.
     */
    @Output() public inputChanged = new EventEmitter<string>();

    /**
     * Represents the input control for the auto-complete.
     */
    @ViewChild(MatInput) private autoCompleteInput: MatInput;
    @ViewChild(MatInput) private sharedAutoComplete: MatAutocomplete;

    /**
     * Represents the auto-complete search box picker contorl.
     */
    public autoCompleteCtrl = new FormControl();

    /**
     * Represents the filtered collection of selectable items.
     */
    public filteredItems: Observable<Array<AutoCompleteItem>>;

    /**
     * Represents the value to be used for all option
     */
    public get allValue() {
        return 'All';
    }

    private hasContentInit = false;

    private autoCompleteName$: Subject<string> = new Subject<string>();
    private shouldScrollToIndex: number;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef
    ) { }

    /**
     * Framework level hook.
     */
    public ngOnInit(): void {
        // for start, select the initial value if the input is set
        if (this.autoActiveFirstOption && this.items && this.items.length > 0 && this.items[0]) {
            this.autoCompleteCtrl.setValue(this.items[0].name);
        }

        this.autoCompleteCtrl.valueChanges
            // We want to filter items not too often - some users may type letters fast
            .pipe(debounceTime(300))
            .pipe(startWith(''))
            .subscribe(locationName => {
                this.filteredItems = of(locationName ? this.filterItems(locationName) : this.items);
                this.inputChanged.emit(locationName);

                // #39484 due to changeDetection: ChangeDetectionStrategy.OnPush we need to mark that component does have changes
                this.uiUtilsService.safeMarkForCheck(this.cdr);

                // #39259 used to scroll to selected item, since filteredItems is reassigned each time, we cannot subscribe to it to call scrollToSelected properly
                this.scrollToSelected(locationName);
            });

        if (this.preselectedItem) {
            this.autoCompleteCtrl.setValue(this.preselectedItem.name);
        }
    }

    /**
     * Framework level life cycle hook.
     */
    public ngOnChanges(changes: SimpleChanges) {
        if (
            this.hasContentInit &&
            changes.items &&
            changes.items.currentValue &&
            changes.items.previousValue
            && changes.items.currentValue.toString() !==
            changes.items.previousValue.toString()
        ) {
            this.clearInputField();
        }
        if (changes.preselectedItem) {
            const value = changes.preselectedItem.currentValue ? changes.preselectedItem.currentValue.name : [];
            this.autoCompleteCtrl.patchValue(value);
        }

        if (changes.disabled) {
            changes.disabled.currentValue ? this.autoCompleteCtrl.disable() : this.autoCompleteCtrl.enable();
        }
    }

    public ngAfterContentInit() {
        this.hasContentInit = true;
    }

    /**
     * Used to filter the location names for the auto-complete.
     * @param searchName Represents the searched for location name.
     */
    public filterItems(searchName: string): Array<AutoCompleteItem> {
        // ensure args
        if (!searchName || typeof searchName !== 'string') {
            return this.items;
        }

        if (!this.sortStartWith) {
            // filter locations
            return this.items.filter((a) => a.name.toLowerCase().includes(searchName.toLowerCase()));
        }

        const includedCustomersSet: Set<string> = new Set();
        const startWithOptions = !this.items ? [] : this.items.filter((a) => {
            const fit = a.name.toLowerCase().startsWith(searchName.toLowerCase());

            if (fit) {
                includedCustomersSet.add(a.name);

                return true;
            }

            return false;
        });

        const likeOptions = !this.items ? [] : this.items.filter((a) => {
            const fit = a.name.toLowerCase().includes(searchName.toLowerCase());

            if (fit && !includedCustomersSet.has(a.name)) {
                return true;
            }

            return false;
        });

        return [...startWithOptions, ...likeOptions];
    }

    /** Handle whenever user finished to enter text. Input focusout event */
    public handleFocusOut() {
        if (this.populateSelectedValueonFocusOut && this.preselectedItem) {
            this.autoCompleteCtrl.setValue(this.preselectedItem.name);
        }
        // #22178 Strict mode, check if option exists, if not clear input
        if (!this.strict) return;

        // #24346 focusout happens just before item is selected from a popup. Delay and listen, drop if selected in meantime.
        this.autoCompleteName$
            .pipe(
                timeout(200),
                catchError((err) => {
                    const exists = this.items.find(x => x.name === this.autoCompleteCtrl.value);

                    if (exists) {
                        this.optionSelected(this.autoCompleteCtrl.value.toLowerCase());
                    } else {
                        this.clearInputField();
                    }
                    return throwError(err);
                })
            ).subscribe(
                () => this.autoCompleteName$.complete(),
                () => this.autoCompleteName$.complete()
            );
    }

    public onEscape() {
        this.locationSearchBox.nativeElement.blur();
    }

    /** Handle user focus on input, to display all options */
    public handleFocus() {
        if (!this.showAllOptionsOnFocus) {
            return;
        }

        this.autoCompleteCtrl.setValue('');

        if (!this.scrollToSelectedOnFocus || !this.preselectedItem || !this.items || !this.items.length) {
            return;
        }

        const preselectedIndex = this.items.findIndex(v => v.name === this.preselectedItem.name);

        if (preselectedIndex === -1) {
            return;
        }

        this.shouldScrollToIndex = preselectedIndex;
    }

    /**
     * Handles the Auto-Complete selection.
     * @param itemSelectedEvent Represents the selected event fired by the Angular Material Auto-Complete component.
     */
    public handleOptionSelected(itemSelectedEvent: MatAutocompleteSelectedEvent): void {
        // ensure args
        if (!itemSelectedEvent) {
            return;
        }

        // get selection name
        const selectedAudoCompleteName: string = itemSelectedEvent.option.value.toLowerCase();

        this.autoCompleteName$.next(selectedAudoCompleteName);
        this.optionSelected(selectedAudoCompleteName);

        setTimeout(() => {
            this.locationSearchBox.nativeElement.blur();
        }, 0);
    }

    private scrollToSelected(searchText: string) {
        setTimeout(() => {
            const optionsListAll = document.querySelectorAll(`[role="${AUTOCOMPLETE_WRAPPER_ROLE_ATTR}"]`);

            if (!optionsListAll || optionsListAll.length === 0) {
                return;
            }

            const optionsList = optionsListAll[optionsListAll.length - 1];

            if (this.shouldScrollToIndex && searchText === '') {
                optionsList.scroll(0, (AUTOCOMPLETE_OPTION_HEIGHT * this.shouldScrollToIndex) - 10);    // 10 pixels difference just for better look

                this.shouldScrollToIndex = null;
            } else {
                optionsList.scroll(0, 0);
            }
        }, 0);
    }

    private optionSelected(selectedAudoCompleteName: string) {
        // check for all locations being selected
        if (selectedAudoCompleteName === this.allValue.toLowerCase()) {
            this.selectedItems.emit(this.items);

            // check if this will be self clearing
            if (this.isSelfClearing) {
                // clear input value
                this.clearInputField();
            }

            // exit immediatly
            return;
        }

        // get location from collection
        const matchedLocations = this.items.filter(
            (loc: AutoCompleteItem) => loc.name.toLowerCase() === selectedAudoCompleteName,
        );

        // emit only if location is found
        if (matchedLocations) {
            this.selectedItems.emit(matchedLocations.map(v => ({ ...v, isChecked: true })));
        }

        // check if this will be self clearing
        if (this.isSelfClearing) {
            // clear input value
            this.clearInputField();
        }
    }

    /**
     * Clears the input fields value.
     * @param event Represents the click event.
     */
    public clearInputField(event?): void {
        // if event is provided, then it is manual clear, so we can check for disabled
        if (event && this.disableClear) {
            return;
        }
        // clear input value
        this.autoCompleteCtrl.setValue(null);

        // only manual clear will prevent auto focus on initial load,
        // it will focus only when input is cleared from the X button
        if (this.onlyManualClear && this.focusOnClear && event) {
            this.locationSearchBox.nativeElement.focus();
        } else if (!this.onlyManualClear && this.focusOnClear) {
            this.locationSearchBox.nativeElement.focus();
        }

        const itemsArray = new Array<AutoCompleteItem>();
        this.selectedItems.emit(itemsArray);
        if (event) {
            event.stopPropagation();
        }
    }
}
