<div class="notification-dashboard notification-dashboard-hint">
    <div class="notification-details mat-box-shadow">
        <div class="title-bar">
            <p class="nd-hint">{{ PAGE_TEXT.TITLE }}</p>
            <div>
                <button *ngIf="showAdminUserOpts" mat-button>
                    <mat-icon class="nd-hint-add_notification">add</mat-icon>{{ PAGE_TEXT.ADD_NOTIFICATION }}
                    <div class="new-notification-dropdown mat-box-shadow">
                        <ul>
                            <li *ngFor="let type of alarmTypes; trackBy: trackByIndex" (click)="addNotification(type)">
                                <hr *ngIf="type.category === UINotificationCategory.SEPARATOR" />
                                <span *ngIf="type.category !== UINotificationCategory.SEPARATOR">
                                    {{
                                        type.category === UINotificationCategory.ALARM
                                            ? PAGE_TEXT.ALARM_TYPES_TEXT[type.value] +
                                              ' ' +
                                              PAGE_TEXT.ALARM_TYPES_TEXT.ALARM
                                            : PAGE_TEXT.NOTIFICATION_TYPES_TEXT[type.value]
                                    }}
                                    <span
                                        *ngIf="
                                            type.category === UINotificationCategory.ALARM &&
                                            type.value === NotificationRouteAlarmType.ANSR
                                        "
                                        >&trade;</span
                                    >
                                </span>
                            </li>
                        </ul>
                    </div>
                </button>
                <button
                class="nd-hint-filter_notification"
                    mat-button
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    (click)="showFilterMenu = !showFilterMenu"
                >
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="showFilterMenu" class="filter-menu">
            <div class="options">
                <div class="dropdown-options">
                    <div class="col">
                        <mat-form-field class="nd-hint-name_notification">
                            <input matInput [(ngModel)]="filterName" placeholder="{{ PAGE_TEXT.COLUMN_NAMES.NAME }}" />
                        </mat-form-field>
                        <app-multi-select-auto-complete
                            [items]="userOptions"
                            [isShowAll]="true"
                            *ngIf="userOptions"
                            placeholder="{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.USERS }}"
                        ></app-multi-select-auto-complete>
                        <app-multi-select-auto-complete
                            [items]="alarmTypeOptions"
                            [isShowAll]="true"
                            *ngIf="alarmTypeOptions"
                            placeholder="{{ PAGE_TEXT.ALARM_TYPE }}"
                        ></app-multi-select-auto-complete>
                    </div>
                    <div class="col">
                        <mat-form-field *ngIf="showLocationOptions" class="nd-hint-location_notification">
                            <mat-select
                                placeholder="{{ PAGE_TEXT.LOCATION_FILTER_TYPE }}"
                                [(ngModel)]="selectedLocationType"
                            >
                                <mat-option *ngFor="let option of locationTypeOptions; trackBy: trackById" [value]="option.id">
                                    {{ option.name }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                        <app-multi-select-auto-complete
                            [items]="locationGroupOptions"
                            [isShowAll]="true"
                            *ngIf="selectedLocationType === 0 && locationGroupOptions"
                            placeholder="{{ PAGE_TEXT.LOCATION_GROUPS }}"
                        >
                        </app-multi-select-auto-complete>
                        <app-multi-select-auto-complete
                            [items]="locationOptions"
                            [isShowAll]="true"
                            *ngIf="selectedLocationType === 1 && locationOptions"
                            placeholder="{{ PAGE_TEXT.ALARM_PAGES_TABS.LOCATIONS }}"
                        >
                        </app-multi-select-auto-complete>
                    </div>
                </div>
            </div>
            <div class="button-row">
                <button mat-button class="nd-buttons-actions" (click)="resetFilter()">{{ 'COMMON.RESET_BTN' | translate }}</button>
                <button mat-button class="nd-buttons-actions" (click)="applyFilter()">{{ 'COMMON.APPLY_BTN' | translate }}</button>
            </div>
        </div>
        <div *ngIf="!pageLoading && showAdminUserOpts !== undefined" class="main-section nd-mian-table-help">
            <table *ngIf="tableData" mat-table [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef class="nd-table-name">{{ PAGE_TEXT.COLUMN_NAMES.NAME }}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef class="nd-table-description">{{ PAGE_TEXT.COLUMN_NAMES.DESCRIPTION }}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.description }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef class="nd-table-type">{{ PAGE_TEXT.COLUMN_NAMES.TYPE }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ PAGE_TEXT.ALARM_TYPES_TEXT[element.type] }}
                        <span *ngIf="element.type === NotificationRouteAlarmType.ANSR">&trade;</span>
                        <span
                            *ngIf="
                                element.type === NotificationRouteAlarmType.ANSR ||
                                element.type === NotificationRouteAlarmType.Standard
                            "
                        >
                            &nbsp;{{ PAGE_TEXT.ALARM_TYPES_TEXT.ALARM }}</span
                        >
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="enable">
                    <mat-header-cell *matHeaderCellDef class="nd-table-enable">{{ PAGE_TEXT.COLUMN_NAMES.ENABLE_DISABLE }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-slide-toggle
                            #enabledAlarmToggle
                            [checked]="element.enabled"
                            [disabled]="!showAdminUserOpts"
                            (change)="flipEnable($event, element)"
                        ></mat-slide-toggle>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="options">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="icon-cell">
                        <button class="nd-table-edit" *ngIf="showAdminUserOpts" mat-icon-button (click)="editNotification(element)">
                            <mat-icon matTooltip="{{ 'COMMON.EDIT_BUTTON' | translate }}">mode_edit</mat-icon>
                        </button>
                        <button class="nd-table-delete" *ngIf="showAdminUserOpts" mat-icon-button (click)="requestDeleteNotification(element)">
                            <mat-icon matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}">delete</mat-icon>
                        </button>
                        <button
                            *ngIf="!showAdminUserOpts && !element.subscribed"
                            mat-icon-button
                            (click)="editNotification(element)"
                        >
                            <mat-icon matTooltip="{{ 'COMMON.SUBSCRIBE_BTN' | translate }}">email</mat-icon>
                        </button>
                        <button
                            *ngIf="!showAdminUserOpts && element.subscribed"
                            mat-icon-button
                            (click)="editNotification(element)"
                        >
                            <mat-icon matTooltip="{{ 'COMMON.UNSUBSCRIBE_BTN' | translate }}">unsubscribe</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </table>
            <mat-paginator
                *ngIf="tableData"
                [length]="totalPaginationLength"
                [pageSizeOptions]="[10, 25, 50, 100]"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                (page)="changePage($event)"
            ></mat-paginator>
            <p class="no-data" *ngIf="!tableData">{{ PAGE_TEXT.NO_NOTIFICATIONS }}</p>
        </div>
        <div *ngIf="pageLoading || showAdminUserOpts === undefined" class="main-section">
            <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>
    </div>
</div>
